<template>
    <div class="record">
        <div class="contentTop">
            <div><el-select class="opt" v-model="opt1" placeholder="" :popper-append-to-body="false"
                    popper-class="select">
                    <el-option :style="opt1 != 'Today' ? 'color: #D5DDFF;' : ''" :label="$t('Today')"
                        value="Today"></el-option>
                    <el-option :style="opt1 != 'Yesterday' ? 'color: #D5DDFF;' : ''" :label="$t('Yesterday')"
                        value="Yesterday"></el-option>
                    <el-option :style="opt1 != '7' ? 'color: #D5DDFF;' : ''" :label="$t('Almost 7 days')"
                        value="7"></el-option>
                    <el-option :style="opt1 != '15' ? 'color: #D5DDFF;' : ''" :label="$t('Almost 15 days')"
                        value="15"></el-option>
                    <el-option :style="opt1 != '30' ? 'color: #D5DDFF;' : ''" :label="$t('Almost 30 days')"
                        value="30"></el-option>
                    <el-option :style="opt1 != '60' ? 'color: #D5DDFF;' : ''" :label="$t('Almost 60 days')"
                        value="60"></el-option>
                </el-select>
                <el-select class="opt" v-model="opt2" placeholder="" :popper-append-to-body="false">
                    <el-option :style="opt2 != 'Tudos' ? 'color: #D5DDFF;' : ''" label="Tudos"
                        value="Tudos"></el-option>
                    <el-option :style="opt2 != 'Completado' ? 'color: #D5DDFF;' : ''" label="Completado"
                        value="Completado"></el-option>
                    <el-option :style="opt2 != 'Distribuído' ? 'color: #D5DDFF;' : ''" label="Distribuído"
                        value="Distribuído"></el-option>
                </el-select>
            </div>
            <div>{{ $t('Total revenue') }} <span>{{ revenue }}</span></div>
        </div>
        <el-table :data="tableData" :stripe="true" style="width: 100%" class="customer-table">
            <el-table-column prop="hours" :label="$t('Hours')">
            </el-table-column>
            <el-table-column prop="type" :label="$t('type')">
            </el-table-column>
            <el-table-column prop="quantity" :label="$t('quantity')">
            </el-table-column>
        </el-table>
        <div class="noListBox">
            <div class="noList" v-if="tableData.length == 0">
                <img src="@/assets/img_Sem-Registros.png" />
                <div>{{ $t('Not yet') }}</div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: '',
    components: {

    },
    mixins: [],
    props: {

    },
    data() {
        return {
            opt1: 'Today',
            opt2:'Tudos',
            tableData: [
                
            ],
            revenue: 0
        }
    },
    computed: {

    },
    watch: {

    },
    mounted() {

    },
    methods: {

    }
};
</script>
<style type="text/less" lang="less" scoped>
.record {
    .noListBox {
        position: relative;
        height: 50vw;
        width: 100vw;
        top: -1px;
        background: #6E8DE6;
        .noList {
            position: absolute;
            text-align: center;
            top: 50%;
            left: 50%;
            transform: translate(-40%, -40%);
            color: #B2C5FD;
            font-size: 3vw;
            img {
                width: 20vw;
                margin-bottom: 1vw;
            }
        }
    }
}

.contentTop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #B2C5FD;
    margin-top: 1vw;
    font-size: 3vw;
    padding: 1vw;
    span {
        margin-left: 0.5vw;
        color: #FFAA09;
    }
}

.opt {
    margin-right: 1vw;
    margin-bottom: 1vw;
    width: 30vw;
}

/deep/.el-dialog {
    background: var(--theme-main-bg-color);
    border-radius: 1vw;
}

/deep/.el-dialog__close {
    color: #fff !important;
}

.el-menu--horizontal>.el-menu-item {
    color: #B2C5FD;
}

.dialogTitle {
    text-align: center;
    border-bottom: 2px solid #fff;
    width: 7vw;
    color: #fff;
    padding-bottom: 1vw;
    margin: auto;
    font-size: 1.5vw;
    font-weight: bold;

    .el-icon-user-solid {
        margin-right: 0.5vw;
    }
}

/deep/.el-input__inner {
    background: var(--theme-main-bg-color);
    color: rgb(178, 197, 253);
    border-radius: 40vw;
}

/deep/.el-input__inner:focus {
    border-color: #fff;
    box-shadow: 0px 0px 10px #fff;
}

/deep/.el-table__body,
/deep/.el-table__header {
    width: 100% !important;
    background: var(--theme-main-bg-color);
}

/deep/.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
    background: #6E8DE6;
}

/deep/.el-table tr {
    background: #5375D1;
}

/deep/.el-table td.el-table__cell,
/deep/.el-table th.el-table__cell.is-leaf {
    border: none;
}

/deep/.el-table--border::after,
/deep/.el-table--group::after {
    width: 0;
}

/deep/.el-table th.el-table__cell {
    background: #6E8DE6;
}

/deep/.el-table tbody tr:hover>td {
    background-color: transparent !important;
}

/deep/.has-gutter {
    width: 100%;
    color: #fff;
    background: var(--theme-main-bg-color);
}

/deep/.el-table tbody .el-table_1_column_2 {
    color: #FFAA09;
}

.customer-table {
    color: #D5DDFF;
}

.customer-table::before {
    width: 0;
}

.customer-table .el-table__fixed-right::before,
.el-table__fixed::before {
    width: 0;
}

/deep/.el-table .el-table__cell {
    text-align: center;
}

/deep/.is-scrolling-none {
    background: #6E8DE6;

}

/deep/.el-table__empty-text {
    display: none;
}
</style>