<template>
  <div class="contentBox">
    <div style="height: calc(var(--vh, 1vh) * 100 - 3.55rem)">
      <myScrollbarBar st="y">
        <div class="gameList" v-if="gameList && gameList.total">
          <div
            style="
              width: 33.33%;
              margin: var(--cardRowSpacing) var(--cardColSpacing);
            "
            v-for="(item, index) in gameList.game_list"
            :key="index"
          >
            <div class="gameBox">
              <div class="gameitem">
                <!--游戏图片-->
                <!-- <img class="cover" :src="item2[3]" /> -->
                <van-image
                  :src="item.show_image"
                  :lazy-load="true"
                  width="var(--outer-box-main-width)"
                  height="var(--outer-box-main-height)"
                  fit="fill"
                  @click="toEnterGame(item)"
                >
                  <template v-slot:loading>
                    <svg-icon
                      icon-class="img777"
                      style="
                        width: 1rem;
                        height: 1rem;
                        font-size: 0.03rem;
                        fill: white;
                      "
                    ></svg-icon>
                  </template>
                  <template v-slot:error>
                    <svg-icon
                      icon-class="img777"
                      style="
                        width: 1rem;
                        height: 1rem;
                        font-size: 0.03rem;
                        fill: white;
                      "
                    ></svg-icon>
                  </template>
                </van-image>
                <div class="gameTitle">
                  <div class="sn">{{ item.show_name }}</div>
                  <!-- <p
                    style="
                      font-size: var(--bottom-name-font-size);
                      margin-top: var(--bottom-name-margin-top);
                      text-shadow: 0 0.02rem 0.04rem
                        var(--game-bottom-name-text-shadow);
                    "
                  >
                    {{ item.vendor_code }}
                  </p> -->
                </div>
                <div class="fault" v-if="!item.server">
                  <van-image
                    :src="require('@/assets/img_dt_whz.png')"
                    fit="fill"
                    style="
                      height: var(--card-benchmark);
                      left: 0;
                      position: absolute;
                      top: 0;
                      width: var(--card-benchmark);
                    "
                  >
                  </van-image>
                </div>
                <div class="sc" @click="favoriteClick(index, item)">
              <!-- <img
                :src="getImg(index, item).img"
                :class="{ sa: getImg(index, item).i === 2 }"
              /> -->

              <img v-if="item.favorite" :src="refresh_id===index?require('@/assets/bookmark_star_2.png'):require('@/assets/btn_sc_on_2.png')" :class="{sa:refresh_id===index}"/>
              <img
                v-if="!item.favorite"
                :src="require('@/assets/btn_sc_off_2.png')"
              />
              </div>
                <!-- <img
                  class="sc"
                  @click="favoriteClick(item)"
                  v-show="item.favorite"
                  src="@/assets/bookmark_star_2.png"
                />
                <img
                  class="sc"
                  @click="favoriteClick(item)"
                  v-show="!item.favorite"
                  src="@/assets/btn_sc_off_2.png"
                /> -->
              </div>
            </div>
          </div>
        </div>
        <van-empty
          v-else
          :image="require('@/assets/img_none_sj.png')"
          :description="$t('nogames')"
        />
      </myScrollbarBar>
    </div>
  </div>
</template>

<script>
import SvgIcon from "@/components/SvgIcon";
import myScrollbarBar from "@/components/phone/common/myScrollbarBar.vue";
import { gameAddFavouriteGame } from "@/api/index";
export default {
  name: "MoreBatchDisplay",
  components: { myScrollbarBar },
  props: {
    gameList: {
      type: Object,
      required: true,
    },
    pageSize: {
      type: Number,
      required: true,
    },
    pageIndex: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      total: 0,
      refresh_id: -1,
    };
  },

  mounted() {},
  computed: {},
  methods: {
    toEnterGame(item) {
      if (!item.server) {
        this.$notify({
          duration: 2000,
          message: this.$t('the game stops serving'),
          type: "warning",
          background: "#fff",
          offset: "3",
        });
        return;
      }
      this.$emit("child-clicked", item);
    },
    flippingPages(index) {
      this.$emit("flipping-pages", index);
    },
    favoriteClick(index, item) {
    
      gameAddFavouriteGame({ game_code: item.game_code }).then(({ data }) => {
        if (data.code === 0) {
          this.refresh_id = index;
          // if (data.data.favorite) {
          //   item.show_animation = true;
          // } else {
          //   item.show_animation = false;
          // }
          // item.show_animation=true;
          item.favorite = data.data.favorite;
          // console.log(item);
          // let newValue = {
          //   category: item.category,
          //   favorite: !item.favorite,
          //   game_code: item.game_code,
          //   hot: item.hot,
          //   popular: item.popular,
          //   recent: item.recent,
          //   server: item.server,
          //   show_image: item.show_image,
          //   show_name: item.show_name,
          //   show_vendor_code: item.show_vendor_code,
          //   sort: item.sort,
          //   vendor_code: item.vendor_code,
          // };
          // this.displayedItems[index]=newValue;
          // console.log(this.displayedItems);
          // this.$set(this.displayedItems, index, newValue);
          // this.$set(this.displayedItems, index, { ...this.displayedItems[index], favorite: data.data.favorite});
        }
      });
      // console.log(item);
    }
  },
  created() {},
};
</script>
<style type="text/less" lang="less" scoped>
.contentBox {
  position: relative;
  // padding-bottom: 10vw;
  font-size: 0.34rem;
  // width: 7rem;
  // padding: 0.2rem;

  p {
    cursor: pointer;
  }

  .gameList {
    display: flex;
    align-items: start;
    flex-wrap: wrap;
    transition: 1s;
  }

  .gameBox {
    width: var(--outer-box-main-width);
    height: var(--outer-box-main-height);
    transition: transform 0.3s, -webkit-transform 0.3s;
    position: relative;
    border-radius: var(--box-cut-border-radius);
    overflow: hidden;
    .gameitem {
      -ms-flex-align: center;
      -ms-flex-pack: end;

      -ms-flex-align: center;
      -ms-flex-pack: center;
      align-items: center;
      background-color:var(--theme-game-card-ddt_bg);
      background-image: initial;
      border-radius: 0;
      color: var(--theme-game-card-ddt_icon);
      display: -ms-flexbox;

      // height: 100%;
      justify-content: center;
      left: 0;
      position: absolute;
      top: 0;
      // width: 100%;
      height: var(--box-cut-height);
      position: relative;
      width: var(--box-cut-width);
      .van-image {
        /deep/ .van-image__loading,
        /deep/ .van-image__error {
          background-color: transparent;
        }
      }

      .fault {
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 0;
        bottom: 0;
        cursor: not-allowed;
        left: 0;
        opacity: 1;
        position: absolute;
        right: 0;
        text-align: center;
        top: 0;
        -webkit-transition: opacity 0.6s;
        transition: opacity 0.6s;
        z-index: 9;
      }
    }
    .gameTitle {
      position: absolute;
      bottom: 0.2rem;
      width: 100%;
      text-align: center;
      word-wrap: break-word;
      color: var(--theme-text-color-task-btn);
      // display: flex;
      // height: var(--game-platform-name-height);
      // height: 11vw;
      // line-height: 1rem;
      font-size: var(--game-platform-name-font-size);
      .sn {
        -webkit-text-stroke: var(--game-name-text-stroke);
        font-weight: var(--game-name-font-weight);
        font-size: var(--game-platform-name-font-size);
        text-align: center;
        // text-shadow: 0 0.02rem 0.04rem var(--game-name-text-shadow);
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        word-wrap: break-word;
        display: -webkit-box;
        font-family: MicrosoftYaHei;
        max-width: 100%;
        overflow: hidden;
        text-align: center;
        text-overflow: ellipsis;
        color: var(--theme-text-color-task-btn);
        // padding: var(--game-name-vertical-padding);
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    .sc {
      position: absolute;
      top: var(--favorited-icon-top);
      right: var(--favorited-icon-right);
      left: var(--favorited-icon-left);
      width: var(--favorited-icon-side);
      height: var(--favorited-icon-side);
      z-index: 4;
      font-size: var(--favorited-icon-side);
      cursor: pointer;
      // overflow: hidden;
      img {
        width: var(--favorited-icon-side);
        height: var(--favorited-icon-side);
      }
      .sa {
        --icon-animation-transform: scale(1.7);
        -webkit-transform: var(--icon-animation-transform);
        transform: var(--icon-animation-transform); 
        // top: 0px;
        margin: 0 auto;
        height: inherit!important;
        width: inherit!important;
      }
    }
  }

  .gameBox:nth-child(3n) {
    margin-right: 0vw;
  }

  /deep/.van-empty__description {
    color: var(--theme-text-color-lighten);
    font-size: 0.22rem;
    margin-top: 0.2rem;
    line-height: 22px;
  }
}
</style>
