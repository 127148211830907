<template>
  <div class="wrap">
    <div class="theme-box-shadow-h5">
      <section class="top">
        <div class="left">
          <span>
            <img :src="require(`@/assets/img_vip_${info.lv}.png`)" />
          </span>
          <span
            @click="
              () => {
                openviplist = true;
              }
            "
            ><i
              style="
                display: inline-flex;
                justify-content: center;
                align-items: center;
              "
            >
              <svg-icon
                icon-class="comm_icon_wh"
                style="
                  width: 1em;
                  height: 1em;

                  fill: currentColor;
                "
              ></svg-icon></i
          ></span>
        </div>
        <div class="right">
          <div class="r-top">
            <div class="r-left">
              <label>{{ $t('collectible') }}</label>
              <span>{{ info.performance }}</span>
            </div>
            <van-button :plain="true">{{ $t('receive') }}</van-button>
            <van-button :plain="true">{{ $t('historic') }}</van-button>
          </div>
          <div class="r-bottom">
            <div class="r-body">
              <label>{{ $t('agent mode') }}</label>
              <span>{{ $t('agent tip1') }}</span>
            </div>
          </div>
        </div>
      </section>

      <div class="inviteInfo">
        <div class="inviteInfoContent">
          <div class="qrcode">
            <van-image
              :lazy-load="true"
              fit="fill"
              width="1.3rem"
              height="1.3rem"
              :src="imgurl"
              style="margin: 0.1rem"
            >
              <template v-slot:loading>
                <div class="imgload"></div>
              </template>
            </van-image>
            <!-- <div class="btn">{{ $t("saveImg") }}</div>
              -->
            <van-button class="btn">{{ $t("save photo") }}</van-button>
          </div>
          <div class="linkBox">
            <div class="myLink">
              {{ $t("my link") }}
              <!-- <i @click="share" class="el-icon-share"></i> -->
              <span
                style="
                  text-wrap: nowrap;
                  color: var(--theme-primary-color);
                  cursor: pointer;
                  font-size: 0.24rem;
                "
                >{{ $t("to alter") }}</span
              >
            </div>
            <div>
              <el-autocomplete
                v-model="link"
                placeholder=""
                :readonly="true"
                popper-class="selectitem"
                style="max-width: 5rem; width: 100%"
                :popper-append-to-body="false"
                :visible-arrow="true"
              >
                <template slot="suffix">
                  <span class="copyIcon">
                    <svg-icon
                      icon-class="comm_icon_copy"
                      style="width: 1em; height: 1em; fill: white"
                    ></svg-icon>
                  </span>
                </template>
                <template>
                  {{ info.link_url }}
                </template>
              </el-autocomplete>

              <!-- <i v-copy="2222" class="el-icon-document-copy"></i> -->
            </div>
            <div class="inviteCode">
              <div>
                <label>{{ $t("direct subordinates") }}</label
                ><span class="subordinatesnum"> {{ info.commission }} </span>
              </div>
              <div>
                <label style="margin-right: 0.1rem">{{
                  $t("invitation code")
                }}</label
                ><span>{{ info.invite_code }}</span>
                <span class="copyIcon2">
                  <svg-icon
                    icon-class="comm_icon_copy"
                    style="width: 1em; height: 1em; fill: white"
                  ></svg-icon>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div
          class="my-scrollbar my-scrollbar-hover"
          style="width: 100%; height: 1.75rem"
        >
          <div class="my-scrollbar-wrap my-scrollbar-wrap-x">
            <div class="my-scrollbar-content">
              <div class="Partilhar">
                <div
                  style="
                    -ms-flex-align: center;
                    align-items: center;
                    display: -ms-flexbox;
                    display: flex;
                    -ms-flex-direction: column;
                    flex-direction: column;
                    min-width: 0.95rem;
                  "
                >
                  <span class="share">
                    <svg-icon
                      icon-class="tg_share"
                      style="width: 1em; height: 1em; fill: white"
                    ></svg-icon> </span
                  ><span>{{ $t('share') }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <myDialog
        :open.sync="openviplist"
        @closeDialog="closeDialog"
        :originalClose="false"
        width="6rem"
        :Title="$t('agent level')"
      >
        <section class="vipList" style="min-height: 8rem; --empty-height: 4rem">
          <el-table
            :data="vipList"
            :stripe="true"
            style="width: 100%"
            :highlight-current-row="false"
            height="7.8rem"
          >
            <el-table-column prop="id" :label="$t('agent level')">
              <template slot-scope="scope">
                <img
                  :src="require(`@/assets/img_vip_${scope.row.lv}.png`)"
                  style="width: 0.6rem"
                />
                <span>{{ scope.row.name }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="deposito">
              <template slot="header">
                <div>
                  <span> {{ $t('agent tip2') }}</span>
                </div>
              </template>
              <template slot-scope="scope">
                <span class="currencyAmount"> {{ scope.row.val }}</span>
              </template>
            </el-table-column>
          </el-table>
        </section>
      </myDialog>
    </div>
  </div>
</template>
<script>
import { getAgentInfo, getAgentLvConfig } from "@/api/index";
import qrcode from "qrcode";
import myDialog from "@/components/phone/common/myDialog.vue";

export default {
  name: "invitationLink",
  components: { myDialog },
  data() {
    return {
  
      link: "",
      loading: false,
      openviplist: false,
      imgurl: "",
      info: { lv: 0 },
      vipList: [],
    };
  },
  created() {},
  mounted() {

    window.addEventListener("resize", this.countWidth);
    this.getAgentLvConfig();
    this.getAgentInfo();
  },
  destroyed() {
    window.removeEventListener("resize", this.countWidth);
  },
  methods: {
    remoteMethod(query) {},
    getAgentLvConfig() {
      getAgentLvConfig().then(({ data }) => {
        const config_json = JSON.parse(data.data.config_json);
        this.vipList = config_json;
      });
    },
    getAgentInfo() {
      getAgentInfo().then(({ data }) => {
        this.info = data.data;
        this.link = data.data.link_url;
        const text = data.data.link_url;
        const opt = { margin: 0 };
        qrcode.toDataURL(text, opt, (err, url) => {
          if (!err) {
            this.imgurl = url;
          }

        });
        //   qrcode.toDataURL(this.info.url,(err,url)=>{
        //   if(!err){
        //     this.imgurl=url;
        //   }
        //  })
      });
    },
    closeDialog() {
      this.openviplist = !1;
    },
    runpage(item) {
      if (this.jnpf.isEmpty(item.id)) {
      } else {
        const url = item.url;
        this.$router.push({
          path: "/" + url + "?setp=99&dataId=" + item.dataId,
        });
      }
    },
    share() {
      navigator.share({
        url: "",
        text: "",
        title: "",
      });
    },
  },
  beforeDestroy(){
    window.removeEventListener("resize", this.countWidth);
  }
};
</script>
<style type="text/less" lang="less" scoped>
.vipList {
  position: relative;
}

/deep/.el-autocomplete {
  height: 0.9rem;
  .el-input--suffix {
    height: 100%;
    .el-input__inner {
      height: 100%;
    }
  }
}
.Partilhar {
  -ms-flex-align: start;
  align-items: flex-start;
  display: -ms-flexbox;
  display: flex;
  min-height: 1.35rem;
  padding-bottom: 0.06rem;
  padding-top: 0.2rem;
  span {
    word-wrap: break-word;
    display: inline-block;
    font-size: 0.22rem;
    line-height: 1.15;
    max-width: 1.1rem;
    padding-top: 0.1rem;
    text-align: center;
  }
  .share {
    -ms-flex-align: center;
    -ms-flex-pack: center;
    align-items: center;
    border: 0.01rem solid var(--theme-color-line);
    border-radius: 50%;
    color: var(--theme-primary-color);
    display: -ms-flexbox;
    display: flex;
    font-size: 0.36rem;
    height: 0.7rem;
    justify-content: center;
    padding: 0;
    width: 0.7rem;
  }
}
// /deep/.el-input__suffix {
//   top: 30%;
// }
.copyIcon {
  font-size: 0.3rem;
  // position: absolute;
  right: 0.2rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: var(--theme-primary-color);
  cursor: pointer;
  // max-width: none;
}

.wrap {
  height: 100%;
  // background: #6E8DE6;
  // overflow: hidden;
  position: relative;
  // padding: .2rem .2rem 0;
  margin: 0;
}
.theme-box-shadow-h5 {
  margin: 0.15rem 0.15rem 0;
  padding: 0.05rem;
  .top {
    background: var(--theme-main-bg-color);
    -ms-flex-align: start;
    align-items: flex-start;
    border-bottom: 0.01rem solid var(--theme-color-line);
    border-radius: 0.14rem 0.14rem 0 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-size: 0.22rem;
    height: auto;
    display: flex;
    justify-content: flex-start;
    padding: 0 0.2rem;
    width: 100%;
    .left {
      margin-right: 0.2rem;
      cursor: pointer;
      height: 100%;
      position: relative;
      span:first-child {
        display: inline-block;
        width: 1.3rem;
        height: 0.8rem;
        img {
          width: 100% !important;
        }
      }

      span:nth-child(2) {
        display: flex;
        justify-content: center;
        cursor: pointer;
        color: var(--theme-primary-font-color);
        border-radius: 50%;
        background-color: var(--theme-primary-color);
        align-items: center;
        -ms-flex-align: center;
        -ms-flex-pack: center;
        height: 0.24rem;
        left: 1.12rem;
        position: absolute;
        top: 0.05rem;
        width: 0.24rem;
        svg {
          font-size: 0.16rem;
          color: var(--theme-primary-font-color);
        }
      }
    }
    .right {
      -ms-flex-positive: 1;
      flex-grow: 1;
      padding: 0.2rem 0;
      .r-top {
        -ms-flex-positive: 1;
        flex-grow: 1;
        -ms-flex-align: center;
        -ms-flex-pack: justify;
        align-items: center;
        color: var(--theme-text-color-darken);
        display: -ms-flexbox;
        display: flex;
        font-size: 0.22rem;
        justify-content: space-between;
        padding: 0;
        .r-left {
          -ms-flex-align: center;
          align-items: center;
          color: var(--theme-text-color-lighten);
          display: -ms-flexbox;
          display: flex;
          -ms-flex-positive: 1;
          flex-grow: 1;
          label {
            color: var(--theme-text-color-lighten);
            margin-right: 0.1rem;
          }
          span {
            font-size: 0.22rem;
            color: var(--theme-secondary-color-finance) !important;
          }
        }
        button {
          font-size: 0.2rem;
          height: 0.5rem;
          line-height: 1.3;
          width: 1.2rem;
          border-radius: 0.14rem;
        }
        /deep/.van-button {
          margin-right: 0.2rem;
        }
        /deep/.van-button--plain {
          background-color: var(--theme-disabled-bg-color);
          border-color: var(--theme-disabled-bg-color);
          color: var(--theme-disabled-font-color);
        }
        button:last-child {
          background-color: var(--theme-primary-color);
          border-color: var(--theme-primary-color);
          color: var(--theme-primary-font-color);
          text-shadow: none;
        }
      }
      .r-bottom {
        -ms-flex-align: start;
        -ms-flex-pack: justify;
        align-items: flex-start;
        color: var(--theme-text-color-darken);
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        font-size: 0.22rem;
        justify-content: space-between;
        margin-top: 0.2rem;
        padding: 0;
        width: 100%;
        .r-body {
          -ms-flex-align: center;
          align-items: center;
          color: var(--theme-text-color-lighten);
          display: -ms-flexbox;
          display: flex;
          label {
            color: var(--theme-text-color-lighten);
            margin-right: 0.1rem;
          }
          span {
            color: var(--theme-text-color-darken);
          }
        }
      }
    }
  }
  .inviteInfo {
    background-color: var(--theme-main-bg-color);
    border-radius: 0.1rem;
    -webkit-box-shadow: 0 0.03rem 0.09rem 0 rgba(0, 0, 0, 0.06);
    box-shadow: 0 0.03rem 0.09rem 0 rgba(0, 0, 0, 0.06);
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-bottom: 0.2rem;
    padding: 0.2rem;
    padding-top: 0.2rem;

    .inviteInfoContent {
      display: flex;
      justify-content: space-between;
      // margin: 2vw 0;

      .qrcode {
        width: 1.5rem;
        margin-right: 0.2rem;
        min-height: 1.9rem;
        background: #fff;
        // overflow: hidden;
        border: 0.01rem solid var(--theme-color-line);
        border-radius: 0.14rem 0.14rem 0.2rem 0.2rem;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        box-sizing: content-box;
        .btn {
          border: 1px solid #d9d9d9;
          background-color: var(--theme-primary-color);
          border-radius: 0 0 0.14rem 0.14rem;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          color: var(--theme-primary-font-color);
          font-size: 0.24rem;
          height: auto;
          min-height: 0.4rem;
          padding-bottom: 0.05rem;
          white-space: pre-wrap;
          width: 1.5rem;
          word-break: break-word;
          line-height: 1.499;
          display: inline-block;
          font-weight: 400;
          touch-action: manipulation;
          padding: 0;
        }
      }

      .linkBox {
        width: 68vw;
        .myLink {
          margin-bottom: 1vw;
          color: var(--theme-text-color-lighten);
          display: flex;
          justify-content: space-between;
          padding: 0 2vw;
          font-size: 0.24rem;
          align-items: center;
          i {
            font-size: 4vw;
          }
        }
        .link {
          width: 90%;
        }
        .inviteCode {
          -ms-flex-align: start;
          -ms-flex-pack: justify;
          align-items: flex-start;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
          font-size: 0.24rem;
          justify-content: space-between;
          margin-top: 0.2rem;
          .subordinatesnum {
            color: var(--theme-text-color-lighten);
          }
          span {
            color: var(--theme-text-color-darken);
          }
          .copyIcon2 {
            color: var(--theme-primary-color);
            margin-left: 0.1rem;
            /deep/ .svg-icon {
              display: inline;
            }
          }
        }
      }
    }
  }
}
/deep/.el-table::before {
  height: 0;
}
/deep/.el-table {
  background-color: transparent;
  tr {
    background-color: transparent;
  }

  .el-table__header-wrapper {
    tr {
      height: 0.8rem;
      text-align: left;
      th {
        // background-color: var(--theme-bg-color);
        border-bottom: 0.01rem solid var(--theme-color-line) !important;
        border-top: 0.01rem solid var(--theme-color-line) !important;
        color: var(--theme-text-color);
        font-size: 0.2rem;
        font-weight: 400;
        background: var(--theme-main-bg-color);
        font-size: 0.24rem;
        height: 0.8rem;
      }
    }
    th:first-child {
      border-bottom-left-radius: 0.06rem;
      border-left: 0.01rem solid var(--theme-color-line) !important;
      border-top-left-radius: 0.06rem;
    }
    th:first-child {
      border-bottom-left-radius: 0.1rem;
      border-top-left-radius: 0.1rem;
    }
    th:last-child {
      border-bottom-right-radius: 0.06rem;
      border-right: 0.01rem solid var(--theme-color-line) !important;
      border-top-right-radius: 0.06rem;
    }

    th:last-child {
      border-bottom-right-radius: 0.1rem;
      border-top-right-radius: 0.1rem;
    }
    .el-table__cell {
      background: var(--theme-main-bg-color);
      // background-color: var(--theme-bg-color) !important;
      font-size: 0.22rem;
      color: var(--theme-text-color-darken);
      border: none;
      text-align: center;
      text-transform: capitalize;
      transition: background 0.3s ease;
      word-wrap: break-word;
      height: 0.8rem;
      padding: inherit;
      .cell {
        padding: 0;
        line-height: 0.24rem;
        display: inline-block;
        max-width: 100%;
        vertical-align: top;
        text-align: center;
        word-wrap: normal;
        word-break: normal;
        width: unset;
        div {
          align-items: center;
          display: flex;
          span:first-child {
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
            vertical-align: middle;
            white-space: break-spaces;
            line-height: 0.24rem;
            border-collapse: separate;
          }
          span:nth-child(2) {
            -ms-flex-align: center;
            -ms-flex-pack: center;
            align-items: center;
            background-color: var(--theme-primary-color);
            border-radius: 50%;
            cursor: pointer;
            display: -ms-inline-flexbox;
            display: inline-flex;
            justify-content: center;
            margin-left: 0.1rem;
            padding: 0.04rem;
            svg {
              color: var(--theme-primary-font-color);
              font-size: 0.12rem;
            }
          }
        }
      }
      // border:#ce9510;
    }
  }

  .el-table__body {
    .cell {
      padding: initial;
      word-break: normal;
      color: var(--theme-text-color);
      font-size: 0.2rem;
      text-align: right;
    }
    tr {
      height: 0.8rem;
    }
    tr:hover {
      .el-table__cell {
        background-color: var(--theme-bg-color) !important;
      }
    }
    .el-table__row {
      // pointer-events: none;
      color: var(--theme-text-color);
      font-size: 0.2rem;
      td {
        background-color: var(--theme-bg-color);

        height: 0.6rem;
        padding: 0 0.13rem !important;
        white-space: pre;
        transition: background 0.3s;
        border-bottom: none;
      }
      td:first-child {
        .cell:first-child {
          -ms-flex-align: center;
          align-items: center;
          display: -ms-flexbox;
          display: flex;
          font-size: 0.2rem;
          text-align: left;
          width: 100%;

          span {
            color: var(--theme-text-color-lighten);
            margin-left: 0.15rem;
          }
        }
      }
    }

    .el-table__row--striped {
      td {
        background-color: var(--theme-main-bg-color) !important;
      }
      .el-table__cell:first-child {
        border-left: none;
      }
      .el-table__cell:last-child {
        border-right: none;
      }
      .el-table__cell {
        border-bottom: none;
        border-top: none;
      }
    }
  }
}
</style>
