<template>
    <div class="mySteps">
      <slot></slot>
    </div>
  </template>
  
  <script>
  export default {
    name: 'mySteps',
    props: {
      current: {
        type: Number,
        default: 0
      }
    },
    provide() {
      return {
        currentStep: this.current
      };
    },
    computed: {
        mySteps() {
        return this.$slots.default().map((vnode, index) => {
          return {
            ...vnode,
            props: {
              ...vnode.props,
              index: index,
            }
          };
        });
      }
    }
  };
  </script>
  
  <style scoped>
  .mySteps {
    -webkit-font-feature-settings: "tnum";
    font-feature-settings: "tnum";
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: rgba(0,0,0,.65);

    font-size: 0;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;

    -ms-flex-pack: center;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
  }
  .steps-item:not(:last-child){
    margin-right: 1.7rem;
  }
  </style>
  