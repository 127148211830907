<template>
  <div class="box">
    <el-container>
      <el-header height=".9rem">
        <van-nav-bar
          :right-text="$t('historic')"
          @click-left="onClickLeft"
          @click-right="onClickRight"
          :fixed="true"
          style="
            max-width: var(--theme-max-width);
            margin: 0 auto;
            left: 0;
            right: 0;
            height: 0.9rem;
          "
        >
          <template #left>
            <div>
              <i style="font-size: 0.24rem">
                <svg-icon
                  icon-class="comm_icon_fh"
                  style="
                    width: 1em;
                    height: 1em;
                    color: var(--theme-text-color);
                    fill: var(--theme-text-color-lighten);
                  "
                ></svg-icon>
              </i>
            </div>
          </template>
          <template #title>
            <div class="title">
              <span class="global-wrapper-title">
                <span
                  style="
                    width: 100%;
                    margin-bottom: -1.64706px;
                    padding-bottom: 1.64706px;
                    font-size: 0.3rem;
                  "
                  ><span
                    style="
                      vertical-align: middle;
                      display: inline-block;
                      width: 100%;
                    "
                  >
                    <span style="-webkit-line-clamp: 2" class="ts inner-text">
                      {{ getName(active) }}</span
                    >
                  </span></span
                >
              </span>
            </div>
          </template>
        </van-nav-bar></el-header
      >
      <el-main>
        <div class="my-scrollbar myscroll" style="width: 100%">
          <div ref="scrollTarget" class="my-scrollbar-wrap my-scrollbar-wrap-y">
            <invite v-if="active === 'invite'"></invite>
            <!-- <assure v-if="index == 1"></assure>
            <dailyGuarantee v-if="index == 2"></dailyGuarantee> -->
            <envelopeReward v-if="active === 'red_packet'"></envelopeReward>
            <trunTable v-if="active === 'fortune_wheel'"></trunTable>
            <signIn v-if="active === 'day_sign'"></signIn>
            <!-- <cashback v-if="index == 6"></cashback> -->

            <div class="allActivities">
              <div class="line"></div>
              <div class="taskList">
                <van-swipe
                  :show-indicators="false"
                  :loop="false"
                  :stop-propagation="true"
                >
                  <van-swipe-item
                    v-for="(item, index2) in taskList"
                    :key="item.key"
                    class="imgBox"
                    style="width: 1.88rem"
                  >
                    <div class="activityItem">
                      <div>
                        <div class="imgborder" @click="selectTask(item.key )">
                          <div class="activateBox" v-show="item.key  === active">
                            <p class="smallTags">
                              <i
                                class="gou"
                                style="
                                  display: inline-flex;
                                  justify-content: center;
                                  align-items: center;
                                "
                              >
                                <svg-icon
                                  icon-class="comm_icon_gou"
                                  focusable="false"
                                  aria-hidden="true"
                                  style="width: 1em; height: 1em"
                                ></svg-icon>
                              </i>

                              <i
                                class="bg"
                                style="
                                  display: inline-flex;
                                  justify-content: center;
                                  align-items: center;
                                "
                              >
                                <svg-icon
                                  icon-class="comm_img_corner"
                                  focusable="false"
                                  aria-hidden="true"
                                  style="width: 1em; height: 1em"
                                ></svg-icon>
                              </i>
                            </p>
                          </div>
                          <!-- <img
                            :src="item.cover"
                            draggable="false"
                            style="height: 0.8rem"
                          /> -->
                          <div
                            class="imgbg"
                            :style="'background-image:url(' + item.image + ')'"
                          ></div>
                        </div>

                        <div
                          class="text"
                          :class="item.key === active ? 'act' : ''"
                        >
                          <div
                            style="
                              font-size: 0.16rem;
                              line-height: 1.2;
                              width: 100%;
                            "
                          >
                            {{ getName(item.key)  }}
                          </div>
                        </div>
                      </div>
                      <!-- <div
                        class="showUsed"
                        v-if="item.id == taskList[index].id"
                      >
                        <i class="el-icon-check"></i>
                      </div> -->
                    </div>
                  </van-swipe-item>
                </van-swipe>

                <!-- <div class="imgList" ref="imgList">
                  <div
                    class="swiper-wrapper"
                    style="
                      transition-duration: 0ms;
                      transform: translate3d(135.357px, 0px, 0px);
                    "
                  >
                    <div
                      @click="selectTask(item.id)"
                      v-for="item in taskList"
                      :key="item.id"
                      class="imgBox"
                      :class="item.id == index ? 'act' : ''"
                      style="margin-right: 10px"
                    >
                      <div>
                        <div class="imgborder">
                          <div class="activateBox">
                            <p class="smallTags">
                              <i
                                class="gou"
                                style="
                                  display: inline-flex;
                                  justify-content: center;
                                  align-items: center;
                                "
                              >
                                <svg-icon
                                  icon-class="comm_icon_gou"
                                  focusable="false"
                                  aria-hidden="true"
                                  style="width: 1em; height: 1em"
                                ></svg-icon>
                              </i>

                              <i
                                class="bg"
                                style="
                                  display: inline-flex;
                                  justify-content: center;
                                  align-items: center;
                                "
                              >
                                <svg-icon
                                  icon-class="comm_img_corner"
                                  focusable="false"
                                  aria-hidden="true"
                                  style="width: 1em; height: 1em"
                                ></svg-icon>
                              </i>
                            </p>
                          </div>
                          <img :src="item.cover" style="height: 0.8rem" />
                        </div>

                        <div
                          class="showUsed"
                          v-if="item.id == taskList[index].id"
                        >
                          <i class="el-icon-check"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </el-main>
    </el-container>
  </div>
</template>
<script>
import invite from "@/components/phone/eventList/invite.vue";
import assure from "@/components/phone/eventList/assure.vue";
import dailyGuarantee from "@/components/phone/eventList/dailyGuarantee.vue";
import envelopeReward from "@/components/phone/eventList/envelopeReward.vue";
import trunTable from "@/components/phone/eventList/trunTable.vue";
import signIn from "@/components/phone/eventList/signIn.vue";
import cashback from "@/components/phone/eventList/cashback.vue";
import "@vant/touch-emulator";
import { getActivityInfo } from "@/api/index";
export default {
  watch: {
    $route(to, from) {
      // if (to.query.tab === 'tab2') {
      //   // 切换到指定标签页的逻辑
      //   this.activeTab = 'tab2'; // 假设activeTab是用来控制当前激活标签页的变量
      // }
      if (to.query.index) {
        if (this.active != to.query.index) {
          this.active = to.query.index;
        }
      }
    },
  },
  activated() {
    if (this.$route.query.index) {
      if (this.active != this.$route.query.active) {
        this.active = this.$route.query.index;
      }
    }
  },
  components: {
    invite,
    assure,
    dailyGuarantee,
    envelopeReward,
    trunTable,
    signIn,
    cashback,
  },
  data() {
    return {
      taskList: [],
      active: "",
    };
  },
  mounted() {this.getActivityInfo();},
  methods: {
    getName(key) {
      // console.log(key);
      for (let index = 0; index <   this.taskList.length; index++) {
        const element =   this.taskList[index];
        if (element.key === key) {
          return this.$t("activity_"+element.key);
        }
      }
    
    },
    getActivityInfo() {
      getActivityInfo().then(({ data }) => {
        if (data.code === 0) {
          this.taskList = data.data.activity_list;
        }
      });
    },
    onClickLeft() {
      history.go(-1);
    },
    onClickRight() {
      this.$router.push({
        path: "/Promotion",
        query: {
          index: 5,
        },
      });
    },
    selectTask(index) {
      this.$router.push({
        path: "/eventItem",
        query: {
          index: index,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
// .box {
//   min-height: 100vh;
//   background-color: var(--theme-bg-color);
// }
.myscroll {
  padding-bottom: 1.5rem;
  min-height: 5rem;
  height: calc(var(--vh, 1vh) * 100);
  // height: calc(var(--vh,1vh)*100 - 1.24rem)!important;
  // height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}
/deep/.el-main {
  padding: 0;
}
.allActivities {
  padding-bottom: 0.1rem;
  height: 4rem;
  .line {
    -ms-flex-align: center;
    align-items: center;
    color: var(--theme-text-color);
    display: -ms-flexbox;
    display: flex;
    font-size: 0.24rem;
    line-height: 0.31rem;
  }
  .taskList {
    -ms-flex-align: center;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    padding: 0.2rem 0;
    padding-left: 0.2rem;
    padding-right: 0.2rem;

    .imgBox {
      width: 1.88rem !important;
      position: relative;
      height: 100%;
      margin-right: 10px;

      .activityItem {
        .imgborder {
          -ms-flex-align: center;
          -ms-flex-pack: center;
          align-items: center;
          background-color: var(--theme-main-bg-color);
          border-radius: 0.05rem;
          -webkit-box-shadow: 0 0.02rem 0.04rem 0
            var(--theme-aside-no-active-box-shadow);
          box-shadow: 0 0.02rem 0.04rem 0
            var(--theme-aside-no-active-box-shadow);
          display: -ms-flexbox;
          display: flex;
          justify-content: center;
          padding: 0.05rem;
          position: relative;
          .activateBox {
            border: thin solid var(--theme-primary-color);
            border-radius: 0.05rem;
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 1;
            // display: none;
            .smallTags {
              bottom: -0.01rem;
              display: block;
              height: 0.3rem;
              line-height: 0.3rem;
              overflow: hidden;
              position: absolute;
              right: -0.01rem;
              width: 0.3rem;
              font-size: 0.3rem;
              .gou {
                bottom: 0.04rem;
                color: var(--theme-active-gou-color);
                color: var(--theme-web_filter_gou);
                height: 0.096rem;
                position: absolute;
                right: 0.04rem;
                width: 0.125rem;
              }
              .bg {
                color: var(--theme-active-bg-color);
                height: 100%;
                width: 100%;
                color: var(--theme-active-gou-color) !important;
                line-height: 0.3rem;
              }
            }
          }
          .imgbg {
            background-repeat: no-repeat;
            background-size: 100% 100%;
            height: 0.8rem;
            opacity: 1;
            position: relative;
            width: 100%;
          }
        }
        .text {
          color: var(--theme-text-color-lighten);
          font-size: 0.18rem;
          margin-top: 0.1rem;
          text-align: center;
          div {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .act {
          color: var(--theme-primary-color);
        }
        // .showUsed {
        //   position: absolute;
        //   width: 0vw;
        //   height: 0vw;
        //   border: 3vw solid transparent;
        //   bottom: -2.6vw;
        //   right: -2.6vw;
        //   transform: rotateZ(136deg);
        //   border-bottom: 3vw solid #fff;
        //   font-size: 3vw;
        //   .el-icon-check {
        //     position: absolute;
        //     top: 0vw;
        //     left: -1.5vw;
        //     transform: rotateZ(225deg);
        //     font-weight: bold;
        //     color: #5375d1;
        //   }
        // }
      }
    }

    // .act {
    //   border: 1px solid #fff;
    //   border-radius: 0.3vw;
    // }
  }
}
[class*="van-hairline"]::after {
  border: 0;
}

.van-nav-bar {
  border-bottom: 0.01rem solid var(--theme-color-line);
  background: var(--theme-main-bg-color);
  /deep/.van-nav-bar__content {
    height: 0.9rem;
    padding: 0px 0.1rem 0px 0.2rem;
  }

  /deep/.van-nav-bar__right {
    width: 0.9rem;
    display: flex;
    justify-content: flex-end;
    position: inherit;
    padding: 0;
    font-size: 0.22rem;
  }
  /deep/.van-nav-bar__left {
    position: inherit;
    padding: 0;
    width: 0.8rem;
    div {
      align-items: center;
      color: var(--theme-text-color);
      cursor: pointer;
      display: flex;
      font-size: 0.2rem;
      font-size: 0.24rem;
      height: 0.8rem;
      width: 0.8rem;
    }
  }
  /deep/.van-nav-bar__title {
    align-items: center;
    display: flex;
    flex: 2 1 0%;
    flex-direction: column;
    margin: 0;
    max-width: none;
    // max-width: max-content;
    .title {
      // padding: 0px 0.34rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--theme-text-color);
      span {
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        color: var(--theme-text-color-darken);
        display: -webkit-box;
        margin: 0px 0.12rem;
        overflow: hidden;
        text-align: center;
        text-overflow: ellipsis;
        vertical-align: middle;
      }
      .global-wrapper-title {
        font-size: 0.3rem;

        margin-top: -0.014rem;

        .ts {
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          display: -webkit-box;
          line-height: 1.2;
          overflow: initial;
          text-align: center;
          text-overflow: ellipsis;
          vertical-align: middle;
          word-break: break-word;
        }
      }
    }
  }

  /deep/.van-nav-bar__arrow {
    color: var(--theme-text-color);
  }

  /deep/.van-nav-bar__text {
    color: var(--theme-ant-primary-color-0);
    word-wrap: break-word;
    line-height: 1.1;
    text-align: center;
    white-space: pre-line;
    word-break: break-word;
    //  font-size: 24.576px;
    font-weight: 400;
    user-select: none;
    font: inherit;
    text-transform: none;
    box-sizing: border-box;
    pointer-events: none;
  }
  /deep/.van-ellipsis {
    white-space: inherit;
  }
}

/deep/.el-input__inner {
  background-color: var(--theme-main-bg-color);
  color: var(--theme-text-color);
  border-color: var(--theme-color-line);
  // border-radius: 40vw;
}

/deep/.el-input__inner:focus {
  border-color: var(--theme-ant-primary-color-13) !important;
  box-shadow: 0 0 0 0.02rem rgba(var(--theme-primay-color-hex), 0.2) !important;
}

::-webkit-scrollbar {
  display: none;
}
</style>
