<template>
  <div>
    <div class="top">
      <div class="left">
        <p class="balance">
          <span>{{ $t("balance") }}</span>
          <span class="b"> {{ formatNumberToString(coin) }}</span>
          <i
            @click="refreshUserInfo"
            class="anticon refresh-coin animate__spin animate__infinite"
            :class="{ animate__animated: isAnimateAnimated }"
          >
            <i
              style="
                display: inline-flex;
                justify-content: center;
                align-items: center;
              "
            >
              <svg-icon
                icon-class="comm_icon_sx"
                style="
                  width: 1em;
                  height: 1em;
                  color: var(--theme-alt-primary);
                  fill: currentColor;
                  font-size: 0.293rem;
                "
              ></svg-icon>
            </i>
          </i>
        </p>
        <p class="condition">
          <span>
            <span>{{ $t("you need to bet") }} </span>
            <span class="bet" @click="gotoPath(0, '', 1)">{{
              formatNumberToString(flow_require)
            }}</span>
            <span> {{ $t("to withdraw money") }}</span>
          </span>
        </p>
      </div>
    </div>
    <section class="bottom">
      <section class="main">
        <div class="common-tabs-content">
          <article class="inputpanel">
            <el-form :rules="rules" ref="cashForm" :model="cashForm">
              <div class="bankcard">
                <el-form-item v-if="cardAccountsInfo.length > 0">
                  <div class="selectbank">
                    <el-select
                      class="selectIpt"
                      :value="cardTitle"
                      :popper-append-to-body="true"
                      popper-class="myselectitem"
                      @change="cradChange"
                    >
                      <template slot="prefix">
                        <div class="ico">
                          <img
                            src="@/assets/icon_normal_pix.png"
                            style="width: 0.48rem; height: 0.48rem"
                          />
                        </div>
                      </template>

                      <el-option
                        :value="index"
                        :key="index"
                        v-for="(item, index) in cardAccountsInfo"
                      >
                        <div class="my-item">
                          <img
                            src="@/assets/icon_normal_pix.png"
                            style="width: 0.4rem; height: 0.4rem"
                          />
                          <span
                            style="
                              margin-left: 0.2rem;
                              font-size: 0.22rem;
                              max-width: 3rem;
                              overflow: hidden;
                              text-overflow: ellipsis;
                              white-space: nowrap;
                            "
                            :style="{
                              color:
                                select_index === item.index
                                  ? 'var(--theme-primary-color)'
                                  : 'var(--theme-text-color-lighten)',
                            }"
                            >PIX</span
                          >
                          <span
                            style="font-size: 0.22rem"
                            :style="{
                              color:
                                select_index === item.index
                                  ? 'var(--theme-primary-color)'
                                  : 'var(--theme-text-color-lighten)',
                            }"
                            >({{ truncateString(item.pix_key) }})</span
                          >
                          <span
                            style="
                              font-size: 0.22rem;
                              color: var(--theme-text-color-lighten);
                              margin-left: 0.04rem;
                            "
                          >
                            {{ item.pix_type }}
                          </span>
                        </div>
                      </el-option>
                    </el-select>
                    <div class="rightButton" @click="gotoPath(0, '', 2)">
                      <i
                        style="
                          display: inline-flex;
                          justify-content: center;
                          align-items: center;
                        "
                      >
                        <svg-icon
                          icon-class="icon_tx_txgl"
                          style="
                            width: 1em;
                            height: 1em;
                            color: var(--theme-alt-primary);
                            fill: currentColor;
                          "
                        ></svg-icon>
                      </i>
                    </div>
                  </div>
                </el-form-item>
                <div v-else class="addBankCard" @click="gotoPath(0, '', 2)">
                  <i
                    style="
                      display: inline-flex;
                      justify-content: center;
                      align-items: center;
                      color: var(--theme-primary-color);
                      font-size: 0.4rem;
                    "
                  >
                    <svg-icon
                      icon-class="img_tx_tjzh"
                      focusable="false"
                      aria-hidden="true"
                      style="width: 1em; height: 1em; fill: currentColor"
                    ></svg-icon>
                  </i>
                  <span>{{ $t("add account") }}</span>
                  <span class="arrow">
                    <i
                      style="
                        display: inline-flex;
                        justify-content: center;
                        align-items: center;
                        color: var(--theme-text-color-lighten);
                        font-size: 0.2rem;
                        margin-left: 0.15rem;
                        -webkit-transform: rotate(180deg);
                        transform: rotate(180deg);
                      "
                    >
                      <svg-icon
                        icon-class="comm_icon_fh"
                        focusable="false"
                        aria-hidden="true"
                        style="width: 1em; height: 1em; fill: currentColor"
                      ></svg-icon>
                    </i>
                  </span>
                </div>
                <el-form-item class="bottom-margin moneyInput" prop="num">
                  <el-input
                    class="inputMoney"
                    v-model="cashForm.num"
                    type="digit"
                    :placeholder="
                      cardAccountsInfo.length > 0
                        ? $t('money limit', { n1: 10, n2: 50000 })
                        : $t('please add account for withdrawal first')
                    "
                    :readonly="cardAccountsInfo.length === 0"
                    :disabled="cardAccountsInfo.length === 0"
                    @input="inputMoney"
                  >
                    <template slot="prefix">
                      <span class="label">R$</span>
                    </template>
                    <template slot="suffix" v-if="cardAccountsInfo.length > 0">
                      <span
                        class="allcash"
                        @click="cashForm.num = user.balance / 100"
                        >Tudo</span
                      >
                    </template>
                  </el-input>
                  <template slot="error" slot-scope="scope">
                    <div class="err-explain">{{ scope.error }}</div>
                  </template>
                </el-form-item>
              </div>
              <div class="passBox">
                <div class="top-line">
                  <el-form-item class="bottom-margin">
                    <myNumberPassInput
                      :passvalue.sync="passvalue"
                      :disabled="cardAccountsInfo.length === 0"
                      @leave="leaveInputPass"
                      @change="inputChange"
                      :title="$t('check withdrawal password')"
                    ></myNumberPassInput>
                    <div class="err-explain" v-if="showerr">
                      {{ $t("6 pure numbers") }}
                    </div>
                  </el-form-item>
                </div>
                <div
                  class="tipsBox"
                  v-if="cardAccountsInfo.length > 0 && flow_require > 0"
                >
                  <span class="tipscontext">
                    <span>
                      <span>{{ $t("you need to bet") }} </span>
                      <span class="money">
                        {{ formatNumberToString(flow_require) }}</span
                      >
                      <span> {{ $t("to withdraw money") }}</span>
                      ，
                      <span class="highlight" @click="gotoPath(0, '', 1)">{{
                        $t("view betting tasks")
                      }}</span>
                    </span>
                  </span>
                  <span class="arrow"></span>
                </div>
              </div>
            </el-form>
            <div class="buttonBox">
              <!-- <van-button class="normal-button">
                    <template>
                      <span>Ganhar juros</span>
                      <div class="leftico">
                        <p class="tool-tips-box">Anual15%</p>
                        <p class="tool-tips-tail"></p>
                      </div>
                    </template>
                  </van-button> -->
              <van-button class="highlight-button" @click="submitW">{{
                $t("confirm withdrawal")
              }}</van-button>
            </div>
          </article>
        </div>
      </section>
    </section>
    <myDialog
      :open.sync="showTipsBox"
      effect="error"
      Title="Lembrete importante"
      width="6rem"
    >
      <div class="TipsInfo">
        <div
          v-html="
            $t('withdrawal rules', { n1: formatNumberToString(flow_require) })
          "
        ></div>
        <!-- <span>De acordo com as regras relevantes,</span>
        <span style="color: var(--theme-text-color-darken); font-weight: 700"
          >No momento, você ainda precisa apostar
          {{ formatNumberToString(flow_require) }} para sacar dinheiro!</span
        > -->

        <div class="btnbox">
          <van-button class="normal-button" @click="gotoPath(0, '', 1)">{{
            $t("view tasks")
          }}</van-button>
          <van-button
            class="highlight-button"
            style="margin-left: 0.3rem"
            @click="gotoPath(1, '/subgame')"
            >{{ $t("go bet") }}</van-button
          >
        </div>
      </div>
    </myDialog>
  </div>
</template>
<script>
import { getWithdrawAccountInfo, withdrawPix } from "@/api/index";
import myNumberPassInput from "@/components/phone/common/myNumberPassInput.vue";
import myDialog from "@/components/phone/common/myDialog.vue";
import { mapState } from "vuex";

import {
  formatNumberToString2,
  truncateString,
  formatString,
} from "@/utils/utils";

export default {
  name: "cash",
  components: { myNumberPassInput, myDialog },
  mixins: [],

  data() {
    return {
      notify: {},

      coin: 0,
      showTipsBox: false,
      isAnimateAnimated: false,
      passvalue: "",
      flow_require: 0,
      cardAccountsInfo: [],
      default_index_account: 0,
      select_index: 0,
      cardTitle: "",
      showerr: false,
      cashForm: {
        num: 0,
      },
      rules: {
        num: [
          {
            required: true,
            message: this.$t("withdrawal amount empty"),
            trigger: "blur",
          },

          {
            validator: (rule, value, callback) => {
              if (value < 10) {
                callback(
                  new Error(
                    this.$t(
                      "the amount of the levy shall be equal to or greater than 10"
                    )
                  )
                );
              } else {
                callback();
              }
            },
          },
        ],
      },
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
      token: (state) => state.user.token,
    }),
  },
  watch: {
    user(u) {
      if (u) {
        // this.refreshUserInfo();
        this.showAD();
      }
    },
  },
  mounted() {
    this.getWithdrawAccountInfo();
    this.refreshUserInfo();
  },
  methods: {
    getWithdrawAccountInfo() {
      getWithdrawAccountInfo().then(({ data }) => {
        if (data.code === 0) {
          this.flow_require = data.data.flow_require;
          this.default_index_account = data.data.default_index_account;
          this.cardAccountsInfo = data.data.accounts;
          this.select_index = this.default_index_account;
          for (var i = 0; i < this.cardAccountsInfo.length; i++) {
            if (this.cardAccountsInfo[i].index === this.select_index) {
              const c = this.truncateString(this.cardAccountsInfo[i].pix_key);
              const t = this.cardAccountsInfo[i].pix_type;
              this.cardTitle = `PIX(${c}) ${t}`;
              break;
            }
          }
        }
      });
    },
    gotoPath(t, path, i) {
      if (t === 0) {
        if (i === 1) return;
        this.$router.push({
          path: "/saque",
          query: { current: i - 1 },
        });
      } else {
        this.$router.push({
          path: path,
        });
      }
    },
    truncateString(v) {
      return truncateString(v, 4);
    },
    formatNumberToString(v) {
      return formatNumberToString2(v);
    },
    cradChange(i) {
      this.select_index = this.cardAccountsInfo[i].index;
      const c = this.truncateString(this.cardAccountsInfo[i].pix_key);
      const t = this.cardAccountsInfo[i].pix_type;
      this.cardTitle = `PIX(${c}) ${t}`;
    },
    refreshUserInfo() {
      if (this.isAnimateAnimated) {
        return;
      }

      this.isAnimateAnimated = true;
      this.$store.dispatch("getUserInfo").then(() => {
        this.showAD();
      });
    },
    inputMoney(t) {
      // this.cashForm.num = t.replace(/\D/g, "");
    },
    submitW() {
      if (this.flow_require > 0) {
        this.showTipsBox = true;
      } else {
        if (this.cashForm.num <= 0) {
          if (this.notify && this.notify.close) {
            this.notify.close();
          }
          this.notify = this.$notify({
            duration: 2000,
            message: this.$t("input err"),
            type: "warning",
            background: "#fff",
            offset: "3",
            showClose: false,
          });
          return;
        }
        if (this.passvalue.length < 6) {
          this.showerr = true;
          return;
        }
        this.$refs.cashForm.validate((valid) => {
          if (valid) {
            withdrawPix({
              amount: this.cashForm.num,
              index_account: this.select_index,
              withdraw_password: this.passvalue,
            }).then(({ data }) => {
              if (data.code === 0 && data.data.success) {
                if (this.notify && this.notify.close) {
                  this.notify.close();
                }
                this.notify = this.$notify({
                  duration: 2000,
                  message: this.$t("request successful"),
                  type: "success",
                  background: "#fff",
                  offset: "3",
                  showClose: false,
                });
                this.refreshUserInfo();
              }
            });
          }
        });
      }
    },
    leaveInputPass(v) {
      if (v.length < 6 && v.length > 0) {
        this.showerr = true;
      }
    },
    inputChange(v) {
      if (v.length === 6) {
        this.showerr = false;
      }
    },
    showAD() {
      const self = this;
      if (self.user.balance) {
        const balance = self.user.balance;
        // const   bet=1000;
        let increment = Math.abs(balance - 0);
        let steps = Math.ceil(increment / 20);
        if (self.coin < balance) {
          const c = setInterval(function () {
            if (self.coin < balance) {
              self.coin += steps;
              // if (self.coin + 10000 < balance) {
              //   self.coin += 10000;
              // } else if (self.coin + 1000 < balance) {
              //   self.coin += 1000;
              // } else if (self.coin + 100 < balance) {
              //   self.coin += 100;
              // } else if (self.coin + 10 < balance) {
              //   self.coin += 10;
              // } else {
              //   self.coin = balance;
              // }
            } else {
              self.coin = balance;
              clearInterval(c);
            }
            self.coinStr = formatNumberToString2(self.coin);
          }, 100);
        } else {
          self.coin = balance;
          self.coinStr = formatNumberToString2(self.coin);
        }
      }

      self.isAnimateAnimated = false;
    },
  },
};
</script>
<style type="text/less" lang="less" scoped>
.top {
  display: -ms-flexbox;
  display: flex;
  height: 0.6rem;
  padding-right: 0.2rem;
  text-align: right;
  .left {
    -ms-flex-pack: justify;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    font-size: 0.22rem;
    justify-content: space-between;
    p {
      -ms-flex-align: center;
      align-items: center;
      line-height: 0.35rem;
      span {
        line-height: 0.35rem;
      }
    }
    .balance {
      -ms-flex-align: center;
      align-items: center;
      display: -ms-flexbox;
      display: flex;
      color: var(--theme-text-color-lighten);
      font-size: 0.24rem;
      margin-bottom: 0.15rem;
      .b {
        color: var(--theme-secondary-color-finance) !important;
        font-size: 0.24rem;
        font-weight: 700;
        margin-left: 0.05rem;
        margin-right: 0.1rem;
        position: relative;
        top: 0;
      }
    }
    .condition {
      span {
        color: var(--theme-text-color-lighten);
      }
      .bet {
        border-bottom: thin solid var(--theme-primary-color);
        color: var(--theme-primary-color);
      }
    }
  }
}
.top {
  -ms-flex-pack: justify;
  background-color: var(--theme-main-bg-color);
  height: auto;
  justify-content: space-between;
  padding: 0.2rem;
  width: 100%;
}
.bottom {
  background-color: var(--theme-bg-color);
  border-top: 0.01rem solid var(--theme-color-line);
  margin: 0;
  padding-bottom: 1rem;
  padding-top: 0;
  .main {
    max-width: 100%;
    position: relative;
    .common-tabs-content {
      .inputpanel {
        margin: 0.3rem auto 0;
        margin-top: 0;
        padding: 0.3rem 0.2rem 0;
        width: 100%;
        .bankcard {
          width: 100%;
          .selectbank {
            -ms-flex-align: center;
            align-items: center;
            display: -ms-flexbox;
            display: flex;
            .selectIpt {
              line-height: 0.7rem;
              width: 100%;
              //   background-color: var(--theme-main-bg-color);
              /deep/.el-input__inner {
                background-color: var(--theme-main-bg-color);
                border-radius: 0.14rem;
                font-size: 0.22rem;
                height: 0.7rem;
                line-height: 0.7rem;
                text-indent: 0.05rem;
                color: var(--theme-text-color-lighten);
                padding: 0;
                padding-left: 0.8rem;
              }
              /deep/.el-input__prefix {
                left: 0.2rem;
              }
              .ico {
                -ms-flex-align: center;
                align-items: center;
                display: -ms-flexbox;
                display: flex;
                height: 0.7rem;
                line-height: 0.7rem;
                width: 100%;
              }
            }
            .rightButton {
              color: var(--theme-primary-color);
              font-size: 0.45rem;
              margin-left: 0.2rem;
            }
          }
          .addBankCard {
            -ms-flex-align: center;
            align-items: center;
            border: thin solid var(--theme-color-line);
            -webkit-box-sizing: content-box;
            box-sizing: content-box;
            display: -ms-flexbox;
            display: flex;
            padding: 0 0.15rem;
            position: relative;
            background-color: var(--theme-main-bg-color);
            border-radius: 0.14rem;
            font-size: 0.22rem;
            height: 0.7rem;
            line-height: 0.7rem;
            margin-bottom: 0.5rem;
            text-indent: 0.05rem;
            span {
              color: var(--theme-text-color-placeholder);
              margin-left: 0.15rem;
            }
            .arrow {
              -ms-flex-align: center;
              align-items: center;
              color: var(--theme-color-line);
              display: -ms-flexbox;
              display: flex;
              position: absolute;
              right: 0.15rem;
              margin-left: 0.15rem;
              font-size: 0.4rem;
            }
          }
        }
        /deep/.is-error {
          .el-input__inner {
            border-color: var(--theme-secondary-color-error) !important;
          }
        }
        .moneyInput {
          .inputMoney {
            // background-color: var(--theme-main-bg-color);
            border-radius: 0.14rem;
            // font-size: 0.3rem;
            width: 100%;
            padding: 0;
            /deep/.el-input__prefix {
              -ms-flex-align: center;
              align-items: center;
              color: rgba(0, 0, 0, 0.65);
              display: -ms-flexbox;
              display: flex;
              line-height: 0;
              position: absolute;
              top: 50%;
              -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
              z-index: 2;
              left: 0.2rem;
            }
            /deep/.el-input__inner {
              border: 1px solid #d9d9d9;
              background-color: var(--theme-main-bg-color);
              border-radius: 0.14rem;
              border-color: var(--theme-color-line);
              height: 0.7rem;
              line-height: 0.7rem;
              text-indent: 0.05rem;
              padding: 0 0.6rem;
              color: var(--theme-text-color-darken);
              font-size: 0.3rem !important;
            }
            /deep/.el-input__inner::placeholder {
              font-size: 12px; /* 设置输入框中占位符的大小 */
              color: var(--theme-text-color-placeholder);
            }

            /deep/.el-input__inner[disabled] {
              cursor: not-allowed;
              opacity: 1;
              -webkit-text-fill-color: var(--theme-text-color-placeholder);
            }
            .label {
              color: var(--theme-text-color-darken);
              font-size: 0.18rem;
            }
          }
          .allcash {
            right: 0.2rem;
            -ms-flex-align: center;
            align-items: center;
            color: rgba(0, 0, 0, 0.65);
            display: -ms-flexbox;
            display: flex;
            line-height: 0;
            position: absolute;
            top: 50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            z-index: 2;
            font-size: 0.22rem;
            color: var(--theme-primary-color);
            line-height: 1.5;
          }
        }
        .bottom-margin {
          margin-bottom: 0.5rem;
        }
        .passBox {
          -ms-flex-pack: center;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-direction: column;
          flex-direction: column;
          justify-content: center;
          .top-line {
            border-top: 0.01rem solid var(--theme-color-line);
          }
          .tipsBox {
            margin-bottom: 0.12rem;
            position: relative;
            text-align: right;
            .tipscontext {
              background-color: var(--theme-main-bg-color);
              border: thin solid var(--theme-color-line);
              border-radius: 0.1rem;
              -webkit-box-shadow: 0 0.03rem 0.12rem 0 rgba(0, 0, 0, 0.12);
              box-shadow: 0 0.03rem 0.12rem 0 rgba(0, 0, 0, 0.12);
              color: var(--theme-text-color-lighten);
              display: inline-block;
              font-size: 0.22rem;
              padding: 0.06rem 0.18rem;
              text-align: center;
              .money {
                color: var(--theme-primary-color);
                text-decoration: underline;
              }
              .highlight {
                color: var(--theme-primary-color);
              }
            }
            .arrow {
              background: var(--theme-main-bg-color);
              border-color: var(--theme-color-line);
              border-style: solid;
              border-width: 0 0.01rem 0.01rem 0;
              bottom: -0.05rem;
              -webkit-box-shadow: 0.03rem 0.03rem 0.07rem rgba(0, 0, 0, 0.07);
              box-shadow: 0.03rem 0.03rem 0.07rem rgba(0, 0, 0, 0.07);
              height: 0.1rem;
              position: absolute;
              right: 0.5rem;
              -webkit-transform: translateX(-50%) rotate(45deg);
              transform: translateX(-50%) rotate(45deg);
              width: 0.1rem;
            }
          }
        }
      }
    }
  }
}
.buttonBox {
  display: -ms-flexbox;
  display: flex;
  .normal-button {
    margin-right: 0.2rem;
    --cu-top: -0.12rem;
    --cu-right: unset;
    --cu-left: 0%;
    span {
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      overflow: hidden;
      text-align: center;
      text-overflow: ellipsis;
      vertical-align: middle;
      white-space: normal;
      width: 100%;
      line-height: 1.1;
      transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
    .leftico {
      height: 0.32rem;
      left: var(--cu-left);
      position: absolute;
      right: var(--cu-right);
      top: var(--cu-top);
      width: fit-content;
      z-index: 100;
      p {
        font-size: 0.15rem !important;
        max-width: 3rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .tool-tips-box {
        background-color: var(--theme-secondary-color-error);
        border-radius: 0.125rem 0.125rem 0.125rem 0px;
        color: rgb(255, 255, 255);
        font-size: 0.14rem;
        height: 0.22rem;
        line-height: 0.22rem;
        padding: 0px 0.08rem;
        width: 100%;
        font-size: 0.18rem;
        height: 0.26rem;
        line-height: 0.26rem;
        padding: 0px 0.07rem;
      }
      .tool-tips-tail {
        border-bottom: 0.07rem solid transparent;
        border-left: 0.07rem solid var(--theme-secondary-color-error);
        border-right: 0.07rem solid transparent;
        height: 0px;
        width: 0px;
      }
    }
  }
  .highlight-button {
    width: 1.8rem;
  }
}
.TipsInfo {
  color: var(--theme-text-color);
  font-size: 0.22rem;
  font-weight: 400;
  padding: 0.2rem;
  .btnbox {
    display: -ms-flexbox;
    display: flex;
    margin-top: 0.38rem;
  }
}
.err-explain {
  margin-top: 0;
}
</style>
