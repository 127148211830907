<template>
  <section class="main">
    <section class="box">
      <dragScroll>
        <div class="head-container">
          <mySelect
            :items="dateList"
            class="myselect"
            :selectItem.sync="dateselectItem"
            @change="itemChange"
          ></mySelect>
          <ul class="otherselect">
            <li>
              <mySelect
                :items="categoryList"
                class="myselect"
                :selectItem.sync="categoryselectItem"
                @change="categoryChange"
                :filterable="true"
              ></mySelect>
            </li>
            <li>
              <mySelect
                :items="vendorList"
                class="myselect"
                :selectItem.sync="vendorselectItem"
                @change="vendorChange"
                :filterable="true"
              ></mySelect>
            </li>

            <li>
              <mySelect
                :items="gameList"
                class="myselect"
                :selectItem.sync="gameselectItem"
                @change="gameChange"
                :filterable="true"
              ></mySelect>
            </li>
          </ul>
        </div>
      </dragScroll>

      <div class="vantDataList">
        <van-empty
          v-if="list.length < 1"
          :image="require('@/assets/azf5f-28vaj.png')"
          :description="$t('no records')"
        />
        <van-list
          v-model="loading"
          :finished="finished"
          @load="onLoad"
          offset="100"
          :loading-text="$t('loading')"
          v-else
        >
          <li class="sticky" v-for="(item, index) in list">
            <p class="top">
              <span class="gameTitle">
                {{ item.vendor_code }}&nbsp;
                {{ item.vendor_category_code }}/
                {{ item.game_code }}
              </span>
              <span class="scoreBox">
                <span class="validBets"
                  ><span>{{ $t('valid bets') }}</span>
                  <span
                    >&nbsp;<span style="color: var(--theme-text-color)"
                      >R$ {{ formatNumberToString(item.bet_amount) }}</span
                    ></span
                  >
                </span>
                <span class="gainsLosses">
                  <span>{{ $t('wins and Losses') }}</span>
                  <span>&nbsp;
                    <span class="isRed" v-if="item.win_loss>=0">+R$ {{ formatNumberToString(item.win_loss) }}</span>
                    <span class="isGreen" v-else>-R$ {{ formatNumberToString(-item.win_loss) }}</span>
                  </span>
                </span>
              </span>
            </p>
            <p class="bottom">
              <span class="date"><span>{{ formatDateTimeVirar(item.timestamp) }}</span></span>
              <span class="noteNumber">
                <span class="number"
                  ><span>{{ $t('note number') }}</span>&nbsp;
                  <span>{{item.bet_id}}&nbsp;</span></span
                >
                <span class="copyIcon" v-copy="item.bet_id">
                  <svg-icon
                    icon-class="comm_icon_copy"
                    style="width: 1em; height: 1em; fill: currentColor"
                  ></svg-icon>
                </span>
              </span>
            </p>
          </li>
        </van-list>
      </div>
    </section>
  </section>
</template>
<script>
import mySelect from "@/components/phone/common/mySelect.vue";
import dragScroll from "@/components/phone/common/dragScroll.vue";
import {
  getLogsBet,
  gameCategoryList,
  getVendorByCategory,
  getAllVendorGamesByCategory,
} from "@/api/index";
import { formatDateTimeVirar,formatNumberToString2 } from "@/utils/utils";

export default {
  name: "betting",
  components: { mySelect, dragScroll },
  mixins: [],
  data() {
    return {
      list: [],
      dateselectItem: "today",
      categoryselectItem: "all",
      vendorselectItem: "all",
      gameselectItem: "all",
      loading: false,
      pageIndex: 0,
      pageSize: 50,
      finished: false,
      dateList: [
      { label: this.$t("today"), value: "today" },
        { label: this.$t("yesterday"), value: "yesterday" },
        { label: this.$t("Last 7 Days"), value: "last_week" },
        { label: this.$t("Last 15 Days"), value: "last_half_month" },
        { label: this.$t("Last 30 Days"), value: "last_month" },
      ],
      categoryList: [{ label:this.$t("all types"), value: "all" }],
      vendorList: [{ label: this.$t('all platforms'), value: "all" }],
      gameList: [{ label: this.$t("all games"), value: "all" }],
    };
  },
  computed: {},
  watch: {},
  activated() {
    this.pageIndex = 0;
  },
  mounted() {
    // this.getList();
    gameCategoryList().then(({ data }) => {
      let list = data.data.game_category_list;
      list.forEach((item) => {
        this.categoryList.push({ label: item, value: item });
      });
    });
  },
  methods: {
    itemChange(v) {
      this.pageIndex = 0;
      this.finished = false;
      this.list = [];
      this.getList();
    },
    getList() {
      getLogsBet({
        time_rand: this.dateselectItem,
        category:
          this.categoryselectItem === "all" ? "" : this.categoryselectItem,
        vendor: this.vendorselectItem === "all" ? "" : this.vendorselectItem,
        game_code: this.gameselectItem === "all" ? "" : this.gameselectItem,
        page: this.pageIndex,
        count: this.pageSize,
      }).then(({ data }) => {
        if (data.code === 0) {
          if (data.data.logs.length > 0) {
            this.list = this.list.concat(data.data.logs);
          }
          if (data.data.logs.length < this.pageSize) {
            this.finished = true;
          }
          this.loading = false;
        }
      });
    },
    formatDateTimeVirar(v) {
      return formatDateTimeVirar(new Date(v*1000));
    },
    formatNumberToString(v){return formatNumberToString2(v)},
    onLoad() {
      this.pageIndex = this.pageIndex + 1;
      this.getList();
    },
    categoryChange(name) {
      this.vendorList = [{ label: this.$t('all platforms'), value: "all" }];
      this.gameList = [{ label:  this.$t("all games"), value: "all" }];
      this.vendorselectItem = "all";
      this.gameselectItem = "all";
      if (name !== "all") {
        getVendorByCategory({ category: name }).then(({ data }) => {

          let list = data.data.vendor_list;
          list.forEach((item) => {
            this.vendorList.push({ label: item.name, value: item.code });
          });
        });
      }
      this.list=[];
      this.getList();
    },
    vendorChange(item) {
      this.gameList = [{ label:  this.$t("all games"), value: "all" }];
      this.gameselectItem = "all";
      if (item !== "all") {

        getAllVendorGamesByCategory({
          category:
            this.categoryselectItem === "all" ? "" : this.categoryselectItem,
          vendor: this.vendorselectItem === "all" ? "" : this.vendorselectItem,
          page: 0,
          count: 500,
        }).then(({ data }) => {

          let list = data.data.game_list;
          list.forEach((item) => {
            this.gameList.push({
              label: item.show_name,
              value: item.game_code,
            });
          });
        });
      }
      this.list=[];
      this.getList();
    },
    gameChange() {
      this.list=[];
      this.getList();
    },
  },
};
</script>
<style type="text/less" lang="less" scoped>
.main {
  padding: 0;
  .box {
    padding: 0.2rem 0;
  
  }
  .head-container {
    margin-bottom: 0.2rem !important;
    -ms-flex-pack: start;
    display: -ms-flexbox;
    display: flex;
    justify-content: flex-start;

    position: relative;

    padding-left: 0.2rem;
    padding-right: 0.2rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    .myselect {
      max-width: 3.15rem;
      min-width: 1.6rem;
      width: auto !important;
      margin-right: 0.2rem;
    }
    .otherselect {
      display: -ms-flexbox;
      display: flex;
      height: 0.5rem;
      margin: 0;
      li {
        margin-right: 0.2rem;
      }
    }
  }
  .vantDataList {
    -ms-flex-pack: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;

    height: calc(var(--vh, 1vh) * 100 - 2.55rem);
    justify-content: center;
    overflow: hidden;
    position: relative;

    .sticky {
      -ms-flex-pack: justify;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      font-size: 0.2rem;
      height: 1.1rem;
      justify-content: space-between;
      padding: 0.2rem;
      padding: 0.18rem 0.1rem !important;
      color: var(--theme-text-color-lighten);
      .top {
        -ms-flex-align: center;
        align-items: center;
        display: -ms-flexbox;
        display: flex;
        .gameTitle {
          margin-right: 0.1rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 2.5rem;
        }
        .scoreBox {
          -ms-flex-align: center;
          align-items: center;
          display: -ms-flexbox;
          display: flex;
          -ms-flex: 1;
          flex: 1;
          .validBets {
            display: inherit;
            width: 2.2rem;
          }
          .validBets > span {
            display: inline-block;
            vertical-align: middle;
            white-space: nowrap;
          }
          .validBets > span:first-child {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          // .validBets > span:nth-child(2) {
          //   -ms-flex-pack: end;
          //   display: -ms-flexbox;
          //   display: flex;
          //   justify-content: flex-end;
          //   margin-left: 0.1rem;
          //   white-space: nowrap;
          //   width: 1.9rem;
          // }

          .gainsLosses {
            -ms-flex-pack: end;
            display: -ms-flexbox;
            display: flex;
            justify-content: flex-end;
            margin-left: 0.1rem;
            white-space: nowrap;
            width: 1.9rem;
          }
          .gainsLosses > span {
            display: inline-block;
            vertical-align: middle;
            white-space: nowrap;
          }
          .gainsLosses > span:first-child {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
      .bottom {
        -ms-flex-align: center;
        word-wrap: break-word;
        align-items: center;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        margin-top: 0.12rem;
        word-break: keep-all;
        .date {
          margin-right: 0.1rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          width: 2.5rem;
        }
        .noteNumber {
          -ms-flex-pack: justify;
          display: -ms-flexbox;
          display: flex;
          -ms-flex: 1;
          flex: 1;
          justify-content: space-between;
          .number {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 3.86rem;
          }
        }
      }
    }
  }
}
</style>
