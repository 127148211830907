// import Cookies from 'js-cookie'
// import Config from '@/settings'

const TokenKey = 'token'
const RefreshTokenKey = 'refreshtoken'
const ACKey='ac'
export function getToken() {
  return localStorage.getItem(TokenKey) 
  // return Cookies.get(TokenKey)
}

export function setToken(token) {
  return localStorage.setItem(TokenKey, token)
}

export function removeToken() {
  return localStorage.removeItem(TokenKey)
}
export function getAC() {
  return localStorage.getItem(ACKey) 
  // return Cookies.get(TokenKey)
}

export function setAC(ac) {
  return localStorage.setItem(ACKey, ac)
}

export function removeAC() {
  return localStorage.removeItem(ACKey)
}

export function getRefreshToken(){
  return localStorage.getItem(RefreshTokenKey) 
}
export function removeRefreshToken() {
  return localStorage.removeItem(RefreshTokenKey)
}

export function setRefreshToken(token) {
  return localStorage.setItem(RefreshTokenKey, token)
}