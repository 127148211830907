<template>
    <div class="contentBox" style="margin: 0 auto;">
      <div
        class="gameList"
        v-if="displayedItems.length > 0"
        style="overflow: hidden"
      >
        <div
          style="width:33.33333%"
          v-for="(item2, index2) in displayedItems"
          :key="index2"
        >
          <div class="gameBox" @click="toEnterGame(item2)">
            <div class="gameitem">

              <van-image
                :src="item2.image_url"
               :lazy-load="true"
                width="var( --advertisement-box-width)"
                height="var(--advertisement-box-height)"
                fit="fill"
              >
                <template v-slot:loading>
                  <svg-icon
                    icon-class="img777"
                    style="
                      width: 1rem;
                      height: 1rem;
                      font-size: 0.03rem;
                      fill: white;
                    "
                  ></svg-icon>
                </template>
                <template v-slot:error>
                  <svg-icon
                    icon-class="img777"
                    style="
                      width: 1rem;
                      height: 1rem;
                      font-size: 0.03rem;
                      fill: white;
                    "
                  ></svg-icon>
                </template>
              </van-image>
        
              <!-- <div class="gameTitle">{{ item2.show_name }}</div> -->
              <!-- <img class="sc" src="../../assets/btn_sc_off_2.png" /> -->
              <div class="fault" v-if="!item2.server">
              <van-image
          
                :src="require('@/assets/img_dt_whz.png')"
                fit="fill"
                style="
                  height: var(--card-benchmark);
                  left: 0;
                  position: absolute;
                  top: 0;
                  width: var(--card-benchmark);
                "
              >
              </van-image>
            </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="hasMoreItems" @click="loadMore" class="more">
        {{ $t("displayingopulargames",{num:displayCount,size:items.length}) }}
        <div class="scroll-text">
          {{ $t("expand more") }}
          <i
            style="display: inline-flex;
              justify-content: center;
              align-items: center;font-size:.14rem;">
            <svg-icon
              icon-class="comm_icon_jzgd"
              focusable="false"
              aria-hidden="true"
              style="width: 1em; height: 1em"
            ></svg-icon>
          </i>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import SvgIcon from "@/components/SvgIcon";
  
  export default {
    name: "classBatchDisplay",
    props: {
      items: {
        type: Array,
        required: true,
      },
  
    },
    data() {
      return {
        displayCount: 0, // 当前显示的数据数量
        isExpanded: false, // 是否已经展开
      };
    },
    computed: {
      displayedItems() {
        // console.log(this.items[0]);
        return this.items.slice(0, this.displayCount);
      },
      hasMoreItems() {
        return this.displayCount < this.items.length;
      },
    },
    methods: {
      loadMore() {
        this.displayCount += 6; // 每次点击展开按钮，显示的数据数量增加6
        if (this.displayCount >= this.items.length) {
          this.isExpanded = true; // 如果所有数据都已展开，将状态设置为已展开
        }
      },
      collapse() {
        this.displayCount = 6; // 点击折叠按钮，显示的数据数量变为6
        this.isExpanded = false; // 将状态设置为未展开
      },
      toEnterGame(item) {
        if(!item.server){
        this.$notify({
              duration: 2000,
              message: this.$t('the game stops serving'),
              type: "warning",
              background: "#fff",
              offset: "3",
            });
            return;
      }
        this.$emit("child-clicked",item);
      },
    },
    created() {
      this.displayCount = 6; // 初始显示的数据数量为6
    },
  };
  </script>
  <style type="text/less" lang="less" scoped>
  .contentBox {
    --advertisement-box-height: 2.792rem;
    --advertisement-box-width: 2.1rem;
    --cardRowSpacing: 0.15rem;
    --cardColSpacing: 0;
    --card-benchmark: 2.1rem;
    position: relative;
    // padding-bottom: 10vw;
    font-size: 0.34rem;
    width: 6.9rem;
    // padding: 0.2rem;
    p {
      cursor: pointer;
    }
  
    .gameList {
      display: flex;
      align-items: start;
      flex-wrap: wrap;
      transition: 1s;
    }
  
    .gameBox {
      --favorited-icon-top: calc(var(--card-benchmark)*0.033);
    --favorited-icon-right: calc(var(--card-benchmark)*0.033);
    --favorited-icon-bottom: initial;
    --favorited-icon-left: initial;
    --click-arean-expand-size: -0.06rem;
    --favorited-icon-side: calc(var(--card-benchmark)*0.206);
      width: var(--advertisement-box-width);
      height: var(--advertisement-box-height);
      margin: var(--cardRowSpacing) var(--cardColSpacing);
      position: relative;
      border-radius: var(--advertisement-box-border-radius);      overflow: hidden;
      .gameitem {
        -ms-flex-align: center;
        -ms-flex-pack: end;
  
        -ms-flex-align: center;
        -ms-flex-pack: center;
        align-items: center;
        background-color: var(--theme-game-card-ddt_bg);
        background-image: initial;
        border-radius: 0;
        color: var(--theme-game-card-ddt_icon);
        display: -ms-flexbox;
  
        height: 100%;
        justify-content: center;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        border-radius:0.2rem;
        .fault {
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 0;
        bottom: 0;
        cursor: not-allowed;
        left: 0;
        opacity: 1;
        position: absolute;
        right: 0;
        text-align: center;
        top: 0;
        -webkit-transition: opacity 0.6s;
        transition: opacity 0.6s;
        z-index: 9;
        border-radius:0.2rem;
      }
        .van-image {
          /deep/ .van-image__loading,
          /deep/ .van-image__error {
            background-color: transparent;
          }
        }
      }


      .sc {
        position: absolute;
      top: var(--favorited-icon-top);
      right: var(--favorited-icon-right);
            left: var(--favorited-icon-left);
      width: var(--favorited-icon-side);
      height: var(--favorited-icon-side);
      z-index: 4;
      }
    }
  
    .gameBox:nth-child(3n) {
      margin-right: 0vw;
    }
  
    .more {
      display: block;
      font-size: 0.22rem;
      text-align: center;
  
      -ms-flex-align: center;
      -ms-flex-pack: center;
      align-items: center;
      color: var(--theme-text-color-lighten);
      justify-content: center;
      margin-top: 0.3rem;
      .scroll-text {
        color: var(--theme-text-color);
        display: block;
        font-size: 0.22rem;
        margin-top: 0.02rem;
        margin-left: 0.08rem;
        cursor: pointer;
      }
    }
  }
  </style>
  