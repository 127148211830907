import Vue from "vue";
import { Message } from "element-ui";
import 'element-ui/lib/theme-chalk/index.css';

Vue.directive("copy", {
    bind(el, { value }) {
        el.$value = value;
        el.handler = () => {
            el.style.position = 'relative';
            if (!el.$value) {
                Message({
                    message: 'copy none',
                    type: 'warning'
                })
                return
            }

            const textarea = document.createElement('textarea');

            textarea.readOnly = 'readonly';
            textarea.style.position = 'absolute';
            textarea.style.top = '0px';
            textarea.style.left = '-9999px';
            textarea.style.zIndex = '-9999';

            textarea.value = el.$value

            el.appendChild(textarea);
            textarea.select()
            const result = document.execCommand('Copy');
            if (result) {
                Message({
                    message: 'success',
                    type: 'success'
                })
            };
            el.removeChild(textarea);
        }
        el.addEventListener('click', el.handler); 
    },

    componentUpdated(el, { value }) {
        el.$value = value;
    },

    unbind(el) {
        el.removeEventListener('click', el.handler);
    },
});

