<template>
  <div class="box">
    <div class="pass-security">
      <span>{{ $t('strength') }}</span>
      <span
        class="item"
        :class="{
          isbred: securityLevel === 1,
          isbOrange: securityLevel === 2,
          isbH: securityLevel === 3,
          isbGreen: securityLevel === 4,
        }"
      ></span>
      <span
        class="item"
        :class="{
          isbOrange: securityLevel === 2,
          isbH: securityLevel === 3,
          isbGreen: securityLevel === 4,
        }"
      ></span>
      <span
        class="item"
        :class="{ isbH: securityLevel === 3, isbGreen: securityLevel === 4 }"
      ></span>
      <span class="item" :class="{ isbGreen: securityLevel === 4 }"></span>
    </div>
  </div>
</template>
<script>
export default {
  name: "passwordSecurityLevel",
  components: {},
  mixins: [],
  props: {
    passValue: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      securityLevel: 0,
    };
  },
  computed: {},
  watch: {
    passValue(v) {
      this.securityLevel = this.evaluatePasswordStrength(v);
    //   console.log(this.securityLevel);
    },
  },
  mounted() {},
  methods: {
    evaluatePasswordStrength(password) {
      if (!password) {
        return 0; // 空密码返回0级
      }

      let level = 1; // 默认等级为1级
      const length = password.length;

      const hasLowerCase = /[a-z]/.test(password);
      const hasUpperCase = /[A-Z]/.test(password);
      const hasDigit = /\d/.test(password);
      const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

      const typeCount = [
        hasLowerCase,
        hasUpperCase,
        hasDigit,
        hasSpecialChar,
      ].filter(Boolean).length;

      if (length >= 8 && typeCount >= 2) {
        level = 2;
      }

      if (length >= 12 && typeCount >= 3) {
        level = 3;
      }

      if (length >= 16 && typeCount === 4) {
        level = 4;
      }

      return level;
    },
  },
};
</script>
<style type="text/less" lang="less" scoped>
.isbred {
  background-color: var(--theme-secondary-color-error) !important;
}
.isbOrange {
  background-color: var(--theme-secondary-color-finance) !important;
}
.isbH {
  background-color: var(--theme-primary-color) !important;
}
.isbGreen {
  background-color: var(--theme-secondary-color-success) !important;
}
.box {
  .pass-security {
    -ms-flex-align: center;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 0.12rem;
    margin-bottom: 0.2rem;
    span {
      color: var(--theme-text-color-darken);
      font-size: 0.22rem;
      margin-right: 0.1rem;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
    }
    .item {
      background-color: var(--theme-color-line);
      border-radius: 0.12rem;
      height: 0.12rem;
      width: 0.8rem;
    }
  }
}
</style>
