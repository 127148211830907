<template>
    <div class="box">
        <div class="todayState">
            <div>{{ $t('No winning guess yet today') }}</div>
        </div>
        <div class="table">
            <div class="num">
                <div>{{ $t('Number of guesses') }}</div>
                <div>{{ loseNum }} {{ $t('frequency') }}</div>
                <div>（{{ extra }} {{ $t('Remaining') }}）</div>
            </div>
            <div>
                <el-table :data="tableData" :stripe="true" class="customer-table">
                    <el-table-column prop="spending" :label="$t('effective spending')">
                    </el-table-column>
                    <el-table-column prop="frequency" :label="$t('Max limit complained')">
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <div class="table">
            <el-table :data="tableData2" :stripe="true" class="customer-table">
                <el-table-column prop="prize" :label="$t('Final guess prize')">
                </el-table-column>
                <el-table-column prop="bonus" :label="$t('fixed bonus')">
                </el-table-column>
            </el-table>
        </div>
        <div class="taskRule">
            <div>1.Depósitos diários ou apostas válidas que cumpram as condições do evento podem ser computados para o
                registro diário. Registros diários bem-sucedidos podem receber bônus fixos correspondentes ou bônus
                adicionais, podendo chegar a 7;<br />
                1.Depósitos diários ou apostas válidas que cumpram as condições do evento podem ser computados para o
                registro diário. Registros diários bem-sucedidos podem receber bônus fixos correspondentes ou bônus
                adicionais, podendo chegar a 7;<br />
                1.Depósitos diários ou apostas válidas que cumpram as condições do evento podem ser computados para o
                registro diário. Registros diários bem-sucedidos podem receber bônus fixos correspondentes ou bônus
                adicionais, podendo chegar a 7;<br />
                1.Depósitos diários ou apostas válidas que cumpram as condições do evento podem ser computados para o
                registro diário. Registros diários bem-sucedidos podem receber bônus fixos correspondentes ou bônus
                adicionais, podendo chegar a 7;<br />
                1.Depósitos diários ou apostas válidas que cumpram as condições do evento podem ser computados para o
                registro diário. Registros diários bem-sucedidos podem receber bônus fixos correspondentes ou bônus
                adicionais, podendo chegar a 7;<br />
                1.Depósitos diários ou apostas válidas que cumpram as condições do evento podem ser computados para o
                registro diário. Registros diários bem-sucedidos podem receber bônus fixos correspondentes ou bônus
                adicionais, podendo chegar a 7;<br /></div>
        </div>
    </div>
</template>
<script>
export default {
    name: '',
    components: {

    },
    mixins: [],
    props: {

    },
    data() {
        return {
            tableData: [
                { spending: '≥100', frequency: '1' },
                { spending: '≥1,000.00', frequency: '2' },
                { spending: '≥10,000.00', frequency: '3' },
                { spending: '≥50,000.00', frequency: '4' },
                { spending: '≥100,000.00', frequency: '5' },
            ],
            tableData2: [
                { prize: '7', bonus: '1.00' },
                { prize: '777', bonus: '5.00' },
                { prize: '7777', bonus: '10.00' },
                { prize: '77777', bonus: '55.00' },
                { prize: '777777', bonus: '200.00' },
                { prize: '7777777', bonus: '300.00' },
                { prize: '77777777', bonus: '555.00' },
            ],
            loseNum: 0.00,
            extra: 0.00
        }
    },
    computed: {

    },
    watch: {

    },
    mounted() {

    },
    methods: {

    }
};
</script>
<style type="text/less" lang="less" scoped>
.box {
    overflow: hidden;
    background: #6E8DE6;
    padding: 10vw 3vw 0;
}

.taskRule {
    background: #6E8DE6;
    border-radius: 0.3vw;
    color: #D5DDFF;
    padding: 1vw 0;

    div {
        padding: 3vw;
        background: #5375D1;
        border-radius: 1vw;
    }
}

.tableList {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.todayState {
    background: #5375D1;
    border-radius: 1vw;
    color: #D5DDFF;
    padding: 3vw 1vw;
    text-align: center;
    margin: 3vw 0;
}

.table {
    background: #5375D1;
    margin-top: 1vw;
    border-radius: 1vw;
    padding: 3vw;
    text-align: center;
    margin-bottom: 3vw;
    .num {
        display: flex;
        margin-bottom: 2vw;
        justify-content: center;
        align-items: center;
        div {
            margin-right: 0.3vw;
            color: #D5DDFF;
        }
    }
}

/deep/.el-table__body,
/deep/.el-table__header {
    width: 100% !important;
}

/deep/.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
    background: #6E8DE6;
}

/deep/.el-table tr {
    background: #5375D1;
}

/deep/.el-table td.el-table__cell,
/deep/.el-table th.el-table__cell.is-leaf {
    border: none;
}

/deep/.el-table--border::after,
/deep/.el-table--group::after {
    width: 0;
}

/deep/.el-table th.el-table__cell {
    background: #6E8DE6;
}

/deep/.el-table tbody tr:hover>td {
    background-color: transparent !important;
}

/deep/.has-gutter {
    width: 100%;
    color: #fff;
}

/deep/.el-table tbody .el-table_1_column_2 {
    color: #FFAA09;
}

.customer-table {
    color: #D5DDFF;
}

.customer-table::before {
    width: 0;
}

.customer-table .el-table__fixed-right::before,
.el-table__fixed::before {
    width: 0;
}

/deep/.el-table .el-table__cell {
    text-align: center;
}
</style>