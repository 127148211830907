<template>
  <div class="noListBox">
    <div >
        <van-empty
     
      :image="require('@/assets/img_none_sj.png')"
      description="Sem Mensagem"
    />
    </div>
  </div>
</template>
<script>
export default {
  name: "",
  components: {},
  mixins: [],
  props: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {},
};
</script>
<style type="text/less" lang="less" scoped>
.noListBox {
  max-height: calc(100vh - 2rem);
  max-height: calc(var(--vh, 1vh) * 100 - 2rem);
  min-height: 6.4rem;
  overflow: auto;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  background-color: var(--theme-bg-color);
  height: auto;
}
</style>
