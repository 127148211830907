<template>
  <div class="wrap">
    <van-row style="margin-bottom: 0.2rem" justify="space-between" type="flex">
      <div class="top">
        <van-button
          :type="active === 0 ? 'primary' : 'default'"
          @click="active = 0"
          >{{ $t('create feedback') }}</van-button
        >
        <van-button
          :type="active === 1 ? 'primary' : 'default'"
          @click="active = 1"
          >{{ $t('my feedback') }}</van-button
        >
      </div>
      <div class="right" v-if="active === 1">
        <div class="untreated">
          <p style="font-size: 0.24rem; color: var(--theme-text-color-lighten)">
            {{ $t('pending') }}
          </p>
          <p class="describe">
            <span>R$ 0,00</span>
          </p>
        </div>
        <van-button class="processed" :disabled="true">{{ $t('rescue all') }}</van-button>
      </div>
    </van-row>
    <div v-if="active === 0">
      <div
        class="feedback"
        ref="feedback"
        :style="{ height: feedbackHigeht + 'px' }"
      >
        <div class="worktop">
          <van-form>
            <van-row>
              <van-col span="24">
                <div class="item">
                  <label
                    >{{ $t('feedback Content') }}<span
                      >{{ $t('make suggestions for improvements') }}</span
                    ></label
                  >
                  <textarea
                    :placeholder="$t('feedbacktip')"
                    maxlength="200"
                    class="input"
                    rows="5"
                    v-model="proposal"
                  ></textarea>
                </div>
              </van-col>
            </van-row>
            <div class="Word-count-record">{{ proposal.length }}/200</div>

            <van-row>
              <van-col>
                <div class="imgitem">
                  <label
                    >{{ $t("Pictures don t lie") }}<span
                      >{{ $t('Easier to accept') }}</span
                    ></label
                  >

                  <van-uploader>
                    <div class="upload">
                      <span class="vertical"></span>
                      <span class="transverse"></span>
                    </div>
                  </van-uploader>
                  <span class="imgtips"
                    >{{ $t('feedbacktip2') }}</span
                  >
                </div>
              </van-col>
            </van-row>
          </van-form>
          <div class="explain">
            <label>{{ $t('rules for rewards') }}</label>
            <div class="text">
             {{ $t('feedbacktip3') }}
            </div>
          </div>
        </div>
      </div>
      <div class="submitBtn" ref="feedbackFooter">
        <div class="verification">
          <van-button
            size="large"
            color="var(--theme-primary-font-color)"
            :block="true"
            :hairline="true"
            :plain="true"
            :border="false"
            type="primary"
            style="
              font-size: 0.24rem;
              height: 0.7rem;
              margin-right: 0 !important;
              width: 7.1rem !important;
              border-radius: 0.14rem;
              background-color: var(--theme-primary-color);
              border-color: var(--theme-primary-color);
            "
            >{{ $t('send feedback') }}</van-button
          >
        </div>
      </div>
    </div>
    <div v-else>
      <div class="feedbackList">
        <div class="feedBackBox">
          <van-empty
            :image="require('@/assets/img_none_sj.png')"
            description="Sem feedback"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      active: 0,
      type: 1,
      textarea: "",
      proposal: "",
      feedbackHigeht: 0,
    };
  },
  computed: {},
  watch: {},
  mounted() {
    setTimeout(() => {
      const feedback = this.$refs.feedback;
      const feedbackFooter = this.$refs.feedbackFooter;
      const s = feedback.getBoundingClientRect().top;
      const i = window.innerHeight - s - feedbackFooter.clientHeight;
      this.feedbackHigeht = i;
    }, 1e3);
  },
  methods: {},
};
</script>
<style type="text/less" lang="less" scoped>
.wrap {
  background: var(--theme-bg-color);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  font-size: 0.24rem;
  min-height: 6.4rem;
  overflow: auto;
  padding: 0.2rem 0.2rem 0;
  .top {
    display: flex;
    button {
      border-radius: 0.25rem;
      display: -ms-flexbox;
      display: flex;
      margin-right: 0.2rem;
    }
    button {
      -ms-flex-align: center;
      -ms-flex-pack: center;
      align-items: center;
      height: 0.5rem;
      justify-content: center;
      line-height: 0.24rem;
      width: auto;
      width: 1.5rem;
    }
    /deep/.van-button__text {
      word-wrap: break-word;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      vertical-align: middle;
      display: inline-block;
      pointer-events: none;
      -webkit-transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      white-space: pre-wrap;
      display: block;
      font-size: 0.24rem;
    }
    /deep/.van-button--default {
      background-color: var(--theme-main-bg-color);
      border-color: var(--theme-color-line);
      color: var(--theme-text-color);
    }
    /deep/.van-button--primary {
      background-color: var(--theme-primary-color);
      border-color: var(--theme-primary-color);
      color: var(--theme-primary-font-color);
      text-shadow: none;
    }
  }
  .right {
    -ms-flex-align: center;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    .untreated {
      line-height: 1;
      text-align: center;
      max-width: 1.9rem;
      .describe {
        span {
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          display: -webkit-box;
          line-height: 1.2;
          //   overflow: hidden;
          overflow: initial;
          text-align: center;
          text-overflow: ellipsis;
          vertical-align: middle;
          word-break: break-word;
          font-size: 0.24rem;
          color: var(--theme-secondary-color-finance);
          vertical-align: middle;
          display: inline-block;
          line-height: 0;
          //   overflow: hidden;
        }
      }
    }
    .processed {
        border: 1px solid #d9d9d9;
      background-color: var(--theme-disabled-bg-color);
      border-color: var(--theme-disabled-bg-color);
      color: var(--theme-disabled-font-color);
      -ms-flex-align: center;
      align-items: center;
      display: -ms-flexbox;
      margin-left: .2rem;
      height: 0.5rem;
      line-height: 0.24rem;
      width: 1.2rem;
      border-radius: .14rem;
      span {
      
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: block;
        display: -webkit-box;
        font-size: 0.16rem;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: middle;
        white-space: pre-wrap;
        font-size: 0.2rem;
        color: var(--theme-disabled-font-color);
      }
    }
  }
  .feedback {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 0;
    overflow: auto;
    padding-bottom: 0.2rem;
    .worktop {
      background-color: var(--theme-main-bg-color);
      border-radius: 0.14rem;
      -webkit-box-shadow: 0 0.03rem 0.1rem 0 var(--theme-aside-box-shadow);
      box-shadow: 0 0.03rem 0.1rem 0 var(--theme-aside-box-shadow);
      -ms-flex: 1;
      flex: 1;
      overflow-y: auto;
      padding: 0.2rem 0.2rem 0.1rem;
      .item {
        position: relative;
        label {
          position: relative;
          color: var(--theme-text-color-darken);
          display: block;
          margin-bottom: 0.2rem;
          font-size: 0.24rem;
          span {
            color: var(--theme-text-color-lighten);
            margin-left: 0.1rem;
          }
        }
        textarea {
          overflow: auto;
          resize: vertical;

          line-height: 1.5;
          max-width: 100%;
          min-height: 32px;
          -webkit-transition: all 0.3s, height 0s;
          transition: all 0.3s, height 0s;
          vertical-align: bottom;
          height: auto;
          text-overflow: ellipsis;
          background-color: var(--theme-main-bg-color);
          border: 0.01rem solid var(--theme-color-line);
          border-radius: 0.1rem;
          color: var(--theme-text-color-darken);
          margin-bottom: 0;
          padding: 0.2rem 0.2rem 0.3rem;
          font-size: 0.22rem;
          width: 100%;
          position: relative;
          margin: 0;
          list-style: none;
          font-variant: tabular-nums;
        }
        textarea:hover {
          border: thin solid var(--theme-primary-color) !important;
        }
      }
      .Word-count-record {
        text-align: right;
        color: var(--theme-text-color-lighten);
        font-size: 0.2rem;
        margin-top: 0.2rem;
      }
      .imgitem {
        margin-top: 0.2rem;
        label {
          position: relative;
          color: var(--theme-text-color-darken);
          display: block;
          margin-bottom: 0.2rem;
          font-size: 0.24rem;
          span {
            color: var(--theme-text-color-lighten);
            margin-left: 0.1rem;
          }
        }
        .upload {
          -ms-flex-negative: 0;
          background-color: var(--theme-main-bg-color);
          border: 0.01rem solid var(--theme-color-line);
          flex-shrink: 0;
          height: 1.25rem;
          position: relative;
          width: 1.25rem;
          .vertical {
            height: 0.37rem;
            width: 0.02rem;
            background-color: var(--theme-color-line);
            left: 50%;
            position: absolute;
            top: 50%;
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
          }
          .transverse {
            background-color: var(--theme-color-line);
            left: 50%;
            position: absolute;
            top: 50%;
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);

            height: 0.02rem;
            width: 0.37rem;
          }
        }
        .imgtips {
          color: var(--theme-text-color-lighten);
          display: block;

          line-height: 1.63;
          margin-top: 0.15rem;
          font-size: 0.22rem;
        }
      }
      .explain {
        margin-top: 0.2rem;
        font-size: 0.24rem;
        label {
          color: var(--theme-text-color-darken);
          font-size: 0.24rem;
          line-height: 0.4rem;
        }
        .text {
          color: var(--theme-text-color-lighten);
          font-size: 0.24rem;
          line-height: 1.56;
        }
      }
    }
  }
  .feedbackList {
    margin: 0;
    padding: 0;
    background-color: var(--theme-bg-color);
    border-radius: 0.1rem;
    max-width: 12rem;
    .feedBackBox {
      -webkit-user-select: text;
      -moz-user-select: text;
      -ms-user-select: text;
      user-select: text;
      overflow: auto;
      padding: 0;
      position: relative;
    }
    /deep/.van-empty {
      -ms-flex-align: center;
      -ms-flex-pack: center;
      align-items: center;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      height: calc(100vh - 2.8rem);
      height: calc(var(--vh, 1vh) * 100 - 2.8rem);
      justify-content: center;
    }
  }
}
.submitBtn {
  -ms-flex-align: center;
  -ms-flex-pack: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  width: 100%;
  .verification {
    -ms-flex-pack: center;
    background-color: var(--theme-main-bg-color);
    bottom: 0;
    -webkit-box-shadow: 0 -0.03rem 0.1rem 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 -0.03rem 0.1rem 0 rgba(0, 0, 0, 0.1);
    display: -ms-flexbox;
    display: flex;
    height: 1.3rem;
    justify-content: center;
    padding-top: 0.2rem;
    position: fixed;
    width: var(--theme-max-width);
    left: auto !important;
    margin: 0 auto;
    max-width: 450px;
    max-width: var(--theme-max-width);
  }
}
</style>
