<template>
  <div class="contentBox">
    <div
      class="gameList"
      v-if="displayedItems.length > 0"
      style="overflow: hidden"
    >
      <div
        style="width: 33.33333%"
        v-for="(item2, index2) in displayedItems"
        :key="index2"
      >
        <div class="gameBox">
          <div class="gameitem">
            <van-image
              :src="item2.show_image"
              :lazy-load="true"
              width="var( --advertisement-box-width)"
              height="var(--advertisement-box-height)"
              fit="fill"
              @click="toEnterGame(item2)"
            >
              <template v-slot:loading>
                <svg-icon
                  icon-class="img777"
                  style="
                    width: 1rem;
                    height: 1rem;
                    font-size: 0.03rem;
                    fill: white;
                  "
                ></svg-icon>
              </template>
              <template v-slot:error>
                <svg-icon
                  icon-class="img777"
                  style="
                    width: 1rem;
                    height: 1rem;
                    font-size: 0.03rem;
                    fill: white;
                  "
                ></svg-icon>
              </template>
            </van-image>
            <div class="gameTitle">
              <div class="sn">{{ item2.show_name }}</div>
            </div>
            <img
              v-if="item2.hot"
              :src="require('@/assets/ah7js-8myps.png')"
              data-blur="0"
              data-status="loaded"
              class="hot"
            />
            <!-- <div class="gameTitle">{{ item2.show_name }}</div> -->
            <div class="sc" @click="favoriteClick(index2, item2)">
              <!-- <img
                :src="getImg(index2, item2).img"
                :class="{ sa: getImg(index2, item2).i === 2 }"
              /> -->
              <!-- {{ Math.random() }} -->
              <img
                v-if="item2.favorite"
                :src="
                  refresh_id === index2
                    ? require('@/assets/bookmark_star_2.png')
                    : require('@/assets/btn_sc_on_2.png')
                "
                :class="{ sa: refresh_id === index2 }"
              />
              <img
                v-if="!item2.favorite"
                :src="require('@/assets/btn_sc_off_2.png')"
              />
            </div>
            <div class="fault" v-if="!item2.server">
              <van-image
                :src="require('@/assets/img_dt_whz.png')"
                fit="fill"
                style="
                  height: var(--card-benchmark);
                  left: 0;
                  position: absolute;
                  top: 0;
                  width: var(--card-benchmark);
                "
              >
              </van-image>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <p v-if="hasMoreItems" @click="loadMore">{{  $t('Expand more') }}
                            </p>
                            <p v-else-if="isExpanded" @click="collapse">{{  $t('Collapse') }}
                            </p> -->
    <div v-if="hasMoreItems && noCollapse" @click="loadMore" class="more">
      {{ $t("displayingopulargames",{num:displayCount,size:items.length}) }}
      <div class="scroll-text">
        {{ $t("expand more") }}
        <i
          style="
            display: inline-flex;
            justify-content: center;
            align-items: center;
            font-size: 0.14rem;
          "
        >
          <svg-icon
            icon-class="comm_icon_jzgd"
            focusable="false"
            aria-hidden="true"
            style="width: 1em; height: 1em"
          ></svg-icon>
        </i>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from "@/components/SvgIcon";
import { gameAddFavouriteGame } from "@/api/index";
export default {
  name: "BatchDisplay",
  props: {
    items: {
      type: Array,
      required: true,
    },
    noCollapse: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      displayCount: 0, // 当前显示的数据数量
      isExpanded: false, // 是否已经展开
      // displayedItems: [],
      refresh_id: -1,
    };
  },
  computed: {
    displayedItems() {
      if (!this.noCollapse) {
        return this.items;
      }
      return this.items.slice(0, this.displayCount);
    },
    hasMoreItems() {
      return this.displayCount < this.items.length;
    },
  },
  methods: {
    loadMore() {
      this.displayCount += 6; // 每次点击展开按钮，显示的数据数量增加6
      // this.displayedItems = this.items.slice(0, this.displayCount);
      if (this.displayCount >= this.items.length) {
        this.isExpanded = true; // 如果所有数据都已展开，将状态设置为已展开
      }
    },
    collapse() {
      this.displayCount = 6; // 点击折叠按钮，显示的数据数量变为6
      this.isExpanded = false; // 将状态设置为未展开
    },

    toEnterGame(item) {
      if (!item.server) {
        this.$notify({
          duration: 2000,
          message: this.$t('the game stops serving'),
          type: "warning",
          background: "#fff",
          offset: "3",
        });
        return;
      }

      //O jogo está em manutençäo
      //item2.server
      this.$emit("child-clicked", item);
    },
    favoriteClick(index, item) {
      // item.favorite = ! item.favorite;
      // this.refresh_id = index;
      // return;
      gameAddFavouriteGame({ game_code: item.game_code }).then(({ data }) => {
        if (data.code === 0) {
          this.refresh_id = index;
          if(data.data.favorite){
            this.refresh_id = index;
          }else{
            this.refresh_id = -1;
          }
          // if (data.data.favorite) {
          //   item.show_animation = true;
          // } else {
          //   item.show_animation = false;
          // }
          // item.show_animation=true;
          item.favorite = data.data.favorite;
          this.$emit("favoriteClick",index,item);
        }
      });
     
    },
    // getImg(index, item) {
    //   // return '<img  src="@/assets/btn_sc_on_2.png"/>';
    //   // console.log(item);
    //   if (item.show_animation && item.favorite && this.refresh_id === index) {
    //     return {
    //       img: require("@/assets/bookmark_star_2.png"),
    //       i: 2,
    //     };
    //   }
    //   if (item.favorite) {
    //     return {
    //       img: require("@/assets/btn_sc_on_2.png"),
    //       i: 1,
    //     };
    //   } else {
    //     return {
    //       img: require("@/assets/btn_sc_off_2.png"),
    //       i: 1,
    //     };
    //   }
    // },
  },
  created() {
    this.displayCount = 6; // 初始显示的数据数量为6
    // this.displayedItems = this.items.slice(0, this.displayCount);
  },
};
</script>
<style type="text/less" lang="less" scoped>
.contentBox {
  --card-benchmark: 2.1rem;
  // --advertisement-box-height: 2.792rem;
  // --advertisement-box-width: 2.1rem;
  --cardRowSpacing: 0.15rem;
  --cardColSpacing: 0;
  --advertisement-box-width: var(--card-benchmark);
  --advertisement-box-height: calc(var(--card-benchmark) * 1.33);
  --advertisement-box-border-width: 0;
  --advertisement-box-border-color: var(--theme-game-lobby-border);
  --advertisement-box-border-radius: 0.2rem;
  position: relative;
  // padding-bottom: 10vw;
  font-size: 0.34rem;
  width: 6.9rem;
  // padding: 0.2rem;
  p {
    cursor: pointer;
  }

  .gameList {
    display: flex;
    align-items: start;
    flex-wrap: wrap;
    transition: 1s;
  }

  .gameBox {
    --card-benchmark: 2.1rem;
    --favorited-icon-top: calc(var(--card-benchmark) * 0.033);
    --favorited-icon-right: calc(var(--card-benchmark) * 0.033);
    --favorited-icon-bottom: initial;
    --favorited-icon-left: initial;
    --click-arean-expand-size: -0.06rem;
    --favorited-icon-side: calc(var(--card-benchmark) * 0.206);
    width: var(--advertisement-box-width);
    height: var(--advertisement-box-height);
    margin: var(--cardRowSpacing) var(--cardColSpacing);
    position: relative;
    border-radius: var(--advertisement-box-border-radius);
    overflow: hidden;
    .gameitem {
      -ms-flex-align: center;
      -ms-flex-pack: end;

      -ms-flex-align: center;
      -ms-flex-pack: center;
      align-items: center;
      background-color: var(--theme-game-card-ddt_bg);
      background-image: initial;
      border-radius: 0;
      color: var(--theme-game-card-ddt_icon);
      display: -ms-flexbox;

      height: 100%;
      justify-content: center;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      border-radius: 0.2rem;
      .hot {
        --label-game-status-width: calc(var(--card-benchmark) * 0.2);
        --label-game-status-height: calc(var(--card-benchmark) * 0.15);
        height: var(--label-game-status-height);
        left: 0;
        position: absolute;
        top: 0;
        width: var(--label-game-status-width);
        z-index: auto;
      }
      .fault {
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 0;
        bottom: 0;
        cursor: not-allowed;
        left: 0;
        opacity: 1;
        position: absolute;
        right: 0;
        text-align: center;
        top: 0;
        -webkit-transition: opacity 0.6s;
        transition: opacity 0.6s;
        z-index: 9;
      }
      .van-image {
        /deep/ .van-image__loading,
        /deep/ .van-image__error {
          background-color: transparent;
        }
      }
    }
    .gameTitle {
      position: absolute;
      bottom: 0.1rem;
      width: 100%;
      text-align: center;
      color: #fff;
      font-size: 0.34rem;
      // height: 11vw;
      // line-height: 10vw;
      --game-name-vertical-padding: 0.06rem 0.15rem;
      --top-name-font-size: 0.34rem;
      --bottom-name-font-size: 0.14rem;
      --bottom-name-margin-top: 0.05rem;
      --game-name-text-shadow: rgba(0, 0, 0, 0.3);
      --game-name-text-stroke: none;
      --game-name-font-weight: normal;
      --game-platform-name-height: calc(var(--card-benchmark) * 0.44);
      --game-platform-name-padding: 0 calc(var(--card-benchmark) * 0.04)
        calc(var(--card-benchmark) * 0.04);
      --game-platform-name-font-size: calc(var(--card-benchmark) * 0.113);
      color: var(--theme-text-color-task-btn);

      font-size: var(--game-platform-name-font-size);
      .sn {
        -webkit-text-stroke: var(--game-name-text-stroke);
        font-weight: var(--game-name-font-weight);
        font-size: var(--game-platform-name-font-size);
        text-align: center;
        // text-shadow: 0 0.02rem 0.04rem var(--game-name-text-shadow);
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        word-wrap: break-word;
        display: -webkit-box;
        font-family: MicrosoftYaHei;
        max-width: 100%;
        overflow: hidden;
        text-align: center;
        text-overflow: ellipsis;
        color: var(--theme-text-color-task-btn);
        // padding: var(--game-name-vertical-padding);
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    .sc {
      position: absolute;
      top: var(--favorited-icon-top);
      right: var(--favorited-icon-right);
      left: var(--favorited-icon-left);
      width: var(--favorited-icon-side);
      height: var(--favorited-icon-side);
      z-index: 4;
      cursor: pointer;
      // overflow: hidden;
      img {
        width: var(--favorited-icon-side);
        height: var(--favorited-icon-side);
      }
      .sa {
        // position: absolute;
        // left: -0.115rem;
        // top: -0.12rem;
        --icon-animation-transform: scale(1.7);
        -webkit-transform: var(--icon-animation-transform);
        transform: var(--icon-animation-transform);
        // top: 0px;
        margin: 0 auto;
        height: inherit !important;
        width: inherit !important;
        // width: calc(var(--favorited-icon-side) * 1.4);
        // height: calc(var(--favorited-icon-side) * 1.4);
      }
    }
  }

  .gameBox:nth-child(3n) {
    margin-right: 0;
  }
  .more {
    display: block;
    font-size: 0.22rem;
    text-align: center;

    -ms-flex-align: center;
    -ms-flex-pack: center;
    align-items: center;
    color: var(--theme-text-color-lighten);
    justify-content: center;
    margin-top: 0.3rem;
    .scroll-text {
      color: var(--theme-text-color);
      display: block;
      font-size: 0.22rem;
      margin-top: 0.02rem;
      margin-left: 0.08rem;
      cursor: pointer;
    }
  }
}
</style>
