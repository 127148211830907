<template>
  <section>
    <div class="topMenu">
      <mySelect
        v-if="dateselectItem !== 'Personalizar'"
        :items="dateItems"
        @change="dateSelect"
        :selectItem.sync="dateselectItem"
      ></mySelect>
      <myDatePickerCompose
        v-if="dateselectItem === 'Personalizar'"
        @cancel="
          () => {
            dateselectItem = 'today';
          }
        "
        @change="change"
      ></myDatePickerCompose>
      <div class="info">
        {{ $t("total Serves")
        }}<span>R$ {{ formatNumberToString(total_withdraw) }}</span>
        <i
          style="margin-left: 0.1rem"
          @click="refresh"
          class="anticon refresh-coin animate__spin animate__infinite"
          :class="{ animate__animated: isAnimateAnimated }"
        >
          <i
            style="
              display: inline-flex;
              justify-content: center;
              align-items: center;
            "
          >
            <svg-icon
              icon-class="comm_icon_sx"
              style="
                width: 1em;
                height: 1em;
                color: var(--theme-alt-primary);
                fill: currentColor;
                font-size: 0.293rem;
              "
            ></svg-icon>
          </i>
        </i>
      </div>
    </div>

    <div>
      <div class="list">
        <van-empty
          v-if="list.length < 1"
          :image="require('@/assets/azf5f-28vaj.png')"
          :description="$t('no records')"
        />
        <van-list
          v-model="loading"
          :finished="finished"
          @load="onLoad"
          offset="100"
          :loading-text="$t('loading')"
          v-else
        >
          <div class="sticky" v-for="(item, index) in list">
            <div class="left">
              <div class="imgbox">
                <img
                  src="@/assets/icon_normal_pix.png"
                  style="width: 0.6rem; height: 0.6rem"
                />
              </div>
              <div>
                <p class="directMember">
                  {{ item.pix_type }}({{ item.pix_key }})
                </p>
                <p class="date">{{ formatDate(item.timestamp) }}</p>
              </div>
            </div>
            <div class="right">
              <p class="money">{{ formatNumberToString(item.amount) }}</p>
              <p
                class="status"
                :class="{
                  isRed:
                    item.state === 3 ||
                    item.state === 6 ||
                    item.state === 4 ||
                    item.state === 7,
                  isOrange:
                    item.state === 1 || item.state === 5 || item.state === 0,
                  isGreen: item.state === 2,
                }"
              >
                {{ getstatus(item.state) }}
              </p>
            </div>
          </div>
        </van-list>
      </div>
    </div>
  </section>
</template>
<script>
import { withdrawGetRecords } from "@/api/index";
import mySelect from "@/components/phone/common/mySelect.vue";

import myDatePickerCompose from "@/components/phone/common/my-date-picker-compose.vue";

import { formatNumberToString2, formatString } from "@/utils/utils";

export default {
  name: "records",
  components: { mySelect, myDatePickerCompose },
  mixins: [],
  data() {
    return {
      startdate: "",
      enddate: "",
      total_withdraw: 0,
      dateselectItem: "today",
      dateItems: [
        { label: this.$t("today"), value: "today" },
        { label: this.$t("yesterday"), value: "yesterday" },
        { label: this.$t("Last 7 Days"), value: "last_week" },
        { label: this.$t("Last 15 Days"), value: "last_half_month" },
        { label: this.$t("Last 30 Days"), value: "last_month" },
        { label: this.$t("custom"), value: "Personalizar" },
      ],
      isAnimateAnimated: false,
      list: [],
      pageIndex: 0,
      pageSize: 50,
      finished: false,
      loading: false,
    };
  },
  computed: {},
  watch: {},
  activated() {
    this.pageIndex = 0;
  },
  mounted() {
    this.getlist();
  },
  methods: {
    dateSelect() {
      if (this.dateselectItem !== "Personalizar") {
        this.pageIndex = 0;
        this.list = [];
        this.getlist();
      }
    },
    handleChange(date, dateString) {
      // console.log(date, dateString);
    },
    getlist(s, e) {
      this.isAnimateAnimated = true;
      withdrawGetRecords({
        time_rang:
          this.dateselectItem === "Personalizar" ? "" : this.dateselectItem,
        start_date: s,
        m: e,
        page: this.pageIndex,
        count: this.pageSize,
      }).then(({ data }) => {
        this.isAnimateAnimated = false;

        if (data.code === 0) {
          if (data.data.records.length > 0) {
            this.list = this.list.concat(data.data.records);
          }

          if (data.data.records.length < this.pageSize) {
            this.finished = true;
          }
          this.total_withdraw = data.data.total_withdraw;
          this.loading = false;
        }
      });
    },
    change(s, e) {
      if (s && e) {
        this.startdate = s;
        this.enddate = e;
        this.pageIndex = 0;
        this.list = [];
        this.getlist(s, e);
      }

      // console.log(s,e);
    },
    onLoad() {
      this.pageIndex = this.pageIndex + 1;
      this.getList();
    },
    formatNumberToString(v) {
      return formatNumberToString2(v);
    },
    refresh() {
      if (this.startdate && this.enddate) {
        this.pageIndex = 0;
        this.list = [];
        this.getlist(this.startdate, this.enddate);
      } else if (this.dateselectItem !== "Personalizar") {
        this.pageIndex = 0;
        this.list = [];
        this.getlist();
      }
    },
    formatDate(n) {
      const date = new Date(n * 1000);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      // const year = date.getFullYear();
      const h = date.getHours().toString().padStart(2, "0");
      const m = date.getMinutes().toString().padStart(2, "0");
      const s = date.getSeconds().toString().padStart(2, "0");
      return `${day}/${month} ${h}:${m}:${s}`;
    },
    getstatus(i) {
      switch (i) {
        case 0:
          return this.$t("order generation");
        case 1:
          return this.$t("transfer");
        case 2:
          return this.$t("successful transfer");
        case 3:
          return this.$t("transfer failed");
        case 4:
          return this.$t("transfer closed");
        case 5:
          return this.$t("review");
        case 6:
          return this.$t("review refused");
        case 7:
          return this.$t("the background fallback has failed");
      }
    },
  },
};
</script>
<style type="text/less" lang="less" scoped>
.topMenu {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0.2rem;
  font-size: 0.22rem;
  .info {
    font-size: 0.22rem;
    margin-left: 0.2rem;
    text-align: right;
    color: var(--theme-text-color-lighten);
    span {
      color: var(--theme-text-color-darken);
      margin-left: 0.05rem;
    }
  }
}
.list {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: calc(var(--vh, 1vh) * 100 - 2.55rem);
  justify-content: center;
  overflow: hidden;
  position: relative;
  font-size: 0.16rem;
  /deep/.van-list > div:nth-child(2n + 1) {
    background-color: var(--theme-main-bg-color);
  }
  /deep/.van-list > div {
    border-radius: 0.1rem;
    padding: 0.2rem;
  }
  /deep/.van-list > div.van-list__placeholder {
    background-color: transparent !important;
  }
  .sticky {
    justify-content: space-between;
    height: 1.1rem;
    align-items: center;
    display: flex;
    .left {
      align-items: center;
      display: flex;
      .imgbox {
        border-radius: 0.1rem;
        height: 0.6rem;
        margin-right: 0.2rem;
        width: 0.6rem;
      }
      .date {
        align-items: center;
        color: var(--theme-text-color-lighten);
        display: flex;
        font-size: 0.2rem;
        justify-content: flex-start;
      }
    }
    .right {
      flex: 1 1 0%;
      text-align: right;
      .money {
        color: var(--theme-text-color-darken);
        font-size: 0.24rem;
        font-weight: 700;
      }
      .status {
        font-size: 0.22rem;
      }
    }
  }
}
</style>
