<template>
  <section class="wrap">
    <el-form :rules="rules" ref="editfrom" :model="editfrom">
      <el-form-item style="margin-bottom: 0.5rem" prop="account">
        <el-input
          v-model="editfrom.account"
          class="myinput"
          maxlength="16"
          @focus="isAFocus = true"
          @blur="isAFocus = false"
          :placeholder="$t('enter username')"
        >
          <template slot="prefix">
            <i
              style="
                display: inline-flex;
                justify-content: center;
                align-items: center;
                color: var(--theme-text-color-lighten);
                height: 0.25rem;
                width: 0.25rem;
                font-size: 0.18rem;
              "
            >
              <svg-icon
                icon-class="input_icon_zh"
                style="width: 0.25rem; height: 0.25rem; fill: currentColor"
              ></svg-icon>
            </i>
          </template>
          <template slot="suffix">
            <i
              v-show="
                editfrom.account.length > 0 ||
                (editfrom.account.length > 0 && isAFocus)
              "
              style="
                display: inline-flex;
                justify-content: center;
                align-items: center;
                color: var(--theme-text-color-lighten);
                height: 0.25rem;
                width: 0.25rem;
                font-size: 0.18rem;
                cursor: pointer;
              "
              @click="editfrom.account = ''"
            >
              <svg-icon
                icon-class="input_icon_clear"
                style="width: 0.25rem; height: 0.25rem; fill: currentColor"
              ></svg-icon>
            </i>
          </template>
        </el-input>
        <template slot="error" slot-scope="scope">
          <div class="err-explain">{{ scope.error }}</div>
        </template>
      </el-form-item>

      <el-form-item style="margin-bottom: 0.5rem" prop="password">
        <el-input
          v-model="editfrom.password"
          class="myinput pass"
          maxlength="16"
          :type="showPsw ? 'text' : 'password'"
          @focus="isPFocus = true"
          @blur="isPFocus = false"
          :placeholder="$t('enter password2')"
        >
          <template slot="prefix">
            <i
              style="
                display: inline-flex;
                justify-content: center;
                align-items: center;
                color: var(--theme-text-color-lighten);
                height: 0.25rem;
                width: 0.25rem;
                font-size: 0.18rem;
              "
            >
              <svg-icon
                icon-class="input_icon_mm"
                style="width: 0.25rem; height: 0.25rem; fill: currentColor"
              ></svg-icon>
            </i>
          </template>
          <template slot="suffix">
            <i
              v-show="
                editfrom.password.length > 0 ||
                (editfrom.password.length > 0 && isPFocus)
              "
              style="
                display: inline-flex;
                justify-content: center;
                align-items: center;
                color: var(--theme-text-color-lighten);
                height: 0.25rem;
                width: 0.25rem;
                font-size: 0.18rem;
                cursor: pointer;
              "
              @click="editfrom.password = ''"
            >
              <svg-icon
                icon-class="input_icon_clear"
                style="width: 0.25rem; height: 0.25rem; fill: currentColor"
              ></svg-icon>
            </i>
            <i
              style="
                display: inline-flex;
                justify-content: center;
                align-items: center;
                color: var(--theme-text-color-lighten);
                height: 0.25rem;
                width: 0.25rem;
                font-size: 0.36rem;
                cursor: pointer;
                padding-right: 0.01rem;
                margin-left: 0.2rem;
                padding-right: 0.01rem;
              "
              @click="showPsw = !showPsw"
            >
              <svg-icon
                :icon-class="!showPsw ? 'comm_icon_hide' : 'comm_icon_show'"
                style="width: 1em; height: 1em; fill: currentColor"
                :style="
                  !showPsw
                    ? 'color: var(--theme-color-line);'
                    : 'color: var(--theme-primary-color);'
                "
              ></svg-icon>
            </i>
          </template>
        </el-input>
        <template slot="error" slot-scope="scope">
          <div class="err-explain">{{ scope.error }}</div>
        </template>
      </el-form-item>
    </el-form>
    <div class="submitBox">
      <van-button
        @click="submitAddAgent"
        :loading="submitLoading"
        class="highlight-button"
        style="font-size: 0.24rem; height: 0.7rem; max-width: 100%; width: 100%"
      >
       {{ $t('submit the registration') }}
      </van-button>
    </div>
    <myDialog
      :open.sync="showSubordinateInfo"
      :originalClose="false"
      width="6rem"
      :Title="$t('successful registration')"
      effect="info"
    >
      <van-row justify="space-between" type="flex" class="subordinateInfo">
        <van-col span="24">
          <span>{{ $t('username') }}:</span>
          <span>{{ showaccount }}</span>
        </van-col>
        <van-col span="24">
          <span>{{ $t('login password') }}:</span>
          <span>{{ showpassword }}</span>
        </van-col>
        <van-col span="24">
          {{ $t('create account tips') }}
        </van-col>
        <van-col span="24">
          <van-button
            v-copy="copyInfo"
            class="highlight-button"
            style="font-size: 0.24rem; height: 0.7rem; width: 100%"
            >{{ $t('copy and close') }}</van-button
          >
        </van-col>
      </van-row>
    </myDialog>
  </section>
</template>
<script>
import { registerSubordinateAccount } from "@/api/index";
import myDialog from "@/components/phone/common/myDialog.vue";
export default {
  name: "createAccount",
  components: { myDialog },
  data() {
    return {
      notify: null,
      submitLoading: false,
      isAFocus: false,
      isPFocus: false,
      showPsw: false,
      showSubordinateInfo: false,
      copyInfo: "",
      showaccount: "",
      showpassword: "",
      editfrom: {
        account: "",
        password: "",
      },
      rules: {
        account: [
          {
            min: 4,
            max: 16,
            required: true,
            message: this.$t(
              "create account err1"
            ),
            trigger: "blur",
          },
        ],
        password: [
          {
            min: 6,
            max: 16,
            required: true,
            message: this.$t(
              "password restrictions1"
            ),
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {},
  mounted() {},
  methods: {
    submitAddAgent() {
      this.$refs.editfrom.validate((valid) => {
        if (valid) {
          this.submitLoading = true;
          registerSubordinateAccount({
            account: this.editfrom.account,
            password: this.editfrom.password,
          }).then(({ data }) => {
            this.submitLoading = false;
            if (data.code !== 0 || !data.data.success) {
              if (this.notify && this.notify.close) {
                this.notify.close();
              }
              this.notify = this.$notify({
                duration: 2000,
                message: data.message,
                type: "error",
                background: "#fff",
                offset: "3",
                showClose: false,
              });
              return;
            } else {
              if (data.data.success) {
                this.showpassword = this.editfrom.password;
                this.showaccount = this.editfrom.account;
                this.copyInfo =
                 this.$t('account')+': ' +
                  this.showaccount +
                  "  " +this.$t('password')+': '+
                  this.showpassword;
                this.editfrom.account = "";
                this.editfrom.password = "";
              
                this.showSubordinateInfo=true;
               
              } else {
                if (this.notify && this.notify.close) {
                  this.notify.close();
                }
                this.notify = this.$notify({
                  duration: 2000,
                  message: this.$t('unknown error'),
                  type: "error",
                  background: "#fff",
                  offset: "3",
                  showClose: false,
                });
              }
            }
          });
        }
      });
    },
  },
};
</script>
<style type="text/less" lang="less" scoped>
.wrap {
  min-height: 7rem;
  border-radius: 0.1rem;
  -ms-flex-pack: justify;
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  height: calc(100vh - 1.5rem) !important;
  height: calc(var(--vh, 1vh) * 100 - 1.5rem) !important;
  justify-content: space-between;
  padding: 0;
  position: relative;
}
/deep/.el-form {
  padding: 0.2rem;
}
.myinput {
  /deep/.el-input__inner {
    height: 0.7rem;
    border-radius: 0.14rem;
    padding: 0 0.6rem;
    font-size: 0.22rem;
  }
  /deep/.el-input__prefix {
    left: 0.2rem;
  }
  /deep/.el-input__prefix,
  /deep/.el-input__suffix {
    -ms-flex-align: center;
    align-items: center;

    display: -ms-flexbox;
    display: flex;
    line-height: 0;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 2;
  }
  /deep/.el-input__suffix {
    right: 0.2rem;
  }
}
.pass {
  /deep/.el-input__suffix-inner {
    word-wrap: inherit;
    display: -ms-flexbox;
    display: flex;
    text-align: inherit;
    width: auto;
  }
}
.err-explain {
  margin-top: 0;
}
.submitBox {
  background: var(--theme-main-bg-color);
  -webkit-box-shadow: 0 -0.03rem 0.1rem 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 -0.03rem 0.1rem 0 rgba(0, 0, 0, 0.1);
  padding: 0.2rem 0.2rem 0.4rem;
  width: 100%;
}
.subordinateInfo {
  margin-bottom: 0.4rem;
  font-size: 0.22rem;
  text-align: center;
  /deep/.van-col {
    color: var(--theme-text-color-lighten);
    font-size: 0.22rem;
  }

  /deep/.van-col:first-child {
    margin-bottom: 0.1rem;
    text-align: left;
    > span:last-child {
      color: var(--theme-text-color-darken);
      font-weight: 700;
      padding-left: 0.08rem;
    }
  }
  /deep/.van-col:nth-child(2) {
    margin-bottom: 0.1rem;
    text-align: left;
    > span:last-child {
      color: var(--theme-text-color-darken);
      font-weight: 700;
      padding-left: 0.08rem;
    }
  }

  /deep/.van-col:nth-child(3) {
    color: var(--theme-secondary-color-error);
    font-size: 0.22rem;
    margin-bottom: 0.4rem;
    text-align: left;
  }
}
/deep/.el-dialog__wrapper {
    position:absolute;
}

</style>
