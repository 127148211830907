import { getRedPointInfo } from '@/api/index'

const state = {
    redPointInfo:{}
};

const mutations = {
    SET_RED_POINT_INFO(state, redPointInfo) {
        state.redPointInfo = redPointInfo;
    },
 
};

const actions = {
    setRedPointInfo({ commit }) {
        getRedPointInfo().then(res => {
            commit('SET_RED_POINT_INFO', res.data.data.infos);
            // resolve(res.data.data)
          }).catch(error => {
            // reject(error)
          })
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
