import MusicPlayer from '@/utils/musicPlayer.js';

const state = {
    playlist: [],
    currentTrack: null,
    isPlaying: false,
    volume: 0.5,
    progress: 0,
    playType: 0,
    muted: MusicPlayer.getMuted(),
    history: MusicPlayer.getHistory(),
    duration: MusicPlayer.getDuration(),
    openMusic: false,
};

const mutations = {
    SET_MUTED(state, muted) {
        state.muted = muted;
    },
    SET_PLAYLIST(state, playlist) {
        state.playlist = playlist;
    },
    SET_CURRENT_TRACK(state, track) {
        state.currentTrack = track;
    },
    SET_PLAYING_STATE(state, isPlaying) {
        state.isPlaying = isPlaying;
    },
    SET_VOLUME(state, volume) {
        state.volume = volume;
        MusicPlayer.setVolume(volume);
    },
    SET_PROGRESS(state, progress) {
        state.progress = progress;
    },

    ADD_TO_HISTORY(state, track) {
        state.history.push(track);
        MusicPlayer.addToHistory(track);
    },
    REMOVE_HISTORY(state, index) {
        state.history.splice(index, 1);
        MusicPlayer.removeHistory(index);
    },
    SET_DURATION(state, duration) {
        state.duration = duration;
    },
    SET_PLAYTYPE(state, playType) {
        state.playType = playType;
        //playType
    },
    SET_OPEN_MUSIC_BOX(state, openMusic) {
        state.openMusic = openMusic;
    }
};

const actions = {
    setVolume({ commit }, volume) {
        commit('SET_VOLUME', volume);
    },
    play({ commit, state }, music) {
        MusicPlayer.play(music);
        commit('SET_PLAYLIST', MusicPlayer.getPlaylist());
        commit('SET_PLAYING_STATE', true);
        commit('SET_CURRENT_TRACK', music);
    },
    pause({ commit }) {
        MusicPlayer.pause();
        commit('SET_PLAYING_STATE', false);
    },
    restore({ commit }) {
        MusicPlayer.restore();
        commit('SET_PLAYING_STATE', true);
    },
    next({ dispatch }) {
        MusicPlayer.next();
        // dispatch('updateCurrentTrack');
    },
    previous({ dispatch }) {
        MusicPlayer.previous();
        // dispatch('updateCurrentTrack');
    },
    updateCurrentTrack({ commit }) {
        const currentTrack = MusicPlayer.getCurrentTrack();
        commit('SET_CURRENT_TRACK', currentTrack);
    },
    saveProgress() {
        MusicPlayer.saveProgress();
    },
    loadProgress({ dispatch }) {
        MusicPlayer.loadProgress();
        dispatch('updateCurrentTrack');
    },
    removeHistory({ commit }, index) {
        commit('REMOVE_HISTORY', index);
    },
    updateProgress({ commit }) {
        const progress = MusicPlayer.getProgress();
        commit('SET_PROGRESS', progress);
    },
    updateDuration({ commit }) {
        const duration = MusicPlayer.getDuration();
        commit('SET_DURATION', duration);
    },
    setProgress({ commit }, progress) {
        MusicPlayer.setProgress(progress);
        commit('SET_PROGRESS', progress);
    },
    setMuted({ commit }, muted) {
        MusicPlayer.setMuted(muted);
        commit('SET_MUTED', muted);
    },
    setPlayType({ commit }, playType) {
        MusicPlayer.setPlayType(playType);
        commit('SET_PLAYTYPE', playType);
    },
    setOpenMusicBox({ commit }, b){
        commit('SET_OPEN_MUSIC_BOX', b);
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
