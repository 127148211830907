<template>
  <div class="wrap">
    <div class="inviteInfo">
      <div class="title">{{ $t("promotion") }}</div>
      <div class="inviteInfoContent">
        <div class="qrcode">
          <van-image
            :lazy-load="true"
            fit="fill"
            width="1.3rem"
            height="1.3rem"
            style="margin: 0.1rem"
            :src="imgurl"
          >
            <template v-slot:loading>
              <div class="imgload"></div>
            </template>
          </van-image>
          <!-- <div class="btn">{{ $t("saveImg") }}</div>
            -->
          <van-button class="btn">{{ $t("click to save") }}</van-button>
        </div>
        <div class="linkBox">
          <div class="myLink">
            {{ $t("my link") }}
            <!-- <i @click="share" class="el-icon-share"></i> -->
            <span
              style="
                text-wrap: nowrap;
                color: var(--theme-primary-color);
                cursor: pointer;
                font-size: 0.24rem;
              "
              >{{ $t("to alter") }}</span
            >
          </div>
          <div>
            <el-autocomplete
              v-model="link"
              placeholder=""
              :readonly="true"
              popper-class="selectitem"
              style="max-width: 5rem; width: 100%"
              :popper-append-to-body="false"
              :visible-arrow="true"
            >
              <template slot="suffix">
                <span class="copyIcon" v-copy="link">
                  <svg-icon
                    icon-class="comm_icon_copy"
                    style="width: 1em; height: 1em; fill: white"
                  ></svg-icon>
                </span>
              </template>
            </el-autocomplete>

            <!-- <i v-copy="2222" class="el-icon-document-copy"></i> -->
          </div>
          <div class="inviteCode">
            <div>
              <label>{{ $t("direct subordinates") }}</label
              ><span class="subordinatesnum"> - </span>
            </div>
            <div>
              <label style="margin-right: 0.1rem">{{
                $t("invitation code")
              }}</label
              ><span>{{ ac }}</span>
              <span class="copyIcon2" v-copy="link">
                <svg-icon
                  icon-class="comm_icon_copy"
                  style="width: 1em; height: 1em; fill: white"
                ></svg-icon>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        class="my-scrollbar my-scrollbar-hover"
        style="width: 100%; height: 1.75rem"
      >
        <div class="my-scrollbar-wrap my-scrollbar-wrap-x">
          <div class="my-scrollbar-content">
            <div class="Partilhar">
              <div>
                <span class="share">
                  <svg-icon
                    icon-class="tg_share"
                    style="width: 1em; height: 1em; fill: white"
                  ></svg-icon> </span
                ><span>{{ $t('share') }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottomTips" >
        
     {{ $t('valid subordinates people',{people:award_num}) }}
       
      </div>
    </div>
    <div class="tips">
      <div class="title">{{ $t("what is a valid promotional number?(Meet all requirements listed below)") }}</div>
      <div class="bottomLayout">
        <div class="tr1">
          <span>{{ $t("subordinate's accumulated deposits") }}</span>
          <span
            >{{ config.require ? config.require.deposit : 0
            }}{{ $t("or more") }}</span
          >
        </div>
        <div class="tr1">
          <span>{{ $t('subordinate accumulator bets') }}</span>
          <span
            >{{ config.require ? config.require.flow : 0
            }}{{ $t("or more") }}</span
          >
        </div>
      </div>
    </div>
    <div class="businessRules">
      <div>
        <div class="treasureChest">
          <div class="boxBody">
            <div class="boxitem" v-for="(item, index) in list" :key="index">
              <div class="boxrow">
                <template v-for="(item2, index2) in item">
                  <div class="boxnode">
                    <div
                      style="position: relative"
                      :style="{
                        cursor: item2.boxStatus === 1 ? 'pointer' : '',
                      }"
                      @click="openBox(index, index2, item2)"
                    >
                      <van-image
                        :lazy-load="true"
                        fit="fill"
                        style="
                          width: 1.32rem;
                          height: 1.23rem;
                          position: relative;
                        "
                        :src="
                          item2.boxStatus === 0
                            ? require('@/assets/tghd_img_bx3.png')
                            : item2.boxStatus === 1
                            ? require('@/assets/tghd_img_bx1.png')
                            : require('@/assets/tghd_img_bx2.png')
                        "
                      />
                      <div class="titlebox">
                        <div
                          class="openRewardTitle"
                          v-if="item2.boxStatus === 2"
                        >
                          <div>
                            <div style="line-height: 1.2; width: 100%">
                              {{ item2.cash }}
                            </div>
                          </div>
                        </div>
                        <div class="title">
                          <span class="span1">
                            <span class="span2">
                              <span class="span3">{{ item2.num }} {{ $t('people') }}</span>
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="bottomTitle" v-if="item2.boxStatus < 2">
                      <span :class="{ isOrange: item2.boxStatus === 1 }">{{
                        item2.cash
                      }}</span>
                    </div>
                  </div>
                  <div v-if="index2 < item.length - 1" class="toward">
                    <i
                      style="
                        display: inline-flex;
                        justify-content: center;
                        align-items: center;
                      "
                    >
                      <svg-icon
                        icon-class="img_tghdjt"
                        style="
                          width: 0.22rem;
                          height: 0.2rem;
                          font-size: 0.22rem;
                          fill: currentColor;
                          color: var(--theme-text-color-lighten);
                        "
                      ></svg-icon>
                    </i>
                  </div>
                </template>
              </div>
              <div class="fingerdown" v-if="index < list.length - 1">
                <i
                  style="
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <svg-icon
                    icon-class="img_tghdjt"
                    style="
                      width: 0.22rem;
                      height: 0.2rem;
                      font-size: 0.22rem;
                      fill: currentColor;
                      color: var(--theme-text-color-lighten);
                      transform: rotate(90deg);
                      margin-right: 0.57rem;
                    "
                  ></svg-icon>
                </i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="taskRule">
      <div class="ruleBody">
        <div>
          <span class="fw400">I.Hora do evento(UTC-3):</span>
        </div>
        <div><span class="fw400"> II.Condições do Evento: </span></div>
        <div>
          <span
            >Somente o subordinado recém -registrado, os subordinados atendem
            aos requisitos de atividade</span
          >
        </div>
        <div>
          <span class="fw400">III.Instruções Do Evento:</span>
          <div>
            1.Convide amigos para abrir o Baú de Tesouros. Conclua com
            diferentes quantidades de pessoas para receber baús correspondentes,
            com um valor máximo de 50000. Quanto mais amigos você convidar,
            maior será a recompensa;<br />2.Essa promoção é um bônus adicional
            da plataforma, e você também pode desfrutar de outras recompensas e
            comissões de agente, o que significa que você pode experimentar
            diretamente a alegria multiplicada;<br />3.A recompensa é limitada à
            coleta manual no final doAPP/iOS、APP/Android、PC/Windows, e o
            expirado será distribuído automaticamente;<br />4.O bônus (excluindo
            o principal) deste evento requer 0 apostas válidas para ser sacado,
            e as apostas não são limitadas a plataforma do jogo;<br />5.Este
            evento é limitado a operações normais realizadas pelo titular da
            conta. É proibido alugar, usar plug-ins externos, robôs, apostar em
            contas diferentes, brushing mútuo, arbitragem, interface, protocolo,
            exploração de vulnerabilidades, controle de grupo ou outros meios
            técnicos para participar. Caso contrário, as recompensas serão
            canceladas ou deduzidas, a conta será congelada ou até mesmo
            adicionada à lista negra;<br />6.Para evitar diferenças na
            compreensão do texto, a plataforma reserva-se o direito de
            interpretação final deste evento.
          </div>
        </div>
      </div>
    </div>
    <myDialog
      :open.sync="showTipsBox"
      effect="error"
      :Title="tipsTitle"
      width="6rem"
    >
      <div class="TipsInfo" style="text-align: center">
        {{ tipsText }}
      </div>
    </myDialog>
    <div class="rewardAnimation" v-if="showReward">
      <img src="@/assets/apng_lingqu_3.png" v-if="showReward" />
    </div>
  </div>
</template>
<script>
import myDialog from "@/components/phone/common/myDialog.vue";
import { mapState } from "vuex";
import {
  getActivityConfig,
  getInviteInfo,
  openInviteReward,
} from "@/api/index";
import qrcode from "qrcode";
export default {
  name: "businessRules",
  components: { myDialog },
  data() {
    return {
      showReward: false,
      boxNumber: 4,
      list: [],
      link: "",
      loading: false,
      config: {},
      imgurl: "",
      info: {},
      un_opened_indexes: [],
      max_award_index: 0,
      award_num: 0,
      invited_num: 0,
      showTipsBox: false,
      tipsTitle: "Lembrete importante",
      tipsText: "",
    };
  },

  mounted() {
    window.addEventListener("resize", this.countWidth);
    this.getConfig();
  },
  destroyed() {
    window.removeEventListener("resize", this.countWidth);
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
      token: (state) => state.user.token,
      ac: (state) => state.user.ac,
    }),
  },
  methods: {
    remoteMethod(query) {},
    getConfig() {
      getActivityConfig({ Key: "invite" }).then(({ data }) => {
        this.config = JSON.parse(data.data.config);
        this.getInviteInfo();
        // console.log(result);
      });
    },
    getInviteInfo() {
      getInviteInfo().then(({ data }) => {
        this.info = data.data;
        this.link = this.info.url;
        this.invited_num = data.data.invited_num;
        this.award_num = data.data.award_num;
        this.max_award_index = data.data.max_award_index;
        this.un_opened_indexes = data.data.un_opened_indexes;
        qrcode.toDataURL(this.info.url, (err, url) => {
          if (!err) {
            this.imgurl = url;
          }
        });
        const result = [];
        for (let i = 0; i < this.config.reward.length; i += 4) {
          const itembox = this.config.reward.slice(i, i + 4);
          for (let j = 0; j < itembox.length; j++) {
            if (i + j < this.max_award_index) {
              itembox[j].boxStatus = 2;
              for (let o = 0; o < this.un_opened_indexes.length; o++) {
                if (i + j === o) {
                  itembox[j].boxStatus = 1;
                  break;
                }
              }
            } else {
              itembox[j].boxStatus = 0;
            }
          }
          result.push(itembox);
          // result.push(this.config.reward.slice(i, i + 4));
        }
        this.list = result;
      });
    },
    // runpage(item) {
    //   if (this.jnpf.isEmpty(item.id)) {
    //   } else {
    //     const url = item.url;
    //     this.$router.push({
    //       path: "/" + url + "?setp=99&dataId=" + item.dataId,
    //     });
    //   }
    // },
    share() {
      navigator.share({
        url: "",
        text: "",
        title: "",
      });
    },
    openBox(index, index2, item) {
      if (item.boxStatus === 1) {
        openInviteReward({ index: (index * 4) + index2 }).then(({ data }) => {
          if (data.code === 0) {
            if (data.data.success) {
              item.boxStatus = 2;
              this.showReward = true;
              this.$set(this.list[index], index2, item);
              setTimeout(() => {
                self.showReward = false;
              }, 1500);
            }
          } else if (data.code === 501) {
            this.tipsText='Please bind the extraction account first';
            this.showTipsBox=true;
          }
        });
        // item.boxStatus = 2;
        // console.log(index);
        // this.$set(this.list[index], index2, item);
        // this.showReward = true;
        // const self = this;
        // setTimeout(() => {
        //   self.showReward = false;
        // }, 1500);
      }

      // console.log(item);
    },
  },
};
</script>
<style type="text/less" lang="less" scoped>
/deep/.el-autocomplete {
  height: 0.9rem;
  .el-input--suffix {
    height: 100%;
    .el-input__inner {
      height: 100%;
    }
  }
}
.Partilhar {
  -ms-flex-align: start;
  align-items: flex-start;
  display: -ms-flexbox;
  display: flex;
  min-height: 1.35rem;
  padding-bottom: 0.06rem;
  padding-top: 0.2rem;
  span {
    word-wrap: break-word;
    display: inline-block;
    font-size: 0.22rem;
    line-height: 1.15;
    max-width: 1.1rem;
    padding-top: 0.1rem;
    text-align: center;
  }
  .share {
    -ms-flex-align: center;
    -ms-flex-pack: center;
    align-items: center;
    border: 0.01rem solid var(--theme-color-line);
    border-radius: 50%;
    color: var(--theme-primary-color);
    display: -ms-flexbox;
    display: flex;
    font-size: 0.36rem;
    height: 0.7rem;
    justify-content: center;
    padding: 0;
    width: 0.7rem;
  }
}
/deep/.el-input__suffix {
  top: 30%;
}
.copyIcon {
  font-size: 0.3rem;
  // position: absolute;
  // right: 0.2rem;
  // top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: var(--theme-primary-color);
  cursor: pointer;
  // max-width: none;
}

.wrap {
  // background: #6E8DE6;
  // overflow: hidden;
  position: relative;
  // padding: .2rem .2rem 0;
  margin: 0;
}
.inviteInfo {
  background-color: var(--theme-main-bg-color);
  border-radius: 0.1rem;
  -webkit-box-shadow: 0 0.03rem 0.09rem 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 0.03rem 0.09rem 0 rgba(0, 0, 0, 0.06);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-bottom: 0.2rem;
  padding: 0.2rem;
  padding-top: 0.2rem;
  .title {
    // padding: 2vw 1vw 2vw;
    margin-bottom: 0.2rem;
    padding: 0 0.2rem;
    font-size: 0.24rem;
    // font-weight: bold;
    border-bottom: thin solid var(--theme-color-line);
    // margin-bottom: 2vw;
    color: var(--theme-text-color-darken);
  }

  .inviteInfoContent {
    display: flex;
    justify-content: space-between;
    // margin: 2vw 0;

    .qrcode {
      width: 1.5rem;
      margin-right: 0.2rem;
      min-height: 1.9rem;
      background: #fff;
      // overflow: hidden;
      border: 0.01rem solid var(--theme-color-line);
      border-radius: 0.14rem 0.14rem 0.2rem 0.2rem;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      box-sizing: content-box;
      .btn {
        border: 1px solid #d9d9d9;
        background-color: var(--theme-primary-color);
        border-radius: 0 0 0.14rem 0.14rem;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        color: var(--theme-primary-font-color);
        font-size: 0.24rem;
        height: auto;
        min-height: 0.4rem;
        padding-bottom: 0.05rem;
        white-space: pre-wrap;
        width: 1.5rem;
        word-break: break-word;
        line-height: 1.499;
        display: inline-block;
        font-weight: 400;
        touch-action: manipulation;
        padding: 0;
      }
    }

    .linkBox {
      // width: 68vw;
      width: 4.9rem;
      .myLink {
        margin-bottom: 0.1rem;
        color: var(--theme-text-color-lighten);
        display: flex;
        justify-content: space-between;
        // padding: 0 2vw;
        font-size: 0.24rem;
        align-items: center;
      }
      .link {
        width: 90%;
      }
      .inviteCode {
        -ms-flex-align: start;
        -ms-flex-pack: justify;
        align-items: flex-start;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        font-size: 0.24rem;
        justify-content: space-between;
        margin-top: 0.2rem;
        .subordinatesnum {
          color: var(--theme-text-color-lighten);
        }
        span {
          color: var(--theme-text-color-darken);
        }
        .copyIcon2 {
          color: var(--theme-primary-color);
          margin-left: 0.1rem;
          /deep/ .svg-icon {
            display: inline;
          }
        }
      }
    }
  }

  .bottomTips {
    font-size: 0.22rem;
    color: var(--theme-text-color-lighten);
    // margin-top: 6vw;
    span {
      color: var(--theme-primary-color);
      font-size: 0.22rem;
    }
  }
}

.tips {
  background-color: var(--theme-main-bg-color);
  // margin-top: 2vw;
  border-radius: 0.1rem;
  color: var(--theme-text-color-lighten);
  // padding: 2vw 1vw;
  text-align: center;
  padding: 0.2rem;
  // font-size: 3vw;
  box-shadow: 0 0.03rem 0.09rem 0 rgba(0, 0, 0, 0.06);
  height: auto;
  .title {
    -ms-flex-align: center;
    -ms-flex-pack: center;
    align-items: center;
    color: var(--theme-text-color-lighten);
    display: -ms-flexbox;
    display: flex;
    // font-size: .2rem;
    // height: .65rem;
    justify-content: center;
    text-align: center;
    font-size: 0.24rem;
    height: 0.75rem;
  }
  .bottomLayout {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    font-size: 0.16rem;
    .tr1 {
      font-size: 0.24rem;
      line-height: 0.7rem;

      -ms-flex-align: center;
      -ms-flex-pack: center;
      align-items: center;
      border-radius: 0.06rem !important;
      color: var(--theme-text-color-lighten);
      display: -ms-flexbox;
      display: flex;
      justify-content: center;
      min-height: 0.5rem;
      span {
        margin-left: 0.04rem;
        text-align: center;
        font-size: 0.24rem;
        line-height: 0.7rem;
      }
      span:nth-child(2) {
        color: var(--theme-text-color-darken);
      }
    }
    .tr1:nth-child(odd) {
      background-color: var(--theme-bg-color);
    }
  }
}

.taskRule {
  padding-bottom: 0;
  padding: 0 0.2rem;
  .ruleBody {
    background-color: var(--theme-main-bg-color);
    border-radius: 0.1rem;
    -webkit-box-shadow: 0 0.03rem 0.09rem 0 rgba(0, 0, 0, 0.06);
    box-shadow: 0 0.03rem 0.09rem 0 rgba(0, 0, 0, 0.06);
    color: var(--theme-text-color);
    font-size: 0.18rem;
    line-height: 1.56;
    padding: 0.2rem;
    white-space: pre-wrap;
    word-break: break-word;
    font-size: 0.24rem;
    line-height: 0.4rem;
    .fw400 {
      font-weight: 700;
    }
  }
}

.businessRules {
  font-size: 0.22rem;
  .treasureChest {
    padding: 0.2rem;
    background-color: var(--theme-main-bg-color);
    border-radius: 0.1rem;
    -webkit-box-shadow: 0 0.03rem 0.09rem 0 rgba(0, 0, 0, 0.06);
    box-shadow: 0 0.03rem 0.09rem 0 rgba(0, 0, 0, 0.06);
    margin: 0.2rem 0;
    // padding: 0.4rem;

    .boxBody {
      margin-top: 0;
      padding-bottom: 0;
      padding-top: 0.19rem;
      font-size: 0.22rem;

      .boxitem {
        padding-top: 0.12rem;
        box-sizing: border-box;
        .boxrow {
          -ms-flex-align: center;
          align-items: center;
          display: -ms-flexbox;
          display: flex;
          .boxnode {
            cursor: default;
            position: relative;
            width: 1.42rem;
            .titlebox {
              bottom: 0;
              left: 0;
              position: absolute;
              right: 0;
              top: 0;
              width: 1.32rem;
              .openRewardTitle {
                -ms-flex-align: center;
                align-items: center;

                display: -ms-flexbox;
                display: flex;
                color: var(--theme-secondary-color-error);
                height: 0.3rem;
                left: 0;
                position: absolute;
                right: 0;
                text-align: center;
                top: 0.4rem;
                > div {
                  padding: 0 0.12rem;
                  width: 100%;
                }
              }
              .title {
                bottom: 0.15rem;
                color: var(--theme-text-color-activities-text);
                font-size: 0.18rem;
                height: 0.4rem;
                padding: 0 0.21rem;
                text-align: center;
                align-items: center;
                display: flex;
                justify-content: center;
                left: 0;
                position: absolute;
                right: 0;
                .span1 {
                  width: 100%;
                  margin-bottom: -1.10231px;
                  padding-bottom: 1.10231px;
                  vertical-align: middle;
                  display: inline-block;
                  line-height: 0;
                  overflow: hidden;
                  .span2 {
                    vertical-align: middle;
                    display: inline-block;
                    width: 100%;
                    .span3 {
                      -webkit-line-clamp: 2;
                      -webkit-line-clamp: 2;
                      -webkit-box-orient: vertical;
                      display: -webkit-box;
                      line-height: 1.2;
                      overflow: hidden;
                      overflow: initial;
                      text-align: center;
                      text-overflow: ellipsis;
                      vertical-align: middle;
                      word-break: break-word;
                    }
                  }
                }
              }
            }
            .bottomTitle {
              -ms-flex-align: center;
              -ms-flex-pack: center;
              align-items: center;
              color: var(--theme-text-color-lighten);
              display: -ms-flexbox;
              display: flex;
              height: 0.5rem;
              justify-content: center;
              width: inherit;
              word-break: break-all;
              font-size: 0.2rem;
              span {
                font-size: 0.2rem;
                line-height: 1;
                text-align: center;
                word-break: break-all;
              }
            }
          }

          .toward {
            color: var(--theme-text-color-lighten);
            display: block;
            padding: 0 0.126rem;
          }
        }
        .fingerdown {
          color: var(--theme-text-color-lighten);
          -ms-flex-pack: end;
          display: -ms-flexbox;
          display: flex;
          justify-content: flex-end;
          margin-top: 0.12rem;
        }
      }
      .boxitem:nth-child(2n) {
        .boxrow {
          -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
        }
        .toward {
          -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
        }
        .fingerdown {
          -ms-flex-pack: start;
          justify-content: flex-start;
          svg {
            margin-left: 0.57rem;
            margin-right: 0;
          }
        }
      }
    }
  }
}


@-webkit-keyframes openAnimation {
    0% {
        background-position-x: 125%
    }

    to {
        background-position-x: -32%
    }
}

@keyframes openAnimation {
    0% {
        background-position-x: 125%
    }

    to {
        background-position-x: -32%
    }
}
.TipsInfo {
  color: var(--theme-text-color);
  font-size: 0.22rem;
  font-weight: 400;
  padding: 0.2rem;
  .btnbox {
    display: -ms-flexbox;
    display: flex;
    margin-top: 0.38rem;
  }
}
</style>
