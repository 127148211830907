<template>
  <section class="box">
    <div class="mine">
      <div class="my-scrollbar" style="overflow-x: hidden; overflow-y: scroll">
        <div class="my-scrollbar-wrap my-scrollbar-wrap-y">
          <div class="my-scrollbar-content">
            <div class="bg">
              <div class="userInfo">
                <div class="messageBox">
                  <div
                    class="messageBoxItem"
                    @click="toPath(1, '/notice?index=4')"
                  >
                    <div
                      style="width: 0.4rem; height: 0.4rem; position: relative"
                    >
                      <i
                        style="
                          display: inline-flex;
                          justify-content: center;
                          align-items: center;
                        "
                      >
                        <svg-icon
                          icon-class="style_1_icon_top_kf"
                          style="width: 1em; height: 1em; fill: currentColor"
                        ></svg-icon>
                      </i>
                    </div>
                    <span class="header-item-label">{{ $t('support') }}</span>
                  </div>
                  <div
                    class="messageBoxItem"
                    @click="toPath(1, '/notice?index=2')"
                  >
                    <div
                      style="width: 0.4rem; height: 0.4rem; position: relative"
                    >
                      <i
                        style="
                          display: inline-flex;
                          justify-content: center;
                          align-items: center;
                        "
                      >
                        <svg-icon
                          icon-class="style_1_icon_top_xxyd"
                          style="
                            width: 1em;
                            height: 1em;

                            fill: currentColor;
                          "
                        ></svg-icon>
                      </i>
                    </div>
                    <span class="header-item-label">{{ $t('messages') }}</span>
                  </div>
                  <div class="messageBoxItem" @click="toPath(1, '/setting')">
                    <div
                      style="width: 0.4rem; height: 0.4rem; position: relative"
                    >
                      <i
                        style="
                          display: inline-flex;
                          justify-content: center;
                          align-items: center;
                        "
                      >
                        <svg-icon
                          icon-class="style_1_icon_top_grzl"
                          style="
                            width: 1em;
                            height: 1em;

                            fill: currentColor;
                          "
                        ></svg-icon>
                      </i>
                    </div>
                    <span class="header-item-label">{{ $t('data') }}</span>
                  </div>
                </div>
                <div class="userInfoTop">
                  <div
                    class="faceUrl"
                    :style="
                      'width: 0.88rem;height: 0.88rem;background-position: 0px 0px;background-image: url(' +
                      this.user.avatar +
                      ');background-size: 100% 100%;'
                    "
                  ></div>
                  <div class="right">
                    <p class="account">
                      <span
                        style="
                          margin-right: 0.1rem;
                          color: var(--theme-text-color);
                        "
                      >
                        {{ $t('Conta') }}:</span
                      >
                      <span style="color: var(--theme-text-color-darken)">{{
                        user.account
                      }}</span>
                    </p>
                    <div class="coin">
                      <p
                        style="
                          -ms-flex-align: center;
                          -ms-flex-pack: center;
                          align-items: center;
                          display: -ms-flexbox;
                          display: flex;
                          justify-content: center;
                          margin-bottom: 0px;
                          margin-top: 0;
                          line-height: normal;
                        "
                      >
                        <span
                          style="
                            margin-right: 0.1rem;
                            color: var(--theme-text-color);
                          "
                        >
                          ID:</span
                        >
                        <span style="color: var(--theme-text-color-darken)">{{
                          user.account_number
                        }}</span>
                                                <div style="width: 0.28rem;height: 0.28rem;margin-left: .1rem;color: var(--theme-mine-icon-color);position: relative;">
                            <i style="display: inline-flex; justify-content: center; align-items: center; font-size: 0.28rem;left: 0; position: absolute; top: 0;">
                               <svg-icon  v-copy="user.id" icon-class="style_1_icon_copy"  style=" width: 1em;height: 1em; fill: currentColor; "></svg-icon></i></div>
                      </p>
                      <span class="line"></span>
                      <div class="money">
                        <i class="ico"></i>
                        <div class="m">
                          <span class="notranslate">{{ coinStr }}</span>
                        </div>
                        <div
                          class="refresh animate__spin animate__infinite"
                          :class="{ animate__animated: isAnimateAnimated }"
                          @click="refreshUserInfo()"
                        >
                          <i
                            style="
                              left: 0;
                              position: absolute;
                              top: 0;
                              display: inline-flex;
                              justify-content: center;
                              align-items: center;
                              font-size: 0.28rem;
                            "
                          >
                            <svg-icon
                              icon-class="comm_icon_sx"
                              style="
                                width: 1em;
                                height: 1em;
                                color: var(--theme-alt-primary);
                                fill: currentColor;
                                font-size: 0.293rem;
                              "
                            ></svg-icon>
                          </i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <div>
                    <div class="message" style="text-align: center;">
                        <i class="el-icon-message"></i>
                        <div>Mensagens</div>
                    </div>
                </div> -->
                </div>
              </div>

              <ul class="Recuperar">
                <li class="item" @click="toPath(1, 'saque')">
                  <div class="icobg">
                    <i>
                      <svg-icon
                        icon-class="style_1_icon_mid_tx"
                        style="width: 1em; height: 1em; fill: currentColor"
                      ></svg-icon>
                    </i>
                  </div>
                  <span class="span1"
                    ><span class="span2">
                      <span class="span3">{{ $t('withdraw') }}</span>
                    </span></span
                  >
                </li>
                <li @click="showSheet" class="item">
                  <div class="icobg">
                    <i>
                      <svg-icon
                        icon-class="style_1_icon_mid_cz"
                        style="width: 1em; height: 1em; fill: currentColor"
                      ></svg-icon>
                    </i>
                  </div>
                  <span class="span1"
                    ><span class="span2">
                      <span class="span3">{{ $t('deposit') }}</span>
                    </span></span
                  >
                </li>
                <li @click="toJuros" class="item">
                  <div class="icobg">
                    <i>
                      <svg-icon
                        icon-class="style_1_icon_mid_lxb"
                        style="width: 1em; height: 1em; fill: currentColor"
                      ></svg-icon>
                    </i>
                  </div>
                  <span class="span1"
                    ><span class="span2">
                      <span class="span3">{{ $t('fees') }}</span>
                    </span></span
                  >
                </li>
              </ul>
              <div class="vipBoxTop">
                <div class="vipbody">
                  <div class="vipInfo">
                    <div class="left">
                      <div class="vipLevel">
                        <img src="@/assets/img_vip.png" />
                        <span>0</span>
                      </div>
                      <p class="vipInfoRight">
                        <span class="Restantes">{{ $t('remaining') }}</span>VIP{{ user.vip
                        }}<span class="Restantes"
                          >{{ $t('remaining amount for deposit') }}</span
                        >
                        {{ dp }},
                        <span class="aposta">{{ $t('remaining amount to bet') }}</span>
                        {{ bp }}
                      </p>
                    </div>
                    <i class="right">
                      <svg-icon
                        icon-class="comm_icon_fh"
                        style="
                          width: 0.24rem;
                          height: 0.24rem;
                          fill: currentColor;
                          color: var(--theme-primary-font-color);
                          transform: rotate(180deg);
                        "
                      ></svg-icon>
                    </i>
                  </div>
                  <div class="vip">
                    <div class="left">
                      <div class="level">
                        <img src="@/assets/color1.png" />
                        <img src="@/assets/img_dj0.png" />
                        <span>0</span>
                      </div>
                    </div>

                    <div class="right">
                      <div class="progress">
                        <div class="label">{{ $t('deposit for promotion') }}</div>
                        <div class="progressBox">
                          <div class="num">
                            {{ user.deposit / 100 }} /
                            {{ nextVipConfig.deposit }}
                          </div>
                          <el-progress
                            :show-text="false"
                            :stroke-width="10"
                            :percentage="rechargeProgress"
                            define-back-color="rgba(0,0,0,.08)"
                            color="var(--theme-secondary-color-success)"
                          ></el-progress>
                        </div>
                      </div>
                      <div class="progress">
                        <div class="label">{{ $t('necessary bet') }}</div>
                        <div class="progressBox">
                          <div class="num">
                            {{ user.bet / 100 }} / {{ nextVipConfig.flow }}
                          </div>
                          <el-progress
                            :show-text="false"
                            :stroke-width="10"
                            :percentage="betProgress"
                            define-back-color="rgba(0,0,0,.08)"
                            color="var(--theme-secondary-color-success)"
                          ></el-progress>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="menulist">
                <ul class="Detalhes">
                  <li class="item" @click="toPath(1, 'centerWallet')">
                    <div class="left">
                      <div class="icobg">
                        <i
                          style="
                            display: inline-flex;
                            justify-content: center;
                            align-items: center;
                            font-size: 0.4rem;
                          "
                        >
                          <svg-icon
                            icon-class="style_1_icon_list_zhye"
                            style="width: 1em; height: 1em; fill: currentColor"
                          ></svg-icon>
                        </i>
                      </div>
                      <span>{{$t('recover balance')}}</span>
                    </div>

                    <div class="right">
                      <i>
                        <svg-icon
                          icon-class="comm_icon_fh"
                          style="
                            color: var(--theme-color-line);
                            height: 0.24rem;
                            -webkit-transform: rotate(180deg);
                            transform: rotate(180deg);
                            width: 0.24rem;
                          "
                        ></svg-icon>
                      </i>
                    </div>
                  </li>
                  <li class="item" @click="toPath(1, 'report')">
                    <div class="left">
                      <div class="icobg">
                        <i
                          style="
                            display: inline-flex;
                            justify-content: center;
                            align-items: center;
                            font-size: 0.4rem;
                            color: var(--theme-secondary-color-finance);
                          "
                        >
                          <svg-icon
                            icon-class="style_1_icon_list_zhmx"
                            style="width: 1em; height: 1em; fill: currentColor"
                          ></svg-icon>
                        </i>
                      </div>
                      <span>{{ $t('Conta') }}</span>
                    </div>

                    <div class="right">
                      <i>
                        <svg-icon
                          icon-class="comm_icon_fh"
                          style="
                            color: var(--theme-color-line);
                            height: 0.24rem;
                            -webkit-transform: rotate(180deg);
                            transform: rotate(180deg);
                            width: 0.24rem;
                          "
                        ></svg-icon>
                      </i>
                    </div>
                  </li>

                  <li class="item" @click="toPath(1, 'report')">
                    <div class="left">
                      <div class="icobg">
                        <i
                          style="
                            display: inline-flex;
                            justify-content: center;
                            align-items: center;
                            font-size: 0.4rem;
                            color: var(--theme-secondary-color-success);
                          "
                        >
                          <svg-icon
                            icon-class="style_1_icon_list_tzjl"
                            style="width: 1em; height: 1em; fill: currentColor"
                          ></svg-icon>
                        </i>
                      </div>
                      <span>{{ $t('bets') }}</span>
                    </div>

                    <div class="right">
                      <i>
                        <svg-icon
                          icon-class="comm_icon_fh"
                          style="
                            color: var(--theme-color-line);
                            height: 0.24rem;
                            -webkit-transform: rotate(180deg);
                            transform: rotate(180deg);
                            width: 0.24rem;
                          "
                        ></svg-icon>
                      </i>
                    </div>
                  </li>

                  <li class="item" @click="toPath(1, 'report')">
                    <div class="left">
                      <div class="icobg">
                        <i
                          style="
                            display: inline-flex;
                            justify-content: center;
                            align-items: center;
                            font-size: 0.4rem;
                          "
                        >
                          <svg-icon
                            icon-class="style_1_icon_list_grbb"
                            style="width: 1em; height: 1em; fill: currentColor"
                          ></svg-icon>
                        </i>
                      </div>
                      <span>{{ $t('report') }}</span>
                    </div>

                    <div class="right">
                      <i>
                        <svg-icon
                          icon-class="comm_icon_fh"
                          style="
                            color: var(--theme-color-line);
                            height: 0.24rem;
                            -webkit-transform: rotate(180deg);
                            transform: rotate(180deg);
                            width: 0.24rem;
                          "
                        ></svg-icon>
                      </i>
                    </div>
                  </li>
                  <li class="item" @click="toPath(1, 'saque')">
                    <div class="left">
                      <div class="icobg">
                        <i
                          style="
                            display: inline-flex;
                            justify-content: center;
                            align-items: center;
                            font-size: 0.4rem;
                            color: var(--theme-secondary-color-error);
                          "
                        >
                          <svg-icon
                            icon-class="style_1_icon_list_txgl"
                            style="width: 1em; height: 1em; fill: currentColor"
                          ></svg-icon>
                        </i>
                      </div>
                      <span>{{ $t('withdrawal management') }}</span>
                    </div>

                    <div class="right">
                      <i>
                        <svg-icon
                          icon-class="comm_icon_fh"
                          style="
                            color: var(--theme-color-line);
                            height: 0.24rem;
                            -webkit-transform: rotate(180deg);
                            transform: rotate(180deg);
                            width: 0.24rem;
                          "
                        ></svg-icon>
                      </i>
                    </div>
                  </li>
                </ul>
                <div class="line"></div>
                <ul class="setting">
                  <li
                    class="settingsItem"
                    v-for="(item, index) in settingList"
                    :key="index"
                    @click="toPath(item.type, item.path)"
                  >
                    <div class="left">
                      <div
                        style="
                          width: 0.4rem;
                          height: 0.4rem;
                          position: relative;
                          color: var(--theme-primary-color);
                        "
                      >
                        <i>
                          <svg-icon
                            :icon-class="item.icon"
                            style="width: 1em; height: 1em; fill: currentColor"
                          ></svg-icon>
                        </i>
                      </div>
                      <span>{{ item.name }}</span>
                    </div>
                    <div class="right">
                      <i>
                        <svg-icon
                          icon-class="comm_icon_fh"
                          style="
                            color: var(--theme-color-line);
                            height: 0.24rem;
                            -webkit-transform: rotate(180deg);
                            transform: rotate(180deg);
                            width: 0.24rem;
                          "
                        ></svg-icon>
                      </i>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <van-dialog
              v-model:show="dialogVisible"
              :message="$t('exit account tips')"
              className="custom_Popup"
              :title="$t('reminder')"
              :show-cancel-button="true"
              :confirmButtonText="$t('cancel')"
              :cancelButtonText="$t('confirm exit')"
              @cancel="logoutBtn"
              style="width: 6rem"
            >
            </van-dialog>
          </div>
        </div>
      </div>
    </div>
    <tabBar ref="tabBar" :tabBarActive="4"></tabBar>
  </section>
</template>
<script>
import { mapState ,mapActions} from "vuex";
import tabBar from "../../components/phone/tabBar.vue";
import {
  formatNumberToString2,
  arraysOfObjectsAreEqual,
  formatNumberToString,
} from "@/utils/utils";
import { getconfigFetch } from "@/api/index";


export default {
  components: {
    tabBar,
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
      token: state => state.user.token,
    }),
  },
  mounted(){
    // if(this.user){
    //   this.refreshUserInfo();
    // }
  
  },
  watch: {
    user(u) {
      if(u){
        // this.refreshUserInfo();
        this.showAD();
      }
  },
  },
  data() {
    return {
      settingList: [
        {
          name: this.$t('toinvite') ,
          icon: "style_1_icon_list_tgzq",
          type: 1,
          path: "/agent",
        },
        {
          name: this.$t('data'),
          icon: "style_1_icon_list_grzl",
          type: 1,
          path: "/setting",
        },
        {
          name: this.$t('security'),
          icon: "style_1_icon_list_aqzx",
          type: 1,
          path: "/security",
        },
        {
          name: this.$t('music'),
          icon: "style_1_icon_list_yy",
          type: 33,
          path: "",
        },
        {
          name: this.$t('faq'),
          icon: "style_1_icon_list_cjwt",
          type: 1,
          path: "/notice?index=4",
        },
        {
          name: this.$t('suggestion bonus'),
          icon: "style_1_icon_list_yjfk",
          type: 1,
          path: "/about",
        },
        {
          name: this.$t('log in to the device'),
          icon: "style_1_icon_list_dlsb",
          type: 1,
          path: "/deviceInfo",
        },
        {
          name: this.$t('about'),
          icon: "style_1_icon_list_gywm",
          type: 1,
          path: "/about",
        },
        {
          name: this.$t('exit account'),
          icon: "style_1_icon_list_aqtc",
          type: 2,
          path: "/home",
        },
      ],
      dialogVisible: false,
      isAnimateAnimated: false,
 
      coin: 0,
      coinStr: "00.00",
      vipConfig: [],
      dp: "0,00",
      bp: "0,00",
      nextVipConfig: {},
      rechargeProgress: 0,
      betProgress: 0,
    };
  },
  activated() {
    this.$refs.tabBar.initBar(4);
    this.getInfo();
    this.getVipconfig();
  },
  methods: {
    ...mapActions("musicPlayer", [
      "setOpenMusicBox",
    ]),
    getVipconfig() {
      getconfigFetch({ key: "vip" }).then(({ data }) => {
        const d = JSON.parse(data.data.data);
        this.vipConfig = d;
       this.updateLevelProgress();
      });
    },
    getInfo() {
      if (!this.$store.getters.token) {
        this.$router.replace({
          path: "/",
        });
      }
      this.refreshUserInfo();
    },
    toPath(type, path) {
      if (type === 1) {
        this.$router.push({
          path,
          query: {},
        });
      } else if (type === 2) {
        this.logout();
      }
      else if(type===33){
        this.setOpenMusicBox(true);
      }
    },
    logout() {
      this.dialogVisible = true;
    },
    logoutBtn() {
      this.dialogVisible = false;
      // localStorage.setItem("token", "");
      this.$store.dispatch("LogOut").then(() => {

        this.$router.replace({
          path: "/",
        });
      });
    },
    toVip() {
      this.$router.push({
        path: "/Promotion",
        query: {
          index: 1,
        },
      });
    },
    toJuros() {
      this.$router.push({
        path: "/Promotion",
        query: {
          index: 4,
        },
      });
    },
    showAD() {
      const self=this;
      
      if (self.user.balance) {
        const balance = self.user.balance;
        let increment = Math.abs(balance - 0); 
        let steps = Math.ceil(increment / 20); 
        if (self.coin < balance) {
        const c = setInterval(function () {
          if (self.coin < balance) {
            self.coin += steps;

          } else {
            self.coin = balance;
            clearInterval(c);
          }
          self.coinStr = formatNumberToString(self.coin);
        }, 100);
      } else {
        self.coin = balance;
        self.coinStr = formatNumberToString(self.coin);
      }
      }
      self.isAnimateAnimated = false;
    },
    showSheet() {
      this.$refs.tabBar.show();
    },
    updateLevelProgress(){
      const index = this.vipConfig.findIndex(
          (e) => e.vip === this.user.vip + 1
        );
        if(index>-1){
          const bet = this.user.bet / 100;
            const deposit = this.user.deposit / 100;
            // nextvip=this.vipConfig[i];
            this.bp = formatNumberToString2(
              bet > this.vipConfig[index].flow
                ? 0
                : (this.vipConfig[index].flow - bet) * 100
            );
            this.dp = formatNumberToString2(
              deposit > this.vipConfig[index].deposit
                ? 0
                : (this.vipConfig[index].deposit - deposit) * 100
            );
            this.nextVipConfig = this.vipConfig[index];

            var r = parseInt((deposit / this.nextVipConfig.deposit) * 100);
            var b = parseInt((bet / this.nextVipConfig.flow) * 100);
            this.rechargeProgress = r > 100 ? 100 : r;
            this.betProgress = b > 100 ? 100 : b;
        }
    },
    refreshUserInfo() {
      if (this.isAnimateAnimated) {
        return;
      }
      const self = this;
      this.isAnimateAnimated = true;
      this.$store.dispatch("getUserInfo").then(() => {
        // const balance = self.user.balance;
        // let nextvip={};
        self.updateLevelProgress();
        self.isAnimateAnimated = false;
      });
    },
  },
};
</script>
<style lang="less" scoped>
// .my-scrollbar-wrap{
//   height: calc(var(--vh, 1vh) * 100);
// }
.box {
  --header-offset-top: 0.16rem;
  //height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}
.bg {
  background-color: var(--theme-main-bg-color);
  background-repeat: no-repeat;
  opacity: 1;
  background-position: 0px 0px;
  background-image: url("../../assets/style_1_topbg_yd.png");
  background-size: 100%;
  box-sizing: border-box;
  // height: 2000px;
}
.mine {
  // background: var(--theme-bg-color);
  height: calc(100vh - 1.24rem);
  height: calc(var(--vh, 1vh) * 100 - 1.24rem);
  box-sizing: border-box;

  .userInfo {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 0.1rem 0.24rem;
    position: relative;

    .messageBox {
      -ms-flex-pack: end;
      display: -ms-flexbox;
      display: flex;
      font-size: 0.2rem;
      justify-content: flex-end;
      color: var(--theme-text-color-darken);

      .messageBoxItem {
        -ms-flex-align: center;
        align-items: center;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        color: var(--theme-primary-color);
        font-size: 0.4rem;
        .header-item-label {
          font-size: 0.2rem;
          display: block;
          height: 0.5rem;
          overflow: hidden;
          text-align: center;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 0.9rem;
        }
      }
    }

    .userInfoTop {
      display: flex;
      .faceUrl {
        border-radius: 50%;
        margin-right: 0.1rem;
        opacity: 1;
      }
      .right {
        -ms-flex-pack: center;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        justify-content: center;
        .account {
          font-size: 0.24rem;
          margin-bottom: 0.09rem;
          // margin-bottom: 0px;
          margin-top: 0;
          line-height: normal;
        }
        .coin {
          -ms-flex-align: center;
          align-items: center;
          display: -ms-flexbox;
          display: flex;
          font-size: 0.32rem;
          .line {
            background: var(--theme-text-color-darken);
            display: block;
            height: 0.26rem;
            margin: 0 0.16rem;
            opacity: 0.1;
            width: 0.01rem;
          }

          .money {
            -ms-flex-align: center;
            -ms-flex-pack: center;
            align-items: center;
            display: -ms-flexbox;
            display: flex;
            justify-content: center;
            font-size: 0.32rem;
            .ico {
              display: inline-block;
              position: relative;
              width: 0.34rem;
              height: 0.34rem;
              background-image: url("../../assets/814195b6-3e29-450b-8c2b-bdb30e15091f.png");
              background-position: -8.11143rem -3.16929rem;
              background-size: 8.81571rem 8.60321rem;
            }
            .m {
              margin: 0 0.1rem;
              .notranslate {
                color: var(--theme-text-color-darken);
              }
            }

            .refresh {
              width: 0.28rem;
              height: 0.28rem;
              color: var(--theme-mine-icon-color);
              position: relative;
            }
          }
        }
      }
    }
  }

  .Recuperar {
    -ms-flex-pack: distribute;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-around;
    margin-bottom: 0.24rem;
    margin-top: 0.32rem;
    font-size: 0.22rem;
    text-align: center;
    .item {
      -ms-flex-align: center;
      align-items: center;
      display: -ms-flexbox;
      display: flex;
      -ms-flex: 1;
      flex: 1;
      -ms-flex-direction: column;
      flex-direction: column;
      font-size: 0.22rem;
      position: relative;
      text-align: center;
      color: var(--theme-text-color-darken);
      .icobg {
        width: 0.53rem;
        height: 0.53rem;
        position: relative;
        color: var(--theme-mine-icon-color);
        i {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          font-size: 0.53rem;
          left: 0;
          position: absolute;
          top: 0;
        }
      }
      .span1 {
        width: 1.52rem;
        margin-bottom: -1.31689px;
        padding-bottom: 1.31689px;
        display: inline-block;
        margin-top: 0.08rem;
        vertical-align: middle;

        line-height: 0;
        overflow: hidden;
        .span2 {
          font-size: 11.1936px;
          vertical-align: middle;
          display: inline-block;
          width: 100%;
          .span3 {
            -webkit-line-clamp: 2;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            line-height: 1.2;
            overflow: initial;
            text-align: center;
            text-overflow: ellipsis;
            vertical-align: middle;
            word-break: break-word;
          }
        }
      }
    }
  }

  .vipBoxTop {
    padding: 0 0.24rem;
    position: relative;
    // width: 100%;
    z-index: 2;
    .vipbody {
      -webkit-box-shadow: 0 0.1rem 0.24rem rgbc(0, 0, 0, 0.1);
      box-shadow: 0 0.1rem 0.24rem rgbc(0, 0, 0, 0.1);
      background-color: var(--theme-primary-color);
      border-radius: 0.16rem;
      color: var(--theme-primary-font-color);
      padding: 0.19rem 0.24rem;
      .vipInfo {
        -ms-flex-align: center;
        -ms-flex-pack: justify;
        align-items: center;
        border-bottom: 0.01rem solid rgba(0, 0, 0, 0.1);
        display: -ms-flexbox;
        display: flex;
        justify-content: space-between;
        padding-bottom: 0.2rem;
        width: 100%;

        .left {
          -ms-flex-align: center;
          align-items: center;
          display: -ms-flexbox;
          display: flex;
          font-size: 0.24rem;
          line-height: 1;
          .vipLevel {
            background-color: rgb(36, 178, 153);
            -ms-flex-align: center;
            -ms-flex-pack: center;
            align-items: center;
            border-radius: 0.14rem 0 0.14rem 0;
            color: #e5b952;
            display: -ms-inline-flexbox;
            display: inline-flex;
            font-size: 0.18rem;
            font-weight: 700;
            height: 0.32rem;
            justify-content: center;
            margin-right: 0.2rem;
            width: 0.77rem;
            position: relative;

            img {
              height: 0.15rem;
              width: 0.32rem;
            }

            span {
              color: transparent;
              display: inline-block;
              font-style: italic;
              font-weight: 700;
              position: relative;
              text-shadow: 0 0.01rem 0 rgba(0, 0, 0, 0.4);
              z-index: 2;
            }
          }

          .vipInfoRight {
            -ms-flex-align: center;
            align-items: center;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-flow: row wrap;
            flex-flow: row wrap;

            .Restantes {
              margin: 0 0.1rem;
              opacity: 0.6;
            }
            .aposta {
              margin: 0 0.05rem 0 0.1rem;
              opacity: 0.6;
            }
          }
        }

        .right {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          opacity: 0.27;
        }
      }

      .vip {
        -ms-flex-align: center;
        align-items: center;
        border-top: 0.01rem solid hsla(0, 0%, 100%, 0.2);
        display: -ms-flexbox;
        display: flex;
        -ms-flex: 1;
        flex: 1;
        padding-top: 0.2rem;
        .left {
          -ms-flex-align: center;
          -ms-flex-pack: center;
          align-items: center;
          background-position: 50%;
          background-repeat: no-repeat;
          background-size: contain;
          display: -ms-flexbox;
          display: flex;
          justify-content: center;
          margin: auto;
          position: relative;
          text-align: center;
          flex: 0 0 0.9rem;
          width: 0.9rem;
          height: 0.9rem;
          font-size: 0.327273rem;
          .level {
            -ms-flex-align: center;
            -ms-flex-pack: center;
            align-items: center;
            display: -ms-flexbox;
            display: flex;
            justify-content: center;

            img {
              width: 0.9rem;
              height: 0.9rem;
              position: absolute;
            }

            span {
              color: #f7ea94;
              display: inline-block;
              font-style: normal;
              font-weight: 700;
              position: relative;
              // text-shadow: 0 .01rem 0 rgba(0,0,0,.4);
            }
          }
        }

        .right {
          grid-gap: 0.1rem;
          -ms-flex-align: center;
          align-items: center;
          display: grid;
          -ms-flex: 1;
          flex: 1;
          font-size: 0.2rem;
          gap: 0.1rem;
          grid-template-columns: auto;
          grid-template-rows: auto auto;
          margin-left: 0.2rem;
          .progress {
            display: grid;
            -ms-flex: 1;
            flex: 1;
            grid-template-columns: auto 1fr;

            /deep/.el-progress {
              width: 100%;
              height: 0.2rem;
            }
            /deep/.el-progress-bar__outer {
              height: 0.2rem !important;
            }
            .label {
              font-size: 0.2rem;
              vertical-align: middle;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              display: -webkit-box;
              line-height: 1.2;
              overflow: initial;
              text-align: center;
              text-overflow: ellipsis;
              vertical-align: middle;
              word-break: break-word;
              text-align: left;
              max-width: 1.5rem;
            }

            .progressBox {
              height: 0.2rem;
              overflow: hidden;
              position: relative;
              width: 100%;
              .num {
                color: #fff;
                line-height: 0.2rem;
                position: absolute;
                text-align: center;
                top: 0;
                width: 100%;
                z-index: 1;
              }
            }
          }
        }
      }
    }
  }

  .menulist {
    font-size: 0.24rem;

    .Detalhes {
      .item {
        color: var(--theme-primary-color);
        padding: 0.3rem 0.44rem;
        -ms-flex-pack: justify;
        display: -ms-flexbox;
        display: flex;
        justify-content: space-between;
        .left {
          -ms-flex: 1;
          flex: 1;
          overflow: hidden;
          align-items: center;
          display: flex;
          .icobg {
            width: 0.4rem;
            height: 0.4rem;
          }
          span {
            color: var(--theme-text-color-darken);
            margin-left: 0.24rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        .right {
          justify-content: end;
          -ms-flex-negative: 0;
          -ms-flex-align: center;
          align-items: center;
          display: -ms-flexbox;
          display: flex;
          flex-shrink: 0;
          i {
            margin-left: 0.1rem;
            display: inline-flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
    .line {
      background-color: var(--theme-bg-color);
      height: 0.2rem;
      width: 100;
    }
    .setting {
      .settingsItem {
        color: var(--theme-primary-color);
        padding: 0.3rem 0.44rem;
        justify-content: space-between;
        display: flex;

        .left {
          width: 2.6rem;
          flex: 1;
          overflow: hidden;
          display: flex;
          align-items: center;
          i {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            font-size: 0.4rem;
            left: 0;
            position: absolute;
            top: 0;
          }
          span {
            color: var(--theme-text-color-darken);
            margin-left: 0.24rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .right {
          justify-content: end;
          align-items: center;
          display: flex;
        }
      }

      .settingsItem:last-child {
        border: none;
      }
    }
  }
}

/deep/.el-dialog {
  background: var(--theme-main-bg-color);
  // border-radius: 1vw;
}

/deep/.el-dialog__close {
  color: #fff !important;
}

/deep/.el-dialog__body {
  padding-top: 0;
}


/deep/.el-dialog__title {
  color: #fff;
}
</style>
