<template>
  <div>
    <div class="box" v-if="!securityInfo.withdraw_password">
      <el-container>
        <el-header height="0.9rem">
          <van-nav-bar
            title="Senha de Saque"
            :left-arrow="true"
            @click-left="onClickLeft"
            :fixed="true"
        /></el-header>
        <el-main>
          <div class="con">
            <div class="tips">
              {{ $t("withdrawal password first tip1") }}
            </div>
            <div class="title">{{ $t("set withdrawal password") }}</div>
            <div style="margin-bottom: 0.5rem">
              <myNumberPassInput
                :passvalue.sync="psw1"
                title="$t('confirm new withdrawal password2')"
                @leave="leaveInputPass"
                @change="inputChange"
              ></myNumberPassInput>
            </div>
            <div class="err-explain" v-if="showerr1">
              {{ $t("withdrawal password err1") }}
            </div>
            <div style="margin-bottom: 0.5rem">
              <myNumberPassInput
                :passvalue.sync="psw2"
                :title="$t('confirm new withdrawal password')"
                @leave="leaveInputPass2"
                @change="inputChange2"
              ></myNumberPassInput>
            </div>
            <div class="err-explain" v-if="showerr2">
              {{ $t("password err") }}
            </div>
            <p class="atencao">
              {{ $t("set withdrawal password tips1") }}
            </p>
          </div>
          <div class="submitBtn">
            <div class="verification">
              <van-button
                @click="updateWP"
                size="large"
                color="var(--theme-primary-font-color)"
                :block="true"
                :plain="true"
                :loading="submitloading"
                type="primary"
                style="
                  font-size: 0.24rem;
                  height: 0.7rem;
                  margin-right: 0 !important;
                  width: 7.1rem !important;
                  border-radius: 0.14rem;
                  background-color: var(--theme-primary-color);
                  border-color: var(--theme-primary-color);
                "
                >{{ $t("confirm") }}</van-button
              >
            </div>
          </div>
        </el-main>
      </el-container>
    </div>
    <section v-else>
      <div class="controlboard">
        <van-tabs v-model="active" swipe-threshold="1">
          <van-tab
            v-for="(item, index) in tab_title"
            title-style="
              height: 0.9rem;
              line-height: 0.9rem;
              margin-right: 0.6rem;
              font-size: 0.24rem;
              margin-left: 0rem;
            "
            :to="'/saque?current=' + index"
            :replace="true"
          >
            <template slot="title">
              <div class="tabTitle">
                <div>
                  <span>{{ item }}</span>
                </div>
              </div>
            </template>
            <cash v-if="index === 0"></cash>
            <!-- <betTask v-if="index === 1"></betTask> -->
            <addCard v-if="index === 1"></addCard>
            <records v-if="index === 2"></records>
          </van-tab>
        </van-tabs>
        <div class="back" @click="onClickLeft">
          <i
            style="
              display: inline-flex;
              justify-content: center;
              align-items: center;
            "
            class="backIco"
          >
            <svg-icon
              icon-class="comm_icon_fh"
              style="
                width: 1em;
                height: 1em;
                color: var(--theme-text-color);
                fill: var(--theme-text-color-lighten);
              "
            ></svg-icon
          ></i>
        </div>
        <div class="customerService">
          <div class="icobg">
            <i
              style="
                display: inline-flex;
                justify-content: center;
                align-items: center;
              "
            >
              <svg-icon
                icon-class="icon_sys_menu_service"
                style="
                  width: 1em;
                  height: 1em;

                  fill: currentColor;
                "
              ></svg-icon>
            </i>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
// 引入模块后自动生效

import { getSecurityInfo, setWithdrawPassword } from "@/api/index";

import { arraysOfObjectsAreEqual } from "@/utils/utils";
import cash from "@/components/phone/withdraw/cash.vue";
import myNumberPassInput from "@/components/phone/common/myNumberPassInput.vue";
import betTask from "@/components/phone/withdraw/betTask.vue";
import addCard from "@/components/phone/withdraw/addCard.vue";
import records from "@/components/phone/withdraw/records.vue";

export default {
  name: "",
  components: { cash, myNumberPassInput, betTask, addCard, records },
  mixins: [],
  props: {},
  watch: {
    $route(to, from) {
      // if (to.query.tab === 'tab2') {
      //   // 切换到指定标签页的逻辑
      //   this.activeTab = 'tab2'; // 假设activeTab是用来控制当前激活标签页的变量
      // }
      if (to.query.current) {
        if (this.active != Number(to.query.current)) {
          this.active = Number(to.query.current);
        }
      }
    },
  },
  data() {
    return {
      psw1: "",
      psw2: "",
      tab_title: [
        this.$t("request withdrawal"),
        // "Tarefas de Apostas",
        this.$t("account for receipt"),
        this.$t("service Record"),
      ],
      securityInfo: {},

      // loadconfig: false,
      submitloading: false,
      active: 0,
      showerr1: false,
      showerr2: false,
    };
  },
  computed: {},
  activated() {
    // this.active = Number(this.$route.query.current);
    if (this.$route.query.current) {
      if (this.active != Number(this.$route.query.current)) {
        this.active = Number(this.$route.query.current);
      }
    } else {
      this.active = 0;
    }
  },
  mounted() {
    this.getSecurityInfo();
  },
  methods: {
    onClickLeft() {
      history.go(-1);
    },
    leaveInputPass(v) {
      if (v.length < 6) {
        this.showerr1 = true;
      }
    },
    leaveInputPass2(v) {
      if (v.length < 6) {
        this.showerr2 = true;
      }
    },
    inputChange(v) {
      if (v.length === 6) {
        this.showerr1 = false;
      }
    },
    inputChange2(v) {
      if (v.length === 6) {
        this.showerr2 = false;
      }
    },
    updateWP() {
      if (this.psw1 === this.psw2 && this.psw1.length === 6) {
        this.submitloading = true;
        setWithdrawPassword({ password: this.psw1 }).then(({ data }) => {
          this.submitloading = false;
          this.securityInfo.withdraw_password = true;
        });
      }
    },
    getSecurityInfo() {
      // this.loadconfig = true;
      getSecurityInfo().then(({ data }) => {
        // if (!arraysOfObjectsAreEqual(this.securityInfo, data.data)) {
        this.securityInfo = data.data;
        // this.loadconfig = false;
        // }
      });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .el-main {
  padding: 0;
}
.box {
  // padding:3vw;
  // padding-top: 9vh;
  // background:#7593E8;
  // min-height:91vh;
  // font-size:.22rem;
  max-width: var(--theme-max-width);
  .con {
    padding: 0.4rem 0.2rem 0.2rem;

    .tips {
      color: var(--theme-secondary-color-success);
      font-size: 0.18rem;
      margin-bottom: 0.3rem;
      text-align: center;
      font-size: 0.22rem;
      line-height: 1.5;
      font-variant: tabular-nums;
      list-style: none;
    }
    .title {
      -ms-flex-align: center;
      -ms-flex-pack: justify;
      align-items: center;
      color: var(--theme-text-color-darken);
      display: -ms-flexbox;
      display: flex;
      font-size: 0.2rem;
      font-weight: 700;
      justify-content: space-between;
      margin-bottom: 0.3rem;
      font-size: 0.24rem;
      margin-bottom: 0.4rem;
      margin-top: 0;
    }
  }
}
.van-nav-bar {
  background: var(--theme-main-bg-color);
  border-bottom: 0.01rem solid var(--theme-color-line);
  border-radius: 0px;
  box-shadow: none;
  height: 0.9rem;
  left: 0px;
  position: fixed;
  top: 0px;
  z-index: 200;
  right: 0px;
  margin: 0 auto;
  max-width: var(--theme-max-width);
  /deep/.van-nav-bar__title {
    color: #fff;
  }

  /deep/.van-nav-bar__arrow {
    color: #fff;
  }

  /deep/.van-nav-bar__text {
    color: #fff;
  }
}

.salary-container {
  margin-bottom: 0.5rem;
  /deep/.van-password-input {
    margin: auto;
  }
  /deep/ .van-password-input__security {
    border-radius: 0.14rem;
    height: 1rem;
    .van-password-input__item {
      background: var(--theme-main-bg-color);
    }
  }

  .label {
    font-size: 0.22rem;
    height: 0.32rem;
    margin-bottom: 0.15rem;
    color: var(--theme-text-color-darken);
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
}
.submitBtn {
  -ms-flex-align: center;
  -ms-flex-pack: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  width: 100%;
  .verification {
    -ms-flex-pack: center;
    background-color: var(--theme-main-bg-color);
    bottom: 0;
    -webkit-box-shadow: 0 -0.03rem 0.1rem 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 -0.03rem 0.1rem 0 rgba(0, 0, 0, 0.1);
    display: -ms-flexbox;
    display: flex;
    height: 1.3rem;
    justify-content: center;
    padding-top: 0.2rem;
    position: fixed;
    width: var(--theme-max-width);
    left: auto !important;
    margin: 0 auto;
    max-width: 450px;
    max-width: var(--theme-max-width);
  }
}
.atencao {
  color: var(--theme-secondary-color-finance);
  font-size: 0.22rem;
  margin-bottom: 0.5rem;
}
.controlboard {
  position: relative;
  /deep/.van-tabs__nav {
    margin-left: 0.95rem;
    margin-right: 0.3rem;
    width: 5.8rem;
  }
  /deep/.van-tabs__line {
    background-color: var(--theme-primary-color);
    bottom: 0;
    height: auto;
    height: 0.03rem;
  }
  /deep/.van-tabs__nav {
    background: transparent;
  }
  /deep/.van-tabs__nav {
    -webkit-overflow-scrolling: touch;
    background-color: var(--theme-main-bg-color);
    display: inline-block;
    overflow-x: auto;
    scrollbar-width: none;
    padding: 0;
    white-space: nowrap;

    .van-tab {
      color: var(--theme-text-color-darken);
      display: inline-block;
      font-size: 0.24rem;
      padding: 0;
      position: relative;
      .van-tab__text--ellipsis {
        overflow: initial;
      }
      .tabTitle {
        display: -webkit-box;
        padding: 0;
      }
    }
    .van-tab--active {
      color: var(--theme-primary-color);
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
    }
  }
  /deep/.van-tabs__wrap {
    background-color: var(--theme-main-bg-color);
  }
  /deep/.van-tabs__wrap {
    border-bottom: thin solid var(--theme-color-line);
    display: -ms-flexbox;
    // display: flex;
    height: auto;
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-width: none;
  }

  .back {
    background-color: var(--theme-main-bg-color);

    left: 0;
    position: absolute;
    top: 0.02rem;
    width: 0.95rem;
    height: 0.88rem;
    .backIco {
      color: var(--theme-text-color);
      font-size: 0.24rem;
      left: 0.2rem;
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
    }
  }
  .customerService {
    background-color: var(--theme-main-bg-color);
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    .icobg {
      -ms-flex-align: center;
      align-items: center;
      color: var(--theme-primary-color);
      display: -ms-flexbox;
      display: flex;
      font-size: 0.44rem;
      height: 0.9rem;
      padding: 0 0.2rem;
    }
  }
}
</style>
