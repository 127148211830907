<template>
  <div class="box">
    <div class="menu">
      <div class="menuItem">
        <ul class="item">
          <li class="active" title="Misto" style="width: 1.5rem">
            <img :src="require('@/assets/btn_zc2_1.png')" class="img2" />
            <div class="icon">
              <i
                class="notActiveIcon"
                style="
                  display: inline-flex;
                  justify-content: center;
                  align-items: center;
                "
              >
                <svg-icon
                  icon-class="dtfl_zh_0"
                  style="
                    width: 1em;
                    height: 1em;
                    fill: var(--theme-left-nav-text-active-color);
                  "
                ></svg-icon>
              </i>
            </div>
            <div class="text">
              <span>{{ $t("mixed") }}</span>
            </div>
          </li>
        </ul>
      </div>
      <li class="hist">
        <div>
          <van-button class="btn" @click="toHistoric">{{ $t('historic') }}</van-button>
        </div>
      </li>
    </div>
    <div class="itemlist">
      <div class="eventList">
        <ul>
          <div v-for="item in taskList" :key="item.key" class="item">
            <van-badge
              :content="
                redPointInfo.activity &&redPointInfo.activity.items['activity_'+item.key]? redPointInfo.activity.items['activity_'+item.key].count : ''
              "
            >
              <div class="imgbg">
                <img :src="item.image" @click="toDetail(item.key)" />
              </div>
            </van-badge>
          </div>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { getActivityInfo } from "@/api/index";
import { mapActions, mapState } from "vuex";
export default {
  name: "",
  components: {},
  mixins: [],
  props: {},
  data() {
    return {
      taskList: [],
    };
  },
  computed: { ...mapState("redPoint", ["redPointInfo"]) },
  watch: {},
  mounted() {
    this.getActivityInfo();
  },
  methods: {
    getActivityInfo() {
      getActivityInfo().then(({ data }) => {
        if (data.code === 0) {
          this.taskList = data.data.activity_list;
        }
      });
    },
    toDetail(key) {
      this.$router.push({
        path: "/eventItem",
        query: { index: key },
      });
    },
    toHistoric() {
      //   this.$router.push({
      //     path: "/historic",
      //   });
    },
  },
};
</script>
<style lang="less" scoped>
.box {
  // width:100vw;
  background: var(--theme-bg-color);
  display: flex;
  padding-top: 0.2rem;

  // padding-bottom:20vw;
}
.menu {
  // width: 24vw;
  // padding:3vw;
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;

  height: calc(var(--vh, 1vh) * 100);
  position: fixed;
  .menuItem {
    margin-top: -0.2rem;
    max-height: calc(100% - 3.94rem);
    overflow-y: auto;
    padding-left: 0.2rem;
    width: 2rem;
    .item {
      width: 1.5rem;
      display: block;
      margin: 0px;
      position: relative;
      margin-top: 0.2rem;
      max-height: none;
      padding-bottom: 0.1rem;

      // padding: 0px 0.04rem 0px 0.06rem;
      .active {
        font-size: 0.24rem;
        pointer-events: none;
        box-shadow: 0 0.03rem 0.09rem 0 var(--theme-aside-no-active-box-shadow);
        min-height: 0.7rem;
        height: 1.05rem;
        display: flex;
        position: relative;
        border: initial;
        color: var(--theme-primary-font-color);
        padding: 0 0.1rem;
        width: 1.5rem;
        text-align: center;
        word-break: break-word;
        flex-direction: column;
        justify-content: center;
        .img2 {
          height: 100%;
          left: 0px;
          position: absolute;
          top: 0px;
          width: 100%;
          z-index: -1;
        }
        .icon {
          font-size: 0.44rem;
          position: relative;
          z-index: 1;
          overflow: visible;
          line-height: 0.24rem;
          .notActiveIcon {
            height: 0.44rem;
            min-width: 0.3rem;
          }
        }
        .text {
          align-items: center;
          display: flex;
          font-size: 0.22rem;
          justify-content: center;
          position: relative;
          padding-left: 0.03rem;
          line-height: 0.24rem;
          font-size: 0.17rem;
        }
      }
    }
  }
  .hist {
    align-items: center;
    color: var(--theme-left-nav-text-color);
    cursor: pointer;
    display: flex;
    font-size: 0.2rem;
    justify-content: center;
    line-height: 0.24rem;
    overflow: visible;
    position: relative;
    text-align: center;
    width: 100%;
    word-break: break-word;
    width: 1.5rem;
    margin-top: 0.2rem;
    flex-shrink: 0;
    font-size: 0.24rem;
    .btn {
      padding: 0 0.05rem;
      border: 1px solid #d9d9d9;
      background-color: var(--theme-primary-color);
      border-color: var(--theme-primary-color);
      color: var(--theme-primary-font-color);
      box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
      border-radius: 0.1rem;
      height: 0.5rem;
      margin-right: 0.05rem;
      display: flex;
      font-size: 0.2rem;
      width: 1.2rem;
      justify-content: center;
    }
  }
}

.itemlist {
  padding-left: 2rem;
  flex-grow: 1;
  font-size: 0.14rem;
  .eventList {
    display: flex;
    flex-wrap: wrap;
    //   margin-top: 3vw;
    //   margin-left: 30vw;
    height: calc(100vh - 1.2rem);
    margin-top: 0;
    border-bottom-left-radius: 0.1rem;
    border-bottom-right-radius: 0.1rem;
    position: relative;
    .item {
      height: 2.5rem;
      width: 5.4rem;
      margin: 0 0 0.2rem;
      background-color: var(--theme-main-bg-color);
      border-radius: 0.1rem;
      padding: 0.1rem 0.1rem 0;
      top: 0.02rem;
      .imgbg {
        height: 2.35rem;
        position: relative;
        overflow: hidden;
        img {
          width: 5.2rem;
          height: 100%;
        }
      }
    }
  }
}
</style>
