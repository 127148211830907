const state = {
    showLoginDialog: false,
    dialogMode: 1, // 默认为登录模式
    showtwoverify:false,
    loginInfo:{}
  };
  
  const mutations = {
    SET_SHOW_LOGIN_DIALOG(state, payload) {
      state.showLoginDialog = payload.show;
      state.dialogMode = payload.mode;
    },
    SET_SHOW_LOGIN_TWO_VERIFY(state, payload){
      state.showtwoverify = payload.show;
      state.loginInfo = payload.info;
    }
  };
  
  const actions = {
    triggerDialog({ commit }, mode) {
      commit('SET_SHOW_LOGIN_DIALOG', { show: true, mode });
    },
    triggerLoginTwoVerify({ commit }, info){
      commit('SET_SHOW_LOGIN_TWO_VERIFY', { show: true, info });
    }
  };
  
  export default {
    state,
    mutations,
    actions
  };
  