import request from '@/utils/request'

export function checkAccount(data) {
  return request({
    url: '/index/check-account',
    method: 'post',
    data: { "account": data }
  })
}

export function userRegister(data) {
  return request({
    url: '/index/sign-up',
    method: 'post',
    data
  })
}
export function userLogin(data) {
  return request({
    url: '/index/sign-in',
    method: 'post',
    data
  })
}
export function userInfo(data) {
  return request({
    url: '/profile/user_profile',
    method: 'post',
    data
  })
}
//两步验证
export function twoFactorLogin(data){
  return request({
    url: '/index/two-factor-login',
    method: 'post',
    data
  })
}
//获取游戏大类
export function gameCategoryList(data) {
  return request({
    url: '/hall/game_category_list',
    method: 'post',
    isCache:true,
    params: data
  })
}
export function vendorGameList(data) {
  return request({
    url: '/hall/hall_game_list',
    method: 'post',
    isCache:true,
    params: data
  })
}
export function getGameUrl(data) {
  return request({
    url: '/game/game_url',
    method: 'post',
    data
  })
}
export function getMoreGameByCategoryAndKindList(data) {
  return request({
    url: '/hall/all_user_games_by_category',
    method: 'post',
    isCache:true,
    data
  })
}
export function getVendorByCategory(data) {
  return request({
    url: '/hall/get_vendor_by_category',
    method: 'post',
    isCache:true,
    data
  })
}
export function getAllVendorGamesByCategory(data) {
  return request({
    url: '/hall/all_vendor_games_by_category',
    method: 'post',
    isCache:true,
    data
  })
}

export function getImageResourcesByKind(data) {
  return request({
    url: '/hall/get_images_by_kind',
    method: 'post',
    isCache:true,
    data
  })
}
//根据游戏大类获取包含的运营商子类 
export function getVendorCategoryByCategory(data) {
  return request({
    url: '/hall/get_vendor_category_by_category',
    method: 'post',
    isCache:true,
    data
  })
}

export function getInviteInfo() {
  return request({
    url: '/activity/invite_info',
    isCache:true,
    method: 'post'
  })
}
//获取活动配置
export function getActivityConfig(data) {
  return request({
    url: '/activity/activity_config',
    method: 'post',
    isCache:true,
    isforever:true,
    data
  })
}
//退出游戏
export function getTerminateGame() {
  return request({
    url: '/game/terminate_game',
    method: 'post'
  })
}
export function testPay(data) {
  return request({
    url: '/deposit/test',
    method: 'post',
    data
  })
}

//获取自己代理信息
export function getAgentInfo() {
  return request({
    url: '/agent/get_agent_info',
    method: 'post'
  })
}

//获取代理等级配置
export function getAgentLvConfig() {
  return request({
    url: '/agent/get_lv_config',
    method: 'post'
  })
}


//根据周期获取自己的直推信息 
export function getPeriodMyData(data) {
  return request({
    url: '/agent/get_period_my_data',
    method: 'post',
    data
  })
}


//获取个人全部数据
export function getTotalMyData() {
  return request({
    url: '/agent/get_total_my_data',
    method: 'post'
  })
}
export function getPeriodAllDataList(data) {
  return request({
    url: '/agent/get_period_all_data_list',
    method: 'post',
    data
  })
}

export function searchGame(data){
  return request({
    url: '/hall/search_game',
    method: 'post',
    data
  })
}
//获取玩家更多资料
export function getUserMoreData(){
  return request({
    url: '/profile/user_more_data',
    method: 'post',
  })
}
//修改用户信息
export function modifyUserData(data){
  return request({
    url: '/profile/modify_user_data',
    method: 'post',
    data
  })
}
//获取用户安全信息
export function getSecurityInfo(){
  return request({
    url: '/security/info',
    method: 'post',
  })
}

//根据时间段获取自己的业绩 
export function getPeriodPerformance(data){
  return request({
    url: '/agent/get_period_performance',
    method: 'post',
    data
  })
}

//pix充值
export function dePositPix(data){
  return request({
    url: '/deposit/pix',
    method: 'post',
    data
  })
}
//刷新订单状态
export function refreshOrderState(data){
  return request({
    url: '/deposit/refresh_order_state',
    method: 'post',
    data
  })
}
//获取直推玩家的列表,
export function getSubordinateList(data){
  return request({
    url: '/agent/get_subordinate_list',
    method: 'post',
    data
  })
}

//获取直推玩家下注信息
export function getSubordinateBetInfo(data){
  return request({
    url: '/agent/get_subordinate_bet_info',
    method: 'post',
    data
  })
}
//获取配置信息
export function getconfigFetch(data){
  return request({
    url: '/config/fetch',
    method: 'post',
    data
  })
}
//get_subordinate_finance_info
//获取下属财务信息
export function getSubordinateFinanceInfo(data){
  return request({
    url: '/agent/get_subordinate_finance_info',
    method: 'post',
    data
  })
}

//修改提现密码
export function setWithdrawPassword(data){
  return request({
    url: '/security/set_withdraw_password',
    method: 'post',
    data
  })
}

//获取提款账号信息
export function getWithdrawAccountInfo(){
  return request({
    url: '/withdraw/account_info',
    method: 'post',
  })
}
//验证交易密码
export function checkPassWord(data){
  return request({
    url: '/withdraw/check_password',
    method: 'post',
    data
  })
}
//添加提款账号
export function addWithdrawAccount(data){
  return request({
    url: '/withdraw/add_account',
    method: 'post',
    data
  })
}

//沙箱充值
export function SandBoxPay(data){
  return request({
    url: '/deposit/sand_box_pay',
    method: 'post',
    data
  })
}
//获取玩家余额变化日志
export function getLogBalanceChange(data){
  return request({
    url: '/logs/balance_change_log',
    method: 'post',
    data
  })
}

//获取玩家下注日志 
export function getLogsBet(data){
  return request({
    url: '/logs/bet_log',
    method: 'post',
    data
  })
}
//获取玩家游戏报表 
export function getLogsGameReport(data){
  return request({
    url: '/logs/game_report',
    method: 'post',
    data
  })
}

//设为默认账号
export function setDefaultIndex(data){
  return request({
    url: '/withdraw/set_default_index',
    method: 'post',
    data
  })
}
//pix转账
export function withdrawPix(data){
  return request({
    url: '/withdraw/pix',
    method: 'post',
    data
  })
}
//提现记录
export function withdrawGetRecords(data){
  return request({
    url: '/withdraw/get_records',
    method: 'post',
    data
  })
}

//搜索大类游戏
export function searchCategoryGame(data){
  return request({
    url: '/hall/search_category_game',
    method: 'post',
    isCache:true,
    data
  })
}
//收藏游戏
export function gameAddFavouriteGame(data){
  return request({
    url: '/game/add_favourite_game',
    method: 'post',
    data
  })
}
export function allUserGames(data){
  return request({
    url: '/hall/all_user_games',
    method: 'post',
    data
  })
}

//开启邀请奖励
export function openInviteReward(data){
  return request({
    url: '/activity/open_invite_reward',
    method: 'post',
    data
  })
}

//转盘抽奖记录
export function fortuneWheelRecords(data){
  return request({
    url: '/activity/fortune_wheel_records',
    method: 'post',
    isCache:true,
    data
  })
}

//获取转盘信息
export function fortuneWheelInfo(){
  return request({
    url: '/activity/fortune_wheel_info',
    method: 'post',
  })
}
//转盘抽奖
export function fortuneWheelDraw(data){
  return request({
    url: '/activity/fortune_wheel_draw',
    method: 'post',
    data
  })
}
//注册下级代理
export function registerSubordinateAccount(data){
  return request({
    url: '/agent/register_subordinate_account',
    method: 'post',
    data
  })
}
export function getActivityInfo(data){
  return request({
    url: '/activity/activity_info',
    method: 'post',
    isCache:true,
    data
  })
}

//绑定谷歌验证器
export function bindGoogleAuthCode(data){
  return request({
    url: '/security/bind_google_auth_code',
    method: 'post',
    data
  })
}
//修改提款密码
export function changeWithdrawPassword(data){
  return request({
    url: '/security/change_withdraw_password',
    method: 'post',
    data
  })
}

//关闭谷歌验证器
export function closeGoogleAuth(data){
  return request({
    url: '/security/close_google_auth',
    method: 'post',
    data
  })
}
//获取谷歌验证器绑定地址
export function getGoogleAuthUrl(data){
  return request({
    url: '/security/get_google_auth_url',
    method: 'post',
    data
  })
}
//修改登录密码
export function modifyLoginPassword(data){
  return request({
    url: '/security/modify_login_password',
    method: 'post',
    data
  })
}
//设置邮箱
export function setEmail(data){
  return request({
    url: '/security/set_email',
    method: 'post',
    data
  })
}
//设置手机号
export function setPhone(data){
  return request({
    url: '/security/set_phone',
    method: 'post',
    data
  })
}
//设置安全问题
export function setSecurityQuestion(data){
  return request({
    url: '/security/set_security_question',
    method: 'post',
    data
  })
}
//充值记录
export function depositGetRecords(data){
  return request({
    url: '/deposit/get_records',
    method: 'post',
    data
  })
}
//音乐列表
export function getMusicList(data){
  return request({
    url: '/hall/get_music_list',
    method: 'post',
    isCache:true,
    data
  })
}
//获取奖池
export function getJackpotVal(){
  return request({
    url: '/hall/get_jackpot_val',
    method: 'post',
  })
}
//获取红点
export function getRedPointInfo(){
  return request({
    url: '/profile/red_point_info',
    method: 'post',
  })
}

//返利信息
export function getRebateInfo(){
  return request({
    url: '/rebate/info',
    isCache:true,
    method: 'post',
  })
}
//返利比例
export function getRebateRate(data){
  return request({
    url: '/rebate/rate',
    method: 'post',
    data
  })
}
//领取返利奖励
export function receiveRebate(){
  return request({
    url: '/rebate/receive',
    method: 'post',
  })
}
//返利记录
export function getRebateRecord(data){
  return request({
    url: '/rebate/record',
    method: 'post',
    data
  })
}