<template>
  <div>
    <div class="wrap" v-if="!showDetail" @click="showDetail = true">
      <div>
        <div class="context">
          <ul class="noticeList">
            <li class="theme-box-shadow-h5" style="cursor: pointer">
              <div class="listBox">
                <div class="item">
                  <div class="msgbox">
                    <div class="left">
                      <i
                        style="
                          display: inline-block;
                          position: relative;
                          width: 0.44rem;
                          height: 0.385rem;
                          background-position: -4.697rem -2.574rem;
                          background-size: 7.986rem 7.7935rem;
                        "
                      ></i>
                    </div>

                    <div class="msg">
                      <h1>
                        <span>
                          <p>
                            <span style="color: rgb(255, 170, 9)">
                              Olá, queridos membros! Sinceramente, convidamos
                              você e seus amigos a visitar 9991BET para
                              experimentar qualidade profissional e serviços de
                              alta qualidade. Obrigado pelo seu apoio e atenção
                              ao 9991BET, desejo um feliz jogo e lucro!
                            </span>
                          </p>
                        </span>
                      </h1>
                    </div>

                    <h2>
                      <i
                        style="
                          display: inline-flex;
                          justify-content: center;
                          align-items: center;
                        "
                      >
                        <svg-icon
                          icon-class="comm_icon_fh"
                          style="width: 1em; height: 1em; fill: currentColor"
                        ></svg-icon
                      ></i>
                    </h2>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="mainBody" v-if="showDetail">
      <myScrollbarBar st="y">
        <div class="text">
          <h1>
            <span>
              <p>
                <span style="color: #ffaa09"
                  >Olá, queridos membros! Sinceramente, convidamos você e seus
                  amigos a visitar 9991BET para experimentar qualidade
                  profissional e serviços de alta qualidade. Obrigado pelo seu
                  apoio e atenção ao 9991BET, desejo um feliz jogo e
                  lucro!</span
                >
              </p>
            </span>
          </h1>
        </div>
      </myScrollbarBar>
    </div>
  </div>
</template>
<script>
import myScrollbarBar from "@/components/phone/common/myScrollbarBar.vue";

export default {
  data() {
    return {
      total: 100,
      showDetail: false,
    };
  },
  components: { myScrollbarBar },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    reset() {
      this.showDetail = false;
    },
  },
};
</script>
<style type="text/less" lang="less" scoped>
.wrap {
  max-height: calc(100vh - 2rem);
  max-height: calc(var(--vh, 1vh) * 100 - 2rem);
  min-height: 6.4rem;
  overflow: auto;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  background-color: var(--theme-bg-color);
  height: auto;
  .context {
    -ms-flex-align: center;
    -ms-flex-pack: center;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    justify-content: center;
    .noticeList {
      height: calc(var(--vh, 1vh) * 100 - 1.8rem);
      margin: 0;
      overflow: scroll;
      padding-bottom: 1rem;
      background-color: var(--theme-bg-color) !important;
      border-radius: 0.1rem;
      padding: 0.2rem 0.1rem 0.2rem 0.2rem;
      width: 100%;
      li {
        -ms-flex-align: center;
        align-items: center;
        background-color: var(--theme-main-bg-color);
        border-radius: 0.1rem;
        -webkit-box-shadow: 0 0.03rem 0.09rem 0 rgba(0, 0, 0, 0.06);
        box-shadow: 0 0.03rem 0.09rem 0 rgba(0, 0, 0, 0.06);
        height: 1rem;
        margin-bottom: 0.2rem;
        overflow: hidden;
        position: relative;
        width: 100%;
        height: auto;
        min-height: 1.2rem;
        .listBox {
          display: -ms-flexbox;
          display: flex;
          height: 100%;
          min-height: 1.2rem;
          -webkit-transform: translateX(0);
          transform: translateX(0);
          -webkit-transition: -webkit-transform 0.2s linear;
          transition: -webkit-transform 0.2s linear;
          transition: transform 0.2s linear;
          transition: transform 0.2s linear, -webkit-transform 0.2s linear;
          width: 8.3rem;
          .item {
            padding: 0.25rem 0.2rem;
            width: 7.1rem;
            -ms-flex-negative: 0;
            -ms-flex-pack: justify;
            display: -ms-flexbox;
            display: flex;
            flex-shrink: 0;
            justify-content: space-between;

            .msgbox {
              -ms-flex-align: center;
              align-items: center;
              display: -ms-flexbox;
              display: flex;
              height: 100%;
              width: 100%;
              .left {
                margin-left: -0.04rem;
                position: relative;
                left: -0.05rem;
                i {
                  color: var(--theme-active-bg-color);
                  font-size: 0.38rem;
                  margin-right: 0.16rem;
                  position: relative;
                  top: 0.03rem;
                  background-image: url("@/assets/814195b6-3e29-450b-8c2b-bdb30e15091f.png");
                }
              }
              .msg {
                -ms-flex-positive: 1;
                -ms-flex-pack: justify;
                display: -ms-flexbox;
                display: flex;
                -ms-flex-direction: column;
                flex-direction: column;
                flex-grow: 1;
                justify-content: space-between;
                h1 {
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 3;
                  color: var(--theme-text-color-darken);
                  display: inline-block;
                  -ms-flex: 1;
                  flex: 1;
                  margin: 0;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  font-size: 0.24rem !important;
                  height: 0.4rem;
                  line-height: 0.4rem;
                  max-width: 5.8rem;
                  overflow: hidden;
                  span,
                  p {
                    margin-top: 0 !important;
                    width: auto !important;
                    display: inline-block !important;
                    font-size: 0.24rem !important;
                    height: 0.4rem;
                    line-height: 0.4rem;
                  }
                }
              }
              h2 {
                -ms-flex-align: center;
                -ms-flex-pack: end;
                align-items: center;
                color: var(--theme-text-color-darken);
                display: -ms-flexbox;
                display: flex;
                font-size: 0.18rem;
                font-weight: 400;
                justify-content: flex-end;
                margin: 0;
                width: 1.3rem;
                i {
                  color: var(--theme-text-color-lighten);
                  -webkit-transform: rotate(180deg);
                  transform: rotate(180deg);
                }
              }
            }
          }
        }
      }
    }
  }
}
.mainBody {
  background-color: var(--theme-bg-color);
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  width: 100%;
  word-break: break-word;
  border-radius: 0;
  height: 9rem;
  .text {
    padding: 0.2rem;
    h1 {
      color: var(--theme-text-color-darken);
      font-size: 0.24rem;
      p {
        font-size: 0.24rem;
      }
    }
  }
}
::-webkit-scrollbar {
  display: none;
}
</style>
