<template>
  <div>
    <div v-show="!LoadingCompleted">
      <div class="skeleton-custom">
        <div>
          <img
            :src="require('@/assets/appIconSkeleton.gif')"
            style="width: 4rem; height: 4rem"
          />
        </div>
      </div>
    </div>
    <div v-show="LoadingCompleted">
      <el-container>
        <el-header height="75">
          <div
            v-if="showdown"
            style="background-color: var(--theme-bg-color); width: 100%"
            @click="downapp"
          >
            <section class="topdown">
              <div class="downbody">
                <div class="frame">
                  <div class="downbox">
                    <div class="close" @click="clospdown">
                      <i
                        style="
                          display: inline-flex;
                          justify-content: center;
                          align-items: center;
                          font-size: 0.2rem;
                        "
                      >
                        <svg-icon
                          icon-class="comm_icon_x"
                          style="
                            width: 1em;
                            height: 1em;
                            color: rgb(22, 120, 255);
                            fill: currentColor;
                          "
                        ></svg-icon>
                      </i>
                    </div>
                    <div class="logo">
                      <img
                        :src="require('@/assets/1724775742415675393.png')"
                        style="
                          height: 0.51rem;
                          min-width: 0.5rem;
                          will-change: auto !important;
                          opacity: 1;
                        "
                      />
                    </div>
                    <div class="downbutton">
                      <van-button
                        style="
                          background-color: rgb(22, 120, 255);
                          color: rgb(255, 255, 255);
                          border: none;
                        "
                        >{{ $t('download now') }}</van-button
                      >
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div ref="topnav" class="topNav">
            <div style="flex: 1; display: flex; position: static !important">
              
              <van-badge
                :content="
                  redPointInfo.activity && redPointInfo.activity.total_count > 0
                    ? redPointInfo.activity.total_count
                    : ''
                "
              >
              <div
                class="sideBar"
                @click="showBar"
                :style="
                  'transform: rotateZ(' + Number(showSideBar) * 180 + 'deg)'
                "
              >
                <svg-icon
                  icon-class="arrow"
                  style="
                    width: 1em;
                    height: 1em;
                    fill: var(--theme-text-color-lighten);
                  "
                ></svg-icon>
              </div>
            </van-badge>
              <van-image
                class="logo"
                :src="require('@/assets/logo.png')"
                :lazy-load="true"
                fit="fill"
              >
                <template v-slot:loading>
                  <div class="imgload"></div>
                </template>
              </van-image>
            </div>
            <div
              style="
                -ms-flex-negative: 0;
                -ms-flex-align: center;
                align-items: center;
                display: -ms-flexbox;
                display: flex;
                flex-shrink: 0;
                position: static !important;
              "
            >
              <div
                v-if="token"
                style="
                  -ms-flex-negative: 0;
                  -ms-flex-align: center;
                  align-items: center;
                  display: -ms-flexbox;
                  display: flex;
                  flex-shrink: 0;
                  position: static !important;
                "
              >
                <div class="coin">
                  <div class="flag">
                    <i></i>
                  </div>
                  <div class="numCoin">{{ coinStr }}</div>
                  <i
                    class="anticon refresh-coin animate__spin animate__infinite"
                    :class="{ animate__animated: isAnimateAnimated }"
                    @click="refreshUserInfo()"
                  >
                    <i
                      style="
                        display: inline-flex;
                        justify-content: center;
                        align-items: center;
                      "
                    >
                      <svg-icon
                        icon-class="comm_icon_sx"
                        style="
                          width: 1em;
                          height: 1em;
                          color: var(--theme-alt-primary);
                          fill: currentColor;
                          font-size: 0.293rem;
                        "
                      ></svg-icon>
                    </i>
                  </i>
                </div>
                <div @click="menuShow = !menuShow" class="top-selectDownArrow">
                  <van-button @click="showPayBox"> {{$t('deposit')}} </van-button>
                  <i class="dropdown-trigger">
                    <i v-if="!menuShow" class="el-icon-caret-bottom"></i>
                    <i v-if="menuShow" class="el-icon-caret-top"></i>
                  </i>
                </div>
                <el-collapse-transition>
                  <div v-show="menuShow" class="menu">
                    <div @click="topath('saque')">{{ $t('withdraw') }}</div>
                    <div @click="topath('promotion')">{{ $t('fees') }}</div>
                  </div>
                </el-collapse-transition>
              </div>
              <div
                v-if="!token"
                style="
                  -ms-flex-align: center;
                  align-items: center;
                  display: -ms-flexbox;
                  display: flex;
                "
              >
                <van-button class="loginbtn" type="primary" @click="login(1)">{{
                  $t("login")
                }}</van-button>
                <van-button
                  @click="login(0)"
                  class="registerbtn"
                  type="default"
                  >{{ $t("register") }}</van-button
                >
              </div>
              <van-icon @click="toSearch" class="searchBtn" name="search" />
            </div>
          </div>
          <div class="floatBox">
            <div class="ad">
              <div class="invite" v-if="showInvite">
                <van-image
                  @click="toEventDetail(0)"
                  :src="require('@/assets/ActiveImg4414071310209996.gif')"
                  :lazy-load="true"
                  fit="fill"
                  width="75"
                  height="75"
                >
                  <template v-slot:loading>
                    <div class="imgload"></div>
                  </template>
                </van-image>

                <div class="entryCloseIcon" @click="showInvite = false">
                  <div class="closeIconBorder">
                    <i
                      style="
                        display: inline-flex;
                        justify-content: center;
                        align-items: center;
                      "
                    >
                      <svg-icon
                        icon-class="comm_icon_x"
                        style="
                          width: 1em;
                          height: 1em;
                          color: var(--theme-text-color-lighten);
                          fill: currentColor;
                        "
                      ></svg-icon>
                    </i>
                  </div>
                </div>
              </div>
              <div class="carouselBox">
                <div style="width: 1.5rem; overflow: hidden; flex-shrink: 0">
                  <el-carousel
                    v-if="showInvite2 || showInvite3 || showInvite4"
                    indicator-position="none"
                    height="1.5rem"
                  >
                    <el-carousel-item v-if="showInvite2">
                      <div class="invite">
                        <van-image
                          @click="toEventDetail(3)"
                          :src="
                            require('@/assets/ActiveImg4416107831340376.gif')
                          "
                          :lazy-load="true"
                          fit="fill"
                          width="75"
                          height="75"
                        >
                          <template v-slot:loading>
                            <div class="imgload"></div>
                          </template>
                        </van-image>

                        <div
                          class="entryCloseIcon"
                          @click="showInvite2 = false"
                        >
                          <div class="closeIconBorder">
                            <i
                              style="
                                display: inline-flex;
                                justify-content: center;
                                align-items: center;
                              "
                            >
                              <svg-icon
                                icon-class="comm_icon_x"
                                style="
                                  width: 1em;
                                  height: 1em;
                                  color: var(--theme-text-color-lighten);
                                  fill: currentColor;
                                "
                              ></svg-icon>
                            </i>
                          </div>
                        </div>
                        <div class="entryCloseIcon" @click="showInvite = false">
                          <div class="closeIconBorder">
                            <i
                              style="
                                display: inline-flex;
                                justify-content: center;
                                align-items: center;
                              "
                            >
                              <svg-icon
                                icon-class="comm_icon_x"
                                style="
                                  width: 1em;
                                  height: 1em;
                                  color: var(--theme-text-color-lighten);
                                  fill: currentColor;
                                "
                              ></svg-icon>
                            </i>
                          </div>
                        </div>
                      </div>
                    </el-carousel-item>
                    <el-carousel-item v-if="showInvite3">
                      <div class="invite">
                        <van-image
                          @click="toEventDetail(4)"
                          :src="
                            require('@/assets/ActiveImg4416004825326299.gif')
                          "
                          :lazy-load="true"
                          fit="fill"
                          width="75"
                          height="75"
                        >
                          <template v-slot:loading>
                            <div class="imgload"></div>
                          </template>
                        </van-image>

                        <div
                          class="entryCloseIcon"
                          @click="showInvite3 = false"
                        >
                          <div class="closeIconBorder">
                            <i
                              style="
                                display: inline-flex;
                                justify-content: center;
                                align-items: center;
                              "
                            >
                              <svg-icon
                                icon-class="comm_icon_x"
                                style="
                                  width: 1em;
                                  height: 1em;
                                  color: var(--theme-text-color-lighten);
                                  fill: currentColor;
                                "
                              ></svg-icon>
                            </i>
                          </div>
                        </div>
                      </div>
                    </el-carousel-item>
                    <el-carousel-item v-if="showInvite4">
                      <div class="invite">
                        <van-image
                          @click="toEventDetail(5)"
                          :src="
                            require('@/assets/ActiveImg4416249442428666.gif')
                          "
                          :lazy-load="true"
                          fit="fill"
                          width="75"
                          height="75"
                        >
                          <template v-slot:loading>
                            <div class="imgload"></div>
                          </template>
                        </van-image>

                        <div
                          class="entryCloseIcon"
                          @click="showInvite4 = false"
                        >
                          <div class="closeIconBorder">
                            <i
                              style="
                                display: inline-flex;
                                justify-content: center;
                                align-items: center;
                              "
                            >
                              <svg-icon
                                icon-class="comm_icon_x"
                                style="
                                  width: 1em;
                                  height: 1em;
                                  color: var(--theme-text-color-lighten);
                                  fill: currentColor;
                                "
                              ></svg-icon>
                            </i>
                          </div>
                        </div>
                      </div>
                    </el-carousel-item>
                  </el-carousel>
                </div>
              </div>
            </div>
            <transition name="el-fade-in">
              <div v-show="showTop" @click="toTop" class="toTop">
                <div class="icobg">
                  <svg-icon
                    icon-class="comm_icon_fhdb"
                    style="
                      width: 1em;
                      height: 1em;
                      color: var(--theme-primary-font-color);
                      fill: currentColor;
                    "
                  ></svg-icon>
                </div>
                <div style="color: var(--theme-text-color-lighten)">Top</div>
              </div>
            </transition>
          </div>
        </el-header>

        <el-container>
          <el-main>
            <van-popup
              v-model:show="showSideBar"
              overlay-class="overlayAside"
              z-index="250"
              :safe-area-inset-top="true"
              position="left"
              class="h5-aside-ZIndex"
              :style="{
                width: '3rem',
                height: '100%',
                'margin-top': downboxheight,
              }"
            >
              <sideBar
                :activeIndex="activeIndex"
                @handleSelect="handleSelect"
                @handJump="handJump"
                :itemList="gameTitles"
              ></sideBar>
            </van-popup>
            <div class="bg">
              <div class="my-scrollbar">
                <div
                  ref="scrollTarget"
                  v-scroll="scrollMetering"
                  class="my-scrollbar-wrap my-scrollbar-wrap-all"
                >
                  <div class="home-page">
                    <div class="swiper">
                      <van-swipe
                        class="my-swipe"
                        :autoplay="3000"
                        indicator-color="white"
                      >
                        <van-swipe-item
                          @click="gotoPromotion(0)"
                          v-for="(item, index) in swipeList"
                          :key="index"
                          style="
                            border-radius: var(--common-banner-borderRedius);
                          "
                        >
                          <van-image
                            class="swipeImg"
                            :src="item"
                            :lazy-load="true"
                            fit="fill"
                          >
                            <template v-slot:loading>
                              <div class="imgload"></div>
                            </template>
                          </van-image>
                        </van-swipe-item>
                      </van-swipe>
                    </div>
                    <!--滚动图结束-->

                    <div class="notice">
                      <van-notice-bar
                        left-icon="volume-o"
                        text="Olá, queridos membros! Sinceramente, convidamos você e seus amigos a
             visitar 9991BET para experimentar qualidade profissional e serviços de alta qualidade. 
            Obrigado pelo seu apoio e atenção ao 9991BET, desejo um feliz jogo e lucro!"
                      >
                        <template slot="right-icon">
                          <van-image
                            class="noticeImg"
                            :src="require('@/assets/icon_icon_openmail.png')"
                            :lazy-load="true"
                            fit="fill"
                          >
                            <template v-slot:loading>
                              <div class="imgload"></div>
                            </template>
                          </van-image>
                        </template>
                        <template slot="left-icon">
                          <van-image
                            class="noticeImg"
                            :src="require('@/assets/icon_dt_pmd.png')"
                            :lazy-load="true"
                            fit="fill"
                          >
                            <template v-slot:loading>
                              <div class="imgload"></div>
                            </template>
                          </van-image>
                        </template>
                      </van-notice-bar>
                    </div>
                    <!--公告结束-->

                    <div class="jackpot" @click="goGpList">
                      <div class="numbg">
                        <div class="numList">
                          <span class="count-to">
                            <img
                              v-for="(item, index) in numList"
                              :key="index"
                              :src="item"
                              v-if="index < 2"
                            />
                            <img src="@/assets/num/number_e.png" />
                            <img
                              v-if="index < 5 && index > 1"
                              v-for="(item, index) in numList"
                              :src="item"
                              :key="index"
                            />
                            <img src="@/assets/num/number_e.png" />
                            <img
                              v-for="(item, index) in numList"
                              :src="item"
                              :key="index"
                              v-if="index < 8 && index > 4"
                            />
                            <img src="@/assets/num/number_g.png" />
                            <img
                              v-for="(item, index) in numList"
                              :key="index"
                              :src="item"
                              v-if="index > 7"
                            />
                          </span>
                        </div>
                      </div>
                    </div>

                    <!--数字结束-->
                    <div
                      class="content"
                      :style="'--home-top-height:' + downboxheight + ';'"
                    >
                      <div
                        ref="ceiling"
                        :style="
                          ceiling
                            ? {
                                position: 'absolute',
                                'z-index': '100',
                                top: '0',
                                width: 'var(--theme-max-width)',
                              }
                            : {}
                        "
                      >
                        <van-tabs
                          ref="tabs"
                          background="#5375D1"
                          title-active-color="#fff"
                          title-inactive-color="#B2C5FD"
                          color="#fff"
                          v-model="active"
                          @change="changeTabs"
                          style="
                            max-width: var(--theme-max-width);
                            width: var(--theme-max-width);
                          "
                          class="midNavH5"
                        >
                          <van-tab
                            v-for="(item, index) in gameTitles"
                            :key="index"
                            title-class="h5MidMenuTab"
                          >
                            <template #title>
                              <div
                                class="tabsTitle"
                                :style="
                                  active === index
                                    ? 'color: var(--theme-primary-color);'
                                    : ''
                                "
                              >
                                <div class="imageWrap">
                                  <i
                                    v-if="active != index"
                                    class="navIcon"
                                    style="
                                      justify-content: center;
                                      display: inline-flex;
                                      align-items: center;
                                    "
                                  >
                                    <svg-icon
                                      :icon-class="item.icon"
                                      style="
                                        width: 1em;
                                        height: 1em;
                                        fill: var(--theme-alt-neutral-2);
                                      "
                                    ></svg-icon>
                                  </i>
                                  <van-image
                                    v-else
                                    :src="
                                      active != index ? item.icon : item.actIcon
                                    "
                                    :show-loading="false"
                                    :show-error="false"
                                    width=".6rem"
                                    height=".46rem"
                                    fit="fill"
                                    style="
                                      font-size: 0.6rem;
                                      display: inline-block;
                                    "
                                  >
                                  </van-image>
                                </div>

                                <span
                                  class="game-category-name"
                                  style="
                                    width: 100%;
                                    margin-bottom: -1.6px;
                                    padding-bottom: 1.6px;
                                  "
                                >
                                  <span class="span2">
                                    <span
                                      style="-webkit-line-clamp: 2"
                                      class="inner-text"
                                    >
                                      {{ item.name }}</span
                                    >
                                  </span>
                                </span>
                              </div>
                            </template>
                          </van-tab>
                        </van-tabs>
                      </div>
                      <div v-if="ceiling" style="height: 1.06rem"></div>
                      <div class="bottom-gamelist">
                        <div
                          class="tabDetailItem"
                          v-for="(item, index) in gameTitles"
                          :id="item.name"
                        >
                          <div
                            v-show="
                              (index < 5 && active < 5) || index === active
                            "
                            style="
                              display: flex;
                              justify-content: space-between;
                              align-items: center;
                            "
                          >
                            <div class="title">
                              <van-image
                                :src="item.actIcon"
                                :lazy-load="true"
                                width="0.52rem"
                                fit="fill"
                              >
                                <template v-slot:loading>
                                  <div class="imgload"></div>
                                </template>
                              </van-image>
                              <span style="margin-left: 5px">{{
                                item.name
                              }}</span>
                            </div>
                            <div
                              v-if="index > 0"
                              @click="toFreeGame(item.name)"
                              style="color: #b2c5fd; font-size: 0.24rem"
                            >
                              {{ $t("all") }}
                            </div>
                          </div>
                          <batchDisplay
                            v-if="index === 0 && active < 5"
                            :items="popularList"
                            @child-clicked="toEmbedded"
                            @favoriteClick="favoriteClick"
                          />
                          <classBatchDisplay
                            v-if="
                              index > 0 &&
                              index < 5 &&
                              active < 5 &&
                              list[index - 1]
                            "
                            :items="list[index - 1]"
                            @child-clicked="tosubGame"
                          />
                          <batchDisplay
                            v-if="active === gameTitles.length-2 && index === gameTitles.length-2"
                            :items="recenteList"
                            @child-clicked="toEmbedded"
                            :noCollapse="false"
                            @favoriteClick="favoriteClick"
                          />
                          <batchDisplay
                            v-if="active === gameTitles.length-1 && index === gameTitles.length-1"
                            :items="favoritlist"
                            @child-clicked="toEmbedded"
                            :noCollapse="false"
                            @favoriteClick="favoriteClick"
                          />
                        </div>
                      </div>
                    </div>

                    <!--游戏结束-->

                    <div class="footer">
                      <section class="section">
                        <div class="footerBox">
                          <ul class="topmenu">
                            <li class="footerList">
                              <h3 class="listTile">{{ $t('cassino') }}</h3>
                              <a
                                class="listItem"
                                v-for="(item, index) in footerList1"
                                :key="index"
                              >
                                {{ item.name }}
                              </a>
                            </li>
                            <li class="footerList">
                              <h3 class="listTile">{{ $t('games') }}</h3>
                              <a
                                class="listItem"
                                v-for="(item, index) in footerList2"
                                :key="index"
                              >
                                {{ item.name }}
                              </a>
                            </li>
                            <li class="footerList">
                              <h3 class="listTile">{{ $t('support') }}</h3>
                              <a
                                class="listItem"
                                v-for="(item, index) in footerList3"
                                :key="index"
                              >
                                {{ item.name }}
                              </a>
                            </li>
                          </ul>
                          <div class="plus18">
                            <div style="position: relative">
                              <div
                                style="
                                  position: relative;
                                  -webkit-transition: opacity 0.3s;
                                  transition: opacity 0.3s;
                                "
                              >
                                <div
                                  style="
                                    -ms-flex-align: center;
                                    -ms-flex-pack: center;
                                    align-items: center;
                                    display: -ms-flexbox;
                                    display: flex;
                                    -ms-flex-wrap: wrap;
                                    flex-wrap: wrap;
                                    justify-content: center;
                                    margin-bottom: 0.2rem;
                                  "
                                >
                                  <van-image
                                    :src="require('@/assets/18plus.png')"
                                    :lazy-load="true"
                                    style="width: 0.7rem; height: 0.7rem"
                                    fit="fill"
                                  >
                                    <template v-slot:loading>
                                      <div class="imgload"></div>
                                    </template>
                                  </van-image>
                                </div>
                                <div class="rule">
                                  <a target="_blank">{{ $t('about') }} 9991BET</a>
                                </div>
                              </div>
                            </div>
                            <div
                              style="
                                font-size: 0.2rem;
                                color: var(--theme-text-color-lighten);
                                line-height: 1.66;
                                word-break: break-all;
                                text-align: center;
                              "
                            >
                           {{ $t('sketch') }}
                            </div>
                          </div>
                        </div>
                        <div class="cardList">
                          <button class="ant-btn-link">
                            <van-image
                              :src="require('@/assets/img_footer_jdb.png')"
                              :lazy-load="true"
                              width="1rem"
                              height="0.36rem"
                              fit="fill"
                              style="
                                border: none;
                                opacity: 1;
                                vertical-align: middle;
                              "
                            >
                              <template v-slot:loading>
                                <div class="imgload"></div>
                              </template>
                            </van-image>
                          </button>
                          <button class="ant-btn-link">
                            <van-image
                              :src="require('@/assets/img_footer_ebet.png')"
                              :lazy-load="true"
                              width="1rem"
                              height="0.36rem"
                              fit="fill"
                              style="
                                border: none;
                                opacity: 1;
                                vertical-align: middle;
                              "
                            >
                              <template v-slot:loading>
                                <div class="imgload"></div>
                              </template>
                            </van-image>
                          </button>
                          <button class="ant-btn-link">
                            <van-image
                              :src="require('@/assets/img_footer_ky.png')"
                              :lazy-load="true"
                              width="1rem"
                              height="0.36rem"
                              fit="fill"
                              style="
                                border: none;
                                opacity: 1;
                                vertical-align: middle;
                              "
                            >
                              <template v-slot:loading>
                                <div class="imgload"></div>
                              </template>
                            </van-image>
                          </button>
                          <button class="ant-btn-link">
                            <van-image
                              :src="require('@/assets/img_footer_bg.png')"
                              :lazy-load="true"
                              width="1rem"
                              height="0.36rem"
                              fit="fill"
                              style="
                                border: none;
                                opacity: 1;
                                vertical-align: middle;
                              "
                            >
                              <template v-slot:loading>
                                <div class="imgload"></div>
                              </template>
                            </van-image>
                          </button>
                          <button class="ant-btn-link">
                            <van-image
                              :src="require('@/assets/img_footer_mt.png')"
                              :lazy-load="true"
                              width="1rem"
                              height="0.36rem"
                              fit="fill"
                              style="
                                border: none;
                                opacity: 1;
                                vertical-align: middle;
                              "
                            >
                              <template v-slot:loading>
                                <div class="imgload"></div>
                              </template>
                            </van-image>
                          </button>
                        </div>
                        <div class="fLink">
                          <van-image
                            :src="require('@/assets/1724827809913593857.png')"
                            :lazy-load="true"
                            width="1rem"
                            fit="fill"
                            style="margin: 0.2rem 0"
                          >
                            <template v-slot:loading>
                              <div class="imgload"></div>
                            </template>
                          </van-image>

                          <van-image
                            :src="require('@/assets/1724827745761386497.png')"
                            :lazy-load="true"
                            width="1rem"
                            fit="fill"
                            style="margin: 0.2rem 0"
                          >
                            <template v-slot:loading>
                              <div class="imgload"></div>
                            </template>
                          </van-image>

                          <van-image
                            :src="require('@/assets/1724827703545618433.png')"
                            :lazy-load="true"
                            width="1rem"
                            fit="fill"
                            style="margin: 0.2rem 0"
                          >
                            <template v-slot:loading>
                              <div class="imgload"></div>
                            </template>
                          </van-image>

                          <van-image
                            :src="require('@/assets/1724827641244581889.png')"
                            :lazy-load="true"
                            width="1rem"
                            fit="fill"
                            style="margin: 0.2rem 0"
                          >
                            <template v-slot:loading>
                              <div class="imgload"></div>
                            </template>
                          </van-image>
                        </div>
                        <div style="width: 100%; height: 2.5rem"></div>
                      </section>
                    </div>

                    <!--底部结束-->
                  </div>
                </div>
              </div>
            </div>
          </el-main>
          <el-footer>
            <tabBar ref="tabBar" :tabBarActive="0" @change="tabChange"></tabBar>
          </el-footer>
        </el-container>
      </el-container>
    </div>
  </div>
</template>
<script>
import sideBar from "../../components/phone/sideBar.vue";
import tabBar from "../../components/phone/tabBar.vue";

import batchDisplay from "../../components/phone/batchDisplay.vue"; // 路径应修改为实际的路径
import classBatchDisplay from "../../components/phone/classBatchDisplay.vue"; // 路径应修改为实际的路径
// import { getToken } from "@/utils/auth"; // getToken from cookie
import {
  formatNumberToString,
  getRandomInteger,
  capitalizeFirstLetter,
} from "@/utils/utils";
import LocalStorageCache from "@/utils/localStorageCache.js";
import { mapState } from "vuex";
import SvgIcon from "@/components/SvgIcon";
import {
  gameCategoryList,
  vendorGameList,
  getImageResourcesByKind,
  getVendorCategoryByCategory,
  allUserGames,
  getJackpotVal,
} from "@/api/index";

import ColorThief from "colorthief";

export default {
  components: { sideBar, tabBar, batchDisplay, classBatchDisplay },
  data() {
    return {
      LoadingCompleted: false,
      coinStr: "00,00",
      coin: 0,
      // user: {},
      isAnimateAnimated: false,
      // dts: false,
      showdown: true,
      activeIndex: 0,
      showSideBar: false,
      loaduserInfo: false,
      menuShow: false,
      active: "0",
      activeItem: {},
      downboxheight: "1.6rem",
      ceilingTop: 0,
      numList: [
        require("@/assets/num/number_0.png"),
        require("@/assets/num/number_0.png"),
        require("@/assets/num/number_0.png"),
        require("@/assets/num/number_0.png"),
        require("@/assets/num/number_0.png"),
        require("@/assets/num/number_0.png"),
        require("@/assets/num/number_0.png"),
        require("@/assets/num/number_0.png"),
        require("@/assets/num/number_0.png"),
        require("@/assets/num/number_0.png"),
      ],
      jackpot: 0,
      jackpot_val: 0,
      jackInterval: null,
      swipeList: [],
      ceiling: false,
      list: [],
      listCache: [],
      popularList: [],
      gameTitles: [],
      recenteList: [],
      favoritlist: [],
      footerList1: [
        { name: this.$t('rebate'), url: "" },
        { name: this.$t('vip'), url: "" },
        { name: this.$t('toinvite'), url: "" },
        { name: this.$t('eventos'), url: "" },
        { name: this.$t('pending'), url: "" },
        { name:this.$t('mission'), url: "" },
      ],
      footerList2: [
      

      ],
      footerList3: [
        { name: this.$t('online support'), url: "" },
        { name: this.$t('helpCenter'), url: "" },
        { name: this.$t('suggestion bonus'), url: "" },
      ],

      showInvite: true,
      showInvite2: true,
      showInvite3: true,
      showInvite4: true,
      showTop: false,

      tagBodyLeft: 0,
      rightOffset: 40,
      scrollBody: 0,
      scrollOuter: 0,
    };
  },
  created() {
    // this.token = this.$store.getters.token;
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
      token: (state) => state.user.token,
      redPointInfo:(state)=>state.redPoint.redPointInfo
    }),
  },

  watch: {
    active(t) {
      this.showTop = true;
      // if (t >= 1 && t <= 4) {
      //   this.showTop = true;
      // } else {
      //   this.showTop = false;
      // }
    },
    user(u) {
      if (u) {
        this.showAD();
      }
      // console.log(u);
      // this.refreshUserInfo();
    },
  },
  mounted() {
    this.loadBanner();
    this.vendorGameList();

    // this.showAD();
    // window.addEventListener("scroll",this.scrollMetering);
    this.initJackPot();

    if (this.token) {
      // console.log(this.user);
      this.refreshUserInfo();
    }
  },
  activated() {
    this.$refs.tabBar.initBar(0);
    if (this.token) {
      this.refreshUserInfo();
    }
  
    // if (!this.dts) {

    // }
    // this.token = this.$store.getters.token;
    // if (this.token) {
    //   this.refreshUserInfo();
    // }
    // this.initJackPot()
    this.getJackpotVal();
  },
  methods: {
    getJackpotVal() {
      const self = this;
      getJackpotVal().then(({ data }) => {
        if (data.code === 0) {
          let val = data.data.jackpot_val / 100;
          self.jackpot_val = val;
          if (self.jackpot === 0) {
            self.jackpot = parseInt(
              self.jackpot_val - getRandomInteger(100, 5000)
            );
            if (self.jackpot < 0) {
              self.jackpot = 0;
            }
            self.initJackPot();
          }
          if (self.jackInterval) {
            clearInterval(self.jackInterval);
          }
          self.jackInterval = setInterval(() => {
            let n = 0;
            let add = 0;
            if (self.jackpot < self.jackpot_val) {
              add += getRandomInteger(1, 100);
            } else {
              add -= getRandomInteger(1, 100);
            }
            const a = setInterval(() => {
              self.jackpot += add;
              n++;
              self.initJackPot();
              if (n == 100) {
                clearInterval(a);
              }
            }, 10);
          }, 5000);
        }
        setTimeout(() => {
          self.getJackpotVal();
        }, 600000);
      });
    },
    favoriteClick(index, item) {
      const pi = this.popularList.findIndex(
        (e) => e.geme_code === item.game_code
      );
      if (pi > -1) {
        this.popularList[pi].favorite = item.favorite;
      }

      const ri = this.recenteList.findIndex(
        (e) => e.geme_code === item.game_code
      );
      if (ri > -1) {
        this.recenteList[ri].favorite = item.favorite;
      }

      if (!item.favorite) {
        // console.log(this.favoritlist);
        // console.log(item);
        const index = this.favoritlist.findIndex(
          (e) => e.game_code === item.game_code
        );
        if (index !== -1) {
          this.favoritlist.splice(index, 1);
        }
      } else {
        // let b = false;
        let isFound = this.favoritlist.some(
          (e) => e.game_code === item.game_code
        );
        if (!isFound) {
          this.favoritlist.push(item);
        }
      }
    },
    scrollMetering(v) {
      if (!this.ceiling) {
        const ot = this.$refs.ceiling.offsetTop;
        const c = v - ot;
        if (c > 0) {
          this.ceilingTop = ot;
          this.ceiling = true;
        }
      } else {
        if (this.ceilingTop - v > 0) {
          this.ceiling = false;
        }
      }
    },
    loadBanner() {
      getImageResourcesByKind({ kind: "banner" }).then(({ data }) => {
        // if
        if (data.code === 0) {
          const urls = data.data.urls;
          this.swipeList = urls;
        }
      });
    },

    showPayBox() {
      this.$refs.tabBar.showPayBox();
    },
    clospdown() {
      this.showdown = false;
      this.downboxheight = "0.9rem";
    },
    getList() {
      gameCategoryList().then(({ data }) => {
        let vendorList = data.data.game_category_list;
        vendorList.forEach(element => {
          this.footerList2.push({name:capitalizeFirstLetter(element),url:''});
        });
        this.VendorCategoryByCategory(vendorList, 2);
      });
    },
    initCategory(index, name) {
      const item = {};
      item.name =this.$t( name);
      item.id = index;
      item.actIcon = require("../../assets/" + name + "_act.png");
      item.icon = name;
      return item;
    },
    initJackPot() {
      for (let i = 0; i < this.numList.length; i++) {
        let temp = String(this.jackpot).padStart(10, "0");
        // console.log(temp);
        this.$set(
          this.numList,
          i,
          require(`../../assets/num/number_${temp[i]}.png`)
        );
      }
    },
    vendorGameList() {
      vendorGameList().then(({ data }) => {
        const gameList = data.data.game_list;
        // this.listCache.push(this.initCategory(1, "popular", gameList));
        this.gameTitles.push(this.initCategory(1, "popular"));
        this.popularList = gameList;
        this.getList();
      });
    },
    VendorCategoryByCategory(vendorList, index) {
      if (vendorList.length > 0) {
        const name = vendorList.shift();
        getVendorCategoryByCategory({ category: name }).then(({ data }) => {
          const gameList = data.data.vendor_category_list;
          this.listCache.push(gameList);
          // this.listCache.push();
          this.gameTitles.push(this.initCategory(index, name));
          this.VendorCategoryByCategory(vendorList, index + 1);
        });
      } else {
        this.gameTitles.push(
          this.initCategory(index, "recent")
        );
        allUserGames({ kind: "recent" }).then(({ data }) => {
          if (data.code === 0) {
            this.recenteList = data.data.game_list;
          }
        });
        allUserGames({ kind: "favorite" }).then(({ data }) => {
          if (data.code === 0) {
            this.favoritlist = data.data.game_list;
          }
        });
        this.gameTitles.push(
          this.initCategory(index + 1, "favorites")
        );
        this.list = this.listCache;
        // console.log( this.list);
        this.LoadingCompleted = true;
      }
    },
    handleSelect(i) {
      // console.log(i);
      this.$refs.tabs.scrollTo(i - 1);
      this.showSideBar = false;
    },
    handJump(p, i) {
      this.showSideBar = false;
      this.$router.push({
        path: p,
        query: { index: i },
      });
    },
    showBar() {
      this.showSideBar = !this.showSideBar;
    },
    changeTabs(name) {
      const self = this;
      setTimeout(() => {
        self.activeIndex = name;
        self.activeItem = self.gameTitles[name];
        const d = document.getElementById(self.activeItem.name);

        if (d) {
          const h = self.$refs.ceiling.clientHeight;
          self.$refs.scrollTarget.scrollTop = d.offsetTop - h;
        }
      }, 20);
    },
    login(type) {
      this.$refs.tabBar.login(type);
    },
    toEmbedded(item) {
      if (this.token) {
        this.$router.push({
          path: "/embedded",
          query: { gameCode: item.game_code, name: item.show_name },
        });
      } else {
        this.login(1);
      }
      // }
    },
    goGpList() {
      this.$router.push({
        path: "/subgame",
        query: {
          category: "slots",
          vendor_code: "PGS",
        },
      });
    },
    tosubGame(item) {
      this.$router.push({
        path: "/subgame",
        query: {
          category: item.vendor_category_code.toLowerCase(),
          vendor_code: item.vendor_code,
        },
      });
    },
    toFreeGame(name) {
      this.$router.push({
        path: "/subgame",
        query: { category: name },
      });
    },

    toEventDetail(index) {
      this.$router.push({
        path: "/eventItem",
        query: { index: index },
      });
    },
    toTop() {
      const scrollTarget = this.$refs.scrollTarget;
      if (scrollTarget) {
        scrollTarget.scrollTop = 0;
      }
    },
    toSearch() {
      this.$router.push({
        path: "/search",
      });
    },
    tabChange() {
      if (this.token) {
        this.refreshUserInfo();
      }
    },
    showAD() {
      const self = this;
      if (self.user.balance) {
        const balance = self.user.balance;
        // const   bet=1000;
        let increment = Math.abs(balance - 0); 
        let steps = Math.ceil(increment / 20); 
        if (self.coin < balance) {
          const c = setInterval(function () {
            if (self.coin < balance) {
              self.coin += steps;
            } else {
              self.coin = balance;
              clearInterval(c);
            }
            self.coinStr = formatNumberToString(self.coin);
          }, 100);
        } else {
          self.coin = balance;
          self.coinStr = formatNumberToString(self.coin);
        }
      }

      self.isAnimateAnimated = false;
    },
    refreshUserInfo() {
      if (this.isAnimateAnimated) {
        return;
      }
      const self = this;
      this.isAnimateAnimated = true;
      this.$store.dispatch("getUserInfo").then(() => {});
    },
    topath(p) {
      this.$router.push({
        path: p,
        query: { index: 0 },
      });
    },
    gotoPromotion(index) {
      this.$router.push({
        path: "/Promotion",
        query: { index: index },
      });
    },
    downapp() {
      this.$refs.tabBar.download(0);
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollMetering);
  },
};
</script>
<style type="text/less" lang="less" scoped>
.skeleton-custom {
  height: 4rem;
  width: 4rem;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto auto;
}
.my-scrollbar {
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  background: 0 0;
  // height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
  .my-scrollbar-wrap {
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    scrollbar-width: none;
    height: 100%;
  }
  .my-scrollbar-wrap-all {
    overflow: scroll;
  }
}
.el-header {
  padding: 0;
}
.el-main {
  padding: 0;
}
.entryCloseIcon {
  height: 0.3rem;
  position: absolute;
  right: 0;
  top: 0;
  width: 0.3rem;
  .closeIconBorder {
    -ms-flex-align: center;
    -ms-flex-pack: center;
    align-items: center;
    border: 0.01rem solid var(--theme-text-color-lighten);
    border-radius: 50%;
    display: -ms-flexbox;
    display: flex;
    height: 0.2rem;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
    width: 0.2rem;
    i {
      color: var(--theme-text-color-lighten);
      height: 0.08rem;
      width: 0.08rem;
    }
  }
}
.bg {
  background: var(--theme-bg-color);
  background-image: url("../../assets/bg_phone.png");
  // min-height: 100vh;
  // padding-bottom: 13vw;
  overflow: hidden;
}

.topdown {
  border-bottom: 0.01rem solid var(--theme-color-line);
  height: 0.7rem;
  position: relative;
  z-index: 252;
  width: 100%;
  overflow: hidden;
  font-size: 0.2rem;
  .downbody {
    height: 0.7rem;
    align-items: center;
    display: flex;
    width: 100%;

    .frame {
      flex-shrink: 0;
      height: 100%;
      width: 100%;
      .downbox {
        background-color: rgba(255, 255, 255, 0.8);
        gap: 0.2rem;
        align-items: center;
        display: flex;
        height: 100%;
        .close {
          margin-right: -0.2rem;
          padding: 0 0.3rem;
          justify-content: center;
          align-items: center;
          display: flex;
          height: 100%;
        }

        .logo {
          cursor: pointer;
          align-items: center;
          display: flex;
        }
        .downbutton {
          -ms-flex-negative: 0;
          flex-shrink: 0;
          margin-left: auto;
          margin-right: 0.2rem;
          box-sizing: border-box;
          .van-button {
            font-size: 0.18rem;
            height: -webkit-fit-content;
            height: -moz-fit-content;
            height: fit-content;
            line-height: 0.2rem;
            max-width: 1.6rem;
            min-height: 0.45rem;
            min-width: 1.2rem;
            padding-left: 0.1rem;
            padding-right: 0.1rem;
            white-space: normal;
            word-break: break-all;
            border-radius: 0.1rem;
            font-family: MicrosoftYaHeiLobby;
            text-align: center;
          }
        }
      }
    }
  }
}
.jackpot {
  --jackpot-card-count-box-scale: 0.6;
  --jackpot-card-count-box-height: 0.76rem;
  --jackpot-card-count-to-bottom: 0.245rem;
  --jackpot-card-width: 100%;
  --jackpot-card-height: 1.64rem;
  --jackpot-font-letter-spacing: 0.007rem;
  --jackpot-card-margin: 0 0 0.2rem;
  --jackpot-font-color: var(--theme-text-color);
  --jackpot-font-size: 0.48rem;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  justify-content: center;
  margin: 0 0.2rem;
  .numbg {
    background-position: 0px 0px;
    background-image: url("../../assets/cjc1_style_1_bg.png");
    background-size: 100% 100%;
    cursor: pointer;
    height: var(--jackpot-card-height);
    margin: var(--jackpot-card-margin);
    position: relative;
    width: var(--jackpot-card-width);
    opacity: 1;
  }

  .numList {
    -ms-flex-align: center;
    -ms-flex-pack: center;
    align-items: center;
    bottom: var(--jackpot-card-count-to-bottom);
    display: -ms-flexbox;
    display: flex;
    height: var(--jackpot-card-count-box-height);
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    .count-to {
      -ms-flex-pack: center;
      display: -ms-flexbox;
      display: flex;
      justify-content: center;
      -ms-flex-align: center;
      align-items: center;
      letter-spacing: var(--jackpot-font-letter-spacing);
      -webkit-transform: scale(var(--jackpot-card-count-box-scale));
      transform: scale(var(--jackpot-card-count-box-scale));
      img {
        width: 0.56rem;
        height: 0.83rem;
        display: block;
      }

      img:nth-child(11) {
        position: relative;
        bottom: -1vw;
      }
    }
  }
}

.topNav {
  -ms-flex-align: center;
  -ms-flex-pack: justify;
  align-items: center;
  background-color: var(--theme-top-nav-bg);
  color: var(--theme-primary-font-color);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  height: 100%;
  justify-content: space-between;
  padding: 0 0.2rem 0 0.1rem;
  position: relative;
  // width: 100%;
  z-index: 252;
  height: 0.9rem;
  .sideBar {
    font-size: 0.36rem;
    margin: 0 0.2rem 0 0;
    transition: all 0.3s;
    color: var(--theme-text-color-lighten);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    transform: rotate(0);
  }

  .logo {
    // width: 35vw;
    display: -ms-flexbox;
    display: flex;
    height: 0.74rem;
    margin-left: 0;
    margin-right: 0.1rem;
    max-width: 3.3rem;
    min-width: 2.1rem;
  }

  .coin {
    --row-align: center;
    --mode: flex;
    --column-align: center;
    height: 0.4rem;
    border: 0.01rem solid var(--theme-color-line);
    border-radius: 0.2rem;
    // margin-left: 12vw;
    display: var(--mode);
    justify-content: space-between;
    align-items: var(--column-align);
    padding: 0 0.084rem 0 0.02rem;
    justify-content: var(--row-align);
    width: min-content;
    .refresh-coin {
      color: var(--theme-alt-primary);
      font-size: 0.293rem;
      position: relative;
      z-index: 10;
    }
    .flag {
      height: 0.34rem;
      width: 0.34rem;
      border-radius: 50%;
      overflow: hidden;
      align-items: center;
      justify-content: center;
      i {
        display: inline-block;
        position: relative;
        width: 0.3rem;
        height: 0.3rem;
        background-image: url("../../assets/814195b6-3e29-450b-8c2b-bdb30e15091f.png");
        background-position: -7.15714rem -2.79643rem;
        background-size: 7.77857rem 7.59107rem;
      }
      // img {
      //   width: 100%;
      //   height: 100%;
      // }
    }

    .numCoin {
      font-size: 0.26rem;
      color: var(--theme-secondary-color-finance);
      margin: -0.06rem 0.073rem 0 0.1rem;
      text-decoration: underline;
      display: block;

      max-width: 1.64rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .top-selectDownArrow {
    -ms-flex-align: center;
    -ms-flex-pack: start;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    justify-content: flex-start;
    .dropdown-trigger:after {
      background: var(--theme-primary-font-color);
      content: "";
      display: block;
      height: 0.24rem;
      left: 0;
      opacity: 0.3;
      position: absolute;
      top: 0.08rem;
      width: 0.01rem;
    }
    button {
      border: 1px solid #d9d9d9;
      border-radius: 0.1rem 0 0 0.1rem;
      font-size: 0.16rem;
      height: 0.4rem;
      margin-left: 0.1rem;
      min-width: 0.8rem;
      padding: 0;
      text-align: center;
      color: var(--theme-primary-font-color);
      background-image: none;
      background-color: var(--theme-primary-color);
      border-color: var(--theme-primary-color);
      font-weight: 400;
      text-shadow: none;
      box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
      line-height: 1.499;
    }

    .dropdown-trigger {
      -ms-flex-align: center;
      -ms-flex-pack: center;
      align-items: center;
      background-color: var(--theme-primary-color);
      border-color: var(--theme-primary-color);
      border-radius: 0 0.1rem 0.1rem 0 !important;
      color: var(--theme-primary-font-color);
      display: -ms-flexbox;
      display: flex;
      height: 0.4rem;
      justify-content: center;
      margin-left: -0.01rem;
      position: relative;
      width: 0.4rem;
    }
  }

  .loginbtn {
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
    border: 1px solid #d9d9d9;
    font-weight: 400;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    font-size: 0.16rem;
    height: 0.4rem;
    min-width: 1rem;
    // width: 13vw;
    // height: 4.8vw;
    // line-height: 4.8vw;
    // font-size: 0.16rem;
    // text-align: center;
    // color: #fffcd1;
    color: var(--theme-primary-font-color);
    text-shadow: none;
    // border: 1px solid #fffcd1;
    background-color: var(--theme-primary-color);
    border-color: var(--theme-primary-color);
    text-align: center;
    border-radius: 0.14rem;
    margin-right: 0.2rem;
    cursor: pointer;
  }
  .registerbtn {
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
    font-weight: 400;
    border: 1px solid #d9d9d9;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    border-radius: 0.14rem;
    text-align: center;
    background-color: transparent;
    border-color: var(--theme-primary-color);
    color: var(--theme-primary-color);
    font-size: 0.16rem;
    height: 0.4rem;
    min-width: 1rem;
    padding: 0 0.1rem;
    line-height: 1.499;
  }
  .menu {
    position: absolute;
    top: 0.8rem;
    background-color: var(--theme-main-bg-color);
    color: var(--theme-text-color-darken);
    right: 0.2rem;
    // padding: 3vw;
    border-radius: 0.1rem;
    z-index: 999;
    div {
      // width: 18vw;
      min-width: 1.3rem;
      height: 0.8rem;
      line-height: 0.8rem;
      font-size: 0.24rem;
      margin: 0;
      padding: 0 0.19rem;
    }
  }

  .searchBtn {
    color: var(--theme-text-color);
    font-size: 0.25rem;
    margin-left: 0.15rem;
  }
}

.swiper {
  // width: 94vw;
  // margin: 22vw auto 0vw;
  padding: 0 0.2rem 0.2rem;
  --common-banner-borderRedius: 0.2rem;

  .swipeImg {
    height: var(--common-banner-height);
    width: var(--common-banner-commonModeCardWidth);
    border-radius: var(--common-banner-borderRedius);
    /deep/ .van-image__img {
      border-radius: var(--common-banner-borderRedius);
    }
  }
}

.notice {
  .van-notice-bar {
    background: transparent;
    color: #ffaa09;
  }

  .noticeImg {
    height: 0.32rem;
    top: -0.01rem;
    width: 0.36rem;
  }
}
.midNavH5 {
  /deep/ .van-tabs__wrap {
    background-color: var(--theme-home-bg);
    border-bottom: 0.01rem solid var(--theme-color-line);
    height: 1.06rem;
    padding: 0.08rem 0 0;
  }

  .h5MidMenuTab {
    min-width: 1.5rem;
    position: relative;
    text-align: center;
    z-index: 2;
  }
}
// /deep/.van-tabs--line .van-tabs__wrap {
//   height: 15vw;
// }

.content {
  .leftButton,
  .rightButton {
    background-color: rgba(0, 0, 0, 0.1);
    border: 0.01rem solid hsla(0, 0%, 100%, 0.1);
    border-radius: 0.18rem;
    color: #fff;
    cursor: pointer;
    height: 0.26rem;
    line-height: 0.36rem;
    text-align: center;
    top: 0.2rem;
    // -webkit-transform: translateY(-50%);
    // transform: translateY(-50%);
    width: 0.26rem;
    position: absolute;
    z-index: 99;
  }
  .leftButton {
    left: 0;
    transform: rotateZ(180deg);
    -webkit-transform: rotateZ(180deg);
  }
  .rightButton {
    right: 0;
  }
  .hideButton {
    display: none;
  }
  // padding: 0 1vw;
  /deep/.van-sticky--fixed {
    max-width: var(--theme-max-width);
  }
  .tabsTitle {
    color: var(--theme-alt-neutral-2);
    display: flex;
    flex-direction: column;
    font-size: 0.24rem;
    height: 0.94rem;
    .imageWrap {
      height: 0.46rem;
      text-align: center;
      .navIcon {
        font-size: 0.6rem;
        height: 0.46rem;
        width: 0.6rem;
      }
    }
    .game-category-name {
      line-height: 1.5;
      max-width: 1.36rem;
      width: 1.36rem !important;
      .span2 {
        vertical-align: middle;
        display: inline-block;
        width: 100%;
        .inner-text {
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          display: -webkit-box;
          line-height: 1.2;
          overflow: hidden;
          overflow: initial;
          text-align: center;
          text-overflow: ellipsis;
          vertical-align: middle;
          word-break: break-word;
          text-transform: capitalize;

        }
      }
    }
  }

  .tabDetailItem {
    // background: #5375D1;
    // padding: 0 1vw;
    width: 6.9rem;
    margin: 0px auto;
    .title {
      display: flex;
      align-items: center;
      color: #fff;
      //   padding: 3vw 0;
      font-size: 0.3rem;

      // img {
      //   margin-right: 0.3vw;
      //   width: 7vw;
      // }
    }
  }
}

.footer {
  padding: 0 0 0.3rem;
  box-shadow: none;
  min-height: auto;
  background: var(--theme-side-footer-bg-color);
  .section {
    padding: 0 0.2rem 0.2rem;
    width: 100%;
    margin: 0 auto;
    position: relative;
    z-index: 1;
    .footerBox {
      -ms-flex-align: start;
      -ms-flex-pack: center;
      align-items: flex-start;
      border-bottom: 0.01rem solid var(--theme-color-line);
      justify-content: center;
      padding: 0.67rem 0 0.4rem;
      position: relative;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;

      .topmenu {
        border-bottom: 0.01rem solid var(--theme-color-line);
        margin: 0;
        padding-bottom: 0.48rem;
        width: 7.1rem;

        // flex-wrap: wrap;
        -ms-flex-pack: justify;
        border-bottom: 0.01rem solid var(--theme-color-line);
        display: -ms-flexbox;
        display: flex;
        justify-content: space-between;
        .footerList {
          width: 2.23rem;
          margin-right: 0.2rem;
        }
        .listTile {
          color: var(--theme-text-color-darken);
          font-size: 0.24rem;
          margin-top: 0;
          line-height: 0.34rem;
          margin-bottom: 0.2rem;

          font-weight: 400;
        }

        .listItem {
          font-size: 0.24rem;
          line-height: 0.34rem;
          margin-bottom: 0.1rem;
          color: var(--theme-text-color-lighten);
          overflow: hidden;
          padding-right: 0.2rem;
          text-overflow: ellipsis;
          white-space: nowrap;

          cursor: pointer;
          display: block;
        }
      }
      .plus18 {
        -ms-flex-align: center;
        -ms-flex-pack: center;
        align-items: center;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        justify-content: center;
        margin-top: 0.4rem;
        width: 100%;
        .rule {
          margin-top: 0.1rem;
          text-align: center;
          font-size: 0.2rem !important;
          margin-bottom: 0.4rem !important;
        }
        a {
          color: var(--theme-text-color-lighten);
          display: inline-block;
          margin-bottom: 0.1rem;
          margin-right: 0.2rem;
          text-decoration: underline;
          word-break: break-all;
        }
        .rule:hover {
          color: rgb(208, 243, 209);
        }
      }
    }

    .cardList {
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 0.4rem;
      margin: 0.3rem 0 0.4rem;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-pack: center;
      display: flex;
      -ms-flex-wrap: wrap;
      width: 100%;
      .ant-btn-link {
        background-color: transparent;
        border-color: transparent;
        -webkit-box-shadow: none;
        box-shadow: none;
        color: var(--theme-ant-primary-color-0);
        height: 100%;
        margin-right: 0.1rem !important;
        margin-top: 0 !important;
        // width: 1rem;
        height: 0.36rem;
        margin-bottom: 0.2rem;
      }
      button {
        outline: 0;
        background-color: #fff;
        background-image: none;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
        box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
        color: rgba(0, 0, 0, 0.65);
        cursor: pointer;
        display: inline-block;
        font-size: 14px;
        font-weight: 400;
        height: 32px;
        line-height: 1.499;

        position: relative;
        text-align: center;
        -ms-touch-action: manipulation;
        touch-action: manipulation;
        -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        white-space: nowrap;
      }
    }

    .fLink {
      flex-wrap: wrap;
      height: auto;
      justify-content: center;
      -ms-flex-align: center;
      -ms-flex-pack: initial;
      align-items: center;
      border: none;
      min-width: 1rem;
      padding: 0;
      display: flex;
      width: 100%;
    }
  }
}

/deep/.el-dialog {
  background: var(--theme-main-bg-color);
  border: thin solid;
  border-color: var(--theme-color-line);
  border-radius: 0.2rem;
  .el-dialog__body {
    padding: 0;
    padding-left: 0.24rem;
    padding-right: 0.24rem;
  }
}

/deep/.el-dialog__close {
  color: #fff !important;
}

.floatBox {
  bottom: 1.44rem;
  right: 0.1rem;
  -ms-flex-align: end;
  align-items: flex-end;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  position: absolute;
  z-index: 100;

  .ad {
    margin-bottom: 0.1rem;

    .carouselBox {
      min-height: 1.68rem;
      width: 1.5rem;
      align-items: flex-start;
      display: flex;
      position: relative;
    }

    .invite {
      width: 1.5rem;
      -ms-flex-align: center;
      align-items: center;
      cursor: pointer;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      margin-top: 0.2rem;
      position: relative;
      // width: 1.2rem;

      img {
        width: 99%;
        height: 99%;
      }

      .close {
        height: 0.3rem;
        position: absolute;
        right: 0;
        top: 0;
        width: 0.3rem;
      }
    }
  }
  .toTop {
    background-color: var(--theme-main-bg-color);
    border: 0.01rem solid var(--theme-color-line);
    border-radius: 0.1rem;
    -webkit-box-shadow: 0.02rem 0 0.06rem 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0.02rem 0 0.06rem 0 rgba(0, 0, 0, 0.1);
    cursor: pointer;
    -ms-flex-direction: column;
    flex-direction: column;
    min-height: 0.78rem;
    //padding: 0.1rem;
    width: 0.84rem;
    min-height: 1rem;
    //padding: 0.14rem 0.1rem;
    width: 1.1rem;
    justify-content: center;
    display: flex;
    align-items: center;
    .icobg {
      background-color: var(--theme-primary-color);
      border-radius: 50%;
      color: var(--theme-primary-font-color);
      font-size: 0.2rem;
      margin-bottom: 0.08rem;
      height: 0.38rem;
      width: 0.38rem;
      -ms-flex-align: center;
      -ms-flex-pack: center;
      align-items: center;
      display: -ms-flexbox;
      display: flex;
      justify-content: center;
    }
  }
}

/deep/.el-backtop {
  bottom: 21vw !important;
}
/deep/.van-sticky--fixed {
  top: var(--home-top-height);
  // top:{downboxheight};
  // top: 19vw;
  // z-index: 99999;
  margin: 0 auto;
}

.my-swipe {
  --common-banner-height: 2.1rem;
  --common-banner-borderRedius: 0.2rem;
  --common-banner-commonModeCardWidth: 8.5rem;
}
</style>
