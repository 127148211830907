// export function deepClone(source) {
//   if (typeof source != "object") {
//     return source;
//  }
//  if (source == null) {
//      return source;
//  }
//  var newObj = source.constructor === Array ? [] : {};  //开辟一块新的内存空间
//  for (var i in source) {
//       newObj[i] = deepCopy(source[i]);
//  }
//  return newObj
// }
export function isEmpty(value) {
  if (value === null || value === undefined) {
    return true;
  }
  if (typeof value === 'string' && value.trim() === '') {
    return true;
  }
  if (Array.isArray(value) && value.length === 0) {
    return true;
  }
  if (typeof value === 'object' && Object.keys(value).length === 0) {
    return true;
  }
  return false;
}
export function formatNumberToString(inputNumber) {
  let result = inputNumber / 100;
  result = result.toFixed(2);
  result = result.replace('.', ',');
  if (result.length === 4) {
    result = '0' + result;
  }
  let parts = result.split(',');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  result = parts.join(',');
  return result;
}

export function formatNumberToString2(inputNumber) {
  let result = inputNumber / 100;
  result = result.toFixed(2);
  result = result.replace('.', ',');
  let parts = result.split(',');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  result = parts.join(',');
  return result;
}
//格式化日期，将日期倒立
export function formatDateVirar(date) {
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}
//格式化日期，正常
export function formatDate(date) {
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${year}/${month}/${day}`;
}
//格式化日期+时间，将日期倒立 时间正常
export function formatDateTimeVirar(date) {
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  const h = date.getHours().toString().padStart(2, '0');
  const m = date.getMinutes().toString().padStart(2, '0');
  const s = date.getSeconds().toString().padStart(2, '0');
  return `${day}/${month}/${year} ${h}:${m}:${s}`;
}
export function addDaysToDate(date, d) {
  var newDate = new Date(date);
  newDate.setDate(newDate.getDate() + d);
  return newDate;
}
export function arraysOfObjectsAreEqual(arr1, arr2) {
  if (arr1.length !== arr2.length) {
    return false;
  }
  // 深度比较每个对象
  for (let i = 0; i < arr1.length; i++) {
    const obj1 = arr1[i];
    const obj2 = arr2[i];
    // 检查对象属性数量
    if (Object.keys(obj1).length !== Object.keys(obj2).length) {
      return false;
    }
    for (let key in obj1) {
      if (obj1[key] !== obj2[key]) {
        return false;
      }
    }
  }
  return true;
}

//将秒转成(分钟:秒)的格式
export function formatTime(seconds) {
  if (isNaN(seconds) || seconds < 0) {
    return "Invalid input";
  }

  var minutes = Math.floor(seconds / 60);
  var remainingSeconds = seconds % 60;

  var formattedTime = minutes + ":" + remainingSeconds.toString().padStart(2, '0');
  return formattedTime;
}

//传入字符串和指定长度做省略处理
export function truncateString(s, l, position = 'start', prefix = '****') {
  if (s.length <= l) {
    return s;
  } else {
    switch (position) {
      case 'start':
        return prefix + s.substring(s.length - l);
      case 'middle':
        const halfLength = Math.floor(l / 2);
        const start = s.substring(0, halfLength);
        const end = s.substring(s.length - halfLength);
        return start + prefix + end;
      case 'end':
        return s.substring(0, l) + prefix;
      default:
        throw new Error('Invalid position parameter. Use "start", "middle", or "end".');
    }
  }
}


export function hashKey(input) {
  var str = JSON.stringify(input);
  var hash = 0, i, chr;
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash.toString(16);
}

export function formatString(e, t, n = "") {
  let i = "";
  if (arguments.length === 2) {
    n = t;
  } else if (arguments.length === 3) {
    i = t;
  }
  const o = n.split(/(\d+|\D)/).filter((e => e));
  const r = o.filter(e => isNaN(Number(e)));
  const s = e.split("").filter(e => !r.includes(e)).join("");
  if (i && e.length < i.length && r.includes(i.slice(-1))) {
    return e.slice(0, -1);
  }
  let a = "";
  let c = 0;
  let l = 0;
  for (let u = 0; u < o.length && s[c]; u++) {
    if (!isNaN(Number(o[u]))) {
      const e = c + Number(o[u]);
      a += s.slice(c, e);
      c = e;
      l += Number(o[u]);
    }
    if (l === a.length && r.includes(o[u + 1])) {
      a += o[u + 1];
      l += 1;
    }
  }
  return a;
}

export function getOperatingSystem() {
  if (navigator.userAgent.indexOf("Win") !== -1) return "Windows";
  if (navigator.userAgent.indexOf("Mac") !== -1) return "MacOS";
  if (navigator.userAgent.indexOf("X11") !== -1) return "UNIX";
  if (navigator.userAgent.indexOf("Linux") !== -1) return "Linux";
  if (/Android/.test(navigator.userAgent)) return "Android";
  if (/like Mac OS X/.test(navigator.userAgent)) {
    const version = /CPU( iPhone)? OS ([0-9_]+) like Mac OS X/.exec(navigator.userAgent)
      .pop()
      .replace(/_/g, ".");
    return "iOS " + version;
  }
  return "Unknown";
}
export function getBrowser() {
  const userAgent = navigator.userAgent;
  if (userAgent.indexOf("Firefox") !== -1) return "Firefox";
  if (userAgent.indexOf("Opera Mini") !== -1) return "Opera Mini";
  if (userAgent.indexOf("Opera") !== -1) return "Opera";
  if (userAgent.indexOf("MSIE") !== -1 || userAgent.indexOf("Trident") !== -1) return "IE";
  if (userAgent.indexOf("Edge") !== -1) return "Edge";
  if (userAgent.indexOf("Chrome") !== -1) return "Chrome";
  if (userAgent.indexOf("Safari") !== -1) return "Safari";
  return "Unknown";
}
export function getElementWidth(element) {

  if (element instanceof HTMLElement) {
    return element.offsetWidth;
  } else {
    throw new Error("The provided argument is not a valid DOM element.");
  }
}

export function getRandomInteger(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
 
export function capitalizeFirstLetter(value){
  return value.toLowerCase().replace(/\b[a-z]/g, function(match) {
    return match.toUpperCase();
  });
}