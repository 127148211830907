<template>
  <div class="wrap">
    <div class="topMenu">
      <div class="menuBox">
        <div
          class="menuItem"
          v-for="(item, index) in topMenu"
          :key="index"
          :class="active === index ? 'active' : ''"
          @click="tabChange(index)"
        >
          {{ item.title }}
        </div>
      </div>
    </div>

    <section class="sketch">
      <div class="areaSupport">
        <div class="item">
          <span class="sptitle">{{ $t('add direct members') }}</span>
          <span class="situation"
            ><span>{{ count_add_subordinate }}</span></span
          >
        </div>
        <div class="item">
          <span class="sptitle">{{ $t('first deposits') }}</span>
          <span class="situation"
            ><span>{{ count_first_deposit }}</span></span
          >
        </div>
        <div class="item">
          <span class="sptitle">{{ $t('deposits') }}</span>
          <span class="situation"
            ><span>{{ count_deposit }}</span></span
          >
        </div>
        <div class="item">
          <span class="sptitle">{{ $t('deposits') }}</span>
          <span class="situation"
            ><span>{{ deposit }}</span></span
          >
        </div>
        <div class="item">
          <span class="sptitle">{{ $t('performance') }}</span>
          <span class="situation"
            ><span>{{ flow }}</span></span
          >
        </div>
        <div class="item">
          <span class="sptitle">{{ $t('commission') }}</span>
          <span class="situation"
            ><span
              style="color: var(--theme-secondary-color-finance) !important"
              >{{ commission }}</span
            ></span
          >
        </div>
      </div>
    </section>

    <section class="detailed">
      <div class="dataCenter">
        <div class="titleBox">
          <span>{{ $t('data overview') }}</span>
        </div>
        <div class="describe">
          <div class="d-item">
            <div>
              <div class="link"><span>{{ $t('my team') }}</span></div>
            </div>
            <div class="showroom">
              <span class="toptitle">{{ $t('members') }}</span>
              <span
                ><span class="currencyAmount"> {{ total_count }}</span></span
              >
            </div>
            <div class="showroom">
              <span class="toptitle">{{ $t('direct members') }}</span>
              <span
                ><span class="currencyAmount">
                  {{ count_subordinate }}</span
                ></span
              >
            </div>
            <div class="showroom">
              <span class="toptitle">{{ $t('other members') }}</span>
              <span
                ><span class="currencyAmount"> {{ count_other }}</span></span
              >
            </div>
          </div>
        </div>

        <div class="describe">
          <div class="d-item">
            <div>
              <div class="link"><span>{{ $t('performance') }}</span></div>
            </div>
            <div class="showroom">
              <span class="toptitle">{{ $t('total yield') }}</span>
              <span
                ><span class="currencyAmount">{{
                  total_performance
                }}</span></span
              >
            </div>
            <div class="showroom">
              <span class="toptitle">{{ $t('d. direct') }}</span>
              <span
                ><span class="currencyAmount">{{
                  performance_subordinate
                }}</span></span
              >
            </div>
            <div class="showroom">
              <span class="toptitle">{{ $t('d. others') }}</span>
              <span
                ><span class="currencyAmount">
                  {{ performance_other }}</span
                ></span
              >
            </div>
          </div>
        </div>

        <div class="describe">
          <div class="d-item">
            <div>
              <div class="link"><span>{{ $t('commission') }}</span></div>
            </div>
            <div class="showroom">
              <span class="toptitle">{{ $t('total commissions') }}</span>
              <span
                ><span class="currencyAmount yellowColor">
                  {{ total_commission }}</span
                ></span
              >
            </div>
            <div class="showroom">
              <span class="toptitle">{{ $t('direct commission') }}</span>
              <span
                ><span class="currencyAmount yellowColor">
                  {{ commission_subordinate }}</span
                ></span
              >
            </div>
            <div class="showroom">
              <span class="toptitle">{{ $t('another commission') }}</span>
              <span
                ><span class="currencyAmount yellowColor">
                  {{ commission_other }}</span
                ></span
              >
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { getPeriodMyData, getTotalMyData } from "@/api/index";
import { formatNumberToString2 } from "@/utils/utils";

export default {
  name: "myDataList",
  data() {
    return {
      active: 0,
      topMenu: [
        {
          index: 0,
          title: this.$t('yesterday'),
          active: "yesterday",
        },
        {
          index: 1,
          title: this.$t('today'),
          active: "today",
        },
        {
          index: 2,
          title: this.$t('this week'),
          active: "this_week",
        },
        {
          index: 3,
          title: this.$t('last week'),
          active: "last_week",
        },
        {
          index: 4,
          title: this.$t('this month'),
          active: "this_month",
        },
        {
          index: 5,
          title: this.$t('last month'),
          active: "last_month",
        },
      ],
      count_add_subordinate: 0,
      count_deposit: 0,
      count_first_deposit: 0,
      deposit: "0,00",
      flow: "0,00",
      commission: "0,00",

      count_subordinate: 0,
      count_other: 0,
      total_count: 0,

      performance_subordinate: "0,00",
      performance_other: "0,00",
      total_performance: "0,00",
      commission_subordinate: "0,00",
      commission_other: "0,00",
      total_commission: "0,00",
    };
  },
  created() {},
  mounted() {

    this.getPeriodMyData(0);
  },
  destroyed() {},
  methods: {
    remoteMethod(query) {},
    getTotalMyData() {
      getTotalMyData().then(({ data }) => {
        this.count_subordinate = data.data.count_subordinate;
        this.count_other = data.data.count_other;
        this.total_count = data.data.total_count;
        this.performance_subordinate = formatNumberToString2(
          data.data.performance_subordinate
        );
        this.performance_other = formatNumberToString2(data.data.performance_other);
        this.total_performance = formatNumberToString2(data.data.total_performance);
        this.commission_subordinate = formatNumberToString2(
          data.data.commission_subordinate
        );
        this.commission_other = formatNumberToString2(data.data.commission_other);
        this.total_commission = formatNumberToString2(data.data.total_commission);
      });
    },
    tabChange(index) {
      this.active = index;
      this.getPeriodMyData(index);
    },
    getPeriodMyData(index) {
      const m = this.topMenu[index];
      getPeriodMyData({ period: m.active }).then(({ data }) => {
        this.count_add_subordinate = data.data.count_add_subordinate;
        this.count_deposit = data.data.count_deposit;
        this.count_first_deposit = data.data.count_first_deposit;
        this.deposit = formatNumberToString2(data.data.deposit);
        this.flow = formatNumberToString2(data.data.flow);
        this.commission = formatNumberToString2(data.data.commission);
      });
    },
  },
};
</script>
<style type="text/less" lang="less" scoped>
.wrap {
  background-color: var(--theme-bg-color);
  display: block;
  height: 100%;
  .topMenu {
    overflow-x: scroll;
    width: 100%;
    .menuBox {
      -ms-flex-pack: start;
      border-bottom: 0.01rem solid var(--theme-color-line);
      justify-content: flex-start;
      min-width: 100%;
      padding: 0.2rem;
      width: -webkit-max-content;
      width: -moz-max-content;
      width: max-content;
      -ms-flex-align: center;
      align-items: center;
      background-color: var(--theme-main-bg-color);
      display: -ms-flexbox;
      display: flex;

      .menuItem {
        -ms-flex-pack: center;
        border: 0.01rem solid var(--theme-color-line);
        border-radius: 0.25rem;
        color: var(--theme-text-color);
        cursor: pointer;
        font-size: 0.22rem;
        justify-content: center;
        min-height: 0.5rem;
        min-width: 1rem;
        overflow: hidden;
        padding: 0 0.1rem;
        text-align: center;
        white-space: nowrap;
        display: flex;
        align-items: center;
        background-color: var(--theme-main-bg-color);
      }
      .menuItem:not(:last-child) {
        margin-right: 0.2rem;
      }
      .active {
        background-color: var(--theme-primary-color);
        border: 0.01rem solid var(--theme-primary-color);
        color: var(--theme-primary-font-color);
      }
    }
  }

  .sketch {
    padding: 0 0.2rem;
    .areaSupport {
      -ms-flex-align: center;
      -ms-flex-pack: justify;
      align-items: center;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 0.2rem;
      .item {
        -ms-flex-align: center;
        -ms-flex-pack: center;
        align-items: center;
        background-color: var(--theme-main-bg-color);
        border-radius: 0.14rem;
        -webkit-box-shadow: 0 0.03rem 0.1rem 0 var(--theme-bg-shadow);
        box-shadow: 0 0.03rem 0.1rem 0 var(--theme-bg-shadow);
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        height: 1.5rem;
        justify-content: center;
        margin-bottom: 0.2rem;
        padding: 0.2rem;
        width: 2.24rem;
        .sptitle {
          color: var(--theme-text-color-lighten);
          font-size: 0.24rem;
          margin-bottom: 0.2rem;
          text-align: center;
        }
        .situation {
          color: var(--theme-text-color-darken);
          font-size: 0.34rem;
        }
      }
    }
  }

  .detailed {
    padding: 0 0.2rem;
    .dataCenter {
      background-color: var(--theme-main-bg-color);
      border-radius: 0.14rem;
      -webkit-box-shadow: 0 0.03rem 0.1rem 0 var(--theme-bg-shadow);
      box-shadow: 0 0.03rem 0.1rem 0 var(--theme-bg-shadow);
      .titleBox {
        -ms-flex-align: center;
        -ms-flex-pack: justify;
        align-items: center;
        border-bottom: 0.01rem solid var(--theme-color-line);
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        display: -ms-flexbox;
        display: flex;
        height: 0.6rem;
        justify-content: space-between;
        padding: 0 0.2rem;
        height: 0.8rem;
        span:first-child {
          color: var(--theme-text-color-darken);
          font-size: 0.24rem;
        }
      }
      .describe {
        padding: 0 0.2rem;
        .d-item {
          -ms-flex-align: center;
          -ms-flex-pack: justify;
          align-items: center;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
          font-size: 0.24rem;
          justify-content: space-between;
          div:first-child {
            border-bottom: 0.01rem solid var(--theme-color-line);
            color: var(--theme-text-color);
            width: 100%;
          }
          .link {
            font-size: 0.24rem;
            height: 0.6rem;
            padding: 0.15rem 0.2rem;
            position: relative;
            text-align: center;
            width: 100%;
          }
          .link::before {
            border-color: var(--theme-text-color);
            border-style: solid;
            border-width: 0.01rem 0.01rem 0 0;
            content: "";
            display: block;
            height: 0.15rem;
            position: absolute;
            right: 0.2rem;
            top: 50%;
            -webkit-transform: translate(-50%, -50%) rotate(45deg);
            transform: translate(-50%, -50%) rotate(45deg);
            width: 0.15rem;
          }
          .showroom {
            -ms-flex-align: center;
            -ms-flex-pack: center;
            align-items: center;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-direction: column;
            flex-direction: column;
            font-size: 0.22rem;
            height: 1.2rem;
            justify-content: center;
            position: relative;
            width: 33.3%;
            .currencyAmount {
              color: var(--theme-text-color-darken);
              font-size: 0.24rem;
            }
            .toptitle {
              color: var(--theme-text-color-lighten);
              margin-bottom: 0.15rem;
              text-align: center;
            }
          }
          .showroom:not(:last-child):after {
            background-color: var(--theme-color-line);
            content: "";
            height: 0.8rem;
            position: absolute;
            right: 0;
            top: 50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            width: 0.01rem;
          }
        }
        .d-item:not(:last-child) {
          border-bottom: 0.01rem solid var(--theme-color-line);
        }
      }
    }
  }
}
</style>
