<template>
  <div class="box">
    <el-container>
      <el-header height=".9rem">
        <van-nav-bar
          @click-left="onClickLeft"
          right-text=" "
          :fixed="true"
          style="
            max-width: var(--theme-max-width);
            margin: 0 auto;
            left: 0;
            right: 0;
            height: 0.9rem;
          "
          :border="false"
        >
          <template #left>
            <div>
              <i style="font-size: 0.24rem">
                <svg-icon
                  icon-class="comm_icon_fh"
                  style="
                    width: 1em;
                    height: 1em;
                    color: var(--theme-text-color);
                    fill: var(--theme-text-color-lighten);
                  "
                ></svg-icon>
              </i>
            </div>
          </template>
          <template #title>
            <div class="title">
              <span class="global-wrapper-title">
                <span
                  style="
                    width: 100%;
                    margin-bottom: -1.64706px;
                    padding-bottom: 1.64706px;
                    font-size: 0.3rem;
                  "
                  ><span
                    style="
                      vertical-align: middle;
                      display: inline-block;
                      width: 100%;
                    "
                  >
                    <span style="-webkit-line-clamp: 2" class="ts inner-text">
                      {{ getTitle() }}</span
                    >
                  </span></span
                >
              </span>
            </div>
          </template>
        </van-nav-bar>
      </el-header>
      <el-main>
        <myScrollbarBar
          style="width: 100%; height: 100%"
          st="y"
          v-if="current > 6"
        >
          <div class="context">
            <div>
              <van-cell-group :border="false">
                <van-cell :title="$t('username')">
                  <template #icon>
                    <i
                      class="anticon"
                      style="
                        color: var(--theme-text-color-lighten);
                        display: flex;
                      "
                    >
                      <i
                        style="
                          display: inline-flex;
                          justify-content: center;
                          align-items: center;
                        "
                      >
                        <svg-icon
                          icon-class="input_icon_zh"
                          style="
                            color: var(--theme-text-color-lighten);
                            fill: currentColor;
                            font-size: 14px;
                          "
                        ></svg-icon></i
                    ></i>
                  </template>
                  {{ account }}
                </van-cell>
                <van-cell
                  :title="$t('telephone number')"
                  :is-link="!phone"
                  :to="!phone ? '/security?current=0' : ''"
                >
                  <template #icon>
                    <i
                      class="anticon"
                      style="color: var(--theme-primary-color); display: flex"
                    >
                      <i
                        style="
                          display: inline-flex;
                          justify-content: center;
                          align-items: center;
                        "
                      >
                        <svg-icon
                          icon-class="input_icon_sj"
                          style="fill: currentColor; font-size: 14px"
                        ></svg-icon></i
                    ></i>
                  </template>

                  {{ phone ? truncateString(phone) :$t('unlink') }}
                </van-cell>

                <van-cell
                  :title="$t('email address')"
                  :is-link="true"
                  to="/security?current=2"
                >
                  <template #icon>
                    <i
                      class="anticon"
                      style="color: var(--theme-primary-color); display: flex"
                    >
                      <i
                        style="
                          display: inline-flex;
                          justify-content: center;
                          align-items: center;
                        "
                      >
                        <svg-icon
                          icon-class="input_icon_yj"
                          style="fill: currentColor; font-size: 14px"
                        ></svg-icon></i
                    ></i>
                  </template>

                  {{ email ? truncateString(email) : $t('unlink') }}
                </van-cell>

                <van-cell
                  :title="$t('google authenticator')"
                  :is-link="true"
                  to="/security?current=3"
                >
                  <template #icon>
                    <i
                      class="anticon"
                      style="color: var(--theme-primary-color); display: flex"
                    >
                      <i
                        style="
                          display: inline-flex;
                          justify-content: center;
                          align-items: center;
                        "
                      >
                        <svg-icon
                          icon-class="icon_wd_ggyz_b"
                          style="fill: currentColor; font-size: 14px"
                        ></svg-icon></i
                    ></i>
                  </template>
                  {{ google_authentication ? $t('activated'): $t('unlink') }}
                </van-cell>
              </van-cell-group>
              <van-cell-group :border="false">
                <van-cell
                  :title="$t('login password')"
                  value=""
                  :is-link="true"
                  to="/security?current=4"
                >
                  <template #icon>
                    <i class="anticon" style="color: #04be02; display: flex">
                      <i
                        style="
                          display: inline-flex;
                          justify-content: center;
                          align-items: center;
                        "
                      >
                        <svg-icon
                          icon-class="input_icon_yz"
                          style="fill: currentColor; font-size: 14px"
                        ></svg-icon></i
                    ></i>
                  </template>
                </van-cell>
                <van-cell
                  :title="$t('withdrawal password')"
                  :value="
                    withdraw_password ? $t('was defined') : $t('not configured')
                  "
                  :is-link="true"
                  to="/security?current=5"
                >
                  <template #icon>
                    <i class="anticon" style="color: #e94d3c; display: flex">
                      <i
                        style="
                          display: inline-flex;
                          justify-content: center;
                          align-items: center;
                        "
                      >
                        <svg-icon
                          icon-class="input_icon_card"
                          style="fill: currentColor; font-size: 14px"
                        ></svg-icon></i
                    ></i>
                  </template>
                </van-cell>

                <van-cell
                  :title="$t('security question')"
                  :value="
                    security_question ? $t('was defined') : $t('not configured')
                  "
                  :is-link="true"
                  to="/security?current=6"
                >
                  <template #icon>
                    <i class="anticon" style="color: #04be02; display: flex">
                      <i
                        style="
                          display: inline-flex;
                          justify-content: center;
                          align-items: center;
                        "
                      >
                        <svg-icon
                          icon-class="input_icon_mb"
                          style="fill: currentColor; font-size: 14px"
                        ></svg-icon></i
                    ></i>
                  </template>
                </van-cell>
              </van-cell-group>

              <van-cell-group :border="false" style="display: none">
                <van-cell
                  :title="$t('bind third login')"
                  :value="$t('not configured')"
                >
                  <template #icon>
                    <i
                      class="anticon"
                      style="color: var(--theme-primary-color); display: flex"
                    >
                      <i
                        style="
                          display: inline-flex;
                          justify-content: center;
                          align-items: center;
                        "
                      >
                        <svg-icon
                          icon-class="input_icon_dsfbd"
                          style="fill: currentColor; font-size: 14px"
                        ></svg-icon></i
                    ></i>
                  </template>
                </van-cell>
              </van-cell-group>
            </div>
          </div>
        </myScrollbarBar>

        <div v-else class="theme-box-shadow">
          <div class="structure">
            <div class="steps-content-wrap">
              <div class="basic-verification-content">
                <div class="formBox">
                  <div>
                    <el-form :rules="rules" ref="editfrom" :model="editfrom">
                      <span class="toptips">
                        <span>{{ getTopTips() }}</span>
                      </span>
                      <div
                        class="topValidEmail"
                        v-if="
                          current <= 6 && isEditVerification && verifyID === 3
                        "
                      >
                        <span
                          style="
                            color: var(--theme-text-color-lighten);
                            margin-right: 0.1rem;
                          "
                          >{{ $t('currently linked e-mail') }}</span
                        >
                        <span style="color: var(--theme-text-color-lighten)">{{
                          email
                        }}</span>
                      </div>
                      <el-form-item
                        style="margin-bottom: 0.5rem"
                        prop="phone"
                        v-if="current === 0 && !isEditVerification"
                      >
                        <el-input
                          class="myinput"
                          v-model="editfrom.phone"
                          type="tel"
                          @input="
                            (v) => {
                              editfrom.phone = v.replace(/\D/g, '');
                            }
                          "
                          :placeholder="$t('enter Telegram ID')"
                        >
                          <template slot="prefix">
                            <el-select
                              class="selectIpt"
                              v-model="editfrom.tel"
                              :popper-append-to-body="false"
                              popper-class="myselectitem"
                              maxlength="11"
                            >
                              <template slot="prefix">
                                <img src="@/assets/flag/flagB_14.png" />
                              </template>

                              <el-option value="+55">
                                <div class="my-item">
                                  <img
                                    src="@/assets/flag/flagB_14.png"
                                    style="width: 0.4rem; height: 0.4rem"
                                  />
                                  <span
                                    style="
                                      margin-left: 0.5rem;
                                      font-size: 0.22rem;
                                      max-width: 3rem;
                                      overflow: hidden;
                                      text-overflow: ellipsis;
                                      white-space: nowrap;
                                      color: var(--theme-primary-color);
                                    "
                                    >Brasil（+55）</span
                                  >
                                </div>
                              </el-option>
                            </el-select>
                          </template>
                          <template slot="suffix">
                            <i
                              v-show="editfrom.phone.length > 0"
                              style="
                                display: inline-flex;
                                justify-content: center;
                                align-items: center;
                                color: var(--theme-text-color-lighten);
                                height: 0.25rem;
                                width: 0.25rem;
                                font-size: 0.18rem;
                                cursor: pointer;
                              "
                              @click="editfrom.phone = ''"
                            >
                              <svg-icon
                                icon-class="input_icon_clear"
                                style="
                                  width: 0.25rem;
                                  height: 0.25rem;
                                  fill: currentColor;
                                "
                              ></svg-icon>
                            </i>
                          </template>
                        </el-input>
                        <template slot="error" slot-scope="scope">
                          <div class="err-explain">{{ scope.error }}</div>
                        </template>
                      </el-form-item>
                      <el-form-item
                        style="margin-bottom: 0.5rem"
                        prop="email"
                        v-if="current === 2 && !isEditVerification"
                      >
                        <el-autocomplete
                          class="myinput2"
                          popper-class="selectitem"
                          v-model="editfrom.email"
                          :placeholder="$t('please enter your email')"
                          :fetch-suggestions="querySearchEMail"
                        >
                          <template slot="prefix">
                            <div class="ico">
                              <img
                                src="@/assets/login_icon_yx02.png"
                                style="width: 0.36rem; height: 0.3rem"
                              />
                            </div>
                          </template>
                          <template slot="suffix">
                            <i
                              v-show="editfrom.email.length > 0"
                              style="
                                display: inline-flex;
                                justify-content: center;
                                align-items: center;
                                color: var(--theme-text-color-lighten);
                                height: 0.25rem;
                                width: 0.25rem;
                                font-size: 0.18rem;
                                cursor: pointer;
                              "
                              @click="editfrom.email = ''"
                            >
                              <svg-icon
                                icon-class="input_icon_clear"
                                style="
                                  width: 0.25rem;
                                  height: 0.25rem;
                                  fill: currentColor;
                                "
                              ></svg-icon>
                            </i>
                          </template>
                        </el-autocomplete>
                        <template slot="error" slot-scope="scope">
                          <div class="err-explain">{{ scope.error }}</div>
                        </template>
                      </el-form-item>
                      <div v-if="current === 3 && !isEditVerification">
                        <div v-if="!google_authentication">
                          <section class="tipsItem" style="font-weight: 400">
                            <span
                              >{{ $t('step') }} 1: {{ $t('google tip1') }}</span
                            >
                            <div>
                            {{ $t('google auth tip2') }}
                            </div>
                          </section>
                          <section class="tipsItem" style="margin-top: 0.3rem">
                            <span
                              >{{ $t('step') }} 2:{{ $t('google auth tip3') }}</span
                            >

                            <div class="betweenparties">
                              <div class="qrbox">
                                <img :src="google_auth_img_url" />
                              </div>
                              <div>
                                <span
                                  v-if="!showGoogle_auth_url"
                                  style="
                                    color: var(--theme-primary-color);
                                    cursor: pointer;
                                  "
                                  @click="showGoogle_auth_url = true"
                                  >{{ $t('google auth tip4') }}</span
                                >

                                <div
                                  class="qrtextbox"
                                  v-if="showGoogle_auth_url"
                                >
                                  <span>{{ google_auth_secret }}</span>
                                  <div class="copybox">
                                    <span
                                      class="copyIcon"
                                      style="margin-left: 0.2rem"
                                      v-copy="google_auth_secret"
                                    >
                                      <svg-icon
                                        icon-class="comm_icon_copy"
                                        style="
                                          width: 1em;
                                          height: 1em;
                                          fill: currentColor;
                                        "
                                      ></svg-icon>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                          <section
                            class="tipsItem"
                            style="
                              border-bottom: none;
                              font-weight: 400;
                              padding-bottom: 0px;
                            "
                          >
                            <span
                              >{{ $t('step') }} 3: {{ $t('google auth tip5') }}</span
                            >
                            <div>
                              <el-form-item
                                style="margin-bottom: 0.5rem"
                                prop="google_code"
                              >
                                <el-input
                                  class="myinput2"
                                  maxlength="6"
                                  type="tel"
                                  @input="
                                    (v) => {
                                      editfrom.google_code = v.replace(
                                        /\D/g,
                                        ''
                                      );
                                    }
                                  "
                                  v-model="editfrom.google_code"
                                  :placeholder="
                                    $t(
                                      'google auth tip6'
                                    )
                                  "
                                >
                                  <template slot="prefix">
                                    <i class="anticon">
                                      <i
                                        aria-hidden="true"
                                        focusable="false"
                                        class="googleico"
                                        style=""
                                      ></i>
                                    </i>
                                  </template>
                                  <template slot="suffix">
                                    <span
                                      style="
                                        color: var(--theme-primary-color);
                                        cursor: pointer;
                                        font-size: 0.22rem;
                                      "
                                      @click="editfrom.google_code = ''"
                                      >{{ $t('paste') }}</span
                                    >
                                  </template>
                                </el-input>
                                <template slot="error" slot-scope="scope">
                                  <div class="err-explain">
                                    {{ scope.error }}
                                  </div>
                                </template>
                              </el-form-item>
                            </div>
                          </section>
                        </div>
                        <el-form-item v-else style="margin-bottom: 0.5rem">
                          <div class="destroy-Google-Auth">
                            <div class="google-item">
                              <i class="anticon" style="margin-right: 0.13rem">
                                <i
                                  aria-hidden="true"
                                  focusable="false"
                                  class="googleico"
                                  style=""
                                ></i>
                              </i>
                              <span style="width: unset"
                                >{{ $t('current status') }}:{{ $t('activated') }}</span
                              >
                            </div>
                          </div>
                        </el-form-item>
                      </div>
                      <div v-if="current === 4 && !isEditVerification">
                        <el-form-item
                          style="margin-bottom: 0.5rem"
                          prop="pass1"
                        >
                          <el-input
                            class="myinput2"
                            maxlength="16"
                            :type="showPsw1 ? 'text' : 'password'"
                            v-model="editfrom.pass1"
                            :placeholder="$t('please enter new password')"
                          >
                            <template slot="prefix">
                              <i
                                style="
                                  display: inline-flex;
                                  justify-content: center;
                                  align-items: center;
                                  color: var(--theme-text-color-lighten);
                                  height: 0.25rem;
                                  width: 0.25rem;
                                  font-size: 0.18rem;
                                "
                              >
                                <svg-icon
                                  icon-class="input_icon_mm"
                                  style="
                                    width: 0.25rem;
                                    height: 0.25rem;
                                    fill: currentColor;
                                  "
                                ></svg-icon>
                              </i>
                            </template>
                            <template slot="suffix">
                              <i
                                v-show="editfrom.pass1.length > 0"
                                style="
                                  display: inline-flex;
                                  justify-content: center;
                                  align-items: center;
                                  color: var(--theme-text-color-lighten);

                                  font-size: 0.36rem;
                                  cursor: pointer;
                                "
                                @click="editfrom.pass1 = ''"
                              >
                                <svg-icon
                                  icon-class="input_icon_clear"
                                  style="
                                    width: 1em;
                                    height: 1em;
                                    fill: currentColor;
                                  "
                                ></svg-icon>
                              </i>
                              <i
                                style="
                                  display: inline-flex;
                                  justify-content: center;
                                  align-items: center;
                                  color: var(--theme-text-color-lighten);

                                  font-size: 0.36rem;
                                  cursor: pointer;
                                  padding-right: 0.01rem;
                                  margin-left: 0.2rem;
                                  padding-right: 0.01rem;
                                "
                                @click="showPsw1 = !showPsw1"
                              >
                                <svg-icon
                                  :icon-class="
                                    !showPsw1
                                      ? 'comm_icon_hide'
                                      : 'comm_icon_show'
                                  "
                                  style="
                                    width: 1em;
                                    height: 1em;
                                    fill: currentColor;
                                  "
                                  :style="
                                    !showPsw1
                                      ? 'color: var(--theme-color-line);'
                                      : 'color: var(--theme-primary-color);'
                                  "
                                ></svg-icon>
                              </i>
                            </template>
                          </el-input>
                          <template slot="error" slot-scope="scope">
                            <div class="err-explain">{{ scope.error }}</div>
                          </template>
                        </el-form-item>
                        <passwordSecurityLevel
                          :passValue="editfrom.pass1"
                        ></passwordSecurityLevel>

                        <el-form-item
                          style="margin-bottom: 0.5rem"
                          prop="pass2"
                        >
                          <el-input
                            class="myinput2"
                            maxlength="16"
                            :type="showPsw2 ? 'text' : 'password'"
                            v-model="editfrom.pass2"
                            :placeholder="$t('confirm new password')"
                          >
                            <template slot="prefix">
                              <i
                                style="
                                  display: inline-flex;
                                  justify-content: center;
                                  align-items: center;
                                  color: var(--theme-text-color-lighten);
                                  height: 0.25rem;
                                  width: 0.25rem;
                                  font-size: 0.18rem;
                                "
                              >
                                <svg-icon
                                  icon-class="input_icon_mm"
                                  style="
                                    width: 0.25rem;
                                    height: 0.25rem;
                                    fill: currentColor;
                                  "
                                ></svg-icon>
                              </i>
                            </template>
                            <template slot="suffix">
                              <i
                                v-show="editfrom.pass2.length > 0"
                                style="
                                  display: inline-flex;
                                  justify-content: center;
                                  align-items: center;
                                  color: var(--theme-text-color-lighten);

                                  font-size: 0.36rem;
                                  cursor: pointer;
                                "
                                @click="editfrom.pass2 = ''"
                              >
                                <svg-icon
                                  icon-class="input_icon_clear"
                                  style="
                                    width: 1em;
                                    height: 1em;
                                    fill: currentColor;
                                  "
                                ></svg-icon>
                              </i>
                              <i
                                style="
                                  display: inline-flex;
                                  justify-content: center;
                                  align-items: center;
                                  color: var(--theme-text-color-lighten);

                                  font-size: 0.36rem;
                                  cursor: pointer;
                                  padding-right: 0.01rem;
                                  margin-left: 0.2rem;
                                  padding-right: 0.01rem;
                                "
                                @click="showPsw2 = !showPsw2"
                              >
                                <svg-icon
                                  :icon-class="
                                    !showPsw2
                                      ? 'comm_icon_hide'
                                      : 'comm_icon_show'
                                  "
                                  style="
                                    width: 1em;
                                    height: 1em;
                                    fill: currentColor;
                                  "
                                  :style="
                                    !showPsw2
                                      ? 'color: var(--theme-color-line);'
                                      : 'color: var(--theme-primary-color);'
                                  "
                                ></svg-icon>
                              </i>
                            </template>
                          </el-input>
                          <template slot="error" slot-scope="scope">
                            <div class="err-explain">{{ scope.error }}</div>
                          </template>
                        </el-form-item>
                      </div>
                      <div v-if="current === 5 && !isEditVerification">
                        <el-form-item
                          style="margin-bottom: 0.5rem"
                          prop="withdraw_password1"
                        >
                          <myNumberPassInput
                            :passvalue.sync="editfrom.withdraw_password1"
                            @leave="leaveInputPass"
                            @change="inputChange"
                            :title="$t('new withdrawal password')"
                          ></myNumberPassInput>
                          <div class="err-explain" v-if="showerr1">
                            {{ errtext1 }}
                          </div>
                        </el-form-item>
                        <el-form-item
                          style="margin-bottom: 0.5rem"
                          prop="withdraw_password2"
                        >
                          <myNumberPassInput
                            :passvalue.sync="editfrom.withdraw_password2"
                            @leave="leaveInputPass2"
                            @change="inputChange2"
                            :title="$('confirm new withdrawal password')"
                          ></myNumberPassInput>
                          <div class="err-explain" v-if="showerr2">
                            {{ errtext2 }}
                          </div>
                        </el-form-item>
                        <p class="withdraw-passtips">
                          {{ $t('change withdrawal tips') }}
                        </p>
                      </div>

                      <div v-if="current === 6 && !isEditVerification">
                        <el-form-item style="margin-bottom: 0.5rem">
                          <myIcoSelect
                            :items="security_options"
                            :select-item.sync="editfrom.questionID"
                            class="myinput2"
                          >
                            <template slot="prefix">
                              <i
                                class="anticon"
                                style="
                                  display: inline-flex;
                                  justify-content: center;
                                  align-items: center;
                                  color: var(--theme-text-color-lighten);
                                  height: 0.34rem !important;
                                  width: 0.3rem !important;

                                  font-size: 0.3rem;
                                "
                              >
                                <svg-icon
                                  icon-class="input_icon_mb"
                                  style="
                                    width: 1em;
                                    height: 1em;
                                    color: var(--theme-text-color-lighten);
                                    fill: currentColor;
                                  "
                                ></svg-icon>
                              </i>
                            </template>
                          </myIcoSelect>
                        </el-form-item>

                        <el-form-item
                          style="margin-bottom: 0.5rem"
                          prop="question_answer"
                        >
                          <el-input
                            class="myinput2"
                            v-model="editfrom.question_answer"
                            :placeholder="$t('enter the Answer')"
                          >
                            <template slot="prefix">
                              <i
                                class="anticon"
                                style="
                                  display: inline-flex;
                                  justify-content: center;
                                  align-items: center;
                                  color: var(--theme-text-color-lighten);
                                  height: 0.34rem !important;
                                  width: 0.3rem !important;

                                  font-size: 0.3rem;
                                "
                              >
                                <svg-icon
                                  icon-class="input_icon_yz"
                                  style="
                                    width: 1em;
                                    height: 1em;
                                    color: var(--theme-text-color-lighten);
                                    fill: currentColor;
                                  "
                                ></svg-icon>
                              </i>
                            </template>
                          </el-input>
                          <template slot="error" slot-scope="scope">
                            <div class="err-explain">{{ scope.error }}</div>
                          </template>
                        </el-form-item>

                        <el-form-item
                          style="margin-bottom: 0.5rem"
                          prop="question_answer_again"
                        >
                          <el-input
                            class="myinput2"
                            v-model="editfrom.question_answer_again"
                            :placeholder="
                              $t(
                                're-enter the answer to the security question'
                              )
                            "
                          >
                            <template slot="prefix">
                              <i
                                class="anticon"
                                style="
                                  display: inline-flex;
                                  justify-content: center;
                                  align-items: center;
                                  color: var(--theme-text-color-lighten);
                                  height: 0.34rem !important;
                                  width: 0.3rem !important;

                                  font-size: 0.3rem;
                                "
                              >
                                <svg-icon
                                  icon-class="input_icon_yz"
                                  style="
                                    width: 1em;
                                    height: 1em;
                                    color: var(--theme-text-color-lighten);
                                    fill: currentColor;
                                  "
                                ></svg-icon>
                              </i>
                            </template>
                          </el-input>
                          <template slot="error" slot-scope="scope">
                            <div class="err-explain">{{ scope.error }}</div>
                          </template>
                        </el-form-item>
                      </div>

                      <div v-if="current <= 6 && isEditVerification">
                        <el-form-item
                          style="margin-bottom: 0.5rem"
                          prop="password_login"
                          v-if="verifyID === 0"
                        >
                          <el-input
                            class="myinput2"
                            maxlength="16"
                            :type="showPsw3 ? 'text' : 'password'"
                            v-model="editfrom.password_login"
                            :placeholder="$t('enter password')"
                          >
                            <template slot="prefix">
                              <i
                                style="
                                  display: inline-flex;
                                  justify-content: center;
                                  align-items: center;
                                  color: var(--theme-text-color-lighten);
                                  height: 0.25rem;
                                  width: 0.25rem;
                                  font-size: 0.18rem;
                                "
                              >
                                <svg-icon
                                  icon-class="input_icon_mm"
                                  style="
                                    width: 0.25rem;
                                    height: 0.25rem;
                                    fill: currentColor;
                                  "
                                ></svg-icon>
                              </i>
                            </template>
                            <template slot="suffix">
                              <i
                                v-show="editfrom.password_login.length > 0"
                                style="
                                  display: inline-flex;
                                  justify-content: center;
                                  align-items: center;
                                  color: var(--theme-text-color-lighten);

                                  font-size: 0.36rem;
                                  cursor: pointer;
                                "
                                @click="editfrom.password_login = ''"
                              >
                                <svg-icon
                                  icon-class="input_icon_clear"
                                  style="
                                    width: 1em;
                                    height: 1em;
                                    fill: currentColor;
                                  "
                                ></svg-icon>
                              </i>
                              <i
                                style="
                                  display: inline-flex;
                                  justify-content: center;
                                  align-items: center;
                                  color: var(--theme-text-color-lighten);

                                  font-size: 0.36rem;
                                  cursor: pointer;
                                  padding-right: 0.01rem;
                                  margin-left: 0.2rem;
                                  padding-right: 0.01rem;
                                "
                                @click="showPsw3 = !showPsw3"
                              >
                                <svg-icon
                                  :icon-class="
                                    !showPsw3
                                      ? 'comm_icon_hide'
                                      : 'comm_icon_show'
                                  "
                                  style="
                                    width: 1em;
                                    height: 1em;
                                    fill: currentColor;
                                  "
                                  :style="
                                    !showPsw3
                                      ? 'color: var(--theme-color-line);'
                                      : 'color: var(--theme-primary-color);'
                                  "
                                ></svg-icon>
                              </i>
                            </template>
                          </el-input>
                          <template slot="error" slot-scope="scope">
                            <div class="err-explain">{{ scope.error }}</div>
                          </template>
                        </el-form-item>

                        <div v-if="verifyID === 1">
                          <el-form-item style="margin-bottom: 0.5rem">
                            <myIcoSelect
                              :items="security_options"
                              :select-item.sync="editfrom.validQuestionID"
                              class="myinput2"
                            >
                              <template slot="prefix">
                                <i
                                  class="anticon"
                                  style="
                                    display: inline-flex;
                                    justify-content: center;
                                    align-items: center;
                                    color: var(--theme-text-color-lighten);
                                    height: 0.34rem !important;
                                    width: 0.3rem !important;

                                    font-size: 0.3rem;
                                  "
                                >
                                  <svg-icon
                                    icon-class="input_icon_mb"
                                    style="
                                      width: 1em;
                                      height: 1em;
                                      color: var(--theme-text-color-lighten);
                                      fill: currentColor;
                                    "
                                  ></svg-icon>
                                </i>
                              </template>
                            </myIcoSelect>
                          </el-form-item>

                          <el-form-item
                            style="margin-bottom: 0.5rem"
                            prop="validQuestion_answer"
                          >
                            <el-input
                              class="myinput2"
                              v-model="editfrom.validQuestion_answer"
                              :placeholder="$t('enter the Answer')"
                            >
                              <template slot="prefix">
                                <i
                                  class="anticon"
                                  style="
                                    display: inline-flex;
                                    justify-content: center;
                                    align-items: center;
                                    color: var(--theme-text-color-lighten);
                                    height: 0.34rem !important;
                                    width: 0.3rem !important;

                                    font-size: 0.3rem;
                                  "
                                >
                                  <svg-icon
                                    icon-class="input_icon_yz"
                                    style="
                                      width: 1em;
                                      height: 1em;
                                      color: var(--theme-text-color-lighten);
                                      fill: currentColor;
                                    "
                                  ></svg-icon>
                                </i>
                              </template>
                            </el-input>
                            <template slot="error" slot-scope="scope">
                              <div class="err-explain">{{ scope.error }}</div>
                            </template>
                          </el-form-item>
                        </div>

                        <el-form-item
                          v-if="verifyID === 2"
                          style="margin-bottom: 0.5rem"
                          prop="validGoogle_code"
                        >
                          <el-input
                            class="myinput2"
                            maxlength="6"
                            type="tel"
                            v-model="editfrom.validGoogle_code"
                            :placeholder="
                              $t('google auth tip6')
                            "
                          >
                            <template slot="prefix">
                              <i class="anticon">
                                <i
                                  aria-hidden="true"
                                  focusable="false"
                                  class="googleico"
                                  style=""
                                ></i>
                              </i>
                            </template>
                            <template slot="suffix">
                              <span
                                style="
                                  color: var(--theme-primary-color);
                                  cursor: pointer;
                                  font-size: 0.22rem;
                                "
                                >{{ $t('paste') }}</span
                              >
                            </template>
                          </el-input>
                          <template slot="error" slot-scope="scope">
                            <div class="err-explain">{{ scope.error }}</div>
                          </template>
                        </el-form-item>

                        <el-form-item
                          style="margin-bottom: 0.5rem"
                          prop="validemail"
                          v-if="verifyID === 3 && isEditVerification"
                        >
                          <section class="emailCheckinputmain">
                            <div class="checkHead">
                              <i
                                style="
                                  display: inline-flex;
                                  justify-content: center;
                                  align-items: center;
                                "
                              >
                                <svg-icon
                                  icon-class="input_icon_yj2"
                                  style="
                                    width: 1em;
                                    height: 1em;
                                    fill: currentColor;
                                  "
                                ></svg-icon>
                              </i>
                              {{ getEmailAccount(0) }}
                            </div>

                            <div class="checkBody">
                              <input
                                type="text"
                                v-model="editfrom.validemail"
                              />
                            </div>
                            <div class="checkRight">
                              {{ getEmailAccount(1) }}
                            </div>
                          </section>

                          <template slot="error" slot-scope="scope">
                            <div class="err-explain">{{ scope.error }}</div>
                          </template>
                        </el-form-item>

                        <div class="validTypeChange">
                          <div class="itembox">
                            <span></span>
                            <div
                              class="menu-change"
                              @click="showSelectVerifType = true"
                            >
                              {{ $t('other methods') }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </el-form>
                  </div>
                </div>
                <div class="submitBox">
                  <div class="verification-btn__group">
                    <van-button
                      v-if="isEditVerification"
                      @click="submitUpdate"
                      :loading="submitLoading"
                      class="highlight-button"
                      style="
                        font-size: 0.24rem;
                        height: 0.7rem;
                        margin-right: 0px !important;
                        width: 7.1rem !important;
                        flex: none;
                        position: relative;
                      "
                    >
                      {{ $t('confirm') }}
                    </van-button>
                    <van-button
                      v-else
                      @click="Validate"
                      class="highlight-button"
                      style="
                        font-size: 0.24rem;
                        height: 0.7rem;
                        margin-right: 0px !important;
                        width: 7.1rem !important;
                        flex: none;
                        position: relative;
                      "
                    >
                      {{ $t('next') }}
                    </van-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-main>
    </el-container>
    <myDialog
      :open.sync="showSelectVerifType"
      :originalClose="false"
      width="6rem"
      Title="Outros Métodos"
      style="margin-top: 3rem"
    >
      <div style="text-align: center">
        <div class="validBox">
          <div class="validContext">
            <div @click="tabvalidType(0)">
              <i class="anticon">
                <i class="loginPassIco"></i>
              </i>
              <span>{{$t('login password')}}</span>
            </div>
            <div @click="tabvalidType(1)" v-if="security_question">
              <i class="anticon">
                <i class="questionIco"></i>
              </i>
              <span>{{ $t('security question') }}</span>
            </div>

            <div @click="tabvalidType(2)" v-if="google_authentication">
              <i class="anticon">
                <i class="googleico2"></i>
              </i>
              <span>{{$t('google verification')}}</span>
            </div>

            <div @click="tabvalidType(3)" v-if="email && email.length > 0">
              <i class="anticon">
                <i class="emailIco"></i>
              </i>
              <span>{{ $t('e-mail check') }}</span>
            </div>
          </div>
        </div>
      </div>
    </myDialog>
  </div>
</template>
<script>
import myScrollbarBar from "@/components/phone/common/myScrollbarBar.vue";
import {
  getSecurityInfo,
  getconfigFetch,
  setSecurityQuestion,
  setPhone,
  setEmail,
  modifyLoginPassword,
  getGoogleAuthUrl,
  closeGoogleAuth,
  changeWithdrawPassword,
  bindGoogleAuthCode,
} from "@/api/index";
import { truncateString } from "@/utils/utils";
import passwordSecurityLevel from "@/components/phone/common/passwordSecurityLevel.vue";
import myNumberPassInput from "@/components/phone/common/myNumberPassInput.vue";
import myIcoSelect from "@/components/phone/common/myIcoSelect.vue";
import myDialog from "@/components/phone/common/myDialog.vue";
import qrcode from "qrcode";
export default {
  components: {
    myScrollbarBar,
    passwordSecurityLevel,
    myNumberPassInput,
    myIcoSelect,
    myDialog,
  },
  watch: {
    $route(to, from) {
      //#region

      this.editfrom.phone = "";
      this.editfrom.email = "";
      this.editfrom.google_code = "";
      this.editfrom.pass1 = "";
      this.editfrom.pass2 = "";
      this.editfrom.withdraw_password1 = "";
      this.editfrom.withdraw_password2 = "";
      this.editfrom.question_answer = "";
      this.editfrom.question_answer_again = "";
      this.editfrom.password_login = "";
      this.editfrom.questionID = 1;
      this.editfrom.validQuestionID = 1;
      this.editfrom.validQuestion_answer = "";
      this.editfrom.validGoogle_code = "";
      this.editfrom.validemail = "";
      this.showPsw2 = false;
      this.showPsw3 = false;
      this.showPsw1 = false;
      this.submitLoading = false;
      this.showerr2 = false;
      this.showerr1 = false;
      this.errtext1 = "";
      this.errtext2 = "";
      this.isEditVerification = false;
      this.verifyID = 0;
      this.google_auth_url = "";
      this.google_auth_img_url = "";
      this.showGoogle_auth_url = false;
      this.google_auth_secret = "";
      //#endregion
      if (to.query.current) {
        if (this.current != Number(to.query.current)) {
          this.current = Number(to.query.current);
        }
      } else {
        this.current = 99;
      }
      // console.log(this.current);
      if (this.current === 3 && !this.google_authentication) {
        // console.log("aaaaaaaa");
        this.getGoogleAuthUrl();
      }
    },
  },
  data() {
    return {
      google_auth_secret: "",
      showGoogle_auth_url: false,
      google_auth_url: "",
      google_auth_img_url: "",
      notify: null,
      showSelectVerifType: false,
      verifyID: 0,
      isEditVerification: false,
      showerr1: false,
      showerr2: false,
      errtext1: "",
      errtext2: "",
      EmailSuffix: {
        zh: [
          "@gmail.com",
          "@qq.com",
          "@163.com",
          "@126.com",
          "@sina.com",
          "@foxmail.com",
          "@outlook.com",
          "@live.com",
          "@sohu.com",
          "@mail.com",
          "@hotmail.com",
          "@tom.com",
          "@yahoo.com",
          "@msn.com",
        ],
        en: [
          "@gmail.com",
          "@outlook.com",
          "@live.com",
          "@hotmail.com",
          "@yahoo.com",
          "@qq.com",
          "@foxmail.com",
          "@163.com",
          "@126.com",
          "@sina.com",
          "@sohu.com",
          "@mail.com",
          "@tom.com",
          "@msn.com",
        ],
      },
      current: 99,
      showPsw1: false,
      showPsw2: false,
      showPsw3: false,
      // userSecurityInfo: {},
      submitLoading: false,
      account: "",
      phone: "",
      email: "",
      google_authentication: false,
      login_password: false,
      withdraw_password: false,
      security_question: false,
      security_options: [],
      editfrom: {
        phone: "",
        tel: "+55",
        email: "",
        google_code: "",
        pass1: "",
        pass2: "",
        withdraw_password1: "",
        withdraw_password2: "",
        questionID: 1,
        question_answer: "",
        question_answer_again: "",
        password_login: "",
        validQuestionID: 1,
        validQuestion_answer: "",
        validGoogle_code: "",
        validemail: "",
      },
      rules: {
        phone: [
          {
            validator: (rule, value, callback) => {
              const numberRegex = /^\d+$/;
              if (value.length < 6) {
                callback(
                  new Error(
                    this.$t("phone empty")
                  )
                );
              }
              if (!numberRegex.test(value)) {
                callback(
                  new Error(this.$t("please enter a valid number"))
                );
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: this.$t("email err1"),
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              var emailRegex =
                /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
              if (!emailRegex.test(value)) {
                callback(
                  new Error(
                    this.$t("email err2")
                  )
                );
              } else {
                callback();
              }
            },
            trigger: "change",
          },
          {
            validator: (rule, value, callback) => {
              var emailRegex =
                /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
              if (!emailRegex.test(value)) {
                callback(
                  new Error(
                    this.$t("email err2")
                  )
                );
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        google_code: [
          {
            required: true,
            message: this.$t(
              "googlecample empty"
            ),
            trigger: "blur",
          },

          {
            validator: (rule, value, callback) => {
              const numberRegex = /^\d+$/;
              if (!numberRegex.test(value)) {
                callback(
                  new Error(this.$t("please enter a valid number"))
                );
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        pass1: [
          {
            validator: (rule, value, callback) => {
              const hasNumber = /\d/;
              const hasLetter = /[a-zA-Z]/;
              const hasSpecialChar = /[^a-zA-Z0-9]/;
              // 计数满足条件的字符类型
              let typeCount = 0;
              if (hasNumber.test(value)) typeCount++;
              if (hasLetter.test(value)) typeCount++;
              if (hasSpecialChar.test(value)) typeCount++;
              if (typeCount < 2 || value.length < 6) {
                callback(
                  new Error(
                    this.$t(
                      "password restrictions1"
                    )
                  )
                );
              } else {
                callback();
              }
            },
          },
        ],
        pass2: [
          {
            validator: (rule, value, callback) => {
              const hasNumber = /\d/;
              const hasLetter = /[a-zA-Z]/;
              const hasSpecialChar = /[^a-zA-Z0-9]/;
              // 计数满足条件的字符类型
              let typeCount = 0;
              if (hasNumber.test(value)) typeCount++;
              if (hasLetter.test(value)) typeCount++;
              if (hasSpecialChar.test(value)) typeCount++;
              if (typeCount < 2 || value.length < 6) {
                callback(
                  new Error(
                    this.$t(
                      "password restrictions1"
                    )
                  )
                );
              } else {
                if (value !== this.editfrom.pass1) {
                  callback(
                    new Error(this.$t("password err"))
                  );
                } else {
                  callback();
                }
              }
            },
          },
        ],
        question_answer: [
          {
            required: true,
            message: this.$t("answers restrictions1"),
            trigger: "blur",
          },
        ],
        question_answer_again: [
          {
            validator: (rule, value, callback) => {
              if (value.length === 0) {
                callback(
                  new Error(
                    this.$t("answers restrictions1")
                  )
                );
              } else if (value !== this.editfrom.question_answer) {
                callback(
                  new Error(
                    this.$t(
                      "answers restrictions2"
                    )
                  )
                );
              } else {
                callback();
              }
            },
          },
        ],
        password_login: [
          {
            validator: (rule, value, callback) => {
              const hasNumber = /\d/;
              const hasLetter = /[a-zA-Z]/;
              const hasSpecialChar = /[^a-zA-Z0-9]/;
              // 计数满足条件的字符类型
              let typeCount = 0;
              if (hasNumber.test(value)) typeCount++;
              if (hasLetter.test(value)) typeCount++;
              if (hasSpecialChar.test(value)) typeCount++;
              if (typeCount < 2 || value.length < 6) {
                callback(
                  new Error(
                    this.$t(
                      "password restrictions1"
                    )
                  )
                );
              } else {
                callback();
              }
            },
          },
        ],
        validQuestion_answer: [
          {
            required: true,
            message: this.$t("answers restrictions1"),
            trigger: "blur",
          },
        ],
        validGoogle_code: [
          {
            required: true,
            message: this.$t(
              "googlecample empty"
            ),
            trigger: "blur",
          },

          {
            validator: (rule, value, callback) => {
              const numberRegex = /^\d+$/;
              if (!numberRegex.test(value)) {
                callback(
                  new Error(this.$t("please enter a valid number"))
                );
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
      },
    };
  },
  activated() {
    // this.user = this.$store.getters.user;
    // this.account = this.user.account_number;
    if (this.$route.query.current) {
      if (this.current != Number(this.$route.query.current)) {
        this.current = Number(this.$route.query.current);
      }
    } else {
      this.current = 99;
    }
    // if (this.current === 3&&!this.google_authentication) {
    //   this.getGoogleAuthUrl();
    // }
  },
  mounted() {
    this.getSecurityInfo();
    this.getconfigFetch();
  },
  methods: {
    tabvalidType(i) {
      this.verifyID = i;
      this.showSelectVerifType = false;
      this.editfrom.validQuestionID = 1;
      this.editfrom.password_login = "";
      this.editfrom.validQuestion_answer = "";
      this.editfrom.validGoogle_code = "";
      this.editfrom.validemail = "";
    },
    Validate() {
      this.$refs.editfrom.validate((valid) => {
        if (valid) {
          this.isEditVerification = true;
        }
      });
    },
    onClickLeft() {
      history.go(-1);
    },
    getSecurityInfo() {
      getSecurityInfo().then(({ data }) => {
        if (data.code === 0) {
          this.account = data.data.account;
          this.phone = data.data.phone;
          this.email = data.data.email;
          this.google_authentication = data.data.google_authentication;
          this.login_password = data.data.login_password;
          this.withdraw_password = data.data.withdraw_password;
          this.security_question = data.data.security_question;
          // console.log(this.account)
        }
      });
    },
    getconfigFetch() {
      getconfigFetch({ key: "security" }).then(({ data }) => {
        if (data.code === 0) {
          const d = JSON.parse(data.data.data);
          if (d.questions) {
            let i = 1;
            d.questions.forEach((element) => {
              // console.log(element);
              this.security_options.push({ label: element, value: i });
              i++;
            });
          }
        }
      });
    },
    getTopTips() {
      if (!this.isEditVerification) {
        switch (this.current) {
          case 0:
            return this.$t('bind phone');
          case 1:
            return "CPF";
          case 2:
            return this.$t('bind email');
          case 3: {
            if (this.google_authentication) {
              return this.$t('disable Google Authenticator');
            } else {
              return this.$t('connect google authenticator');
            }
          }

          case 4:
            return this.$t('change password');
          case 5:
            return this.$t('change withdrawal password');
          case 6:
            return this.$t('security of the configuration question');
          default:
            return this.$t('security');
        }
      } else {
        switch (this.verifyID) {
          case 0:
            return this.$t('check password for login');
          case 1:
            return this.$t('security question');
          case 2:
            return this.$('google verification');
          case 3:
            return this.$t('e-mail check');
          default:
            break;
        }
      }
    },
    getTitle() {
      switch (this.current) {
        case 0:
          return this.$t('telephone number');
        case 1:
          return "CPF";
        case 2:
          return this.$t('email address');
        case 3:
          return this.$t('google authenticator');
        case 4:
          return this.$t('login password');
        case 5:
          return this.$t('withdrawal password');
        case 6:
          return this.$t('security question');
        default:
          return this.$t('security');
      }
    },
    truncateString(s) {
      // console.log(s);
      return truncateString(s, 8, "middle", "****");
    },
    querySearchEMail(queryString, cb) {
      if (queryString) {
        var results = this.EmailSuffix.zh.map((e) => {
          const t = queryString.split("@")?.[0];
          return {
            label: t + e,
            value: t + e,
          };
        });
        // console.log(results);
        cb(results);
      } else {
        cb([]);
      }
      //@gmail.com
      //@outlook.com
      //@live.com
      //@hotmail.com
      //@yahoo.com
      //@qq.com
      //@foxmail.com
      //@163.com
      //@126.com
      //@sina.com
      //@sohu.com
      //@mail.com
      //@tom.com
      //@msn.com
      // var restaurants = this.restaurants;
      // var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据
      // cb(results);
    },
    submitUpdate() {
      if (this.submitLoading || !this.isEditVerification) {
        return;
      }
      this.$refs.editfrom.validate((valid) => {
        if (valid) {
          this.submitLoading = true;
          // this.isEditVerification = true;
          switch (this.current) {
            case 0:
              this.updatePhone();
              //修改号码
              break;
            case 2:
              this.updateEmail();
              //修改邮箱
              break;
            case 3:
              this.bindGoogleAuth();
              break;
            case 4:
              this.setLoginPass();
              break;
            case 5:
              this.setWithdrawPassword();
              break;
            case 6:
              this.setSecurityAnswer();
              break;
            default:
              break;
          }
        }
      });
    },
    updatePhone() {
      //修改手机号码
      if (this.phone === "") {
        setPhone({
          verify_type: this.getVerifyType(),
          verify_value: this.getVerifyValue(),
          phone: this.editfrom.phone,
          country_code: "55",
        }).then(({ data }) => {
          this.resultHandle(data);
        });
      } else {
        self.submitLoading = false;
        self.$router.push({
          path: "/security",
          query: {},
        });
      }
    },
    getGoogleAuthUrl() {
      getGoogleAuthUrl().then(({ data }) => {
        if (data.code === 0) {
          this.google_auth_url = data.data.url;
          this.google_auth_secret = data.data.secret;
          qrcode.toDataURL(this.google_auth_url, (err, url) => {
            if (!err) {
              this.google_auth_img_url = url;
            }
          });
        }
      });
    },
    updateEmail() {
      setEmail({
        verify_type: this.getVerifyType(),
        verify_value: this.getVerifyValue(),
        email: this.editfrom.email,
      }).then(({ data }) => {
        this.resultHandle(data);
      });
    },
    bindGoogleAuth() {
      if (this.google_authentication) {
        closeGoogleAuth({
          verify_type: this.getVerifyType(),
          verify_value: this.getVerifyValue(),
        }).then(({ data }) => {
          this.resultHandle(data);
        });
      } else {
        bindGoogleAuthCode({
          verify_type: this.getVerifyType(),
          verify_value: this.getVerifyValue(),
          code: this.editfrom.google_code,
        }).then(({ data }) => {
          this.resultHandle(data);
        });
      }
    },
    setLoginPass() {
      modifyLoginPassword({
        verify_type: this.getVerifyType(),
        verify_value: this.getVerifyValue(),
        password_new: this.editfrom.pass1,
        password_new_again: this.editfrom.pass2,
      }).then(({ data }) => {
        this.resultHandle(data);
      });
    },
    setWithdrawPassword() {
      changeWithdrawPassword({
        verify_type: this.getVerifyType(),
        verify_value: this.getVerifyValue(),
        password: this.editfrom.withdraw_password1,
        password_again: this.editfrom.withdraw_password2,
      }).then(({ data }) => {
        this.resultHandle(data);
      });
    },
    setSecurityAnswer() {
      setSecurityQuestion({
        verify_type: this.getVerifyType(),
        verify_value: this.getVerifyValue(),
        question_index: this.editfrom.questionID,
        answer: this.editfrom.question_answer,
        answer_again: this.editfrom.question_answer_again,
      }).then(({ data }) => {
        this.resultHandle(data);
      });
    },
    resultHandle(result) {
      if (this.notify && this.notify.close) {
        this.notify.close();
      }
      if (result.code === 0) {
        this.notify = this.$notify({
          duration: 2000,
          message: this.$t('modified successfully'),
          type: "success",
          background: "#fff",
          offset: "3",
          showClose: false,
        });
        const self = this;
        setTimeout(() => {
          self.submitLoading = false;
          this.getSecurityInfo();
          self.$router.push({
            path: "/security",
            query: {},
          });
        }, 2000);
      } else {
        this.notify = this.$notify({
          duration: 2000,
          message: result.message,
          type: "error",
          background: "#fff",
          offset: "3",
          showClose: false,
        });
        const self = this;
        setTimeout(() => {
          self.submitLoading = false;
          if (result.code === 801||result.code === 805) {
            self.isEditVerification = false;
            self.editfrom.password_login = "";
            self.editfrom.questionID = 1;
            self.editfrom.validQuestionID = 1;
            self.editfrom.validQuestion_answer = "";
            self.editfrom.validGoogle_code = "";
            self.editfrom.validemail = "";
            self.verifyID = 0;
          }
        }, 2000);
      }
    },
    getVerifyType() {
      switch (this.verifyID) {
        case 0:
          return "login_password";
        case 1:
          return "question_answer";
        case 2:
          return "google_auth";
        case 3:
          return "email";
        default:
          return "login_password";
      }
    },
    getVerifyValue() {
      switch (this.verifyID) {
        case 0:
          return this.editfrom.password_login;
        case 1:
          return this.editfrom.validQuestionID;
        case 2:
          return this.editfrom.validGoogle_code;
        case 3:
          return (
            this.getEmailAccount(0) +
            this.editfrom.validemail +
            this.getEmailAccount(1)
          );
        default:
          return this.editfrom.password_login;
      }
    },
    leaveInputPass(v) {
      if (v.length === 0) {
        this.errtext1 = this.$t('password empty');
        this.showerr1 = true;
        return;
      }
      if (v.length < 6 && v.length > 0) {
        this.errtext1 =this.$t('num6');
        this.showerr1 = true;
        return;
      } else {
        this.showerr1 = false;
      }
    },
    inputChange(v) {
      if (v.length === 6) {
        this.showerr1 = false;
      }
    },
    leaveInputPass2(v) {
      if (v.length === 0) {
        this.errtext2 = this.$t('password empty');
        this.showerr2 = true;
        return;
      }
      if (v.length < 6 && v.length > 0) {
        this.errtext2 = this.$t('num6');
        this.showerr2 = true;
        return;
      } else {
        if (v !== this.editfrom.withdraw_password1) {
          this.errtext2 =this.$t('password err');
          this.showerr2 = true;
          return;
        }
        this.showerr2 = false;
      }
    },
    inputChange2(v) {
      if (v.length === 6) {
        if (v !== this.editfrom.withdraw_password1) {
          this.errtext2 = this.$t('password err');
          this.showerr2 = true;
          return;
        }
        this.showerr2 = false;
      }
    },

    getEmailAccount(i) {
      if (this.email) {
        let p = this.email.indexOf("*");
        let q = this.email.indexOf("@");
        if (i === 0) {
          if (p > 0) {
            return this.email.substring(0, p);
          }
        } else {
          if (q > 0) {
            return this.email.substring(q);
          }
        }
      }
      return "";
    },
  },
};
</script>
<style lang="less" scoped>
.context {
  padding: 0.2rem;
  /deep/.van-cell-group {
    background-color: var(--theme-main-bg-color);
    border-radius: 0.14rem;
    -webkit-box-shadow: 0 0.03rem 0.1rem 0 rgba(0, 0, 0, 0.06);
    box-shadow: 0 0.03rem 0.1rem 0 rgba(0, 0, 0, 0.06);
    margin-bottom: 0.2rem;
    // padding: 0 0.2rem;
    padding: 0 0.2rem;
    .van-cell::after {
      border-bottom: 0.01rem solid var(--theme-color-line);
    }
    .van-cell {
      background-color: transparent;
      // color: var(--theme-text-color-darken);
      // font-size: 0.22rem;
      // margin-left: 0.16rem;
      .van-cell__title {
        color: var(--theme-text-color-darken);
        font-size: 0.22rem;
        margin-left: 0.16rem;
      }
      svg {
        height: 0.25rem;
        width: 0.25rem;
      }
      .van-cell__value {
        color: var(--theme-text-color-lighten);
        font-size: 0.22rem;
      }
      .van-cell__right-icon {
        color: var(--theme-color-line);
      }
    }
  }
}
/deep/.el-main,
/deep/.el-head {
  padding: 0;
}

.van-nav-bar {
  border-bottom: 0.01rem solid var(--theme-color-line);
  background: var(--theme-main-bg-color);
  /deep/.van-nav-bar__content {
    height: 0.9rem;
    padding: 0px 0.1rem 0px 0.2rem;
  }

  /deep/.van-nav-bar__right {
    width: 0.9rem;
    display: flex;
    justify-content: flex-end;
    position: inherit;
    padding: 0;
    font-size: 0.22rem;
  }
  /deep/.van-nav-bar__left {
    position: inherit;
    padding: 0;
    width: 0.8rem;
    div {
      align-items: center;
      color: var(--theme-text-color);
      cursor: pointer;
      display: flex;
      font-size: 0.2rem;
      font-size: 0.24rem;
      height: 0.8rem;
      width: 0.8rem;
    }
  }
  /deep/.van-nav-bar__title {
    align-items: center;
    display: flex;
    flex: 2 1 0%;
    flex-direction: column;
    margin: 0;
    max-width: none;
    // max-width: max-content;
    .title {
      // padding: 0px 0.34rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--theme-text-color);
      span {
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        color: var(--theme-text-color-darken);
        display: -webkit-box;
        margin: 0px 0.12rem;
        overflow: hidden;
        text-align: center;
        text-overflow: ellipsis;
        vertical-align: middle;
      }
      .global-wrapper-title {
        font-size: 0.3rem;
        margin-top: -0.014rem;
        .ts {
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          display: -webkit-box;
          line-height: 1.2;
          overflow: initial;
          text-align: center;
          text-overflow: ellipsis;
          vertical-align: middle;
          word-break: break-word;
        }
      }
    }
  }

  /deep/.van-nav-bar__arrow {
    color: var(--theme-text-color);
  }

  /deep/.van-nav-bar__text {
    color: var(--theme-ant-primary-color-0);
    word-wrap: break-word;
    line-height: 1.1;
    text-align: center;
    white-space: pre-line;
    word-break: break-word;
    //  font-size: 24.576px;
    font-weight: 400;
    user-select: none;
    font: inherit;
    text-transform: none;
    box-sizing: border-box;
    pointer-events: none;
  }
  /deep/.van-ellipsis {
    white-space: inherit;
  }
}
/deep/.van-cell {
  -ms-flex-align: center;
  -ms-flex-pack: justify;
  align-items: center;
  padding: 0;
  display: -ms-flexbox;
  display: flex;
  height: 0.8rem;
  justify-content: space-between;
  padding-left: 0.04rem;
  position: relative;
}
/deep/.van-cell::after {
  left: 0;
  right: 0;
}
/deep/.van-cell__right-icon {
  font-size: 0.28rem;
}
.theme-box-shadow {
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0.1rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 6rem;
  overflow: auto;
  padding-bottom: 0.6rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
}
.structure {
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  max-width: 100%;
  padding-top: 0;
  width: 100%;
}
.steps-content-wrap {
  -ms-flex-pack: center;
  background-color: transparent;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  height: 100%;
  padding-bottom: 0.5rem;
  width: 100%;
  .basic-verification-content {
    padding: 0.4rem 0.2rem 0.2rem;
    width: 100%;
    .formBox {
      -ms-flex-pack: justify;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      .toptips {
        -ms-flex-align: center;
        -ms-flex-pack: justify;
        align-items: center;
        color: var(--theme-text-color-darken);

        display: -ms-flexbox;
        display: flex;
        font-weight: 700;
        justify-content: space-between;
        font-size: 0.24rem;
        margin-bottom: 0.4rem;
        margin-top: 0;
      }
      /deep/ .myinput {
        .el-input__inner {
          background-color: var(--theme-main-bg-color);
          // border-color: var(--theme-color-line) !important;
          color: var(--theme-text-color-darken);
          height: 0.7rem;
          padding: 0 0.6rem;
          padding-left: 2rem;
          font-size: 0.22rem;
          border-radius: 0.14rem;
        }
        .el-input__suffix {
          -ms-flex-align: center;
          align-items: center;

          display: -ms-flexbox;
          display: flex;
          line-height: 0;
          position: absolute;
          top: 50%;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
          z-index: 2;
        }
        .el-input__suffix {
          right: 0.2rem;
        }
      }
      /deep/.selectIpt {
        box-shadow: none;
        border: none;
        padding: 0;
        width: 1.68rem;
        color: var(--theme-text-color-lighten);
        margin: 0 auto;
        display: flex;
        align-items: center;
        border-right: 0.01rem solid var(--theme-color-line);

        img {
          width: 0.36rem;
          height: 0.27rem;
          -ms-flex-align: center;
          align-items: center;
          display: -ms-flexbox;
          display: flex;
          left: 0.2rem;
          line-height: normal;
          position: absolute;
          top: 50%;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
          z-index: 2;
        }
        .el-input__prefix {
          left: 0;
        }
        .el-input__inner {
          padding-left: 0.65rem;
          border: 0;
          background: transparent;
        }
        .el-input__inner:focus {
          box-shadow: none;
        }
        // margin-left: 7vw;
      }
      /deep/ .myinput2 {
        width: 100%;
        .el-input__inner {
          background-color: var(--theme-main-bg-color);
          // border-color: var(--theme-color-line) !important;
          color: var(--theme-text-color-darken);
          height: 0.7rem;
          padding: 0 0.6rem;
          // padding-left: 2rem;
          font-size: 0.22rem;
          border-radius: 0.14rem;
          width: 100%;
        }
        .el-input__prefix {
          position: absolute;
          top: 50%;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
          z-index: 2;

          -ms-flex-align: center;
          align-items: center;
          display: -ms-flexbox;
          display: flex;
          left: 0.2rem;
          line-height: 1.5;
        }
        .el-input__suffix {
          -ms-flex-align: center;
          align-items: center;

          display: -ms-flexbox;
          display: flex;
          line-height: 0;
          position: absolute;
          top: 50%;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
          z-index: 2;
        }
        .el-input__suffix {
          right: 0.2rem;
        }
      }
      .tipsItem {
        border-bottom: 0.01rem solid var(--theme-color-line);
        padding-bottom: 0.2rem;
        > span {
          color: var(--theme-text-color-darken);
          display: inline-block;
          font-size: 0.22rem;
          margin-bottom: 0.1rem;
        }
        > div {
          color: var(--theme-text-color-lighten);
          font-size: 0.2rem;
        }
        .betweenparties {
          -ms-flex-align: center;
          -ms-flex-pack: center;
          align-items: center;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-direction: column;
          flex-direction: column;
          justify-content: center;
          font-size: 0.22rem !important;
        }
        .qrbox {
          box-shadow: rgba(0, 0, 0, 0.1) 0px 0.01rem 0.08rem 0px;
          overflow: hidden;
          text-align: center;
          font-size: 0.18rem !important;
          height: 3.5rem;
          margin: 0.3rem;
          width: 3.5rem;
          img {
            height: 100%;
            -webkit-transform: scale(1.1);
            transform: scale(1.1);
            width: 100%;
          }
        }
        .qrtextbox {
          align-items: center;
          color: var(--theme-text-color);
          display: flex;
          font-size: 0.18rem;
          user-select: text;
          > span {
            margin-right: 0.1rem;
          }
          .copybox {
            align-items: center;
            display: flex;
            font-size: 0.3rem;
            height: 0.35rem;
            width: 0.3rem;
          }
        }
      }
    }
  }
}
.err-explain {
  margin-top: 0;
}
/deep/.el-input__inner::placeholder {
  color: var(--theme-text-color-placeholder) !important;
}
/deep/.el-input__inner:focus {
  border-color: var(--theme-ant-primary-color-13) !important;
  box-shadow: 0 0 0 0.02rem rgba(var(--theme-primay-color-hex), 0.2) !important;
}
.submitBox {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
  .verification-btn__group {
    background-color: var(--theme-main-bg-color);
    bottom: 0px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px -0.03rem 0.1rem 0px;
    display: flex;
    height: 1.3rem;
    justify-content: center;
    padding-top: 0.2rem;
    position: fixed;
    width: var(--theme-max-width);
  }
}
.googleico {
  display: inline-block;
  position: relative;
  width: 0.25rem;
  height: 0.25rem;
  background-image: url("@/assets/814195b6-3e29-450b-8c2b-bdb30e15091f.png");
  background-position: -1.04583rem -0.775rem;
  background-size: 3.025rem 2.95208rem;
}
.withdraw-passtips {
  letter-spacing: normal;
  line-height: 1.75;
  text-align: left;
  color: var(--theme-secondary-color-finance);
  font-size: 0.22rem;
  margin-bottom: 0.5rem;
}
.validTypeChange {
  margin-bottom: 0.2rem;
  .itembox {
    -ms-flex-align: center;
    -ms-flex-pack: justify;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    line-height: 1.2;
    margin-top: -0.1rem;
    .menu-change {
      color: var(--theme-primary-color);
      cursor: pointer;
      font-size: 0.22rem;
      height: auto;
      padding-right: 0;
    }
  }
}
.validBox {
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  .validContext {
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -ms-flex-pack: justify;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: space-between;
    //margin-top: 0.4rem;
    > div {
      -ms-flex-align: center;
      -ms-flex-pack: justify;
      align-items: center;
      border: 0.01rem solid var(--theme-color-line);
      border-radius: 0.1rem;
      cursor: pointer;
      display: -ms-flexbox;
      display: flex;
      height: 0.6rem;
      justify-content: space-between;
      padding: 0.12rem 0.1rem 0.12rem 0.15rem;
      width: 2.3rem;
      > span {
        color: var(--theme-text-color);
        -ms-flex: 1;
        flex: 1;
        font-size: 0.18rem;
        line-height: 1.2;
        text-align: center;
      }
      > span {
        font-size: 0.22rem;
        width: 1.84rem;
      }
    }

    > div {
      border-radius: 0.14rem;
      height: auto;
      padding: 0.12rem 0.05rem 0.12rem 0.2rem;
      width: 2.6rem;
    }

    > div:not(:first-child):not(:nth-child(2)) {
      margin-top: 0.2rem;
    }
  }
}

.loginPassIco {
  display: inline-block;
  position: relative;
  width: 0.46rem;
  height: 0.46rem;
  background-image: url("@/assets/814195b6-3e29-450b-8c2b-bdb30e15091f.png");
  background-position: -0.989rem -1.426rem;
  background-size: 5.566rem 5.43183rem;
}

.questionIco {
  display: inline-block;
  position: relative;
  width: 0.46rem;
  height: 0.46rem;
  background-image: url("@/assets/814195b6-3e29-450b-8c2b-bdb30e15091f.png");
  background-position: -2.392rem -1.426rem;
  background-size: 5.566rem 5.43183rem;
}
.googleico2 {
  display: inline-block;
  position: relative;
  width: 0.46rem;
  height: 0.46rem;
  background-image: url("@/assets/814195b6-3e29-450b-8c2b-bdb30e15091f.png");
  background-position: -1.92433rem -1.426rem;
  background-size: 5.566rem 5.43183rem;
}
.emailIco {
  display: inline-block;
  position: relative;
  width: 0.46rem;
  height: 0.46rem;
  background-image: url("@/assets/814195b6-3e29-450b-8c2b-bdb30e15091f.png");
  background-position: -0.467667rem -1.93967rem;
  background-size: 5.566rem 5.43183rem;
}
.topValidEmail {
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  font-size: 0.22rem;
  margin-top: 0.1rem;
}
.emailCheckinputmain {
  -ms-flex-align: center;
  -ms-flex-pack: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  width: 100%;
  .checkHead {
    -ms-flex-align: center;
    align-items: center;
    color: var(--theme-text-color-darken);
    display: -ms-flexbox;
    display: flex;
    font-weight: 700;
    font-size: 0.3rem;
    line-height: 0.44rem;
    margin-top: 0;
    i {
      color: var(--theme-text-color-lighten);
    }
  }
  .checkBody {
    border: 0.01rem solid var(--theme-secondary-color-error);
    border-radius: 0.1rem;
    height: 0.5rem;
    margin: 0 0.1rem;
    overflow: hidden;
    height: 0.7rem;
    input {
      background: 0 0 !important;
      border: none !important;
      border-bottom: 0.01rem solid var(--theme-color-line) !important;
      border-radius: 0;
      color: var(--theme-text-color-darken) !important;
      height: 100%;
      padding: 0;
      width: 2rem;
      font-size: 0.3rem;
      margin: 0 0.1rem;
    }
  }
  .checkRight {
    -ms-flex-align: center;
    align-items: center;
    color: var(--theme-text-color-darken);
    display: -ms-flexbox;
    display: flex;
    font-size: 0.3rem;
    font-weight: 700;
    line-height: 0.44rem;
    margin-top: 0;
  }
}
.destroy-Google-Auth {
  -ms-flex-pack: justify;
  border-bottom: 0.01rem solid var(--theme-color-line);
  justify-content: space-between;
  width: 100%;
  padding-bottom: 0.3rem;
  line-height: 0.25rem;
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  .google-item {
    min-width: 2.73rem;
    -ms-flex-align: center;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    width: 3.25rem;
    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      text-align: left;
      width: 1rem;
      color: var(--theme-text-color-darken);
      font-size: 0.22rem;
    }
  }
}
</style>
