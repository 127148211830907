<template>
  <section class="wrap">
    <div>
      <div style="max-width: var(--theme-max-width);">
        <div class="content">
          <div class="info">
            <h1 class="top">
              <div class="text">
                <div>
                  <span class="left">
                    {{ $t("pontos de sorte atuais") }}
                  </span>
                  <span class="right">
                    {{ luckValue }}
                  </span>
                </div>
                <span class="bottomText" style="display: none;">
                  {{ $t("you still need to bet") }}
                  <span>{{ luckValue }}</span>
                  {{ $t("to get") }}
                  <span>{{ luckValue }}</span>
                  {{ $t("luck points") }}
                </span>
              </div>
              <van-button class="btn" :plain="true">{{ $t("details") }}</van-button>
            </h1>

            <div class="trunTable">
              <div
                class="bg "
                :style="'background-image:url(' + target.table + ');transform: rotate('+luckindex+'deg);'"
                style="
                  width: 5rem;
                  height: 5rem;
                  background-position: 0px 0px;
                  background-size: 100% 100%;
                "
                :class="{turntableAnimation:anticon}"
              >
                <div
                  class="prizeItem"
                  v-for="(item, index) in target.numList"
                  :key="index"
                  :style="'transform: rotateZ(' + 36 * (index + n) + 'deg)'"
                >
                  <span class="span1">
                    <span class="span2"
                      ><span class="span3"> {{ item }}</span>
                    </span></span
                  >
                  <img
                    style="width: 0.667rem; height: 0.667rem"
                    src="@/assets/img_zphdjp_s1.png"
                  />
                </div>
              </div>
              <div
              @click="start"
                class="worktop"
                style="
                  width: 1.65rem;
                  height: 1.825rem;
                  background-position: 0px 0px;
                  background-size: 100% 100%;
                  cursor: pointer;
                "
                :style="'background-image:url(' + target.btn + ')'"
              >
                <span
                  style="
                    width: 70%;
                    margin-bottom: -2.26016px;
                    padding-bottom: 2.26016px;
                    font-size: 0.5rem;
                  "
                  class="btn"
                >
                  <span class="span1">
                    <span class="span2">
                      <span>{{ $t("rotate") }}</span>
                    </span>
                  </span>
                </span>
              </div>

              <div
                class="signboard"
                :style="'background-image:url(' + target.prizeArea + ')'"
                style="
                  width: 1.758rem;
                  height: 3.208rem;
                  background-position: 0px 0px;
                  background-size: 100% 100%;
                "
              >
                <img
                  style="width: 1.667rem; height: 3.208rem"
                  :src="require('@/assets/zphd_xzg_s2.png')"
                />
              </div>
            </div>

            <div class="menu">
              <div
                class="item"
                :class="target.id === 1 ? 'act' : ''"
                @click="selectTable(1)"
              >
                <p>
                  <div><div style="font-size: 0.20381rem;line-height: 1.2; width: 100%;">
                {{ $t("silver wheel") }}
            </div>
            </div>
                </p>
                <span>
                  <div>
                    <div
                      style="
                        font-size: 0.162869rem;
                        line-height: 1.2;
                        width: 100%;
                      "
                    >
                      {{ $t("pontos de sort",{n1:silver.cost,n2:silver.cost}) }} 
                    </div>
                  </div>
                </span>
              </div>

              <div
                class="item"
                :class="target.id === 2 ? 'act' : ''"
                @click="selectTable(2)"
              >
                <p>
                  <div><div style="font-size: 0.20381rem;line-height: 1.2; width: 100%;">
                {{ $t("golden wheel") }}
            </div>
            </div>
                </p>
                <span>
                  <div>
                    <div
                      style="
                        font-size: 0.162869rem;
                        line-height: 1.2;
                        width: 100%;
                      "
                    >
                      {{ $t("pontos de sort",{n1:gold.cost,n2:gold.cost}) }}
                    </div>
                  </div>
                </span>
              </div>

              <div
                class="item"
                :class="target.id ===3 ? 'act' : ''"
                @click="selectTable(3)"
              >
                <p>
                  <div><div style="font-size: 0.20381rem;line-height: 1.2; width: 100%;">
                {{ $t("diamond wheel") }}
            </div>
            </div>
                </p>
                <span>
                  <div>
                    <div
                      style="
                        font-size: 0.162869rem;
                        line-height: 1.2;
                        width: 100%;
                      "
                    >
                      {{ $t("pontos de sort",{n1:diamond.cost,n2:diamond.cost}) }} 
                    </div>
                  </div>
                </span>
              </div>
            </div>
          </div>

          <div class="bottom">
            <van-tabs
              v-model:active="active"
              :animated="true"
              title-inactive-color="var(--theme-text-color-darken)"
              title-active-color="var(--theme-primary-color)"
              :line-width="active === 0 ? '138px' : '98px'"
            
            >
              <van-tab title-class="title">
                <template slot="title">
                  {{ $t("award notification") }}
                </template>
                <template slot="default">
                 
                  <ul class="exchangeList"  >
                    <van-empty
                    :image="require('@/assets/img_none_sj.png')"
                    :description="$t('no records')"
                    v-if="!target.record||target.record.length===0"
                  />
                    <li v-if="target.record.length>0"  v-for="item in target.record" >
                      <div class="item">
                        <img
                          :src="target.icon"
                          style="width: 0.44rem; height: 0.44rem"
                        />
                        <div class="text">
                          <p>{{ formatDate(item.timestamp) }}</p>
                          <p>{{ item.account }}</p>
                        </div>
                      </div>
                      <span>{{ formatNumberToString(item.award) }}</span>
                    </li>
                  </ul>
                </template>
              </van-tab>
              <van-tab title-class="title">
                <template slot="title">
                  {{ $t("my records") }}
                </template>
                <template slot="default">
                
                  <ul class="exchangeList" >
                    <van-empty
                    :image="require('@/assets/img_none_sj.png')"
                     :description="$t('no records')"
                    v-if="!target.meRecord||target.meRecord.length===0"
                  />
                    <li v-if="target.meRecord.length>0" v-for="item in target.meRecord">
                      <div class="item">
                        <img
                          :src="target.icon"
                          style="width: 0.44rem; height: 0.44rem"
                        />
                        <div class="text">
                          <p>{{ formatDate(item.timestamp) }}</p>
                          <p>{{ item.account }}</p>
                        </div>
                      </div>
                      <span>{{ formatNumberToString(item.award) }}</span>
                    </li>
                  </ul>
                </template>
              </van-tab>
              <!-- <van-tab :title="$t('audit records')" title-class="title">
              <div class="auditList">
                <div class="noList">
                  <img src="../../../assets/img_Sem-Registros.png" />
                  <div>{{ $t("Not yet") }}</div>
                </div>
              </div>
            </van-tab> -->
            </van-tabs>
          </div>
        </div>
      </div>
      <div class="taskRule">
        <div class="text">
          <div>
            <div>
              <span style="font-weight: 700">I.Hora do evento(UTC-3):</span>
              <div><span>05/12/2023 00:00:00 - 30/11/2030 23:59:59</span></div>
            </div>
          </div>
          <div>
            <span style="font-weight: 700">II.Condições do Evento:</span>
            <div>
              <span
                >Apostas acumuladas (ou seja, apostas válidas ou em curso)</span
              >
            </div>
          </div>
          <div>
            <span style="font-weight: 700">III.Plataforma especificada:</span>
            <div><span>Todos os jogos</span></div>
          </div>
          <div>
            <span style="font-weight: 700">IV.Instruções Do Evento:</span>
            <div>
              1.Durante o período do evento, cada aposta válida de 1 ganha
              automaticamente 1 ponto da sorte. 1500-15000 pontos da sorte
              permitem um sorteio, com a recompensa máxima sendo 5555.<br />2.O
              valor da sorte obtido este mês não é usado no próximo mês e
              expirará para limpar zero;<br />3.As recompensas só podem ser
              resgatadas manualmente em APP/iOS、APP/Android、PC/Windows.<br />4.A
              atribuição de bônus desta atividade (excluindo o principal) requer
              1 X rollover (isto é, auditoria, participação ou aposta válida)
              para sacar o dinheiro. Participações não limitadas por jogo ou
              plataforma;<br />5.Este evento é limitado a operações normais
              realizadas pelo titular da conta. É proibido alugar, usar plug-ins
              externos, robôs, apostar em contas diferentes, brushing mútuo,
              arbitragem, interface, protocolo, exploração de vulnerabilidades,
              controle de grupo ou outros meios técnicos para participar. Caso
              contrário, as recompensas serão canceladas ou deduzidas, a conta
              será congelada ou até mesmo adicionada à lista negra;<br />6.Para
              evitar diferenças na compreensão do texto, a plataforma reserva-se
              o direito de interpretação final deste evento.
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="rewardAnimation" v-if="showReward">
      <img src="@/assets/apng_lingqu_3.png" v-if="showReward" />
    </div>
    <myDialog
      :open.sync="showTipsBox"
      effect="error"
      :Title="tipsTitle"
      width="6rem"
    >
      <div class="TipsInfo" style="text-align: center">
        {{ tipsText }}
      </div>
    </myDialog>
  </section>
</template>
<script>
import { formatNumberToString2} from "@/utils/utils";
import myDialog from "@/components/phone/common/myDialog.vue";
import { mapState } from "vuex";
import {
  getActivityConfig,
  fortuneWheelDraw,
  fortuneWheelInfo,
  fortuneWheelRecords

} from "@/api/index";
export default {
  components: { myDialog },
  data() {
    return {
      luckValue: 0,
      showTipsBox:false,
      tipsTitle:this.$t('important reminder'),
      tipsText:'',
      silver: {
        id: 1,
        icon: require("@/assets/zphd_icon_silver.png"),
        table: require("@/assets/zphd_bj_s1.png"),
        prizeArea: require("@/assets/zphd_zz_s1.png"),
        btn: require("@/assets/zphd_ljcj_s1.png"),
        numList: [1, 2, 3, 5, 8, 15, 35, 55, 155, 555],
        cost:1500,
        record:[],
        meRecord:[]
      },
      gold: {
        id: 2,
        icon: require("@/assets/zphd_icon_gold.png"),
        table: require("@/assets/zphd_bj_s2.png"),
        prizeArea: require("@/assets/zphd_zz_s2.png"),
        btn: require("@/assets/zphd_ljcj_s2.png"),
        numList: [2, 5, 8, 35, 55, 85, 155, 255, 555, 1555],
        cost:5000,
        record:[],
        meRecord:[]
      },
      diamond: {
        id: 3,
        icon: require("@/assets/zphd_icon_diamond.png"),
        table: require("@/assets/zphd_bj_s3.png"),
        prizeArea: require("@/assets/zphd_zz_s3.png"),
        btn: require("@/assets/zphd_ljcj_s3.png"),
        numList: [5, 8, 35, 55, 85, 155, 355, 555, 2555, 5555],
        cost:15000,
        record:[],
        meRecord:[]
      },
      target: {},
      active: 0,
      n: 0,
      changeTable: false,
      config:{},
      luckindex:0,
      showReward:false,
      award_info:{},
      anticon:false,
      suspend:false,
    };
  },
  ...mapState({
      user: (state) => state.user.user,
      token: (state) => state.user.token,
      ac: (state) => state.user.ac,
    }),
  mounted() {
    this.target = this.silver;
    this.getConfig();
    this.fortuneWheelInfo();
    this.fortuneWheelRecords(1,false);
    this.fortuneWheelRecords(2,false);
    this.fortuneWheelRecords(3,false);
    if(this.user){
      this.fortuneWheelRecords(1,true);
     this.fortuneWheelRecords(2,true);
     this.fortuneWheelRecords(3,true);
    }
  },
  methods: {
    fortuneWheelInfo(){
      fortuneWheelInfo().then(({data})=>{
          if(data.code===0){
          this.luckValue=data.data.month_point;
          }
      });
    },
    selectTable(type) {
      if(this.suspend){
        return;
      }
      this.anticon=false;
      this.n = 0;
      this.changeTable = true;
      if (type === 1) {
        this.target = this.silver;
      } else if (type === 2) {
        this.target = this.gold;
      } else if (type === 3) {
        this.target = this.diamond;
      }
      setTimeout(() => {
        this.changeTable = false;
        this.luckindex=0;
      }, 500);
      this.fortuneWheelRecords(this.target.id,false);
      this.fortuneWheelRecords(this.target.id,true);
    },
    formatDate(n) {
      const date = new Date(n * 1000);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      // const year = date.getFullYear();
      const h = date.getHours().toString().padStart(2, "0");
      const m = date.getMinutes().toString().padStart(2, "0");
      const s = date.getSeconds().toString().padStart(2, "0");
      return `${day}/${month} ${h}:${m}:${s}`;
    },
    virar(i){
      return (36*10)+(i*36*10)+360;
    },
    getConfig() {
      getActivityConfig({ Key: "fortune_wheel" }).then(({ data }) => {
        this.config = JSON.parse(data.data.config);
        // console.log(this.config);
        this.silver.numList=this.config.wheels[0].reward;
        this.silver.cost=this.config.wheels[0].cost;

        this.gold.numList=this.config.wheels[1].reward;
        this.gold.cost=this.config.wheels[1].cost;

        this.diamond.numList=this.config.wheels[2].reward;
        this.diamond.cost=this.config.wheels[2].cost;
        // this.gold=this.config.w
        // this.getInviteInfo();
        // console.log(result);
      });
    },
    fortuneWheelRecords(index,b){
      fortuneWheelRecords({wheel_index:index,self:b}).then(({data})=>{
          if(data.code===0){
            if(index===1){
              if(b){
                this.silver.meRecord=data.data.record_list;
              }else{
                this.silver.record=data.data.record_list;
              }
            }else if(index===2){
              if(b){
                this.gold.meRecord=data.data.record_list;
              }else{
                this.gold.record=data.data.record_list;
              }
            }
            else{
              if(b){
                this.diamond.meRecord=data.data.record_list;
              }else{
                this.diamond.record=data.data.record_list;
              }

            }
          }
      });
    },
    start(){
      if(this.suspend){
        return;
      }
      this.anticon=true;
      this.suspend=true;

      // this.luckindex=0;
      // const i= Math.floor(Math.random() * 10) + 1;
      // this.luckindex=this.virar(i);
// return;
      if(this.target.cost>this.luckValue){
        this.tipsText=this.$t( "luck points lack");
        this.showTipsBox=true;
        return;
      }
      fortuneWheelDraw({wheel_index:this.target.id}).then(({data})=>{
              if(data.code===0){
                this.award_info=data.data;
                const self=this;
                setTimeout(() => {
                     self.showReward=true;
                      setTimeout(()=>{
                        self.showReward=false;
                        self.suspend=false;
                       },1500);
                }, 5000);
           this.luckindex=  this.luckindex+this.virar(data.data.wheel_index);
           this.luckValue=this.luckValue-this.target.cost;
          }
/*

    "wheel_index": 0,
    "award_index": 0,
    "award_cash": 0,
    "balance": 0
*/

      });
//this.luckindex=1800;
    },
    formatNumberToString(n){
        return formatNumberToString2(n);
    },
  
  },
};
</script>
<style type="text/less" lang="less" scoped>
.wrap {
  background-color: var(--theme-bg-color);
  -ms-flex-pack: center;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  padding-top: 0.2rem;
  padding-top: 0;
}
.taskRule {
  padding: 0px 0.2rem;
  .text {
    font-size: 0.24rem;
    line-height: 0.4rem;
    background-color: var(--theme-main-bg-color);
    border-radius: 0.1rem;
    box-shadow: rgba(0, 0, 0, 0.06) 0px 0.03rem 0.09rem 0px;
    color: var(--theme-text-color);
    padding: 0.2rem;
    white-space: pre-wrap;
    word-break: break-word;
  }
}

.content {
  background-color: var(--theme-bg-color);
  -webkit-box-shadow: none;
  box-shadow: none;
  height: 100%;
  margin-top: 0.2rem;
  padding: 0 0.2rem;
  width: 100%;
  margin-bottom: 0.2rem;
  border-radius: 0.1rem;
  .info {
    height: 7.95rem;
    padding: 0.2rem;
    width: 6.47rem;
    background-color: var(--theme-main-bg-color);
    border-radius: 0.14rem;
    -webkit-box-shadow: 0 -0.03rem 0.1rem 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 -0.03rem 0.1rem 0 rgba(0, 0, 0, 0.1);
    height: auto;
    padding-top: 0.71rem;
    position: relative;
    width: 100%;
    padding-top: 1.13rem;
    .top {
      -ms-flex-align: center;
      -ms-flex-pack: justify;
      align-items: center;
      border-bottom: 0.01rem solid var(--theme-color-line);
      color: var(--theme-text-color-lighten);
      display: -ms-flexbox;
      display: flex;
      font-size: 0.24rem;
      height: 0.7rem;
      justify-content: space-between;
      margin-bottom: 0;
      position: absolute;
      top: 0;
      width: calc(100% - 0.4rem);
      height: 1.12rem;
      .text {
        color: var(--theme-text-color);
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        font-size: 0.24rem;
        font-weight: 400;
        .left {
          color: var(--theme-text-color-lighten);
          margin-right: 0.1rem;
        }
        .right {
          color: var(--theme-secondary-color-finance);
        }
        .bottomText {
          color: var(--theme-text-color-lighten);
          font-size: 0.22rem;
          margin-left: 0;
          margin-top: 0.07rem;
          span:first-child {
            color: var(--theme-text-color-darken);
            font-weight: 700;
            margin: 0 0.05rem;
          }
          span:first-child(2) {
            color: var(--theme-secondary-color-finance);
            margin: 0 0.05rem;
          }
        }
      }
      .btn {
        background-image: none;
        background-color: transparent;
        border-color: transparent;
        font-size: 0.24rem;
        box-shadow: none;
        outline: 0;
        margin: 0;
        padding: 0;
        border-radius: 0.14rem;
        color: var(--theme-primary-color);
        -webkit-appearance: button;
        font-weight: 400;
        display: inline-block;
        height: 32px;
        position: relative;
        text-align: center;
        touch-action: manipulation;
        user-select: none;
        white-space: nowrap;
        text-transform: none;
      }
    }
    .trunTable {
      display: -ms-flexbox;
      display: flex;
      margin-top: 0.34rem;
      overflow: hidden;
      padding-top: 0.1rem;
      position: relative;
      margin: 0.2rem auto;
      width: 5rem;
      .turntableAnimation{
        -webkit-transition: -webkit-transform 5s ease-in-out;
    transition: -webkit-transform 5s ease-in-out;
    transition: transform 5s ease-in-out;
    transition: transform 5s ease-in-out, -webkit-transform 5s ease-in-out;
      }
      .bg {

        .prizeItem {
          bottom: 0;
          color: var(--theme-text-color-activities-text);
          font-size: 0.28rem;
          height: 5.35rem;
          left: 0;
          margin: auto;
          padding-top: 0.16rem;
          position: absolute;
          right: 0;
          text-align: center;
          top: 0;
          width: 1.26rem;
          z-index: 10;
          height: 4.458rem;
          padding-top: 0.1rem;
          .span1 {
            width: 100%;
            margin-bottom: -1.10447px;
            padding-bottom: 1.10447px;
            font-size: 0.56rem;
            vertical-align: middle;
            display: inline-block;
            line-height: 0;
            overflow: hidden;
            color: var(--theme-text-color-activities-text);
            .span2 {
              font-size: 0.28rem;
              vertical-align: middle;
              display: inline-block;
              width: 100%;
              .span3 {
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                line-height: 1.2;
                overflow: hidden;
                overflow: initial;
                text-align: center;
                text-overflow: ellipsis;
                vertical-align: middle;
                word-break: break-word;
                -webkit-line-clamp: 2;
              }
            }
          }
          img {
            opacity: 1;
            border-style: none;
            vertical-align: middle;
            text-align: center;
          }
        }
      }
      .worktop {
        -ms-flex-align: center;
        -ms-flex-pack: center;
        align-items: center;
        bottom: 0;
        cursor: pointer;
        display: -ms-flexbox;
        display: flex;
        justify-content: center;
        left: 2.02rem;
        margin: auto;
        position: absolute;
        top: 0;
        z-index: 1;
        left: 1.683rem;
        .btn {
          color: var(--theme-text-color-activities-text);
          line-height: 1.2;
          margin-top: -0.1rem;
          position: relative;
          text-align: center;
          top: 0.16rem;
          width: 1.5rem;
          vertical-align: middle;
          display: inline-block;
          overflow: hidden;
          .span1 {
            font-size: 0.25rem !important;
            vertical-align: middle;
            display: inline-block;
            width: 100%;
            .span2 {
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              display: -webkit-box;
              line-height: 1.2;
              overflow: hidden;
              overflow: initial;
              text-align: center;
              text-overflow: ellipsis;
              vertical-align: middle;
              word-break: break-word;
              -webkit-line-clamp: 2;
            }
          }
        }
      }

      .signboard {
        margin: auto;
        position: absolute;
        top: -0.05rem;
        left: 1.6rem;
        opacity: 1;
        img {
          left: 0.06rem;
          opacity: 0;
          position: relative;
        }
      }
    }
    .menu {
      display: flex;
      justify-content: center;
      .item {
        margin-left: 0.2rem;
        border-radius: 0.14rem;
        flex-direction: column;
        height: 0.81rem;
        width: 2.1rem;
        align-items: center;
        border: thin solid var(--theme-color-line);
        flex-wrap: wrap;
        display: flex;
        justify-content: center;
        p {
          font-size: 0.3rem;
          margin: 0;
          text-align: center;
          color: var(--theme-text-color-activities-text);
          width: 100%;
        }
        span {
          font-size: 0.24rem;
          color: var(--theme-text-color-lighten);
        }
      }
      .act {
        background-color: var(--theme-event-luck-wheel-silver);
        span {
          color: var(--theme-text-color-activities-text);
        }
      }
    }
  }
  .bottom {
    background-color: var(--theme-main-bg-color);
    text-align: center;
    box-shadow: 0 -0.03rem 0.1rem 0 rgba(0, 0, 0, 0.1);
    border-radius: 0.14rem;
    height: 5.83rem;
    margin-top: 0.2rem;
    width: 100%;

    /deep/.van-tabs__wrap {
      margin-bottom: -1px;
      height: 0.8rem;
      border-color: var(--theme-color-line);
    }
    /deep/.van-tabs__nav {
      // border-bottom: 1px solid #e8e8e8;
      border-color: var(--theme-color-line);
      background-color: var(--theme-main-bg-color);
      // margin: 0;
      .title {
        font-size: 0.26rem;
        height: 0.8rem;
        line-height: 0.8rem;
        padding: 0;
      }
      .van-tabs__line {
        height: 0.03rem;
        background-color: var(--theme-ant-primary-color-0);
        // bottom: 1px;
      }
    }
    .exchangeList {
      height: 5.03rem;
     padding: 0 0.2rem;
      overflow-y: auto;
      // width: 80px;
      // overflow-x: hidden;
      li {
        -ms-flex-align: center;
        -ms-flex-pack: justify;
        align-items: center;
        border-bottom: 0.01rem solid var(--theme-color-line);
        display: -ms-flexbox;
        display: flex;
        justify-content: space-between;
        // padding: 0.15rem 0;
        padding: 0.2rem 0;
        .item {
          -ms-flex-align: center;
          align-items: center;
          color: var(--theme-text-color-lighten);
          display: -ms-flexbox;
          display: flex;
          font-size: 0.18rem;
          text-align: left;
          img {
            margin-right: 0.2rem;
            opacity: 1;
          }
          .text {
            align-items: center;
            display: flex;
            margin-right: 0.1rem;
            p:first-child {
              flex-shrink: 0;
              width: 1.6rem;
            }
            p {
              -ms-flex-negative: 0;
              font-size: 0.22rem;
            }
          }
        }
        span {
          font-size: 0.22rem;
          color: var(--theme-secondary-color-finance);
        }
      }
    }
  }
}

/deep/.van-tabs__wrap {
  border-bottom: 1px solid #fff;
}

::-webkit-scrollbar {
  display: none;
}
.TipsInfo {
  color: var(--theme-text-color);
  font-size: 0.22rem;
  font-weight: 400;
  padding: 0.2rem;
  .btnbox {
    display: -ms-flexbox;
    display: flex;
    margin-top: 0.38rem;
  }
}
</style>
