
export default class LocalStorageCache {
    static temp_cache=[];
    static  setCache(key, value) {
        // const item = {
        //     value: JSON.stringify(value)
        // };
        this.temp_cache[key]=value;
    }
    static getCache(key) {
        const value = this.temp_cache[key];
        // console.log(this.temp_cache);
        if (value) {
            // const item = JSON.parse(itemStr);
            return value;
        }
        return null;
    }
    static deleteCache(key){
        this.temp_cache[key]=[];
    }
    // 存储数据
    static  set(key, value, expirationTime = null) {
        const item = {
            value: JSON.stringify(value),
            expirationTime: expirationTime ? new Date().getTime() + expirationTime : null
        };
        localStorage.setItem(key, JSON.stringify(item));
    }

    // 获取数据
    static get(key) {
        const itemStr = localStorage.getItem(key);
        if (itemStr) {
            const item = JSON.parse(itemStr);
            if (item.expirationTime === null || item.expirationTime > new Date().getTime()) {
              if(item.value){
                return JSON.parse(item.value);
              }else return null;
                
            } else {
                this.delete(key);
            }
        }
        return null;
    }

    // 删除数据
    static delete(key) {
        localStorage.removeItem(key);
    }

    // 检查数据是否存在
    static has(key) {
        return !!this.get(key);
    }
    static deleteExpiredData(){
        for (let index = 0; index < localStorage.length; index++) {
          const name=localStorage.key(index);
          if(name.indexOf('temp')!==-1){
            this.get(name);
          }
           
        }
    }
    // 获取所有缓存数据
    static getAll() {
        return Object.keys(localStorage).reduce((acc, key) => {
            acc[key] = this.get(key);
            return acc;
        }, {});
    }

    // 清空缓存
    static clear() {
        localStorage.clear();
    }
}
