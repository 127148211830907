<template>
  <section class="wrap">
    <div class="topMenu">
      <van-row justify="space-between" type="flex">
        <van-col span="12">
          <div class="dateselect">
            <dateSelect
              width="3rem"
              :startDate.sync="startDate"
              :endDate.sync="endDate"
              @change="selectDate"
            ></dateSelect>
          </div>
        </van-col>
        <van-col span="12">
          <label class="lb">{{ $t('total yield') }}</label>
          <span class="currencyAmount">{{ performance_total }}</span>
        </van-col>
        <van-col span="12">
          <label class="lb">{{ $t('d. direct') }}</label>
          <span class="currencyAmount">{{ performance_subordinate }}</span>
        </van-col>
        <van-col span="12"
          ><label class="lb">{{ $t('d. others') }}</label>
          <span class="currencyAmount">{{ performance_other }}</span></van-col
        >
      </van-row>
    </div>
    <div class="context">
      <van-empty
        v-if="list.length < 1"
        :image="require('@/assets/azf5f-28vaj.png')"
        :description="$t('no records')"
      />
      <van-list v-else>
        <div class="sticky" v-for="(item, index) in list">
          <van-row justify="space-between" type="flex">
            <van-col span="12">
              <label>{{ $t('settlement date') }} </label>
              <span class="dateTime"> {{ item.day }} </span>
            </van-col>
            <van-col span="12">
              <label>{{ $t('total income') }} </label>
              <span class="currencyAmount">{{
                formatNumberToString(item.performance_total)
              }}</span>
            </van-col>
          </van-row>
          <van-row justify="space-between" type="flex">
            <van-col span="12">
              <label>{{ $t('d. direct') }} </label>
              <span :class="item.performance_subordinate > 0 ? 'active' : ''">
                <span class="currencyAmount">{{
                  formatNumberToString(item.performance_subordinate)
                }}</span></span
              >
            </van-col>
            <van-col span="12">
              <label>{{ $t('d. others') }} </label>
              <span :class="item.performance_other > 0 ? 'active' : ''">
                <span class="currencyAmount">{{
                  formatNumberToString(item.performance_other)
                }}</span>
              </span>
            </van-col>
          </van-row>
        </div>
      </van-list>
    </div>
  </section>
</template>
<script>
import dateSelect from "@/components/phone/common/dateSelect.vue";
import { getPeriodPerformance } from "@/api/index";
import { formatDate, formatNumberToString2 } from "@/utils/utils";

export default {
  name: "performance",
  components: { dateSelect },
  data() {
    return {
      active: 0,
      startDate: new Date(),
      endDate: new Date(),
      list: [],
      loading: false,
      performance_total: 0,
      performance_subordinate: 0,
      performance_other: 0,
    };
  },
  created() {},
  mounted() {

  },
  destroyed() {},
  methods: {
    remoteMethod(query) {},
    tabChange(index) {
      this.active = index;
    },
    onSearch() {},
    selectDate(start, end) {
      this.getList(start, end);
    },
    formatNumberToString(n) {
      return formatNumberToString2(n);
    },
    getList(start, end) {
      if (!this.loading) {
        const data = {
          start_day: formatDate(start),
          end_day: formatDate(end),
        };
        this.loading = true;
        getPeriodPerformance(data).then(({ data }) => {
          this.performance_total = formatNumberToString2(data.data.performance_total);
          this.performance_subordinate = formatNumberToString2(
            data.data.performance_subordinate
          );
          this.performance_other = formatNumberToString2(data.data.performance_other);
          this.list = data.data.list;
          this.loading = false;
        });
      }
    },
  },
};
</script>
<style type="text/less" lang="less" scoped>
.wrap {
  padding: 0.2rem 0.2rem 0;
  .topMenu {
    background-color: var(--theme-main-bg-color);
    border-radius: 0.1rem;
    padding: 0.2rem 0.2rem 0;
    -webkit-box-shadow: 0 0.03rem 0.09rem 0 var(--theme-bg-shadow);
    box-shadow: 0 0.03rem 0.09rem 0 var(--theme-bg-shadow);
    font-size: 0.2rem;
    font-variant: tabular-nums;
    .dateselect {
      padding: 0;
      width: 3rem;
      position: relative;
      z-index: 999;
    }
    /deep/.van-col {
      -ms-flex-align: center;
      align-items: center;
      color: var(--theme-text-color-darken);
      display: -ms-flexbox;
      display: flex;
      margin-bottom: 0.2rem;
    }
    .lb {
      color: var(--theme-text-color-lighten);
      margin-right: 0.1rem;
      -ms-touch-action: manipulation;
      touch-action: manipulation;
    }
  }
  .context {
    -ms-flex-pack: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    height: calc(100vh - 2.55rem);
    height: calc(var(--vh, 1vh) * 100 - 2.55rem);
    justify-content: center;
    overflow: hidden;
    position: relative;
    /deep/.van-empty {
      -ms-flex-positive: 1;
      flex-grow: 1;
      padding: 0;

      .van-empty__image {
        height: 2.1rem;
        text-align: center;
        width: 2.1rem;
      }
      .van-empty__description {
        color: var(--theme-text-color-lighten);
        font-size: 0.26rem;
        margin-top: 0.2rem;
      }
    }

    /deep/.van-list {

      .sticky {
        color: var(--theme-text-color-lighten);
        font-size: 0.2rem !important;
        position: relative;
        border-radius: 0.1rem;
        padding: 0.2rem;
        .active {
          border-bottom: 0.01rem solid var(--theme-primary-color);
          color: var(--theme-primary-color);
        }
      }
      .sticky:nth-child(odd) {
        background-color: var(--theme-bg-color);
      }
      .van-col {
        color: var(--theme-text-color-darken);
        margin-bottom: 0.05rem;
        font-size: 0.2rem !important;
        label {
          color: var(--theme-text-color-lighten);
          margin-right: 0.1rem;
          touch-action: manipulation;
        }
      }
    }
  }
}
.dateTime {
  font-size: 0.2rem !important;
  color: var(--theme-text-color-darken);
}
</style>
