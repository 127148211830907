<template>
  <div id="app">
    <transition :name="transitionName">
      <Keep-alive>
        <router-view />
      </Keep-alive>
    </transition>
    <el-dialog
      :visible.sync="dialogLogin"
      width="6.9rem"
      top="0"
      :show-close="false"
      custom-class="loginDialog"
      :append-to-body="true"
      login=""
    >
      <register :type="dialogMode" @closeDialog="closeDialog"></register>
    </el-dialog>

    <myDialog
      :open.sync="openTwoVerify"
      :originalClose="false"
      width="6.9rem"
      Title="Verificação de dois passos"
    >
      <twoVerify @closeDialog="closeDialog2"></twoVerify>
    </myDialog>

    <myDialog
      :open.sync="myopenMusic"
      :originalClose="false"
      width="6.9rem"
      :Title="$t('music')"
      my-custom-class="music-background"
      @closeDialog="closeDialog3"
      :destroyClose="false"
      :appendBody="true"
    >
      <musicControl ></musicControl>
    </myDialog>
  </div>
</template>

<script>
import register from "@/components/phone/register.vue";
import myDialog from "@/components/phone/common/myDialog.vue";
import twoVerify from "@/components/phone/login/twoVerify.vue";
import musicControl from "@/components/phone/musicControl.vue";
import { mapState } from "vuex";
export default {
  name: "app",
  data() {
    return {
      transitionName: "",
      type: 1,
      dialogLogin: false,
      openTwoVerify: false,
      myopenMusic: false,
    };
  },
  computed: {
    ...mapState({
      showLoginDialog: (state) => state.login.showLoginDialog,
      dialogMode: (state) => state.login.dialogMode,
      token: (state) => state.user.token,
      showtwoverify: (state) => state.login.showtwoverify,
      loginInfo: (state) => state.login.loginInfo,
      openMusic: (state) => state.musicPlayer.openMusic,
    }),
  },
  components: { register, myDialog, twoVerify, musicControl },
  watch: {
    $route(to, from) {
      const toIndex = to.meta.index;
      const fromIndex = from.meta.index;
      if (toIndex && fromIndex && (toIndex != 1 || fromIndex != 1)) {
        this.transitionName =
          toIndex < fromIndex ? "slide-right" : "slide-left";
      }
    },
    showLoginDialog(newVal) {
      if (newVal) {
        this.dialogLogin = true;
        this.$store.commit("SET_SHOW_LOGIN_DIALOG", {
          show: false,
          mode: this.dialogMode,
        }); // 重置状态
      }
    },
    showtwoverify(newVal) {
      if (newVal) {
        this.openTwoVerify = true;
        this.$store.commit("SET_SHOW_LOGIN_TWO_VERIFY", {
          show: false,
          info: this.loginInfo,
        }); // 重置状态
      }
    },
    openMusic(newVal) {
      this.myopenMusic = newVal;
    },
  },
  methods: {
    closeDialog() {
      this.dialogLogin = false;
      if (this.token) {
        this.$store.dispatch("getUserInfo").then(() => {});
      }
    },
    closeDialog2() {
      this.openTwoVerify = false;
      if (this.token) {
        this.$store.dispatch("getUserInfo").then(() => {});
      }
    },
    closeDialog3(){
      this.myopenMusic=false;
      this.$store.dispatch("musicPlayer/setOpenMusicBox",false);
    }
  },
};
</script>

<style>
/* #app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
} */
html {
  -webkit-text-size-adjust: none;
  border: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 100%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  width: 100%;
}
html body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--theme-text-color);
  font-size: 0.14rem;
  background: var(--theme-bg-color);
}
html body #app,
html body #app > div:not(#google-btn-help) {
  border: 0;
  height: 100%;
  /* height: auto; */
  margin: 0;
  padding: 0;
  width: 100%;
}
.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active {
  will-change: transform;
  transition: all 500ms;
  position: absolute;
}

.slide-right-enter {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}

.slide-right-leave-active {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}

.slide-left-enter {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}

.slide-left-leave-active {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}
</style>
