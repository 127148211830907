<template>
  <div class="steps-item" :class="[{ 'step-active': this.status === 'ok' }]">
    <div class="step-container">
      <div
        class="step-icon"
        :class="[
          { 'step-icon-active': this.status === 'ok' || this.status === 'js' },
        ]"
      >
        <slot name="icon">
          <span>{{ index + 1 }}</span>
        </slot>
      </div>
      <div class="steps-item-tail"></div>
      <div class="step-content">
        <div class="step-title" :class="[
          { 'step-title-active': this.status === 'ok' || this.status === 'js' },
        ]">
          <slot name="title">{{ title }}</slot>
        </div>
        <div class="step-description">
          <slot name="description">{{ description }}</slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "myStep",
  props: {
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    status: {
      type: String,
      default: "wait", // 'wait', 'process', 'finish'
    },
    index: {
      type: Number,
      required: true,
    },
  },
  computed: {},
};
</script>

<style scoped>
.steps-item {
  display: inline-block;

  overflow: hidden;
  position: relative;
  vertical-align: top;
  -ms-flex: none;
  flex: none;
  width: 0.4rem;
  overflow: visible;
}
.step-container {
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}

.step-icon {
  vertical-align: top;
  transition: background-color 0.3s, border-color 0.3s;
  text-align: center;
  background: none;
  display: inline-block;
  margin: 0;
  border-radius: 0.3rem;
  font-size: 0.24rem;
  height: 0.6rem;
  line-height: 0.6rem;
  width: 0.6rem;
  background-color: var(--theme-main-bg-color);
  border: thin solid var(--theme-color-line);
  box-sizing: initial;
  color: var(--theme-text-color-lighten);
  span {
    font-size: 0.24rem;
    line-height: inherit;
  }
}
.step-icon-active {
  background-color: var(--theme-primary-color);
  border: thin solid var(--theme-primary-color);
  color: var(--theme-primary-font-color) !important;
}

.steps-item-tail {
  margin: 0;
  padding: 0;
  height: 0.1rem;
  left: 0.494rem;
  top: 0.275rem;
  position: absolute;
  width: 100%;
}

.step-content {
  display: block;

  text-align: center;
  width: 116px;
  margin-top: 0;
}
.step-title {
    font-size: .2rem;
    font-weight: 400;
    height: auto;
    line-height: normal;
    margin-top: .07rem;
    color: var(--theme-text-color-lighten);
    margin: .064rem 0 0;
}
.step-title-active{
    color: var(--theme-primary-color)!important;
}
.step-description {
  color: #999;
}
.step-active {
  .steps-item-tail:after {
    background: #e8e8e8;
    border-radius: 1px;
    content: "";
    display: inline-block;
    -webkit-transition: background 0.3s;
    transition: background 0.3s;
    width: 1.53rem;
    background-color: var(--theme-color-line);
    height: 100%;
  }
}
</style>
