<template>
  <section>
    <div class="tipsbox">
      <p class="tips">
        Você também precisa apostar efetivamente 5,00 para sacar dinheiro!
        <i
          class="anticon refresh-coin animate__spin animate__infinite"
          :class="{ animate__animated: isAnimateAnimated }"
        >
          <i
            style="
              display: inline-flex;
              justify-content: center;
              align-items: center;
            "
          >
            <svg-icon
              icon-class="comm_icon_retry"
              style="
                width: 1em;
                height: 1em;
                color: var(--theme-alt-primary);
                fill: currentColor;
              "
            ></svg-icon>
          </i>
        </i>
      </p>
    </div>
    <div class="selectMain">
      <div class="selectBox">
        <mySelect :items="dayItems" @change="daySelect"   :selectItem.sync="myselectValue"></mySelect>
      </div>
    </div>
    <div class="listBox">
        <div class="context">
            <van-empty
        v-if="list.length < 1"
        :image="require('@/assets/azf5f-28vaj.png')"
        :description="$t('no records')"
      />
      <van-list v-else>
        <div class="sticky" v-for="(item, index) in list">
          <van-row justify="space-between" type="flex">
            <div class="leftTop">
              <div class="globalVipLevel">
                <p class="icobg">V{{ item.vip_lv }}</p>
              </div>
              <div class="globalInnerMain">
                <div class="leftTitle">{{ item.account_number }}</div>
                <span class="copy">
                  <i
                    style="
                      display: inline-flex;
                      justify-content: center;
                      align-items: center;
                    "
                  >
                    <svg-icon
                      icon-class="comm_icon_copy"
                      style="width: 1em; height: 1em; fill: white"
                    ></svg-icon
                  ></i>
                </span>
              </div>
            </div>
            <van-col span="12">
              <label>Apostas Válidas</label>
              <span class="directMember">{{ item.flow }}</span>
            </van-col>
          </van-row>
          <van-row justify="space-between" type="flex">
            <van-col span="12">
              <span class="dateTime">{{ item.register_time }}</span>
            </van-col>
            <van-col span="12">
              <label>Depósito</label>
              <span class="currencyAmount">{{ item.deposit }}</span>
            </van-col>
          </van-row>
        </div>
      </van-list>
        </div>
    </div>
  </section>
</template>
<script>
import mySelect from "@/components/phone/common/mySelect.vue";

export default {
  name: "betTask",
  components: {mySelect},
  mixins: [],

  data() {
    return {
      isAnimateAnimated: false,
      list:[],
      myselectValue:'0',
      dayItems:[
        {label:'Tudo',value:'0'},
        {label:'Aguardando apostas',value:'1'},
        {label:'Em andamento',value:'2'},
        {label:'Completado',value:'3'},
        {label:'Abandonado',value:'4'},
      ]
    };
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
daySelect(v){

}

  },
};
</script>
<style type="text/less" lang="less" scoped>
.tipsbox {
  -ms-flex-align: center;
  align-items: center;
  background-color: var(--theme-main-bg-color);
  display: -ms-flexbox;
  display: flex;
  font-size: 0.22rem;
  min-height: 0.8rem;
  padding: 0.2rem;
  .tips {
    color: var(--theme-secondary-color-error);
    padding: 0 0.2rem;
    text-align: center;
    width: 100%;
  }
  .refresh-coin {
    cursor: pointer;
    color: var(--theme-primary-color);
    margin-left: 0.1rem;
  }
}
.selectMain {
  -ms-flex-align: center;
  -ms-flex-pack: justify;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  margin: 0.2rem;
  padding: 0 0.2rem;
  .selectBox {
    -ms-flex-align: center;
    align-items: center;
    display: -ms-flexbox;
    display: flex;

  }
}
.listBox{
    padding: 0 .2rem;
    .context{
        -ms-flex-pack: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    height: calc(100vh - 2.55rem);
    height: calc(var(--vh, 1vh)*100 - 2.55rem);
    justify-content: center;
    overflow: hidden;
    position: relative;
    }
}
</style>
