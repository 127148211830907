<template>
  <div class="iframeBox">
    <div class="gohome" @click="gohome">
      <i
        style="
          display: inline-flex;
          justify-content: center;
          align-items: center;
          font-size: 0.4rem;
        "
      >
        <svg-icon
          icon-class="game_2_style_1_zk"
          fill="currentColor"
          style="width: 1em; height: 1em; font-size: 0.4rem"
        ></svg-icon>
      </i>
      <span> {{ $t('lobby') }}</span>
    </div>
    <iframe
      ref="gameBox"
      frameborder="0"
      scrolling="hidden"
      allow="clipboard-write; clipboard-read; web-share"
      style="width: 100%; height: 100%;overflow: hidden;"
      :src="gameUrl"
    ></iframe>
  </div>
</template>
<script>
import { getGameUrl, getTerminateGame } from "@/api/index";

export default {
  name: "",
  components: {},
  mixins: [],
  props: {},
  data() {
    return {
      gameCode: "",
      gameUrl: "",
      vendor: "",
      gameName: "",
    };
  },
  activated() {
    this.gameUrl = "";
    window.scrollTo(0, 0);
    this.gameCode = this.$route.query.gameCode;
    this.vendor = this.gameCode.split("_")[0];
    this.gameName = this.$route.query.name;
    this.getGameUrl();
  },
  methods: {
    fullscreen() {
      // this.$refs.gameBox.requestFullscreen();
    },
    getGameUrl() {
      // const userString = localStorage.getItem('user') ? localStorage.getItem('user') : {};

      // const  user=JSON.parse(userString);
      // const postData={
      //     "token":user.token,
      //     "account_number":user.accountNumber
      // };
      let data = {
        game_code: this.gameCode,
        platform: "H5",
        // token:user.token,
        // account_number:user.accountNumber
      };
      getGameUrl(data).then(({ data }) => {
        if (!data.data) {
          this.$message.error(data.message);
        } else {
          this.gameUrl = data.data.game_url;
        }
        this.fullscreen();
      });
    },
    gohome() {
      getTerminateGame().then(({ data }) => {});
      this.$router.push({
        path: "/",
        query: {},
      });
    },
  },
};
</script>
<style lang="less" scoped>
.iframeBox {
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1997;
}
.gohome {
  -ms-flex-align: center;
  -ms-flex-pack: center;
  -webkit-user-drag: none;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  border: 0.015rem solid hsla(0, 0%, 100%, 0.65);
  border-radius: 50%;
  -webkit-box-shadow: 0 0.03rem 0.06rem 0 rgba(0, 0, 0, 0.16);
  box-shadow: 0 0.03rem 0.06rem 0 rgba(0, 0, 0, 0.16);
  color: #fff;
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 0.95rem;
  justify-content: center;
  left: 0.03rem;
  position: fixed;
  top: 0.03rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 0.95rem;
  z-index: 1998;
}
</style>
