<template>
  <div class="box">
    <el-container>
      <el-header height=".9rem">
        <van-nav-bar
          @click-left="onClickLeft"
          right-text=" "
          :fixed="true"
          style="
            max-width: var(--theme-max-width);
            margin: 0 auto;
            left: 0;
            right: 0;
            height: 0.9rem;
          "
        >
          <template #left>
            <div>
              <i style="font-size: 0.24rem">
                <svg-icon
                  icon-class="comm_icon_fh"
                  style="
                    width: 1em;
                    height: 1em;
                    color: var(--theme-text-color);
                    fill: var(--theme-text-color-lighten);
                  "
                ></svg-icon>
              </i>
            </div>
          </template>

          <template #title>
            <div class="title">
              <span class="global-wrapper-title">
                <span
                  style="
                    width: 100%;
                    margin-bottom: -1.64706px;
                    padding-bottom: 1.64706px;
                    font-size: 0.3rem;
                  "
                  ><span
                    style="
                      vertical-align: middle;
                      display: inline-block;
                      width: 100%;
                    "
                  >
                    <span style="-webkit-line-clamp: 2" class="ts inner-text">
                      {{ $t('toinvite') }}</span
                    >
                  </span></span
                >
              </span>
            </div>
          </template>
        </van-nav-bar></el-header
      >
      <el-main>
        <van-tabs
          v-model="active"
          background="var(--theme-main-bg-color)"
          title-active-color="var(--theme-primary-color)"
          title-inactive-color="var(--theme-text-color-darken)"
          color="#fff"
          :scrollspy="true"
          :swipeable="true"
          :animated="true"
          :ellipsis="false"
        >
          <van-tab :title="$t('invitation link')" title-class=" van-title ">
            <div style="height: calc(var(--vh, 1vh) * 100)">
              <invitationLink></invitationLink>
            </div>
          </van-tab>

          <van-tab :title="$t('my data')" title-class=" van-title ">
            <div style="height: calc(var(--vh, 1vh) * 100)">
              <myDataList></myDataList>
            </div>
          </van-tab>

          <van-tab :title="$t('all data')" title-class=" van-title ">
            <div style="height: calc(var(--vh,1vh)*100 - 1.65rem)">
              <teamDataDetail></teamDataDetail>
            </div>
          </van-tab>

          <van-tab :title="$t('performance')" title-class=" van-title ">
            <div style="height:calc(var(--vh,1vh)*100 - 1.65rem)">
              <performance></performance>
            </div>
          </van-tab>

          <van-tab :title="$t('commission')" title-class=" van-title ">
            <div style="height: calc(var(--vh, 1vh) * 100)">
              <commission></commission>
            </div>
          </van-tab>
          <van-tab :title="$t('subordinate data')" title-class=" van-title ">
            <directData></directData>
          </van-tab>

          <van-tab :title="$t('subordinate betting')" title-class=" van-title ">
            <directBetting></directBetting>
          </van-tab>

          <van-tab :title="$t('finance of subordinates')" title-class=" van-title ">
            <directFinance></directFinance>
          </van-tab>

          <van-tab :title="$t('redemptions of subordinates')" title-class=" van-title ">
            <directReceive></directReceive>
          </van-tab>


          <van-tab :title="$t('register subordinates')" title-class=" van-title ">
            <createAccount></createAccount>
          </van-tab>
        </van-tabs>

        <!-- <invite v-if="index == 0"></invite>
              <assure v-if="index == 1"></assure>
              <dailyGuarantee v-if="index == 2"></dailyGuarantee>
              <envelopeReward v-if="index == 3"></envelopeReward>
              <trunTable v-if="index == 4"></trunTable>
              <signIn v-if="index == 5"></signIn>
              <cashback v-if="index == 6"></cashback> -->
      </el-main>
    </el-container>
  </div>
</template>
<script>
import invitationLink from "@/components/phone/agent/invitationLink.vue";
import myDataList from "@/components/phone/agent/myDataList.vue";
import teamDataDetail from "@/components/phone/agent/teamDataDetail.vue";
import performance from "@/components/phone/agent/performance.vue";
import commission from "@/components/phone/agent/commission.vue";
import directData from "@/components/phone/agent/directData.vue";
import directBetting from "@/components/phone/agent/directBetting.vue";
import directFinance from "@/components/phone/agent/directFinance.vue";

import directReceive from "@/components/phone/agent/directReceive.vue";

import createAccount from "@/components/phone/agent/createAccount.vue";

import "@vant/touch-emulator";
export default {
  components: {
    invitationLink,
    myDataList,
    teamDataDetail,
    performance,
    commission,
    directData,
    directBetting,
    directFinance,
    directReceive,
    createAccount
  },
  data() {
    return {
      active: 0,
      index: 0,
    };
  },
  created() {
    if (this.$route.query.index) {
      this.index = this.$route.query.index;
    }
  },
  activated() {
    if (this.$route.query.index) {
      this.index = this.$route.query.index;
    }
  },
  methods: {
    onClickLeft() {
      history.go(-1);
    },

    selectTask(index) {
      this.index = index;
    },
  },
};
</script>
<style lang="less" scoped>
// .box {
//   min-height: 100vh;
//   background-color: var(--theme-bg-color);
// }

/deep/.el-main {
  padding: 0;
}

[class*="van-hairline"]::after {
  border: 0;
}
/deep/.van-tabs {
  .van-tabs__wrap {
    border-bottom: thin solid var(--theme-color-line);
  }
}
.van-nav-bar {
  border-bottom: 0.01rem solid var(--theme-color-line);
  background: var(--theme-main-bg-color);
  /deep/.van-nav-bar__content {
    height: 0.9rem;
    padding: 0px 0.1rem 0px 0.2rem;
  }

  /deep/.van-nav-bar__right {
    width: 0.9rem;
    display: flex;
    justify-content: flex-end;
    position: inherit;
    padding: 0;
    font-size: 0.22rem;
  }
  /deep/.van-nav-bar__left {
    position: inherit;
    padding: 0;
    width: 0.8rem;
    div {
      align-items: center;
      color: var(--theme-text-color);
      cursor: pointer;
      display: flex;
      font-size: 0.2rem;
      font-size: 0.24rem;
      height: 0.8rem;
      width: 0.8rem;
    }
  }
  /deep/.van-nav-bar__title {
    align-items: center;
    display: flex;
    flex: 2 1 0%;
    flex-direction: column;
    margin: 0;
    max-width: none;
    // max-width: max-content;
    .title {
      // padding: 0px 0.34rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--theme-text-color);
      span {
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        color: var(--theme-text-color-darken);
        display: -webkit-box;
        margin: 0px 0.12rem;
        overflow: hidden;
        text-align: center;
        text-overflow: ellipsis;
        vertical-align: middle;
      }
      .global-wrapper-title {
        font-size: 0.3rem;

        margin-top: -0.014rem;

        .ts {
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          display: -webkit-box;
          line-height: 1.2;
          overflow: initial;
          text-align: center;
          text-overflow: ellipsis;
          vertical-align: middle;
          word-break: break-word;
        }
      }
    }
  }

  /deep/.van-nav-bar__arrow {
    color: var(--theme-text-color);
  }

  /deep/.van-nav-bar__text {
    color: var(--theme-ant-primary-color-0);
    word-wrap: break-word;
    line-height: 1.1;
    text-align: center;
    white-space: pre-line;
    word-break: break-word;
    //  font-size: 24.576px;
    font-weight: 400;
    user-select: none;
    font: inherit;
    text-transform: none;
    box-sizing: border-box;
    pointer-events: none;
  }
  /deep/.van-ellipsis {
    white-space: inherit;
  }
}

/deep/.el-input__inner {
  background-color: var(--theme-main-bg-color);
  color: var(--theme-text-color);
  border-color: var(--theme-color-line);
  // border-radius: 40vw;
}

/deep/.el-input__inner:focus {
  border-color: var(--theme-ant-primary-color-13) !important;
  box-shadow: 0 0 0 0.02rem rgba(var(--theme-primay-color-hex), 0.2) !important;
}

::-webkit-scrollbar {
  display: none;
}
.van-title {
  height: 0.7rem;
  line-height: 0.7rem;
  margin-right: 0.3rem;
  font-size: 0.22rem;
  margin-left: 0.2rem;
}
</style>
