<template>
   <div>
      <div class="taskRule">
         <h3>I. Conteúdo da Atividade:</h3>
         9991BET em tempo real rebate ilimitadamente, toda a rede a partir de R$1 pode se auto-aplicar! Liquidação em
         tempo real, sem espera,
         rebate de um clique, operação mais fácil, aposta a partir de R$1, rebate diário até 1,0% limite ilimitado! Os
         membros podem liquidar
         o rebate no mesmo dia imediatamente de acordo com suas próprias necessidades,
         e o rebate chegará em segundos! Quando você quiser voltar, você pode voltar quando quiser, dizer adeus à
         espera!<br />
         <table>
            <thead>
               <th>Apostas</th>
               <th>eletrônico</th>
               <th>Pescariaria</th>
               <th>Cartas</th>
               <th>Vídeo</th>
               <th>Esportes</th>
            </thead>
            <tbody>
               <tr>
                  <td>1+</td>
                  <td>0.20%</td>
                  <td>0.25%</td>
                  <td>0.25%</td>
                  <td>0.30%</td>
                  <td>0.40%</td>
               </tr>
               <tr>
                  <td>1000000+</td>
                  <td>0.50%</td>
                  <td>0.50%</td>
                  <td>0.50%</td>
                  <td>0.50%</td>
                  <td>0.60%</td>
               </tr>
               <tr>
                  <td>5000000+</td>
                  <td>0.80%</td>
                  <td>0.80%</td>
                  <td>0.80%</td>
                  <td>0.80%</td>
                  <td>0.80%</td>
               </tr>
               <tr>
                  <td>10000000+</td>
                  <td>1.00%</td>
                  <td>1.00%</td>
                  <td>1.00%</td>
                  <td>1.00%</td>
                  <td>1.00%</td>
               </tr>
            </tbody>
         </table>
         <h3>II. Método de aplicação:</h3>
         Inicie sessão na conta de membro → o rebate → [Rebate] reivindicação com um clique
         <h3>Ⅲ. Regras de Atividade:</h3>
         1. O rebate obtido pode ser levantado sem capotamento;<br />
         2. Todos os videogames de jogos de mesa e video poker, jogos de loteria - BB fast open, loteria VR não
         participarão do cálculo de rebates diários;<br />
         3. Comportamentos que violem as regras normais do jogo, como sparring e arbitragem, não acumularão apostas
         válidas. Por exemplo, nas apostas de apostas desportivas, as apostas de cobertura ou de apostas não são
         contabilizadas; Em entretenimento ao vivo, jogos eletrônicos, apostas de loteria, apostas sem risco não são
         contadas; Quaisquer apostas, eventos ou rodadas canceladas não contam. As apostas sem risco incluem apostas
         simultâneas, grandes e pequenas, simples e até, vermelhas e pretas, apostas de roleta em mais de 24 números,
         etc. Se "grande" + "duplo" forem colocados ao mesmo tempo, e o número de números for alterado para um total de
         27, a rodada não será contada como uma aposta válida e, se exceder um terço do número de apostas, não será
         contada como uma aposta válida ao longo do dia;<br />
         4. A participação dos Membros nas atividades é contada automaticamente pelo sistema, se houver alguma objeção, prevalecerá o resultado da consulta da 9991BET;<br />
         5. Se você esquecer sua conta/senha de membro, entre em contato com "atendimento ao cliente on-line 24 horas" para ajudá-lo a recuperar as informações da sua conta;<br />
         6.A 9991BET reserva-se o direito de alterar, parar ou cancelar esta promoção a qualquer momento.
      </div>
   </div>
</template>
<script>
export default {
   name: '',
   components: {

   },
   mixins: [],
   props: {

   },
   data() {
      return {

      }
   },
   computed: {

   },
   watch: {

   },
   mounted() {

   },
   methods: {

   }
};
</script>
<style type="text/less" lang="less" scoped>
.taskRule {
   background: #5375D1;
   margin-top: 1vw;
   border-radius: 0.3vw;
   color: #D5DDFF;
   padding: 10vw 2vw 2vw;
   font-size: 3.3vw;
}

table {
   margin: 2vw auto;
   border-collapse: collapse;
}

td {
   border: 1px solid #fff;
   text-align: center;
}

th {
   border: 1px solid #fff;
   padding: 1vw;
}

tr {
   border: 1px solid #fff;
}
</style>