<template>
  <section class="wrap">
    <div class="topMenu">
      <div class="left">
        <dateSelect
          :startDate.sync="startDate"
          :endDate.sync="endDate"
          @change="selectDate"
        ></dateSelect>
      </div>
      <div class="right">
        <search
           :placeholder="$t('member ID')"
          @searchClick="searchClick"
          :loading.sync="loading"
          :findtext.sync="findtext"
        ></search>
      </div>
    </div>
    <div class="context">
      <van-empty
        :image="require('@/assets/azf5f-28vaj.png')"
        :description="$t('no records')"
      />
    </div>
  </section>
</template>
<script>
import dateSelect from "@/components/phone/common/dateSelect.vue";
import search from "@/components/phone/common/search.vue";

export default {
  name: "directReceive",
  components: { dateSelect, search },
  data() {
    return {
      findtext: "",

      startDate: new Date(),
      endDate: new Date(),
      list: [],

      loading: false,
    };
  },
  created() {},
  mounted() {

  },
  destroyed() {},
  methods: {
    selectDate(start, end) {
      this.getList(start, end, this.findtext);
    },
    searchClick(text) {
      this.getList(this.startDate, this.endDate, text);
    },
    getList(start, end, text) {
      const data = {
        start_day: formatDate(start),
        end_day: formatDate(end),
        account_number: text,
      };
      this.loading = true;
      this.list = [];
      // getPeriodAllDataList(data).then(({ data }) => {
      //   this.count_deposit_other = data.data.count_deposit_other;
      //   this.count_deposit_subordinate = data.data.count_deposit_subordinate;
      //   this.deposit_other = formatNumberToString2(data.data.deposit_other);
      //   this.deposit_subordinate = formatNumberToString2(data.data.deposit_subordinate);
      //   this.total_count_deposit = data.data.total_count_deposit;
      //   this.total_deposit = formatNumberToString2(data.data.total_deposit);
      //   this.list = data.data.list;
      //   this.loading = false;
      // });
    },
  },
};
</script>
<style type="text/less" lang="less" scoped>
.wrap {
  border-radius: 0.1rem;
  background: 0 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 0;
  .topMenu {
    -ms-flex-align: center;
    -ms-flex-pack: start;
    align-items: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: flex-start;
    padding-bottom: 0.2rem;
    padding: 0.2rem;
    .left {
      position: relative;
      z-index: 999;
      margin-right: 0.2rem;
    }
    .right {
      width: 2rem;
    }
  }
  .context {
    -ms-flex-pack: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    height: calc(100vh - 2.55rem);
    height: calc(var(--vh, 1vh) * 100 - 2.55rem);
    justify-content: center;
    overflow: hidden;
    position: relative;
    /deep/.van-empty {
      -ms-flex-positive: 1;
      flex-grow: 1;
      padding: 0;

      .van-empty__image {
        height: 2.1rem;
        text-align: center;
        width: 2.1rem;
      }
      .van-empty__description {
        color: var(--theme-text-color-lighten);
        font-size: 0.26rem;
        margin-top: 0.2rem;
      }
    }
  }
}
</style>
