var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app"},[_c('div',{staticClass:"main"},[_c('div',[_c('van-nav-bar',{staticStyle:{"z-index":"999","max-width":"var(--theme-max-width)","right":"0","left":"0","margin":"0 auto"},attrs:{"fixed":true},on:{"click-left":_vm.onClickLeft},scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('svg-icon',{staticStyle:{"width":"1em","height":"1em","color":"var(--theme-text-color)","fill":"var(--theme-text-color-lighten)"},attrs:{"icon-class":"comm_icon_fh"}})]},proxy:true},{key:"title",fn:function(){return [_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.title))])]},proxy:true}])})],1),_c('div',{staticClass:"search"},[_c('el-input',{attrs:{"placeholder":_vm.$t('look'),"clearable":true,"suffix-icon":"el-icon-search","size":"small"},on:{"input":_vm.search},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}})],1),_c('div',{staticClass:"gameBody"},[_c('el-container',[(!_vm.hideLeftMenu)?_c('el-aside',{staticClass:"leftmenu",attrs:{"width":"1.5rem"}},[_c('van-sidebar',{attrs:{"lazy-render":false,"duration":"0"},on:{"change":_vm.leftMenuChange},model:{value:(_vm.activeIndex),callback:function ($$v) {_vm.activeIndex=$$v},expression:"activeIndex"}},_vm._l((_vm.leftMenu),function(item,index){return _c('van-sidebar-item',{key:index,staticClass:"leftitem",on:{"click":_vm.leftMenuClick},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"itembg"},[_c('van-image',{attrs:{"src":index === _vm.activeIndex
                        ? require('@/assets/btn_zc2_1.png')
                        : require('@/assets/btn_zc2_2.png'),"show-loading":false,"fit":"fill"}})],1),_c('div',{staticClass:"itemtitlebg"},[_c('van-image',{attrs:{"src":item.icon,"lazy-load":true,"fit":"fill"},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('div',{staticClass:"imgload"})]},proxy:true}],null,true)}),_c('span',{staticClass:"span1"},[_c('span',{staticClass:"span2"},[_c('span',{staticClass:"span3"},[_vm._v(" "+_vm._s(item.title)+" ")])])])],1)]},proxy:true}],null,true)})}),1)],1):_vm._e(),_c('el-container',{staticStyle:{"margin-left":"0.2rem"}},[_c('el-main',[_c('van-tabs',{staticClass:"mainBody",attrs:{"swipe-threshold":"3"},on:{"change":_vm.topMenuChange,"click":_vm.topMenuClick},model:{value:(_vm.topActive),callback:function ($$v) {_vm.topActive=$$v},expression:"topActive"}},_vm._l((_vm.topMenu),function(item,index){return _c('van-tab',{key:index,scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"menuTitle"},[_c('div',{staticClass:"titlebg",style:(index === _vm.topActive
                          ? 'background-color: var(--theme-primary-color);border: initial;color: var(--theme-primary-font-color);'
                          : '')},[_c('span',{staticClass:"span1",staticStyle:{"width":"100%","margin-bottom":"-1.42871px","padding-bottom":"1.42871px"}},[_c('span',{staticClass:"span2",staticStyle:{"font-size":"12.144px"}},[_c('span',{staticClass:"span3",staticStyle:{"-webkit-line-clamp":"2"}},[_vm._v(_vm._s(item.title))])])])])])]},proxy:true}],null,true)},[_c('div',{staticStyle:{"margin-top":"0.2rem","--cardRowSpacing":"0.1rem","--cardColSpacing":"0","--box-cut-width":"fit-content","--box-cut-height":"fit-content","--box-cut-border-radius":"0.14rem 0.2rem 0.2rem 0.2rem","--favorited-icon-side":"calc( var(--card-benchmark) * 0.206 )","--favorited-icon-top":"calc(var(--card-benchmark) * 0.033)","--favorited-icon-right":"calc( var(--card-benchmark) * 0.033 )","--favorited-icon-bottom":"initial","--favorited-icon-left":"initial","--card-benchmark":"1.6rem","--click-arean-expand-size":"-0.06rem","--game-name-vertical-padding":"0.06rem 0.15rem","--top-name-font-size":"0.24rem","--bottom-name-font-size":"0.14rem","--bottom-name-margin-top":"0.05rem","--game-name-text-shadow":"rgba(0, 0, 0, 0.3)","--game-name-text-stroke":"none","--game-name-font-weight":"normal","--game-platform-name-height":"calc(var(--card-benchmark) * 0.44 )","--game-platform-name-padding":"0","--game-platform-name-font-size":"calc( var(--card-benchmark) * 0.113 )"},style:([
                    {
                      '--outer-box-main-width': _vm.hideLeftMenu
                        ? '2.1rem'
                        : '1.6rem',
                    },
                    {
                      '--outer-box-main-height': _vm.hideLeftMenu
                        ? 'calc(var(--card-benchmark)*1.56)'
                        : '2.12rem',
                    },
                  ])},[_c('moreBatchDisplay',{attrs:{"gameList":_vm.gameList,"pageSize":_vm.pageSize,"pageIndex":_vm.pageIndex},on:{"update:pageIndex":function($event){_vm.pageIndex=$event},"update:page-index":function($event){_vm.pageIndex=$event},"child-clicked":_vm.toEmbedded,"flipping-pages":_vm.flippingPages}})],1),(_vm.gameList.total > _vm.pageSize)?_c('div',{staticClass:"more"},[_c('div',{staticClass:"morebox"},[_c('van-pagination',{attrs:{"value":_vm.pageIndex,"total-items":_vm.gameList.total,"items-per-page":_vm.pageSize,"show-page-size":3,"force-ellipses":true},on:{"change":_vm.flippingPages},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('svg-icon',{staticStyle:{"width":"1em","height":"1em","fill":"currentColor","color":"var(--theme-text-color-lighten)"},attrs:{"icon-class":"arrow_left"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('svg-icon',{staticStyle:{"width":"1em","height":"1em","fill":"currentColor","color":"var(--theme-text-color-lighten)"},attrs:{"icon-class":"arrow_right"}})]},proxy:true}],null,true)})],1)]):_vm._e()])}),1)],1)],1)],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }