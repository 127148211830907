<template>
  <div class="box">
    <el-container>
      <el-header height=".9rem">
        <van-nav-bar
          @click-left="onClickLeft"
          right-text=" "
          :fixed="true"
          style="
            max-width: var(--theme-max-width);
            margin: 0 auto;
            left: 0;
            right: 0;
            height: 0.9rem;
          "
        >
          <template #left>
            <div>
              <i style="font-size: 0.24rem">
                <svg-icon
                  icon-class="comm_icon_fh"
                  style="
                    width: 1em;
                    height: 1em;
                    color: var(--theme-text-color);
                    fill: var(--theme-text-color-lighten);
                  "
                ></svg-icon>
              </i>
            </div>
          </template>

          <template #title>
            <div class="title">
              <span class="global-wrapper-title">
                <span
                  style="
                    width: 100%;
                    margin-bottom: -1.64706px;
                    padding-bottom: 1.64706px;
                    font-size: 0.3rem;
                  "
                  ><span
                    style="
                      vertical-align: middle;
                      display: inline-block;
                      width: 100%;
                    "
                  >
                    <span style="-webkit-line-clamp: 2" class="ts inner-text">
                      {{ $t('about') }}</span
                    >
                  </span></span
                >
              </span>
            </div>
          </template>
        </van-nav-bar>
      </el-header>
      <el-main>
        <myScrollbarBar style="width: 100%; " :style="`height: calc(${pheight}px - 1.03rem)`" st="y">
          <div class="about">
            <div class="textBox">
              <div style="overflow: hidden">
                <div style="transform-origin: 0 0">
                  <div style="font-size: 0.22rem; text-align: left">
                    <p style="text-align: center">
                      <strong>{{ $t('about') }} 9991BET</strong>
                    </p>
                    <p>
                      9991BET é uma casa de apostas com licença legal de apostas
                      online emitida pela Zona Econômica e Porto Livre da Ilha
                      de Man e Cagayan. Esta é uma organização de
                      desenvolvimento internacional com uma escala que abrange
                      toda a região Ásia-Pacífico. Com experiência e elevada
                      reputação tendo servido mais de 10 milhões de jogadores, a
                      9991BET tem vindo a afirmar a sua posição e marca no
                      mercado de jogos online. Como casa de apostas do M.A.N
                      Entertainment Group, com sede em Manila, Filipinas - o
                      grupo é considerado o maior da Ásia no mercado de apostas
                      online, com uma enorme equipe. Com comprovada alta
                      confiabilidade, a 9991BET é atualmente uma das casas de
                      apostas claramente autenticada e possui licença
                      certificada pela associação internacional de apostas
                      online. A principal preocupação da 9991BET é a segurança
                      do desenvolvimento de informações pessoais. A 9991BET
                      garante não divulgar informações pessoais dos clientes a
                      terceiros porque utiliza criptografia de dados com os mais
                      altos padrões. Todas as informações pessoais são
                      transmitidas via Secure Socket (padrão de criptografia SS
                      128 bits) e de forma segura em ambientes de gerenciamento
                      seguros e não acessíveis à área da rede pública. Todos os
                      dados internos e externos são limitados, monitorados de
                      perto e gerenciados com rigor, trazendo uma sensação de
                      segurança absoluta aos jogadores.
                    </p>
                    <p>&nbsp;</p>
                    <p>Honestidade</p>
                    <p>
                      A honestidade é o primeiro e mais importante fator para
                      afirmar a reputação da 9991BET. Como operador profissional
                      internacional de jogos online. Temos o compromisso de
                      fornecer aos clientes os serviços e jogos mais seguros,
                      honestos e justos.
                    </p>
                    <p>&nbsp;</p>
                    <p>Diversifique o jogo</p>
                    <p>
                      Além de diversificar os jogos esportivos. Também
                      oferecemos caça-níqueis, jogos virtuais e jogos de cartas
                      de pôquer. Selecionamos uma ampla variedade de jogos
                      online emocionantes para oferecer a você a escolha mais
                      emocionante. Com uma equipe jovem e dedicada de
                      atendimento ao cliente, o suporte direto 24 horas por dia,
                      7 dias por semana, garante que os resultados dos seus
                      jogos sejam sempre honestos, e não resultados determinados
                      pela máquina.
                    </p>
                    <p>&nbsp;</p>
                    <p>Programas promocionais</p>
                    <p>
                      Ao participar do entretenimento no 9991BET, todos os dias
                      você tem a oportunidade de aproveitar promoções
                      inesperadas, seja você um novo jogador ou um cliente fiel.
                      E cada jogador pode obter vitórias extremamente grandes.
                    </p>
                    <p>&nbsp;</p>
                    <p>Atendimento ao Cliente</p>
                    <p>
                      - No mercado de jogos altamente competitivo, 9991BET ainda
                      é a única escolha para muitos jogadores. Porque além de
                      produtos de entretenimento novos e interessantes, também
                      oferecemos aos jogadores o serviço de atendimento ao
                      cliente mais rápido, conveniente, dedicado e profissional.
                    </p>
                    <p>
                      - Interação multicanal: Com diversos canais de
                      comunicação, a equipe de atendimento está sempre pronta
                      para atendê-lo da maneira mais oportuna.
                    </p>
                    <p>
                      - Sempre traga surpresas e surpresas aos clientes com
                      promoções atrativas.
                    </p>
                    <p>&nbsp;</p>
                    <p>Responsabilidade do jogador</p>
                    <p>
                      9991BET é a promoção de apostas controladas. Portanto,
                      recomendamos que você siga as seguintes regras básicas:
                    </p>
                    <p>
                      - Menores de 18 anos estão proibidos de participar de
                      jogos de apostas. Antes de participar em qualquer jogo de
                      apostas, certifique-se de ter pelo menos 18 anos de idade.
                    </p>
                    <p>
                      - Crie um orçamento de apostas e limite as apostas apenas
                      ao seu orçamento existente.
                    </p>
                    <p>&nbsp;</p>
                    <p>Conta e segurança</p>
                    <p>
                      Todas as transações bancárias e de pagamento online dos
                      jogadores são realizadas por instituições financeiras
                      internacionais, absolutamente seguras e protegidas (padrão
                      de criptografia SSL de 128 bits)
                    </p>
                    <p>
                      Cada jogador recebe um ID de login e senha exclusivos para
                      garantir que os fundos do jogador estejam seguros e
                      protegidos. Eles são criptografados usando as medidas de
                      criptografia mais avançadas.
                    </p>
                    <p>
                      Com uma equipe de atendimento online 24 horas por dia, 7
                      dias por semana, garantimos o espaço de jogo mais seguro e
                      honesto.
                    </p>
                    <p>
                      Os clientes são mantidos em total confidencialidade ao
                      fazer login e jogar em nosso sistema. Comprometemo-nos a
                      nunca divulgar informações de clientes a terceiros
                      (incluindo o governo).
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </myScrollbarBar>
      </el-main>
    </el-container>
  </div>
</template>
<script>
import myScrollbarBar from "@/components/phone/common/myScrollbarBar.vue";

export default {
  components: { myScrollbarBar },
  data() {
    return {
      active: 0,
      pheight: 0,
    };
  },  mounted() {
    this.pheight = window.innerHeight;
    window.addEventListener("resize", this.resize);

  },
  methods: {
    resize() {
      this.pheight = window.innerHeight;
    },
    onClickLeft() {
      history.go(-1);
    },
  },
  beforeDestroy(){
    window.removeEventListener("resize", this.resize);
  }
};
</script>
<style lang="less" scoped>
/deep/.el-main ,/deep/.el-head{
    padding: 0;
}
.about {
  min-height: 6.2rem;
  padding: 0.2rem;

  .textBox {
    border-radius: 0.14rem;
    -webkit-box-shadow: 0 0.03rem 0.1rem 0 rgba(0, 0, 0, 0.06);
    box-shadow: 0 0.03rem 0.1rem 0 rgba(0, 0, 0, 0.06);
    background-color: var(--theme-main-bg-color);
    min-height: 5.8rem;
    padding: 0.2rem;
    word-break: break-word;
  }
}

.van-nav-bar {
  border-bottom: 0.01rem solid var(--theme-color-line);
  background: var(--theme-main-bg-color);
  /deep/.van-nav-bar__content {
    height: 0.9rem;
    padding: 0px 0.1rem 0px 0.2rem;
  }

  /deep/.van-nav-bar__right {
    width: 0.9rem;
    display: flex;
    justify-content: flex-end;
    position: inherit;
    padding: 0;
    font-size: 0.22rem;
  }
  /deep/.van-nav-bar__left {
    position: inherit;
    padding: 0;
    width: 0.8rem;
    div {
      align-items: center;
      color: var(--theme-text-color);
      cursor: pointer;
      display: flex;
      font-size: 0.2rem;
      font-size: 0.24rem;
      height: 0.8rem;
      width: 0.8rem;
    }
  }
  /deep/.van-nav-bar__title {
    align-items: center;
    display: flex;
    flex: 2 1 0%;
    flex-direction: column;
    margin: 0;
    max-width: none;
    // max-width: max-content;
    .title {
      // padding: 0px 0.34rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--theme-text-color);
      span {
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        color: var(--theme-text-color-darken);
        display: -webkit-box;
        margin: 0px 0.12rem;
        overflow: hidden;
        text-align: center;
        text-overflow: ellipsis;
        vertical-align: middle;
      }
      .global-wrapper-title {
        font-size: 0.3rem;

        margin-top: -0.014rem;

        .ts {
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          display: -webkit-box;
          line-height: 1.2;
          overflow: initial;
          text-align: center;
          text-overflow: ellipsis;
          vertical-align: middle;
          word-break: break-word;
        }
      }
    }
  }

  /deep/.van-nav-bar__arrow {
    color: var(--theme-text-color);
  }

  /deep/.van-nav-bar__text {
    color: var(--theme-ant-primary-color-0);
    word-wrap: break-word;
    line-height: 1.1;
    text-align: center;
    white-space: pre-line;
    word-break: break-word;
    //  font-size: 24.576px;
    font-weight: 400;
    user-select: none;
    font: inherit;
    text-transform: none;
    box-sizing: border-box;
    pointer-events: none;
  }
  /deep/.van-ellipsis {
    white-space: inherit;
  }
}
</style>
