<template>
    <div
      class="drag-scroll"
      :class="direction"
      @mousedown="startDrag"
      @mousemove="drag"
      @mouseup="endDrag"
      @mouseleave="endDrag"
    >
      <slot></slot>
    </div>
  </template>
  
  <script>
  export default {
    name: 'DragScroll',
    props: {
      direction: {
        type: String,
        default: 'horizontal', // 默认为水平拖拽
        validator: function (value) {
          return ['horizontal', 'vertical'].includes(value);
        }
      }
    },
    data() {
      return {
        dragging: false,
        lastX: 0,
        lastY: 0
      };
    },
    methods: {
      startDrag(e) {
        this.dragging = true;
        this.lastX = e.pageX;
        this.lastY = e.pageY;
      },
      drag(e) {
        if (!this.dragging) return;
        let dx = this.lastX - e.pageX;
        let dy = this.lastY - e.pageY;
        this.lastX = e.pageX;
        this.lastY = e.pageY;
        if (this.direction === 'horizontal') {
          this.$el.scrollLeft += dx;
        } else {
          this.$el.scrollTop += dy;
        }
      },
      endDrag() {
        this.dragging = false;
      }
    }
  };
  </script>
  
  <style scoped>
  .drag-scroll {
    overflow: hidden; /* 隐藏滚动条 */
    /* overflow: auto; */
    cursor: grab;
  }
  .drag-scroll:active {
    cursor: grabbing;
  }
  .drag-scroll.horizontal {
    overflow-x: scroll;
    overflow-y: hidden;
  }
  .drag-scroll.vertical {
    overflow-x: hidden;
    overflow-y: scroll;
  }
  </style>
  