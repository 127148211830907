import Vue from 'vue'
import App from './App.vue'
import router from './router'

import { Toast, List, Uploader, Icon, Field, Image as VanImage, 
          Lazyload, NoticeBar, Checkbox, Tab, Tabs, PasswordInput, Swipe, SwipeItem,
          Tabbar, TabbarItem, NavBar,ActionSheet,Slider , Sidebar, SidebarItem ,Notify ,Dialog ,Pagination ,Empty ,Popup ,NumberKeyboard ,DatetimePicker  ,Col, Row ,Cell, CellGroup ,Form ,CountDown,Badge } from 'vant';

          import './plugins/vant.js'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './registerServiceWorker'
import i18n from './i18n'
import locale from 'element-ui/lib/locale/lang/pt'
import './assets/css/theme.css';
import './assets/css/main.css';
import './assets/css/child.css';
import './assets/css/login.css';
import './assets/css/music.css';
import '@/icons'
import LocalStorageCache from "@/utils/localStorageCache.js";
Vue.use(ElementUI, { locale })

import './utils/v-copy'
import './utils/v-scroll'
import FingerprintJS from "fingerprintjs2";
import store from './store'
// import VConsole from 'vconsole';
// const vconsole=new VConsole();


Vue.use(Toast);
Vue.use(NoticeBar);
Vue.use(List);
Vue.use(Uploader);
Vue.use(Icon);
Vue.use(Field);
Vue.use(ElementUI);
Vue.use(VanImage);
Vue.use(Lazyload);
Vue.use(Checkbox)
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(ElementUI)
Vue.use(PasswordInput)
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(NavBar);
Vue.use(ActionSheet);
Vue.use(Sidebar);
Vue.use(SidebarItem);
Vue.use(Notify);
Vue.use(Dialog);
Vue.use(Pagination);
Vue.use(Empty);
Vue.use(Popup);
Vue.use(NumberKeyboard);
Vue.use(DatetimePicker );
Vue.use(Row);
Vue.use(Col);
Vue.use(CountDown);
Vue.use(Cell);
Vue.use(CellGroup );
Vue.use(Form );
Vue.use(Slider);
Vue.use(Badge);
Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  if (to.path.indexOf('game') === 1) {
    next('/')
  }
  if (to.meta.title) {
    document.title = to.meta.title
  }
  if(to.query.invite_code){
    LocalStorageCache.setCache('invite_code',to.query.invite_code);
  }
  next()
})
i18n.locale = 'pt'
Vue.config.productionTip = false;
const app = new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app');

Vue.prototype.$root = app;
//删除过期数据
LocalStorageCache.deleteExpiredData();
if(!LocalStorageCache.get("UniqueFingerprint")){
  FingerprintJS.get((components) => {
    const values = components.map((component) => component.value);
    const murmur = FingerprintJS.x64hash128(values.join(""), 31);
    LocalStorageCache.set("UniqueFingerprint",murmur);
  });
}

