<template>
  <div>
    <van-tabbar
      :fixed="true"
      @change="changeTabBar"
      v-model="active"
      active-color="var(--theme-filter-active-color)"
      inactive-color="var(--theme-btm-def-color)"
      z-index="9"
      :before-change="beforechange"
    >
      <van-tabbar-item icon="home-o">
        <template #icon="props">
          <div v-if="!props.active" class="Inicio tabBarIcon"></div>
          <div v-if="props.active" class="InicioAct tabBarIcon"></div>
        </template>
        {{ $t('start') }}</van-tabbar-item
      >
      <van-tabbar-item icon="point-gift-o" :badge="redPointInfo.activity&&redPointInfo.activity.total_count>0?redPointInfo.activity.total_count:''">
        <template #icon="props">
          <div v-if="!props.active" class="Promoção tabBarIcon"></div>
          <div v-if="props.active" class="PromoçãoAct tabBarIcon"></div>
        </template>
        {{ $t('promotion') }}

        <!-- <template slot="title" v-if="redPointInfo.activity">
           
           <van-badge :content="redPointInfo.activity.total_count"> Promoção </van-badge>
        </template>
        <template slot="title" v-else>
          Promoção
        </template> -->
        </van-tabbar-item
      >
      <van-tabbar-item v-if="!token" @click="login(1)">
        <template #icon="props">
          <div class="login tabBarIcon"></div>
        </template>
        {{ $t('login') }}</van-tabbar-item
      >
      <van-tabbar-item v-if="!token" @click="login(0)">
        <template #icon="props">
          <div class="register tabBarIcon"></div>
        </template>
        {{ $t('register') }}</van-tabbar-item
      >
      <van-tabbar-item @click="showSheet = true" v-if="token" icon="paid">
        <template #icon="props">
          <div class="Depósito tabBarIcon"></div>
        </template>
        {{ $t('deposit') }}</van-tabbar-item
      >
      <van-tabbar-item @click="toSaque" v-if="token" icon="coupon-o">
        <template #icon="props">
          <div class="Saque tabBarIcon"></div>
        </template>
        {{ $t('withdraw') }}</van-tabbar-item
      >
      <van-tabbar-item icon="user-o">
        <template #icon="props">
          <div v-if="!props.active" class="Perfil tabBarIcon"></div>
          <div v-if="props.active" class="PerfilAct tabBarIcon"></div>
        </template>
        {{ $t('profile') }}</van-tabbar-item
      >
    </van-tabbar>

    <van-action-sheet v-model="showSheet" :title="$t('deposit')" :closeable="false">
      <div class="back" @click="showSheet = false">
        <svg-icon
          icon-class="comm_icon_fh"
          style="
            width: 1em;
            height: 1em;
            color: var(--theme-text-color);
            fill: var(--theme-text-color-lighten);
          "
        ></svg-icon>
      </div>
      <div class="top">
        <span @click="showRegistro = true">{{ $t('recharge record') }}</span>
      </div>
      <div class="content">
        <div class="online">
          <ul class="iconbox">
            <li class="icon">
              <div
                style="
                  font-size: 0.24rem;
                  -ms-flex-align: center;
                  -ms-flex-pack: center;
                  align-items: center;
                  display: -ms-flexbox;
                  display: flex;
                  height: 100%;
                  justify-content: center;
                  width: 100%;
                "
              >
                <i class="anticon">
                  <svg-icon
                    icon-class="cz_zxcz1"
                    style="
                      width: 1em;
                      height: 1em;
                      font-size: 0.03rem;
                      color: rgb(255, 252, 209);
                      fill: currentcolor;
                      font-size: 0.38rem;
                    "
                  ></svg-icon>
                </i>
                <span
                  style="
                    margin-left: 0.1rem;
                    text-align: center;
                    white-space: nowrap;
                  "
                >
                  {{ $t('online deposit') }}</span
                >
              </div>
            </li>
          </ul>

          <div class="pay">
            <ul class="payul">
              <li class="payitem">
                <dl class="paybody">
                  <dt>
                    <i class="anticon">
                      <img
                        src="@/assets/icon_normal_pix.png"
                        style="width: 0.44rem; height: 0.44rem"
                    /></i>
                  </dt>
                  <dd>Pay</dd>
                </dl>
                <p class="activate">
                  <i
                    class="gou"
                    style="
                      display: inline-flex;
                      justify-content: center;
                      align-items: center;
                    "
                  >
                    <svg-icon
                      icon-class="comm_icon_gou"
                      focusable="false"
                      aria-hidden="true"
                      style="width: 1em; height: 1em"
                    ></svg-icon
                  ></i>
                  <i
                    class="bg"
                    style="
                      display: inline-flex;
                      justify-content: center;
                      align-items: center;
                    "
                  >
                    <svg-icon
                      icon-class="comm_img_corner"
                      focusable="false"
                      aria-hidden="true"
                    ></svg-icon>
                  </i>
                </p>
              </li>
              <!-- <div class="aliPay">
            <div class="aliPayBtn">
              <img src="../../assets/img_zfb.png" />
              aliPay
              <div class="showUsed">
                <i class="el-icon-check"></i>
              </div>
            </div>
          </div> -->
            </ul>
          </div>
        </div>
        <div class="numList">
          <div class="title">{{ $t('deposit') }}</div>
          <ul class="payNumBox">
            <li
              @click="
                () => {
                  num = item;
                }
              "
              v-for="(item, index) in numList"
              :key="index"
              class="numBox"
              :class="item == num ? 'numBox_active' : ''"
            >
              <div>{{ item }}</div>
            </li>
          </ul>

          <div class="ipt">
            <van-field
              class="inputMoney"
              v-model="num"
              :clearable="true"
              type="digit"
              :placeholder="$t('money limit',{n1:10,n2:50000})"
            >
              <template slot="label">
                <span class="label">R$</span>
              </template>
            </van-field>
          </div>
          <div class="payBtn">
            <van-button
              :loading="payloading"
              @click="goTestPay"
              size="large"
              color="var(--theme-primary-font-color)"
              :block="true"
              :hairline="true"
              :plain="true"
              type="primary"
              style="
                border-radius: 0.14rem;
                font-size: 0.24rem;
                height: 0.7rem;
                width: 100%;
                background-color: var(--theme-primary-color);
                color: var(--theme-primary-font-color);
                text-align: center;
                border-color: var(--theme-primary-color);
                padding: 0 0.1rem;
              "
            >
              {{ $t("recharge now") }}</van-button
            >
          </div>
        </div>
      </div>
    </van-action-sheet>
    <van-action-sheet
      v-model="showRegistro"
      :title="$t('recharge record')"
      :closeable="false"
      :append-to-body="true"
    >
      <div class="recordBox">
        <div class="back" @click="showRegistro = false">
          <svg-icon
            icon-class="comm_icon_fh"
            style="
              width: 1em;
              height: 1em;
              color: var(--theme-text-color);
              fill: var(--theme-text-color-lighten);
            "
          ></svg-icon>
        </div>
        <div class="record-menu">
          <mySelect
            :items="dateItems"
            class="dayselect"
            :filterable="true"
            :selectItem.sync="dateselectItem"
            @change="itemChange"
            style="width: 1.6rem !important"
          ></mySelect>
          <div class="allPayum">
            {{ $t('total deposit') }}
            <span>R$ {{ formatNumberToString(total_deposit) }}</span>
          </div>
        </div>

        <div class="payrecord">
          <van-empty
            v-if="list.length < 1"
            :image="require('@/assets/azf5f-28vaj.png')"
            :description="$t('no records')"
          />

          <van-list v-model="loading" :finished="finished" v-else>
            <div class="sticky" v-for="(item, index) in list">
              <div class="payRecordItem">
                <div class="itemLeft"><p class="paySupplier">Pay</p></div>
                <div class="itemright"><p class="">R${{ formatNumberToString(item.amount) }}</p></div>
              </div>
              <div class="payRecordItem">
                <div class="itemLeft">
                  <p class="payOrderNo">
                    {{ formatDateTimeVirar(item.timestamp) }}
                    <span style="margin: 0 0.1rem">{{ item.order_id }}</span>
                    <span class="copyIcon" v-copy="item.order_id">
                      <svg-icon
                        icon-class="comm_icon_copy"
                        style="width: 1em; height: 1em; fill: currentColor"
                      ></svg-icon>
                    </span>
                  </p>
                </div>
                <div class="itemright">
                  <p style="font-size: 0.22rem">
                    <span :class="
                    {
                      isGreen:item.state===2,
                      isRed:item.state===3||item.state===4||item.state===5||item.state===6,
                      isOrange:item.state===1||item.state===0
                    }
                    
                    ">{{ getStatusType(item.state) }}</span>
                  </p>
                </div>
              </div>
            </div>
          </van-list>
        </div>
      </div>
    </van-action-sheet>
    <fullDialog :title="$t('deposit')" :dialogTableVisible.sync="dialogTableVisible"
      ><payBox
        :payInfo="payinfo"
        @refreshOrderState="refreshOrderState"
      ></payBox
    ></fullDialog>
    <myDialog
      :open.sync="opendownload"
      :originalClose="false"
      width="6rem"
      Title=""
    >
      <div style="text-align: center">
        <div>
          <div
            style="
              -ms-flex-align: center;
              align-items: center;
              display: -ms-flexbox;
              display: flex;
            "
          >
            <img src="@/assets/appIconBig.png" style="width: 1.2rem" />
          </div>
          <div
            style="
              display: -ms-flexbox;
              display: flex;
              -ms-flex-wrap: wrap;
              flex-wrap: wrap;
              margin-top: 0.67rem;
              margin-bottom: 0.67rem;
            "
          >
            <van-button @click="downapp" class="normal-button"
              >{{ $t('normal version') }}</van-button
            >
          </div>
        </div>
      </div>
    </myDialog>
  </div>
</template>
<script>
// import { getToken } from '@/utils/auth';
import mySelect from "@/components/phone/common/mySelect.vue";
import payBox from "../../components/phone/payBox.vue";
import fullDialog from "../../components/phone/common/fullDialog.vue";
import myDialog from "../../components/phone/common/myDialog.vue";
import qrcode from "qrcode";
import { dePositPix, refreshOrderState, depositGetRecords } from "@/api/index";
import { mapActions, mapState } from "vuex";
import { formatDateTimeVirar, formatNumberToString2 } from "@/utils/utils";
export default {
  name: "",
  components: {
    fullDialog,
    payBox,
    myDialog,
    mySelect,
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
      token: (state) => state.user.token,
      redPointInfo:(state)=>state.redPoint.redPointInfo
    }),
  },
  mixins: [],
  props: {
    tabBarActive: {
      default: 0,
    },
  },
  data() {
    return {
      loading: false,
      pageIndex: 0,
      pageSize: 50,
      finished: false,
      beforechange: this.beforechange2,
      list: [],
      type: 1,
      active: this.tabBarActive,
      showSheet: false,
      numList: [20, 50, 100, 300, 500, 1500, 3000, 5000],
      num: 20,
      total_deposit: 0,
      showRegistro: false,
      imgurl: "",
      dialogTableVisible: false,
      payinfo: {},
      payloading: false,
      postCount: 0,
      opendownload: false,
      dateselectItem: "today",
      dateItems: [
        { label: this.$t('today'), value: "today" },
        { label: this.$t('yesterday'), value: "yesterday" },
        { label: this.$t('Last 7 Days'), value: "last_week" },
        { label: this.$t('Last 15 Days'), value: "last_half_month" },
        { label: this.$t('Last 30 Days'), value: "last_month" },
        { label: this.$t('all'), value: "all" },
      ],
    };
  },
  activated() {
    this.pageIndex = 0;
  },
  watch: {},
  methods: {
    ...mapActions(["triggerDialog"]),
    beforechange2(active) {
      this.dialogTableVisible = false;
      if (active >= 2 && !this.token) {
        this.login(1);
        return false;
      }
      return true;
    },
    refreshOrderState() {
      refreshOrderState({ order_no: this.payinfo.order_no }).then(
        ({ data }) => {
          this.payinfo.state = data.data.state;
        }
      );
    },
    getStatusType(t) {
      switch (t) {
        case 0:
          return this.$t('generation of orders');
        case 1:
          return this.$t('payment');
        case 2:
          return this.$t('successful payment');
        case 3:
          return this.$t('payment failure');
        case 4:
          return this.$t('revoked');
        case 5:
          return this.$t('refund');
        case 6:
          return this.$t('closure of orders');
      }
    },
    downapp() {
      const apk = this.$store.getters.download.apk;
      window.open(apk);
    },
    goTestPay() {
      const self = this;
      this.payloading = true;
      // qrcode.toDataURL("ooxxx",(err,url)=>{
      //   if(!err){
      //     this.imgurl=url;
      //   }
      //  });
      // let info = {
      //   success: true,
      //   order_no: "string",
      //   amount: 0,
      //   pay_no: "string",
      //   qr_code: "string",
      //   create_timestamp: 0,
      //   state: 0,
      //   amount2:'100',
      //   qrImg:''
      // };
      // this.payinfo=info;
      // this.showSheet = false;
      // this.dialogTableVisible = true;
      // return;
      dePositPix({ amount: this.num * 100 }).then(({ data }) => {
        if (data.code === 0) {
          let info = data.data;
          qrcode.toDataURL(info.qr_code, (err, url) => {
            if (!err) {
              info.qrImg = url;
              info.amount2 = "R$" + info.amount / 100;
              this.payinfo = info;
              this.showSheet = false;
              this.dialogTableVisible = true;
              this.payloading = false;
              self.postCount = 0;
              const c = setInterval(function () {
                self.refreshOrderState();
                self.postCount = self.postCount + 1;
                if (self.postCount > 60) {
                  self.dialogTableVisible = false;
                  clearInterval(c);
                }
                if (self.payinfo.state === 2) {
                  self.dialogTableVisible = false;
                  clearInterval(c);
                  self.$emit("change");
                  self.$toast.success(this.$t('successful payment'));
                }
              }, 2000);
            }
          });
        }
      });

      //  return;
      // testPay({ amount: this.num }).then(({ data }) => {
      //   self.showSheet = false;
      // });
    },

    changeTabBar(active) {
      this.dialogTableVisible = false;
      if (active === 0) {
        this.$router.push({
          path: "/",
        });
      } else if (active === 1) {
        this.$router.push({
          path: "/Promotion",
        });
      } else if (active === 4) {
        this.$router.push({
          path: "/mine",
        });
      }
    },
    login(type) {
      this.type = type;
      // const app = this.$root;
      // console.log(this.$root);
      this.triggerDialog(type);
      // if (app && app.showLoginDialog) {
      //   app.showLoginDialog(type);
      // }
      // this.dialogLogin = true;
      // this.$root.showLoginDialog(type);
    },

    showPayBox() {
      this.showSheet = true;
    },
    download(i) {
      this.opendownload = true;
    },
    initBar(i) {
      this.active = i;
    },
    show() {
      this.showSheet = true;
    },
    toSaque() {
      this.$router.push({
        path: "/saque",
      });
    },
    itemChange() {
      this.pageIndex = 0;
      this.finished = false;
      this.list = [];
      this.getList();
    },
    onLoad() {
      this.pageIndex = this.pageIndex + 1;
      this.getList();
    },
    formatDateTimeVirar(v) {
      return formatDateTimeVirar(new Date(v * 1000));
    },
    formatNumberToString(v) {
      return formatNumberToString2(v);
    },
    getList() {
      depositGetRecords({
        time_rand: this.dateselectItem === "all" ? "" : this.dateselectItem,
        page: this.pageIndex,
        count: this.pageSize,
      }).then(({ data }) => {
        if (data.code === 0) {
          if (data.data.records.length > 0) {
            this.list = this.list.concat(data.data.records);
          }

          if (data.data.records.length < this.pageSize) {
            this.finished = true;
          }
          this.total_deposit = data.data.total;
          // this.total_withdraw = data.data.total_withdraw;
          this.loading = false;

          // this.$emit("change", data.data);
        }
      });
    },
  },
};
</script>
<style type="text/less" lang="less" scoped>
.qrcode {
  text-align: center;
}
.van-tabbar {
  background: var(--theme-btm-bg-color);
}

.van-tabbar-item--active {
  background: var(--theme-btm-bg-color);
}
.van-popup--bottom.van-popup--round {
  border-radius: 0.2rem 0.2rem 0 0 !important;
}
.tabBarIcon {
  width: 0.5rem;
  height: 0.5rem;
  background: url("../../assets/814195b6-3e29-450b-8c2b-bdb30e15091f.png");
  background-size: 10.3714rem 10.1214rem;
  display: inline-block;
  line-height: 1;
}
.login {
  background-position: -3.67143rem -5.95714rem;
}
.Inicio {
  background-position: -4.94286rem -6.47143rem;
}
.InicioAct {
  background-position: -5.45714rem -6.47143rem;
}
.Promoção {
  background-position: -7.28571rem -3.08571rem;
}
.PromoçãoAct {
  background-position: -7.28571rem -3.6rem;
}
.Depósito {
  background-position: -3.15714rem -5.95714rem;
}
.Saque {
  background-position: -6.48571rem -6.47143rem;
}
.Perfil {
  background-position: -7.28571rem -1.02857rem;
}
.PerfilAct {
  background-position: -7.28571rem -1.54286rem;
}
.register {
  background-position: -7.28571rem -4.11429rem;
}
// /deep/.van-icon {
//     font-size: 7vw;
// }
/deep/.van-tabbar-item__text {
  font-size: 0.24rem;
}
.recordBox {
  .record-menu {
    -ms-flex-align: center;
    -ms-flex-pack: justify;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    margin: 0.2rem;
    .dayselect {
      margin: 0;
    }
    .allPayum {
      color: var(--theme-text-color-lighten);
      font-size: 0.26rem;
      span {
        color: var(--theme-text-color-darken);
        margin-left: 0.08rem;
      }
    }
  }
  .payrecord {
    -ms-flex-pack: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    height: calc(var(--vh, 1vh) * 100 - 2.55rem);
    justify-content: center;
    overflow: hidden;
    position: relative;
    font-size: 0.16rem;
    /deep/.van-list {
      -ms-flex-positive: 1;
      flex-grow: 1;
      margin-right: 0.07rem;
      overflow: scroll;
      padding: 0 0.13rem 0 0.2rem;
      > div {
        border-radius: 0.1rem;
        padding: 0.2rem;
        .van-list__placeholder {
          background-color: transparent !important;
        }
      }
      > div:nth-child(odd) {
        background-color: var(--theme-main-bg-color);
      }
      .sticky {
        padding: 0.15rem 0.2rem !important;

        .payRecordItem {
          -ms-flex-pack: justify;
          cursor: pointer;
          justify-content: space-between;
          padding: 0.05rem 0;
          -ms-flex-align: center;
          align-items: center;
          display: -ms-flexbox;
          display: flex;
          .itemleft {
            -ms-flex-negative: 0;
            border-radius: 0.1rem;
            flex-shrink: 0;
            height: 0.4rem;
            margin-right: 0.15rem;
            width: 0.4rem;
          }
          .itemright {
            color: var(--theme-text-color-darken);
            text-align: right;
            max-width: 2.2rem;
          }

          .paySupplier {
            font-size: 0.24rem;
            max-width: 4rem;
            color: var(--theme-text-color-darken);
          }
          .payMoney {
            color: var(--theme-text-color-darken);
            font-weight: 700;
            font-size: 0.26rem;
          }
          .payOrderNo {
            color: var(--theme-text-color-lighten);
            font-size: 0.2rem;
          }
        }
      }
    }
  }
}

.content {
  padding: 0.2rem 0.3rem 0;
  .online {
    .iconbox {
      border-bottom: thin solid var(--theme-color-line);
      //   margin: 0 0.3rem;
      //   border-radius: .1rem .1rem 0 0;
      .icon {
        border-bottom: 0.04rem solid var(--theme-primary-color);
        height: 0.72rem;
        font-size: 0.22rem;
        margin-right: 0 !important;
        color: var(--theme-primary-color);
        width: min-content;
      }
    }
    .pay {
      height: 1rem;
      margin-top: 0.2rem;
      .payul {
        border-bottom: thin solid var(--theme-color-line);
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        padding-bottom: 0;
        .payitem {
          position: relative;
          height: 0.7rem;
          margin: 0 3.5% 0.2rem 0;
          width: 31%;
          border: thin solid var(--theme-filter-active-color);
          background-color: var(--theme-main-bg-color);
          border-radius: 0.1rem;
          .paybody {
            -ms-flex-align: center;
            align-items: center;
            display: -ms-flexbox;
            display: flex;
            height: 100%;
            width: 100%;
            margin-bottom: 0;
            margin-top: 0;
            dt {
              border-radius: 0.05rem;
              height: 0.44rem;
              margin-left: 0.08rem;
              margin-right: 0.03rem;
              overflow: hidden;
              width: 0.44rem;
              font-weight: 500;
            }
            dd {
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              -ms-flex-align: center;
              -ms-flex-pack: center;
              align-items: center;
              color: var(--theme-text-color-darken);
              display: -webkit-box;
              -ms-flex: 1;
              flex: 1;
              font-size: 0.18rem;
              justify-content: center;
              margin: 0;
              overflow: hidden;
              text-align: center;
              text-overflow: ellipsis;
              vertical-align: middle;
              word-break: break-all;
              color: var(--theme-primary-color);
              font-size: 0.22rem;
              line-height: 0.24rem;
            }
          }
          .activate {
            bottom: -0.01rem;
            display: block;
            height: 0.3rem;
            line-height: 0.3rem;
            overflow: hidden;
            position: absolute;
            right: -0.01rem;
            width: 0.3rem;
            .gou {
              bottom: 0.04rem;
              color: var(--theme-active-gou-color);
              color: var(--theme-web_filter_gou);
              height: 0.096rem;
              position: absolute;
              right: 0.04rem;
              width: 0.125rem;
            }
            .bg {
              color: var(--theme-filter-active-color);
              height: 100%;
              width: 100%;
              font-size: 14px;
            }
          }
        }
      }
    }
    // .aliPay {
    //   padding: 5vw 0;
    //   // border-top: 1px solid #D5DDFF;
    //   border-bottom: thin solid var(--theme-color-line);
    //   margin: 0 0 5vw;
    //   font-size: 0.22rem;
    //   .aliPayBtn {
    //     border: 1px solid #fffcd1;
    //     display: flex;
    //     align-items: center;
    //     justify-content: space-around;
    //     border-radius: 1vw;
    //     padding: 2vw 0;
    //     color: #fff;
    //     width: 30vw;
    //     position: relative;
    //   }
    //   img {
    //     width: 10vw;
    //   }
    // }
  }
  .numList {
    padding: 0.4rem 0;
    .title {
      color: var(--theme-text-color-darken);
      margin-bottom: 0.2rem !important;
      flex: none;
      font-size: 0.24rem;
    }
    .payNumBox {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 0;
      list-style: none;
      margin: 0;
      padding: 0;
      .numBox {
        -ms-flex-align: center;
        -ms-flex-pack: center;
        align-items: center;
        background-color: var(--theme-main-bg-color);
        border: thin solid var(--theme-color-line);
        border-radius: 0.1rem;
        color: var(--theme-text-color);
        cursor: pointer;
        display: -ms-flexbox;
        display: flex;
        // font-size: 0.18rem;
        // height: 0.6rem;
        justify-content: center;
        margin: 0 2.1% 0.2rem 0;
        position: relative;
        text-align: center;
        width: 22.375%;
        word-break: break-all;
        font-size: 0.22rem;
        height: 0.7rem;
      }
      .numBox_active {
        border: thin solid var(--theme-filter-active-color);
        color: var(--theme-primary-color);
      }
    }

    .ipt {
      width: 100%;
      position: relative;
      margin-top: 2vw;
      .inputMoney:hover {
        border-color: #837b10;
      }
      .inputMoney {
        border: 1px solid #d9d9d9;
        background-color: var(--theme-main-bg-color);
        border-color: var(--theme-ant-primary-color-13);
        border-right-width: 1px !important;
        color: var(--theme-text-color-darken);
        border-radius: 0.1rem;
        /deep/.van-field__label {
          width: auto;
        }
        /deep/.van-field__control {
          color: var(--theme-text-color-darken);
          font-size: 0.24rem;
        }
        /deep/.van-field__body {
          height: 100%;
        }
        .label {
          font-size: 0.3rem;
          color: var(--theme-text-color-darken);
          line-height: 1.5;
          width: auto;
        }
      }
    }

    .payBtn {
      padding: 0 0.3rem;
      padding-top: 0.3rem;
    }
  }
}

/deep/.van-action-sheet {
  max-height: calc(var(--vh, 1vh) * 100);
  height: calc(var(--vh, 1vh) * 100);
  background-color: var(--theme-main-bg-color);
  // max-width: 100vw !important;
  width: var(--theme-max-width);
  top: 0.9rem;
}

/deep/.van-action-sheet__header {
  color: var(--theme-text-color-darken);
}
.back {
  color: var(--theme-text-color);
  font-size: 0.24rem;
  left: 0.24rem;
  position: absolute;
  top: 0.24rem;
}
.top {
  // padding: .2rem .3rem;
  position: absolute;
  font-size: 0.24rem; // top: 3.7vw;
  // width: 90vw;
  display: flex;
  justify-content: space-between;
  // padding: 0 5vw;
  // font-size: 3vw;
  color: var(--theme-primary-color);
  align-items: center;
  top: 0.15rem;
  line-height: 0.5rem !important;
  word-wrap: break-word;
  text-align: center;
  height: 0.5rem;
  right: 0.3rem;
  // width: 100%;
  // position: relative;
}
/deep/.el-input__inner {
  background: var(--theme-main-bg-color);
  color: #fff;
}
/deep/.van-tabbar {
  height: 1.24rem;
}
.dayselect {
  max-width: 3.15rem;
  min-width: 1.6rem;
  width: auto !important;
  margin-right: 0.2rem;
}
</style>
