<template>
  <section class="login-two-verify-main">
    <div class="login-two-verify-step-box">
      <mySteps :current="step">
        <myStep
          :title="$t('id verification')"
          :status="getStatus(0)"
          :index="0"
        ></myStep>
        <myStep title="Terminar" :status="getStatus(1)" :index="1"></myStep>
      </mySteps>
    </div>
    <div class="login-two-verify-info">
      <div class="login-two-verify-box">
        <myScrollbarBar st="y" style="width: 100%; height: 100%">
          <div>
            <el-form
              :model="verifyForm"
              :rules="rules"
              class="verifyForm"
              ref="verifyForm"
            >
              <el-form-item>
                <el-input
                  class="login-account-input"
                  :disabled="true"
                  Maxlength="14"
                  :value="loginInfo.account"
                >
                </el-input>
              </el-form-item>

              <div class="login-two-verify-google-code-box">
                <div
                  style="
                    background-color: var(--theme-color-line);
                    height: 0.02rem;
                    margin-bottom: 0.3rem;
                    width: 100%;
                  "
                ></div>
                <div>
                  <div class="login-two-google-title">
                    <div class="toggle">
                      <span class="google-auth-title"
                        >{{ $t('google verification') }}</span
                      >
                    </div>
                  </div>

                  <el-form-item
                    style="margin-bottom: 0.5rem"
                    prop="google_code"
                  >
                    <el-input
                      class="login-google-auth-input"
                      maxlength="6"
                      type="tel"
                      @input="
                        (v) => {
                          verifyForm.google_code = v.replace(/\D/g, '');
                        }
                      "
                      v-model="verifyForm.google_code"
                      :placeholder="
                        $t('google auth tip6')
                      "
                    >
                      <template slot="prefix">
                        <i class="anticon">
                          <i
                            aria-hidden="true"
                            focusable="false"
                            class="login-two-googleico"
                            style=""
                          ></i>
                        </i>
                      </template>
                      <template slot="suffix">
                        <span
                          style="
                            color: var(--theme-primary-color);
                            cursor: pointer;
                            font-size: 0.22rem;
                          "
                          @click="verifyForm.google_code = ''"
                          >{{ $t('paste') }}</span
                        >
                      </template>
                    </el-input>
                    <template slot="error" slot-scope="scope">
                      <div class="err-explain">
                        {{ scope.error }}
                      </div>
                    </template>
                  </el-form-item>
                </div>
              </div>
            </el-form>
          </div>
        </myScrollbarBar>
        <div style="text-align: center">
          <van-button
            @click="submitVerify"
            class="highlight-button"
            style="width: calc(100% - 0.6rem)"
            >{{ $t('confirm') }}</van-button
          >
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import mySteps from "@/components/phone/common/mySteps.vue";
import myStep from "@/components/phone/common/myStep.vue";
import myScrollbarBar from "@/components/phone/common/myScrollbarBar.vue";
import { mapState } from "vuex";
import { getBrowser, getOperatingSystem } from "@/utils/utils";
import LocalStorageCache from "@/utils/localStorageCache.js";

export default {
  name: "twoVerify",
  components: { mySteps, myStep, myScrollbarBar },
  mixins: [],
  data() {
    return {
      verifyForm: {
        google_code: "",
        device_id: "",
        device_info: "",
      },
      rules: {
        google_code: [
          {
            required: true,
            message: this.$t(
              "googlecample empty"
            ),
            trigger: "blur",
          },

          {
            validator: (rule, value, callback) => {
              const numberRegex = /^\d+$/;
              if (!numberRegex.test(value)) {
                callback(
                  new Error(this.$t("please enter a valid number"))
                );
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
      },
      step: 0,
    };
  },
  computed: {
    ...mapState({
      loginInfo: (state) => state.login.loginInfo,
    }),
  },
  watch: {},

  mounted() {
    this.verifyForm.device_id = LocalStorageCache.get("UniqueFingerprint");
    this.verifyForm.device_info = getOperatingSystem() + "|" + getBrowser();
  },
  methods: {
    getStatus(index) {
      if (index === 1 && this.step === 1) {
        return "js";
      } else if (index <= this.step) {
        return "ok";
      }
    },
    closeDialog() {
      this.$emit("closeDialog");
    },
    submitVerify() {
      this.$refs.verifyForm.validate((valid) => {
        if (valid) {
          this.$store.dispatch("twoFactorLogin", {
            account_number: this.loginInfo.accountNumber,
            two_factor_verify_token: this.loginInfo.two_factor_verify_token,
            code: this.verifyForm.google_code,
            device_id: this.verifyForm.device_id,
            device_info: this.verifyForm.device_info,
          }).then(({data})=>{
           
            this.closeDialog();
          }).catch((msg)=>{
              this.$notify({
              duration: 2000,
              message: msg,
              type: "error",
              background: "#fff",
              offset: "3",
            });
           
          });
        }
      });
    },
  },
};
</script>
