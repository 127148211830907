<template>
  <div>
    <div
      ref="dateBox"
      class="dateBox"
      @click="
        () => {
          showPopup = true;
        }
      "
      :style="{ width: width }"
    >
      <div class="startDateBox">
        <div>
          <van-field v-model="startDateText" :readonly="true" />
        </div>
      </div>
      <div class="endDateBox">
        <div>
          <van-field v-model="endDateText" :readonly="true"></van-field>
        </div>
      </div>
    </div>

    <div v-if="showPopup" class="dateSelectBox">
      <div class="worktop">
        <div class="left">
          <h5 class="title">{{ $t('start date') }}</h5>
          <van-datetime-picker
            type="date"
            :columns-type="columnsType"
            :min-date="startMinDate"
            :max-date="startMaxDate"
            :show-toolbar="false"
            visible-item-count="3"
            item-height="35"
            v-model="startcurrentDate"
            @change="startDateSelect"
          />
          <van-button class="btn" @click="cancel" :plain="true">Cancelar</van-button>
        </div>
        <div class="line"></div>
        <div class="right">
          <h5 class="title">{{ $t('end date') }}</h5>
          <van-datetime-picker
            type="date"
            :columns-type="columnsType"
            :min-date="endMinDate"
            :max-date="endMaxDate"
            :show-toolbar="false"
            visible-item-count="3"
            item-height="35"
            v-model="endcurrentDate"
            @change="endDateSelect"
          />
          <van-button @click="determine" class="btn" :plain="true">Confimar</van-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { formatDateVirar, addDaysToDate } from "@/utils/utils";

export default {
  name: "dateSelect",
  props: {
    width: String,
    startDate: Date,
    endDate: Date,
  },
  data() {
    return {
      startDateText: "28/12/2023",
      endDateText: "28/12/2024",
      showPopup: false,
      startMinDate: new Date(2023, 0, 1),
      startMaxDate: new Date(),
      endMinDate: new Date(2023, 0, 1),
      endMaxDate: new Date(),
      columnsType: ["year", "month", "day"],
      startcurrentDate: new Date(),
      endcurrentDate: new Date(),
    };
  },
  created() {},
  mounted() {
    // const currentDate = new Date();

    if (this.startDate) {
      this.startcurrentDate = this.startDate;
    }
    if (this.endDate) {
      this.endcurrentDate = this.endDate;
    }
    this.endMinDate = this.startcurrentDate;
    this.fushDateShow();

  },
  destroyed() {},
  methods: {
    determine() {
      this.showPopup = !!0;
      this.$emit("update:startDate", this.startcurrentDate);
      this.$emit("update:endDate", this.endcurrentDate);
      this.$emit("change", this.startcurrentDate, this.endcurrentDate);
    },
    cancel() {
      this.showPopup = !!0;
    },
    fushDateShow() {
      this.startDateText = formatDateVirar(this.startcurrentDate);
      this.endDateText = formatDateVirar(this.endcurrentDate);
    },
    startDateSelect() {
      this.endMinDate = this.startcurrentDate;
      if (this.endcurrentDate < this.startcurrentDate) {
        this.endcurrentDate = this.startcurrentDate;
      }
      const maxDate = addDaysToDate(this.startcurrentDate, 90);
      if (maxDate > new Date()) {
        this.endMaxDate = new Date();
      } else {
        this.endMaxDate = addDaysToDate(this.startcurrentDate, 90);
      }
      this.fushDateShow();
    },
    endDateSelect() {
      this.fushDateShow();
    },
  },
};
</script>
<style type="text/less" lang="less" scoped>
.dateBox {
  -ms-flex-align: center;
  -ms-flex-pack: justify;
  align-items: center;
  background-color: var(--theme-main-bg-color);
  border: 0.01rem solid var(--theme-color-line);
  border-radius: 0.25rem;
  display: -ms-flexbox;
  display: flex;
  height: 0.5rem;
  justify-content: space-between;
  overflow: hidden;
  padding: 0 0.15rem;
  position: relative;
  font-size: 0.2rem;
  width: 3.6rem;
  .startDateBox {
    -ms-flex-align: center;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    height: 0.5rem;

    width: 1.6rem;
    /deep/.van-cell {
      font-size: 0.2rem;
      height: 0.5rem;

      padding: 0.04rem;
      padding-left: 0.2rem;
      text-align: center;
      border: none;
      color: var(--theme-text-color-lighten);
      touch-action: manipulation;
      background: none;
      .van-field__control {
        color: var(--theme-text-color-lighten);
        text-align: center;
      }
    }
  }
  .endDateBox {
    -ms-flex-align: center;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    height: 0.5rem;
    width: 1.6rem;
    /deep/.van-cell {
      font-size: 0.2rem;
      height: 0.5rem;

      padding: 0.04rem;
      padding-right: 0.2rem;
      text-align: center;
      border: none;
      color: var(--theme-text-color-lighten);
      touch-action: manipulation;
      background: none;
      .van-field__control {
        color: var(--theme-text-color-lighten);
        text-align: center;
      }
    }
  }
  .endDateBox::after {
    background-color: var(--theme-text-color-lighten);
    content: "";
    height: 0.03rem;
    left: 50%;
    position: absolute;
    top: 50%;
    -webkit-transform: var(--theme-color-line);
    transform: var(--theme-color-line);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 0.11rem;
    z-index: 1;
  }
}
.dateSelectBox {
  left: 0;
  position: absolute;
  right: 0;
  top: 0.52rem;
  width: 7rem;
  .worktop {
    -ms-flex-align: center;
    -ms-flex-pack: center;
    align-items: center;
    background-color: var(--theme-main-bg-color);
    border: 0.01rem solid var(--theme-color-line);
    border-radius: 0.14rem;
    -webkit-box-shadow: 0 0.04rem 0.12rem 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 0.04rem 0.12rem 0 rgba(0, 0, 0, 0.1);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: -ms-flexbox;
    display: flex;
    height: 4.8rem;
    justify-content: center;
    padding: 0.2rem;
    width: 100%;
    .left {
      -ms-flex-align: center;
      align-items: center;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      width: 50%;
      .title {
        color: var(--theme-text-color);
        font-size: 0.24rem;
        font-weight: 700;
        margin-bottom: 0;
      }
      /deep/.van-datetime-picker {
        width: 100%;
      }
      .btn {
        border: 0.01rem solid var(--theme-primary-color);
        font-size: 0.2rem;
        height: 0.5rem;
        line-height: 0.24rem;
        width: 1.2rem;
        border-radius: 0.14rem;
        padding: 0;
        color: var(--theme-primary-color);
        background-color: transparent;
      }
    }
    .line {
      border-left: 0.01rem solid var(--theme-color-line);
      height: 6.2rem;
      margin: 0 0.3rem;
      -webkit-transform: scale(0.5);
      transform: scale(0.5);
      -webkit-transform-origin: center;
      transform-origin: center;
      width: 0.01rem;
    }
    .right {
      -ms-flex-align: center;
      align-items: center;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      width: 50%;
      .title {
        color: var(--theme-text-color);
        font-size: 0.24rem;
        font-weight: 700;
        margin-bottom: 0;
      }
      /deep/.van-datetime-picker {
        width: 100%;
      }
      .btn {
        border: 0.01rem solid var(--theme-primary-color);
        font-size: 0.2rem;
        height: 0.5rem;
        line-height: 0.24rem;
        width: 1.2rem;
        background-color: var(--theme-primary-color);
        color: var(--theme-primary-font-color);
        border-radius: 0.14rem;
      }
    }
  }
}
/deep/.van-picker.van-datetime-picker {
  background: none;
  margin: 0.25rem 0;
  //   width: 40vw;
}
/deep/.van-picker__mask {
  background: none !important;
}
/deep/.van-hairline-unset--top-bottom:after {
  border-bottom: 0.01rem solid var(--theme-color-line) !important;
  border-top: 0.05rem solid var(--theme-color-line) !important;
}
/deep/.van-hairline--top-bottom:after,
.van-hairline-unset--top-bottom:after {
  border-width: 1px 0;
}
/deep/.van-picker-column__item {
  color: var(--theme-text-color-lighten) !important;
}
/deep/.van-picker-column__item.van-picker-column__item--selected {
  color: var(--theme-text-color-darken) !important;
}
/deep/.van-ellipsis {
  font-size: 0.24rem;
}
/deep/.van-button::before {
  background-color: transparent;
  border-color: transparent;
}
/deep/.van-field__body{
  height: 0.4rem;
}
</style>
