<template>
  <div class="my-scrollbar my-scrollbar-always">
    <div
      class="my-scrollbar-wrap"
      :class="
        st === 'all'
          ? 'my-scrollbar-wrap-all'
          : this.st === 'y'
          ? 'my-scrollbar-wrap-y'
          : 'my-scrollbar-wrap-x'
      "
    >
      <div class="my-scrollbar-content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "myScrollbarBar",

  mixins: [],
  props: {
    st: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {},
};
</script>
<style lang="less" scoped></style>
