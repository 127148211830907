<template>
  <div class="box">
    <el-container>
      <el-header height=".9rem">
        <van-nav-bar
          @click-left="onClickLeft"
          right-text=" "
          :fixed="true"
          :border="false"
          style="
            max-width: var(--theme-max-width);
            margin: 0 auto;
            left: 0;
            right: 0;
            height: 0.9rem;
          "
        >
          <template #left>
            <div>
              <i style="font-size: 0.24rem">
                <svg-icon
                  icon-class="comm_icon_fh"
                  style="
                    width: 1em;
                    height: 1em;
                    color: var(--theme-text-color);
                    fill: var(--theme-text-color-lighten);
                  "
                ></svg-icon>
              </i>
            </div>
          </template>

          <template #title>
            <div class="title">
              <span class="global-wrapper-title">
                <span
                  style="
                    width: 100%;
                    margin-bottom: -1.64706px;
                    padding-bottom: 1.64706px;
                    font-size: 0.3rem;
                  "
                  ><span
                    style="
                      vertical-align: middle;
                      display: inline-block;
                      width: 100%;
                    "
                  >
                    <span style="-webkit-line-clamp: 2" class="ts inner-text">
                      {{ $t('data') }}</span
                    >
                  </span></span
                >
              </span>
            </div>
          </template>
        </van-nav-bar>
      </el-header>
      <el-main>
        <van-form name="form1">
          <myScrollbarBar st="y">
            <div class="theme-box-shadow">
              <div class="top">
                <div class="left">
                  <van-image
                    class="avatar"
                    :src="userinfo.avatar"
                    :lazy-load="true"
                    fit="fill"
                  >
                    <template v-slot:loading>
                      <div class="imgload"></div>
                    </template>
                  </van-image>
                  <div class="viplvbox">
                    <div style="display: contents" class="viplvbody">
                      <div class="vip-badge">
                        <img
                          :src="require('@/assets/img_vip.png')"
                          class="vip-img"
                        />
                        <span data-text="1">1</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="right">
                  <div class="config" @click="dialogTableVisible = true">
                    {{ $t('config') }}
                  </div>
                  <div class="acbox">
                    ID:<span class="ac">{{ userinfo.account_number }}</span>
                    <span class="copyIcon">
                      <svg-icon
                        icon-class="comm_icon_copy"
                        style="width: 1em; height: 1em; fill: white"
                      ></svg-icon>
                    </span>
                  </div>
                </div>
              </div>
              <van-row style="margin-bottom: 0.5rem">
                <van-col span="24">
                  <van-field :disabled="true" v-model="account">
                    <template slot="left-icon">
                      <i
                        class="anticon"
                        style="
                          height: 0.25rem;
                          width: 0.25rem;
                          font-size: 0.18rem;
                        "
                      >
                        <i
                          style="
                            display: inline-flex;
                            justify-content: center;
                            align-items: center;
                            color: var(--theme-text-color-lighten);
                            height: 0.25rem;
                            width: 0.25rem;
                          "
                        >
                          <svg-icon
                            icon-class="input_icon_zh"
                            style="
                              width: 1em;
                              height: 1em;
                              color: var(--theme-text-color);
                              fill: var(--theme-text-color-lighten);
                              line-height: 1.5;
                            "
                          ></svg-icon>
                        </i>
                      </i>
                    </template>
                  </van-field>
                </van-col>
              </van-row>

              <van-row style="margin-bottom: 0.5rem">
                <van-col span="24">
                  <van-field
                    v-model="wechat"
                    :clearable="true"
                    :placeholder="$t('enter WeChat ID')"
                    style="background-color: var(--theme-main-bg-color)"
                    :clickable="true"
                  >
                    <template slot="left-icon">
                      <i class="anticon">
                        <i
                          style="
                            display: inline-flex;
                            justify-content: center;
                            align-items: center;
                            color: var(--theme-text-color-lighten);
                            height: 0.32rem;
                            width: 0.32rem;
                            font-size: 0.32rem;
                          "
                        >
                          <svg-icon
                            icon-class="input_icon_wechat"
                            style="
                              width: 1em;
                              height: 1em;
                              color: var(--theme-text-color);
                              fill: var(--theme-text-color-lighten);
                            "
                          ></svg-icon>
                        </i>
                      </i>
                    </template>
                  </van-field>
                </van-col>
              </van-row>

              <van-row style="margin-bottom: 0.5rem">
                <van-col span="24">
                  <van-field
                    v-model="whats"
                    :clearable="true"
                    :placeholder="$t('enter WhatsApp ID')"
                    style="background-color: var(--theme-main-bg-color)"
                    :clickable="true"
                  >
                    <template slot="left-icon">
                      <i class="anticon">
                        <i
                          style="
                            display: inline-flex;
                            justify-content: center;
                            align-items: center;
                            color: var(--theme-text-color-lighten);
                            height: 0.32rem;
                            width: 0.32rem;
                            font-size: 0.32rem;
                          "
                        >
                          <svg-icon
                            icon-class="input_icon_whatsapp"
                            style="
                              width: 1em;
                              height: 1em;
                              color: var(--theme-text-color);
                              fill: var(--theme-text-color-lighten);
                            "
                          ></svg-icon>
                        </i>
                      </i>
                    </template>
                  </van-field>
                </van-col>
              </van-row>

              <van-row style="margin-bottom: 0.5rem">
                <van-col span="24">
                  <van-field
                    v-model="facebook"
                    :clearable="true"
                    :placeholder="$t('enter Facebook ID')"
                    style="background-color: var(--theme-main-bg-color)"
                    :clickable="true"
                  >
                    <template slot="left-icon">
                      <i class="anticon">
                        <i
                          style="
                            display: inline-flex;
                            justify-content: center;
                            align-items: center;
                            color: var(--theme-text-color-lighten);
                            height: 0.32rem;
                            width: 0.32rem;
                            font-size: 0.32rem;
                          "
                        >
                          <svg-icon
                            icon-class="input_icon_fb"
                            style="
                              width: 1em;
                              height: 1em;
                              color: var(--theme-text-color);
                              fill: var(--theme-text-color-lighten);
                            "
                          ></svg-icon>
                        </i>
                      </i>
                    </template>
                  </van-field>
                </van-col>
              </van-row>

              <van-row style="margin-bottom: 0.5rem">
                <van-col span="24">
                  <van-field
                    v-model="telegram"
                    :clearable="true"
                    :placeholder="$t('enter Telegram ID')"
                    style="background-color: var(--theme-main-bg-color)"
                    :clickable="true"
                  >
                    <template slot="left-icon">
                      <i class="anticon">
                        <i
                          style="
                            display: inline-flex;
                            justify-content: center;
                            align-items: center;
                            color: var(--theme-text-color-lighten);
                            height: 0.32rem;
                            width: 0.32rem;
                            font-size: 0.32rem;
                          "
                        >
                          <svg-icon
                            icon-class="input_icon_telegram"
                            style="
                              width: 1em;
                              height: 1em;
                              color: var(--theme-text-color);
                              fill: var(--theme-text-color-lighten);
                            "
                          ></svg-icon>
                        </i>
                      </i>
                    </template>
                  </van-field>
                </van-col>
              </van-row>

              <p class="tips">
               {{ $t('birthtips') }}
              </p>
              <van-row style="margin-bottom: 0.5rem">
                <van-col span="24">
                  <van-field
                    class="birthday"
                    v-model="birthday"
                    :style="
                      !userinfo.birthday || userinfo.birthday === ''
                        ? 'background-color: var(--theme-main-bg-color)'
                        : ''
                    "
                    :readonly="true"
                    :placeholder="$('day month year')"
                    @click="
                      () => {
                        if (!userinfo.birthday || userinfo.birthday === '') {
                          showPicker = true;
                        }
                      }
                    "
                  >
                    <template
                      v-if="!userinfo.birthday || userinfo.birthday === ''"
                      slot="right-icon"
                    >
                      <i class="anticon">
                        <i
                          style="
                            display: inline-flex;
                            justify-content: center;
                            align-items: center;
                            color: var(--theme-text-color-lighten);
                            margin: auto 0 auto auto;
                            -webkit-transform: rotate(-90deg);
                            transform: rotate(-90deg);
                            font-size: 0.22rem;
                          "
                        >
                          <svg-icon
                            icon-class="comm_icon_fh"
                            style="
                              width: 1em;
                              height: 1em;
                              color: var(--theme-text-color);
                              fill: var(--theme-text-color-lighten);
                            "
                          ></svg-icon>
                        </i>
                      </i>
                    </template>
                  </van-field>
                </van-col>
              </van-row>
            </div>
          </myScrollbarBar>
        </van-form>
        <van-popup v-model:show="showPicker" class="pop" position="bottom">
          <van-datetime-picker
            type="date"
            :columns-type="columnsType"
            visible-item-count="6"
            v-model="currentDate"
            :title="$t('select date')"
            :confirm-button-text="$t('confirm')"
            :cancel-button-text="$t('cancel')"
            @confirm="onDateConfirm"
            @cancel="showPicker = false"
          />
        </van-popup>
        <div class="wg-fixed-no-desktop bottomMenu">
          <div class="context">
            <van-button class="left H5BottomReturnBtn" @click="onClickLeft"
              >{{ $t('turn back') }}</van-button
            >
            <van-button class="right" @click="onConfirm">{{ $t('save') }}</van-button>
          </div>
        </div>

        <el-dialog
          :show-close="false"
          :fullscreen="true"
          :visible.sync="dialogTableVisible"
        >
          <template slot="title">
            <van-nav-bar
              @click-left="dialogTableVisible = false"
              right-text=" "
              :fixed="true"
              :border="false"
              style="
                max-width: var(--theme-max-width);
                margin: 0 auto;
                left: 0;
                right: 0;
                height: 0.9rem;
              "
            >
              <template #left>
                <div>
                  <i style="font-size: 0.24rem">
                    <svg-icon
                      icon-class="comm_icon_fh"
                      style="
                        width: 1em;
                        height: 1em;
                        color: var(--theme-text-color);
                        fill: var(--theme-text-color-lighten);
                      "
                    ></svg-icon>
                  </i>
                </div>
              </template>

              <template #title>
                <div class="title">
                  <span class="global-wrapper-title">
                    <span
                      style="
                        width: 100%;
                        margin-bottom: -1.64706px;
                        padding-bottom: 1.64706px;
                        font-size: 0.3rem;
                      "
                      ><span
                        style="
                          vertical-align: middle;
                          display: inline-block;
                          width: 100%;
                        "
                      >
                        <span
                          style="-webkit-line-clamp: 2"
                          class="ts inner-text"
                        >
                          {{ $t('change avatar') }}</span
                        >
                      </span></span
                    >
                  </span>
                </div>
              </template>
            </van-nav-bar>
          </template>
          <avatarSelectBox
            :avatarUrl="userinfo.avatar"
            @closeDialog="closeDialog"
          ></avatarSelectBox>
        </el-dialog>
      </el-main>
    </el-container>
  </div>
</template>
<script>
import avatarSelectBox from "@/components/phone/avatarSelectBox.vue";
import myScrollbarBar from "@/components/phone/common/myScrollbarBar.vue";
import { formatDateVirar } from "@/utils/utils";
import { getUserMoreData, modifyUserData } from "@/api/index";
export default {
  components: { myScrollbarBar, avatarSelectBox },
  data() {
    return {
      userinfo: {},
      active: 0,
      wechat: "",
      account: "",
      whats: "",
      facebook: "",
      telegram: "",
      showPicker: false,
      result: "",
      currentDate: "",
      columnsType: ["year", "month", "day"],
      birthday: "",
      dialogTableVisible: false,
    };
  },
  activated() {
    this.userinfo = this.$store.getters.user;
    // this.account=this.user.account_number;
   
    this.getUserMoreData();
  },
  methods: {
    onClickLeft() {
      history.go(-1);
    },
    closeDialog(url){
      this.dialogTableVisible = false;
      this.userinfo.avatar=url;
    },
    onConfirm() {
      let data = {};
      if (this.wechat != this.userinfo.wechat_id) {
        data.wechat_id = this.wechat;
      }

      if (this.whats != this.userinfo.whatsapp) {
        data.whatsapp = this.whats;
      }

      if (this.facebook != this.userinfo.facebook) {
        data.facebook = this.facebook;
      }

      if (this.telegram != this.userinfo.telegram) {
        data.telegram = this.telegram;
      }
      if (
        this.currentDate != this.userinfo.birthday &&
        this.userinfo.birthday === ""
      ) {
        data.birthday = this.currentDate;
      }
      modifyUserData(data).then(({ data }) => {
        this.$toast($('modification of successful personal information'));
      });
    },
    onDateConfirm() {
      this.showPicker = false;
      this.birthday = formatDateVirar(this.currentDate);
    },
    getUserMoreData() {
      getUserMoreData().then(({ data }) => {
        this.userinfo = data.data;
        this.account = data.data.account;
        this.wechat = data.data.wechat_id;
        this.whats = data.data.whatsapp;
        this.facebook = data.data.facebook;
        this.telegram = data.data.telegram;
        this.birthday = data.data.birthday;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.bottomMenu {
  background-color: var(--theme-main-bg-color);
  bottom: 0;
  box-shadow: 0 -0.03rem 0.1rem 0 rgba(0, 0, 0, 0.1);
  padding: 0.2rem;
  position: fixed;
  width: 100%;
  z-index: 9;
  .context {
    align-items: center;
    display: flex;
    button {
      border-radius: 0.14rem;
      border-radius: 0.14rem;
      font-size: 0.24rem !important;
      height: 0.7rem;
      width: 100%;
      padding: 0px 0.1rem;
    }
    .H5BottomReturnBtn {
      border-radius: 0.14rem;
      font-size: 0.24rem !important;
      height: 0.7rem;
      width: 100%;
      background-color: transparent !important;
      border-color: var(--theme-ant-primary-color-0);
      color: var(--theme-ant-primary-color-0);
    }
    .right {
      background-color: var(--theme-primary-color);
      border-color: var(--theme-primary-color);
      // background-color: var(--theme-disabled-bg-color) !important;
      margin-left: 0.2rem;
      color: var(--theme-primary-font-color);
    }
  }
}
/deep/.el-main,
/deep/.el-head {
  padding: 0;
}
.theme-box-shadow {
  border-radius: 0;
  -ms-flex: 1;
  flex: 1;
  margin-bottom: 0;
  overflow: auto;
  padding: 0.2rem 0.2rem 0;
  .top {
    -ms-flex-align: center;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    font-size: 0.22rem;
    height: 1.1rem;
    margin-bottom: 0.5rem;
    .left {
      border-radius: 0.14rem;
      height: 1.1rem;
      position: relative;
      width: 1.1rem;
      .avatar {
        width: 1.1rem;
        height: 1.1rem;
        border-radius: 0.14rem;
      }
      .viplvbox {
        bottom: 0;
        position: absolute;
        right: 0;
        font-size: 0.22rem;
        .viplvbody {
          border-top-left-radius: 0.04rem;
          height: 0.18rem;
          min-width: 0.45rem;
          padding: 0 0.04rem 0 0.075rem;
          .vip-badge {
            -ms-flex-align: center;
            -ms-flex-pack: center;
            align-items: center;

            border-radius: 0.04rem 0 0.04rem 0;
            display: -ms-flexbox;
            display: flex;

            justify-content: center;

            border-top-left-radius: 0.04rem;
            height: 0.24rem;
            max-width: 0.66rem;
            min-width: 0.5rem;
            padding: 0 0.035rem;
            background: rgb(31, 168, 42);
            .vip-img {
              border-radius: initial !important;
              margin-top: 0.01rem;
              height: 0.172rem;

              min-width: auto !important;
              width: 0.368rem;
            }
            span {
              color: transparent;
              display: inline-block;
              font-size: 0.14rem;
              font-style: normal;
              font-weight: 700;
              margin: -0.02rem 0 0;
              position: relative;
              text-shadow: 0 0.01rem 0 rgba(0, 0, 0, 0.4);
              -webkit-transform: scale(0.6);
              transform: scale(0.6);
              background: transparent none repeat 0 0 / auto auto padding-box
                border-box scroll;
              background: initial;
              font-size: 0.22rem;
              font-style: italic;
              margin: -0.025rem 0 0 0.01rem;
              -webkit-transform: scale(0.9);
              transform: scale(0.9);
            }
            span:before {
              -webkit-background-clip: text;
              background-clip: text;
              background-image: -webkit-gradient(
                linear,
                left top,
                left bottom,
                from(#f7ea94),
                color-stop(51%, #e5b952),
                to(#ce9510)
              );
              background-image: linear-gradient(
                180deg,
                #f7ea94 0,
                #e5b952 51%,
                #ce9510
              );
              background-size: cover;
              content: attr(data-text);
              height: 100%;
              left: 0;
              position: absolute;
              text-shadow: none;
              top: 0;
              width: 100%;
              z-index: 0;
            }
          }
        }
      }
    }
    .right {
      -ms-flex-align: start;
      -ms-flex-pack: center;
      align-items: flex-start;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      justify-content: center;
      margin-left: 0.4rem;
      font-size: 0.22rem;
      .config {
        color: var(--theme-primary-color);
        cursor: pointer;
      }
      .acbox {
        color: var(--theme-text-color-lighten);
        .ac {
          margin-left: 0.02rem;
        }
        .copyIcon {
          display: inline-block;
          font-size: 0.3rem;
          margin-left: 0.15rem;
          -webkit-transform: translateY(0.04rem);
          transform: translateY(0.04rem);
          color: var(--theme-primary-color);
          cursor: pointer;
        }
      }
    }
  }
  // /deep/.van-cell::after{
  //   border: 1px solid #d9d9d9;
  //   border-radius: .14rem;
  //   color: var(--theme-text-color-darken);
  //   font-size: .22rem;
  //   height: .7rem;
  //   width: 100%;
  //   border-color: var(--theme-color-line) !important;
  // }
  /deep/ .van-cell::after {
    border-bottom: 0;
  }
  /deep/.van-cell {
    border: 1px solid #d9d9d9;
    border-radius: 0.14rem;
    height: 0.7rem;
    background-color: var(--theme-bg-color);
    border-color: var(--theme-color-line);
    padding: 0;
    .van-field__left-icon {
      // padding: 10px 10px;
      display: flex;
      margin-right: 0;
      -ms-flex-align: center;
      align-items: center;
      color: rgba(0, 0, 0, 0.65);
      display: -ms-flexbox;
      display: flex;
      line-height: 0;
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      z-index: 2;
      left: 0.16rem !important;
    }
    .van-field__control {
      border-radius: 0.14rem;
      color: var(--theme-text-color-darken);
      font-size: 0.22rem;
      height: 0.7rem;
      width: 100%;
      padding: 0px 0.6rem;
    }
    .van-field__control {
      height: 0.7rem;
    }
    .van-field__clear {
      margin-right: 0;
    }
    .van-field__control::placeholder {
      color: var(--theme-text-color-placeholder);
    }
  }
  .tips {
    color: var(--theme-text-color-darken);
    margin-bottom: 0.12rem;
    font-size: 0.22rem;
    margin-top: 0;
  }
}

.van-nav-bar {
  border-bottom: 0.01rem solid var(--theme-color-line);
  background: var(--theme-main-bg-color);
  /deep/.van-nav-bar__content {
    height: 0.9rem;
    padding: 0px 0.1rem 0px 0.2rem;
  }

  /deep/.van-nav-bar__right {
    width: 0.9rem;
    display: flex;
    justify-content: flex-end;
    position: inherit;
    padding: 0;
    font-size: 0.22rem;
  }
  /deep/.van-nav-bar__left {
    position: inherit;
    padding: 0;
    width: 0.8rem;
    div {
      align-items: center;
      color: var(--theme-text-color);
      cursor: pointer;
      display: flex;
      font-size: 0.2rem;
      font-size: 0.24rem;
      height: 0.8rem;
      width: 0.8rem;
    }
  }
  /deep/.van-nav-bar__title {
    align-items: center;
    display: flex;
    flex: 2 1 0%;
    flex-direction: column;
    margin: 0;
    max-width: none;
    // max-width: max-content;
    .title {
      // padding: 0px 0.34rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--theme-text-color);
      span {
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        color: var(--theme-text-color-darken);
        display: -webkit-box;
        margin: 0px 0.12rem;
        overflow: hidden;
        text-align: center;
        text-overflow: ellipsis;
        vertical-align: middle;
      }
      .global-wrapper-title {
        font-size: 0.3rem;

        margin-top: -0.014rem;

        .ts {
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          display: -webkit-box;
          line-height: 1.2;
          overflow: initial;
          text-align: center;
          text-overflow: ellipsis;
          vertical-align: middle;
          word-break: break-word;
        }
      }
    }
  }

  /deep/.van-nav-bar__arrow {
    color: var(--theme-text-color);
  }

  /deep/.van-nav-bar__text {
    color: var(--theme-ant-primary-color-0);
    word-wrap: break-word;
    line-height: 1.1;
    text-align: center;
    white-space: pre-line;
    word-break: break-word;
    //  font-size: 24.576px;
    font-weight: 400;
    user-select: none;
    font: inherit;
    text-transform: none;
    box-sizing: border-box;
    pointer-events: none;
  }
  /deep/.van-ellipsis {
    white-space: inherit;
  }
}
.pop {
  background-color: var(--theme-main-bg-color);
  border-top-left-radius: 0.14rem;
  border-top-right-radius: 0.14rem;
  position: fixed !important;
}
.birthday {
  /deep/.van-field__control {
    margin-left: 0.2rem;
  }
  /deep/.van-field__right-icon {
    margin-right: 0;
  }
}
/deep/.van-datetime-picker {
  margin: 0.2rem 0;
  background: none;
  .van-picker__toolbar {
    border-bottom: thin solid var(--theme-color-line);
    height: auto;
    margin-bottom: 0.2rem;
    padding-bottom: 0.2rem;
    .van-picker__cancel {
      color: var(--theme-text-color-lighten);
      font-size: 0.3rem;
    }
    .van-picker__title {
      color: var(--theme-text-color-darken);
      font-size: 0.3rem;
      line-height: normal;
    }
    .van-picker__confirm {
      color: var(--theme-primary-color);
      font-size: 0.3rem;
    }
  }
  .van-picker-column__item {
    font-family: MicrosoftYaHeiLobby;
    font-size: 0.22rem;
    color: var(--theme-text-color-lighten) !important;
  }
  .van-picker__mask {
    background: none !important;
  }
  .van-picker__frame {
    background-color: var(--theme-bg-color);
    left: 0;
    right: 0;
    z-index: -1;
  }
  .van-picker__frame::after {
    border-width: 0;
  }
}
/deep/.el-dialog {
  background-color: var(--theme-bg-color);
}
</style>
