<template>
  <section>
    <div class="wrap">
      <div class="topMenu">
        <div class="left">
          <dateSelect
            :startDate.sync="stareDate"
            :endDate.sync="endDate"
            @change="selectDate"
          ></dateSelect>
        </div>
        <div class="right">
          <search
            :placeholder="$t('member ID')"
            @searchClick="searchClick"
            :loading.sync="loading"
            :findtext.sync="findtext"
          ></search>
        </div>
      </div>
      <div class="context">
        <van-list>
          <div class="sticky" v-for="(item, index) in list">
            <van-row justify="space-between" type="flex">
              <div class="leftTop">
                <div class="globalVipLevel">
                  <p class="icobg">V{{ item.vip_lv }}</p>
                </div>
                <div class="globalInnerMain">
                  <div class="leftTitle">{{ item.account_number }}</div>
                  <span class="copy">
                    <i
                      style="
                        display: inline-flex;
                        justify-content: center;
                        align-items: center;
                      "
                    >
                      <svg-icon
                        icon-class="comm_icon_copy"
                        style="width: 1em; height: 1em; fill: white"
                      ></svg-icon
                    ></i>
                  </span>
                </div>
              </div>
              <van-col span="12">
                <label>{{ $t('his subordinates') }}</label>
                <span class="directMember">{{ item.count_subordinate }}</span>
              </van-col>
            </van-row>
            <van-row justify="space-between" type="flex">
              <van-col span="12">
                <label>{{ $t('deposit') }}</label>
                <span class="currencyAmount">{{
                  formatNumberToString(item.deposit)
                }}</span>
              </van-col>
              <van-col span="12">
                <label>{{ $t('registration date') }}</label>
                <span class="currencyAmount">{{ item.register_time }}</span>
              </van-col>
            </van-row>

            <van-row justify="space-between" type="flex">
              <van-col span="12">
                <label>{{ $t('valid bets') }}</label>
                <span class="currencyAmount">{{ item.flow }}</span>
              </van-col>
              <van-col span="12">
                <label>{{ $t('login date') }}</label>
                <span class="currencyAmount">{{ item.last_login_time }}</span>
              </van-col>
            </van-row>

            <van-row justify="space-between" type="flex">
              <van-col span="12">
                <label>{{ $t('current') }}</label>
                <span>
                  <span
                    >{{ item.online ? this.$t('online'): this.$t('offline')
                    }}<span>(1{{ $t('order') }})</span></span
                  >
                </span>
              </van-col>
              <van-col span="12">
                <label>{{ $t('state') }}</label>
                <span v-if="item.state === 1" class="isGreen">{{ $t('normal') }}</span>
                <span v-else class="isRed">{{ $t('disable') }}</span>
              </van-col>
            </van-row>
          </div>
        </van-list>

        <myDrawer v-if="list.length > 0" class="mydrawer">
          <van-row style="font-size: 0.2rem" type="flex">
            <van-col span="12">
              <label>{{ $t('number of registered participants') }}</label
              ><span class="currencyAmount"> {{ count_register }}</span>
            </van-col>

            <van-col span="12">
              <label>{{ $t('deposits') }}</label
              ><span class="currencyAmount"> {{ count_deposit }}</span>
            </van-col>

            <van-col span="12">
              <label>{{ $t('first deposits') }}</label
              ><span class="currencyAmount"> {{ count_first_deposit }}</span>
            </van-col>

            <van-col span="12">
              <label>{{ $t('deposit') }}</label
              ><span class="currencyAmount"> {{ deposit }}</span>
            </van-col>

            <van-col span="12">
              <label>{{ $t('valid bets') }}</label
              ><span class="currencyAmount"> {{ flow }}</span>
            </van-col>
          </van-row>
          <div style="height: 0.5rem; width: 100%"></div>
        </myDrawer>
      </div>
    </div>
  </section>
</template>
<script>
import dateSelect from "@/components/phone/common/dateSelect.vue";
import search from "@/components/phone/common/search.vue";
import myDrawer from "@/components/phone/common/myDrawer.vue";
import { getSubordinateList } from "@/api/index";
import { formatDate, formatNumberToString2 } from "@/utils/utils";
export default {
  name: "directData",
  components: { dateSelect, search, myDrawer },
  data() {
    return {
      findtext: "",
      list: [],
      stareDate: new Date(),
      endDate: new Date(),
      loading: false,
      count_register: 0,
      count_deposit: 0,
      count_first_deposit: 0,
      deposit: 0,
      flow: 0,
    };
  },
  created() {},
  mounted() {

  },
  destroyed() {},
  methods: {
    remoteMethod(query) {},
    tabChange(index) {
      this.active = index;
    },
    formatNumberToString(v) {
      return formatNumberToString2(v);
    },
    onSearch() {},
    selectDate(start, end) {
      this.getList(start, end, this.findtext);
    },
    searchClick(text) {
      this.getList(this.startDate, this.endDate, text);
    },
    getList(start, end, text) {
      const data = {
        start_day: formatDate(start),
        end_day: formatDate(end),
        account_number: text,
      };
      this.loading = true;
      this.list = [];
      getSubordinateList(data).then(({ data }) => {
        this.count_register = data.data.count_register;
        this.count_deposit = data.data.count_deposit;
        this.count_first_deposit = data.data.count_first_deposit;
        this.deposit = formatNumberToString2(data.data.deposit);
        this.flow = formatNumberToString2(data.data.flow);
        this.list = data.data.list;
        this.loading = false;
      });
    },
  },
};
</script>
<style type="text/less" lang="less" scoped>

.wrap {
  background-color: var(--theme-main-bg-color);
  border-radius: 0.1rem;
  -webkit-box-shadow: 0 0.03rem 0.09rem 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 0.03rem 0.09rem 0 rgba(0, 0, 0, 0.06);
  margin-bottom: 0.2rem;
  padding: 0.2rem 0.2rem 0.35rem;
  border-radius: 0.1rem;
  background: 0 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 0;

  .topMenu {
    -ms-flex-align: center;
    -ms-flex-pack: start;
    align-items: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: flex-start;
    padding-bottom: 0.2rem;
    padding: 0.2rem;
    .left {
      position: relative;
      z-index: 999;
      margin-right: 0.2rem;
    }
    .right {
      width: 2rem;
    }
  }
  .context {
    -ms-flex-pack: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    height: calc(100vh - 2.55rem);
    height: calc(var(--vh, 1vh) * 100 - 2.55rem);
    justify-content: center;
    overflow: hidden;
    position: relative;
    /deep/.van-list {
 
      .sticky {
        color: var(--theme-text-color-darken);
        font-size: 0.2rem !important;
        position: relative;
        border-radius: 0.1rem;
        padding: 0.2rem;
        .leftTop {
          .globalVipLevel {
            -ms-flex-align: center;
            -ms-flex-pack: center;
            align-items: center;
            background-color: #c12929;
            border-radius: 0.04rem 0 0.04rem 0;
            display: -ms-flexbox;
            display: flex;
            height: 0.23rem;
            justify-content: center;
            left: 0;
            line-height: 1;
            position: absolute;
            top: 0;
            width: -webkit-fit-content;
            width: -moz-fit-content;
            width: fit-content;
            width: 0.3rem;
            .icobg {
              -webkit-text-fill-color: transparent;
              -webkit-background-clip: text;
              background-clip: text;
              background-image: -webkit-gradient(
                linear,
                left top,
                left bottom,
                color-stop(-5%, #f7ea94),
                color-stop(58%, #e5b952),
                color-stop(114%, #ce9510)
              );
              background-image: linear-gradient(
                180deg,
                #f7ea94 -5%,
                #e5b952 58%,
                #ce9510 114%
              );
              font-size: 0.16rem;
              font-weight: 700;
              line-height: 1;
            }
          }
          .globalInnerMain {
            -ms-flex-align: center;
            -ms-flex-pack: start;
            align-items: center;
            display: -ms-flexbox;
            display: flex;
            justify-content: flex-start;
            -ms-flex-pack: initial;
            justify-content: normal;
            .leftTitle {
              color: var(--theme-text-color-darken);
              margin-right: 0.1rem;
              overflow: hidden;
              text-align: left;
              text-overflow: ellipsis;
            }
            .copy {
              -ms-flex-negative: 0;
              flex-shrink: 0;
              font-size: 0.24rem;
              margin-left: 0.1rem;
              position: relative;
              top: 0.02rem;
              color: var(--theme-primary-color);
              cursor: pointer;
            }
          }
        }
      }
      .sticky:nth-child(odd) {
        background-color: var(--theme-main-bg-color);
      }
    }
    /deep/.van-col {
      -ms-flex-align: center;
      align-items: center;
      color: var(--theme-text-color-darken);
      display: -ms-flexbox;
      display: flex;
      margin-bottom: 0.05rem;
      font-size: 0.2rem !important;
      label {
        color: var(--theme-text-color-lighten);
        margin-right: 0.1rem;
        touch-action: manipulation;
      }
    }
  }
}
.mydrawer {
  /deep/.van-row {
    font-size: 0.2rem;
    .van-col {
      -ms-flex-align: center;
      align-items: center;
      color: var(--theme-text-color-darken);
      display: -ms-flexbox;
      display: flex;
      margin-bottom: 0.15rem;
      label {
        color: var(--theme-text-color-lighten);
        margin-right: 0.1rem;
        width: 50%;
      }
    }
  }
}
</style>
