import Vue from 'vue'
import Router from 'vue-router'

import home from '@/pages/home/index.vue'
import embedded from '@/pages/home/embedded.vue'
import Promotion from '@/pages/home/promotion.vue'
import mine from '@/pages/home/mine.vue'
import eventItem from '@/pages/home/eventDetail.vue'
import aboutUs from '@/pages/home/about'
import notice from '@/pages/home/notice'
import saque from '@/pages/home/saque'
import subgame from '@/pages/home/subgame'
import install from '@/pages/install'
import agent from '@/pages/home/agent.vue'
import deviceInfo from '@/pages/home/deviceInfo.vue'

import cashback from '@/pages/home/cashback.vue';

import security from '@/pages/home/security.vue'
import setting from '@/pages/home/setting.vue'

import centerWallet from '@/pages/home/centerWallet.vue'

import report from '@/pages/home/report.vue'
Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [{
    path: '/',
    name: 'home',
    component: home,
    meta: {
      title: '9991BET',
    }
  },

  {
    path: '/subgame',
    name: 'subgame',
    component: subgame,

  },
  {
    path: '/embedded',
    name: 'embedded',
    component: embedded,
 
  }, {
    path: '/search',
    name: 'search_Phone',
    component: () => import('@/pages/home/search'),
  
  }, {
    path: '/Promotion',
    name: 'Promotion',
    component: Promotion,
 
  },
  {
    path: '/deviceInfo',
    name: 'deviceInfo',
    component: deviceInfo,
  
  },
  {
    path: '/security',
    name: 'security',
    component: security,
 
  },  {
    path: '/setting',
    name: 'setting',
    component: setting,

  }
    , {
    path: '/mine',
    name: 'mine',
    component: mine,
  
  }, {
    path: '/eventItem',
    name: 'eventItem',
    component: eventItem,
  
  },
  {
    path: '/about',
    name: 'aboutUs',
    component: aboutUs,
  
  }, {
    path: '/notice',
    name: 'notice',
    component: notice,
  
  },
  {
    path: '/saque',
    name: 'saque',
    component: saque,
  
  },
  {
    path: '/agent',
    name: 'agent',
    component: agent,
   
  },
  {
    path: '/install',
    name: 'install',
    component: install,
   
  },
  {
    path: '/centerWallet',
    name: 'centerWallet',
    component: centerWallet,
  
  },
  {
    path: '/report',
    name: 'report',
    component: report,
  },

  {
    path: '/cashback',
    name: 'cashback',
    component: cashback,
  },
  ],
})