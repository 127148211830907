import store from '@/store';
class MusicPlayer {
  constructor() {
    this.audio = new Audio();
    this.audio.volume =0.5;
    this.playlist = [];
    this.currentIndex = 0;
    this.history = JSON.parse(localStorage.getItem('playHistory')) || [];
    this.playType = 0;//0列表循环,1单曲循环,2随机播放
    this.audio.addEventListener('ended', this.handleEnded.bind(this));
    this.audio.addEventListener('error', this.handleError.bind(this));
  }

  setPlaylist(playlist) {
    this.playlist = playlist;
  }
  getPlaylist() {
    return this.playlist;
  }
  play(item) {
    // if (this.playlist.length === 0) return;
    if(!item)return;
    const index = this.playlist.findIndex(e => e.id === item.id);
    //  let isFound = this.playlist.some(e => e.id === item.id);
    if (index === -1) {
      this.playlist.push(item);
      this.currentIndex = this.playlist.length - 1;
    } else {
      this.currentIndex = index;
    }
    this.audio.src = item.oss_url;
    store.dispatch('musicPlayer/updateCurrentTrack');
    this.audio.play().then(() => {
      store.dispatch('musicPlayer/updateDuration');
      this.addToHistory(item);
    }).catch(this.handleError.bind(this));
  }

  pause() {
    this.audio.pause();
  }
  restore() {
    if (this.audio && !this.audio.error) {
      this.audio.play();
    } else {
      // 从音乐列表读取一个音乐并播放
      this.play(this.playlist[this.currentIndex]);
    }
  }
  next() {
    if (this.playType === 2) {
      this.currentIndex = Math.floor(Math.random() * this.playlist.length);
    }
    else if (this.playType === 0) {
      this.currentIndex = (this.currentIndex + 1) % this.playlist.length;
    }
    this.play(this.playlist[this.currentIndex]);
  }

  previous() {
    if (this.playType === 2) {
      this.currentIndex = Math.floor(Math.random() * this.playlist.length);
    } else if (this.playType === 0) {
      this.currentIndex = (this.currentIndex - 1 + this.playlist.length) % this.playlist.length;
    }

    this.play(this.playlist[this.currentIndex]);
  }

  setVolume(volume) {
    this.audio.volume = volume;
  }

  setPlayType(t) {
    this.playType = t;
    // if (t === 1) {
    //   this.audio.loop = true;
    // }else{
    //   this.audio.loop = this.isLoop;
    // }
  }

  setProgress(value) {
    this.audio.currentTime = value;
  }
  getProgress() {
    return this.audio.currentTime;
  }

  getDuration() {
    // console.log(this.audio.duration);
    return this.audio.duration;
  }
  setMuted(muted) {
    this.audio.muted = muted;
  }
  getMuted() {
    return this.audio.muted;
  }

  getCurrentTrack() {
    return this.playlist[this.currentIndex];
  }

  addToHistory(track) {
    this.history.push(track);
 //   localStorage.setItem('playHistory', JSON.stringify(this.history));
  }

  getHistory() {
    return this.history;
  }

  removeHistory(index) {
    this.history.splice(index, 1);
  //  localStorage.setItem('playHistory', JSON.stringify(this.history));
  }

  saveProgress() {
    const progress = {
      currentIndex: this.currentIndex,
      currentTime: this.audio.currentTime,
    };
  //  localStorage.setItem('playProgress', JSON.stringify(progress));
  }

  loadProgress() {
    const progress = JSON.parse(localStorage.getItem('playProgress'));
    if (progress) {
      this.currentIndex = progress.currentIndex;
      this.audio.currentTime = progress.currentTime;
      this.play(this.playlist[this.currentIndex]);
    }
  }

  handleEnded() {
    if (!this.isLoop) {
      this.next();
    }
  }

  handleError() {
    // console.error("Playback error occurred. Skipping to next track.");
    this.next();
  }

  isPlayableEnvironment() {
    return !!this.audio.canPlayType;
  }

  canPlay(url) {
    return new Promise((resolve, reject) => {
      const testAudio = new Audio(url);
      testAudio.addEventListener('canplaythrough', () => resolve(true));
      testAudio.addEventListener('error', () => reject(false));
    });
  }
}

export default new MusicPlayer();
