<template>
  <section class="wrap">
    <div>
      <div class="top">
        <div class="betdetails">
          {{ $t("red tip1", { n1: consume, n2: consumed }) }}
        </div>
        <div class="assistance">
          <div class="text">
            {{ $t("red tip2", { n1: maximum, n2: maximum }) }}
          </div>
        </div>
      </div>

      <div class="taskRule">
        <div class="text">
          <div>
            <div>
              <span style="font-weight: 700">I.Hora do evento(UTC-3):</span>
              <div><span>05/12/2023 00:00:00 - 30/11/2030 23:59:59</span></div>
            </div>
          </div>
          <div>
            <span style="font-weight: 700">II.Condições do Evento:</span>
            <div>
              <span
                >Apostas acumuladas (ou seja, apostas válidas ou em curso)</span
              >
            </div>
          </div>
          <div>
            <span style="font-weight: 700">III.Plataforma especificada:</span>
            <div><span>Todos os jogos</span></div>
          </div>
          <div>
            <span style="font-weight: 700">IV.Instruções Do Evento:</span>
            <div>
              1.Durante o período do evento, cada aposta válida de 1 ganha
              automaticamente 1 ponto da sorte. 1500-15000 pontos da sorte
              permitem um sorteio, com a recompensa máxima sendo 5555.<br />2.O
              valor da sorte obtido este mês não é usado no próximo mês e
              expirará para limpar zero;<br />3.As recompensas só podem ser
              resgatadas manualmente em APP/iOS、APP/Android、PC/Windows.<br />4.A
              atribuição de bônus desta atividade (excluindo o principal) requer
              1 X rollover (isto é, auditoria, participação ou aposta válida)
              para sacar o dinheiro. Participações não limitadas por jogo ou
              plataforma;<br />5.Este evento é limitado a operações normais
              realizadas pelo titular da conta. É proibido alugar, usar plug-ins
              externos, robôs, apostar em contas diferentes, brushing mútuo,
              arbitragem, interface, protocolo, exploração de vulnerabilidades,
              controle de grupo ou outros meios técnicos para participar. Caso
              contrário, as recompensas serão canceladas ou deduzidas, a conta
              será congelada ou até mesmo adicionada à lista negra;<br />6.Para
              evitar diferenças na compreensão do texto, a plataforma reserva-se
              o direito de interpretação final deste evento.
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "",
  components: {},
  mixins: [],
  props: {},
  data() {
    return {
      consume: "0.00",
      consumed: "0",
      maximum: 1,
    };
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {},
};
</script>
<style lang="less" scoped>
.wrap {
  padding-top: 0px;
  display: flex;
  justify-content: center;
}
.top {
  margin: 0.2rem;

  .betdetails {
    background-color: var(--theme-main-bg-color);
    border-radius: 0.1rem;
    box-shadow: rgba(0, 0, 0, 0.06) 0px 0.03rem 0.09rem 0px;
    color: var(--theme-text-color-lighten);
    font-size: 0.2rem;
    margin-bottom: 0.2rem;
    padding: 0.2rem;
    text-align: center;
    span {
      color: var(--theme-text-color-darken);
    }
  }
  .assistance {
    background-color: var(--theme-main-bg-color);
    border-radius: 0.1rem;
    box-shadow: rgba(0, 0, 0, 0.06) 0px 0.03rem 0.09rem 0px;
    margin-bottom: 0.2rem;
    padding: 0.2rem;
    .text {
      color: var(--theme-text-color-lighten);
      font-size: 0.2rem;
      text-align: center;
    }
  }
}
.taskRule {
  padding: 0px 0.2rem;
  .text {
    font-size: 0.24rem;
    line-height: 0.4rem;
    background-color: var(--theme-main-bg-color);
    border-radius: 0.1rem;
    box-shadow: rgba(0, 0, 0, 0.06) 0px 0.03rem 0.09rem 0px;
    color: var(--theme-text-color);
    padding: 0.2rem;
    white-space: pre-wrap;
    word-break: break-word;
  }
}
</style>
