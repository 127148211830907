<template>
  <div>
    <div class="scroll-tab-bar">
      <div class="promotiontopMenu">
        <div class="pro-back" @click="onClickLeft">
          <i
            style="
              display: inline-flex;
              justify-content: center;
              align-items: center;
            "
          >
            <svg-icon
              icon-class="comm_icon_fh"
              style="
                width: 1em;
                height: 1em;
                fill: var(--theme-text-color-lighten);
              "
            ></svg-icon>
          </i>
        </div>

        <section isscrollx="true" class="promotion-rightMenu">
          <van-tabs
            v-model="active"
            background="var(--theme-main-bg-color)"
            title-active-color="var(--theme-primary-color)"
            title-inactive-color="var(--theme-text-color-darken)"
            color="#fff"
            @change="change"
          >
            <van-tab :title="$t('events')" :to="'/promotion?index=0'" replace>
              <template slot="title" v-if="redPointInfo.activity">
                <van-badge :content="redPointInfo.activity.total_count">
                  {{ $t('events') }}
                </van-badge>
              </template>
            </van-tab>
            <van-tab :title="$t('vip')" :to="'/promotion?index=1'" replace> </van-tab>
            <van-tab :title="$t('rebate')" :to="'/promotion?index=2'" replace>
            </van-tab>
            <van-tab :title="$t('pending')" :to="'/promotion?index=3'" replace>
            </van-tab>
            <van-tab :title="$t('fees')" :to="'/promotion?index=4'" replace>
            </van-tab>
            <van-tab :title="$t('historic')" :to="'/promotion?index=5'" replace>
            </van-tab>
          </van-tabs>
        </section>
      </div>
    </div>
    <div
      style="
        background-color: var(--theme-bg-color);
        min-height: calc(100vh - 1.24rem);
        padding: 0.9rem 0 1.24rem;
      "
    >
      <div style="height: calc(var(--vh, 1vh) * 100)">
        <myScrollbarBar :st="'y'">
          <eventList v-if="active === 0"></eventList>
          <vip v-if="active === 1"></vip>
          <Rebate v-if="active === 2"></Rebate>
          <pendente v-if="active === 3"> </pendente>
          <Juros v-if="active === 4"></Juros>
          <myHhistory v-if="active === 5"></myHhistory>
        </myScrollbarBar>
      </div>
    </div>
    <tabBar ref="tabBar" :tabBarActive="1"></tabBar>
  </div>
</template>
<script>
import eventList from "@/components/phone/eventList";
import tabBar from "@/components/phone/tabBar.vue";
import vip from "@/components/phone/vip.vue";
import Rebate from "@/components/phone/rebate.vue";
import Juros from "@/components/phone/juros.vue";
import myHhistory from "@/components/phone/myHhistory.vue";
import pendente from "@/components/phone/pendente.vue";
import myScrollbarBar from "@/components/phone/common/myScrollbarBar.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    eventList,
    vip,
    Rebate,
    tabBar,
    Juros,
    pendente,
    myHhistory,
    myScrollbarBar,
  },
  data() {
    return {
      active: 0,
    };
  },
  computed: { ...mapState("redPoint", ["redPointInfo"]) },
  watch: {
    $route(to, from) {
      if (to.query.index) {
        if (this.active != Number(to.query.index)) {
          this.active = Number(to.query.index);
        }
      }
    },
  },
  activated() {
    if (this.$route.query.index) {
      this.active = Number(this.$route.query.index);
    }
    // console.log(this.redPointInfo);
    // console.log( this.active );
    this.$refs.tabBar.initBar(1);
  },
  methods: {
    change() {},
    onClickLeft() {
      history.go(-1);
    },
  },
};
</script>
<style lang="less" scoped>
.scroll-tab-bar {
  background-color: var(--theme-main-bg-color);
  border-bottom: 0.01rem solid var(--theme-color-line);
  overflow: hidden;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 100;
  left: auto !important;
  margin: 0 auto;

  max-width: var(--theme-max-width);
  .promotiontopMenu {
    display: -ms-flexbox;
    display: flex;
    padding: 0;
    position: relative;
    width: 100%;
    z-index: 101;
    .promotion-rightMenu {
      margin-left: 0.84rem;
      width: calc(100% - 0.84rem);
    }
  }
}
/deep/ .van-tabs--line .van-tabs__wrap {
  // border-bottom: thin solid var(--theme-color-line);

  height: 0.9rem;
  .van-tab {
    font-size: 0.24rem;
  }
}
.pro-back {
  cursor: pointer;
  font-size: 0.24rem;
  left: 0;
  padding: 0.33rem 0.4rem 0.27rem 0.2rem;
  position: absolute;
  top: 0;
  z-index: 2;
}
</style>
