import { render, staticRenderFns } from "./trunTable.vue?vue&type=template&id=30c39a14&scoped=true"
import script from "./trunTable.vue?vue&type=script&lang=js"
export * from "./trunTable.vue?vue&type=script&lang=js"
import style0 from "./trunTable.vue?vue&type=style&index=0&id=30c39a14&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30c39a14",
  null
  
)

export default component.exports