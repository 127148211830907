<template>
  <el-select
    :class="'selectIpt '+ myCustomClass"
    v-model="myselectItem"
    :popper-append-to-body="true"
    popper-class="myselectitem"
    @change="select"
    :filterable="filterable"
  >
    <el-option
      v-for="(item, index) in items"
      :label="item.label"
      :value="item.value"
      :key="item.label"
      :style="{
        color:
          item.value === myselectItem
            ? 'var(--theme-primary-color)'
            : 'var(--theme-text-color-lighten)',
      }"
    ></el-option>
  </el-select>
</template>
<script>
export default {
  name: "mySelect",
  components: {},
  mixins: [],
  props: {
    myCustomClass:String,
    items: {
      type: Array,
      required: true,
    },
    selectItem: {
      required: true,
    },
    filterable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      myselectItem: {},
    };
  },
  computed: {},
  watch: {
    selectItem(s) {
      this.myselectItem = s;
    },
  },
  mounted() {
    this.myselectItem = this.selectItem;
    if (this.items.length > 0 && this.myselectItem === "") {
      this.myselectItem = this.items[0].value;
      this.$emit("update:selectItem", this.myselectItem);
    }
  },
  methods: {
    select() {
      this.$emit("update:selectItem", this.myselectItem);
      this.$emit("change", this.myselectItem);
    },
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.options = this.list.filter((item) => {
            return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1;
          });
        }, 200);
      } else {
        this.options = [];
      }
    },
  },
};
</script>
<style type="text/less" lang="less" scoped>
.selectIpt {
  height: 0.5rem !important;
  max-width: 3.15rem;
  min-width: 1.6rem;
  width: auto !important;
  border-top: 1.02px solid #d9d9d9;
  border: 1px solid #d9d9d9;
  box-shadow: none;
  background-color: var(--theme-main-bg-color) !important;
  padding: 0;
  color: var(--theme-text-color-lighten) !important;
  font-size: 0.2rem;
  //   line-height: 0.68rem !important;
  margin: 0 auto;
  display: flex;
  align-items: center;
  border-color: var(--theme-color-line);
  border-radius: 0.5rem;

  img {
    width: 0.28rem;
    height: 0.21rem;
  }
  /deep/.el-input {
    overflow: hidden;
    width: auto;
  }
  .el-input__prefix {
    left: 0;
  }
  /deep/ .el-input__inner {
    // padding-left: 0.45rem;
    border: 0;
    background: transparent;
    color: var(--theme-text-color-lighten) !important;
    margin-left: 0.2rem;
    margin-right: 0.55rem;
    padding: 0;
    line-height: 0.48rem !important;
    float: left;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;

    white-space: nowrap;
    // height: 100%;
    height: 0.7rem !important;
    font-size: 0.2rem !important;
    width: auto;
  }
  .el-input__inner:focus {
    box-shadow: none;
  }
  /deep/.el-select__caret {
    color: var(--theme-text-color-lighten);
  }

  /deep/.el-input__icon {
    font-size: 0.2rem !important;
    line-height: 0.26rem !important;
  }
  // margin-left: 7vw;
}
.selectIpt:active,
.selectIpt:focus,
.selectIpt:hover {
  border-color: var(--theme-ant-primary-color-13);
  border-right-width: 1px !important;
  -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  outline: 0;
}
</style>
