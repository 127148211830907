<template>
  <div class="box">
    <el-container>
      <el-header height=".9rem">
        <van-nav-bar
          @click-left="onClickLeft"
          right-text=" "
          :fixed="true"
          style="
            max-width: var(--theme-max-width);
            margin: 0 auto;
            left: 0;
            right: 0;
            height: 0.9rem;
          "
        >
          <template #left>
            <div>
              <i style="font-size: 0.24rem">
                <svg-icon
                  icon-class="comm_icon_fh"
                  style="
                    width: 1em;
                    height: 1em;
                    color: var(--theme-text-color);
                    fill: var(--theme-text-color-lighten);
                  "
                ></svg-icon>
              </i>
            </div>
          </template>

          <template #title>
            <div class="title">
              <span class="global-wrapper-title">
                <span
                  style="
                    width: 100%;
                    margin-bottom: -1.64706px;
                    padding-bottom: 1.64706px;
                    font-size: 0.3rem;
                  "
                  ><span
                    style="
                      vertical-align: middle;
                      display: inline-block;
                      width: 100%;
                    "
                  >
                    <span style="-webkit-line-clamp: 2" class="ts inner-text">
                      {{ $t("cashback tax") }}</span
                    >
                  </span></span
                >
              </span>
            </div>
          </template>
        </van-nav-bar>
      </el-header>
      <el-main>
        <div class="boxShadow">
          <div class="topMenu">
            <ul class="selectbox">
              <li>
                <mySelect
                  :items="vendorList"
                  class="myselect"
                  :selectItem.sync="vendorselectItem"
                  @change="vendorChange"
                ></mySelect>
              </li>
              <li>
                <mySelect
                  :items="supplierList"
                  class="myselect"
                  :selectItem.sync="supplierItem"
                  @change="supplierChange"
                ></mySelect>
              </li>
            </ul>

            <div class="rightItem">
              <span>{{ $t("cashback total") }}</span>
              <span>{{ $t("cashback tax") }}</span>
            </div>
          </div>
          <section class="rateListBox">
            <van-empty
              v-if="list.length < 1"
              :image="require('@/assets/azf5f-28vaj.png')"
              :description="$t('no records')"
            />
            <van-list
              style="height: calc(-3.44rem + 100vh)"
              v-model="loading"
              :finished="finished"
              @load="onLoad"
              offset="100"
              :loading-text="$t('loading')"
              v-else
            >
              <div class="rate-item" v-for="(item, index) in list">
                <p>{{ item.category }}</p>
                <p>{{ item.vendor }}</p>
                <p><span>≥{{item.bet_require}}+</span></p>
                <p>
                  <span style="font-size: 0.18rem; margin-left: -0.32rem"
                    >{{item.rate}}%</span
                  >
                </p>
              </div>
            </van-list>
          </section>
        </div>
        <tabBar ref="tabBar" :tabBarActive="1"></tabBar>
      </el-main>
    </el-container>
  </div>
</template>
<script>
import myScrollbarBar from "@/components/phone/common/myScrollbarBar.vue";
import mySelect from "@/components/phone/common/mySelect.vue";
import tabBar from "@/components/phone/tabBar.vue";
import { getRebateInfo, getRebateRate } from "@/api/index";
export default {
  components: { myScrollbarBar, mySelect, tabBar },
  data() {
    return {
      vendorList: [],
      vendorselectItem: "",
      pageIndex: 0,
      pageSize: 50,
      finished: false,
      list: [],
      supplierList: [],
      supplierItem: "",
      loading: false,
      finished: false,
      current_index: -1,
      category: "",
      vendor: "",
    };
  },
  watch: {
    $route(to, from) {
      if (to.query.category) {
        if (this.category !== to.query.category) {
          this.category = to.query.category;
          this.vendorselectItem= this.category;
          // this.vendorChange( this.vendorselectItem);
        }
      }
      if (to.query.vendor) {
        if (this.vendor !== to.query.vendor) {
          this.vendor = to.query.vendor;
          this.supplierItem=this.vendor;
        }
      }
      this.supplierChange();
    },
  },

  mounted() {
    if (this.$route.query.category) {
      this.getRebateInfo();
      if (this.category !== this.$route.query.category) {
        this.category = this.$route.query.category;
        this.vendorselectItem= this.category;
      }
    }
    if (this.$route.query.vendor) {
      if (this.vendor !== this.$route.query.vendor) {
        this.vendor = this.$route.query.vendor;
        this.supplierItem=this.vendor;
      }
    }
    this.supplierChange();
  },
  methods: {
    onClickLeft() {
      history.go(-1);
    },
    onLoad() {
      this.pageIndex = this.pageIndex + 1;
      this.getList();
    },
    vendorChange(v) {
      //   this.pageIndex = 0;
      //   this.finished = false;
      //   this.list = [];
      //   this.getList();
      this.supplierList = [];
      this.supplierItem = "";
      const index = this.vendorList.findIndex((e) => e.value === v);
      const s = this.vendorList[index];
      s.item.forEach((e) => {
        this.supplierList.push({
          label: e.vendor,
          value: e.vendor,
        });
        // console.log(e);
      });
      this.supplierItem = this.supplierList[0].value;
    },
    supplierChange() {
      this.pageIndex = 0;
      this.finished = false;
      this.list = [];
      this.getList();
    },
    getList() {
      getRebateRate({
        category: this.vendorselectItem,
        vendor: this.supplierItem,
        page: this.pageIndex,
        count: this.pageSize,
      }).then(({ data }) => {
        if (data.code === 0) {
          if (data.data.rebate_rate_infos.length > 0) {
            this.list = this.list.concat(data.data.rebate_rate_infos);
          }
          if (data.data.rebate_rate_infos.length < this.pageSize) {
            this.finished = true;
          }
          // console.log(data.data);
          this.current_index = data.data.current_index;
          this.loading = false;
        }
      });
    },
    getRebateInfo() {
      getRebateInfo().then(({ data }) => {
        if (data.code === 0) {
          const l = data.data.category_rebate_infos;
          l.forEach((e) => {
            this.vendorList.push({
              label: e.category,
              value: e.category,
              item: e.vendor_rebate,
            });
          });
          this.vendorChange(this.vendorselectItem);
          this.supplierItem=this.vendor;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.el-main,
/deep/.el-head {
  padding: 0;
}

.van-nav-bar {
  border-bottom: 0.01rem solid var(--theme-color-line);
  background: var(--theme-main-bg-color);
  /deep/.van-nav-bar__content {
    height: 0.9rem;
    padding: 0px 0.1rem 0px 0.2rem;
  }

  /deep/.van-nav-bar__right {
    width: 0.9rem;
    display: flex;
    justify-content: flex-end;
    position: inherit;
    padding: 0;
    font-size: 0.22rem;
  }
  /deep/.van-nav-bar__left {
    position: inherit;
    padding: 0;
    width: 0.8rem;
    div {
      align-items: center;
      color: var(--theme-text-color);
      cursor: pointer;
      display: flex;
      font-size: 0.2rem;
      font-size: 0.24rem;
      height: 0.8rem;
      width: 0.8rem;
    }
  }
  /deep/.van-nav-bar__title {
    align-items: center;
    display: flex;
    flex: 2 1 0%;
    flex-direction: column;
    margin: 0;
    max-width: none;
    // max-width: max-content;
    .title {
      // padding: 0px 0.34rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--theme-text-color);
      span {
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        color: var(--theme-text-color-darken);
        display: -webkit-box;
        margin: 0px 0.12rem;
        overflow: hidden;
        text-align: center;
        text-overflow: ellipsis;
        vertical-align: middle;
      }
      .global-wrapper-title {
        font-size: 0.3rem;

        margin-top: -0.014rem;

        .ts {
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          display: -webkit-box;
          line-height: 1.2;
          overflow: initial;
          text-align: center;
          text-overflow: ellipsis;
          vertical-align: middle;
          word-break: break-word;
        }
      }
    }
  }

  /deep/.van-nav-bar__arrow {
    color: var(--theme-text-color);
  }

  /deep/.van-nav-bar__text {
    color: var(--theme-ant-primary-color-0);
    word-wrap: break-word;
    line-height: 1.1;
    text-align: center;
    white-space: pre-line;
    word-break: break-word;
    //  font-size: 24.576px;
    font-weight: 400;
    user-select: none;
    font: inherit;
    text-transform: none;
    box-sizing: border-box;
    pointer-events: none;
  }
  /deep/.van-ellipsis {
    white-space: inherit;
  }
}
.topMenu {
  -ms-flex-align: center;
  align-items: center;
  background-color: var(--theme-bg-color);
  display: -ms-flexbox;
  display: flex;
  height: 0.9rem;
  margin-top: 0;
  padding: 0 0.2rem;
  position: fixed;
  width: 100%;
  left: auto !important;
  margin: 0 auto;
  max-width: 450px;
  max-width: var(--theme-max-width);
  z-index: 1;
  .selectbox {
    margin: 0;
    display: -ms-flexbox;
    display: flex;
    height: 0.5rem;
  }
  .rightItem {
    -ms-flex-align: center;
    align-items: center;
    color: var(--theme-text-color-lighten);
    display: -ms-flexbox;
    display: flex;
    font-size: 0.24rem;
    padding-left: 0.12rem;
    > span:first-of-type {
      margin-right: 0.835rem;
    }
  }
}
.rateListBox {
  min-height: 7rem;
  --empty-height: 7rem;
  padding-top: 1rem;
  /deep/.van-list {
    > :nth-child(2n + 1) {
      background-color: var(--theme-main-bg-color);
    }
    > :nth-child(2n) {
      background-color: var(--theme-bg-color);
    }
  }
  .rate-item {
    padding: 0.2rem;
    border-radius: 0.1rem;
    display: -ms-flexbox;
    display: flex;
    background-color: var(--theme-main-bg-color);
    padding: 0.2rem;
    p {
      color: var(--theme-text-color-lighten);
      font-size: 0.2rem;
      text-align: center;
      span {
        color: var(--theme-text-color-lighten);
      }
    }
    p:first-of-type {
      margin-right: 0.2rem;
      padding-right: 0.2rem;
      width: 1.4rem;
    }
    p:nth-of-type(2) {
      margin-right: 0.2rem;
      width: 1.6rem;
    }
    p:nth-of-type(3) {
      margin-right: 0.2rem;
      width: 1.56rem;
    }
    p:nth-of-type(4) {
      -ms-flex: 1;
      flex: 1;
    }
  }
}
.myselect {
  max-width: 3.15rem;
  min-width: 1.6rem;
  width: 1rem !important;
  margin-right: 0.2rem;
}
</style>
