<template>
  <div class="box">
    <el-container>
      <el-header height=".9rem">
        <van-nav-bar
          @click-left="onClickLeft"
          right-text=" "
          :fixed="true"
          style="
            max-width: var(--theme-max-width);
            margin: 0 auto;
            left: 0;
            right: 0;
            height: 0.9rem;
          "
        >
          <template #left>
            <div>
              <i style="font-size: 0.24rem">
                <svg-icon
                  icon-class="comm_icon_fh"
                  style="
                    width: 1em;
                    height: 1em;
                    color: var(--theme-text-color);
                    fill: var(--theme-text-color-lighten);
                  "
                ></svg-icon>
              </i>
            </div>
          </template>

          <template #title>
            <div class="title">
              <span class="global-wrapper-title">
                <span
                  style="
                    width: 100%;
                    margin-bottom: -1.64706px;
                    padding-bottom: 1.64706px;
                    font-size: 0.3rem;
                  "
                  ><span
                    style="
                      vertical-align: middle;
                      display: inline-block;
                      width: 100%;
                    "
                  >
                    <span style="-webkit-line-clamp: 2" class="ts inner-text">
                      {{$t('about')}}</span
                    >
                  </span></span
                >
              </span>
            </div>
          </template>
        </van-nav-bar>
      </el-header>
      <el-main>
        <myScrollbarBar
          style="width: 100%; "
          st="y"
        >
          <div class="context">
            <div class="top">
              <img class="logo" src="@/assets/logo.png" />
              <div class="synopsis">
                <p>
                  <span>ID:</span>
                  {{ ac }}
                </p>
                <p><span>{{ $t('account') }}:</span> {{ myname }}</p>
              </div>
            </div>
            <div class="bottom">
              <p class="title">{{ $t('current device') }}</p>
              <div class="info">
                <div class="left">
                  <i
                    style="
                      display: inline-flex;
                      justify-content: center;
                      align-items: center;
                    "
                  >
                    <svg-icon
                      icon-class="icon_dl_sj"
                      focusable="false"
                      aria-hidden="true"
                      style="width: 1em; height: 1em"
                    ></svg-icon>
                  </i>
                </div>
                <div class="right">
                  <div class="item">
                    <p class="lp">{{ $t('application') }}</p>
                    <p class="rp">H5</p>
                  </div>
                  <div class="item">
                    <p class="lp">{{ $t('version') }}</p>
                    <p class="rp">-</p>
                  </div>
                  <div class="item">
                    <p class="lp">{{ $t('equipment type') }}</p>
                    <p class="rp">-</p>
                  </div>
                  <div class="item">
                    <p class="lp">{{ $t('system') }}</p>
                    <p class="rp">-</p>
                  </div>
                  <div class="item">
                    <p class="lp">{{ $t('ip area') }}</p>
                    <p class="rp">-</p>
                  </div>
                  <div class="item">
                    <p class="lp">{{ $t('login time') }}</p>
                    <p class="rp">-</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </myScrollbarBar>
        <div class="foot">
            <van-button class="btn">{{ $t('save photo') }}</van-button>
        </div>
      </el-main>
    </el-container>
  </div>
</template>
<script>
import myScrollbarBar from "@/components/phone/common/myScrollbarBar.vue";

export default {
  components: { myScrollbarBar },
  data() {
    return {
      active: 0,
      ac: "",
      myname: "",
    };
  },
  methods: {
    onClickLeft() {
      history.go(-1);
    },
  },
};
</script>
<style lang="less" scoped>
p {
  span {
    color: var(--theme-text-color-lighten);
  }
}
.context {
  height: 100%;
  overflow: auto;
  padding: 0.2rem 0.2rem 2rem;
  position: relative;
  width: 100%;
  .top {
    -ms-flex-align: center;
    -ms-flex-pack: center;
    align-items: center;
    background-color: var(--theme-main-bg-color);
    border-radius: 0.14rem;
    -webkit-box-shadow: 0 0.03rem 0.1rem 0 rgba(0, 0, 0, 0.06);
    box-shadow: 0 0.03rem 0.1rem 0 rgba(0, 0, 0, 0.06);
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 0.2rem;
    padding: 0.37rem 0 0.34rem;
    width: 100%;
    .logo {
      height: 0.66rem;
      -o-object-fit: contain;
      object-fit: contain;
    }
    .synopsis {
      -ms-flex-align: center;
      -ms-flex-pack: center;
      align-items: center;
      display: -ms-flexbox;
      display: flex;
      justify-content: center;
      margin-top: 0.36rem;
      p {
        color: var(--theme-text-color-darken);
        font-size: 0.22rem;
        margin-right: 0.54rem;
      }
    }
  }
  .bottom {
    background-color: var(--theme-main-bg-color);
    border-radius: 0.14rem;
    -webkit-box-shadow: 0 0.03rem 0.1rem 0 rgba(0, 0, 0, 0.06);
    box-shadow: 0 0.03rem 0.1rem 0 rgba(0, 0, 0, 0.06);
    margin-bottom: 0.2rem;
    width: 100%;
    .title {
      -ms-flex-align: center;
      -ms-flex-pack: justify;
      align-items: center;
      color: var(--theme-text-color-darken);
      display: -ms-flexbox;
      display: flex;
      font-size: 0.24rem;
      justify-content: space-between;
      line-height: 1.8;
      min-height: 0.9rem;
      padding: 0 0.3rem;
      text-align: left;
      width: 100%;
    }
    .info {
      -ms-flex-align: start;
      -ms-flex-pack: start;
      align-items: flex-start;
      border-top: 0.01rem solid var(--theme-color-line);
      display: -ms-flexbox;
      display: flex;
      justify-content: flex-start;
      padding: 0.2rem 0.2rem 0.15rem 0.3rem;
      width: 100%;
      .left {
        -ms-flex-align: center;
        -ms-flex-pack: center;
        align-items: center;
        border: 0.01rem solid var(--theme-color-line);
        border-radius: 0.14rem;
        color: var(--theme-primary-color);
        display: -ms-flexbox;
        display: flex;
        font-size: 0.5rem;
        height: 0.8rem;
        justify-content: center;
        width: 0.8rem;
      }
      .right {
        margin-left: 0.24rem;
        width: calc(100% - 1.04rem);
        .item {
          -ms-flex-align: center;
          -ms-flex-pack: start;
          align-items: center;
          display: -ms-flexbox;
          display: flex;
          font-size: 0.24rem;
          justify-content: flex-start;
          margin-bottom: 0.25rem;
          width: 100%;
          .lp {
            color: var(--theme-text-color-lighten);
            margin-right: 0.8rem;
            text-align: right;
            width: 20%;
          }
          .rp {
            color: var(--theme-text-color-darken);
            width: calc(80% - 0.8rem);
          }
        }
      }
    }
  }
}
/deep/.el-main,
/deep/.el-head {
  padding: 0;
}

.van-nav-bar {
  border-bottom: 0.01rem solid var(--theme-color-line);
  background: var(--theme-main-bg-color);
  /deep/.van-nav-bar__content {
    height: 0.9rem;
    padding: 0px 0.1rem 0px 0.2rem;
  }

  /deep/.van-nav-bar__right {
    width: 0.9rem;
    display: flex;
    justify-content: flex-end;
    position: inherit;
    padding: 0;
    font-size: 0.22rem;
  }
  /deep/.van-nav-bar__left {
    position: inherit;
    padding: 0;
    width: 0.8rem;
    div {
      align-items: center;
      color: var(--theme-text-color);
      cursor: pointer;
      display: flex;
      font-size: 0.2rem;
      font-size: 0.24rem;
      height: 0.8rem;
      width: 0.8rem;
    }
  }
  /deep/.van-nav-bar__title {
    align-items: center;
    display: flex;
    flex: 2 1 0%;
    flex-direction: column;
    margin: 0;
    max-width: none;
    // max-width: max-content;
    .title {
      // padding: 0px 0.34rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--theme-text-color);
      span {
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        color: var(--theme-text-color-darken);
        display: -webkit-box;
        margin: 0px 0.12rem;
        overflow: hidden;
        text-align: center;
        text-overflow: ellipsis;
        vertical-align: middle;
      }
      .global-wrapper-title {
        font-size: 0.3rem;

        margin-top: -0.014rem;

        .ts {
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          display: -webkit-box;
          line-height: 1.2;
          overflow: initial;
          text-align: center;
          text-overflow: ellipsis;
          vertical-align: middle;
          word-break: break-word;
        }
      }
    }
  }

  /deep/.van-nav-bar__arrow {
    color: var(--theme-text-color);
  }

  /deep/.van-nav-bar__text {
    color: var(--theme-ant-primary-color-0);
    word-wrap: break-word;
    line-height: 1.1;
    text-align: center;
    white-space: pre-line;
    word-break: break-word;
    //  font-size: 24.576px;
    font-weight: 400;
    user-select: none;
    font: inherit;
    text-transform: none;
    box-sizing: border-box;
    pointer-events: none;
  }
  /deep/.van-ellipsis {
    white-space: inherit;
  }
}
.foot{
    background-color: var(--theme-main-bg-color);
    bottom: 0;
    -webkit-box-shadow: 0 -.03rem .1rem 0 rgba(0,0,0,.1);
    box-shadow: 0 -.03rem .1rem 0 rgba(0,0,0,.1);
    left: auto;
    padding: .2rem .2rem .4rem;
    position: absolute;
    width: 100%;
    .btn{
        background-color: var(--theme-primary-color);
    border: none;
    color: var(--theme-primary-font-color);
    font-size: .24rem;
    height: .7rem;
    line-height: .7rem;
    text-align: center;
    width: 100%;
    border-radius: .14rem;
    border-radius: .14rem;
    }
}
</style>
