var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"wrap"},[_c('el-form',{ref:"editfrom",attrs:{"rules":_vm.rules,"model":_vm.editfrom}},[_c('el-form-item',{staticStyle:{"margin-bottom":"0.5rem"},attrs:{"prop":"account"},scopedSlots:_vm._u([{key:"error",fn:function(scope){return [_c('div',{staticClass:"err-explain"},[_vm._v(_vm._s(scope.error))])]}}])},[_c('el-input',{staticClass:"myinput",attrs:{"maxlength":"16","placeholder":_vm.$t('enter username')},on:{"focus":function($event){_vm.isAFocus = true},"blur":function($event){_vm.isAFocus = false}},model:{value:(_vm.editfrom.account),callback:function ($$v) {_vm.$set(_vm.editfrom, "account", $$v)},expression:"editfrom.account"}},[_c('template',{slot:"prefix"},[_c('i',{staticStyle:{"display":"inline-flex","justify-content":"center","align-items":"center","color":"var(--theme-text-color-lighten)","height":"0.25rem","width":"0.25rem","font-size":"0.18rem"}},[_c('svg-icon',{staticStyle:{"width":"0.25rem","height":"0.25rem","fill":"currentColor"},attrs:{"icon-class":"input_icon_zh"}})],1)]),_c('template',{slot:"suffix"},[_c('i',{directives:[{name:"show",rawName:"v-show",value:(
              _vm.editfrom.account.length > 0 ||
              (_vm.editfrom.account.length > 0 && _vm.isAFocus)
            ),expression:"\n              editfrom.account.length > 0 ||\n              (editfrom.account.length > 0 && isAFocus)\n            "}],staticStyle:{"display":"inline-flex","justify-content":"center","align-items":"center","color":"var(--theme-text-color-lighten)","height":"0.25rem","width":"0.25rem","font-size":"0.18rem","cursor":"pointer"},on:{"click":function($event){_vm.editfrom.account = ''}}},[_c('svg-icon',{staticStyle:{"width":"0.25rem","height":"0.25rem","fill":"currentColor"},attrs:{"icon-class":"input_icon_clear"}})],1)])],2)],1),_c('el-form-item',{staticStyle:{"margin-bottom":"0.5rem"},attrs:{"prop":"password"},scopedSlots:_vm._u([{key:"error",fn:function(scope){return [_c('div',{staticClass:"err-explain"},[_vm._v(_vm._s(scope.error))])]}}])},[_c('el-input',{staticClass:"myinput pass",attrs:{"maxlength":"16","type":_vm.showPsw ? 'text' : 'password',"placeholder":_vm.$t('enter password2')},on:{"focus":function($event){_vm.isPFocus = true},"blur":function($event){_vm.isPFocus = false}},model:{value:(_vm.editfrom.password),callback:function ($$v) {_vm.$set(_vm.editfrom, "password", $$v)},expression:"editfrom.password"}},[_c('template',{slot:"prefix"},[_c('i',{staticStyle:{"display":"inline-flex","justify-content":"center","align-items":"center","color":"var(--theme-text-color-lighten)","height":"0.25rem","width":"0.25rem","font-size":"0.18rem"}},[_c('svg-icon',{staticStyle:{"width":"0.25rem","height":"0.25rem","fill":"currentColor"},attrs:{"icon-class":"input_icon_mm"}})],1)]),_c('template',{slot:"suffix"},[_c('i',{directives:[{name:"show",rawName:"v-show",value:(
              _vm.editfrom.password.length > 0 ||
              (_vm.editfrom.password.length > 0 && _vm.isPFocus)
            ),expression:"\n              editfrom.password.length > 0 ||\n              (editfrom.password.length > 0 && isPFocus)\n            "}],staticStyle:{"display":"inline-flex","justify-content":"center","align-items":"center","color":"var(--theme-text-color-lighten)","height":"0.25rem","width":"0.25rem","font-size":"0.18rem","cursor":"pointer"},on:{"click":function($event){_vm.editfrom.password = ''}}},[_c('svg-icon',{staticStyle:{"width":"0.25rem","height":"0.25rem","fill":"currentColor"},attrs:{"icon-class":"input_icon_clear"}})],1),_c('i',{staticStyle:{"display":"inline-flex","justify-content":"center","align-items":"center","color":"var(--theme-text-color-lighten)","height":"0.25rem","width":"0.25rem","font-size":"0.36rem","cursor":"pointer","padding-right":"0.01rem","margin-left":"0.2rem"},on:{"click":function($event){_vm.showPsw = !_vm.showPsw}}},[_c('svg-icon',{staticStyle:{"width":"1em","height":"1em","fill":"currentColor"},style:(!_vm.showPsw
                  ? 'color: var(--theme-color-line);'
                  : 'color: var(--theme-primary-color);'),attrs:{"icon-class":!_vm.showPsw ? 'comm_icon_hide' : 'comm_icon_show'}})],1)])],2)],1)],1),_c('div',{staticClass:"submitBox"},[_c('van-button',{staticClass:"highlight-button",staticStyle:{"font-size":"0.24rem","height":"0.7rem","max-width":"100%","width":"100%"},attrs:{"loading":_vm.submitLoading},on:{"click":_vm.submitAddAgent}},[_vm._v(" "+_vm._s(_vm.$t('submit the registration'))+" ")])],1),_c('myDialog',{attrs:{"open":_vm.showSubordinateInfo,"originalClose":false,"width":"6rem","Title":_vm.$t('successful registration'),"effect":"info"},on:{"update:open":function($event){_vm.showSubordinateInfo=$event}}},[_c('van-row',{staticClass:"subordinateInfo",attrs:{"justify":"space-between","type":"flex"}},[_c('van-col',{attrs:{"span":"24"}},[_c('span',[_vm._v(_vm._s(_vm.$t('username'))+":")]),_c('span',[_vm._v(_vm._s(_vm.showaccount))])]),_c('van-col',{attrs:{"span":"24"}},[_c('span',[_vm._v(_vm._s(_vm.$t('login password'))+":")]),_c('span',[_vm._v(_vm._s(_vm.showpassword))])]),_c('van-col',{attrs:{"span":"24"}},[_vm._v(" "+_vm._s(_vm.$t('create account tips'))+" ")]),_c('van-col',{attrs:{"span":"24"}},[_c('van-button',{directives:[{name:"copy",rawName:"v-copy",value:(_vm.copyInfo),expression:"copyInfo"}],staticClass:"highlight-button",staticStyle:{"font-size":"0.24rem","height":"0.7rem","width":"100%"}},[_vm._v(_vm._s(_vm.$t('copy and close')))])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }