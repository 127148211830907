<template>
  <el-dialog
    :visible="open"
    :style="{ width: width }"
    :show-close="originalClose"
    :modal-append-to-body="modalBody"
    :custom-class="'myDialog '+ myCustomClass"
    :destroy-on-close="destroyClose"
    :append-to-body="appendBody"
    style="margin: 0 auto; "
    @close="closeDialog"
     top="0"
    login=""
  >
    <template slot="title">
      <div class="dialogTitle" :class="effect">
        <i class="anticon" v-if="effect==='error'">
          <i
            style="
              display: inline-flex;
              justify-content: center;
              align-items: center;
            "
          >
            <svg-icon
              icon-class="comm_icon_zyts"
              style="
                width: 1em;
                height: 1em;
                fill: currentColor;
              "
            ></svg-icon>
          </i>
        </i>

        {{ Title }}
      </div>
    </template>
    <slot></slot>
    <div class="closeIcon" @click="closeDialog" v-if="!originalClose">
      <i aria-hidden="true" focusable="false" class=""></i>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: "myDialog",
  components: {},
  mixins: [],

  props: {
    Title: String,
    open: Boolean,
    width: String,
    originalClose: Boolean,
    destroyClose:{
      type: Boolean,
      default:true,
    },
    modalBody:{
      type: Boolean,
      default:true,
    },
    appendBody:{
      type: Boolean,
      default:false,
    },
    myCustomClass:String,
    effect: {
      type: String,
      default: "default", // 默认为水平拖拽
      validator: function (value) {
        return ["warning", "info", "error","default"].includes(value);
      },
    },
  },
  data() {
    return { opendialog: true };
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    closeDialog() {
      // this.open=this.opendialog;
      this.$emit("update:open", false);
      this.$emit("closeDialog");
    },
  },
};
</script>
<style type="text/less" lang="less" scoped>
/deep/.el-dialog {
  background: var(--theme-main-bg-color);
  border: thin solid;
  border-color: var(--theme-color-line);
  border-radius: 0.2rem;
  width: 100%;
  .el-dialog__body {
    padding: 0;
    padding-left: 0.24rem;
    padding-right: 0.24rem;
    padding-bottom: 0.3rem;
  }
}

/deep/.el-dialog__close {
  color: #fff !important;
}
/deep/.el-dialog__headerbtn {
  right: 0;
  top: 0;
  font-weight: 700;
  transition: color 0.3s;
  font-size: 0.35rem;
  height: 0.6rem;
  line-height: 0.6rem;
  width: 0.6rem;
}
.dialogTitle {
  word-wrap: break-word;
  margin: 0;
  color: var(--theme-text-color-darken);
  line-height: 0.34rem;
  text-align: center;
  font-size: 0.3rem;
  font-weight: revert;
  border-bottom: 0;
display:block;
}
.closeIcon {
  -ms-flex-align: center;
  -ms-flex-pack: center;
  align-items: center;
  background: 0 0;
  border-radius: 50%;
  bottom: -0.88rem;
  display: -ms-flexbox;
  display: flex;
  height: 0.64rem;
  justify-content: center;
  margin-right: -0.3rem;
  position: absolute;
  right: 50%;
  top: auto;
  width: 0.64rem;
  i {
    display: inline-block;
    position: relative;
    width: 0.5984rem;
    height: 0.5984rem;
    background-image: url("@/assets/814195b6-3e29-450b-8c2b-bdb30e15091f.png");
    background-position: -5.1136rem -4.1616rem;
    background-size: 9.8736rem 9.6356rem;
  }
}
.error {
  color: var(--theme-secondary-color-error);
}
.info{
  color:var(--theme-secondary-color-success)
}
.default{
  color: var(--theme-text-color-darken);

}
</style>
