<template>
  <div class="musicBox">
    <div class="soundControl">
      <span>{{ $t('music') }}</span>
      <van-slider
        v-model="musicVolume"
        @change="setMusicVolume"
        :min="1"
        :max="100"
        class="soundSlider"
      />
      <i
        @click="setMuted(!muted)"
        style="
          display: inline-flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        "
      >
        <svg-icon
          :icon-class="!muted ? 'comm_icon_sy' : 'comm_icon_jy'"
          focusable="false"
          aria-hidden="true"
          style="width: 1em; height: 1em; fill: currentColor"
        ></svg-icon>
      </i>
    </div>
    <div class="playControl">
      <p v-if="currentTrack">{{ currentTrack.music_name }}</p>
      <div class="progressControl">
        <span>{{ formatTime(progress) }}</span>
        <div class="progressBox">
          <van-slider
            :value="progress"
            :max="duration"
            @change="setMusicProgress"
            class="progressSlider"
          />
        </div>
        <span>{{ getMusiclong() }}</span>
      </div>

      <div class="switchControl">
        <div
          class="orderPlay"
          @click="
            () => {
              setPlayType(playType === 2 ? 0 : playType + 1);
            }
          "
        >
          <i class="anticon">
            <svg-icon
              :icon-class="
                playType === 0
                  ? 'comm_icon_xhbf'
                  : playType === 1
                  ? 'comm_icon_dqxh'
                  : 'comm_icon_sj'
              "
              focusable="false"
              aria-hidden="true"
              style="width: 1em; height: 1em; fill: currentColor"
            ></svg-icon>
          </i>
          <p>
            {{
              playType === 0
                ? $t('cycle')
                : playType === 1
                ? $t('repeat')
                : $t('random')
            }}
          </p>
        </div>
        <div class="changeSongControl">
          <i class="anticon" @click="previous">
            <div class="hand">
              <i
                :style="
                  'display: inline-block;position: relative;width: 0.6rem;height: 0.6rem;background-image: url(' +
                  require('@/assets/814195b6-3e29-450b-8c2b-bdb30e15091f.png') +
                  ');background-position: -1.22143rem -3.36429rem;background-size: 7.77857rem 7.59107rem;'
                "
              >
              </i>
              <i class="playItem">
                <svg-icon
                  icon-class="comm_nav_sys"
                  focusable="false"
                  aria-hidden="true"
                  style="width: 1em; height: 1em; fill: currentColor"
                ></svg-icon>
              </i>
            </div>
          </i>
          <i
            class="anticon"
            @click="
              () => {
                if (isPlaying) {
                  pause();
                } else {
                  restore();
                }
              }
            "
          >
            <div class="hand">
              <i
                :style="
                  'display: inline-block;position: relative;width: 0.8rem;height: 0.8rem;background-image: url(' +
                  require('@/assets/814195b6-3e29-450b-8c2b-bdb30e15091f.png') +
                  ');background-position: 0rem -2.25455rem;background-size: 8.8rem 8.58788rem;padding: .2rem;margin: 0 .5rem!important;'
                "
              >
              </i>
              <i class="playItem playItem-zt">
                <svg-icon
                  :icon-class="isPlaying ? 'comm_nav_zt' : 'comm_nav_bf'"
                  focusable="false"
                  aria-hidden="true"
                  style="width: 1em; height: 1em; fill: currentColor"
                ></svg-icon>
              </i>
            </div>
          </i>
          <i class="anticon" @click="next">
            <div class="hand">
              <i
                :style="
                  'display: inline-block;position: relative;width: 0.6rem;height: 0.6rem;background-image: url(' +
                  require('@/assets/814195b6-3e29-450b-8c2b-bdb30e15091f.png') +
                  ');background-position: -1.22143rem -3.36429rem;background-size: 7.77857rem 7.59107rem;'
                "
              >
              </i>
              <i class="playItem">
                <svg-icon
                  icon-class="comm_nav_xys"
                  focusable="false"
                  aria-hidden="true"
                  style="width: 1em; height: 1em; fill: currentColor"
                ></svg-icon>
              </i>
            </div>
          </i>
        </div>
        <div class="playNumber">
          <div class="numberBox">{{ playlist.length }}</div>
          <p>{{ $t('song sheet') }}</p>
        </div>
      </div>
    </div>

    <div class="musicListControl">
      <section class="center">
        <div style="position: relative">
          <van-tabs
            v-model="active"
            background="var(--theme-main-bg-color)"
            title-active-color="var(--theme-primary-color)"
            title-inactive-color="var(--theme-text-color-darken)"
            color="#fff"
            :scrollspy="true"
            :swipeable="true"
            :animated="true"
            :ellipsis="false"
            @change="changeTabs"
          >
            <van-tab :title="$t('music system')">
              <van-empty
                v-if="list.length < 1"
                :image="require('@/assets/azf5f-28vaj.png')"
                :description="$t('no records')"
              />
              <van-list
                v-model="loading"
                :finished="finished"
                @load="loadList"
                :offset="-300"
                :loading-text="$t('loading')"
                v-else
              >
                <ul class="musicList">
                  <li
                    class="music-item"
                    v-for="(item, index) in list"
                    @click="playMusic(item)"
                  >
                    <div>
                      <span class="music-item-index">{{ item.id }}</span>
                      <span class="music-item-name">{{ item.music_name }}</span>
                      <span class="music-item-size">{{
                        convertBytes(item.music_size)
                      }}</span>
                    </div>
                    <div class="down">
                      <i class="down">
                        <svg-icon
                          :icon-class="
                            currentTrack && item.id === currentTrack.id
                              ? 'comm_nav_zt'
                              : 'comm_nav_bf'
                          "
                          focusable="false"
                          aria-hidden="true"
                          style="width: 1em; height: 1em; fill: currentColor"
                        ></svg-icon>
                      </i>
                    </div>
                  </li>
                </ul>
              </van-list>
            </van-tab>

            <van-tab :title="$t('my songs')">
              <van-empty
                v-if="playlist.length < 1"
                :image="require('@/assets/azf5f-28vaj.png')"
                :description="$t('no records')"
              />
              <van-list offset="0" loading-text="Cargando..." v-else>
                <ul class="musicList">
                  <li
                    class="music-item"
                    v-for="(item, index) in playlist"
                    @click="playMusic(item)"
                  >
                    <div>
                      <span class="music-item-index">{{ index+1 }}</span>
                      <span class="music-item-name">{{ item.music_name }}</span>
                      <span class="music-item-size">{{
                        convertBytes(item.music_size)
                      }}</span>
                    </div>
                    <div class="down">
                      <i class="down">
                        <svg-icon
                          :icon-class="
                            currentTrack && item.id === currentTrack.id
                              ? 'comm_nav_zt'
                              : 'comm_nav_bf'
                          "
                          focusable="false"
                          aria-hidden="true"
                          style="width: 1em; height: 1em; fill: currentColor"
                        ></svg-icon>
                      </i>
                    </div>
                  </li>
                </ul>
              </van-list>
            </van-tab>
          </van-tabs>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import myScrollbarBar from "@/components/phone/common/myScrollbarBar.vue";
import { mapState, mapActions } from "vuex";
import { getMusicList } from "@/api/index";

export default {
  name: "musicControl",
  components: { myScrollbarBar },
  mixins: [],
  props: {},
  data() {
    return {
      musicVolume: 0,
      progressInterval: null,
      active: 0,
      list: [],
      finished: false,
      loading: false,
      pageIndex: 0,
      pageSize: 50,
      lock: 0,
    };
  },
  computed: {
    ...mapState("musicPlayer", [
      "playlist",
      "currentTrack",
      "isPlaying",
      "progress",
      "volume",
      "playType",
      "history",
      "duration",
      "muted",
    ]),
  },
  watch: {
    volume(v) {
      this.musicVolume = v * 100;
 
    },
  },
  activated() {
    const self = this;
    if (this.progressInterval) {
      clearInterval(this.progressInterval);
    }
    this.progressInterval = setInterval(() => {
      self.updateProgress();
      // self.updateDuration();
    }, 1000);
  },

  mounted() {
    this.getList();
    const self = this;
    if (this.progressInterval) {
      clearInterval(this.progressInterval);
    }
    this.progressInterval = setInterval(() => {
      self.updateProgress();
      // self.updateDuration();
    }, 1000);
    this.musicVolume = this.volume*100;
    // const self=this;
    // console.log("jjjjjjjjjjj");
  },
  methods: {
    ...mapActions("musicPlayer", [
      "play",
      "pause",
      "next",
      "previous",
      "setVolume",
      "saveProgress",
      "loadProgress",
      "removeHistory",
      "updateProgress",
      "updateDuration",
      "setProgress",
      "setMuted",
      "setPlayType",
      "restore",
    ]),
    playMusic(item) {
      this.play(item);
      const self = this;
      if (this.progressInterval) {
        clearInterval(this.progressInterval);
      }
      this.progressInterval = setInterval(() => {
        self.updateProgress();
        // self.updateDuration();
      }, 1000);
    },
    closeDialog() {
      clearInterval(this.progressInterval);
      this.$emit("closeDialog");
    },

    changeTabs() {},
    getList() {
      getMusicList({
        page: this.pageIndex,
        count: this.pageSize,
      }).then(({ data }) => {
        if (data.code === 0) {
          if (data.data.music_list.length > 0) {
            this.list = this.list.concat(data.data.music_list);
          }
          if (data.data.music_list.length < this.pageSize) {
            this.finished = true;
          }
          this.loading = false;
        }
      });
    },
    loadList() {
      this.pageIndex = this.pageIndex + 1;
      this.getList();
    },
    convertBytes(bytes) {
      if (bytes < 1024) {
        return bytes + " bytes";
      } else if (bytes < 1024 * 1024) {
        return (bytes / 1024).toFixed(2) + " KB";
      } else {
        return (bytes / (1024 * 1024)).toFixed(2) + " MB";
      }
    },
    formatTime(seconds) {
      // if (isNaN(seconds) || seconds < 0) {
      //   return "Invalid input";
      // }
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = Math.round(seconds % 60);

      return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
        .toString()
        .padStart(2, "0")}`;
    },
    getMusiclong() {
      // console.log(this.duration);
      if (!this.duration) return "00:00";
      else {
        return this.formatTime(this.duration);
      }
    },
    setMusicVolume(v) {
      this.setVolume(v / 100);
    },
    setMusicProgress(v) {
      this.setProgress(v);
    },
  },
  beforeDestroy() {
    // console.log("xxxxxxx");
    clearInterval(this.progressInterval);
  },
};
</script>
<style type="text/less" lang="less" scoped></style>
