import axios from 'axios'
import store from '@/store';
import Qs from 'qs'
import { Message } from "element-ui";
import { getToken, getAC, getRefreshToken, removeRefreshToken, setRefreshToken, removeToken, removeAC, setAC, setToken } from '@/utils/auth'
import LocalStorageCache from "@/utils/localStorageCache.js";
import { hashKey, getBrowser, getOperatingSystem } from "@/utils/utils";

// 刷新 token 的函数
const refreshToken = (data) => {
    return request({
        url: '/index/refresh-token',
        method: 'post',
        data
    })
}
const request = (data, b, islogin) => {
    // create an axios instance

    const isJSON = !!b
    const service = axios.create({
        baseURL: process.env.VUE_APP_HOST_URL,
        timeout: 30000 // request timeout
    })
    service.interceptors.request.use(
        config => {
            if (getToken()) {
                config.headers['Token'] = getToken()
                config.headers['Account_Number'] = getAC()
            }
            if (config.method === 'get') {
                config.params = {
                    ...config.params
                }
            } else if (config.method === 'post') {
                if (isJSON) {
                    config['Content-Type'] = 'application/json'
                    config.data = JSON.parse(JSON.stringify(config.data))
                } else {
                    let isFormData = config.data instanceof FormData
                    if (!isFormData) {
                        config.data = Qs.stringify({
                            ...config.data
                        }, { indices: false })
                    }
                }

            }
            return config
        },
        error => {
            // Do something with request error
            Promise.reject(error)
        }
    )
    service.interceptors.response.use(
        response => {
            const res = response.data
            if (res.code === 61) {
                // removeToken();
                // removeAC();
                // window.location.href = '/'
                return refreshToken({ account_number: getAC(), refresh_token: getRefreshToken() }).then(refreshResponse => {
                    const refreshRes = refreshResponse.data;
                    if (refreshRes.code === 0) {
                        // console.log(refreshRes);
                        // 刷新 token 成功，重新设置 token
                        setToken(refreshRes.data.token);
                        setAC(refreshRes.data.account_number);
                        setRefreshToken(refreshRes.data.refresh_token);
                        // 重新发送原来的请求
                        response.config.headers['Token'] = getToken();
                        response.config.headers['Account_Number'] = getAC();
                        return service(data);
                    } else {
                        // 刷新 token 失败，清除 token 数据
                        store.dispatch('LogOut');
                        // removeToken();
                        // removeAC();
                        // removeRefreshToken();
                        // window.location.href = '/'
                        store.dispatch('triggerDialog', 1);
                        // Message({
                        //     message: 'Token 已过期，请重新登录',
                        //     type: 'error'
                        // });
                        // return Promise.reject(new Error('Token 已过期，请重新登录'));
                    }
                }).catch((d) => {
                    // console.log(d);
                    // 刷新 token 失败，清除 token 数据
                    // removeToken();
                    // removeAC();
                    // removeRefreshToken();
                    store.dispatch('LogOut');
                    // window.location.href = '/'
                    store.dispatch('triggerDialog', 1);
                    // Message({
                    //     message: 'Token 已过期，请重新登录',
                    //     type: 'error'
                    // });
                    // return Promise.reject(new Error('Token 已过期，请重新登录'));
                });
            }
            if (res.code === 110) {
                store.dispatch('LogOut');
                // removeToken();
                // removeRefreshToken();
                // removeAC();
                // window.location.href = '/'
                store.dispatch('triggerDialog', 1);
            }
            if (res.code !== 20000) {
                if (res.code === 400) {
                    if (res.errors) {
                        for (let i in res.errors) {
                            Message({
                                message: res.errors[i][0],
                                type: 'error'
                            })
                            break
                        }
                    } else {
                        Message({
                            message: res.message,
                            type: 'error'
                        })
                    }
                }
                return response
            } else {
                return response
            }
        },
        error => {
            return Promise.reject(error)
        }
    )
    //先发起异步请求网络
    const requestPromise = service(data);
    const cacheKey = "temp_" + hashKey(data);
    //如果有缓存则返回缓存
    if (data.isCache) {
        let cacheData = null;
        if (data.isforever) {
            cacheData = LocalStorageCache.get(cacheKey);
        } else {
            cacheData = LocalStorageCache.getCache(cacheKey);
        }

        if (cacheData) {
            return Promise.resolve(cacheData);
        }
    }

    //将数据缓存
    if (data.isCache) {
        requestPromise.then(response => {
            if (response && response.data.code === 0) {
                if (data.isforever) {
                    LocalStorageCache.set(cacheKey, { data: response.data }, 7 * 24 * 60 * 60 * 1000);
                } else {
                    LocalStorageCache.setCache(cacheKey, { data: response.data });
                }

            }
        });
    }

    return requestPromise;
    //  return service(data)
}
export default request
