<template>
  <div class="input-edit">
    <MyDatePicker
      v-model="startdate"
      :placeholder="$t('start date')"
      type="date"
      value-format="yyyy/MM/dd"
      :picker-options="pickerOptions"
      @cancel="cancel"
      @change="change"
    >
    </MyDatePicker>
    <slot name="range-separator">
      <span class="my-range-separator">{{ rangeSeparator }}</span>
    </slot>
    <MyDatePicker
      v-model="enddate"
      :placeholder="$t('end date')"
      type="date"
      value-format="yyyy/MM/dd"
      :picker-options="pickerOptions2"
       @cancel="cancel"
      @change="change"
    >
    </MyDatePicker>
  </div>
  
</template>
<script>
import MyDatePicker from "@/components/phone/common/my-date-picker/";
import { addMonths, format } from "date-fns";
export default {
  name: "myDatePickerCompose",
  components: {MyDatePicker},
  mixins: [],

  props: {

  },
  data() {
    return {
      startdate: "",
      enddate: "",
      pickerOptions: {
        disabledDate: (time) => {
          const today = new Date();
          const oneMonthAgo = addMonths(today, -1);
          return (
            time.getTime() > today.getTime() ||
            time.getTime() < oneMonthAgo.getTime()
          );
        },
        
      },
      pickerOptions2: {
        disabledDate: (time) => {
          const today = new Date();
          const oneMonthAgo = addMonths(today, -1);
          return (
            time.getTime() > today.getTime() ||
            time.getTime() < new Date(this.startdate)||!this.startdate
          );
        },
        
      },
      rangeSeparator :'-'
    };
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    cancel(){
        this.$emit("cancel");
    },
    change(){
        this.$emit("change", this.startdate,this.enddate);
    }
  },
};
</script>
<style type="text/less" lang="less" scoped>

.input-edit{
    -ms-flex-align: center;
    -ms-flex-pack: justify;
    align-items: center;
    background-color: var(--theme-main-bg-color);
    border: .01rem solid var(--theme-color-line);
    border-radius: .25rem;
    display: -ms-flexbox;
    display: flex;
    font-size: .16rem;
    height: .5rem;
    justify-content: space-between;
    overflow: hidden;
    padding: 0 .15rem;
    position: relative;
    width: 3.2rem;
    font-size: .2rem;
    width: 3.6rem;
}</style>
