<template>
    <div>
        <div class="content">
            <div>9991BET é uma casa de apostas com licença legal de apostas online emitida pela Zona Econômica e
                Porto Livre da Ilha de Man e Cagayan. Esta é uma organização de desenvolvimento internacional
                com uma escala que abrange toda a região Ásia-Pacífico. Com experiência e elevada reputação
                tendo servido mais de 10 milhões de jogadores, a 9991BET tem vindo a afirmar a sua posição e
                marca no mercado de jogos online. Como casa de apostas do M.A.N Entertainment Group, com sede em
                Manila, Filipinas - o grupo é considerado o maior da Ásia no mercado de apostas online, com uma
                enorme equipe. Com comprovada alta confiabilidade, a 9991BET é atualmente uma das casas de
                apostas claramente autenticada e possui licença certificada pela associação internacional de
                apostas online. A principal preocupação da 9991BET é a segurança do desenvolvimento de
                informações pessoais. A 9991BET garante não divulgar informações pessoais dos clientes a
                terceiros porque utiliza criptografia de dados com os mais altos padrões. Todas as informações
                pessoais são transmitidas via Secure Socket (padrão de criptografia SS 128 bits) e de forma
                segura em ambientes de gerenciamento seguros e não acessíveis à área da rede pública. Todos os
                dados internos e externos são limitados, monitorados de perto e gerenciados com rigor, trazendo
                uma sensação de segurança absoluta aos jogadores.</div>
        </div>
    </div>
</template>
<script>
export default {
    name: '',
    components: {

    },
    mixins: [],
    props: {

    },
    data() {
        return {

        }
    },
    computed: {

    },
    watch: {

    },
    mounted() {

    },
    methods: {

    }
};
</script>
<style type="text/less" lang="less" scoped>
.content {
    margin: 3vw;
    background:var(--theme-main-bg-color);
    padding: 3vw;
    border-radius: 0.3vw;
    border:1px solid #B2C5FD;
    color:#B2C5FD;
    font-size: 3vw;
}
</style>