<template>
  <div class="box">
    <el-container>
      <el-header height=".9rem">
        <van-nav-bar
          @click-left="onClickLeft"
          right-text=" "
          :fixed="true"
          style="
            max-width: var(--theme-max-width);
            margin: 0 auto;
            left: 0;
            right: 0;
            height: 0.9rem;
          "
        >
          <template #left>
            <div>
              <i style="font-size: 0.24rem">
                <svg-icon
                  icon-class="comm_icon_fh"
                  style="
                    width: 1em;
                    height: 1em;
                    color: var(--theme-text-color);
                    fill: var(--theme-text-color-lighten);
                  "
                ></svg-icon>
              </i>
            </div>
          </template>

          <template #title>
            <div class="title">
              <span class="global-wrapper-title">
                <span
                  style="
                    width: 100%;
                    margin-bottom: -1.64706px;
                    padding-bottom: 1.64706px;
                    font-size: 0.3rem;
                  "
                  ><span
                    style="
                      vertical-align: middle;
                      display: inline-block;
                      width: 100%;
                    "
                  >
                    <span style="-webkit-line-clamp: 2" class="ts inner-text">
                      {{ $t('message center') }}</span
                    >
                  </span></span
                >
              </span>
            </div>
          </template>
        </van-nav-bar>
      </el-header>
      <el-main>
        <van-tabs
          v-model="active"
          background="var(--theme-main-bg-color)"
          title-active-color="var(--theme-primary-color)"
          title-inactive-color="var(--theme-text-color-darken)"
          color="#fff"
          :scrollspy="true"
          :swipeable="true"
          :animated="true"
          :ellipsis="false"
        >
          <van-tab :title="$t('support')" title-class="van-title">
              <customerService></customerService>
           
          </van-tab>

          <van-tab :title="$('news')" title-class="van-title">
            <messageList></messageList>
          </van-tab>

          <van-tab :title="$('notification')" title-class="van-title">
            <messageList></messageList>
          </van-tab>

          <van-tab :title="$('rolling panel')" title-class="van-title">
            <notification></notification>
          </van-tab>

          <van-tab :title="$('suggestion bonus')" title-class="van-title">
        
              <suggestion></suggestion>
      
          </van-tab>
        </van-tabs>
      </el-main>
    </el-container>
  </div>
</template>
<script>
import customerService from "@/components/phone/notice/customerService";
import notification from "@/components/phone/notice/notification";
import suggestion from "@/components/phone/notice/suggestion";
import messageList from "@/components/phone/notice/messageList";

export default {
  name: "",
  components: {
    customerService,
    notification,
    suggestion,
    messageList,
  },
  mixins: [],
  props: {},
  data() {
    return {
      active: 0,
    };
  },
  computed: {},
  watch: {},
  activated() {
    this.active = Number(this.$route.query.index);
  },
  mounted() {
    this.active = Number(this.$route.query.index);
  },
  methods: {
    handleClick(name, title) {
      // if (title == this.$t("system notification")) {
      //   this.$refs.notification.reset();
      // }
    },
    onClickLeft() {
      history.go(-1);
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.el-main,
/deep/.el-head {
  padding: 0;
}
.van-nav-bar {
  border-bottom: 0.01rem solid var(--theme-color-line);
  background: var(--theme-main-bg-color);
  /deep/.van-nav-bar__content {
    height: 0.9rem;
    padding: 0px 0.1rem 0px 0.2rem;
  }

  /deep/.van-nav-bar__right {
    width: 0.9rem;
    display: flex;
    justify-content: flex-end;
    position: inherit;
    padding: 0;
    font-size: 0.22rem;
  }
  /deep/.van-nav-bar__left {
    position: inherit;
    padding: 0;
    width: 0.8rem;
    div {
      align-items: center;
      color: var(--theme-text-color);
      cursor: pointer;
      display: flex;
      font-size: 0.2rem;
      font-size: 0.24rem;
      height: 0.8rem;
      width: 0.8rem;
    }
  }
  /deep/.van-nav-bar__title {
    align-items: center;
    display: flex;
    flex: 2 1 0%;
    flex-direction: column;
    margin: 0;
    max-width: none;
    // max-width: max-content;
    .title {
      // padding: 0px 0.34rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--theme-text-color);
      span {
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        color: var(--theme-text-color-darken);
        display: -webkit-box;
        margin: 0px 0.12rem;
        overflow: hidden;
        text-align: center;
        text-overflow: ellipsis;
        vertical-align: middle;
      }
      .global-wrapper-title {
        font-size: 0.3rem;

        margin-top: -0.014rem;

        .ts {
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          display: -webkit-box;
          line-height: 1.2;
          overflow: initial;
          text-align: center;
          text-overflow: ellipsis;
          vertical-align: middle;
          word-break: break-word;
        }
      }
    }
  }

  /deep/.van-nav-bar__arrow {
    color: var(--theme-text-color);
  }

  /deep/.van-nav-bar__text {
    color: var(--theme-ant-primary-color-0);
    word-wrap: break-word;
    line-height: 1.1;
    text-align: center;
    white-space: pre-line;
    word-break: break-word;
    //  font-size: 24.576px;
    font-weight: 400;
    user-select: none;
    font: inherit;
    text-transform: none;
    box-sizing: border-box;
    pointer-events: none;
  }
  /deep/.van-ellipsis {
    white-space: inherit;
  }
}
[class*="van-hairline"]::after {
  border: 0;
}
/deep/.van-tabs {
  .van-tabs__wrap {
    border-bottom: thin solid var(--theme-color-line);
  }
}
.van-title {
  height: 0.7rem;
  line-height: 0.7rem;
  margin-right: 0.3rem;
  font-size: 0.22rem;
  margin-left: 0.2rem;
}
</style>
