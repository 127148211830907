<template>
  <section class="inputBox">
    <div class="number-password-input__label center">
      <span>{{ title }}</span>
      <span class="center">
        <i
          @click="showorhidePsw"
          style="
            display: inline-flex;
            justify-content: center;
            align-items: center;
            color: var(--theme-color-line);
          "
          :class="{prohibited_events:disabled}"
        >
          <svg-icon
            :icon-class="!showPsw ? 'comm_icon_hide' : 'comm_icon_show'"
            style="width: 1em; height: 1em; fill: currentColor"
            :style="
              !showPsw
                ? 'color: var(--theme-color-line);'
                : 'color: var(--theme-primary-color);'
            "
          ></svg-icon>
        </i>
      </span>
    </div>
    <input
      type="tel"
      :maxlength="maxlength"
      v-model="value"
      v-focus="isfocus"
      @change="inputChange"
      @input="validateInput"
      class="num-input"
      @blur="leaveInput"
    />
    <ul class="showbox" @click="inputClick"  :style="{'cursor':disabled?'not-allowed':'pointer'}">
      <li
        v-for="item in maxlength"
        class="pwd-input-item"
        :class="{
          'pwd-input-item-active': item === inputLength + 1 && isfocus,
          'pwd-input-item-fill': inputLength >= item,
        }"
      >
        <i v-if="!showPsw||item>inputLength"></i>
        <span v-if="showPsw && inputLength >= item" class="number-input__text">
          {{ value.charAt(item - 1) }}
        </span>
      </li>
    </ul>
  </section>
</template>
<script>
export default {
  name: "myNumberPassInput",
  components: {},
  mixins: [],
  props: {
    title: {
      type: String,
      required: true,
    },
    passvalue: {
      type: String,
      required: true,
    },
    disabled:{
      default:false,
      type:Boolean,
    }
  },
  data() {
    return {
      showPsw: false,
      maxlength: 6,
      insert: 0,
      value: "",
      isfocus: false,
      inputLength:0,
    };
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    showorhidePsw() {
      this.showPsw = !this.showPsw;
     
    },
    validateInput(){
      if(this.disabled){
        return;
      }
      this.value = this.value.replace(/\D/g, '');
     this.inputLength=this.value.length;
    },
    inputClick() {
      if(this.disabled){
        return;
      }
      this.isfocus = true;
    },
    leaveInput() {
      this.isfocus = false;
      this.$emit("leave", this.value);
      this.$emit("blur", this.value);
    },
    inputChange() {
      this.$emit("update:passvalue", this.value);
      this.$emit("change", this.value);
    },
  },
  directives: {
    Focus: {
      update: function (el, { value }) {
        if (value) {
          // if(true)就聚焦
          el.focus();
        }
      },
    },
  },
};
</script>
<style type="text/less" lang="less" scoped>
.inputBox {
  border-radius: 0.14rem;
  overflow: hidden;
  .number-password-input__label {
    -ms-flex-pack: justify;
    color: var(--theme-text-color-darken);
    justify-content: space-between;
    width: 100%;
    margin-top: .3rem;
    font-size: 0.22rem;
    height: 0.32rem;
    margin-bottom: 0.15rem;
  }
}
.center {
  -ms-flex-align: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  font-size: .36rem;
}
.num-input {
  height: 0.15rem;
  opacity: 0;
  position: fixed;
  width: 0.15rem;
  z-index: -1;
}
.migrate {
  left: -100%;
  opacity: 0;
}
.showbox {
  list-style: none;
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  margin: 0;
  max-width: 100%;
  overflow: hidden;
  border-radius: 0.14rem;
  border: thin solid var(--theme-color-line);
  height: 1rem;
  li:first-child.pwd-input-item-active:after{
    border-bottom-left-radius: .14rem;
    border-top-left-radius: .14rem
  }
  li:last-child.pwd-input-item-active:after{
    border-bottom-right-radius: .14rem;
    border-top-right-radius: .14rem
  }
}
.pwd-input-item {
  -ms-flex-align: center;
  -ms-flex-pack: center;
  align-items: center;
  background: var(--theme-main-bg-color);
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1;
  flex: 1;
  height: 100%;
  justify-content: center;
  position: relative;
  i {
    background: var(--theme-text-color-darken);
    border-radius: 50%;
    display: inline-block;
    opacity: 0;
    height: 0.26rem;
    width: 0.26rem;
  }
}
.pwd-input-item:not(:last-child) {
  border-right: 0.01rem solid var(--theme-color-line);
}
.pwd-input-item-active {
  i {
    height: 0.32rem;
    width: 0.025rem;
    -webkit-animation: flicker 1s infinite;
    animation: flicker 1s infinite;
    background: var(--theme-text-color-darken);
    border-radius: 0;
    display: inline-block;

    opacity: 1;
    -webkit-transition: none;
    transition: none;
  }
}
.pwd-input-item-active:after {
  border: 0.01rem solid var(--theme-primary-color);
  content: "";
  display: flex;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.pwd-input-item-fill {
  i {
    background: var(--theme-text-color-darken);
    border-radius: 50%;
    display: inline-block;
    height: 0.22rem;
    opacity: 0;
    width: 0.22rem;
    opacity: 1;
    height: 0.26rem;
    width: 0.26rem;
  }
  .number-input__text {
    font-size: 0.5rem;
    color: var(--theme-text-color-darken);
  }
}
@keyframes flicker {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
.prohibited_events{
  pointer-events: none;
}
</style>
