<template>
  <div class="drawer">
    <div class="skeleton" :class="!drawer ? 'turn-off-styles' : ''">
      <div class="door-handle" @click="switchDrawer">
        <i
          class="hendle"
          :class="!drawer ? 'hendle-off' : ''"
          style="
            display: inline-flex;
            justify-content: center;
            align-items: center;
          "
        >
          <svg-icon
            icon-class="img_jt_ss"
            style="width: 1em; height: 1em; fill: currentColor"
          ></svg-icon>
        </i>
      </div>
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "myDrawer",
  components: {},
  mixins: [],

  props: {},
  data() {
    return {
      drawer: false,
    };
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    switchDrawer() {this.drawer=!this.drawer},
  },
};
</script>
<style type="text/less" lang="less" scoped>
.drawer {
  min-height: 0.4rem;
  position: relative;
  width: 100%;
  .skeleton {
    background-color: var(--theme-main-bg-color);
    border-top: .01rem solid var(--theme-color-line);
    bottom: 0;
    font-size: .2rem;
    line-height: .22rem;
    padding: .2rem .2rem 0;
    position: fixed;
    -webkit-transition: -webkit-transform .3s;
    transition: -webkit-transform .3s;
    transition: transform .3s;
    transition: transform .3s,-webkit-transform .3s;
    width: 100%;
  }
  .turn-off-styles {
    -webkit-transform: translateY(calc(100% - 0.3rem));
    transform:translateY(calc(100% - .3rem));
  }
  .door-handle {
    -ms-flex-align: self-start;
    -ms-flex-pack: center;
    align-items: self-start;
    background-color: var(--theme-main-bg-color);
    border-radius: 0.5rem 0.5rem 0 0;
    border-top: 0.02rem solid var(--theme-color-line);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: var(--theme-primary-color);
    display: -ms-flexbox;
    display: flex;
    height: 0.35rem;
    justify-content: center;
    left: 50%;
    padding: 0.1rem;
    position: absolute;
    top: 0;
    -webkit-transform: translate(-50%, -98%);
    transform: translate(-50%, -98%);
    width: 0.7rem;
    .hendle {
      font-size: 0.2rem;
      -webkit-transition: -webkit-transform 0.3s;
      transition: -webkit-transform 0.3s;
      transition: transform 0.3s;
      transition: transform 0.3s, -webkit-transform 0.3s;
   
    }
    .hendle-off {
      -webkit-transform: rotate(-180deg);
      transform: rotate(-180deg);
    }
  }
}
</style>
