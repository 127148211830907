<template>
  <div class="app">
    <div class="main">
      <div>
        <van-nav-bar
          @click-left="onClickLeft"
          :fixed="true"
          style="
            z-index: 999;
            max-width: var(--theme-max-width);
            right: 0;
            left: 0;
            margin: 0 auto;
          "
        >
          <template #left>
            <svg-icon
              icon-class="comm_icon_fh"
              style="
                width: 1em;
                height: 1em;
                color: var(--theme-text-color);
                fill: var(--theme-text-color-lighten);
              "
            ></svg-icon>
          </template>
          <template #title
            ><span class="title">{{ title }}</span>
          </template>
        </van-nav-bar>
      </div>
      <div class="search">
        <el-input
          v-model="keyword"
          :placeholder="$t('look')"
          :clearable="true"
          suffix-icon="el-icon-search"
          size="small"
          @input="search"
        ></el-input>
      </div>
      <div class="gameBody">
        <el-container>
          <el-aside class="leftmenu" width="1.5rem" v-if="!hideLeftMenu">
            <van-sidebar
              v-model="activeIndex"
              :lazy-render="false"
              duration="0"
              @change="leftMenuChange"
            >
              <van-sidebar-item
                v-for="(item, index) in leftMenu"
                class="leftitem"
                :key="index"
                @click="leftMenuClick"
              >
                <template #title>
                  <div class="itembg">
                    <van-image
                      :src="
                        index === activeIndex
                          ? require('@/assets/btn_zc2_1.png')
                          : require('@/assets/btn_zc2_2.png')
                      "
                      :show-loading="false"
                      fit="fill"
                    >
                    </van-image>
                  </div>
                  <div class="itemtitlebg">
                    <van-image :src="item.icon" :lazy-load="true" fit="fill">
                      <template v-slot:loading>
                        <div class="imgload"></div>
                      </template>
                    </van-image>
                    <span class="span1">
                      <span class="span2">
                        <span class="span3">
                          {{ item.title }}
                        </span>
                      </span>
                    </span>
                  </div>
                </template>
              </van-sidebar-item>
            </van-sidebar>
          </el-aside>

          <el-container style="margin-left: 0.2rem">
            <el-main>
              <van-tabs
                v-model="topActive"
                class="mainBody"
                @change="topMenuChange"
                @click="topMenuClick"
                swipe-threshold="3"
              >
                <van-tab v-for="(item, index) in topMenu" :key="index">
                  <template #title>
                    <div class="menuTitle">
                      <div
                        class="titlebg"
                        :style="
                          index === topActive
                            ? 'background-color: var(--theme-primary-color);border: initial;color: var(--theme-primary-font-color);'
                            : ''
                        "
                      >
                        <span
                          style="
                            width: 100%;
                            margin-bottom: -1.42871px;
                            padding-bottom: 1.42871px;
                          "
                          class="span1"
                        >
                          <span style="font-size: 12.144px" class="span2">
                            <span class="span3" style="-webkit-line-clamp: 2">{{
                              item.title
                            }}</span>
                          </span>
                        </span>
                      </div>
                    </div>
                  </template>
                  <div
                    style="
                      margin-top: 0.2rem;
                      --cardRowSpacing: 0.1rem;
                      --cardColSpacing: 0;
                      --box-cut-width: fit-content;
                      --box-cut-height: fit-content;
                      --box-cut-border-radius: 0.14rem 0.2rem 0.2rem 0.2rem;
                      --favorited-icon-side: 0.4rem;
                      --favorited-icon-top: 0.1rem;
                      --favorited-icon-right: 0.1rem;
                      --favorited-icon-bottom: initial;
                      --favorited-icon-left: initial;

                      --card-benchmark: 1.6rem;
                      --favorited-icon-top: calc(var(--card-benchmark) * 0.033);
                      --favorited-icon-right: calc( var(--card-benchmark) * 0.033 );
                      --favorited-icon-bottom: initial;
                      --favorited-icon-left: initial;
                      --click-arean-expand-size: -0.06rem;
                      --favorited-icon-side: calc( var(--card-benchmark) * 0.206 );

                      --game-name-vertical-padding: 0.06rem 0.15rem;
                      --top-name-font-size: 0.24rem;
                      --bottom-name-font-size: 0.14rem;
                      --bottom-name-margin-top: 0.05rem;
                      --game-name-text-shadow: rgba(0, 0, 0, 0.3);
                      --game-name-text-stroke: none;
                      --game-name-font-weight: normal;
                      --game-platform-name-height: calc(var(--card-benchmark) * 0.44 );
                      --game-platform-name-padding: 0
                        calc(var(--card-benchmark) * 0.04)
                        calc(var(--card-benchmark) * 0.04);
                      --game-platform-name-font-size: calc( var(--card-benchmark) * 0.113 );
                    "
                    :style="[
                      {
                        '--outer-box-main-width': hideLeftMenu
                          ? '2.1rem'
                          : '1.6rem',
                      },
                      {
                        '--outer-box-main-height': hideLeftMenu
                          ? 'calc(var(--card-benchmark)*1.56)'
                          : '2.12rem',
                      },
                    ]"
                  >
                    <moreBatchDisplay
                      :gameList="gameList"
                      :pageSize="pageSize"
                      :pageIndex.sync="pageIndex"
                      @child-clicked="toEmbedded"
                      @flipping-pages="flippingPages"
                    />
                  </div>
                  <div class="more" v-if="gameList.total > pageSize">
                    <div class="morebox">
                      <van-pagination
                        :value="pageIndex"
                        :total-items="gameList.total"
                        :items-per-page="pageSize"
                        :show-page-size="3"
                        @change="flippingPages"
                        :force-ellipses="true"
                      >
                        <template #prev-text>
                          <svg-icon
                            icon-class="arrow_left"
                            style="
                              width: 1em;
                              height: 1em;
                              fill: currentColor;
                              color: var(--theme-text-color-lighten);
                            "
                          ></svg-icon>
                        </template>
                        <template #next-text>
                          <svg-icon
                            icon-class="arrow_right"
                            style="
                              width: 1em;
                              height: 1em;
                              fill: currentColor;
                              color: var(--theme-text-color-lighten);
                            "
                          ></svg-icon>
                        </template>
                      </van-pagination>
                    </div>
                  </div>
                </van-tab>
              </van-tabs>
            </el-main>
          </el-container>
        </el-container>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getVendorByCategory,
  getMoreGameByCategoryAndKindList,
  getAllVendorGamesByCategory,
  searchCategoryGame,
} from "@/api/index";
import moreBatchDisplay from "../../components/phone/moreBatchDisplay.vue"; // 路径应修改为实际的路径
import { mapState,mapActions } from 'vuex';
export default {
  components: { moreBatchDisplay },
  data() {
    return {
      category: "",
      showSideBar: true,
      title: this.$t("all"),
      keyword: "",
      // item: {},
      activeIndex: 0,
      topActive: 0,
      leftMenu: [],
      vendor: "",
      kind: "",
      topMenu: [
        {
          name: "popular",
          title: this.$t("popular"),
          val: 101,
        },
        {
          name: "recent",
          title: this.$t("recent"),
          val: 102,
        },
        {
          name: "favorite",
          title: this.$t("favorites"),
          val: 103,
        },
      ],
      gameList: {},
      pageIndex: 1,
      pageSize: 30,
      vendor_code: "",
      loadGameList: false,
      hideLeftMenu: false,
    };
  },
  mounted() {
    // this.leftMenu.push( this.$route.query.category);
    // 在这里处理组件被激活时的逻辑
    // const item = this.$route.params.item;
  },
  activated() {
    this.leftMenu = [];
    this.title = this.$route.query.category;
    this.category = this.$route.query.category;
    this.vendor_code = this.$route.query.vendor_code;
    if (this.category) {
      this.hideLeftMenu = false;
      this.leftMenu.push(this.initCategory(this.category));
    } else {
      this.hideLeftMenu = true;
      this.title = this.$t('popular');
    }
    this.getList();
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
      token: state => state.user.token,
    }),
  },
  methods: {
    ...mapActions(['triggerDialog']),
    topMenuClick(index) {
      const val = this.topMenu[index].val;
      if (val !== 99) {
        // console.log(name);
        this.keyword = "";
      }
      if (val !== 100 && val !== 99) {
        if (this.topMenu.length > 3) {
          this.topMenu.splice(0, 1);
          if (this.topActive !== 0) this.topActive = this.topActive - 1;
        }
        this.updateGameList(this.activeIndex, index);
      }
      if (this.activeIndex !== 0) {
        this.activeIndex = 0;
        this.updateGameList(this.activeIndex, index);
      }
    },
    leftMenuClick(index) {
      if (index !== 0) {
        if (this.topMenu.length === 3) {
          this.topActive = this.topActive + 1;
          this.topMenu.unshift({
            name: "all",
            title: this.$t("all"),
            val: 100,
          });
          this.updateGameList(index, this.topActive);
        }
        this.keyword = "";
        if (this.topMenu.length > 3) {
          this.topMenu.splice(0, 1);
          this.topMenu.unshift({
            name: "all",
            title: this.$t("all"),
            val: 100,
          });
        }
        this.topActive = 0;
        this.updateGameList(index, this.topActive);
      } else {
        if (this.topMenu.length > 3) {
          this.topMenu.splice(0, 1);
          if (this.topActive !== 0) this.topActive = this.topActive - 1;
          this.updateGameList(index, this.topActive);
        }
      }
    },
    onClickLeft() {
      history.go(-1);
    },
    getList() {
      if (!this.hideLeftMenu) {
        const category = { category: this.category };
        getVendorByCategory(category).then(({ data }) => {
          if (data.code === 0) {
            for (let i = 0; i < data.data.vendor_list.length; i++) {
              this.leftMenu.push(this.initVendor(data.data.vendor_list[i]));
              if (this.vendor_code === data.data.vendor_list[i].code) {
                this.activeIndex = i + 1;
                   if (this.activeIndex !== 0&&this.topMenu[0].val !== 100) {
                    // this.topMenu.splice(0, 1);
                  this.topMenu.unshift({
                    name: "all",
                    title: this.$t("all"),
                    val: 100,
                  });
                }
               
             
                // break;
              }
            }
            this.updateGameList(this.activeIndex, this.topActive);
          }
        });
      } else {
        this.updateGameList(this.activeIndex, this.topActive);
      }
    },
    updateGameList(l, t) {
      this.vendor = this.leftMenu[l] ? this.leftMenu[l].code : "";
      this.kind = this.topMenu[t].name;
      this.loadMore(0);
    },
    initCategory(name) {
      const item = {};
      item.title = name;
      item.isShow = false;
      item.code = name;
      item.icon = require("@/assets/" + name + "_act.png");
      return item;
    },
    initVendor(vendor) {
      const item = {};
      item.title = vendor.name;
      item.isShow = false;
      item.code = vendor.code;
      item.icon = vendor.logo_url;
      return item;
    },
    login(type) {
      this.type = type;
      this.triggerDialog(type);
      // this.dialogLogin = true;
    },

    toEmbedded(item) {
      if (this.token) {
        this.$router.push({
          path: "/embedded",
          query: { gameCode: item.game_code, name: item.show_name },
        });
      } else {
        this.login(1);
      }
      // }
    },
    search(v) {
      // this.topMenu = [];
      if (this.activeIndex !== 0) {
        this.activeIndex = 0;
      }

      if (this.topMenu.length === 3 && this.keyword !== "") {
        this.topMenu.unshift({
          name: "Pesquisar",
          title: this.$t("look"),
          val: 99,
        });
        this.updateGameList(this.activeIndex, 0);
        return;
      } else {
        if (this.topMenu[0].val !== 99) {
          this.topMenu.splice(0, 1);
          this.topMenu.unshift({
            name: "Pesquisar",
            title: this.$t("look"),
            val: 99,
          });
        }
      }

      this.updateGameList(this.activeIndex, this.topActive);
    },
    leftMenuChange() {},
    topMenuChange() {},

    loadMore(index) {
      // 每次点击展开按钮，显示的数据数量增加6
      const data = {
        category: this.category ? this.category : "slots",
        kind: this.kind.toLowerCase(),
        word: this.keyword,
        vendor: this.vendor,
        page: index,
        count: this.pageSize,
      };
      this.pageIndex = index + 1;
      this.gameList = {};
      this.loadGameList = true;
      if (this.keyword != "") {
        searchCategoryGame(data).then(({ data }) => {
          if (data.code === 0) {
            this.loadGameList = false;
            this.gameList = data.data;
          }
        });
      } else if (this.hideLeftMenu || this.category === this.vendor) {
        getMoreGameByCategoryAndKindList(data).then(({ data }) => {
          if (data.code === 0) {
            this.loadGameList = false;
            this.gameList = data.data;
          }
        });
      } else {
        getAllVendorGamesByCategory(data).then(({ data }) => {
          if (data.code === 0) {
            this.loadGameList = false;
            this.gameList = data.data;
          }
        });
      }
    },

    flippingPages(page) {
      this.loadMore(page - 1);
    },
  },
};
</script>
<style type="text/less" lang="less" scoped>
.app {
  // font-size: 0.8vw;
  background: var(--theme-bg-color);

  // min-height: 100vh;
  //overflow-x: hidden;
  /deep/ .van-hairline--bottom::after {
    border-bottom-width: 0;
  }
  /deep/.el-main {
    padding: 0;
  }
  .main {
    -ms-flex-pack: center;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    padding-top: 0.2rem;
    flex-direction: column;
    /deep/ .van-nav-bar {
      background: var(--theme-main-bg-color);
    }
    .title {
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      line-height: 1.2;
      overflow: hidden;
      overflow: initial;
      text-align: center;
      text-overflow: ellipsis;
      vertical-align: middle;
      word-break: break-word;
      font-size: .3rem;
      color: var(--theme-text-color-darken);
    }
    .search {
      background: transparent none repeat 0 0 / auto auto padding-box border-box
        scroll;
      background: initial;
      -webkit-box-shadow: none;
      box-shadow: none;
      height: 0.7rem;
      padding: 0.2rem 0.2rem 0;
      margin-top: 0.7rem;
      /deep/.el-input__inner {
        background: transparent none repeat 0 0 / auto auto padding-box
          border-box scroll;
        background: initial;
        border-radius: 0.25rem;
        font-size: 0.22rem;
        padding: 0 0.2rem;
        height: 0.5rem;
        border: 1px solid var(--theme-color-line);
        //var(--theme-color-line);
        //var(--theme-text-color-darken)
        // transition: border-color .2s cubic-bezier(var(--theme-ant-primary-color-13));
      }
      /deep/ .el-input__inner:hover {
        border: 1px solid var(--theme-text-color-darken);
      }
    }

    .gameBody {
      margin-top: 0.2rem;
      padding: 0 0.2rem;
      .leftmenu {
        width: 1.3rem;
        overflow: visible;

        .leftitem {
          margin: 0.2rem 0;
          cursor: pointer;
          height: 0.9rem;
          position: relative;
          width: 1.3rem;
          z-index: 0;
          font-size: 0.14rem;
          /deep/ .van-sidebar-item__text {
            position: initial;
            height: 0.9rem;
            width: 1.3rem;
            position: relative;
          }
          .itembg {
            -webkit-box-shadow: var(--theme-aside-active-box-shadow);
            box-shadow: var(--theme-aside-active-box-shadow);
            border-radius: 0.18rem;
            -webkit-box-shadow: 0 0.02rem 0.04rem 0
              var(--theme-aside-no-active-box-shadow);
            box-shadow: 0 0.02rem 0.04rem 0
              var(--theme-aside-no-active-box-shadow);
            height: 100%;
            overflow: hidden;
            position: absolute;
            width: 100%;
            z-index: -1;
            cursor: pointer;
          }
          .itemtitlebg {
            -ms-flex-align: center;
            -ms-flex-pack: center;
            align-items: center;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-direction: column;
            flex-direction: column;
            height: 100%;
            justify-content: center;
            // padding: 0 0.05rem;
            width: 100%;
            .van-image {
              -ms-flex-align: center;
              -ms-flex-pack: center;
              align-items: center;
              display: -ms-flexbox;
              display: flex;
              height: 0.5rem;
              justify-content: center;
              width: 0.5rem;
            }
            .span1 {
              // width: 100%;
              margin-bottom: -1.56009px;
              padding-bottom: 1.56009px;
              font-size: 0.24rem;
              line-height: 1.2;
              position: relative;
              width: 100%;
              .span2 {
                font-size: 0.24rem;
                vertical-align: middle;
                display: inline-block;
                width: 100%;
                line-height: 1.2;
                .span3 {
                  -webkit-line-clamp: 2;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  display: -webkit-box;
                  line-height: 1.2;
                  overflow: initial;
                  text-align: center;
                  text-overflow: ellipsis;
                  vertical-align: middle;
                  word-break: break-word;
                }
              }
            }
          }
        }
        /deep/.van-sidebar {
          width: auto;
        }
        /deep/.van-sidebar-item {
          padding: 0;
          background-color: transparent;
          color: var(--theme-left-nav-text-color);
        }
        /deep/.van-sidebar-item--select {
          color: var(--theme-left-nav-text-active-color);
          pointer-events: none;
        }
        /deep/ .van-sidebar-item--select::before {
          position: absolute;
          top: 50%;
          left: 0;
          width: 4px;
          height: 16px;
          background-color: transparent;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
          content: "";
        }
        /deep/ .van-sidebar-item:not(:last-child)::after {
          border-bottom-width: 0px;
        }
      }
      .mainBody {
        /deep/.van-tabs__wrap {
          height: 0.55rem;
          .van-tabs__nav {
            background-color: transparent;
            height: 0.55rem;
            .van-tab__text {
              height: 0.55rem;
              overflow: visible;
            }
            .van-tabs__line {
              position: absolute;
              bottom: 0;
              left: 0;
              z-index: 1;
              width: 0;
              height: 0;
              background-color: transparent;
              border-radius: 0;
            }
          }
        }
        .menuTitle {
          border-bottom: none;
          color: var(--theme-text-color);
          font-size: 0.16rem;
          height: 100%;
          .titlebg {
            border-radius: 0.14rem;
            width: 1.4rem;
            align-items: center;
            background-color: var(--theme-main-bg-color);
            border: 0.01rem solid var(--theme-color-line);
            display: flex;
            height: 100%;
            justify-content: center;
            font-size: 0.16rem;
            .span1 {
              font-size: 0.22rem;
              word-break: break-word;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              display: -webkit-box;
              // font-size: .24rem;
              line-height: 1.2;
              max-width: 1.2rem;
              overflow: hidden;
              text-overflow: ellipsis;
              vertical-align: middle;
              width: 100%;
              text-align: center;
              white-space: pre-line;
              .span2 {
                word-break: break-word;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                font-size: 0.24rem;
                line-height: 1.2;
                max-width: 1.2rem;
                overflow: hidden;
                text-overflow: ellipsis;
                vertical-align: middle;
                width: 100%;
                text-align: center;
                white-space: pre-line;
                .span3 {
                  font-size: 0.22rem;
                  word-break: break-word;
                  line-height: 1.2 !important;
                  -webkit-box-orient: vertical;
                  max-width: 1.2rem;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  vertical-align: middle;
                  width: 100%;
                  text-align: center;
                }
              }
            }
          }
        }
      }
    }
  }
}
/deep/.el-loading-mask {
  opacity: 0.4;
}
/deep/.el-dialog {
  background: var(--theme-main-bg-color);
  // border-radius: 1vw;
}

/deep/.el-dialog__close {
  color: #fff !important;
}


.more {
  -ms-flex-pack: center;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  margin-top: 0.3rem;
  box-sizing: border-box;
  color: var(--theme-text-color);
  .morebox {
    color: var(--theme-text-color);
    font-size: 0.14rem;
    .scroll-text {
      color: var(--theme-text-color);
      display: block;
      font-size: 0.22rem;
      margin-top: 0.02rem;
      margin-left: 0.08rem;
      cursor: pointer;
    }
    // .pagination-prev {
    //   border-radius: 0.1rem;
    //   font-size: 0.2rem;
    //   height: 0.5rem;
    //   line-height: 0.5rem;
    //   margin: 0 0.1rem 0 0;
    //   width: 0.5rem;
    // }
    /deep/.van-pagination {
      -webkit-font-feature-settings: "tnum";
      font-feature-settings: "tnum";
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      color: rgba(0, 0, 0, 0.65);
      font-size: 14px;
      font-variant: tabular-nums;
      line-height: 1.5;
      display: -ms-flexbox;
      display: flex;
      .van-pagination__item {
        border-radius: 0.1rem;
        font-size: 0.2rem;
        height: 0.5rem;
        line-height: 0.5rem;
        margin: 0 0.1rem 0 0;
        width: 0.5rem;
        -webkit-flex: 0;
        flex: 0;
        background-color: var(--theme-main-bg-color);
        color: #fff;
        border: 1px solid #d9d9d9;
        list-style: none;
        text-align: center;
        min-width: 0.4rem;
      }
      .van-pagination__item::after {
        border-width: 0;
      }
      .van-pagination__item--active {
        color: var(--theme-primary-font-color);
        background-color: var(--theme-primary-color);
      }
      .van-pagination__prev {
        min-width: 0.4rem;
        padding: 0;
        font-size: 0.2rem;
      }
    }
  }
}
/deep/.el-input__icon {
  line-height: 0.5rem !important;
}
/deep/.el-input__inner {
  color: var(--theme-text-color-darken);
}
/deep/.van-nav-bar__content{
  height: .9rem;
}
</style>
