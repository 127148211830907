var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box"},[_c('div',{staticClass:"pass-security"},[_c('span',[_vm._v(_vm._s(_vm.$t('strength')))]),_c('span',{staticClass:"item",class:{
        isbred: _vm.securityLevel === 1,
        isbOrange: _vm.securityLevel === 2,
        isbH: _vm.securityLevel === 3,
        isbGreen: _vm.securityLevel === 4,
      }}),_c('span',{staticClass:"item",class:{
        isbOrange: _vm.securityLevel === 2,
        isbH: _vm.securityLevel === 3,
        isbGreen: _vm.securityLevel === 4,
      }}),_c('span',{staticClass:"item",class:{ isbH: _vm.securityLevel === 3, isbGreen: _vm.securityLevel === 4 }}),_c('span',{staticClass:"item",class:{ isbGreen: _vm.securityLevel === 4 }})])])
}
var staticRenderFns = []

export { render, staticRenderFns }