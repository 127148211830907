<template>
  <div class="wrap">
    <div class="hoje">
      <div class="title">
        <label><span>{{ $t('available for redemption today') }}</span></label>
        <div><span> 0,00</span></div>
      </div>
    </div>
    <div class="cash-back-tabs">
      <el-container style="margin-left: 0.2rem">
        <el-main style="min-height: 6rem">
          <div class="leftmenu">
            <van-sidebar
              v-model="activeIndex"
              :lazy-render="false"
              duration="0"
              @change="leftMenuChange"
            >
              <van-sidebar-item
                v-for="(item, index) in leftMenu"
                class="leftitem"
                :key="index"
              >
                <template #title>
                  <img
                    :src="
                      index === activeIndex
                        ? require('@/assets/btn_zc1_1.png')
                        : require('@/assets/btn_zc1_2.png')
                    "
                  />

                  <div class="icon">
                    <i
                      style="
                        display: inline-flex;
                        justify-content: center;
                        align-items: center;
                      "
                    >
                      <svg-icon
                        height="1em"
                        width="1em"
                        :icon-class="item.category"
                        style="fill: currentColor"
                      ></svg-icon
                    ></i>
                  </div>
                  <div class="text">
                    <span class="inner-text">
                      {{ item.category }}
                    </span>
                  </div>
                </template>
              </van-sidebar-item>
            </van-sidebar>

            <div class="leftButton">
              <van-button
                disabled
                class="normal-button"
                style="border-radius: 0.1rem; font-size: 0.2rem; height: 0.5rem"
                >{{ $t('rescue all') }}</van-button
              >
            </div>
            <div class="leftButton">
              <van-button
                class="highlight-button"
                style="height: 0.5rem; font-size: 0.2rem"
                >{{ $t('historic') }}</van-button
              >
            </div>
          </div>

          <div class="common-tabs-content">
            <div>
              <ul class="rightlist">
                <template
                  v-if="
                    leftMenu[activeIndex] &&
                    leftMenu[activeIndex].vendor_rebate.length > 0
                  "
                >
                  <li
                    class="vendor-item"
                    v-for="(item, index) in leftMenu[activeIndex].vendor_rebate"
                  >
                    <div class="vendor-item-body" @click="goPath(item)">
                      <div>
                        <div class="vendor-item-icon">
                          <img
                            :src="item.icon"
                            style="width: 0.5rem; height: 0.5rem;margin: 0 auto;display: block;margin-right:.1rem;"
                          />
                          <div class="vendor-valid-bets">
                            <label>{{ $t('valid bets') }}</label>
                            <span> {{ formatNumberToString(item.bet) }}</span>
                          </div>
                        </div>
                        <div class="vendor-valid-cashback">
                          <div>
                            <label>{{ $t('cashback tax') }}</label>
                            <span>{{item.rebate_rate*100}}%</span>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div class="vendor-valid-cashback-progress">
                          <el-progress
                            :show-text="true"
                            :stroke-width="10"
                            :percentage="item.next_rebate_rate*100"
                            :text-inside="true"
                            text-color="#fff"
                            :format="()=>{
                            return  progressText(item)
                            }"
                            define-back-color="var(--theme-text-color-placeholder)"
                            color="var(--theme-secondary-color-success)"
                          ></el-progress>
                        </div>
                        <div class="vendor-valid-collectable">
                          <label>{{ $t('collectible') }}</label>
                          <span class="isOrange"> {{ formatNumberToString(item.rebate) }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="vendor-item-right">
                      <i style="display: inline-flex; justify-content: center; align-items: center;">
                  <svg-icon
                    icon-class="comm_icon_fh"
                    style="
                      width: 1em;
                      height: 1em;
                      fill: var(--theme-text-color-lighten);
                    "
                  ></svg-icon>
                </i>
                    </div>
                  </li>
                </template>

                <van-empty
                  v-else
                  :image="require('@/assets/azf5f-28vaj.png')"
                  description="Sem dados disponíveis"
                />
              </ul>
            </div>
          </div>
        </el-main>
      </el-container>
    </div>
  </div>
</template>
<script>
import {
  getRebateInfo,
  getRebateRate,
  receiveRebate,
  getRebateRecord,
} from "@/api/index";
import { formatNumberToString2 } from "@/utils/utils";

export default {
  name: "",
  components: {},
  mixins: [],
  props: {},
  data() {
    return {
      activeIndex: 0,
      leftMenu: [],
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.getRebateInfo();
  },
  methods: {
    goPath(item){
      //vendor
      // console.log(this.leftMenu[this.activeIndex]);
      this.$router.push({
          path: "/cashback",
          query: { category: this.leftMenu[this.activeIndex].category, vendor: item.vendor },
        });
      // console.log(item);
      //cashback
    },
    getRebateInfo() {
      getRebateInfo().then(({ data }) => {
        if (data.code === 0) {
          this.leftMenu = data.data.category_rebate_infos;
        }
      });
    },
    leftMenuChange() {
      // console.log(this.leftMenu[this.activeIndex]);
    },
    formatNumberToString(v) {
      return formatNumberToString2(v);
    },
    progressText(item) {
      // console.log(item);
      return this.$t('bet to receive',{num:item.next_rebate_remain,p:item.next_rebate_rate});
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.el-main {
  padding: 0;
  padding-top: 0.2rem;
  display: flex;
  height: 100%;
  justify-content: space-between;
  position: relative;
}
.wrap {
  background-color: var(--theme-bg-color);
  min-height: calc(100vh - 1.24rem);
  width: 100%;
  padding: 0 0 1.24rem;
  position: relative;
  .hoje {
    -ms-flex-pack: justify;
    background-color: var(--theme-bg-color);
    // height: .7rem;
    justify-content: space-between;
    left: 0;
    padding: 0 0.2rem;
    position: absolute;
    right: 0;
    // top: .2rem;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    width: 100%;
    .title {
      color: var(--theme-text-color);
      font-size: 0.18rem;
      padding-right: 0.4rem;
      -ms-flex-align: center;
      align-items: center;
      display: -ms-flexbox;
      display: flex;
      font-size: 0.22rem;
      padding-right: 0;
      label {
        color: var(--theme-text-color-lighten);
        margin-right: 0.04rem;
        span {
          display: inline-block;
          width: max-content;
        }
      }
    }
  }
  .cash-back-tabs {
    padding-top: 0.7rem;
    min-height: 6rem;
    .leftmenu {
      width: 1.6rem;
      .leftitem {
        /deep/.van-sidebar-item__text {
          -ms-flex-align: center;
          -ms-flex-pack: center;
          align-items: center;
          cursor: pointer;
          display: -ms-flexbox;
          display: flex;
          justify-content: center;
          line-height: 0.24rem;
          overflow: visible;
          position: relative;
          text-align: center;
          word-break: break-word;
          border-radius: 0.1rem;
          height: 0.6rem;
          white-space: pre-wrap;
          -ms-flex-negative: 0;
          flex-shrink: 0;
          font-size: 0.24rem;
          pointer-events: none;
          -webkit-box-shadow: 0 0.03rem 0.09rem 0
            var(--theme-aside-no-active-box-shadow);
          box-shadow: 0 0.03rem 0.09rem 0
            var(--theme-aside-no-active-box-shadow);
          min-height: 0.7rem;
          padding: 0 0.04rem 0 0.06rem;
          img {
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            // z-index: -1;
            border-style: none;
            vertical-align: middle;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
          }

          .icon {
            font-size: 0.48rem;
            width: 0.44rem;
            position: relative;
            box-sizing: border-box;
            i {
              font-size: 0.44rem;
              height: 0.52rem;
            }
          }

          .text {
            -ms-flex: 1;
            flex: 1;
            position: relative;
            width: 0.96rem;
            font-size: 0.24rem;
            .inner-text {
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              display: -webkit-box;
              line-height: 1.2;
              overflow: hidden;
              overflow: initial;
              text-align: center;
              text-overflow: ellipsis;
              vertical-align: middle;
              word-break: break-word;
              width: 100%;

              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              display: -webkit-box;
              line-height: 1.2;
              overflow: hidden;
              padding: 0.02rem 0;
              text-overflow: ellipsis;
              vertical-align: middle;
              white-space: pre-line;
              width: 100%;
            }
          }
        }
      }
      /deep/.van-sidebar {
        width: auto;
      }
      /deep/.van-sidebar-item {
        padding: 0;
        background-color: transparent;
        color: var(--theme-left-nav-text-color);
      }
      /deep/.van-sidebar-item--select {
        color: var(--theme-left-nav-text-active-color);
        pointer-events: none;
      }
      /deep/ .van-sidebar-item--select::before {
        position: absolute;
        top: 50%;
        left: 0;
        width: 4px;
        height: 16px;
        background-color: transparent;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        content: "";
      }
      /deep/ .van-sidebar-item:not(:last-child)::after {
        border-bottom-width: 0px;
      }
      .leftButton {
        -ms-flex-align: center;
        -ms-flex-pack: center;
        align-items: center;
        color: var(--theme-left-nav-text-color);
        cursor: pointer;
        // display: -ms-flexbox;
        // display: flex;
        font-size: 0.2rem;
        justify-content: center;
        line-height: 0.24rem;
        overflow: visible;
        position: relative;
        text-align: center;
        width: 100%;
        word-break: break-word;
      }
      .leftButton:not(:first-child) {
        margin-top: 0.2rem;
      }
      .leftitem + .leftitem {
        margin-top: 0.2rem;
      }
    }
    .common-tabs-content {
      margin-bottom: 0;
      padding-right: 0.2rem;
      width: 5.6rem;
      .rightlist {
        width: 100%;
        .vendor-item {
          -ms-flex-align: center;
          -ms-flex-pack: justify;
          align-items: center;
          background-color: var(--theme-main-bg-color);
          border-radius: 0.12rem;
          -webkit-box-shadow: 0 0.05rem 0.18rem 0 rgba(0, 0, 0, 0.06);
          box-shadow: 0 0.05rem 0.18rem 0 rgba(0, 0, 0, 0.06);
          display: -ms-flexbox;
          display: flex;
          font-size: 0.2rem;
          justify-content: space-between;
          margin-bottom: 0.2rem;
          min-height: 1.06rem;
          padding: 0.2rem 0 0.2rem 0.1rem;
          .vendor-item-body {
            -ms-flex: 1;
            flex: 1;
            padding-left: 0.1rem;
            color: var(--theme-text-color-darken);
            > div {
              -ms-flex-pack: justify;
              display: -ms-flexbox;
              display: flex;
              justify-content: space-between;
            }
            .vendor-item-icon {
              -ms-flex-align: center;
              align-items: center;
              display: -ms-flexbox;
              display: flex;
            }
            .vendor-valid-bets {
              -ms-flex-align: baseline;
              align-items: baseline;
              display: -ms-flexbox;
              display: flex;
            }
            .vendor-valid-cashback {
              -ms-flex-align: center;
              align-items: center;
              display: -ms-flexbox;
              display: flex;

              -ms-flex-negative: 0;
              flex-shrink: 0;
              padding-left: 0.14rem;
              padding-right: 0.04rem;
              width: 2.01rem;
              > div {
                -ms-flex-align: baseline;
                display: -ms-flexbox;
                display: flex;
                -ms-flex-align: center;
                align-items: center;
              }
            }
            .vendor-valid-collectable {
              -ms-flex-negative: 0;
              flex-shrink: 0;
              padding-left: 0.14rem;
              padding-right: 0.06rem;
              width: 1.76rem;
              padding-right: 0.04rem;
              width: 2.01rem;
            }
            .vendor-valid-cashback-progress {
              -ms-flex-align: center;
              align-items: center;
              display: -ms-flexbox;
              display: flex;
              position: relative;
              width: 2.6rem;
              font-size: 0.2rem;
              /deep/.el-progress {
                width: 100%;
              }
              /deep/.el-progress-bar__innerText {
                -webkit-background-clip: text !important;
                left: 0;
                top: 60%;
                position: absolute;
                overflow: hidden;
                text-align: center;
                -webkit-transform: translate(0, -50%);
                transform: translate(0, -50%);
                // width: 94%;
                text-overflow: ellipsis;
              }
              /deep/.el-progress-bar__outer {
                height: 0.2rem !important;
              }
            }
            /deep/ .el-progress-bar__innerText {
              font-size: 0.2rem;
            }
          }
          .vendor-item-right{
            color: var(--theme-color-line);
            padding: 0 .18rem 0 0;
            svg{
              display: block;
    height: .26rem;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    width: .26rem;
            }
          }
        }
        label {
          color: var(--theme-text-color-lighten);
          margin-right: 0.04rem;
          -ms-flex-preferred-size: 0;
          //flex-basis: 0;
        }
      }
    }
  }
}
html[data-device="mobile"] .leftButton {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  font-size: 0.24rem;
}
</style>
