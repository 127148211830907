<template>
  <div class="app">
    <section class="topMenu">
      <div>
        <mySelect
          :items="dateItems"
          myCustomClass="myselect"
          :selectItem.sync="dateselectItem"
          @change="itemChange"
        ></mySelect>
        <mySelect
          :items="typeItems"
          myCustomClass="myselect "
          style="margin-left: 0.2rem"
          :selectItem.sync="typeselectItem"
          @change="itemChange"
        ></mySelect>
      </div>
      <div class="right-Info">
        <div class="item-info">
          <span>{{ $t('bonus') }}</span><span class="isOrange">0,00</span>
        </div>
      </div>
    </section>
    <div>
      <section class="list-body">
        <van-empty
          v-if="list.length < 1"
          :image="require('@/assets/img_none_sj.png')"
          :description="$t('no records')"
        />
        <van-list
          style="
            --empty-height: 5rem;
            height: calc(calc(var(--vh, 1vh) * 100) - 1.62rem - 1.4rem);
            overflow: scroll;
          "
          v-model="loading"
          :finished="finished"
          @load="onLoad"
          offset="100"
          :loading-text="$t('loading')"
          v-else
        >
          <div class="rebate-item" v-for="(item, index) in list">
            <div class="rebate-item-body">
              <div class="rebate-item-body-title">{{ $t('rebate') }}</div>
              <div class="rebate-item-body-info">
                <span class="rebate-item-body-info-icon">
                  <i
                    style="
                      display: inline-flex;
                      justify-content: center;
                      align-items: center;
                    "
                  >
                    <svg-icon
                      icon-class="comm_icon_sqb_brl"
                      style="
                        width: 1em;
                        height: 1em;

                        fill: currentColor;
                      "
                    ></svg-icon>
                  </i>
                  <span> 0,32</span>
                </span>

                <span class="rebate-item-body-info-date"> 26/07/2024 </span>
                <span>
                  <span class="rebate-item-body-info-source">{{ $t('source') }}:</span>
                  <span style="color: var(--theme-primary-color)"
                    >Voltar para a água</span
                  >
                </span>
              </div>
            </div>
            <span class="rebate-item-state">{{ $t('rescued') }}</span>
          </div>
        </van-list>
      </section>
    </div>
  </div>
</template>
<script>
import mySelect from "@/components/phone/common/mySelect.vue";
export default {
  name: "myHhistory",
  components: { mySelect },
  data() {
    return {
      type: 1,
      dateselectItem: "today",
      typeselectItem: "all",
      title: this.$t("historic"),
      dateItems: [
        { label:this.$t('today'), value: "today" },
        { label:this.$t('yesterday'), value: "yesterday" },
        { label: this.$t('Last 7 Days'), value: "last_week" },
        { label: this.$t('Last 15 Days'), value: "last_half_month" },
        { label: this.$t('Last 30 Days'), value: "last_month" },
      ],
      typeItems: [
        { label: this.$t('all'), value: "all" },
        { label: this.$t('rescued'), value: "receive" },
        { label: this.$t('distributed'), value: "distribute" },
      ],
      pageIndex: 0,
      pageSize: 50,
      finished: false,
      list: [],
      loading: false,
      finished: false,
    };
  },
  mounted() {},
  methods: {
    itemChange() {},
  },
};
</script>
<style type="text/less" lang="less" scoped>
.app {
  background-color: transparent;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 0.2rem 0 0;
  .topMenu {
    -ms-flex-align: center;
    -ms-flex-pack: justify;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    background-color: var(--theme-bg-color);
    height: 0.86rem;
    padding: 0 0.2rem;
    .myselect {
      font-size: 0.2rem;
      height: 0.5rem !important;
      width: 1.6rem !important;
      display: inline-block;
      /deep/.el-input__inner {
        height: 0.5rem !important;
        font-size: 0.2rem !important;
      }
    }
    .right-Info {
      -ms-flex-align: center;

      display: -ms-flexbox;

      font-size: 0.18rem;
      line-height: 1.5;
      -ms-flex-align: end;
      align-items: flex-end;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      .item-info {
        -ms-flex-align: center;
        align-items: center;
        color: var(--theme-text-color-lighten);
        display: -ms-flexbox;
        display: flex;
        font-size: 0.24rem;
        margin-right: 0;

        > span:first-child {
          color: var(--theme-text-color-lighten);
          margin-right: 0.1rem;
        }
      }
    }
  }

  .list-body {
    min-height: 5rem;
    --empty-height: 5rem;
    .rebate-item {
      -ms-flex-align: center;
      -ms-flex-pack: justify;
      align-items: center;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      color: var(--theme-text-color-lighten);
      display: -ms-flexbox;
      display: flex;
      font-size: 0.2rem;
      height: 1.4rem;
      justify-content: space-between;
      margin: 0 0.2rem;
      padding: 0.2rem;
      border-radius: 0.1rem;
      .rebate-item-body {
        color: var(--theme-text-color-lighten);
        font-size: 0.2rem;
        width: 80%;

        .rebate-item-body-title {
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          color: var(--theme-text-color-darken);
          display: -webkit-box;
          font-size: 0.22rem;
          height: 0.7rem;
          line-height: 0.36rem;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .rebate-item-body-info {
          display: -ms-flexbox;
          display: flex;
          > span:not(:last-child) {
            margin-right: 0.2rem;
          }
          > span {
            -ms-flex-align: center;
            align-items: center;
            display: -ms-flexbox;
            display: flex;
          }
          .rebate-item-body-info-icon {
            color: var(--theme-secondary-color-finance);
            font-size: 0.18rem;
            line-height: 0.26rem;
            > i {
              margin-right: 0.05rem;
            }
          }
          .rebate-item-body-info-date {
            color: var(--theme-text-color-lighten);
            font-size: 0.2rem;
          }
          .rebate-item-body-info-source {
            color: var(--theme-text-color);
            margin-right: 0.05rem;
          }
        }
      }
      .rebate-item-state {
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        font-size: 0.2rem;
        overflow: hidden;
        text-align: right;
        text-overflow: ellipsis;
        vertical-align: middle;
        width: 20%;
      }
    }
    .rebate-item :nth-of-type(odd) {
      background-color: var(--theme-main-bg-color) !important;
    }

    .rebate-item :nth-of-type(2n) {
      background-color: var(--theme-bg-color) !important;
    }
  }
}
</style>
