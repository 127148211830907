<template>
  <section class="main">
    <section class="box">
      <dragScroll >
        <div class="head-container">
          <mySelect
            :items="dateItems"
            class="dayselect"
            :selectItem.sync="dateselectItem"
            @change="itemChange"
          ></mySelect>
          <ul class="otherselect">
            <li>
              <mySelect
                :items="classItems"
                class="dayselect"
                :filterable="true"
                :selectItem.sync="classselectItem"
                @change="itemChange"
              ></mySelect>
            </li>
          </ul>
        </div>
      </dragScroll>

      <div class="footerDataList">
        <van-empty
          v-if="list.length < 1"
          :image="require('@/assets/azf5f-28vaj.png')"
          :description="$t('no records')"
        />
        <van-list
          v-model="loading"
          :finished="finished"
          @load="onLoad"
          offset="100"
          :loading-text="$t('loading')"
          v-else
        >
          <div class="sticky" v-for="(item, index) in list">
            <div class="left">
              <p class="csbox">
                <span class="cs">
                  <div class="ly">
                    <span>{{ $t('fund exchange') }}</span>
                  </div>
                </span>
                <span>（{{ item.desc }}）</span>
              </p>
              <p>
                <span>{{ formatDateTimeVirar(item.time) }}</span>
              </p>
            </div>
            <div class="right">
              <p>
                <span class="cs"> {{ item.amount }}</span>
              </p>
              <p><span class="balance">{{ $t('balance') }}</span></p>
            </div>
          </div>
        </van-list>
      </div>
    </section>
  </section>
</template>
<script>
import mySelect from "@/components/phone/common/mySelect.vue";
import dragScroll from "@/components/phone/common/dragScroll.vue";
import { getLogBalanceChange } from "@/api/index";
import { formatDateTimeVirar } from "@/utils/utils";
export default {
  name: "accounts",
  components: { dragScroll, mySelect },
  mixins: [],

  data() {
    return {
      isAnimateAnimated: false,
      list: [],
      dateselectItem: "today",
      classselectItem: "all",
      loading: false,
      total_deposit: 0,
      total_withdraw: 0,
      pageIndex: 0,
      pageSize: 50,
      finished: false,
      dateItems: [
      { label: this.$t("today"), value: "today" },
        { label: this.$t("yesterday"), value: "yesterday" },
        { label: this.$t("Last 7 Days"), value: "last_week" },
        { label: this.$t("Last 15 Days"), value: "last_half_month" },
        { label: this.$t("Last 30 Days"), value: "last_month" },
      ],
      classItems: [
        { label:this.$t("all types"), value: "all" },
        { label: this.$t('activity'), value: "activity" },
        { label: this.$t('bet'), value: "bet" },
        { label: this.$t( "bet result"), value: "bet_result" },
        { label: this.$t('deposit'), value: "deposit" },
        { label:this.$t('mission'), value: "mission" },
        { label: this.$t('rebate'), value: "rebate" },
        { label: this.$t('register'), value: "register" },
        { label: this.$t('return'), value: "rollback" },
        { label: this.$t("vip reward"), value: "vip_reward" },
        { label: this.$t('withdraw'), value: "withdraw" },
      ]
     
    };
  },
  computed: {},
  watch: {},
  activated() {
    this.pageIndex = 0;
  },
  mounted() {
    this.getList();
  },
  methods: {
    itemChange(v) {
      this.pageIndex = 0;
      this.finished = false;
      this.list = [];
      this.getList();
    },
    getList() {
      getLogBalanceChange({
        time_rand: this.dateselectItem,
        reason: this.classselectItem === "all" ? "" : this.classselectItem,
        page: this.pageIndex,
        count: this.pageSize,
      }).then(({ data }) => {
        if (data.code === 0) {
          if (data.data.logs.length > 0) {
            this.list = this.list.concat(data.data.logs);
          }

          if (data.data.logs.length < this.pageSize) {
            this.finished = true;
          }
          this.total_deposit = data.data.total_deposit;
          this.total_withdraw = data.data.total_withdraw;
          this.loading = false;
          this.$emit("change", data.data);
        }
      });
    },
    onLoad() {
      this.pageIndex = this.pageIndex + 1;
      this.getList();
    },
    formatDateTimeVirar(v) {
      return formatDateTimeVirar(new Date(v*1000));
    }
  },
};
</script>
<style type="text/less" lang="less" scoped>

.main {
  padding: 0;
  .box {
    padding: 0.2rem 0;
  }
  .head-container {
    margin-bottom: 0.2rem !important;
    -ms-flex-pack: start;
    display: -ms-flexbox;
    display: flex;
    justify-content: flex-start;

    position: relative;

    padding-left: 0.2rem;
    padding-right: 0.2rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    .dayselect {
      max-width: 3.15rem;
      min-width: 1.6rem;
      width: auto !important;
      margin-right: 0.2rem;
      box-shadow:none;
     /deep/ .el-input__inner:focus {
        box-shadow: none;
      }
    }
    .otherselect {
      display: -ms-flexbox;
      display: flex;
      height: 0.5rem;
      margin: 0;
      li {
        margin-right: 0.2rem;
      }
    }
  }
  .footerDataList {
    -ms-flex-pack: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;

    justify-content: center;
    overflow: hidden;
    position: relative;
    height: calc(100vh - 3.55rem);
    height: calc(var(--vh, 1vh) * 100 - 3.55rem);

    /deep/.van-empty {
      -ms-flex-positive: 1;
      flex-grow: 1;
      padding: 0;

      .van-empty__image {
        height: 2.1rem;
        text-align: center;
        width: 2.1rem;
      }
      .van-empty__description {
        color: var(--theme-text-color-lighten);
        font-size: 0.26rem;
        margin-top: 0.2rem;
      }
    }

    /deep/.van-list > div:nth-child(odd) {
      background-color: var(--theme-main-bg-color);
    }

    /deep/.van-list > div:nth-child(2n) {
      background-color: var(--theme-bg-color);
    }

    /deep/.van-list > div.van-list__loading {
      background-color: transparent !important;
      color: var(--theme-text-color-lighten);
    }

    /deep/.van-list > div.van-list__placeholder {
      background-color: transparent !important;
    }

    /deep/.van-list.reverse > div:nth-child(odd) {
      background-color: var(--theme-bg-color);
    }

    /deep/.van-list.reverse > div:nth-child(2n) {
      background-color: var(--theme-main-bg-color);
    }

    /deep/.van-list {
   
      // div:nth-child(odd) {
      //   background-color: var(--theme-main-bg-color);
      // }
      // div:nth-child(2n) {
      //   background-color: var(--theme-bg-color);
      // }
      // div {
      //   border-radius: 0.1rem;
      // }
      .sticky {
        -ms-flex-align: center;
        -ms-flex-pack: justify;
        align-items: center;
        display: -ms-flexbox;
        display: flex;
        font-size: 0.2rem;
        justify-content: space-between;
        padding: 0.2rem !important;
        .left {
          color: var(--theme-text-color-lighten);
          .csbox {
            display: -ms-flexbox;
            display: flex;
            margin-bottom: 0.1rem;
            span:first-child {
              color: var(--theme-text-color-darken);
            }
            .cs {
              font-size: 0.22rem;
              .ly {
                -ms-flex-align: center;
                -ms-flex-pack: center;
                align-items: center;
                display: -ms-flexbox;
                display: flex;
                justify-content: center;
                justify-content: flex-start;
              }
            }

            span {
              margin: auto;
            }
          }
        }
        .right {
          color: var(--theme-text-color-lighten);
          text-align: right;
          p:first-child {
            margin-bottom: 0.1rem;
          }
          .cs {
            color: var(--theme-text-color-darken);
            font-size: 0.24rem;
          }
          .balance {
            font-size: 0.22rem;
            display: inline-block;
            max-width: 4.5rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
  }
}
</style>
