<template>
    <section class="wrap">
      <div class="topMenu">
        <van-row justify="space-between" type="flex">
          <van-col span="12">
            <div class="dateselect">
              <dateSelect width="3rem"></dateSelect>
            </div>
          </van-col>
          <van-col span="12">
            <label class="lb">{{ $t('total commissions') }}</label>
            <span class="currencyAmount yellowColor">0,00</span>
          </van-col>
          <van-col span="12">
            <label class="lb">{{ $t('direct commission') }}</label>
            <span class="currencyAmount yellowColor">0,00</span>
          </van-col>
          <van-col span="12"
            ><label class="lb">{{ $t('another commission') }}</label>
            <span class="currencyAmount yellowColor">0,00</span></van-col
          >
        </van-row>
      </div>
      <div class="context">
        <van-empty
          :image="require('@/assets/azf5f-28vaj.png')"
          :description="$t('no records')"
        />
      </div>
    </section>
  </template>
  <script>
  import dateSelect from "@/components/phone/common/dateSelect.vue";
  
  export default {
    name: "commission",
    components: { dateSelect },
    data() {
      return {
        active: 0,
        stareDate: "28/12/2023",
        endDate: "28/12/2024",
        value:"",
      };
    },
    created() {},
    mounted() {

    },
    destroyed() {},
    methods: {
      remoteMethod(query) {},
      tabChange(index) {
        this.active = index;
      },
      onSearch() {},
    },
  };
  </script>
  <style type="text/less" lang="less" scoped>
  .wrap {
    padding: 0.2rem 0.2rem 0;
    .topMenu {
      background-color: var(--theme-main-bg-color);
      border-radius: 0.1rem;
      padding: 0.2rem 0.2rem 0;
      -webkit-box-shadow: 0 0.03rem 0.09rem 0 var(--theme-bg-shadow);
      box-shadow: 0 0.03rem 0.09rem 0 var(--theme-bg-shadow);
      font-size: .2rem;
      font-variant: tabular-nums;
      .dateselect {
        padding: 0;
        width: 3rem;
        position: relative;
        z-index: 999;
      }
      /deep/.van-col {
        -ms-flex-align: center;
        align-items: center;
        color: var(--theme-text-color-darken);
        display: -ms-flexbox;
        display: flex;
        margin-bottom: 0.2rem;
      }
      .lb {
        color: var(--theme-text-color-lighten);
        margin-right: 0.1rem;
        -ms-touch-action: manipulation;
        touch-action: manipulation;
      }
    }
    .context {
      -ms-flex-pack: center;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      height: calc(100vh - 2.55rem);
      height: calc(var(--vh, 1vh) * 100 - 2.55rem);
      justify-content: center;
      overflow: hidden;
      position: relative;
      /deep/.van-empty {
        -ms-flex-positive: 1;
        flex-grow: 1;
        padding: 0;
  
        .van-empty__image {
          height: 2.1rem;
          text-align: center;
          width: 2.1rem;
        }
        .van-empty__description {
          color: var(--theme-text-color-lighten);
          font-size: 0.26rem;
          margin-top: 0.2rem;
        }
      }
    }
  }
  </style>
  