var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"top"},[_c('div',{staticClass:"appInfo"},[_c('div',{staticClass:"appInfoTop"},[_c('div',{staticClass:"cover"},[_vm._v("1")]),_c('div',{staticClass:"name"},[_c('div',[_vm._m(0),_c('div',{staticClass:"rank"},[_vm._v("娱乐 第一名")]),(_vm.deferredPrompt)?_c('div',{staticClass:"btn",on:{"click":_vm.promptInstall}},[_vm._v("获取")]):_c('div',{staticClass:"btn",on:{"click":function($event){_vm.dialogTableVisible = true}}},[_vm._v("获取")])]),_c('div')])]),_vm._m(1)]),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)]),_vm._m(6),(_vm.mask)?_c('div',{staticClass:"mask"},[_vm._m(7),_vm._m(8)]):_vm._e(),_c('el-dialog',{attrs:{"width":"90%","visible":_vm.dialogTableVisible},on:{"update:visible":function($event){_vm.dialogTableVisible=$event}}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require("../assets/Tutorial.png")}})])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"appName"},[_vm._v("9991BET "),_c('div',[_vm._v("18+")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info"},[_c('div',{staticClass:"item"},[_c('div',[_vm._v("5 ★★★★★")]),_c('div',[_vm._v("k个评分")])]),_c('div',{staticClass:"item"},[_c('div',[_vm._v("#0")]),_c('div',[_vm._v("其他")])]),_c('div',{staticClass:"item"},[_c('div',[_vm._v("18+")]),_c('div',[_vm._v("年龄")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box"},[_c('div',{staticClass:"title",staticStyle:{"margin-bottom":"15vw"}},[_vm._v("简介")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box"},[_c('div',{staticClass:"title"},[_vm._v(" 评分及评论 ")]),_c('div',{staticClass:"star"},[_c('div',{staticClass:"num"},[_vm._v("5")]),_c('div',{staticClass:"starList"},[_c('div',{staticClass:"starListItem"},[_c('div',{staticClass:"xx"},[_vm._v("★★★★★")]),_c('div',{staticClass:"jd"},[_c('div')])]),_c('div',{staticClass:"starListItem"},[_c('div',{staticClass:"xx"},[_vm._v("★★★★")]),_c('div',{staticClass:"jd"},[_c('div',{staticStyle:{"width":"25%"}})])]),_c('div',{staticClass:"starListItem"},[_c('div',{staticClass:"xx"},[_vm._v("★★★")]),_c('div',{staticClass:"jd"},[_c('div',{staticStyle:{"width":"10%"}})])]),_c('div',{staticClass:"starListItem"},[_c('div',{staticClass:"xx"},[_vm._v("★★")]),_c('div',{staticClass:"jd"},[_c('div',{staticStyle:{"width":"5%"}})])]),_c('div',{staticClass:"starListItem"},[_c('div',{staticClass:"xx"},[_vm._v("★")]),_c('div',{staticClass:"jd"},[_c('div',{staticStyle:{"width":"2%"}})])])])]),_c('div',{staticClass:"pf"},[_c('div',[_vm._v("满分5分")]),_c('div',[_vm._v("K个评分")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box"},[_c('div',{staticClass:"title"},[_vm._v("新功能")]),_c('div',[_vm._v("版本 0.1.0")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box"},[_c('div',{staticClass:"title"},[_vm._v("信息")]),_c('div',{staticClass:"item"},[_c('div',[_vm._v("经销商")]),_c('div')]),_c('div',{staticClass:"item"},[_c('div',[_vm._v("大小")]),_c('div',[_vm._v("1MB")])]),_c('div',{staticClass:"item"},[_c('div',[_vm._v("种类")]),_c('div',[_vm._v("其他")])]),_c('div',{staticClass:"item"},[_c('div',[_vm._v("兼容性")]),_c('div',[_vm._v("需要IOS11.0或者更高版本，与iphone兼容")])]),_c('div',{staticClass:"item"},[_c('div',[_vm._v("语言")]),_c('div',[_vm._v("简体中文")])]),_c('div',{staticClass:"item"},[_c('div',[_vm._v("年龄分级")]),_c('div',[_vm._v("限18岁以上")])]),_c('div',{staticClass:"item"},[_c('div',[_vm._v("Copyright")]),_c('div')]),_c('div',{staticClass:"item"},[_c('div',[_vm._v("价格")]),_c('div',[_vm._v("免费")])]),_c('div',{staticClass:"item"},[_c('div',[_vm._v("隐私政策")]),_c('div')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bottom"},[_c('p',[_vm._v("免责声明：")]),_c('p',[_vm._v("本网站仅为开发者提供App的下载和安装托管，App内的内容和运营相关事项由App开发者负责，与本网站无关")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top"},[_c('img',{attrs:{"src":require("../assets/arrow_top.png")}}),_c('div',[_vm._v("第一步")]),_c('div',[_vm._v("点击“复制”链接")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bottom"},[_c('div',[_vm._v("第二步")]),_c('img',{staticClass:"arrow",attrs:{"src":require("../assets/arrow_bottom.png")}}),_c('img',{staticClass:"browser",attrs:{"src":require("../assets/showBottomOtherBrowser.png")}})])
}]

export { render, staticRenderFns }