<template>
    <div class="app">
        <div class="content" v-show="false">
            <el-table :data="tableData" :stripe="true" style="width: 100%" class="customer-table">
                <el-table-column prop="hours" :label="$t('Hours')">
                </el-table-column>
                <el-table-column prop="names" :label="$t('Names')">
                </el-table-column>
                <el-table-column prop="bonus" :label="$t('Bonus')">
                </el-table-column>
                <el-table-column prop="source" :label="$t('Source')">
                </el-table-column>
                <el-table-column prop="status" :label="$t('Status')">
                </el-table-column>
            </el-table>
            <div class="noList" v-if="tableData.length == 0">
                <img src="@/assets/img_Sem-Registros.png" />
                <div>{{ $t('Not yet') }}</div>
            </div>
        </div>
        <el-backtop></el-backtop>
        <van-empty
          :image="require('@/assets/azf5f-28vaj.png')"
          description="Sem data"
        />
    </div>
</template>
<script>


export default {
    name: 'Historic',
    components: {},
    data() {
        return {
            type: 1,
            dialogLogin: false,
            showSideBar: true,
            title: this.$t('historic'),

            tableData: [
                {}
            ],

            opt1: 'Today',
            opt2: 'Tudos',
            bonus: 0
        }
    },
    mounted() {

    },
    methods: {
        handleSelect(key) {
            if (key == 6) {
                this.$router.push({
                    path: '/freeGame'
                })
            } else {
                this.$router.push({
                    path: '/',
                    query: { type: key }
                })
            }
        },
        login(type) {
            this.type = type
            this.dialogLogin = true
        },
        showBar() {
            this.showSideBar = !this.showSideBar
        }
    }
};
</script>
<style type="text/less" lang="less" scoped>
.app {
    font-size: 4vw;
    background: var(--theme-main-bg-color);
    min-height: 93vh;
    overflow-x: hidden;
}

.content {
    margin-top: 1vw;
    background: var(--theme-main-bg-color);
    padding: 1vw;
    border-radius: 0.3vw;
    min-height: 25vw;
    position: relative;

    .contentTop {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #B2C5FD;
        margin-top: 1vw;

        span {
            margin-left: 0.5vw;
            color: #FFAA09;
        }
    }
}

.opt {
    margin-right: 1vw;
    margin-bottom: 3vw;
    width: 30vw;
}

/deep/.el-dialog {
    background: var(--theme-main-bg-color);
    border-radius: 1vw;
}

/deep/.el-dialog__close {
    color: #fff !important;
}

.el-menu--horizontal>.el-menu-item {
    color: #B2C5FD;
}

.dialogTitle {
    text-align: center;
    border-bottom: 2px solid #fff;
    width: 7vw;
    color: #fff;
    padding-bottom: 1vw;
    margin: auto;
    font-size: 1.5vw;
    font-weight: bold;

    .el-icon-user-solid {
        margin-right: 0.5vw;
    }
}

.noList {
    position: absolute;
    text-align: center;
    top: 80vw;
    left: 50%;
    transform: translate(-40%, );
    color: #B2C5FD;
    font-size: 5vw;
    img {
        width: 20vw;
        margin-bottom: 1vw;
    }
}

/deep/.el-input__inner {
    background: var(--theme-main-bg-color);
    color: rgb(178, 197, 253);
    border-radius: 40vw;
}

/deep/.el-input__inner:focus {
    border-color: #fff;
    box-shadow: 0px 0px 10px #fff;
}

/deep/.el-table__body,
/deep/.el-table__header {
    width: 100% !important;
}

/deep/.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
    background: #6E8DE6;
}

/deep/.el-table tr {
    background: #5375D1;
}

/deep/.el-table td.el-table__cell,
/deep/.el-table th.el-table__cell.is-leaf {
    border: none;
}

/deep/.el-table--border::after,
/deep/.el-table--group::after {
    width: 0;
}

/deep/.el-table th.el-table__cell {
    background: #6E8DE6;
}

/deep/.el-table tbody tr:hover>td {
    background-color: transparent !important;
}

/deep/.has-gutter {
    width: 100%;
    color: #fff;
}

/deep/.el-table tbody .el-table_1_column_2 {
    color: #FFAA09;
}

.customer-table {
    color: #D5DDFF;
}

.customer-table::before {
    width: 0;
}

.customer-table .el-table__fixed-right::before,
.el-table__fixed::before {
    width: 0;
}

/deep/.el-table .el-table__cell {
    text-align: center;
}

/deep/.is-scrolling-none {
    background: var(--theme-main-bg-color);
}

/deep/.el-table__empty-text {
    display: none;
}
/deep/.el-select-dropdown {
    background: var(--theme-main-bg-color);
    color: #D5DDFF !important;
    /deep/.el-select-dropdown__item.hover {
        background: #D5DDFF !important;
    }
}
/deep/.el-table::before {
  height: 0px;
}
/deep/.el-table--border::after {
  width: 0px;
}
</style>