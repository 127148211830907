<template>
  <div class="main">
    <div class="context" v-loading="isAnimateAnimated">
      <div class="topBox" style="height: auto; max-height: 50%">
        <myScrollbarBar st="y" style="width: 100%; height: 100%">
          <div>
            <div class="titleBox">
              <h3 class="title">
                {{ $t('account for receipt') }}
                <span>({{ accountList.length }}/4)</span>
                <i @click="getWithdrawAccountInfo"
                  class="anticon refresh-num animate__spin animate__infinite"
                  :class="{ animate__animated: isAnimateAnimated }"
                >
                  <i
                    style="
                      display: inline-flex;
                      justify-content: center;
                      align-items: center;
                    "
                  >
                    <svg-icon
                      icon-class="comm_icon_retry"
                      style="
                        width: 1em;
                        height: 1em;
                        color: var(--theme-alt-primary);
                        fill: currentColor;
                      "
                    ></svg-icon>
                  </i>
                </i>
              </h3>
              <span>
                <span class="eye" @click="shwoCardNo=!shwoCardNo">
                  <svg-icon
                    :icon-class="!shwoCardNo ? 'comm_icon_hide' : 'comm_icon_show'"
                    style="width: 1em; height: 1em; fill: currentColor"
                    :style="
                      !shwoCardNo
                        ? 'color: var(--theme-color-line);'
                        : 'color: var(--theme-primary-color);'
                    "
                  ></svg-icon>
                </span>
              </span>
            </div>
            <ul class="cardlistbox">
              <li
                v-for="(item, index) in accountList"
                class="li-item"
                :class="{'border-filter':item.index===default_index_account}"
              >
                <div class="itemContext">
                  <img
                    src="@/assets/icon_normal_pix.png"
                    style="width: 0.6rem; height: 0.6rem"
                  />
                  <div class="cardInfo">
                    <p class="cardType">
                      <div class="title">
                        <span>PIX</span>
                        <span style="color:var(--theme-text-color-lighten)">({{ item.pix_type }})</span>
                          </div>
                    </p>
                    <p class="cardNoBox">
                      <span class="cardNo" >
                        {{ shwoCardNo?item.pix_key:truncateString(item.pix_key) }}
                      </span>
                      <span class="copyIcon" v-if="shwoCardNo" v-copy="item.pix_key">
                  <svg-icon
                    icon-class="comm_icon_copy"
                    style="width: 1em; height: 1em; fill: currentColor"
                  ></svg-icon>
                </span>
                    </p>
                  </div>
                </div>
                <div class="selectDefault">
                  <span class="default"  v-if="item.index !== default_index_account" @click="setDefault(item.index)">{{ $t('set default') }}</span>
                </div>
                <p class="subscript" v-if="item.index === default_index_account">
              <i
                style="
                  display: inline-flex;
                  justify-content: center;
                  align-items: center;
                "
                class="gou"
              >
                <svg-icon
                  icon-class="comm_icon_gou"
                  focusable="false"
                  aria-hidden="true"
                  style="width: 1em; height: 1em; fill: currentColor"
                ></svg-icon
              ></i>
              <i
                style="
                  display: inline-flex;
                  justify-content: center;
                  align-items: center;
                "
                class="bg"
              >
                <svg-icon
                  icon-class="comm_img_corner"
                  focusable="false"
                  aria-hidden="true"
                  style="fill: currentColor"
                ></svg-icon
              ></i>
            </p>
              </li>
            </ul>
          </div>
        </myScrollbarBar>
      </div>
      <div class="supplierBox"     
      style="height: auto; max-height: 50%">
        <myScrollbarBar st="y" style="width: 100%; height: 100%">
          <ul class="supplierlist">
            <li class="item" @click="openPassWord = true">
              <div class="left">
                <img
                  src="@/assets/icon_normal_pix.png"
                  style="width: 0.6rem; height: 0.6rem"
                />
                <div class="text">
                  <p class="t">PIX</p>
                </div>
              </div>
              <div class="right">
                <a>{{ $t('add') }}</a>
                <i
                  style="
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    color: var(--theme-text-color-lighten);
                    font-size: 0.2rem;
                    margin-left: 0.15rem;
                    -webkit-transform: rotate(180deg);
                    transform: rotate(180deg);
                  "
                >
                  <svg-icon
                    icon-class="comm_icon_fh"
                    focusable="false"
                    aria-hidden="true"
                    style="width: 1em; height: 1em; fill: currentColor"
                  ></svg-icon>
                </i>
              </div>
            </li>
          </ul>
        </myScrollbarBar>
      </div>
    </div>
    <myDialog
      :open.sync="openPassWord"
      @closeDialog="closeDialog"
      width=" 6.9rem"
      :Title="$t('enter password')"
      :originalClose="false"
    >
      <div style="">
        <div style="margin-bottom: 0.5rem">
          <myNumberPassInput
            :passvalue.sync="passvalue"
            @leave="leaveInputPass"
            @change="inputChange"
            :title="$t('withdrawal password')"
          ></myNumberPassInput>
        </div>
        <div class="err-explain" v-if="showerr">6 números puros</div>
        <div class="tipsbox">
          <span
            >{{ $t('withdrawal pass tip1') }}</span
          >
          <span class="forgot-password">{{$t('forgot password')}}?</span>
        </div>

        <div class="sunbitbox">
          <van-button
            class="highlight-button"
            :loading="submitLoading"
            @click="submitPass"
            >{{ $t('next') }}</van-button
          >
        </div>
      </div>
    </myDialog>
    <myDialog
      :open.sync="showAddCardBox"
      width="6.9rem"
      :Title="$t('add') +'PIX'"
      :originalClose="true"
    >
      <div class="addCardForm">
        <el-form :rules="rules" ref="addcard" :model="addcard">
          <el-form-item style="margin-bottom: 0.5rem">
            <el-input
              class="myinput"
              :disabled="real_name !== ''"
              v-model="addcard.account"
            >
              <template slot="prefix">
                <i
                  style="
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    color: var(--theme-text-color-lighten);
                    height: 0.34rem !important;
                    width: 0.3rem !important;

                    font-size: 0.3rem;
                  "
                >
                  <svg-icon
                    icon-class="input_icon_zsxm"
                    style="
                      width: 1em;
                      height: 1em;
                      color: var(--theme-text-color);
                      fill: var(--theme-text-color-lighten);
                      line-height: 1.5;
                    "
                  ></svg-icon>
                </i>
              </template>
            </el-input>
          </el-form-item>

          <el-form-item style="margin-bottom: 0.5rem">
            <myIcoSelect
              :items="cardList"
              :select-item.sync="addcard.myitem"
             
            >
              <template slot="prefix">
                <i
                  class="anticon"
                  style="
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    color: var(--theme-text-color-lighten);
                    height: 0.34rem !important;
                    width: 0.3rem !important;

                    font-size: 0.3rem;
                  "
                >
                  <svg-icon
                    icon-class="input_icon_zh"
                    style="
                      width: 1em;
                      height: 1em;
                      color: var(--theme-text-color-lighten);
                      fill: currentColor;
                    "
                  ></svg-icon>
                </i>
              </template>
            </myIcoSelect>
          </el-form-item>

          <el-form-item
            style="margin-bottom: 0.5rem"
            v-if="addcard.myitem !== 'CPF'"
            prop="pix"
          >
            <el-input
              v-model="addcard.pix"
              class="myinput"
              maxlength="11"
              :clearable="true"
              :placeholder="$t('introduza-a-sua-chave-do-pix')"
            >
              <template slot="prefix">
                <i
                  style="
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    color: var(--theme-text-color-lighten);
                    height: 0.34rem !important;
                    width: 0.3rem !important;
                    font-size: 0.3rem;
                  "
                >
                  <svg-icon
                    icon-class="pix_icon"
                    style="
                      width: 1em;
                      height: 1em;
                      color: var(--theme-text-color);
                      fill: var(--theme-text-color-lighten);
                      line-height: 1.5;
                    "
                  ></svg-icon>
                </i>
              </template>
            </el-input>
            <template slot="error" slot-scope="scope">
              <div class="err-explain">{{ scope.error }}</div>
            </template>
          </el-form-item>
          <el-form-item style="margin-bottom: 0.5rem" prop="cpf">
            <el-input
              class="myinput"
              :value="addcard.cpf"
              :disabled="cpf !== ''"
              Maxlength="14"
              @input="inputCpfChange"
              :clearable="true"
              :placeholder="$t('enter the 11-digit number of the CPF')"
            >
              <template slot="prefix">
                <i
                  style="
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    color: var(--theme-text-color-lighten);
                    height: 0.34rem !important;
                    width: 0.3rem !important;
                    font-size: 0.3rem;
                  "
                >
                  <svg-icon
                    icon-class="input_icon_cpf"
                    style="
                      width: 1em;
                      height: 1em;
                      color: var(--theme-text-color);
                      fill: var(--theme-text-color-lighten);
                      line-height: 1.5;
                    "
                  ></svg-icon>
                </i>
              </template>
            </el-input>
            <template slot="error" slot-scope="scope">
              <div class="err-explain">{{ scope.error }}</div>
            </template>
          </el-form-item>

          <p class="tips">
           {{ $t('withdrawal pass tip2') }}
          </p>
        </el-form>
        <div class="addbutton">
          <van-button
            class="highlight-button"
            :loading="submitLoading"
            @click="addBankCard"
            >{{ $t('confirm') }}</van-button
          >
        </div>
      </div>
    </myDialog>
  </div>
</template>
<script>
import myScrollbarBar from "@/components/phone/common/myScrollbarBar.vue";
import myDialog from "@/components/phone/common/myDialog.vue";
import myNumberPassInput from "@/components/phone/common/myNumberPassInput.vue";
import {
  checkPassWord,
  getWithdrawAccountInfo,
  addWithdrawAccount,
  setDefaultIndex
} from "@/api/index";
import MyDialog from "@/components/phone/common/myDialog.vue";
import myIcoSelect from "@/components/phone/common/myIcoSelect.vue";
import { formatString ,truncateString} from "@/utils/utils";
export default {
  name: "addCard",
  components: { myScrollbarBar, myDialog, myNumberPassInput, myIcoSelect },
  mixins: [],
  data() {
    return {
      addcard: { cpf: "", pix: "", account: "", myitem: "CPF" },
      isAnimateAnimated: false,
      openPassWord: false,
      passvalue: "",
      showerr: false,
      isauth: false,
      showAddCardBox: false,
      submitLoading: false,
      shwoCardNo:false,
      default_index_account:0,
      notify: {},
      check_code: "",
      cardList: [
        { label: "CPF", value: "CPF" },
        { label: "EVP", value: "EVP" },
        { label: "EMAIL", value: "EMAIL" },
        { label: "PHONE", value: "PHONE" },
      ],
      rules: {
        pix: [
          {
            required: true,
            message: this.$t(
              "pix err1"
            ),
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
              const phoneRegex = /^\(?\d{2}\)?\s?\d{4,5}-?\d{4}$/; // Brazilian phone number regex
              const evpRegex = /^[A-Z]{3}-\d{4}$/; // EVP code regex
              if (this.addcard.myitem === "EMAIL" && !emailRegex.test(value)) {
                callback(
                  new Error(
                    this.$t("email err3")
                  )
                );
              } else if (
                this.addcard.myitem === "PHONE" &&
                !phoneRegex.test(value)
              ) {
                callback(
                  new Error(
                    this.$t(
                      $t('pix err2')
                    )
                  )
                );
              } else if (
                this.addcard.myitem === "EVP" &&
                !evpRegex.test(value)
              ) {
                callback(
                  new Error(this.$t("evp err1"))
                );
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        cpf: [
          {
            required: true,
            message: this.$t("cpf err1"),
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              const customRegex = /^[\w]{3}\.[\w]{3}\.[\w]{3}-[\w]{2}$/;
              if (!customRegex.test(value)&&this.cpf==='') {
                callback(
                  new Error(this.$t("number 11 err"))
                );
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
      },
      real_name: "",
      accountList: [],
      cpf: "",
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.getWithdrawAccountInfo();
  },
  methods: {
    getWithdrawAccountInfo() {
      this.isAnimateAnimated=true;
      getWithdrawAccountInfo().then(({ data }) => {
        if (data.code === 0) {
          this.accountList = data.data.accounts;
          this.real_name = data.data.real_name;
          this.cpf = data.data.cpf;
          this.addcard.account = this.real_name;
          this.addcard.cpf = this.cpf;
          this.isAnimateAnimated=false;
          this.default_index_account=data.data.default_index_account;
        }
      });
    },
    closeDialog() {
      this.openPassWord = false;
    },
    leaveInputPass(v) {
      if (v.length < 6 && v.length > 0) {
        this.showerr = true;
      }
    },
    truncateString(v){
      return truncateString(v,4);
    },
    inputChange(v) {
      if (v.length === 6) {
        this.showerr = false;
      }
    },
  
    submitPass() {
      this.check_code = "";
      if (this.passvalue.length === 6) {
        this.showerr = false;
        checkPassWord({ withdraw_password: this.passvalue }).then(
          ({ data }) => {
            if (data.code !== 0 || !data.data.success) {
              if (this.notify && this.notify.close) {
                this.notify.close();
              }

              this.notify = this.$notify({
                duration: 2000,
                message:
                  this.$t("withdrawal pass err1"),
                type: "error",
                background: "#fff",
                offset: "3",
                showClose: false,
              });
            } else {
              this.isauth = data.data.success;
              this.check_code = data.data.check_code;
              this.openPassWord = false;
              this.showAddCardBox = true;
            }
          }
        );
      }
    },
    addBankCard() {
      this.submitLoading = true;
      this.$refs.addcard.validate((valid) => {
        if (valid) {
          var postData = {};
          if (this.real_name !== "") {
            postData.real_name = this.real_name;
          } else {
            postData.real_name = this.addcard.account;
          }
          if (this.cpf !== "") {
            postData.cpf=this.cpf;
          }else{
            postData.cpf = this.addcard.cpf;
          }
          if (this.addcard.myitem !== "CPF") {
            postData.pix_key = this.addcard.pix;
          }
          postData.pix_type = this.addcard.myitem;
          postData.check_code = this.check_code;
          addWithdrawAccount(postData).then(({ data }) => {
            if (data.code === 0 && data.data.success) {
              this.showAddCardBox = false;
              if (this.notify && this.notify.close) {
                this.notify.close();
              }
              this.notify = this.$notify({
                duration: 2000,
                message: this.$t('add success'),
                type: "success",
                background: "#fff",
                offset: "3",
                showClose: false,
              });
              this.getWithdrawAccountInfo();
            } else {
              if (this.notify && this.notify.close) {
                this.notify.close();
              }
              this.notify = this.$notify({
                duration: 2000,
                message: data.message,
                type: "error",
                background: "#fff",
                offset: "3",
                showClose: false,
              });
            }
            this.submitLoading = false;
          });
          return false;
        } else {
          this.submitLoading = false;
          return false;
        }
      });
    },
    inputCpfChange(value) {
      this.addcard.cpf = formatString(value, this.addcard.cpf, "3.3.3-2");
    },
    setDefault(index){
        if(index!==this.default_index_account){
          setDefaultIndex({index:index}).then(({data})=>{
          this.getWithdrawAccountInfo();
          });
        }
    }
  },
};
</script>
<style type="text/less" lang="less" scoped>

/deep/.my-scrollbar-wrap {
  padding: 0.2rem;
}
ul {
  margin: 0;
}
.main {
  -ms-flex-align: center;
  -ms-flex-pack: justify;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  font-size: 0.22rem;
  height: calc(100vh - 0.75rem);
  height: calc(var(--vh, 1vh) * 100 - 0.75rem);
  justify-content: space-between;
  margin-top: 0;
  overflow: hidden;
  padding: 0;
  width: 100%;
  .context {
    -ms-flex-align: center;
    -ms-flex-pack: justify;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    font-size: 0.22rem;
    height: calc(100vh - 1rem);
    height: calc(var(--vh, 1vh) * 100 - 1rem);
    justify-content: space-between;
    margin-top: 0;
    overflow: hidden;
    padding: 0;
    width: 100%;
    .topBox {
      background-color: var(--theme-main-bg-color);
      border-radius: 0.14rem;
      -webkit-box-shadow: 0 0.05rem 0.18rem 0 var(--theme-bg-shadow);
      box-shadow: 0 0.05rem 0.18rem 0 var(--theme-bg-shadow);
      margin: 0.2rem;
      max-height: 50%;
      overflow: hidden;
      padding: 0.2rem;
      width: calc(100% - 0.4rem);
      .titleBox {
        -ms-flex-align: center;
        -ms-flex-pack: justify;
        align-items: center;
        display: -ms-flexbox;
        display: flex;
        justify-content: space-between;
        .title {
          color: var(--theme-text-color-darken);
          margin-bottom: 0.16rem;
          margin-top: 0.1rem;
          font-size: 0.22rem;
          -ms-flex-align: center;
          align-items: center;
          display: -ms-flexbox;
          display: flex;
          margin-bottom: 0.2rem;
          span {
            color: var(--theme-text-color-lighten);
            font-weight: 400;
            margin-left: 0.06rem;
          }
        }
        .eye {
          -ms-flex-align: center;
          align-items: center;
          color: var(--theme-primary-color);
          display: -ms-flexbox;
          display: flex;
          font-size: 0.36rem;
        }
        .refresh-num {
          color: var(--theme-primary-color);
          font-size: 0.3rem;
          margin-left: 0.1rem;
        }
      }
      .cardlistbox {
        margin: 0;
        -ms-flex-pack: justify;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        justify-content: space-between;
        .li-item {
          border: thin solid var(--theme-color-line);
          position: relative;
          justify-content: space-between;
          background-color: var(--theme-main-bg-color);
          -ms-flex-pack: justify;
          -ms-flex-align: center;
          align-items: center;
          display: -ms-flexbox;
          display: flex;
          border-radius: 0.14rem;
          margin-bottom: .2rem;
          height: 0.8rem;
          margin-right: 0;
          padding: 0.1rem 0.2rem 0.1rem 0.1rem;
          width: 100%;
          .itemContext {
            -ms-flex-align: center;
            align-items: center;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-negative: 0;
            flex-shrink: 0;
            width: 4.2rem;
            .cardInfo {
              -ms-flex-pack: center;
              display: -ms-flexbox;
              display: flex;
              -ms-flex-direction: column;
              flex-direction: column;
              justify-content: center;
              margin-left: 0.2rem;
              .cardType {
                color: var(--theme-text-color-darken);
                display: -ms-flexbox;
                display: flex;
                .title {
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  display: -webkit-box;
                  max-width: 3rem;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  vertical-align: middle;
                  max-width: 2.25rem !important;
                  line-height: 1;
                }
              }
              .cardNoBox{
                -ms-flex-align: center;
                align-items: center;
                color: var(--theme-text-color-lighten);
                display: -ms-flexbox;
                display: flex;
                max-width: 3.3rem;
                .cardNo{
                  max-width: 2.7rem;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
                .copyIcon{
                  margin-left: .1rem;
                  margin-top: .02rem;
                  color: var(--theme-primary-color);
                  cursor: pointer;
                }
              }
            }
          }
          .selectDefault{
            -ms-flex-align: center;
            align-items: center;
            display: -ms-flexbox;
            display: flex;
            font-size: .22rem;
            line-height: 1.5;
            text-align: right;
            .default{
              word-wrap: break-word;
              color: var(--theme-primary-color);
              margin-right: .15rem;
              line-height: 1.1;
              shape-outside: content-box;
              text-align: right;
              max-width: 1.62rem;
            }
          }
        }
        .li-item:last-child {
          margin-bottom: 0;
        }
      }
    }
    .supplierBox {
      background-color: var(--theme-main-bg-color);
      border-radius: 0.14rem 0.14rem 0 0;
      -webkit-box-shadow: 0 -0.06rem 0.1rem 0 var(--theme-bg-shadow);
      box-shadow: 0 -0.06rem 0.1rem 0 var(--theme-bg-shadow);
      max-height: 50%;
      width: 100%;
      z-index: 100;
      .supplierlist {
        -ms-flex-pack: justify;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        justify-content: space-between;
        .item {
          -ms-flex-pack: justify;
          background-color: var(--theme-main-bg-color);
          border: thin solid var(--theme-color-line);
          justify-content: space-between;
          margin-bottom: 0.2rem;
          position: relative;
          -ms-flex-align: center;
          align-items: center;
          display: -ms-flexbox;
          display: flex;
          border-radius: 0.14rem;
          height: 0.8rem;
          margin-right: 0;
          padding: 0.1rem 0.2rem 0.1rem 0.1rem;
          width: 100%;
          .left {
            -ms-flex-align: center;
            align-items: center;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-negative: 0;
            flex-shrink: 0;
            width: 4.2rem;
            .text {
              -ms-flex-pack: center;
              display: -ms-flexbox;
              display: flex;
              -ms-flex-direction: column;
              flex-direction: column;
              justify-content: center;
              margin-left: 0.2rem;
              .t {
                color: var(--theme-text-color-darken);
                display: -ms-flexbox;
                display: flex;
              }
            }
          }
          .right {
            -ms-flex-align: center;
            align-items: center;
            display: -ms-flexbox;
            display: flex;
            font-size: 0.26rem;
            line-height: 1.5;
            text-align: right;
            a {
              -webkit-text-decoration-skip: objects;
              background-color: transparent;
              color: var(--theme-ant-primary-color-0);
              cursor: pointer;
              outline: none;
              -webkit-transition: color 0.3s;
              transition: color 0.3s;
            }
          }
        }
        .item:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
.tipsbox {
  -ms-flex-align: center;
  -ms-flex-pack: justify;
  align-items: center;
  color: var(--theme-text-color-darken);
  display: -ms-flexbox;
  display: flex;
  font-size: 0.16rem;
  justify-content: space-between;
  margin-top: 0.12rem;
  font-size: 0.22rem;
  .forgot-password {
    color: var(--theme-primary-color);
    cursor: pointer;
    text-align: right;
  }
}
.sunbitbox {
  -ms-flex-align: center;
  -ms-flex-pack: center;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;
}
.addCardForm {
  padding: 0 0.3rem 0.3rem;
  .tips {
    font-size: 0.2rem;
    color: var(--theme-text-color-darken);
    margin-top: 0.16rem;
  }
  .addbutton {
    -ms-flex-align: center;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    margin-top: 0.5rem;
  }
  /deep/.el-form-item__content {
    display: block;
    .myinput {
      width: 100%;
      background-color: var(--theme-main-bg-color) !important;
      color: var(--theme-text-color) !important;
      border-radius: 0.14rem !important;
      font-size: 0.22rem !important;
      height: 0.7rem !important;
      .el-input__inner {
        background-color: var(--theme-main-bg-color);
        border-color: var(--theme-color-line) !important;
        color: var(--theme-text-color-darken);
        border-radius: 0.14rem;
        font-size: 0.22rem;
        height: 0.7rem;
        padding: 0 0.6rem;
      }
      .el-input__prefix {
        -ms-flex-align: center;
        align-items: center;
        color: rgba(0, 0, 0, 0.65);
        display: -ms-flexbox;
        display: flex;
        line-height: 0;
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        z-index: 2;
        left: 0.2rem;
        font-size: 0.22rem !important;
      }
    }
    .err-explain {
      margin-top: 0;
    }
  }
}
</style>
