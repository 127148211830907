<template>
    <div v-if="dialogTableVisible" style="z-index: 9999;position:absolute;">
  <el-dialog :show-close="false" :fullscreen="true" :visible="true" :modal="false">
    <template slot="title">
      <van-nav-bar
        @click-left="closeDialog"
        right-text=" "
        :fixed="true"
        :border="false"
        style="
          max-width: var(--theme-max-width);
          margin: 0 auto;
          left: 0;
          right: 0;
          height: 0.9rem;
        "
      >
        <template #left>
          <div>
            <i style="font-size: 0.24rem">
              <svg-icon
                icon-class="comm_icon_fh"
                style="
                  width: 1em;
                  height: 1em;
                  color: var(--theme-text-color);
                  fill: var(--theme-text-color-lighten);
                "
              ></svg-icon>
            </i>
          </div>
        </template>

        <template #title>
          <div class="title">
            <span class="global-wrapper-title">
              <span
                style="
                  width: 100%;
                  margin-bottom: -1.64706px;
                  padding-bottom: 1.64706px;
                  font-size: 0.3rem;
                "
                ><span
                  style="
                    vertical-align: middle;
                    display: inline-block;
                    width: 100%;
                  "
                >
                  <span style="-webkit-line-clamp: 2" class="ts inner-text">
                    {{ title }}</span
                  >
                </span></span
              >
            </span>
          </div>
        </template>
      </van-nav-bar>
    </template>
    <slot></slot>
  </el-dialog>
</div>
</template>
<script>
import myScrollbarBar from "@/components/phone/common/myScrollbarBar.vue";
export default {
  name: "fullDialog",
  components: { myScrollbarBar },
  mixins: [],

  props: {
    dialogTableVisible: Boolean,
    payInfo: {},
    title: String,
  },
  data() {
    return {
     
    };
  },
  computed: {},
  watch: {},
  
  mounted() {},
  methods: {
    closeDialog() {
      this.showBox = false;
      // this.dialogTableVisible = false;
      this.$emit("update:dialogTableVisible", this.showBox);
      this.$emit("closeDialog");
    },
  },
};
</script>
<style type="text/less" lang="less" scoped>
.van-nav-bar {
  border-bottom: 0.01rem solid var(--theme-color-line);
  background: var(--theme-main-bg-color);
  /deep/.van-nav-bar__content {
    height: 0.9rem;
    padding: 0px 0.1rem 0px 0.2rem;
  }

  /deep/.van-nav-bar__right {
    width: 0.9rem;
    display: flex;
    justify-content: flex-end;
    position: inherit;
    padding: 0;
    font-size: 0.22rem;
  }
  /deep/.van-nav-bar__left {
    position: inherit;
    padding: 0;
    width: 0.8rem;
    div {
      align-items: center;
      color: var(--theme-text-color);
      cursor: pointer;
      display: flex;
      font-size: 0.2rem;
      font-size: 0.24rem;
      height: 0.8rem;
      width: 0.8rem;
    }
  }
  /deep/.van-nav-bar__title {
    align-items: center;
    display: flex;
    flex: 2 1 0%;
    flex-direction: column;
    margin: 0;
    max-width: none;
    // max-width: max-content;
    .title {
      // padding: 0px 0.34rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--theme-text-color);
      span {
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        color: var(--theme-text-color-darken);
        display: -webkit-box;
        margin: 0px 0.12rem;
        overflow: hidden;
        text-align: center;
        text-overflow: ellipsis;
        vertical-align: middle;
      }
      .global-wrapper-title {
        font-size: 0.3rem;

        margin-top: -0.014rem;

        .ts {
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          display: -webkit-box;
          line-height: 1.2;
          overflow: initial;
          text-align: center;
          text-overflow: ellipsis;
          vertical-align: middle;
          word-break: break-word;
        }
      }
    }
  }

  /deep/.van-nav-bar__arrow {
    color: var(--theme-text-color);
  }

  /deep/.van-nav-bar__text {
    color: var(--theme-ant-primary-color-0);
    word-wrap: break-word;
    line-height: 1.1;
    text-align: center;
    white-space: pre-line;
    word-break: break-word;
    //  font-size: 24.576px;
    font-weight: 400;
    user-select: none;
    font: inherit;
    text-transform: none;
    box-sizing: border-box;
    pointer-events: none;
  }
  /deep/.van-ellipsis {
    white-space: inherit;
  }
}
/deep/.el-dialog {
  background-color: var(--theme-bg-color);
  .el-dialog__body {
    padding: 30px 0 !important;
  }
}
</style>
