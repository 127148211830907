<template>
  <span class="main">
    <input class="search-input" @change="changeText" v-model="text" :placeholder="placeholder" />
    <span class="suffix">
      <i
        aria-label="icon: search"
        v-show="!loading"
        @click="searchClick"
        tabindex="-1"
        class="anticon anticon-search"
        ><svg
          viewBox="64 64 896 896"
          data-icon="search"
          width="1em"
          height="1em"
          fill="currentColor"
          aria-hidden="true"
          focusable="false"
          class=""
        >
          <path
            d="M909.6 854.5L649.9 594.8C690.2 542.7 712 479 712 412c0-80.2-31.3-155.4-87.9-212.1-56.6-56.7-132-87.9-212.1-87.9s-155.5 31.3-212.1 87.9C143.2 256.5 112 331.8 112 412c0 80.1 31.3 155.5 87.9 212.1C256.5 680.8 331.8 712 412 712c67 0 130.6-21.8 182.7-62l259.7 259.6a8.2 8.2 0 0 0 11.6 0l43.6-43.5a8.2 8.2 0 0 0 0-11.6zM570.4 570.4C528 612.7 471.8 636 412 636s-116-23.3-158.4-65.6C211.3 528 188 471.8 188 412s23.3-116.1 65.6-158.4C296 211.3 352.2 188 412 188s116.1 23.2 158.4 65.6S636 352.2 636 412s-23.3 116.1-65.6 158.4z"
          ></path></svg></i>
      <i
        v-show="loading"
        aria-label="icon: loading"
        class="anticon anticon-loading input-search-icon"
      >
        <svg
          viewBox="0 0 1024 1024"
          data-icon="loading"
          width="1em"
          height="1em"
          fill="currentColor"
          aria-hidden="true"
          focusable="false"
          class="anticon-spin"
        >
          <path
            d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 0 0-94.3-139.9 437.71 437.71 0 0 0-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"
          ></path>
        </svg>
      </i>
    </span>
  </span>
</template>
<script>
export default {
  name: "search",
  components: {},
  mixins: [],

  props: {
    placeholder: String,
    loading: {
      type: Boolean,
      required: true,
    },
    findtext: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      text: this.findtext,
    };
  },
  computed: {},
  watch: {},
  mounted() {
    if (this.findtext) {
      this.text = this.findtext;
    }
   

  },
  methods: {
    searchClick() {
      if (!this.loading) {
        this.$emit("searchClick", this.findtext);
      }
    },
    changeText(){
      this.$emit("update:findtext", this.text);
    
    }
  },
};
</script>
<style type="text/less" lang="less" scoped>
.main {
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.65);
  display: inline-block;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
}
.search-input {
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.65);
  display: inline-block;
  font-size: 14px;
  font-variant: tabular-nums;
  height: 32px;
  line-height: 1.5;
  list-style: none;
  margin: 0;
  padding: 4px 11px;
  position: relative;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  width: 100%;
}
.search-input {
  text-overflow: ellipsis;
  background-color: var(--theme-main-bg-color);
  border-color: var(--theme-color-line);
  color: var(--theme-text-color-lighten);
  border-radius: 0.5rem;
  color: var(--theme-text-color-darken);
  font-size: 0.18rem;
  height: 0.5rem;
  width: 2.2rem;
  position: relative;
  text-align: inherit;
}
.search-input:not(:last-child) {
  padding-right: 30px;
}
.suffix {
  -ms-flex-align: center;
  align-items: center;
  color: var(--theme-primary-color);
  display: -ms-flexbox;
  display: flex;
  line-height: 0;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 2;
  right: 12px;
}
</style>
