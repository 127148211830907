<template>
  <section class="main">
    <section class="box">
      <dragScroll>
        <div class="head-container">
          <mySelect
            :items="dateList"
            class="myselect"
            :selectItem.sync="dateselectItem"
            @change="itemChange"
          ></mySelect>
          <ul class="otherselect">
            <li>
              <mySelect
                :items="categoryList"
                class="myselect"
                :selectItem.sync="categoryselectItem"
                @change="categoryChange"
                :filterable="true"
              ></mySelect>
            </li>
            <li>
              <mySelect
                :items="vendorList"
                class="myselect"
                :selectItem.sync="vendorselectItem"
                @change="vendorChange"
                :filterable="true"
              ></mySelect>
            </li>
          </ul>
        </div>
      </dragScroll>

      <div class="footerDataList">
        <van-empty
          v-if="list.length < 1"
          :image="require('@/assets/azf5f-28vaj.png')"
          :description="$t('no records')"
        />
        <van-list
          v-model="loading"
          :finished="finished"
          @load="onLoad"
          offset="100"
          :loading-text="$t('loading')"
          v-else
        >
          <div class="sticky" v-for="(item, index) in list">
            <div class="left">
              <p>
                <span>
                  {{ item.vendor_code }}&nbsp; {{ item.vendor_category_code }}/
                  {{ item.game_code }}</span
                >
              </p>
              <p>
                <span>{{ item.day }}</span>
              </p>
            </div>
            <div class="right">
              <p>
                <span
                  ><span style="color: var(--theme-text-color)">{{
                    item.count_bet
                  }}</span
                  >/<span style="color: var(--theme-text-color)"
                    >R$ {{ formatNumberToString(item.total_bet) }}</span
                  >/<span class="isRed" v-if="item.win_loss >= 0"
                    >+R$ {{ formatNumberToString(item.win_loss) }}</span
                  >
                  <span class="isGreen" v-else
                    >-R$ {{ formatNumberToString(-item.win_loss) }}</span
                  >
                </span>
              </p>
            </div>
          </div>
        </van-list>
      </div>
    </section>
  </section>
</template>
<script>
import mySelect from "@/components/phone/common/mySelect.vue";
import dragScroll from "@/components/phone/common/dragScroll.vue";
import {
  getLogsGameReport,
  gameCategoryList,
  getVendorByCategory,
} from "@/api/index";
import { formatNumberToString2 } from "@/utils/utils";
export default {
  name: "reportSummary",
  components: { mySelect, dragScroll },
  mixins: [],
  data() {
    return {
      list: [],
      dateselectItem: "today",
      categoryselectItem: "all",
      vendorselectItem: "all",
      loading: false,
      pageIndex: 0,
      pageSize: 50,
      finished: false,
      dateList: [
        { label: this.$t("today"), value: "today" },
        { label: this.$t("yesterday"), value: "yesterday" },
        { label: this.$t("Last 7 Days"), value: "last_week" },
        { label: this.$t("Last 15 Days"), value: "last_half_month" },
        { label: this.$t("Last 30 Days"), value: "last_month" },
      ],
      categoryList: [{ label: this.$t("all types"), value: "all" }],
      vendorList: [{ label: this.$t('all platforms'), value: "all" }],
    };
  },
  computed: {},
  watch: {},
  activated() {
    this.pageIndex = 0;
  },
  mounted() {
    // this.getList();
    gameCategoryList().then(({ data }) => {
      let list = data.data.game_category_list;
      list.forEach((item) => {
        this.categoryList.push({ label: item, value: item });
      });
    });
  },
  methods: {
    itemChange(v) {
      this.pageIndex = 0;
      this.finished = false;
      this.list = [];
      this.getList();
    },
    getList() {
      getLogsGameReport({
        time_rand: this.dateselectItem,
        category:
          this.categoryselectItem === "all" ? "" : this.categoryselectItem,
        vendor: this.vendorselectItem === "all" ? "" : this.vendorselectItem,
        page: this.pageIndex,
        count: this.pageSize,
      }).then(({ data }) => {
        if (data.code === 0) {
          if (data.data.logs.length > 0) {
            this.list = this.list.concat(data.data.logs);
          }
          if (data.data.logs.length < this.pageSize) {
            this.finished = true;
          }
          // this.total_deposit = data.data.total_deposit;
          // this.total_withdraw = data.data.total_withdraw;
          this.loading = false;

          this.$emit("change", data.data);
        }
      });
    },
    onLoad() {
      this.pageIndex = this.pageIndex + 1;
      this.getList();
    },
    categoryChange(name) {
      this.vendorList = [{ label:this.$t("all platforms"), value: "all" }];
      this.vendorselectItem = "all";

      if (name !== "all") {
        getVendorByCategory({ category: name }).then(({ data }) => {
          let list = data.data.vendor_list;
          list.forEach((item) => {
            this.vendorList.push({ label: item.name, value: item.code });
          });
        });
      }
      this.list = [];
      this.getList();
    },
    vendorChange(item) {
      this.list = [];
      this.getList();
    },
    formatNumberToString(v) {
      return formatNumberToString2(v);
    },
  },
};
</script>
<style type="text/less" lang="less" scoped>
.main {
  padding: 0;
  .box {
    padding: 0.2rem 0;
  }
  .head-container {
    margin-bottom: 0.2rem !important;
    -ms-flex-pack: start;
    display: -ms-flexbox;
    display: flex;
    justify-content: flex-start;

    position: relative;

    padding-left: 0.2rem;
    padding-right: 0.2rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    .myselect {
      max-width: 3.15rem;
      min-width: 1.6rem;
      width: auto !important;
      margin-right: 0.2rem;
    }
    .otherselect {
      display: -ms-flexbox;
      display: flex;
      height: 0.5rem;
      margin: 0;
      li {
        margin-right: 0.2rem;
      }
    }
  }
  .footerDataList {
    -ms-flex-pack: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    position: relative;
    height: calc(var(--vh, 1vh) * 100 - 3.55rem);

    /deep/ .van-list > div {
      border-radius: 0.1rem;
    }
    .sticky {
      -ms-flex-align: center;
      -ms-flex-pack: justify;
      align-items: center;
      display: -ms-flexbox;
      display: flex;
      font-size: 0.2rem;
      justify-content: space-between;
      padding: 0.2rem !important;
      .left {
        color: var(--theme-text-color-lighten);
      }
      .right {
        color: var(--theme-text-color-lighten);
        text-align: right;
      }
    }
    /deep/ .van-list > div:nth-child(odd) {
      background-color: var(--theme-main-bg-color);
    }
    /deep/ .van-list > div:nth-child(2n) {
      background-color: var(--theme-bg-color);
    }
  }
}
</style>
