<template>
  <div>
    <div class="wrap-view">
      <div class="login-register-body">
        <van-tabs
          v-model="active"
          background="var(--theme-main-bg-color)"
          title-active-color="var(--theme-primary-color)"
          title-inactive-color="var(--theme-text-color-darken)"
          color="#fff"
          :scrollspy="true"
          :swipeable="true"
          :animated="true"
          :ellipsis="false"
          :line-width="lineWidth"
          @change="changeTabs"
        >
          <van-tab title-class=" van-title ">
            <template slot="title">
              <span class="Login-tabTitle" :ref="'tabTitle0'">
                <i
                  style="
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 0.08rem;
                  "
                >
                  <svg-icon
                    icon-class="login_icon_zj"
                    style="width: 1em; height: 1em; fill: currentColor"
                  ></svg-icon>
                </i>
                {{ $t("sign up") }}
              </span>
            </template>

            <el-form
              :model="regForm"
              :rules="rules2"
              class="loginForm"
              ref="reg"
            >
              <div
                class="my-scrollbar my-scrollbar-hover"
                style="width: 100%; height: 6rem"
              >
                <div class="my-scrollbar-wrap my-scrollbar-wrap-all">
                  <div class="my-scrollbar-content">
                    <div
                      class="login-register-from-scroll ant-space ant-space-vertical login-register-from-space login-register-from-scroll"
                    >
                      <el-form-item>
                        <div class="loginTitle">
                          {{ $t("only supports accountRegistration") }}
                        </div>
                      </el-form-item>
                      <el-form-item
                        prop="account"
                        style="margin-bottom: 0.2rem"
                      >
                        <el-input
                          v-model="regForm.account"
                          :placeholder="$t('please enter account')"
                          class="login-input"
                        >
                          <template slot="prefix">
                            <i class="headIco">
                              <svg-icon
                                icon-class="input_icon_zh"
                                style="
                                  width: 0.25rem;
                                  height: 0.25rem;
                                  fill: currentColor;
                                "
                              ></svg-icon>
                            </i>
                            <span
                              style="
                                color: var(--theme-secondary-color-error);
                                display: inline-block;
                                margin-left: 0.04rem;
                              "
                              >*</span
                            >
                          </template>
                          <template slot="suffix">
                            <i
                              v-show="regForm.account.length > 0"
                              style="
                                display: inline-flex;
                                justify-content: center;
                                align-items: center;
                                color: var(--theme-text-color-lighten);
                                height: 0.36rem;
                                width: 0.36rem;
                                font-size: 0.18rem;
                                cursor: pointer;
                              "
                              @click="regForm.account = ''"
                            >
                              <svg-icon
                                icon-class="input_icon_clear"
                                style="
                                  width: 0.36rem;
                                  height: 0.36rem;
                                  fill: currentColor;
                                "
                              ></svg-icon>
                            </i>
                          </template>
                        </el-input>
                        <template slot="error" slot-scope="scope">
                          <div class="err-explain">{{ scope.error }}</div>
                        </template>
                      </el-form-item>
                      <el-form-item
                        prop="password"
                        style="margin-bottom: 0.2rem"
                      >
                        <el-input
                          v-model="regForm.password"
                          maxlength="16"
                          :placeholder="$t('password')"
                          class="login-input login-pass"
                          :type="showPsw2 ? 'text' : 'password'"
                        >
                          <template slot="prefix">
                            <i class="headIco">
                              <svg-icon
                                icon-class="input_icon_mm"
                                style="
                                  width: 0.25rem;
                                  height: 0.25rem;
                                  fill: currentColor;
                                "
                              ></svg-icon>
                            </i>
                            <span
                              style="
                                color: var(--theme-secondary-color-error);
                                display: inline-block;
                                margin-left: 0.04rem;
                              "
                              >*</span
                            >
                          </template>
                          <template slot="suffix">
                            <i
                              v-show="regForm.password.length > 0"
                              style="
                                display: inline-flex;
                                justify-content: center;
                                align-items: center;
                                color: var(--theme-text-color-lighten);
                                height: 0.36rem;
                                width: 0.36rem;
                                font-size: 0.18rem;
                                cursor: pointer;
                              "
                              @click="regForm.password = ''"
                            >
                              <svg-icon
                                icon-class="input_icon_clear"
                                style="
                                  width: 0.36rem;
                                  height: 0.36rem;
                                  fill: currentColor;
                                "
                              ></svg-icon>
                            </i>
                            <i
                              style="
                                display: inline-flex;
                                justify-content: center;
                                align-items: center;
                                color: var(--theme-text-color-lighten);

                                font-size: 0.36rem;
                                cursor: pointer;
                                padding-right: 0.01rem;
                                margin-left: 0.2rem;
                                padding-right: 0.01rem;
                              "
                              @click="showPsw2 = !showPsw2"
                            >
                              <svg-icon
                                :icon-class="
                                  !showPsw2
                                    ? 'comm_icon_hide'
                                    : 'comm_icon_show'
                                "
                                style="
                                  width: 1em;
                                  height: 1em;
                                  fill: currentColor;
                                "
                                :style="
                                  !showPsw2
                                    ? 'color: var(--theme-color-line);'
                                    : 'color: var(--theme-primary-color);'
                                "
                              ></svg-icon>
                            </i>
                          </template>
                        </el-input>
                      </el-form-item>
                      <passwordSecurityLevel
                        :passValue="regForm.password"
                      ></passwordSecurityLevel>

                      <el-form-item
                        prop="password2"
                        style="margin-bottom: 0.2rem"
                      >
                        <el-input
                          class="login-input login-pass"
                          v-model="regForm.password2"
                          maxlength="16"
                          :placeholder="
                            $t(
                              'confirm the password again, same as the password'
                            )
                          "
                          :type="showPsw3 ? 'text' : 'password'"
                        >
                          <template slot="prefix">
                            <i class="headIco">
                              <svg-icon
                                icon-class="input_icon_mm"
                                style="
                                  width: 0.25rem;
                                  height: 0.25rem;
                                  fill: currentColor;
                                "
                              ></svg-icon>
                            </i>
                            <span
                              style="
                                color: var(--theme-secondary-color-error);
                                display: inline-block;
                                margin-left: 0.04rem;
                              "
                              >*</span
                            >
                          </template>
                          <template slot="suffix">
                            <i
                              v-show="regForm.password2.length > 0"
                              style="
                                display: inline-flex;
                                justify-content: center;
                                align-items: center;
                                color: var(--theme-text-color-lighten);
                                height: 0.36rem;
                                width: 0.36rem;
                                font-size: 0.36rem;
                                cursor: pointer;
                              "
                              @click="regForm.password2 = ''"
                            >
                              <svg-icon
                                icon-class="input_icon_clear"
                                style="fill: currentColor"
                              ></svg-icon>
                            </i>
                            <i
                              style="
                                display: inline-flex;
                                justify-content: center;
                                align-items: center;
                                color: var(--theme-text-color-lighten);

                                font-size: 0.36rem;
                                cursor: pointer;
                                padding-right: 0.01rem;
                                margin-left: 0.2rem;
                                padding-right: 0.01rem;
                              "
                              @click="showPsw3 = !showPsw3"
                            >
                              <svg-icon
                                :icon-class="
                                  !showPsw3
                                    ? 'comm_icon_hide'
                                    : 'comm_icon_show'
                                "
                                style="
                                  width: 1em;
                                  height: 1em;
                                  fill: currentColor;
                                "
                                :style="
                                  !showPsw3
                                    ? 'color: var(--theme-color-line);'
                                    : 'color: var(--theme-primary-color);'
                                "
                              ></svg-icon>
                            </i>
                          </template>
                        </el-input>
                        <template slot="error" slot-scope="scope">
                          <div class="err-explain">{{ scope.error }}</div>
                        </template>
                      </el-form-item>

                      <el-form-item
                        prop="realName"
                        style="margin-bottom: 0.2rem"
                      >
                        <el-input
                          v-model="regForm.realName"
                          :placeholder="
                            $t(
                              'please fill in the real name and make it convenient for later withdrawal'
                            )
                          "
                          class="login-input"
                        >
                          >
                          <template slot="prefix">
                            <i class="anticon headIco">
                              <svg-icon
                                icon-class="input_icon_zsxm"
                                style="
                                  width: 0.25rem;
                                  height: 0.25rem;
                                  color: var(--theme-text-color-lighten);
                                  fill: currentColor;
                                "
                              ></svg-icon>
                            </i>
                            <span
                              style="
                                color: var(--theme-secondary-color-error);
                                display: inline-block;
                                margin-left: 0.04rem;
                              "
                              >*</span
                            >
                          </template>

                          <template slot="suffix">
                            <i
                              v-show="regForm.realName.length > 0"
                              style="
                                display: inline-flex;
                                justify-content: center;
                                align-items: center;
                                color: var(--theme-text-color-lighten);
                                height: 0.36rem;
                                width: 0.36rem;
                                font-size: 0.18rem;
                                cursor: pointer;
                              "
                              @click="regForm.realName = ''"
                            >
                              <svg-icon
                                icon-class="input_icon_clear"
                                style="
                                  width: 0.36rem;
                                  height: 0.36rem;
                                  fill: currentColor;
                                "
                              ></svg-icon>
                            </i>
                          </template>
                        </el-input>
                        <template slot="error" slot-scope="scope">
                          <div class="err-explain">{{ scope.error }}</div>
                        </template>
                      </el-form-item>

                      <el-form-item prop="cpf" style="margin-bottom: 0.2rem">
                        <el-input
                          v-model="regForm.cpf"
                          class="login-input"
                          :placeholder="
                            $t(
                              'fill in the CPF and make it convenient for later withdrawal'
                            )
                          "
                        >
                          <template slot="prefix">
                            <i class="anticon headIco">
                              <svg-icon
                                icon-class="wd_cpf"
                                style="
                                  width: 0.25rem;
                                  height: 0.25rem;
                                  fill: currentColor;
                                "
                              ></svg-icon>
                            </i>
                          </template>
                          <template slot="suffix">
                            <i
                              v-show="regForm.cpf.length > 0"
                              style="
                                display: inline-flex;
                                justify-content: center;
                                align-items: center;
                                color: var(--theme-text-color-lighten);
                                height: 0.36rem;
                                width: 0.36rem;
                                font-size: 0.36rem;
                                cursor: pointer;
                              "
                              @click="regForm.cpf = ''"
                            >
                              <svg-icon
                                icon-class="input_icon_clear"
                                style="
                                  width: 0.36rem;
                                  height: 0.36rem;
                                  fill: currentColor;
                                "
                              ></svg-icon>
                            </i>
                          </template>
                        </el-input>
                        <template slot="error" slot-scope="scope">
                          <div class="err-explain">{{ scope.error }}</div>
                        </template>
                      </el-form-item>

                      <el-form-item
                        style="margin-bottom: 0.2rem"
                        prop="currency"
                      >
                        <el-select
                          class="login-selectIpt"
                          v-model="regForm.currency"
                          :popper-append-to-body="true"
                          popper-class="myselectitem login-selectMenu"
                        >
                          <template slot="prefix">
                            <div class="ico">
                              <i class="login-brl-ico"></i>
                            </div>
                          </template>

                          <el-option value="BRL">
                            <div class="my-item">
                              <i class="login-brl-ico"></i>
                              BRL
                            </div>
                          </el-option>
                        </el-select>
                        <div class="tips">
                          {{
                            $t(
                              "currency determines third party games and payment methods and cannot be modified"
                            )
                          }}
                        </div>
                      </el-form-item>

                      <el-form-item>
                        <el-input
                          v-model="regForm.inviteCode"
                          :placeholder="$t('please enter your invitation code')"
                          class="login-input"
                        >
                          <template slot="prefix">
                            <i class="anticon headIco">
                              <svg-icon
                                icon-class="input_icon_yqm"
                                style="
                                  width: 0.25rem;
                                  height: 0.25rem;

                                  fill: currentColor;
                                "
                              ></svg-icon>
                            </i>
                          </template>
                        </el-input>
                      </el-form-item>
                    </div>
                  </div>
                </div>
              </div>
            </el-form>
          </van-tab>

          <van-tab>
            <template slot="title">
              <span class="Login-tabTitle" :ref="'tabTitle1'">
                <i
                  style="
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 0.08rem;
                  "
                >
                  <svg-icon
                    icon-class="login_icon_dl"
                    style="width: 1em; height: 1em; fill: currentColor"
                  ></svg-icon>
                </i>
               {{ $t('member login') }}
              </span>
            </template>
            <el-form
              :model="ruleForm"
              :rules="rules"
              ref="ruleForm"
              class="loginForm"
            >
              <div class="login-register-from-space">
                <el-form-item>
                  <div class="loginTitle">{{ $t('only supports accountLogin') }}</div>
                </el-form-item>
                <el-form-item style="margin-bottom: 0.2rem" prop="account">
                  <el-input
                    v-model="ruleForm.account"
                    class="login-input"
                    maxlength="16"
                    :placeholder="$t('please enter account')"
                  >
                    <template slot="prefix">
                      <i class="headIco">
                        <svg-icon
                          icon-class="input_icon_zh"
                          style="
                            width: 0.25rem;
                            height: 0.25rem;
                            fill: currentColor;
                          "
                        ></svg-icon>
                      </i>
                      <span
                        style="
                          color: var(--theme-secondary-color-error);
                          display: inline-block;
                          margin-left: 0.04rem;
                        "
                        >*</span
                      >
                    </template>
                    <template slot="suffix">
                      <i
                        v-show="ruleForm.account.length > 0"
                        style="
                          display: inline-flex;
                          justify-content: center;
                          align-items: center;
                          color: var(--theme-text-color-lighten);
                          height: 0.36rem;
                          width: 0.36rem;
                          font-size: 0.36rem;
                          cursor: pointer;
                        "
                        @click="ruleForm.account = ''"
                      >
                        <svg-icon
                          icon-class="input_icon_clear"
                          style="
                            width: 0.36rem;
                            height: 0.36rem;
                            fill: currentColor;
                          "
                        ></svg-icon>
                      </i>
                    </template>
                  </el-input>
                  <template slot="error" slot-scope="scope">
                    <div class="err-explain">{{ scope.error }}</div>
                  </template>
                </el-form-item>
                <el-form-item style="margin-bottom: 0.2rem" prop="password">
                  <el-input
                    v-model="ruleForm.password"
                    class="login-input login-pass"
                    maxlength="16"
                    :type="showPsw ? 'text' : 'password'"
                    :placeholder="$t('password')"
                  >
                    <template slot="prefix">
                      <i class="headIco">
                        <svg-icon
                          icon-class="input_icon_mm"
                          style="
                            width: 0.25rem;
                            height: 0.25rem;
                            fill: currentColor;
                          "
                        ></svg-icon>
                      </i>
                      <span
                        style="
                          color: var(--theme-secondary-color-error);
                          display: inline-block;
                          margin-left: 0.04rem;
                        "
                        >*</span
                      >
                    </template>
                    <template slot="suffix">
                      <i
                        v-show="ruleForm.password.length > 0"
                        style="
                          display: inline-flex;
                          justify-content: center;
                          align-items: center;
                          color: var(--theme-text-color-lighten);
                          height: 0.36rem;
                          width: 0.36rem;
                          font-size: 0.36rem;
                          cursor: pointer;
                        "
                        @click="ruleForm.password = ''"
                      >
                        <svg-icon
                          icon-class="input_icon_clear"
                          style="fill: currentColor"
                        ></svg-icon>
                      </i>
                      <i
                        style="
                          display: inline-flex;
                          justify-content: center;
                          align-items: center;
                          color: var(--theme-text-color-lighten);

                          font-size: 0.36rem;
                          cursor: pointer;
                          padding-right: 0.01rem;
                          margin-left: 0.2rem;
                          padding-right: 0.01rem;
                        "
                        @click="showPsw = !showPsw"
                      >
                        <svg-icon
                          :icon-class="
                            !showPsw ? 'comm_icon_hide' : 'comm_icon_show'
                          "
                          style="width: 1em; height: 1em; fill: currentColor"
                          :style="
                            !showPsw
                              ? 'color: var(--theme-color-line);'
                              : 'color: var(--theme-primary-color);'
                          "
                        ></svg-icon>
                      </i>
                    </template>
                  </el-input>
                  <template slot="error" slot-scope="scope">
                    <div class="err-explain">{{ scope.error }}</div>
                  </template>
                </el-form-item>
              </div>
            </el-form>
          </van-tab>
        </van-tabs>

        <div class="login-btns">
          <div class="my-login-checkbox my-login-item">
            <van-checkbox
              v-if="active === 0"
              v-model="checked"
              checked-color="#04BE02"
              shape="square"
            >
              <template slot="default">
                {{ $t("18+") }}
                <span style="color: var(--theme-primary-color)">
                  {{ $t("useragreement") }}
                </span>
              </template>
            </van-checkbox>

            <van-checkbox
              v-if="active === 1"
              v-model="rememberPsw"
              checked-color="#04BE02"
              shape="square"
              >{{ $t("forgot password") }}</van-checkbox
            >
          </div>
          <div class="my-login-item">
            <van-button
              @click="register"
              style="width: 100%"
              class="highlight-button"
              v-if="active === 0"
            >
              <i v-if="registerLoading" class="el-icon-loading"></i>
              {{ $t("register") }}
            </van-button>

            <van-button
              @click="login"
              style="width: 100%"
              class="highlight-button"
              v-if="active === 1"
            >
              <i v-if="registerLoading" class="el-icon-loading"></i>
              {{ $t("login") }}
            </van-button>
          </div>

          <ul class="my-login-item login-service" v-if="active === 0">
            <li><span>{{ $t('client support') }}</span></li>
            <li><span>
              <!-- Jogar Demo -->
              
            </span></li>
          </ul>

          <ul class="my-login-item login-service" v-if="active === 1">
            <li><span>{{ $t('client support') }}</span></li>
            <li><span>
              <!-- Jogar Demo -->
            </span></li>
            <li><span>{{ $t('forgot password') }}</span></li>
          </ul>
        </div>
      </div>

      <div class="closeIcon" @click="closeDialog">
        <i aria-hidden="true" focusable="false" class=""></i>
      </div>
    </div>
  </div>
</template>
<script>
// import { showNotify } from 'vant';

import { checkAccount } from "@/api/index";
import Cookies from "js-cookie";
import LocalStorageCache from "@/utils/localStorageCache.js";
import passwordSecurityLevel from "@/components/phone/common/passwordSecurityLevel.vue";
import { getElementWidth, getBrowser, getOperatingSystem } from "@/utils/utils";

export default {
  name: "",
  components: { passwordSecurityLevel },
  mixins: [],
  props: {
    type: {
      default: 0,
    },
  },
  data() {
  
    return {
      showPsw: false,
      showPsw2: false,
      showPsw3: false,
      dialogLogin: true,
      active: 0,
      lineWidth: 0,
      ruleForm: {
        account: "",
        password: "",
        device_id: "",
        device_info: "",
      },
      rules: {
        account: [
          {
            required: true,
            message: this.$t("please enter account"),
            trigger: "blur",
          },
          {
            min: 6,
            max: 16,
            message: this.$t("6 to 16 characters"),
            trigger: "blur",
          },
        ],
        // password: [
        //   {
        //     required: true,
        //     message: this.$t("Enter the password"),
        //     trigger: "blur",
        //   },
        //   {
        //     min: 8,
        //     max: 18,
        //     message: this.$t("6 to 18 characters"),
        //     trigger: "blur",
        //   },
        // ],
      },

      regForm: {
        account: "",
        phone: "",
        password: "",
        password2: "",
        telHead: "+55",
        tel: "",
        realName: "",
        cpf: "",
        currency: "BRL",
        inviteCode: "",
        device_id: "",
        device_info: "",
      },
      rules2: {
        account: [
          {
            required: true,
            message: this.$t("6 to 16 characters"),
            trigger: "blur",
          },
          {
            min: 6,
            max: 16,
            message: this.$t("6 to 16 characters"),
            trigger: "blur",
          },
        ],
        // password: [
        //   {
        //     required: true,
        //     message: this.$t("6 to 18 characters"),
        //     trigger: "blur",
        //   },
        //   {
        //     min: 6,
        //     max: 18,
        //     message: this.$t("6 to 18 characters"),
        //     trigger: "blur",
        //   },
        //   {
        //     validator: (rule, value, callback) => {
        //       const hasUpperCase = /[A-Z]/.test(value);
        //       const hasLowerCase = /[a-z]/.test(value);
        //       const hasNumber = /\d/.test(value);
        //       const hasSpecialChar =
        //         /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(value);

        //       if (!hasUpperCase) {
        //         callback(
        //           new Error(
        //             this.$t(
        //               "Password must contain at least one uppercase letter"
        //             )
        //           )
        //         );
        //       } else if (!hasLowerCase) {
        //         callback(
        //           new Error(
        //             this.$t(
        //               "Password must contain at least one lowercase letter"
        //             )
        //           )
        //         );
        //       } else if (!hasNumber) {
        //         callback(
        //           new Error(
        //             this.$t("Password must contain at least one number")
        //           )
        //         );
        //       } else if (!hasSpecialChar) {
        //         callback(
        //           new Error(
        //             this.$t(
        //               "Password must contain at least one special character"
        //             )
        //           )
        //         );
        //       } else {
        //         callback();
        //       }
        //     },
        //     trigger: "blur",
        //   },
        // ],
        // password2: [
        //   {
        //     required: true,
        //     message: this.$t("6 to 18 characters"),
        //     trigger: "blur",
        //   },
        //   {
        //     min: 6,
        //     max: 18,
        //     message: this.$t("6 to 18 characters"),
        //     trigger: "blur",
        //   },
        //   {
        //     validator: (rule, value, callback) => {
        //       if (value !== this.regForm.password) {
        //         callback(new Error(this.$t("The two passwords do not match")));
        //       } else {
        //         callback();
        //       }
        //     },
        //     trigger: "blur",
        //   },
        // ],
        realName: [
          {
            required: true,
            message: this.$t('field empty'),
            trigger: "blur",
          },
        ],
      },
      checked: true,
      rememberPsw: true,

      loginLoading: false,
      registerLoading: false,
    };
  },
  activated() {
    this.regForm.account = "";
    this.regForm.password = "";
    this.regForm.password2 = "";
    this.regForm.tel = "";
    this.regForm.realName = "";
    this.regForm.inviteCode = "";

    this.ruleForm.account = "";
    this.ruleForm.password = "";
    this.getCookie();
    this.getLineWidth();
    this.getCookie();
  },

  computed: {},
  watch: {
    type(t) {
      this.active = t;
    },
  },
  mounted() {
    this.getCookie();
    this.getLineWidth();
    this.active = this.type;

    this.regForm.device_id = LocalStorageCache.get("UniqueFingerprint");
    this.regForm.device_info = getOperatingSystem() + "|" + getBrowser();

    this.ruleForm.device_id = LocalStorageCache.get("UniqueFingerprint");
    this.ruleForm.device_info = getOperatingSystem() + "|" + getBrowser();
  },
  methods: {
    closeDialog() {
      this.$emit("closeDialog");
    },
    getCookie() {
      let account = Cookies.get("account");
      let password = Cookies.get("password");
      account = account === undefined ? "" : account;
      // 保存cookie里面的加密后的密码
      // this.cookiePass = password === undefined ? '' : password
      password = password === undefined ? "" : password;
      this.ruleForm.password = password;
      this.ruleForm.account = account;
      const invite_code = LocalStorageCache.getCache("invite_code");
      // console.log(invite_code);
      this.regForm.inviteCode = invite_code;
    },
    login() {
      this.loginLoading = true;
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.$store
            .dispatch("login", this.ruleForm)
            .then(
              () => {
                if (this.checked) {
                  Cookies.set("account", this.ruleForm.account);
                  Cookies.set("password", this.ruleForm.password);
                } else {
                  Cookies.remove("account");
                  Cookies.remove("password");
                }
                this.loginLoading = false;
                this.closeDialog();
                // location.reload();
                // this.$router.push({ path: this.redirect || '/' })
              },
              (msg) => {
                this.$notify({
                  duration: 2000,
                  message: this.$t(msg),
                  type: "error",
                  background: "#fff",
                  offset: "3",
                });
                this.loginLoading = false;
              }
            )
            .catch(() => {
              this.loginLoading = false;
            });
        } else {
          this.loginLoading = false;
          return false;
        }
      });

      setTimeout(() => {
        this.loginLoading = false;
      }, 5000);
    },
    register() {
      // return;

      this.registerLoading = true;
      this.$refs.reg.validate((valid) => {
        if (valid) {
          if (!this.checked) {
            this.$notify({
              duration: 2000,
              message: this.$t("Verify usage"),
              type: "error",
              background: "#fff",
              offset: "3",
            });

            return;
          }
          checkAccount(this.regForm.account).then(({ data }) => {
            if (data.code === 0 && data.data.OK === true) {
              this.$store.dispatch("register", this.regForm).then(
                () => {
                  this.closeDialog();
                },
                (msg) => {
                  this.$notify({
                    duration: 2000,
                    message: this.$t(msg),
                    type: "error",
                    background: "#fff",
                    offset: "3",
                  });
                }
              );
            } else {
              this.$notify({
                duration: 2000,
                message: this.$t("account already exists"),
                type: "error",
                background: "#fff",
                offset: "3",
              });
            }
          });
        } else {
          this.registerLoading = false;
          return false;
        }
      });

      setTimeout(() => {
        this.registerLoading = false;
      }, 5000);
    },
    changeTabs() {
      this.getLineWidth();
    },
    getLineWidth() {
      if (this.$refs["tabTitle" + this.active]) {
        this.lineWidth = getElementWidth(this.$refs["tabTitle" + this.active]);
      } else {
        this.lineWidth = 80;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.my-scrollbar {
  background: transparent;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;

  .my-scrollbar-wrap:not(.my-scrollbar-wrap-use-systembar) {
    scrollbar-width: none;
  }
  .my-scrollbar-wrap {
    height: 100%;
  }
  .my-scrollbar-wrap-all {
    overflow: scroll;

    .my-scrollbar-content {
      .login-register-from-scroll {
        min-height: 3.4rem;
      }
      .login-register-from-scroll {
        --login-register-form-max-height: 370px;
        max-height: 6.13rem !important;
        max-height: var(--login-register-form-max-height, 613px) !important;
        min-height: 1.8rem;
        // padding-left: .24rem;
        // padding-right: .24rem;
        vertical-align: top;
        width: 100%;
        min-height: 2.4rem;
      }
      .ant-space-vertical {
        -ms-flex-direction: column;
        flex-direction: column;
      }
      .ant-space {
        display: -ms-inline-flexbox;
        display: inline-flex;
      }
    }
  }
}
.closeIcon {
  -ms-flex-align: center;
  -ms-flex-pack: center;
  align-items: center;
  background: 0 0;
  border-radius: 50%;
  bottom: -0.88rem;
  display: -ms-flexbox;
  display: flex;
  height: 0.64rem;
  justify-content: center;
  margin-right: -0.3rem;
  position: absolute;
  right: 50%;
  top: auto;
  width: 0.64rem;
  i {
    display: inline-block;
    position: relative;
    width: 0.5984rem;
    height: 0.5984rem;
    background-image: url("../../assets/814195b6-3e29-450b-8c2b-bdb30e15091f.png");
    background-position: -5.1136rem -4.1616rem;
    background-size: 9.8736rem 9.6356rem;
  }
}
</style>
