<template>
    <div>
        <div class="table">
            <div class="num">
                <div>{{ $t('Yesterday losses') }} <span style="color: #04BE02;">{{ loseNum }}</span></div>
                <div>{{ $t('Today Support Bonus') }} <span style="color: #FFAA09;">{{ extra }}</span></div>
            </div>
            <div>
                <el-table :data="tableData" :stripe="true" style="width: 100%" class="customer-table">
                    <el-table-column prop="loseNum" :label="$t('Amount of loss')">
                    </el-table-column>
                    <el-table-column prop="extra" :label="$t('Extra rewards')">
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <div class="taskRule">
            <p>1.Depósitos diários ou apostas válidas que cumpram as condições do evento podem ser computados para o
            registro diário. Registros diários bem-sucedidos podem receber bônus fixos correspondentes ou bônus
            adicionais, podendo chegar a 7;<br />
            1.Depósitos diários ou apostas válidas que cumpram as condições do evento podem ser computados para o
            registro diário. Registros diários bem-sucedidos podem receber bônus fixos correspondentes ou bônus
            adicionais, podendo chegar a 7;<br />
            1.Depósitos diários ou apostas válidas que cumpram as condições do evento podem ser computados para o
            registro diário. Registros diários bem-sucedidos podem receber bônus fixos correspondentes ou bônus
            adicionais, podendo chegar a 7;<br />
            1.Depósitos diários ou apostas válidas que cumpram as condições do evento podem ser computados para o
            registro diário. Registros diários bem-sucedidos podem receber bônus fixos correspondentes ou bônus
            adicionais, podendo chegar a 7;<br />
            1.Depósitos diários ou apostas válidas que cumpram as condições do evento podem ser computados para o
            registro diário. Registros diários bem-sucedidos podem receber bônus fixos correspondentes ou bônus
            adicionais, podendo chegar a 7;<br />
            1.Depósitos diários ou apostas válidas que cumpram as condições do evento podem ser computados para o
            registro diário. Registros diários bem-sucedidos podem receber bônus fixos correspondentes ou bônus
            adicionais, podendo chegar a 7;<br /></p>
        </div>
    </div>
</template>
<script>
export default {
    name: '',
    components: {

    },
    mixins: [],
    props: {

    },
    data() {
        return {
            tableData: [
                { loseNum: '≥500', extra: '1.00%' },
                { loseNum: '≥3000', extra: '1.50%' },
                { loseNum: '≥9000', extra: '2.00%' },
                { loseNum: '≥30000', extra: '2.50%' },
                { loseNum: '≥90000', extra: '3.00%' },
            ],
            loseNum: 0.00,
            extra: 0.00,

        }
    },
    computed: {

    },
    watch: {

    },
    mounted() {

    },
    methods: {

    }
};
</script>
<style type="text/less" lang="less" scoped>
div {
    background: #6E8DE6;

}
.taskRule {
    background: #6E8DE6;
    color: #D5DDFF;
    overflow: hidden;
    padding: 1vw 3vw;
    p {
        background: #5375D1;
        padding: 2vw;
        border-radius: 1vw;
    }
}

.table {
    background: #6E8DE6;
    margin-top: 1vw;
    padding: 3vw;
    padding-top: 12vw;
    .num {
        display: flex;
        margin-bottom: 1vw;
        justify-content: space-between;
        div {
            color: #D5DDFF;
        }
    }
}

/deep/.el-table__body,
/deep/.el-table__header {
    width: 100% !important;
}

/deep/.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
    background: #6E8DE6;
}
/deep/.el-table__row {
    border-radius: 1vw;

}
/deep/.el-table tr {
    background: #5375D1;
    border-radius: 1vw;
}

/deep/.el-table td.el-table__cell,
/deep/.el-table th.el-table__cell.is-leaf {
    border: none;
}

/deep/.el-table--border::after,
/deep/.el-table--group::after {
    width: 0;
}

/deep/.el-table th.el-table__cell {
    background: #6E8DE6;
}

/deep/.el-table tbody tr:hover>td {
    background-color: transparent !important;
}
/deep/.has-gutter {
    width:100%;
    color:#fff;
}
/deep/.el-table tbody .el-table_1_column_2 {
    color:#FFAA09;
} 
.customer-table {
    color:#D5DDFF;
}
.customer-table::before {
    width: 0;
}

.customer-table .el-table__fixed-right::before,
.el-table__fixed::before {
    width: 0;
}

/deep/.el-table .el-table__cell {
    text-align: center;
}
</style>