<template>
  <div class="box">
    <el-container>
      <el-header height=".9rem">
        <van-nav-bar
          @click-left="onClickLeft"
          right-text=" "
          :fixed="true"
          :hairline="false"
          style="
            max-width: var(--theme-max-width);
            margin: 0 auto;
            left: 0;
            right: 0;
            height: 0.9rem;
          "
        >
          <template #left>
            <div>
              <i style="font-size: 0.24rem">
                <svg-icon
                  icon-class="comm_icon_fh"
                  style="
                    width: 1em;
                    height: 1em;
                    color: var(--theme-text-color);
                    fill: var(--theme-text-color-lighten);
                  "
                ></svg-icon>
              </i>
            </div>
          </template>

          <template #title>
            <div class="title">
              <span class="global-wrapper-title">
                <span
                  style="
                    width: 100%;
                    margin-bottom: -1.64706px;
                    padding-bottom: 1.64706px;
                    font-size: 0.3rem;
                  "
                  ><span
                    style="
                      vertical-align: middle;
                      display: inline-block;
                      width: 100%;
                    "
                  >
                    <span style="-webkit-line-clamp: 2" class="ts inner-text">
                      {{ $t('report') }}</span
                    >
                  </span></span
                >
              </span>
            </div>
          </template>
        </van-nav-bar>
      </el-header>
      <el-main>
        <section style="display: block">
          <van-tabs
            v-model="active"
            background="var(--theme-main-bg-color)"
            :scrollspy="true"
            :animated="true"
            :ellipsis="false"
          >
            <van-tab
              :title="$t('account')"
              title-class=" van-title "
              title-style=" height: 0.7rem;  line-height: 0.7rem; margin-right: 0.6rem;font-size: 0.22rem;margin-left: 0.2rem;"
            >
              <accounts @change="updateSummary"></accounts>
            </van-tab>

            <van-tab
              :title="$t('bets')"
              title-class=" van-title "
              title-style="height: 0.7rem;line-height: 0.7rem; margin-right: 0.6rem; font-size: 0.22rem;margin-left: 0.2rem;"
            >
              <betting></betting>
            </van-tab>

            <van-tab
              :title="$t('report')"
              title-class=" van-title "
              title-style="height: 0.7rem; line-height: 0.7rem; margin-right: 0.6rem;  font-size: 0.22rem; margin-left: 0.2rem;"
            >
              <reportSummary @change="updateSummary"></reportSummary>
            </van-tab>
          </van-tabs>
        </section>
        <div class="wg-fixed-no-desktop statistics" v-if="active !== 1">
          <div class="summary" v-if="active === 0">
            <span class="summary-item">
              <span>{{ $t('total deposit') }}</span>
              <span class="isGreen"
                >R${{ formatNumberToString(total_deposit) }}</span
              >
            </span>
            <span class="summary-item"
              ><span>{{ $t('total Serves') }}</span
              ><span class="isRed"
                >R$ {{ formatNumberToString(total_withdraw) }}</span
              >
            </span>
            <span class="summary-item"
              ><span>{{ $t('total accumulated') }}</span
              ><span class="isOrange"
                >R$
                {{ formatNumberToString(total_deposit - total_withdraw) }}</span
              ></span
            >
          </div>
          <div class="summary" v-else>
            <span class="summary-item">
              <span> {{ $t('cumulative bet value') }}</span>
              <span>{{ total_number }}</span>
            </span>
            <span class="summary-item"
              ><span>{{ $t('total valid bet') }}</span><span>R$ {{ formatNumberToString(total_bet) }}</span>
            </span>
            <span class="summary-item"
              ><span>{{ $t('r/d Total') }}</span><span class="isRed">R$  {{ formatNumberToString(total_win_loss) }}</span></span
            >
          </div>
        </div>
      </el-main>
    </el-container>
  </div>
</template>
<script>
import accounts from "@/components/phone/report/accounts.vue";
import betting from "@/components/phone/report/betting.vue";
import reportSummary from "@/components/phone/report/reportSummary.vue";
import { formatNumberToString2 } from "@/utils/utils";
export default {
  components: { accounts, betting, reportSummary },
  data() {
    return {
      active: 0,
      total_deposit: 0,
      total_withdraw: 0,
      total_bet: 0,
      total_number: 0,
      total_win_loss:0,
    };
  },
  watch: {
    $route(to, from) {
      // if (to.query.tab === 'tab2') {
      //   // 切换到指定标签页的逻辑
      //   this.activeTab = 'tab2'; // 假设activeTab是用来控制当前激活标签页的变量
      // }
      if (to.query.current) {
        if (this.active != Number(to.query.current)) {
          this.active = Number(to.query.current);
        }
      }
    },
  },
  activated() {
    // this.active = Number(this.$route.query.current);
    if (this.$route.query.current) {
      if (this.active != Number(this.$route.query.current)) {
        this.active = Number(this.$route.query.current);
      }
    }
  },
  mounted() {},
  methods: {
    onClickLeft() {
      history.go(-1);
    },
    updateSummary(item) {
      if (this.active === 0) {
        this.total_deposit = item.total_deposit;
        this.total_withdraw = item.total_withdraw;
      } else {
        this.total_bet = item.total_bet;
        this.total_number = item.total_number;
        this.total_win_loss=item.total_win_loss;
      }
    },
    formatNumberToString(v) {
      return formatNumberToString2(v);
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.el-main,
/deep/.el-header {
  padding: 0;
}
[class*="van-hairline"]::after {
  border: 0;
}
.van-nav-bar {
  border-bottom: 0.01rem solid var(--theme-color-line);
  background: var(--theme-main-bg-color);
  /deep/.van-nav-bar__content {
    height: 0.9rem;
    padding: 0px 0.1rem 0px 0.2rem;
  }

  /deep/.van-nav-bar__right {
    width: 0.9rem;
    display: flex;
    justify-content: flex-end;
    position: inherit;
    padding: 0;
    font-size: 0.22rem;
  }
  /deep/.van-nav-bar__left {
    position: inherit;
    padding: 0;
    width: 0.8rem;
    div {
      align-items: center;
      color: var(--theme-text-color);
      cursor: pointer;
      display: flex;
      font-size: 0.2rem;
      font-size: 0.24rem;
      height: 0.8rem;
      width: 0.8rem;
    }
  }
  /deep/.van-nav-bar__title {
    align-items: center;
    display: flex;
    flex: 2 1 0%;
    flex-direction: column;
    margin: 0;
    max-width: none;
    // max-width: max-content;
    .title {
      // padding: 0px 0.34rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--theme-text-color);
      span {
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        color: var(--theme-text-color-darken);
        display: -webkit-box;
        margin: 0px 0.12rem;
        overflow: hidden;
        text-align: center;
        text-overflow: ellipsis;
        vertical-align: middle;
      }
      .global-wrapper-title {
        font-size: 0.3rem;

        margin-top: -0.014rem;

        .ts {
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          display: -webkit-box;
          line-height: 1.2;
          overflow: initial;
          text-align: center;
          text-overflow: ellipsis;
          vertical-align: middle;
          word-break: break-word;
        }
      }
    }
  }

  /deep/.van-nav-bar__arrow {
    color: var(--theme-text-color);
  }

  /deep/.van-nav-bar__text {
    color: var(--theme-ant-primary-color-0);
    word-wrap: break-word;
    line-height: 1.1;
    text-align: center;
    white-space: pre-line;
    word-break: break-word;
    //  font-size: 24.576px;
    font-weight: 400;
    user-select: none;
    font: inherit;
    text-transform: none;
    box-sizing: border-box;
    pointer-events: none;
  }
  /deep/.van-ellipsis {
    white-space: inherit;
  }
}
/deep/.van-tabs__wrap {
  border-bottom: thin solid var(--theme-color-line);
  display: -ms-flexbox;
  display: flex;
  height: auto;
  overflow-x: auto;
  overflow-y: hidden;

  .van-tabs__nav {
    background: transparent;
    -webkit-overflow-scrolling: touch;
    background-color: var(--theme-main-bg-color);
    display: inline-block;
    overflow-x: auto;
    padding: 0;
    white-space: nowrap;
    width: 100%;

    .van-tab {
      color: var(--theme-text-color-darken);
      display: inline-block;
      font-size: 0.24rem;
      padding: 0;
      position: relative;
    }
    .van-tabs__line {
      background-color: var(--theme-primary-color);
      height: 0.03rem;
      bottom: 0;
    }
  }
}
/deep/ .van-tab--active {
  color: var(--theme-primary-color) !important;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.statistics {
  -ms-flex-align: center;
  align-items: center;
  background-color: var(--theme-main-bg-color);
  border-top: thin solid var(--theme-color-line);
  bottom: 0;
  display: -ms-flexbox;
  display: flex;
  left: 0;
  min-height: 0.76rem;
  position: fixed;
  width: 100%;
  z-index: 20;
  .summary-item {
    -ms-flex-align: center;
    -ms-flex-pack: center;
    align-items: center;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    margin: 0;
    width: 50%;
    span {
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      margin: 0;
      overflow: hidden;
      padding-right: 0.05rem;
      text-overflow: ellipsis;
      vertical-align: middle;
      width: 50%;
      word-break: break-all;
    }
    span:last-child {
      padding-left: 0.2rem;
      padding-right: 0;
    }
  }
  .summary-item:nth-of-type(2n) {
    padding-left: 0.2rem;
  }
}
</style>
