<template>
    <div class="app">
        <van-empty
          :image="require('@/assets/azf5f-28vaj.png')"
          description="Sem data"
        />
    </div>
</template>
<script>

import registration from '@/components/phone/financial/registration.vue'
import ruleFinacial from '@/components/phone/financial/ruleFinacial.vue'

export default {
    components: { registration, ruleFinacial },
    data() {
        return {
            lump: 0,
            nowTime: '',
            amount: '',
            active:0
        }
    },
    mounted() {
        setInterval(() => {
            let date = new Date();
            let month = date.getMonth() + 1;
            let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
            let hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
            let minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
            let seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
            this.nowTime = month + '/' + day + ' ' + hours + ":" + minutes + ":" + seconds
        }, 1000)
    },
    methods: {

    }
};
</script>
<style type="text/less" lang="less" scoped>


</style>