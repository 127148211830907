import Vue from "vue";
Vue.directive("scroll", {
    inserted(el, binding) {
        // 为元素添加滚动事件监听
        el.addEventListener('scroll', () => {
          // 获取滚动位置
          const scrollTop = el.scrollTop;
          // 执行绑定的方法，并传递滚动位置作为参数
          binding.value(scrollTop);
        });
      }
});
