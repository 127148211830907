<template>
  <section class="wrap">
    <div class="topMenu">
      <div class="left">
        <dateSelect
          :startDate.sync="startDate"
          :endDate.sync="endDate"
          @change="selectDate"
        ></dateSelect>
      </div>
      <div class="right">
        <search
           :placeholder="$t('member ID')"
          @searchClick="searchClick"
          :loading.sync="loading"
          :findtext.sync="findtext"
        ></search>
      </div>
    </div>
    <div class="context">
      <van-empty
        v-if="list.length < 1"
        :image="require('@/assets/azf5f-28vaj.png')"
        :description="$t('no records')"
      />
      <van-list v-else>
        <div class="sticky" v-for="(item, index) in list">
          <van-row justify="space-between" type="flex">
            <div class="leftTop">
              <div class="globalVipLevel">
                <p class="icobg">V{{ item.vip_lv }}</p>
              </div>
              <div class="globalInnerMain">
                <div class="leftTitle">{{ item.account_number }}</div>
                <span class="copy">
                  <i
                    style="
                      display: inline-flex;
                      justify-content: center;
                      align-items: center;
                    "
                  >
                    <svg-icon
                      icon-class="comm_icon_copy"
                      style="width: 1em; height: 1em; fill: white"
                    ></svg-icon
                  ></i>
                </span>
              </div>
            </div>
            <van-col span="12">
              <label>{{ $t('valid bets') }}</label>
              <span class="directMember">{{ item.flow }}</span>
            </van-col>
          </van-row>
          <van-row justify="space-between" type="flex">
            <van-col span="12">
              <span class="dateTime">{{ item.register_time }}</span>
            </van-col>
            <van-col span="12">
              <label>{{ $t('deposit') }}</label>
              <span class="currencyAmount">{{ item.deposit }}</span>
            </van-col>
          </van-row>
        </div>
      </van-list>
      <myDrawer v-if="list.length > 0">
        <van-row style="font-size: 0.2rem" type="flex">
          <van-col span="12">
            <label>{{ $t('direct recharge') }}</label
            ><span class="currencyAmount"> {{ deposit_subordinate }}</span>
          </van-col>

          <van-col span="12">
            <label>{{ $t('first direct recharge') }}</label
            ><span class="customNormal"> {{ count_deposit_subordinate }}</span>
          </van-col>

          <van-col span="12">
            <label>{{ $t('other deposit') }}</label
            ><span class="currencyAmount">{{ deposit_other }}</span>
          </van-col>

          <van-col span="12">
            <label>{{ $t('first other recharge') }}</label
            ><span class="customNormal"> {{ count_deposit_other }}</span>
          </van-col>

          <van-col span="12">
            <label>{{ $t('total deposit') }}</label
            ><span class="currencyAmount"> {{ total_deposit }}</span>
          </van-col>

          <van-col span="12">
            <label>{{ $t('total players making first deposits') }}</label
            ><span class="customNormal"> {{ total_count_deposit }}</span>
          </van-col>
        </van-row>
      </myDrawer>
    </div>
  </section>
</template>
<script>
import dateSelect from "@/components/phone/common/dateSelect.vue";
import search from "@/components/phone/common/search.vue";
import myDrawer from "@/components/phone/common/myDrawer.vue";
import { getPeriodAllDataList } from "@/api/index";
import { formatDate, formatNumberToString2 } from "@/utils/utils";

export default {
  name: "teamDataDetail",
  components: { dateSelect, search, myDrawer },
  data() {
    return {
      findtext: "",
      active: 0,
      startDate: new Date(),
      endDate: new Date(),
      list: [],
      count_deposit_other: 0,
      count_deposit_subordinate: 0,
      deposit_other: 0,
      deposit_subordinate: 0,
      total_count_deposit: 0,
      total_deposit: 0,
      loading: false,
    };
  },
  created() {},
  mounted() {

  },
  destroyed() {},
  methods: {
  
    onSearch() {},
    selectDate(start, end) {
      this.getList(start, end, this.findtext);
    },
    searchClick(text) {
      this.getList(this.startDate, this.endDate, text);
    },
    getList(start, end, text) {
      const data = {
        start_day: formatDate(start),
        end_day: formatDate(end),
        account_number: text,
      };
      this.loading = true;
      this.list = [];
      getPeriodAllDataList(data).then(({ data }) => {
        this.count_deposit_other = data.data.count_deposit_other;
        this.count_deposit_subordinate = data.data.count_deposit_subordinate;
        this.deposit_other = formatNumberToString2(data.data.deposit_other);
        this.deposit_subordinate = formatNumberToString2(data.data.deposit_subordinate);
        this.total_count_deposit = data.data.total_count_deposit;
        this.total_deposit = formatNumberToString2(data.data.total_deposit);
        this.list = data.data.list;
        this.loading = false;
      });
    },
  },
};
</script>
<style type="text/less" lang="less" scoped>
.wrap {
  border-radius: 0.1rem;
  background: 0 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 0;
  .topMenu {
    -ms-flex-align: center;
    -ms-flex-pack: start;
    align-items: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: flex-start;
    padding-bottom: 0.2rem;
    padding: 0.2rem;
    .left {
      position: relative;
      z-index: 999;
      margin-right: 0.2rem;
    }
    .right {
      width: 2rem;
    }
  }
  .context {
    -ms-flex-pack: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;

    height: calc(var(--vh, 1vh) * 100 - 2.55rem);
    justify-content: center;
    overflow: hidden;
    position: relative;
    /deep/.van-empty {
      -ms-flex-positive: 1;
      flex-grow: 1;
      padding: 0;

      .van-empty__image {
        height: 2.1rem;
        text-align: center;
        width: 2.1rem;
      }
      .van-empty__description {
        color: var(--theme-text-color-lighten);
        font-size: 0.26rem;
        margin-top: 0.2rem;
      }
    }

    /deep/.van-list {

      .sticky {
        color: var(--theme-text-color-darken);
        font-size: 0.2rem !important;
        position: relative;
        border-radius: 0.1rem;
        padding: 0.2rem;
        .leftTop {
          .globalVipLevel {
            -ms-flex-align: center;
            -ms-flex-pack: center;
            align-items: center;
            background-color: #c12929;
            border-radius: 0.04rem 0 0.04rem 0;
            display: -ms-flexbox;
            display: flex;
            height: 0.23rem;
            justify-content: center;
            left: 0;
            line-height: 1;
            position: absolute;
            top: 0;
            width: -webkit-fit-content;
            width: -moz-fit-content;
            width: fit-content;
            width: 0.3rem;
            .icobg {
              -webkit-text-fill-color: transparent;
              -webkit-background-clip: text;
              background-clip: text;
              background-image: -webkit-gradient(
                linear,
                left top,
                left bottom,
                color-stop(-5%, #f7ea94),
                color-stop(58%, #e5b952),
                color-stop(114%, #ce9510)
              );
              background-image: linear-gradient(
                180deg,
                #f7ea94 -5%,
                #e5b952 58%,
                #ce9510 114%
              );
              font-size: 0.16rem;
              font-weight: 700;
              line-height: 1;
            }
          }
          .globalInnerMain {
            -ms-flex-align: center;
            -ms-flex-pack: start;
            align-items: center;
            display: -ms-flexbox;
            display: flex;
            justify-content: flex-start;
            -ms-flex-pack: initial;
            justify-content: normal;
            .leftTitle {
              color: var(--theme-text-color-darken);
              margin-right: 0.1rem;
              overflow: hidden;
              text-align: left;
              text-overflow: ellipsis;
            }
            .copy {
              -ms-flex-negative: 0;
              flex-shrink: 0;
              font-size: 0.24rem;
              margin-left: 0.1rem;
              position: relative;
              top: 0.02rem;
              color: var(--theme-primary-color);
              cursor: pointer;
            }
          }
        }
      }
      .sticky:nth-child(odd) {
        background-color: var(--theme-main-bg-color);
      }
      .van-col {
        color: var(--theme-text-color-darken);

        margin-bottom: 0.05rem;
        font-size: 0.2rem !important;
        label {
          color: var(--theme-text-color-lighten);
          margin-right: 0.1rem;
          touch-action: manipulation;
        }
      }
    }

    .dateTime {
      font-size: 0.2rem !important;
      color: var(--theme-text-color-lighten);
    }
    .drawer {
      /deep/.van-col {
        -ms-flex-align: center;
        align-items: center;
        color: var(--theme-text-color-darken);
        display: -ms-flexbox;
        display: flex;
        margin-bottom: 0.15rem;
        label {
          color: var(--theme-text-color-lighten);
          margin-right: 0.1rem;
          width: 50%;
        }
      }
    }
  }
}
</style>
