<template>
  <div class="my-scrollbar" style="width: 100%; height: 100%">
    <div class="my-scrollbar-wrap my-scrollbar-wrap-y">
      <div class="my-scrollbar-content">
        <div class="leftBox">
          <div class="game-menu">
            <div class="game-menu__items">
              <div
                @click="handleSelect(item.id)"
                v-for="item in itemList"
                :key="item.id"
                class="itemBox"
                :class="activeIndex + 1 === item.id ? 'acitem' : ''"
              >
                <div class="icobg">
                  <van-image
                    v-if="activeIndex + 1 === item.id"
                    :src="item.actIcon"
                    :show-loading="false"
                    :show-error="false"
                    fit="fill"
                  >
                  </van-image>
                  <i v-else>
                    <svg-icon
                      :icon-class="item.icon"
                      style="
                        display: inline-flex;
                        justify-content: center;
                        align-items: center;
                      "
                    ></svg-icon>
                  </i>
                </div>
                <div
                  class="text"
                  :style="activeIndex + 1 === item.id ? 'color: #4969C2' : ''"
                >
                  {{ item.name }}
                </div>
              </div>
            </div>
          </div>

          <div class="sys-menu">
            <div class="music">
              <div class="headerMusicPlayer">
                <div class="musicControlContent">
                  <div class="controlBar">
                    <div class="playerControl">
                      <i class="anticon" @click="previous">
                        <svg-icon
                          icon-class="musicleft"
                          style="
                            display: inline-flex;
                            justify-content: center;
                            align-items: center;
                          "
                          width="1em"
                          height="1em"
                          fill="white"
                        ></svg-icon>
                      </i>
                      <i
                        class="anticon"
                        @click="
                          () => {
                            if (isPlaying) {
                              pause();
                            } else {
                              restore();
                            }
                          }
                        "
                      >
                        <svg-icon
                          :icon-class="
                            isPlaying ? 'comm_nav_zt' : 'comm_nav_bf'
                          "
                          style="
                            display: inline-flex;
                            justify-content: center;
                            align-items: center;
                          "
                          width="1em"
                          height="1em"
                          fill="white"
                        ></svg-icon>
                      </i>
                      <i class="anticon" @click="next">
                        <svg-icon
                          icon-class="musicright"
                          style="
                            display: inline-flex;
                            justify-content: center;
                            align-items: center;
                          "
                          width="1em"
                          height="1em"
                          fill="white"
                        ></svg-icon>
                      </i>
                    </div>
                    <div class="songName">
                      {{ currentTrack ? currentTrack.music_name : "" }}
                    </div>
                  </div>
                  <div>
                    <div class="musicPlayType">
                      <div>
                        <i
                          class="anticon"
                          @click="
                            () => {
                              setPlayType(playType === 2 ? 0 : playType + 1);
                            }
                          "
                        >
                          <svg-icon
                            :icon-class="
                              playType === 0
                                ? 'comm_icon_xhbf'
                                : playType === 1
                                ? 'comm_icon_dqxh'
                                : 'comm_icon_sj'
                            "
                            style="
                              display: inline-flex;
                              justify-content: center;
                              align-items: center;
                            "
                            width="1em"
                            height="1em"
                            fill="white"
                          ></svg-icon>
                        </i>
                      </div>
                      <div class="right">
                        <i
                          class="anticon"
                          @click="
                            () => {
                              setOpenMusicBox(true);
                            }
                          "
                        >
                          <svg-icon
                            icon-class="musicxhqk"
                            style="
                              display: inline-flex;
                              justify-content: center;
                              align-items: center;
                            "
                            width="1em"
                            height="1em"
                            fill="white"
                          ></svg-icon>
                        </i>
                        <div class="musicCount">
                          {{ playlist.length > 99 ? "99+" : playlist.length }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div @click="handJump('/report', 2)" class="record">
              <svg-icon
                icon-class="sy_zc_tzjl"
                style="
                  display: inline-flex;
                  justify-content: center;
                  align-items: center;
                "
              ></svg-icon>
              <div style="width: 1.9rem; text-align: center">
                {{ $t("bets") }}
              </div>
            </div>
            <div class="btnBoxLink">
              <div class="event-title"><span>{{ $t('promotion') }}</span></div>

              <van-badge
                :content="
                  redPointInfo.activity && redPointInfo.activity.total_count > 0
                    ? redPointInfo.activity.total_count
                    : ''
                "
              >
                <div @click="gotoPromotion(0)" class="por">
                  <van-image
                    :src="require('@/assets/btn_sy_zc_hd.png')"
                    :lazy-load="true"
                    fit="fill"
                    heighe="0.8rem"
                  >
                    <template v-slot:loading>
                      <div class="imgload"></div>
                    </template>
                  </van-image>
                  <span class="text">{{ $t("events") }}</span>

                  <div
                    class="tool-tips"
                    hidden="hidden"
                    style="--cu-top: -0.2rem; --cu-right: 0rem; --cu-left: 1rem"
                  >
                    <p class="tool-tips-box">
                      <span>0</span>
                    </p>
                    <p class="tool-tips-tail"></p>
                  </div>
                </div>
              </van-badge>

              <div class="por" @click="gotoPromotion(1)">
                <van-image
                  :src="require('@/assets/btn_sy_zc_fs.png')"
                  :lazy-load="true"
                  fit="fill"
                  heighe="0.8rem"
                >
                  <template v-slot:loading>
                    <div class="imgload"></div>
                  </template>
                </van-image>
                <span class="text">{{ $t('rebate') }}</span>
                <div
                  class="tool-tips"
                  hidden="hidden"
                  style="--cu-top: -0.2rem; --cu-right: 0rem; --cu-left: 1rem"
                >
                  <p class="tool-tips-box">
                    <span>0</span>
                  </p>
                  <p class="tool-tips-tail"></p>
                </div>
              </div>
              <div @click="gotoPromotion(2)" class="por">
                <van-image
                  :src="require('@/assets/btn_sy_zc_jl.png')"
                  :lazy-load="true"
                  fit="fill"
                  heighe="0.8rem"
                >
                  <template v-slot:loading>
                    <div class="imgload"></div>
                  </template>
                </van-image>
                <span class="text">{{ $t('pending') }}</span>
                <div
                  class="tool-tips"
                  hidden="hidden"
                  style="--cu-top: -0.2rem; --cu-right: 0rem; --cu-left: 1rem"
                >
                  <p class="tool-tips-box">
                    <span>0</span>
                  </p>
                  <p class="tool-tips-tail"></p>
                </div>
              </div>
              <div @click="gotoPromotion(3)" class="por">
                <van-image
                  :src="require('@/assets/btn_sy_zc_lqjl.png')"
                  :lazy-load="true"
                  fit="fill"
                  heighe="0.8rem"
                >
                  <template v-slot:loading>
                    <div class="imgload"></div>
                  </template>
                </van-image>
                <span class="text">{{ $t('historic') }}</span>
                <div
                  class="tool-tips"
                  hidden="hidden"
                  style="--cu-top: -0.2rem; --cu-right: 0rem; --cu-left: 1rem"
                >
                  <p class="tool-tips-box">
                    <span>0</span>
                  </p>
                  <p class="tool-tips-tail"></p>
                </div>
              </div>
              <div class="por" @click="gotoPromotion(4)">
                <van-image
                  :src="require('@/assets/btn_sy_zc_yeb.png')"
                  :lazy-load="true"
                  fit="fill"
                  heighe="0.8rem"
                >
                  <template v-slot:loading>
                    <div class="imgload"></div>
                  </template>
                </van-image>

                <span class="text">{{ $t('fees') }}</span>
                <div
                  class="tool-tips"
                  hidden="hidden"
                  style="--cu-top: -0.2rem; --cu-right: 0rem; --cu-left: 1rem"
                >
                  <p class="tool-tips-box">
                    <span>0</span>
                  </p>
                  <p class="tool-tips-tail"></p>
                </div>
              </div>
              <div @click="gotoPromotion(5)" class="por">
                <van-image
                  :src="require('@/assets/btn_sy_zc_vip.png')"
                  :lazy-load="true"
                  fit="fill"
                  heighe="0.8rem"
                >
                  <template v-slot:loading>
                    <div class="imgload"></div>
                  </template>
                </van-image>

                <span class="text">{{ $t('vip') }}</span>
                <div
                  class="tool-tips"
                  hidden="hidden"
                  style="--cu-top: -0.2rem; --cu-right: 0rem; --cu-left: 1rem"
                >
                  <p class="tool-tips-box">
                    <span>0</span>
                  </p>
                  <p class="tool-tips-tail"></p>
                </div>
              </div>
            </div>
            <div class="other-group">
              <div class="btnLink">
                <div class="icobg">
                  <svg-icon
                    icon-class="sy_zc_xz"
                    style="
                      display: inline-flex;
                      justify-content: center;
                      align-items: center;
                    "
                  ></svg-icon>
                </div>
                <div class="text">{{ $t("download app") }}</div>
              </div>
              <div @click="handJump('/notice')" class="btnLink">
                <div class="icobg">
                  <svg-icon
                    icon-class="sy_zc_kf"
                    style="
                      display: inline-flex;
                      justify-content: center;
                      align-items: center;
                    "
                  ></svg-icon>
                </div>

                <div>{{ $t('support') }}</div>
              </div>
              <div @click="handJump('/notice')" class="btnLink">
                <div class="icobg">
                  <svg-icon
                    icon-class="sy_zc_cjwt"
                    style="
                      display: inline-flex;
                      justify-content: center;
                      align-items: center;
                    "
                  ></svg-icon>
                </div>

                <div>{{ $t("faq") }}</div>
              </div>
              <div @click="handJump('/about')" class="btnLink">
                <div class="icobg">
                  <svg-icon
                    icon-class="sy_zc_gywm"
                    style="
                      display: inline-flex;
                      justify-content: center;
                      align-items: center;
                    "
                  ></svg-icon>
                </div>

                <div>{{ $t("about") }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { gameCategoryList } from "@/api/index";
import { mapState, mapActions } from "vuex";
export default {
  name: "sideBar",
  components: {},
  mixins: [],
  computed: {
    ...mapState("musicPlayer", [
      "playlist",
      "currentTrack",
      "isPlaying",
      "progress",
      "volume",
      "playType",
      "history",
      "duration",
      "muted",
    ]),
    ...mapState("redPoint", ["redPointInfo"]),
  },
  props: ["activeIndex", "itemList"],
  data() {
    return {
      //   itemList: [
      //     {
      //       id: "1",
      //       isShow: false,
      //       actIcon: require("../../assets/icon_dtfl_rm_1.png"),
      //       name: "",
      //       icon: "dtfl_rm_0",
      //     },
      //     {
      //       id: "2",
      //       isShow: false,
      //       actIcon: require("../../assets/icon_dtfl_dz_1.png"),
      //       name: "",
      //       icon: "dtfl_dz_0",
      //     },
      //     {
      //       id: "3",
      //       isShow: false,
      //       actIcon: require("../../assets/icon_dtfl_qkl_1.png"),
      //       name: "",
      //       icon: "dtfl_qkl_0",
      //     },
      //     {
      //       id: "4",
      //       isShow: false,
      //       actIcon: require("../../assets/icon_dtfl_qp_1.png"),
      //       name: "",
      //       icon: "dtfl_qp_0",
      //     },
      //     {
      //       id: "5",
      //       isShow: false,
      //       actIcon: require("../../assets/icon_dtfl_by_1.png"),
      //       name: "",
      //       icon: "dtfl_by_0",
      //     },
      //     {
      //       id: "6",
      //       gameLsit: [],
      //       actIcon: require("../../assets/img_game_5.png"),
      //       name: this.$t("free"),
      //       icon: "dtfl_sw_0",
      //     },
      //     {
      //       id: "7",
      //       gameLsit: [],
      //       actIcon: require("../../assets/img_recente.png"),
      //       name: this.$t("Recently viewed"),
      //       icon: "dtfl_zj_0",
      //     },
      //     {
      //       id: "8",
      //       gameLsit: [],
      //       actIcon: require("../../assets/img_game_4.png"),
      //       name: this.$t("collection"),
      //       icon: "dtfl_sc_0",
      //     },
      //   ],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    ...mapActions("musicPlayer", [
      "play",
      "pause",
      "next",
      "previous",
      "setVolume",
      "updateProgress",
      "updateDuration",
      "setProgress",
      "setMuted",
      "setPlayType",
      "restore",
      "setOpenMusicBox",
    ]),
    getList() {
      gameCategoryList().then(({ data }) => {
        let vendorList = data.data;
        for (let i = 0; i < vendorList.length; i++) {
          this.itemList[i].name = vendorList[i].name;
        }
      });
    },
    handleSelect(id) {
      this.$emit("handleSelect", id);
    },
    handJump(path, i = 0) {
      this.$emit("handJump", path, i);
    },
    gotoPromotion(index) {
      this.$router.push({
        path: "/Promotion",
        query: { index: index },
      });
    },
  },
};
</script>
<style type="text/less" lang="less" scoped>
.my-scrollbar {
  // background: 0 0;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
  background: var(--theme-main-bg-color);
}
.my-scrollbar-wrap:not(.my-scrollbar-wrap-use-systembar) {
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  scrollbar-width: none;
}
.my-scrollbar-wrap {
  height: 100%;
}
.my-scrollbar-wrap-y {
  overflow-x: hidden;
  overflow-y: scroll;
}
.my-scrollbar-content {
  height: 100vh;
}
.anticon {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: inherit;
  display: inline-block;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  font-size: 0.21rem;
}
.leftBox {
  width: 3rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  background: var(--theme-main-bg-color);
  position: relative;
  z-index: 9;
  //   top: 20vw;
  //   height: 200vh;
  -webkit-box-shadow: 0 0 0.09rem 0 rgba(0, 0, 0, 0.06);
  box-shadow: 0 0 0.09rem 0 rgba(0, 0, 0, 0.06);
  color: var(--theme-primary-font-color);
  min-height: calc(var(--vh, 1vh) * 100 - var(--theme-header-height));
  padding-bottom: 1rem;

  .game-menu {
    padding: 0.15rem 0.15rem 0;
    .game-menu__items {
      -ms-flex-pack: justify;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      justify-content: space-between;
      .itemBox {
        border-radius: 0.14rem;
        cursor: pointer;
        font-size: 0.2rem;
        height: 0.9rem;
        max-width: 1.3rem;
        min-width: 1.3rem;
        width: 1.3rem;

        -ms-flex-pack: distribute;
        background: var(--theme-side-menu-btn-color);

        color: var(--theme-text-color);
        cursor: pointer;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;

        justify-content: space-around;
        margin-bottom: 0.1rem;

        /deep/ .svg-icon {
          fill: var(--theme-text-color);
          width: 1.6em;
          height: 1.6em;

          font-size: 0.32rem;
        }
        .icobg {
          margin: 0.05rem auto 0;
          position: relative;

          height: 0.38rem;
          width: 0.5rem;
        }
        .text {
          font-size: 0.2rem;
          overflow: hidden;
          padding: 0 0.04rem;
          text-align: center;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 100%;
          text-transform: capitalize;
        }
      }
      .acitem {
        background-color: var(--theme-primary-color);
        background-position: 50%;
        background-size: 100% 100%;
        color: var(--theme-primary-font-color);
        position: relative;
        -webkit-transition: all 0.3;
        transition: all 0.3;
      }
    }
  }

  .sys-menu {
    padding: 0 0.15rem;
    .btnBoxLink {
      -ms-flex-pack: justify;
      background-color: var(--theme-side-menu-btn-color);
      border-radius: 0.1rem;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 0.1rem 0 0.05rem;
      padding: 0.1rem 0.1rem 0;
      width: 100%;
      .event-title {
        color: var(--theme-text-color-lighten);
        font-size: 0.24rem;
        padding-bottom: 0.1rem;
        text-align: center;
        width: 2.5rem;
      }
      .por {
        margin-bottom: 0.1rem;
        position: relative;
        cursor: pointer;
        width: 1.2rem;
      }

      .text {
        font-size: 0.15rem;
        left: 0.06rem;
        max-width: 0.8rem;
        top: 0.08rem;
        word-wrap: break-word;
        color: var(--theme-text-color-darken);
        display: inline-block;
        line-height: 1.2;
        position: absolute;
        white-space: pre-wrap;
        
      }

      .tool-tips {
        height: 0.32rem;
        left: var(--cu-left);
        position: absolute;
        right: var(--cu-right);
        top: var(--cu-top);
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        z-index: 100;

        .tool-tips-box {
          font-size: 0.18rem;
          height: 0.26rem;
          line-height: 0.26rem;
          padding: 0 0.07rem;
          background-color: var(--theme-secondary-color-error);
          border-radius: 0.125rem 0.125rem 0.125rem 0;
          color: #fff;
          width: 100%;
        }
        .tool-tips-tail {
          border-bottom: 0.07rem solid transparent;
          border-left: 0.07rem solid var(--theme-secondary-color-error);
          border-right: 0.07rem solid transparent;
          height: 0;
          width: 0;
        }
      }
    }
    .other-group {
      color: var(--theme-primary-font-color);
      .btnLink:hover {
        color: #fff !important;
      }
      .btnLink {
        cursor: pointer;
        font-size: 0.22rem;
        min-height: 0.6rem;
        padding-left: 0.18rem;
        color: var(--theme-text-color-lighten);
        height: 0.5rem;
        padding: 0.04rem 0 0.04rem 0.14rem;
        align-items: center;
        display: flex;
        img {
          width: 4.5vw;
          margin: 0 3vw;
        }
        .icobg {
          margin-right: 0.193rem;
          height: 0.334rem;
          width: 0.334rem;
          -ms-flex-align: center;
          -ms-flex-pack: center;
          align-items: center;
          background: var(--theme-text-color-lighten);
          border-radius: 50%;
          display: -ms-flexbox;
          justify-content: center;
          margin-right: 0.15rem;
          position: relative;
          i {
            font-size: 0.32rem;
          }
        }
        .text {
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
          vertical-align: middle;
          word-break: break-all;
          word-break: break-word;
          
        }
        /deep/ .svg-icon {
          color: var(--theme-side-menu-bg-color);
          width: 1em;
          height: 1em;

          font-size: 0.32rem;
        }
      }
    }
    .record {
      background: var(--theme-side-menu-btn-color);
      width: 100%;
      margin: auto;
      border-radius: 0.1rem;

      display: flex;
      align-items: center;
      justify-content: center;
      height: 0.75rem;
      color: var(--theme-text-color);
      font-size: 0.24rem;
      /deep/ .svg-icon {
        width: 1.5em;
        height: 1.5em;
      }
    }
    .music {
      background-color: var(--theme-side-menu-btn-color);
      border-radius: 0.1rem;
      -ms-flex-pack: center;
      font-size: 0.14rem;

      justify-content: center;

      width: 100%;
      margin: auto;
      margin-bottom: 0.1rem;
      display: flex;
      align-items: center;
      .headerMusicPlayer {
        height: 0.75rem;
        -ms-flex-align: start;
        align-items: flex-start;
        background-color: var(--theme-side-menu-btn-color);
        border-radius: 0.1rem;
        box-sizing: border-box;
        padding: 0.04rem 0.1rem;
        position: relative;
        display: flex;
        width: 2.66rem;
        margin: 0;
        .musicControlContent {
          padding-top: 0.08rem;
          -ms-flex-align: center;
          -ms-flex-pack: justify;
          align-items: center;
          display: -ms-flexbox;
          display: flex;
          justify-content: space-between;
          width: 100%;
        }
        .controlBar {
          -ms-flex-align: center;
          -ms-flex-pack: center;
          align-items: center;
          display: -ms-flexbox;
          display: flex;
          -ms-flex: 1;
          flex: 1;
          -ms-flex-direction: column;
          flex-direction: column;
          justify-content: center;

          .playerControl {
            font-size: 0.14rem;
            color: #fffcd1;
            height: 0.26rem;
            width: 1.5rem;
            -ms-flex-align: center;
            -ms-flex-pack: distribute;
            align-items: center;
            display: -ms-flexbox;
            display: flex;
            justify-content: space-around;
          }

          .songName {
            top: 0.36rem;

            font-size: 0.18rem;
            left: 0;
            overflow: hidden;
            padding: 0 0.1rem;
            position: absolute;
            text-align: center;
            text-overflow: ellipsis;

            white-space: nowrap;
            width: 100%;
            margin-top: 0.06rem;
            color: var(--theme-text-color);
          }
        }

        .musicPlayType {
          color: var(--theme-primary-color);
          height: 0.26rem;

          -ms-flex-align: center;
          align-items: center;
          display: -ms-flexbox;
          display: flex;

          .right {
            cursor: pointer;
            margin-left: 0.3rem;
            position: relative;
            .musicCount {
              background-color: var(--theme-main-bg-color);
              border: 0.01rem solid var(--theme-color-line);
              border-radius: 0.2rem;
              left: -0.22rem;
              line-height: 0.19rem;
              min-width: 0.3rem;
              padding: 0 0.06rem;
              position: absolute;
              text-align: center;
              top: -0.14rem;
              color: var(--theme-alt-neutral-1);
            }
          }
        }
      }
    }
  }
}
</style>
