import { render, staticRenderFns } from "./commission.vue?vue&type=template&id=18d37ca9&scoped=true"
import script from "./commission.vue?vue&type=script&lang=js"
export * from "./commission.vue?vue&type=script&lang=js"
import style0 from "./commission.vue?vue&type=style&index=0&id=18d37ca9&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18d37ca9",
  null
  
)

export default component.exports