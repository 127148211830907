<template>
    <div>
        <div class="top">
            <div class="appInfo">
                <div class="appInfoTop">
                    <div class="cover">1</div>
                    <div class="name">
                        <div>
                            <div class="appName">9991BET <div>18+</div>
                            </div>
                            <div class="rank">娱乐 第一名</div>
                            <div v-if="deferredPrompt" class="btn" @click="promptInstall">获取</div>
                            <div v-else class="btn" @click="dialogTableVisible = true">获取</div>
                        </div>
                        <div></div>
                    </div>
                </div>
                <div class="info">
                    <div class="item">
                        <div>5 ★★★★★</div>
                        <div>k个评分</div>
                    </div>
                    <div class="item">
                        <div>#0</div>
                        <div>其他</div>
                    </div>
                    <div class="item">
                        <div>18+</div>
                        <div>年龄</div>
                    </div>
                </div>
            </div>
            <div class="box">
                <div class="title" style="margin-bottom: 15vw;">简介</div>
            </div>
            <div class="box">
                <div class="title">
                    评分及评论
                </div>
                <div class="star">
                    <div class="num">5</div>
                    <div class="starList">
                        <div class="starListItem">
                            <div class="xx">★★★★★</div>
                            <div class="jd">
                                <div></div>
                            </div>
                        </div>
                        <div class="starListItem">
                            <div class="xx">★★★★</div>
                            <div class="jd">
                                <div style="width: 25%;"></div>
                            </div>
                        </div>
                        <div class="starListItem">
                            <div class="xx">★★★</div>
                            <div class="jd">
                                <div style="width: 10%;"></div>
                            </div>
                        </div>
                        <div class="starListItem">
                            <div class="xx">★★</div>
                            <div class="jd">
                                <div style="width: 5%;"></div>
                            </div>
                        </div>
                        <div class="starListItem">
                            <div class="xx">★</div>
                            <div class="jd">
                                <div style="width: 2%;"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pf">
                    <div>满分5分</div>
                    <div>K个评分</div>
                </div>
            </div>
            <div class="box">
                <div class="title">新功能</div>
                <div>版本 0.1.0</div>
            </div>
            <div class="box">
                <div class="title">信息</div>
                <div class="item">
                    <div>经销商</div>
                    <div></div>
                </div>
                <div class="item">
                    <div>大小</div>
                    <div>1MB</div>
                </div>
                <div class="item">
                    <div>种类</div>
                    <div>其他</div>
                </div>
                <div class="item">
                    <div>兼容性</div>
                    <div>需要IOS11.0或者更高版本，与iphone兼容</div>
                </div>
                <div class="item">
                    <div>语言</div>
                    <div>简体中文</div>
                </div>
                <div class="item">
                    <div>年龄分级</div>
                    <div>限18岁以上</div>
                </div>
                <div class="item">
                    <div>Copyright</div>
                    <div></div>
                </div>
                <div class="item">
                    <div>价格</div>
                    <div>免费</div>
                </div>
                <div class="item">
                    <div>隐私政策</div>
                    <div></div>
                </div>
            </div>
        </div>
        <div class="bottom">
            <p>免责声明：</p>
            <p>本网站仅为开发者提供App的下载和安装托管，App内的内容和运营相关事项由App开发者负责，与本网站无关</p>
        </div>

        <div v-if="mask" class="mask">
            <div class="top">
                <img src="../assets/arrow_top.png" />
                <div>第一步</div>
                <div>点击“复制”链接</div>
            </div>
            <div class="bottom">
                <div>第二步</div>
                <img class="arrow" src="../assets/arrow_bottom.png" />
                <img class="browser" src="../assets/showBottomOtherBrowser.png" />
            </div>
        </div>

        <el-dialog width="90%" :visible.sync="dialogTableVisible">
            <img style="width: 100%;" src="../assets/Tutorial.png" />
        </el-dialog>
    </div>
</template>
<script>
import { VuePwaInstallMixin } from "vue-pwa-install";
export default {
    name: '',
    components: {

    },
    mixins: [VuePwaInstallMixin],
    props: {

    },
    data() {
        return {
            deferredPrompt: VuePwaInstallMixin,
            mask: true,
            dialogTableVisible: false,
        }
    },
    created() {
        window.addEventListener("beforeinstallprompt", (BeforeInstallPromptEvent) => {
            event.preventDefault();
            this.deferredPrompt = event;

        });
        var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
   
        if (isSafari) {
            sessionStorage.setItem('show', '1')
        }
        this.mask = sessionStorage.getItem('show') ? false : true
    },
    computed: {

    },
    watch: {

    },
    mounted() {

    },
    methods: {
        promptInstall() {
            const u = navigator.userAgent,
                app = navigator.appVersion;
            const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
            if (isiOS) {
                this.dialogTableVisible = true
                return
            }
            if (this.deferredPrompt) {
                // Show the prompt:
                this.deferredPrompt.prompt();
                // Wait for the user to respond to the prompt:
                this.deferredPrompt.userChoice.then((choiceResult) => {
                    if (choiceResult.outcome === "accepted") {
                        console.log("User accepted the install prompt");
                    } else {
                        console.log("User dismissed the install prompt");
                    }

                    this.deferredPrompt = null;
                });
            }

        }
    }
};
</script>
<style lang='less' scoped>
.top {
    background: #fff;
    padding: 6vw;

    .appInfo {
        .appInfoTop {
            display: flex;

            .cover {
                width: 25vw;
                height: 25vw;
                background: #000;
            }

            .name {
                margin-left: 5vw;

                .appName {
                    display: flex;
                    align-items: center;
                    font-size: 8vw;

                    div {
                        padding: 0.5vw 1vw;
                        font-size: 3vw;
                        border: 1px solid #ccc;
                        margin-left: 3vw;
                    }
                }

                .rank {
                    color: #8e8e93;
                    margin-top: 1vw;
                }

                .btn {
                    height: 7vw;
                    line-height: 7vw;
                    background-color: #017afe;
                    text-align: center;
                    color: #fff;
                    margin-top: 3vw;
                    border-radius: 5vw;
                }
            }

        }

        .info {
            display: flex;
            justify-content: space-between;
            margin-top: 5vw;
            margin-bottom: 3vw;
        }
    }

    .box {
        border-top: 1px solid #a9a9a9;
        padding: 6vw 0 3vw;

        .title {
            font-size: 6vw;
            margin-bottom: 4vw;
        }

        .item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 2vw 0;
            border-bottom: 1px solid #a9a9a9;
            font-size: 3vw;

            div:nth-child(1) {
                color: #8e8e93;
            }
        }

        .star {
            display: flex;
            justify-content: space-around;

            .num {
                font-weight: bold;
                font-size: 20vw;
                width: 20vw;
                text-align: center;
            }

            .starList {
                .starListItem {
                    display: flex;
                    align-items: center;

                    .xx {
                        width: 20vw;
                        text-align: right;
                        padding-right: 3vw;
                    }

                    .jd {
                        width: 30vw;
                        height: 1vw;
                        background: #eee;
                        position: relative;

                        div {
                            position: absolute;
                            top: 0;
                            left: 0;
                            background: #999;
                            width: 95%;
                            height: 100%;
                        }
                    }
                }
            }
        }

        .pf {
            display: flex;
            justify-content: space-around;
            text-align: center;

            div:nth-child(1) {
                width: 20vw;
            }

            div:nth-child(2) {
                width: 50vw;
                text-align: right;
            }
        }
    }
}

.bottom {
    background-color: #eee;
    padding: .186667rem;
    color: #a9a9a9;
    font-size: 3vw;
}

.mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);

    .top {
        text-align: center;
        background: transparent;

        img {
            width: 15vw;
            margin-left: 20vw;
        }

        div:nth-child(2) {
            color: #ffc0a1;
            font-size: 4vw;
        }

        div:nth-child(3) {
            color: #fff;
            font-size: 4vw;
        }
    }

    .bottom {
        text-align: center;
        background: transparent;
        color: #ffc0a1;
        font-size: 4vw;

        .arrow {
            position: relative;
            left: -8vw;
            width: 15vw;
            z-index: 2;
        }

        .browser {
            width: 100vw;
            position: relative;
            top: -6vw;
        }
    }
}
</style>