<template>
  <section class="mainBody">
    <div style="width: 100%">
      <div style="margin: 0px auto; width: 100%">
        <div class="context">
          <div class="top">
            <div class="body">
              <span class="leftLabel">{{ $t('current level') }}</span>
              <div class="leftbg">
                <img src="@/assets/color1.png" />
                <img
                  :src="
                    require('@/assets/img_dj' +
                      (user && user.vip ? user.vip : 0) +
                      '.png')
                  "
                />
                <span
                  class="levelText"
                  :data-text="user && user.vip ? user.vip : '0'"
                  >{{ user && user.vip ? user.vip : "0" }}
                </span>
              </div>
              <div class="rightBody">
                <div class="left">
                  <span
                    >{{ $t('remaining') }}<i
                      >{{ $t('vip') }}{{ user && user.vip ? user.vip : 1 }}</i
                    ></span
                  >
                  <span
                    >
                    {{ $t('deposit more',{ money: dp }) }}
                    </span
                  >，
                  <span
                    >
                    {{ $t('bet more',{ money: bp }) }}
                    
                  </span>
                </div>
                <div class="right">
                  <van-button :disabled="true">{{ $t('rescue all') }}</van-button>

                  <van-button>{{ $t('historic') }}</van-button>
                </div>
              </div>
            </div>
          </div>
          <div class="vipProgress">
            <div class="title">
              <p>{{ $t('vip tier list') }}</p>
            </div>
            <div>
              <section class="exhibitionStage">
                <div style="padding-left: 0; position: relative">
                  <van-tabs
                    v-model="active"
                    title-inactive-color="var(--theme-text-color-darken)"
                    title-active-color="var(--theme-primary-color)"
                    :line-width="active === 0 ? '2.6rem' : '1rem'"
                    :animated="true"
                  >
                    <van-tab :title="$t('level up bonus')">
                      <div style="margin-top: 0.2rem; padding: 0">
                        <section
                          style="
                            min-height: 5rem;
                            --empty-height: 5rem;
                            position: relative;
                            padding: 0.01rem;
                          "
                        >
                          <el-table
                            :data="vipList"
                            :stripe="true"
                            style="width: 100%"
                            :highlight-current-row="false"
                            :row-class-name="rowClass"
                          >
                            <el-table-column prop="id" :label="$t('level')">
                              <template
                                slot-scope="scope"
                                style="
                                  flex: 0 0 0.7rem;
                                  width: 0.7rem;
                                  height: 0.7rem;
                                  font-size: 0.254545rem;
                                "
                              >
                                <img
                                  :src="
                                    require(`@/assets/color${
                                      scope.$index + 1
                                    }.png`)
                                  "
                                  style="width: 0.7rem; height: 0.7rem"
                                />
                                <img
                                  :src="
                                    require(`@/assets/img_dj${scope.$index}.png`)
                                  "
                                  style="width: 0.7rem; height: 0.7rem"
                                />
                                <span
                                  class="levelText"
                                  :data-text="scope.$index"
                                  >{{ scope.$index }}</span
                                >
                              </template>
                            </el-table-column>
                            <el-table-column prop="deposit">
                              <template slot="header">
                                <div>
                                  <span> {{ $t('deposit for promotion') }}</span>
                                  <span
                                    @click="
                                      () => {
                                        tipstype = 0;
                                        dialogTips = true;
                                      }
                                    "
                                  >
                                    <i
                                      style="
                                        display: inline-flex;
                                        justify-content: center;
                                        align-items: center;
                                      "
                                    >
                                      <svg-icon
                                        icon-class="comm_icon_wh"
                                        style="
                                          width: 1em;
                                          height: 1em;
                                          fill: currentColor;
                                        "
                                      ></svg-icon>
                                    </i>
                                  </span>
                                </div>
                              </template>
                              <template slot-scope="scope">
                                <div style="text-align: center; width: 100%">
                                  {{ scope.row.deposit }}
                                </div>

                                <el-progress
                                  style="margin-top: 0.15rem"
                                  v-if="
                                    user &&
                                    user.vip &&
                                    user.vip + 1 === scope.row.vip
                                  "
                                  :show-text="true"
                                  :stroke-width="10"
                                  :percentage="rechargeProgress"
                                  :text-inside="true"
                                  text-color="#fff"
                                  :format="progressText"
                                  define-back-color="var(--theme-text-color-placeholder)"
                                  color="var(--theme-secondary-color-success)"
                                ></el-progress>
                              </template>
                            </el-table-column>
                            <el-table-column
                              prop="flow"
                              :label="$t('bet for promotion')"
                            >
                              <template slot="header">
                                <div>
                                  <span> {{ $t('bet for promotion') }}</span>
                                  <span
                                    @click="
                                      () => {
                                        tipstype = 1;
                                        dialogTips = true;
                                      }
                                    "
                                  >
                                    <i
                                      style="
                                        display: inline-flex;
                                        justify-content: center;
                                        align-items: center;
                                      "
                                    >
                                      <svg-icon
                                        icon-class="comm_icon_wh"
                                        style="
                                          width: 1em;
                                          height: 1em;
                                          fill: currentColor;
                                        "
                                      ></svg-icon>
                                    </i>
                                  </span>
                                </div>
                              </template>

                              <template slot-scope="scope">
                                <div style="text-align: center; width: 100%">
                                  {{ scope.row.flow }}
                                </div>

                                <el-progress
                                  style="margin-top: 0.15rem"
                                  v-if="
                                    user &&
                                    user.vip &&
                                    user.vip + 1 === scope.row.vip
                                  "
                                  :show-text="true"
                                  :stroke-width="10"
                                  :percentage="betProgress"
                                  :text-inside="true"
                                  text-color="#fff"
                                  :format="progressText2"
                                  define-back-color="var(--theme-text-color-placeholder)"
                                  color="var(--theme-secondary-color-success)"
                                ></el-progress>
                              </template>
                            </el-table-column>
                            <el-table-column :label="$t('level up bonus')">
                              —
                            </el-table-column>
                          </el-table>
                        </section>
                      </div>
                    </van-tab>
                    <van-tab :title="$t('vip privilege')">
                      <div style="margin-top: 0.2rem; padding: 0">
                        <section
                          style="
                            min-height: 5rem;
                            --empty-height: 5rem;
                            position: relative;
                          "
                        >
                          <el-table
                            :data="vipList"
                            :stripe="true"
                            style="width: 100%"
                            :highlight-current-row="false"
                          >
                            <el-table-column prop="id" :label="$t('level')">
                              <template
                                slot-scope="scope"
                                style="
                                  flex: 0 0 0.7rem;
                                  width: 0.7rem;
                                  height: 0.7rem;
                                  font-size: 0.254545rem;
                                "
                              >
                                <img
                                  :src="
                                    require(`@/assets/color${
                                      scope.$index + 1
                                    }.png`)
                                  "
                                  style="width: 0.7rem; height: 0.7rem"
                                />
                                <img
                                  :src="
                                    require(`@/assets/img_dj${scope.$index}.png`)
                                  "
                                  style="width: 0.7rem; height: 0.7rem"
                                />
                                <span
                                  class="levelText"
                                  :data-text="scope.$index"
                                  >{{ scope.$index }}</span
                                >
                              </template>
                            </el-table-column>
                            <el-table-column prop="deposit">
                              <template slot="header">
                                <div>
                                  <span>{{ $t('withdrawal day limit') }}</span>
                                </div>
                              </template>
                              <template>
                                {{ $t('unlimited') }}
                              </template>
                            </el-table-column>
                            <el-table-column
                              prop="withdraw_verify"
                              :label="$t('withdrawal day limit2')"
                            >
                              <template slot="header">
                                <div>
                                  <span>
                                    {{ $t('withdrawal day limit2') }}</span
                                  >
                                </div>
                              </template>
                            </el-table-column>
                            <el-table-column
                              prop="bonus"
                              :label="$t('level up bonus')"
                            >
                            —
                            </el-table-column>
                          </el-table>
                        </section>
                      </div>
                    </van-tab>
                  </van-tabs>
                </div>
              </section>
            </div>
          </div>

          <div class="vipRule">
            <div class="title">{{ $t('vip rules instructions') }}</div>
            <div class="text">
              1.Padrão de promoção: atenda aos requisitos da promoção VIP (ou
              seja, a recarga ou apostas eficazes podem atender às condições),
              você pode avançar para o nível VIP correspondente e obter o bônus
              de promoção correspondente.O bônus pode ser recebido de tempos em
              tempos;<br />
              2.Salário diário: Se a recarga diária e as apostas válidas
              atenderem aos requisitos salariais diários do nível atual, você
              poderá obter o bônus salarial diário correspondente. Se você
              avançar para vários níveis consecutivos, só poderá obter o bônus
              salarial diário do atual nível.O bônus pode ser recebido de tempos
              em tempos;<br />
              3.Salário Semanal: Se a recarga semanal e as apostas válidas
              atenderem ao nível atual de requisitos salariais semanais, você
              poderá obter o bônus salarial semanal correspondente. Se você
              avançar para vários níveis consecutivos, poderá obter apenas o
              nível atual de bônus salarial semanal.O bônus pode ser recebido de
              tempos em tempos;<br />
              4.Lulu mensal: recarga mensal e apostas efetivas para atender ao
              nível atual do Lulu mensal, e você pode obter o bônus de prêmio
              mensal correspondente.O bônus pode ser recebido de tempos em
              tempos;<br />
              5.Tempo de Expiração da Recompensa: O bônus recebido fica
              disponível por %d dias. Se não for resgatado ativamente durante
              esse período, ele será automaticamente creditado na conta. Por
              exemplo: se uma recompensa for obtida em 1º de janeiro e retida
              por %d dias, será automaticamente creditada na conta em %d de
              janeiro às 00:00:00.<br />
              6.Instruções para auditoria: o bônus VIP oferecido pode ser
              levantado apenas após o cumprimento do requisito de rollover 1x
              (ou seja, auditoria, apostas ou apostas válidas),
              independentemente da plataforma em que joga;<br />
              7.Declarações: Esta função está limitada às operações normais dos
              titulares de contas. É proibido alugar contas, efetuar apostas sem
              risco (apostas com contas diferentes, swiping mútuo e swiping de
              odds baixas), arbitragem viciosa, utilizar plug-ins, robôs,
              exploração de acordos, lacunas, interfaces, controlo de grupo ou
              outros meios técnicos de participação; caso contrário, uma vez
              provado, a plataforma tem o direito de proibir os membros de
              iniciar sessão, suspender a utilização do nosso website, e
              confiscar o bônus e os ganhos indevidos, sem qualquer aviso
              especial;<br />
              8.Instruções: Ao reclamar o bônus VIP, considera-se que os membros
              aceitam e cumprem as regras correspondentes. A fim de evitar
              diferenças na compreensão do texto, a plataforma reserva o direito
              final de interpretar esta atividade.
            </div>
          </div>
        </div>
      </div>

      <myDialog
        myCustomClass="mydialog"
        :open.sync="dialogTips"
        :originalClose="false"
        :appendBody="true"
        :Title="$t('reminder')"
        width="6rem"
      >
        <div class="TipsInfo">
          {{ tipstype === 0 ? tipsText1 : tipsText2 }}
        </div>
      </myDialog>
    </div>
  </section>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { getconfigFetch } from "@/api/index";
import { formatNumberToString2 } from "@/utils/utils";
import myDialog from "@/components/phone/common/myDialog.vue";
export default {
  name: "vip",
  computed: {
    ...mapState({
      user: (state) => state.user.user,
      token: (state) => state.user.token,
    }),
  },
  components: { myDialog },
  data() {
    return {
      dp: "0,00",
      bp: "0,00",
      tipsText1:
        this.$t('deposit for promotion tips'),
      tipsText2:
        this.$t('bet for promotion tips'),
      type: 1,
      showSideBar: true,
      dialogTips: false,
      rechargeProgress: 0,
      betProgress: 0,
      title: "VIP",
      tipstype: 0,
      active: 0,
      vipConfig: [],
      nextVipConfig: {},
      vipList: [
        { id: 0, deposit: 0, flow: 0, withdraw_verify: 0, vip: 0 },
        // { id: 1, deposito: 10, aposta: 10, bonus: "-" },
        // { id: 2, deposito: 100, aposta: 100, bonus: "-" },
        // { id: 3, deposito: 1000, aposta: 1000, bonus: "-" },
        // { id: 4, deposito: 5000, aposta: 5000, bonus: "-" },
        // { id: 5, deposito: 10000, aposta: 10000, bonus: "-" },
        // { id: 6, deposito: 50000, aposta: 50000, bonus: "-" },
        // { id: 7, deposito: 100000, aposta: 100000, bonus: "-" },
        // { id: 8, deposito: 500000, aposta: 500000, bonus: "-" },
        // { id: 9, deposito: 1000000, aposta: 1000000, bonus: "-" },
      ],
    };
  },
  activated() {
    // console.log(this.user);
    this.updateLevelProgress();
  },
  mounted() {
    this.getVipconfig();
  },
  methods: {
    progressText() {
      if (this.user && this.user.deposit) {
        return `${this.user.deposit / 100}/${this.nextVipConfig.deposit}`;
      }
      return "";
    },
    progressText2(){
      if (this.user && this.user.bet) {
        return `${this.user.bet / 100}/${this.nextVipConfig.flow}`;
      }
      return "";
    },
    updateLevelProgress() {
      if (this.user && this.user.vip) {
        const index = this.vipConfig.findIndex(
          (e) => e.vip === this.user.vip + 1
        );

        if (index !== -1) {
          const e = this.vipConfig[index];
          if (e.vip === this.user.vip + 1) {
            const bet = this.user.bet / 100;
            const deposit = this.user.deposit / 100;
            this.bp = formatNumberToString2(
              bet > e.flow ? 0 : (e.flow - bet) * 100
            );
            this.dp = formatNumberToString2(
              deposit > e.deposit ? 0 : (e.deposit - deposit) * 100
            );
            this.nextVipConfig = e;
            var r = parseInt((deposit / this.nextVipConfig.deposit) * 100);
            var b = parseInt((bet / this.nextVipConfig.flow) * 100);
            this.rechargeProgress = r > 100 ? 100 : r;
            this.betProgress = b > 100 ? 100 : b;
          }
        }
      } else {
        this.$store.dispatch("getUserInfo").then(() => {
          this.updateLevelProgress();
        });
      }
    },
    toHistoric() {
      this.$router.push({
        path: "/Promotion",
        query: {
          index: 5,
        },
      });
    },
    getVipconfig() {
      getconfigFetch({ key: "vip" }).then(({ data }) => {
        const d = JSON.parse(data.data.data);
        this.vipConfig = d;
        // console.log(this.vipConfig);
        this.vipConfig.forEach((e) => {
          this.vipList.push(e);
        });
        this.updateLevelProgress();
      });
    },
    rowClass(row, rowIndex) {
      // console.log(row.row.vip);
      if (this.user && this.user.vip) {
        if (row.row.vip === this.user.vip) {
          return this.$t('activation');
        }
        return "";
      } else if (row.row.vip === 0) {
        return this.$t('activation');
      } else return "";
    },
  },
};
</script>
<style type="text/less" lang="less" scoped>
.mainBody {
  padding: 0.1rem 0 1.42rem;
  text-transform: capitalize;
  display: flex;
  justify-content: center;
  .context {
    padding: 0 0.2rem;
    .top {
      .body {
        -ms-flex-align: center;
        -ms-flex-pack: justify;
        align-items: center;
        background-color: var(--theme-main-bg-color);
        border-radius: 0.12rem;
        -webkit-box-shadow: 0 0.03rem 0.09rem 0 rgba(0, 0, 0, 0.06);
        box-shadow: 0 0.03rem 0.09rem 0 rgba(0, 0, 0, 0.06);
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        display: -ms-flexbox;
        display: flex;
        justify-content: space-between;
        padding: 0.45rem 0.2rem 0.25rem;
        position: relative;
        padding: 0.2rem;
        .leftLabel {
          -ms-flex-align: center;
          -ms-flex-pack: center;
          align-items: center;
          background-color: var(--theme-secondary-color-error);
          border-radius: 0.08rem 0.08rem 0.08rem 0;
          color: #fff;
          display: -ms-flexbox;
          display: flex;
          font-size: 0.18rem;
          height: 0.3rem;
          justify-content: center;
          left: 0;
          padding: 0 0.06rem;
          position: absolute;
          top: 0;
          z-index: 1;
        }
        .leftbg {
          -ms-flex-align: center;
          -ms-flex-pack: center;
          align-items: center;
          background-position: 50%;
          background-repeat: no-repeat;
          background-size: contain;
          display: -ms-flexbox;
          display: flex;
          justify-content: center;
          margin: auto;
          position: relative;
          text-align: center;
          margin: 0 0.2rem 0 0;
          margin-top: 0.2rem;
          flex: 0 0 1rem;
          width: 1rem;
          height: 1rem;
          font-size: 0.363636rem;
          img {
            width: 1rem;
            height: 1rem;
            position: absolute;
          }
        }
        .rightBody {
          align-items: center;
          display: flex;
          justify-content: space-between;
          flex-grow: 1;

          .left {
            text-align: left;
            color: var(--theme-text-color-lighten);
            padding-top: 0.2rem;
            line-height: 0.26rem;
            font-size: 0.24rem;
            margin-bottom: 0;
            span:first-child {
              display: block;
            }
            i {
              color: var(--theme-secondary-color-error);
              font-weight: 700;
              margin: 0 0.05rem;
            }
            strong {
              color: var(--theme-text-color-darken);
            }
          }
          .right {
            align-items: flex-end;
            display: flex;
            flex-direction: column;
            button:first-child {
              margin-bottom: 0.2rem;
            }
            button:nth-child(2) {
              margin-left: 0.2rem;
              background-color: var(--theme-primary-color);
              border-color: var(--theme-primary-color);
              box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
              font-weight: 400;
              color: var(--theme-primary-font-color);
              text-shadow: none;
            }
            button {
              border-radius: 0.1rem;
              font-size: 0.16rem;

              line-height: 0.18rem;
              padding: 0 0.05rem;

              height: 0.5rem;
              width: 1.2rem;
            }
            /deep/.van-button--disabled {
              background-color: var(--theme-disabled-bg-color);
              border-color: var(--theme-disabled-bg-color);
              color: var(--theme-disabled-font-color);
              opacity: 1;
            }
          }
        }
      }
    }

    .vipProgress {
      background: 0 0;
      box-shadow: none;
      padding: 0;
      font-size: 0.3rem;
      border-radius: 0.1rem;
      color: var(--theme-text-color-darken);
      margin: 0.2rem 0;
      text-align: left;
      .title {
        padding-left: 0;
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding: 0.2rem 0.2rem 0;
      }
      .exhibitionStage {
        -ms-overflow-style: none;
        border-bottom: 0.01rem solid var(--theme-color-line);
        min-width: 100%;
        scrollbar-width: none;

        /deep/.van-tab__text {
          font-size: 0.22rem;
          line-height: 0.7rem;
        }
        /deep/.van-tabs__wrap {
          border-bottom: thin solid var(--theme-color-line);
          display: flex;
          height: auto;
          overflow: auto hidden;
        }
        /deep/.van-tabs__nav {
          background-color: var(--theme-main-bg-color);
          display: inline-block;
          overflow-x: auto;
          padding: 0px;
          white-space: nowrap;
          width: 100%;
          padding-top: 0.2rem;
          background-color: transparent;
          .van-tab {
            color: var(--theme-text-color-darken);
            display: inline-block;
            font-size: 0.24rem;
            padding: 0px;
            position: relative;
            display: inline-block;
            position: relative;
            height: 0.7rem;
            line-height: 0.7rem;
            margin-right: 0.3rem;
            font-size: 0.22rem;
          }
          .van-tab--active {
            color: var(--theme-primary-color);
            transition: all 0.3s ease 0s;
          }
          .van-tabs__line {
            background-color: var(--theme-primary-color);
            bottom: 0px;
            height: 0.03rem;
          }
        }
        /deep/.el-table {
          background-color: transparent;
          tr {
            background-color: transparent;
          }

          .el-table__header-wrapper {
            tr {
              height: 1rem;
              text-align: left;
              th {
                background-color: var(--theme-bg-color);
                border-bottom: 0.01rem solid var(--theme-color-line) !important;
                border-top: 0.01rem solid var(--theme-color-line) !important;
                color: var(--theme-text-color);
                font-size: 0.2rem;
                font-weight: 400;
                background: var(--theme-main-bg-color);
                font-size: 0.24rem;
                height: 0.8rem;
              }
            }
            th:first-child {
              border-bottom-left-radius: 0.06rem;
              border-left: 0.01rem solid var(--theme-color-line) !important;
              border-top-left-radius: 0.06rem;
            }
            th:first-child {
              border-bottom-left-radius: 0.1rem;
              border-top-left-radius: 0.1rem;
            }
            th:last-child {
              border-bottom-right-radius: 0.06rem;
              border-right: 0.01rem solid var(--theme-color-line) !important;
              border-top-right-radius: 0.06rem;
            }

            th:last-child {
              border-bottom-right-radius: 0.1rem;
              border-top-right-radius: 0.1rem;
            }
            .el-table__cell {
              background: var(--theme-main-bg-color);
              background-color: var(--theme-bg-color) !important;
              font-size: 0.22rem;
              color: var(--theme-text-color-darken);
              border: none;
              text-align: center;
              text-transform: capitalize;
              transition: background 0.3s ease;
              word-wrap: break-word;
              height: 0.8rem;
              padding: inherit;
              .cell {
                padding: 0;
                line-height: 0.24rem;
                display: inline-block;
                max-width: 100%;
                vertical-align: top;
                text-align: center;
                word-wrap: normal;
                word-break: normal;
                width: unset;
                div {
                  align-items: center;
                  display: flex;
                  span:first-child {
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    display: -webkit-box;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    vertical-align: middle;
                    white-space: break-spaces;
                    line-height: 0.24rem;
                    border-collapse: separate;
                  }
                  span:nth-child(2) {
                    -ms-flex-align: center;
                    -ms-flex-pack: center;
                    align-items: center;
                    background-color: var(--theme-primary-color);
                    border-radius: 50%;
                    cursor: pointer;
                    display: -ms-inline-flexbox;
                    display: inline-flex;
                    justify-content: center;
                    margin-left: 0.1rem;
                    padding: 0.04rem;
                    svg {
                      color: var(--theme-primary-font-color);
                      font-size: 0.12rem;
                    }
                  }
                }
              }
              // border:#ce9510;
            }
          }

          .el-table__body {
            .cell {
              padding: initial;
              word-break: normal;
            }
            tr {
              height: 1rem;
            }
            .el-table__row {
              pointer-events: none;
              color: var(--theme-text-color);
              font-size: 0.2rem;
              td {
                background-color: var(--theme-main-bg-color);
                height: 0.6rem;
                padding: 0 0.13rem !important;
                white-space: pre;
                transition: background 0.3s;
                border-bottom: none;
              }
              td:first-child {
                border-bottom-left-radius: 0.1rem;
                border-top-left-radius: 0.1rem;
                .cell:first-child {
                  -ms-flex-align: center;
                  -ms-flex-pack: center;
                  align-items: center;
                  background-position: 50%;
                  background-repeat: no-repeat;
                  background-size: contain;
                  display: -ms-flexbox;
                  display: flex;
                  justify-content: center;
                  // margin: auto;
                  position: relative;
                  text-align: center;
                  flex: 0 0 0.7rem;
                  width: 0.7rem;
                  height: 0.7rem;
                  font-size: 0.254545rem;
                  img {
                    position: absolute;
                  }
                }
              }
              td:last-child {
                border-bottom-right-radius: 0.1rem;
                border-top-right-radius: 0.1rem;
              }
            }

            .el-table__row--striped {
              td {
                background-color: var(--theme-main-color);
              }
              .el-table__cell:first-child {
                border-left: none;
              }
              .el-table__cell:last-child {
                border-right: none;
              }
              .el-table__cell {
                border-bottom: none;
                border-top: none;
              }
            }

            .activation {
              td:first-child {
                border-bottom-left-radius: 0.1rem;
                border-top-left-radius: 0.1rem;
              }
              td:last-child {
                border-bottom-right-radius: 0.1rem;
                border-top-right-radius: 0.1rem;
              }
              .el-table__cell:first-child {
                border-left: thin solid var(--theme-primary-color);
              }
              .el-table__cell:last-child {
                border-right: thin solid var(--theme-primary-color);
              }
              .el-table__cell {
                border-bottom: thin solid var(--theme-primary-color);
                border-top: thin solid var(--theme-primary-color);
              }
            }
          }
        }

        /deep/ .el-table--border::after,
        /deep/ .el-table--group::after,
        /deep/.el-table::before {
          background-color: transparent;
        }
      }
    }

    .vipRule {
      background: 0 0;
      -webkit-box-shadow: none;
      box-shadow: none;
      padding: 0;
      font-size: 0.3rem;
      border-radius: 0.1rem;
      color: var(--theme-text-color-darken);
      margin: 0.2rem 0;
      text-align: left;
      .title {
        padding: 0.2rem 0.2rem 0;
      }
      .text {
        border-top: 0.01rem solid var(--theme-color-line);
        color: var(--theme-text-color);
        font-size: 0.18rem;
        line-height: 1.56;
        margin-top: 0.2rem;
        padding: 0.2rem;
        text-transform: none;
        white-space: break-spaces;
        word-break: break-all;
        font-size: 0.22rem;
        line-height: 1.64;
      }
    }
  }
}

.TipsInfo {
  color: var(--theme-text-color-darken);
  font-size: 0.24rem;
  font-weight: 400;
  text-align: center;
  margin: .3rem 0 .65rem;
  width: 100%;
  // padding: 0.2rem;
  // .btnbox {
  //   display: -ms-flexbox;
  //   display: flex;
  //   // margin-top: 0.38rem;
  // }
}
/deep/.el-dialog__wrapper {
  position: absolute !important;
}
.levelText {
  color: transparent;
  display: inline-block;
  font-style: normal;
  font-weight: 700;
  position: relative;
  text-shadow: 0 0.01rem 0 rgba(0, 0, 0, 0.4);
}

.levelText:before {
  -webkit-background-clip: text;
  background-clip: text;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#f7ea94),
    color-stop(51%, #e5b952),
    to(#ce9510)
  );
  background-image: linear-gradient(180deg, #f7ea94 0, #e5b952 51%, #ce9510);
  background-size: cover;
  content: attr(data-text);
  height: 100%;
  left: 0;
  position: absolute;
  text-shadow: none;
  top: 0;
  width: 100%;
  z-index: 0;
}
/deep/.el-progress-bar__outer {
  height: 0.2rem !important;
}
/deep/.el-progress-bar__innerText {
  -webkit-background-clip: text !important;
  left: 50%;
  top: 60%;
  position: absolute;
  overflow: hidden;
  text-align: center;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 94%;
  text-overflow: ellipsis;
}
/deep/.el-table__cell .cell{
  text-align: center;
}
</style>
