<template>
  <div class="box">
    <div class="avatarList">
      <div class="sexSelect">
        <div
          class="sexbox"
          @click="sex = 1"
          :class="sex === 1 ? 'sex-select' : ''"
        >
          <span class="sex">
            <i
              style="
                display: inline-flex;
                justify-content: center;
                align-items: center;
              "
            >
              <svg-icon
                icon-class="img_szxb1"
                style="
                  width: 1em;
                  height: 1em;

                  fill: currentColor;
                "
              ></svg-icon>
            </i>
          </span>
          <span>{{ $t('masculine') }}</span>
        </div>
        <div
          class="sexbox"
          @click="sex = 2"
          :class="sex === 2 ? 'sex-select' : ''"
        >
          <span class="sex">
            <i
              style="
                display: inline-flex;
                justify-content: center;
                align-items: center;
              "
            >
              <svg-icon
                icon-class="img_szxb2"
                style="
                  width: 1em;
                  height: 1em;

                  fill: #ff5662; ;
                "
              ></svg-icon>
            </i>
          </span>
          <span>{{ $t('feminine') }}</span>
        </div>
      </div>
      <myScrollbarBar st="y" class="myscroll">
        <ul class="alist">
          <li
            v-for="(item, index) in avatarList[sex - 1]"
            :key="index"
            class="item"
            :class="index === active ? 'selectItem' : ''"
            @click="active = index"
          >
            <van-image
             :lazy-load="true"
              fit="fill"
              width="1rem"
              height="1rem"
              :src="item"
            >
              <template v-slot:loading>
                <div class="imgload"></div>
              </template>
            </van-image>
            <p class="subscript" v-if="index === active">
              <i
                style="
                  display: inline-flex;
                  justify-content: center;
                  align-items: center;
                "
                class="gou"
              >
                <svg-icon
                  icon-class="comm_icon_gou"
                  focusable="false"
                  aria-hidden="true"
                  style="width: 1em; height: 1em; fill: currentColor"
                ></svg-icon
              ></i>
              <i
                style="
                  display: inline-flex;
                  justify-content: center;
                  align-items: center;
                "
                class="bg"
              >
                <svg-icon
                  icon-class="comm_img_corner"
                  focusable="false"
                  aria-hidden="true"
                  style=" fill: currentColor"
                ></svg-icon
              ></i>
            </p>
          </li>
        </ul>
      </myScrollbarBar>
    </div>

    <div class="workbenches">
      <van-button class="btn" @click="onConfirm" type="primary"
        >{{ $t('save') }}</van-button
      >
    </div>
  </div>
</template>
<script>
import myScrollbarBar from "@/components/phone/common/myScrollbarBar.vue";
import { getImageResourcesByKind } from "@/api/index";
import { modifyUserData } from "@/api/index";

export default {
  name: "avatarSelectBox",
  components: { myScrollbarBar },
  mixins: [],

  props: {
    avatarUrl: String,
  },
  data() {
    return {
      sex: 1,
      list: [],
      avatarList: [[], []],
      active: 0,
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.loadList();
  },
  methods: {
    closeDialog(url) {
      this.$emit("closeDialog",url);
    },
    loadList() {
      getImageResourcesByKind({ kind: "avatar" }).then(({ data }) => {
        // if
        if (data.code === 0) {
          this.list = data.data.urls;
          this.list.forEach((item) => {

            const i = JSON.parse(item);
            this.avatarList[i.sex - 1].push(i.url);
            if (this.avatarUrl === i.url && i.sex - 1 === 1) {
              this.active = this.avatarList[i.sex - 1].length - 1;
            }
          });

        }
      });
    },
    onConfirm() {
      const url = this.avatarList[this.sex - 1][this.active];
      if (url !== this.avatarUrl) {
        modifyUserData({ avatar: url }).then(({ data }) => {
          this.$toast(this.$t('modification of successful personal information'));
          this.closeDialog(url);
        });
      }
    },
  },
};
</script>
<style type="text/less" lang="less" scoped>

.myscroll {
  height: 5.5rem !important;
  margin: 0 auto auto;
  width: 5.7rem !important;
}
.box {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  .avatarList {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    background-color: var(--theme-main-bg-color);
    border-radius: 0.14rem;
    -webkit-box-shadow: 0 0.03rem 0.1rem 0 rgba(0, 0, 0, 0.06);
    box-shadow: 0 0.03rem 0.1rem 0 rgba(0, 0, 0, 0.06);
    height: 7.5rem;
    margin: 0.2rem 0.2rem auto;
    .sexSelect {
      -ms-flex-align: center;
      -ms-flex-pack: center;
      align-items: center;
      display: -ms-flexbox;
      display: flex;
      justify-content: center;
      font-size: 0.22rem;
      height: 0.5rem;
      margin: 0.5rem auto;
      .sexbox {
        border: thin solid var(--theme-color-line);
        -ms-flex-align: center;
        -ms-flex-pack: center;
        align-items: center;
        display: -ms-flexbox;
        display: flex;
        justify-content: center;
        padding: 0 0.18rem;
        min-width: 1rem;
        cursor: pointer;
        border-radius: 0.25rem;
        font-size: 0.22rem;
        height: 0.5rem;
        line-height: 0.5rem;
        margin-right: 0.4rem;
        width: 1.7rem;
        color: var(--theme-text-color);
        span {
          -ms-flex-align: center;
          align-items: center;
          display: -ms-flexbox;
          display: flex;
        }
        .sex {
          -webkit-transform: scale(1.5);
          transform: scale(1.5);
          -webkit-transform-origin: center center;
          transform-origin: center center;
        }
        span:last-child {
          margin-left: 0.1rem;
          line-height: 0.5rem;
          word-wrap: break-word;
        }
      }
      .sex-select {
        background-color: var(--theme-primary-color);
        border: none;
        color: var(--theme-primary-font-color);
      }
    }
    .alist {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      max-height: 5.21rem;
      .item {
        -ms-flex-align: center;
        -ms-flex-pack: center;
        align-items: center;
        border: thin solid transparent;
        border-radius: 0.1rem;
        cursor: pointer;
        display: -ms-flexbox;
        display: flex;
        justify-content: center;
        overflow: hidden;
        position: relative;
        height: 1.04rem;
        margin-bottom: 0.46rem;
        margin-right: 0.46rem;
        width: 1.04rem;
        img {
          border-radius: 0.1rem;
        }
      }
      .item:nth-child(4n) {
        margin-right: 0;
      }
      .selectItem {
        border: thin solid var(--theme-primary-color);
        height: 1.04rem;
        width: 1.04rem;
      }
    }
  }
  .workbenches {
    -ms-flex-align: center;
    -ms-flex-pack: center;
    align-items: center;
    background-color: var(--theme-main-bg-color);
    -webkit-box-shadow: 0 -0.03rem 0.1rem 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 -0.03rem 0.1rem 0 rgba(0, 0, 0, 0.1);
    display: -ms-flexbox;
    display: flex;
    height: 1.3rem;
    justify-content: center;
    margin: auto auto 0;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    .btn {
      text-shadow: none;
      background-color: var(--theme-primary-color);
      border-color: var(--theme-primary-color);
      border-radius: 0.14rem;
      color: var(--theme-primary-font-color);
      font-size: 0.24rem;
      height: 0.7rem;
      line-height: 0.55rem;
      margin: 0.2rem 0.2rem auto;
      text-align: center;
      width: 100%;
    }
  }
}
</style>
