import { render, staticRenderFns } from "./dateSelect.vue?vue&type=template&id=37844d71&scoped=true"
import script from "./dateSelect.vue?vue&type=script&lang=js"
export * from "./dateSelect.vue?vue&type=script&lang=js"
import style0 from "./dateSelect.vue?vue&type=style&index=0&id=37844d71&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37844d71",
  null
  
)

export default component.exports