<template>
  <div class="box">
    <div class="logo">
      <img
        src="@/assets/a810p-pbdnf.png"
        style="width: 2.25rem; height: 0.8rem"
      />
    </div>
    <ul class="tips">
      <li>
      {{ $t('paytip1') }}
      </li>
      <li>
        {{$t('paytip2')}}
      </li>
      <li class="orange">
        {{ $t('paytip3') }}
      </li>
    </ul>
    <div class="qrbox">
      <div class="title">
        <span>{{ payInfo.amount2 }}</span>
        <span class="copyIcon" v-copy="payInfo.amount2">
          <svg-icon
            icon-class="comm_icon_copy"
            style="width: 1em; height: 1em; fill: currentColor"
          ></svg-icon>
        </span>
      </div>
      <div class="qrimg">
        <img :src="payInfo.qrImg" />
      </div>
      <div>
        <div style="font-size: 0.22rem">
          <van-count-down :time="sj" :format="$t('remainder')+' mm : ss'" />
        </div>
        <div class="copyqr">
          <span class="copybox">
            <van-button v-copy="payInfo.qr_code">
              <template slot="icon">
                <p>
                  <i
                    style="
                      display: inline-flex;
                      justify-content: center;
                      align-items: center;
                      margin-right: 0.2rem;
                    "
                  >
                    <svg-icon
                      icon-class="comm_icon_copy"
                      style="width: 1em; height: 1em; fill: currentColor"
                    ></svg-icon
                  ></i>
                </p>
              </template>
              {{ $t('copy QR code') }}
            </van-button>
          </span>
        </div>
        <p style="margin-top:20px;"><van-button class="highlight-button" @click="SandBoxPay" :loading="sandpayloading"> test pay </van-button></p>
        <p class="copyOrderOn">
        {{ $t('QR code address') }}  {{ truncateString(payInfo.qr_code) }}
          <span class="copyIcon" v-copy="payInfo.qr_code">
            <svg-icon
              icon-class="comm_icon_copy"
              style="width: 1em; height: 1em; fill: currentColor"
            ></svg-icon>
          </span>
        </p>
      </div>
    </div>
    <ul class="payInfo">
      <li>
        <span class="leftT">{{ $t('order status') }}</span>
        <div class="rightC">
          <span class="o">{{ getStatusType(payInfo.state) }}</span>
          <span class="refreshStatus" @click="refreshOrderState">
            <i
              class="anticon refresh-coin animate__spin animate__infinite"
              :class="{ animate__animated: isAnimateAnimated }"
            >
              <i
                style="
                  display: inline-flex;
                  justify-content: center;
                  align-items: center;
                "
              >
                <svg-icon
                  icon-class="comm_icon_sx"
                  style="
                    width: 1em;
                    height: 1em;
                    color: var(--theme-alt-primary);
                    fill: currentColor;
                    font-size: 0.293rem;
                  "
                ></svg-icon>
              </i>
            </i>
          </span>
        </div>
      </li>
      <li>
        <span style="color: var(--theme-text-color-lighten)"
          >{{ $t('creation time') }}</span
        >
        <span>{{ payInfo.create_timestamp }}</span>
      </li>
      <li>
        <span class="leftT">{{ $t('request number') }}</span>
        <div class="rightC">
          <span>{{ payInfo.order_no }}</span>
          <span
            class="copyIcon"
            style="margin-left: 0.2rem"
            v-copy="payInfo.order_no"
          >
            <svg-icon
              icon-class="comm_icon_copy"
              style="width: 1em; height: 1em; fill: currentColor"
            ></svg-icon>
          </span>
        </div>
      </li>

      <li>
        <span class="leftT">{{ $t('merchant order number') }}</span>
        <div class="rightC">
          <span>{{ payInfo.pay_no }}</span>
          <span
            class="copyIcon"
            style="margin-left: 0.2rem"
            v-copy="payInfo.pay_no"
          >
            <svg-icon
              icon-class="comm_icon_copy"
              style="width: 1em; height: 1em; fill: currentColor"
            ></svg-icon>
          </span>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import myScrollbarBar from "@/components/phone/common/myScrollbarBar.vue";
import { formatTime, truncateString } from "@/utils/utils";
import { SandBoxPay  } from "@/api/index";

export default {
  name: "payBox",
  components: { myScrollbarBar },
  mixins: [],
  props: {
    payInfo: {},
  },
  data() {
    return {
      sex: 1,
      list: [],
      avatarList: [[], []],
      active: 0,
      isAnimateAnimated: false,
      countdown: "",
      sj: 1800000,
      sandpayloading:false
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.sj = 1800000;
    // // this.loadList();
    // const self = this;
    // const c = setInterval(function () {
    //   if (self.sj <= 0) {
    //     clearInterval(c);
    //   }
    //   self.sj = self.sj - 1;
    //   self.countdown = formatTime(self.sj);
    // }, 1000);
  },
  activated() {},
  methods: {
    truncateString(s) {
      return truncateString(s, 8,'start','...**');
    },
    closeDialog() {
      this.$emit("closeDialog");
    },
    getStatusType(t) {
      switch (t) {
        case 0:
          return this.$t('generation of orders');
        case 1:
          return this.$t('payment');
        case 2:
          return this.$t('successful payment');
        case 3:
          return this.$t('payment failure');
        case 4:
          return this.$t('revoked');
        case 5:
          return this.$t('refund');
        case 6:
          return this.$t('closure of orders');
      }
    },
    refreshOrderState() {
      this.$emit("refreshOrderState");
    },
    SandBoxPay(){
      this.sandpayloading=true;
      SandBoxPay({order_no:this.payInfo.order_no}).then(({data})=>{

      });
    }
  },
};
</script>
<style type="text/less" lang="less" scoped>
/deep/.van-count-down {
  color: white;
}
/deep/.svg-icon {
  display: inline !important;
}

.myscroll {
  height: 5.5rem !important;
  margin: 0 auto auto;
  width: 5.7rem !important;
}
.box {
  background-color: var(--theme-main-bg-color);
  border-radius: 0.14rem;
  color: var(--theme-text-color-darken);
  padding: 0.3rem 0.2rem;
  .logo {
    -ms-flex-align: center;
    -ms-flex-pack: center;
    align-items: center;
    border-bottom: 0.01rem dotted var(--theme-color-line);
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    padding: 0.1rem 0 0.3rem;
    img {
      opacity: 1;
    }
  }
  .tips {
    font-size: 0.22rem;
    padding-top: 0.3rem;
    li {
      line-height: 0.34rem;
      margin-bottom: 0.2rem;
      padding-left: 0.25rem;
      position: relative;
    }
    li:after {
      background-color: var(--theme-text-color-darken);
      border-radius: 50%;
      content: "";
      height: 0.1rem;
      left: 0;
      position: absolute;
      top: 0.12rem;
      width: 0.1rem;
    }
    .orange {
      color: var(--theme-secondary-color-finance);
      font-weight: 700;
    }
  }
  .qrbox {
    text-align: center;
    .title {
      font-size: 0.5rem;
    }
    .copyIcon {
      font-size: 0.3rem;
      margin-left: 0.2rem;
      color: var(--theme-primary-color);
      cursor: pointer;
    }
    .qrimg {
      height: 2.8rem;
      margin: 0 auto;
      position: relative;
      width: 2.8rem;
      img {
        display: block;
        height: 100;
        width: 100%;
      }
    }
    .copyqr {
      -ms-flex-pack: center;
      display: -ms-flexbox;
      display: flex;
      justify-content: center;
      margin-top: 0.1rem;
      .copybox {
        color: var(--theme-primary-color);
        cursor: pointer;
        /deep/.van-button {
          font-size: 0.24rem;
          height: 0.7rem;
          width: 3.25rem;
          border-radius: 0.14rem;
          background-color: var(--theme-primary-color);
          border-color: var(--theme-primary-color);
          color: var(--theme-primary-font-color);
          text-shadow: none;
        }
      }
    }
    .copyOrderOn {
      -ms-flex-align: center;
      -ms-flex-pack: center;
      align-items: center;
      display: -ms-flexbox;
      display: flex;
      font-size: 0.22rem;
      justify-content: center;
      padding-top: 0.1rem;
      width: 100%;
    }
  }
  .payInfo {
    font-size: 0.22rem;
    margin-top: 1.2rem;
    li {
      -ms-flex-align: center;
      -ms-flex-pack: justify;
      align-items: center;
      display: -ms-flexbox;
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.2rem;
      .leftT {
        color: var(--theme-text-color-lighten);
      }
      .rightC {
        -ms-flex-align: center;
        align-items: center;
        display: -ms-flexbox;
        display: flex;
        .o {
          color: var(--theme-secondary-color-finance);
        }
        .refreshStatus {
          -ms-flex-align: center;
          align-items: center;
          display: -ms-flexbox;
          display: flex;
          color: var(--theme-primary-color);
          margin-left: 0.2rem;
        }
      }
    }
  }
}
</style>
