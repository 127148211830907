import { userRegister, userLogin, userInfo, twoFactorLogin } from '@/api/index'
import { getToken, setToken, removeToken, getAC, setAC, removeAC, removeRefreshToken, setRefreshToken } from '@/utils/auth'

const user = {
  state: {
    ac: getAC(),
    token: getToken(),
    user: {},
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_AC: (state, ac) => {
      state.ac = ac
    },
    SET_USER: (state, user) => {
      state.user = user
    }
  },

  actions: {
    // 登录
    login({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        userLogin(userInfo).then(res => {
          if (res.data.code === 0) {
            if (res.data.data.token === '') {
              // store.dispatch('triggerLoginTwoVerify',res.data.data);
              commit('SET_SHOW_LOGIN_TWO_VERIFY', { show: true, info: res.data.data });
            } else {
              setToken(res.data.data.token)
              setAC(res.data.data.accountNumber)
              setRefreshToken(res.data.data.refresh_token);
              // setAC()
              commit('SET_TOKEN', res.data.data.token)
              commit('SET_AC', res.data.data.accountNumber)
            }
            // getUserInfo();
            // setUserInfo(res.data.data, commit)
            resolve(res.data)
          } else {
            reject(res.data.message)
          }
        }).catch(error => {
          reject(error)
        })
      })
    },
    twoFactorLogin({ commit }, postData) {
      return new Promise((resolve, reject) => {
        twoFactorLogin(postData).then(res => {
          if (res.data.code === 0) {
            setToken(res.data.data.token)
            setAC(res.data.data.accountNumber)
            setRefreshToken(res.data.data.refresh_token);
            // setAC()
            commit('SET_TOKEN', res.data.data.token)
            commit('SET_AC', res.data.data.accountNumber)
            resolve(res.data)
          } else {
            reject(res.data.message)
          }
        }).catch(error => {
          reject(error)
        })
      })
    },
    register({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        userRegister(userInfo).then(res => {
          if (res.data.code === 0) {
            setToken(res.data.data.token);
            setAC(res.data.data.accountNumber);
            setRefreshToken(res.data.data.refresh_token);
            // setAC()
            commit('SET_AC', res.data.data.accountNumber);
            commit('SET_TOKEN', res.data.data.token);
            // setUserInfo(res.data.data, commit)
            // this.getUserInfo();
            resolve(res.data)
          } else {
            reject(res.data.message)
          }
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 获取用户信息
    getUserInfo({ dispatch,commit }) {
      return new Promise((resolve, reject) => {
        userInfo().then(res => {
          setUserInfo(res.data.data, commit)
          dispatch('redPoint/setRedPointInfo',commit);//刷新红点
          resolve(res.data.data)
        }).catch(error => {
          reject(error)
        })
      })
    },
    //登出
    LogOut({ commit }) {
      return new Promise((resolve, reject) => {
        logOut(commit)
        resolve()
      })
    }
    // }
  }
}

export const logOut = (commit) => {
  commit('SET_TOKEN', '')
  commit('SET_AC', '')
  // commit('SET_ROLES', [])
  removeToken()
  removeAC()
  removeRefreshToken();
}

export const setUserInfo = (res, commit) => {
  commit('SET_USER', res)
}

export default user
