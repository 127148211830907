<template>
  <div class="kf">
    <div class="kfleft">
      <div class="context">
        <div class="top">
       
          <van-image
                  :src="require('@/assets/img_kf_kf01.png')"
                  :lazy-load="true"
                  fit="fill"
                  height="0.82rem"
                  width="1.44rem"
                >
                  <template v-slot:loading>
                    <div class="imgload"></div>
                  </template>
                </van-image>

          <div class="right">
            <div class="title">{{ $t('online support 24') }}</div>
            <div class="text">
             {{ $t('online support 24++') }}
            </div>
          </div>
        </div>
        <div class="bottom">
          <van-button :block="true">9991BET</van-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {},
};
</script>
<style type="text/less" lang="less" scoped>
.kf {
  padding: 0.2rem 0.2rem 0;
  .kfleft {
    background-color: var(--theme-main-bg-color);
    border-radius: 0 0 0.1rem 0.1rem;
    -webkit-box-shadow: 0 0.03rem 0.09rem 0 rgba(0, 0, 0, 0.06);
    box-shadow: 0 0.03rem 0.09rem 0 rgba(0, 0, 0, 0.06);
    padding: 0.2rem;
    border-radius: 0.1rem;
    padding: 0.24rem 0.2rem;
    .context {
      -ms-flex-align: center;
      -ms-flex-pack: justify;
      align-items: center;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      justify-content: space-between;
      .top {
        -ms-flex-align: center;
        align-items: center;
        display: -ms-flexbox;
        display: flex;
        -ms-flex: 1;
        flex: 1;
        -ms-flex-align: initial;
        align-items: normal;
        .right {
          margin-left: 0.2rem;
          .title {
            margin-top: 0.05rem;

            font-size: 0.3rem;
            color: var(--theme-text-color-darken);
          }
          .text {
            color: var(--theme-text-color-lighten);
            font-size: 0.22rem;
          }
        }
      }
      .bottom {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-left: 1.02rem;
        margin-top: 0.2rem;
        width: 100%;
        .van-button::before{
            border: 1px solid #d9d9d9;
            content:'';
        }
        button {
         
          margin-left: 0;
          font-size: 0.2rem;
          height: 0.5rem;
          line-height: 0.22rem;
          margin-left: 0.2rem;
          width: 1.75rem;
          border-radius: 0.14rem;
          padding: 0px;
          padding: 0px;
          border: thin solid var(--theme-ant-primary-color-0);
          text-align: center;
          background-color: transparent;
        //   border-color: transparent;
          -webkit-box-shadow: none;
          box-shadow: none;
          color: var(--theme-ant-primary-color-0);
          font-weight: 400;
        }
      }
    }
  }
}
</style>
