<template>
  <el-select
    class="selectIpt"
    v-model="myselectItem"
    :popper-append-to-body="true"
    popper-class="selectitem myIcoSelect-selectitem"
    @change="select"
  >
    <template slot="prefix">
      <slot name="prefix"></slot>
    </template>
    <el-option
      v-for="(item, index) in items"
      :label="item.label"
      :value="item.value"
      :style="{
        color:
          item.value === myselectItem
            ? 'var(--theme-primary-color)'
            : 'var(--theme-text-color-lighten)',
      }"
    ></el-option>
  </el-select>
</template>
<script>
export default {
  name: "myIcoSelect",
  components: {},
  mixins: [],
  props: {
    items: {
      type: Array,
      required: true,
    },
    selectItem: {
      required: true,
    },
  },

  data() {
    return {
      myselectItem: "",
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.myselectItem=this.selectItem;
    if (this.items.length > 0&&this.myselectItem==='') {
      this.myselectItem = this.items[0].value;
      this.$emit("update:selectItem", this.myselectItem);
    }
  },
  methods: {
    select() {
      this.$emit("update:selectItem", this.myselectItem);
      this.$emit("change", this.myselectItem);
    },
  },
};
</script>
<style type="text/less" lang="less" scoped>
.selectIpt {
    height: .7rem!important;
  width: 100%;
  background-color: var(--theme-main-bg-color);
  border: 1px solid #d9d9d9;
  border-color: var(--theme-color-line);
  border-radius: .14rem!important;
  /deep/.el-input__prefix{
    left: 0.2rem;
    top: 50%;
    transform: translateY(-50%);
    display:flex;
    align-items:center;
  }

  /deep/.el-input__inner {
    font-size: .22rem!important;
    background-color: transparent;
    border: none;
    color: var(--theme-text-color-darken);
    padding: 0;
    height: .7rem!important;
    padding:0 0.6rem;
  }

  // margin-left: 7vw;
}

.selectIpt:active,
  .selectIpt:focus,
  .selectIpt:hover {
    border-color: var(--theme-ant-primary-color-13);
    border-right-width: 1px !important;
    -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    outline: 0;
  }
</style>
